import { HeadCell } from "components/organisms/SortableTable/SortableTable";
export const columnsInfo: { [key: string]: { key: string; header: string }[] } =
  {
    RISKY_REPORT: [
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "risky_id", header: "帳票番号" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "place_found_issue", header: "発行元" },
      { key: "incident_date", header: "発生年月日" },
      { key: "issue_place_floor", header: "発生場所フロア" },
      { key: "issue_place_span", header: "発生場所スパン" },
      { key: "issue_type", header: "事故区分" },
      { key: "responsible_person", header: "事故当事者" },
      { key: "person_found_issue", header: "発見者氏名" },
      { key: "product_type", header: "分類" },
      { key: "product_name", header: "商品名" },
      { key: "amount_of_product", header: "数量" },
      { key: "product_id", header: "機番" },
      { key: "product_packaging_status", header: "荷姿状態" },
      { key: "reason_cause_issue", header: "事故発生理由" },
      { key: "warehouse_place_floor", header: "格納場所フロア" },
      { key: "warehouse_place_span", header: "格納場所スパン" },
      { key: "bad_place", header: "不良ロケ" },
      { key: "importance_type", header: "重要度区分" },
      { key: "date_shipper_take", header: "荷主報告日時" },
      { key: "shipper_name", header: "荷主報告者" },
      { key: "receiver", header: "報告相手" },
      { key: "instruction_solve_issue", header: "事故処理（指示内容）" },
      { key: "completion_confirm_date", header: "処理完了確認（日付）" },
      {
        key: "completion_confirm_person_in_charge",
        header: "処理完了確認（担当）",
      },
      { key: "expense_claim", header: "事故費請求費用" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    INSPECTION_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "date_of_delivery", header: "搬入年月日" },
      { key: "container_no", header: "コンテナ番号" },
      { key: "container_size", header: "コンテナサイズ" },
      { key: "delivery_time", header: "納入時間" },
      { key: "carrier_name", header: "搬入者名" },
      { key: "unit_class", header: "Unit区分" },
      { key: "delivery_location", header: "搬入場所" },
      { key: "invoice_no", header: "インボイスナンバー" },
      { key: "seal_no", header: "シールNO." },
      { key: "berth_number", header: "バース番号" },
      { key: "sealed", header: "封印" },
      { key: "person_worker", header: "作業者名" },
      { key: "container_status", header: "コンテナの状態" },
      { key: "product_class", header: "入荷予定情報・商品分類" },
      { key: "model_name_1", header: "入荷予定情報・機種名" },
      { key: "number_of_moves", header: "入荷予定情報・搬入数" },
      { key: "unpacked_number", header: "入荷予定情報・開梱対象総台数" },
      { key: "no_abnomality_number", header: "入荷予定情報・開梱後異常なし" },
      { key: "model_name", header: "製品の状態・機種名" },
      { key: "count", header: "製品の状態・数量カウント" },
      { key: "good_products_number", header: "製品の状態・良品数" },
      { key: "defective_products_number", header: "製品の状態・不適合数" },
      { key: "total_number", header: "製品の状態・合計" },
      { key: "memo", header: "備考" },
      //{ key: "defective_products0", header: "製品の状態・不適合数内容①数量" },//defective_productsにある分出力するので、プログラムで追加
      { key: "unit_abnomality_report", header: "異常報告" }, //旧異常報告{ key: "abnomality_report", header: "異常報告" }
      {
        key: "bool_attention",
        header: "V.2段積み冷蔵庫、3段積み洗濯機は2人作業を実施",
      },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
    TEMPLATE_FORKLIFT_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "vehicles_frame_no", header: "車体番号" },
      { key: "vehicles_workplace", header: "事業場名" },
      { key: "vehicles_model", header: "車種名" },
      { key: "vehicles_fuel_type", header: "燃料" },
      { key: "vehicles_name", header: "車両番号" },
      { key: "driver", header: "担当者名" },
      { key: "year_month", header: "実施年月" },
      { key: "day", header: "実施日付" },
      { key: "check_user", header: "点検実施者名" },
      { key: "section", header: "点検箇所" },
      { key: "item", header: "点検項目" },
      { key: "result", header: "結果" },
    ],
    TEMPLATE_EXCEL_REPORT: [
      { key: "risky_id", header: "帳票番号" },
      { key: "report_type", header: "帳票種類" },
      { key: "project_id", header: "帳票" },
      { key: "applied_at", header: "申請日" },
      { key: "approved_at", header: "最終承認日" },
      { key: "updated_at", header: "最終更新日" },
      { key: "work_status_name", header: "帳票状況" },
      { key: "approver", header: "承認者" },
      { key: "created_by_name", header: "申請者" },
      { key: "flow_approve_name", header: "承認ルート" },
      { key: "is_image_exist", header: "写真添付有無" },
    ],
  };

export const SEALED_MESSAGE = {
  TRUE: "異常あり",
  FALSE: "異常なし",
};

export const headCells: { [key: string]: HeadCell[] } = {
  RISKY_REPORT: [
    { id: "risky_id", label: "事故番号", sortable: true, width: 200 },
    { id: "product_name", label: "品名", sortable: true, width: 250 },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 100,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 200,
    },
    {
      id: "is_image_array_text",
      label: "添付写真",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  INSPECTION_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    { id: "date_of_delivery", label: "搬入年月日", sortable: true, width: 100 },
    {
      id: "container_no",
      label: "コンテナ番号",
      sortable: true,
      width: 150,
    },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 50,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 200,
    },
    {
      id: "product_class_text",
      label: "商品分類",
      sortable: true,
      width: 200,
    },
    {
      id: "is_image_array_text",
      label: "添付写真",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  TEMPLATE_FORKLIFT_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    { id: "vehicles_frame_no", label: "車体番号", sortable: true, width: 150 },
    { id: "vehicles_workplace", label: "事業場名", sortable: true, width: 200 },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 50,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 100,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
  TEMPLATE_EXCEL_REPORT: [
    { id: "risky_id", label: "帳票番号", sortable: true, width: 150 },
    {
      id: "work_status_name",
      label: "状況",
      sortable: true,
      width: 50,
    },
    {
      id: "applied_at",
      label: "申請日",
      sortable: true,
      width: 150,
    },
    {
      id: "created_by_name",
      label: "申請者",
      sortable: true,
      width: 100,
    },
    {
      id: "is_image_array_text",
      label: "添付写真",
      sortable: true,
      width: 200,
    },
    {
      id: "btn_detail",
      label: "",
      sortable: false,
      width: 100,
      disablePadding: true,
    },
  ],
};
