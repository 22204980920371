import { call, select } from "redux-saga/effects";
import { getUserInfo, storeCondition } from "shared/utils";

export function* setDevanSearchConditionSaga(action: any): any {
  try {
    const updatedConditionDevanSearch = yield select(
      (state: any) => state.devanSearch,
    );
    const userId = yield getUserInfo()?.user_id;
    yield call(
      storeCondition,
      "devanSearch",
      updatedConditionDevanSearch,
      userId,
    );
  } catch (error) {
    console.log("function*setDevanSearchConditionSaga ~ error", error);
  }
}
