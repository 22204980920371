import React, { useMemo } from "react";
import { Box, Button, ButtonGroup, IconButton, Stack } from "@mui/material";
import {
  Circle,
  Delete,
  Edit,
  HorizontalRule,
  Layers,
  LayersClear,
  Rectangle,
  TextFields,
  Undo,
} from "@mui/icons-material";
import { Colors } from "@template/style";
import {
  ColorMode,
  DrawToolMode,
  LIST_COLOR_MODE,
  LIST_DRAW_TOOL_MODE,
  LIST_STROKE_WIDTH_MODE,
  StrokeWidthMode,
} from "services/models";
import { useSize } from "shared/hook/useSize";

interface IProps {
  color: ColorMode;
  onClickColor: (color: ColorMode) => void;
  strokeWidth: StrokeWidthMode;
  onClickStrokeWidth: (color: StrokeWidthMode) => void;
  drawTool: DrawToolMode;
  onClickDrawTool: (color: DrawToolMode) => void;
  showLayer: boolean;
  onClickLayer: () => void;
  onClickUndo: () => void;
  disabledUndo: boolean;
}

const DrawMenu: React.VFC<IProps> = ({
  color,
  onClickColor,
  strokeWidth,
  onClickStrokeWidth,
  drawTool,
  onClickDrawTool,
  showLayer,
  onClickLayer,
  onClickUndo,
  disabledUndo,
}) => {
  const { isMdUp } = useSize();
  return (
    <Stack spacing={0.5}>
      {/* 色 */}
      <ButtonGroup
        orientation={isMdUp ? "horizontal" : "vertical"}
        sx={{ alignItems: "flex-start" }}
      >
        {LIST_COLOR_MODE.map((value) => (
          <ColorButton
            color={value}
            selected={color === value}
            onClick={onClickColor}
            key={value}
          />
        ))}
      </ButtonGroup>
      {/* 太さ */}
      <ButtonGroup variant="outlined" size="small">
        {LIST_STROKE_WIDTH_MODE.map((value) => (
          <StrokeWidthButton
            width={value}
            selected={strokeWidth === value}
            onClick={onClickStrokeWidth}
            key={value}
          />
        ))}
      </ButtonGroup>
      {/* 描画 */}
      {LIST_DRAW_TOOL_MODE.map((value) => (
        <DrawToolButton
          drawTool={value}
          selected={drawTool === value}
          onClick={onClickDrawTool}
          key={value}
        />
      ))}
      {/* 戻す */}
      <Button
        sx={styles.button}
        variant="text"
        startIcon={<Undo />}
        onClick={onClickUndo}
        disabled={disabledUndo}
        children={"戻す"}
      />
      {/* レイヤー表示切替 */}
      <Button
        sx={styles.button}
        variant="text"
        startIcon={showLayer ? <Layers /> : <LayersClear />}
        onClick={onClickLayer}
        children={showLayer ? "表示" : "非表示"}
      />
    </Stack>
  );
};

/**
 * 色選択ボタン
 */
const ColorButton: React.VFC<{
  color: ColorMode;
  selected: boolean;
  onClick: (v: ColorMode) => void;
}> = ({ color, selected, onClick }) => {
  return (
    <IconButton
      sx={{ p: 0.5 }}
      onClick={() => {
        onClick(color);
      }}
    >
      <Box
        sx={{
          bgcolor: selected ? Colors.MAIN_GREEN : "transparent",
          borderRadius: "50%",
          display: "flex",
          padding: "3px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Circle
          sx={{
            color: color,
            bgcolor: selected ? "#fff" : Colors.BORDER,
            borderRadius: "50%",
          }}
        />
      </Box>
    </IconButton>
  );
};

/**
 * 線の太さ選択ボタン
 */
const StrokeWidthButton: React.VFC<{
  width: StrokeWidthMode;
  selected: boolean;
  onClick: (v: StrokeWidthMode) => void;
}> = ({ width, selected, onClick }) => {
  const buttonText = useMemo(() => {
    if (width == "light") {
      return "細";
    } else if (width == "bold") {
      return "太";
    }
  }, [width]);

  return (
    <Button
      variant={selected ? "contained" : undefined}
      onClick={() => {
        onClick(width);
      }}
      children={buttonText}
    />
  );
};

/**
 * 描画モード選択ボタン
 */
const DrawToolButton: React.VFC<{
  drawTool: DrawToolMode;
  selected: boolean;
  onClick: (v: DrawToolMode) => void;
}> = ({ drawTool, selected, onClick }) => {
  const startIcon = useMemo(() => {
    let icon = null;
    switch (drawTool) {
      case "pen":
        icon = <Edit sx={{ color: Colors.MAIN_GREEN }} />;
        break;
      case "line":
        icon = <HorizontalRule sx={{ color: Colors.MAIN_GREEN }} />;
        break;
      case "text":
        icon = <TextFields sx={{ color: Colors.MAIN_GREEN }} />;
        break;
      case "rectangle":
        icon = <Rectangle sx={{ color: Colors.MAIN_GREEN }} />;
        break;
      case "ellipse":
        icon = <Circle sx={{ color: Colors.MAIN_GREEN }} />;
        break;
      case "eraser":
        icon = <Delete sx={{ color: Colors.MAIN_GREEN }} />;
        break;
    }
    return icon;
  }, [drawTool]);

  const buttonText = useMemo(() => {
    let button_text = "";
    switch (drawTool) {
      case "pen":
        button_text = "フリー";
        break;
      case "line":
        button_text = "直線";
        break;
      case "text":
        button_text = "文字";
        break;
      case "rectangle":
        button_text = "四角";
        break;
      case "ellipse":
        button_text = "円形";
        break;
      case "eraser":
        button_text = "削除";
        break;
    }
    return button_text;
  }, [drawTool]);

  return (
    <Button
      variant="text"
      startIcon={startIcon}
      sx={{
        ...styles.button,
        color: selected ? Colors.SECONDARY : undefined,
      }}
      onClick={() => {
        onClick(drawTool);
      }}
      children={buttonText}
    />
  );
};

export default DrawMenu;

const styles = {
  button: { justifyContent: "start" },
} as const;
