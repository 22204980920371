import { Avatar, AvatarProps } from "@mui/material";
import { Person } from "@mui/icons-material";

type TSize = "sm" | "md" | "lg" | number;
type CheckboxLabelProps = Omit<AvatarProps, "size"> & {
  size?: TSize;
};

const getSizePx = (size: TSize) => {
  if (typeof size === "number") return size;
  switch (size) {
    case "sm":
      return 24;
    case "md":
      return 40;
    case "lg":
      return 90;
  }
};

const AvatarRes = ({ size = "md", ...args }: CheckboxLabelProps) => {
  const { sx, ...props } = args;
  const size_avatar = getSizePx(size);
  const size_icon = size_avatar * 0.64;
  return (
    <Avatar sx={{ width: size_avatar, height: size_avatar, ...sx }} {...props}>
      {!props.src && (
        <Person sx={{ fontSize: size_icon + "px", color: "white" }} />
      )}
    </Avatar>
  );
};

export default AvatarRes;
