import React from "react";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import LabelRequired from "components/atoms/LabelRequired";
import { TemplateExcelDataInfo } from "services/models";
import { IRadioValue } from "@utils/template/excelTemplate";
import { labeltextBreak } from "@utils/index";

interface IRadioInputField {
  setting: TemplateExcelDataInfo;
  value: IRadioValue;
  onChange: (value: IRadioValue) => void;
}

const RadioInputField = ({ setting, value, onChange }: IRadioInputField) => {
  return (
    <FormControl>
      <InputLabel>
        <LabelRequired title={labeltextBreak(setting.name)} />
      </InputLabel>
      <RadioGroup
        name={"daily-radio-group-" + setting.coordinate}
        value={value?.index ?? ""}
        onChange={(_, value) => {
          if (setting.radio) {
            const selected = setting.radio[Number(value)];
            onChange({ index: Number(value), value: selected.text });
          }
        }}
      >
        {setting.radio?.map((item, index) => (
          <FormControlLabel
            value={index}
            label={item.label}
            componentsProps={{ typography: { sx: { wordBreak: "break-all" } } }}
            control={<Radio />}
            key={index}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioInputField;
