import { useRef } from "react";
import { Group, Line, Rect } from "react-konva";
import { DrawItem } from "services/models";
import { itemWidth } from "@shared-constants";

interface IProps {
  data: DrawItem;
  onClick: () => void;
  selected: boolean;
  onChange: (v: any) => void;
}

/**
 * 直線
 */
const DrawLine = ({ data, onClick, selected, onChange }: IProps) => {
  const shapeRef = useRef<any>();
  const anchor1Ref = useRef<any>();
  const anchor2Ref = useRef<any>();
  const groupRef = useRef<any>();

  const handleResizeStart = () => {
    const anchor1Pos = anchor1Ref.current?.getPosition(),
      anchor2Pos = anchor2Ref.current?.getPosition();
    const node = shapeRef.current;
    node.position({ x: anchor1Pos.x + 5, y: anchor1Pos.y + 5 });
    node.points([
      0,
      0,
      anchor2Pos.x - anchor1Pos.x,
      anchor2Pos.y - anchor1Pos.y,
    ]);
  };

  const handleResizeEnd = () => {
    const node = shapeRef.current;
    const nodePos = node.getPosition(),
      anchor2Pos = anchor2Ref.current?.getPosition();
    node.points([
      0,
      0,
      anchor2Pos.x - nodePos.x + 5,
      anchor2Pos.y - nodePos.y + 5,
    ]);
  };

  const updateDrawItem = () => {
    const node = shapeRef.current;
    onChange({
      x: node.x(),
      y: node.y(),
      points: node.points(),
    });
  };

  const handleDragEnd = () => {
    // ドラッグした位置へpointsを移動
    const node = shapeRef.current;
    const group = groupRef.current;
    onChange({
      x: node.x() + group.x(),
      y: node.y() + group.y(),
      points: node.points(),
    });
    group.setAttrs({
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    });
  };

  return (
    <Group
      ref={groupRef}
      draggable
      onDragStart={selected ? undefined : onClick}
      onDragEnd={handleDragEnd}
    >
      <Line
        ref={shapeRef}
        x={data.data?.x ?? 10}
        y={data.data?.y ?? 10}
        stroke={data.colorMode}
        strokeWidth={itemWidth[data.strokeWidthMode]}
        points={data.data?.points ?? [0, 0, 100, 0]}
        onClick={onClick}
      />
      {selected && (
        <>
          <Rect
            ref={anchor1Ref}
            x={data.data?.x ? data.data.x - 5 : 5}
            y={data.data?.y ? data.data.y - 5 : 5}
            width={10}
            height={10}
            stroke={"#08a4ff"}
            fill="white"
            strokeWidth={1}
            draggable
            onDragMove={handleResizeStart}
            onDragEnd={updateDrawItem}
          />
          <Rect
            ref={anchor2Ref}
            x={
              data.data?.x && data.data?.points
                ? data.data.x + data.data.points[2] - 5
                : 105
            }
            y={
              data.data?.y && data.data?.points
                ? data.data.y + data.data.points[3] - 5
                : 5
            }
            width={10}
            height={10}
            stroke={"#08a4ff"}
            fill="white"
            strokeWidth={1}
            draggable
            onDragMove={handleResizeEnd}
            onDragEnd={updateDrawItem}
          />
        </>
      )}
    </Group>
  );
};

export default DrawLine;
