import {
  apiGetChildrenGroup4GroupMaster,
  apiGetListGroupMaster,
  apiGetMemberByConditions,
} from "@api/flowApproval";
import { Box, Button, MenuItem, SelectChangeEvent } from "@mui/material";
import { Colors } from "@template/style";
import { getDataStorage, STORAGE } from "@utils/Storage";
import SelectLabel from "components/atoms/SelectLabel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ApproveState } from "store/reducer/ApproveFlowReducer";
import { TYPES } from "store/types";
type MasterGroupFilter = {
  masterGroupId: string;
  isDisable: boolean;
  options: Type.GroupInfo[];
};

export const FilterMemberArea: React.FC = () => {
  const approvalFlowState: ApproveState = useSelector(
    (state: RootState) => state.approveFlow,
  );
  const { textSearch, currentStepId, stepList } = approvalFlowState;
  useEffect(() => {
    dispatch({ type: TYPES.CLEAR_MEMBER_LIST_SEARCH });
  }, [textSearch]);
  const [masterGroups, setMasterGroups] = useState<Type.MasterGroup[]>([]);
  const [locationId, setLocationId] = useState<string>("");
  const [theLastSelectedOption, setTheLastSelectedOption] = useState({
    masterGroupIndex: -1,
    optionId: "",
  });
  const [optionsPerGroupMasterList, setOptionsPerGroupMasterList] = useState<
    MasterGroupFilter[]
  >([]);
  const handleSetValueToStates = async () => {
    const masterGroupsRes = await apiGetListGroupMaster();
    const numberOfGroups = masterGroupsRes.length;
    const optionsPerGroupMasterListAtFirstTime = [];
    const groupInfoDefault: Type.GroupInfo = {
      updated_at: "",
      group_master_id: "",
      created_at: "",
      created_by: "",
      group_parentid: "",
      location_id: "",
      SK: "defaultValue",
      PK: "",
      seq: -1,
      group_id: "",
      group_name: "未選択",
      isSelected: true,
    };
    if (numberOfGroups > 0) {
      const theFirstMasterGroup = masterGroupsRes[0];
      const targetId = theFirstMasterGroup.PK;
      const groupsInfo4MasterGroup = await apiGetChildrenGroup4GroupMaster({
        group_parentid: targetId,
      });
      const optionsOfTheFirstGroup = {
        masterGroupId: theFirstMasterGroup.SK,
        isDisable: false,
        options: [groupInfoDefault, ...groupsInfo4MasterGroup],
      };
      optionsPerGroupMasterListAtFirstTime.push(optionsOfTheFirstGroup);
    }
    for (let i = 1; i < numberOfGroups; i++) {
      const item = {
        masterGroupId: masterGroupsRes[i].SK,
        isDisable: true,
        options: [groupInfoDefault],
      };
      optionsPerGroupMasterListAtFirstTime.push(item);
    }
    const userInfo = JSON.parse(getDataStorage(STORAGE.USER_INFO));
    const targetLocationId = userInfo.location_id;
    setLocationId(targetLocationId);
    setMasterGroups(masterGroupsRes);
    setOptionsPerGroupMasterList(optionsPerGroupMasterListAtFirstTime);
  };
  useEffect(() => {
    handleSetValueToStates();
  }, []);
  const handleSelectOption = (
    e: SelectChangeEvent<string | number>,
    index: number,
  ) => {
    const value = e.target.value;
    if (index === 0) {
      let targetLocationId = "";
      if (value.toString() !== "defaultValue") {
        targetLocationId = value.toString();
      } else {
        const userInfo = JSON.parse(getDataStorage(STORAGE.USER_INFO));
        targetLocationId = userInfo.location_id;
      }
      setLocationId(targetLocationId);
    }
    setTheLastSelectedOption({
      masterGroupIndex: index,
      optionId: value.toString(),
    });
    dispatch({ type: TYPES.CLEAR_MEMBER_LIST_SEARCH });
  };

  const handleGetOptions4GroupMaster = useCallback(async () => {
    const masterGroupIndex = theLastSelectedOption.masterGroupIndex;
    if (masterGroupIndex !== -1) {
      const optionId = theLastSelectedOption.optionId;
      const groupsInfo4MasterGroup = await apiGetChildrenGroup4GroupMaster({
        group_parentid: optionId,
      });
      const clonedOptionsPerGroupMasterList = [...optionsPerGroupMasterList];
      clonedOptionsPerGroupMasterList[masterGroupIndex].options.forEach(
        (item) => {
          if (item.SK === optionId) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        },
      );
      const numOfMasterGroup = masterGroups.length;
      const groupInfoDefault: Type.GroupInfo = {
        updated_at: "",
        group_master_id: "",
        created_at: "",
        created_by: "",
        group_parentid: "",
        location_id: "",
        SK: "defaultValue",
        PK: "",
        seq: -1,
        group_id: "",
        group_name: "未選択",
        isSelected: false,
      };
      if (masterGroupIndex + 1 < numOfMasterGroup) {
        clonedOptionsPerGroupMasterList[masterGroupIndex + 1].options = [
          groupInfoDefault,
          ...groupsInfo4MasterGroup,
        ];
        clonedOptionsPerGroupMasterList[masterGroupIndex + 1].isDisable =
          optionId === "defaultValue";
      }
      for (let i = masterGroupIndex + 2; i < numOfMasterGroup; i++) {
        clonedOptionsPerGroupMasterList[i].isDisable = true;
        clonedOptionsPerGroupMasterList[i].options = [groupInfoDefault];
      }
      setOptionsPerGroupMasterList(clonedOptionsPerGroupMasterList);
    }
  }, [theLastSelectedOption]);

  useEffect(() => {
    handleGetOptions4GroupMaster();
  }, [handleGetOptions4GroupMaster]);

  const getSelectedOption = useCallback(
    (index: number) => {
      if (optionsPerGroupMasterList.length === 0) {
        return -1;
      }
      const option =
        optionsPerGroupMasterList[index].options.find(
          (item) => item.isSelected,
        ) || optionsPerGroupMasterList[index].options[0];
      return option.SK;
    },
    [optionsPerGroupMasterList],
  );
  const renderFilterArea = useMemo(() => {
    const filterArea: any = [];
    if (optionsPerGroupMasterList.length === 0) {
      return filterArea;
    }
    const numberOfMasterGroups = masterGroups.length;
    for (let i = 0; i < numberOfMasterGroups; i++) {
      const item = (
        <SelectLabel
          key={masterGroups[i].SK}
          label={masterGroups[i].group_master_name}
          sx={[styles.selectLabel, { mr: 2 }]}
          onChange={(e) => {
            handleSelectOption(e, i);
          }}
          value={getSelectedOption(i)}
          disabled={optionsPerGroupMasterList[i].isDisable}
          isIgnoreExtractRuleLabel={true}
          isIgnoreExtractRuleSelect={true}
        >
          {optionsPerGroupMasterList[i].options.map((option) => {
            return (
              <MenuItem key={option.SK} value={option.SK}>
                {option.SK != "defaultValue" ? (
                  <span className="IgnoreExtractRuleTarget">
                    {option.group_name}
                  </span>
                ) : (
                  <>{option.group_name}</>
                )}
              </MenuItem>
            );
          })}
        </SelectLabel>
      );
      filterArea.push(item);
    }
    return filterArea;
  }, [optionsPerGroupMasterList, masterGroups, theLastSelectedOption]);
  const dispatch = useDispatch();
  const handleStoreListMember = (members: Type.UserInfo[]) => {
    const linkedGroup: string[] = [];
    optionsPerGroupMasterList.forEach((item) => {
      for (let i = 0; i < item.options.length; i++) {
        if (
          item.options[i].isSelected &&
          item.options[i].SK !== "defaultValue"
        ) {
          linkedGroup.push(item.options[i].group_name);
          break;
        }
      }
    });
    const convertedMemberList = members.map((item) => {
      let targetLinkedGroup = "";
      const optionId = theLastSelectedOption.optionId;
      if (optionId === "" || optionId === "defaultValue") {
        const locationId = item.location_id;
        const parentGroup = optionsPerGroupMasterList[0].options.find(
          (item) => item.SK === locationId,
        );
        const prefixLinkedGroup = parentGroup ? parentGroup.group_name : "";
        targetLinkedGroup = `${prefixLinkedGroup} ${linkedGroup.join(" ")} ${
          item.path_group ?? ""
        }`;
      } else {
        targetLinkedGroup = `${linkedGroup.join(" ")} ${item.path_group ?? ""}`;
      }
      let SK = "";
      let PK = "";
      const memberId = `${item.user_id}|${item.group_id}`;
      const isExistApprover = stepList.find(
        (item) =>
          item.stepId === currentStepId &&
          item.approvers.find((approver) => {
            if (approver.memberId === memberId) {
              SK = approver.SK;
              PK = approver.PK;
              return true;
            }
            return false;
          }),
      );
      const convertedItem: Type.Member = {
        groupId: item.group_id,
        memberId: memberId,
        name: item.full_name,
        linkedGroup: targetLinkedGroup,
        isSelected: isExistApprover ? true : false,
        linkAvatar: item.avatar,
        SK,
        PK,
        isDisable: false,
      };
      return convertedItem;
    });
    const targetStep = stepList.find((item) => item.stepId === currentStepId);
    if (targetStep) {
      for (let i = 0; i < convertedMemberList.length - 1; i++) {
        for (let j = i + 1; j < convertedMemberList.length; j++) {
          if (
            convertedMemberList[i].isSelected &&
            convertedMemberList[i].memberId.split("|")[0] ===
              convertedMemberList[j].memberId.split("|")[0]
          ) {
            convertedMemberList[j].isDisable = true;
          }
        }
      }
      targetStep.currentSearch = convertedMemberList;
    }
    dispatch({
      type: TYPES.SET_MEMBER_LIST_SEARCH,
      payload: [...stepList],
    });
  };
  const handleSearchListMember = async () => {
    dispatch({
      type: TYPES.SET_CLICK_ON_SEARCH,
    });

    let optionId = "";
    if (theLastSelectedOption.optionId === "defaultValue") {
      if (theLastSelectedOption.masterGroupIndex > 0) {
        const selectedOption = optionsPerGroupMasterList[
          theLastSelectedOption.masterGroupIndex - 1
        ].options.find((item) => item.isSelected);
        optionId = selectedOption ? selectedOption.SK : "";
      }
    } else {
      optionId = theLastSelectedOption.optionId;
    }
    let memberListRes: Type.UserInfo[] = [];
    if (optionId) {
      const params = {
        sk_group: optionId === "defaultValue" ? masterGroups[0].PK : optionId,
        key_word: textSearch ? textSearch : undefined,
        location_id: locationId,
      };
      memberListRes = await apiGetMemberByConditions(params);
      handleStoreListMember(memberListRes);
    } else {
      const params = {
        sk_group: masterGroups[0].PK,
        key_word: textSearch ? textSearch : undefined,
        location_id: locationId,
      };
      memberListRes = await apiGetMemberByConditions(params);
    }
    handleStoreListMember(memberListRes);
  };
  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterArea}>{renderFilterArea}</Box>
      <Box sx={styles.searchBtnCustomPosition}>
        <Button sx={styles.searchBtnCustom} onClick={handleSearchListMember}>
          検索
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  card: { mb: 2 },
  title: { mt: 2, mb: 1 },
  selectLabel: { width: 300, height: 50 },
  button: { width: 150, ml: 1, height: 56 },
  formContent: {
    my: 2,
    mr: 2,
    p: 2,
    border: 2,
    borderRadius: 1,
    borderColor: Colors.MAIN_GREEN,
    position: "relative",
  },
  clearButton: { position: "absolute", right: 0, top: 0 },
  clearIcon: {
    color: "#fff",
    backgroundColor: Colors.TEXT,
    borderRadius: 50,
  },
  container: {
    my: 2,
    mx: 3,
  },
  searchBtnCustom: {
    backgroundColor: Colors.MAIN_GREEN,
    color: "white",
  },
  searchBtnCustomPosition: { display: "flex", justifyContent: "flex-end" },
  filterArea: {
    height: 110,
    overflow: "auto",
    whiteSpace: "nowrap",
    scrollbarWidth: 10,
    borderBottom: 1,
    borderBottomColor: Colors.BORDER,
    marginBottom: 2,
  },
} as const;
