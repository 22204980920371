import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GenericTemplate from "@template/index";
import { Work } from "services/models";
import { createWorkRejectApi } from "@api/work";
import { Validation } from "@validation";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import {
  createFormMutiplePhotoUpload,
  decodeBase64toArrayBuffer,
  getFileByUrl,
} from "@utils/index";
import { SelectImageFile } from "screens/CreateReport/FormContextReport/SelectFileReport";
import FormContextReportConfirm from "screens/WorkConfirmTlog/FormContextReportConfirm";
import styles from "screens/WorkConfirmTlog/FormContextReportConfirm/styles";
import HistoryRejectWork from "screens/HistoryRejectWork";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import { useDispatch } from "react-redux";
import { TYPES } from "store/types";
import { getMultipleS3UploadUrl } from "@api/template";

const WorkRejectTlogScreen: React.VFC = () => {
  const location = useLocation<any>();
  const data: Work | null = location.state?.data;
  const template: any = location.state?.template;
  const disableHistory: boolean = location.state?.disable;
  const mail_work_id: string = location.state?.mail_work_id ?? "";
  const [disabledUpload, setDisableUpload] = useState<boolean>(true);
  const [memo, setMemo] = useState<String>();
  const [selectedImages, setSelectedImages] = useState<Array<Object>>([]);
  const [selectedFiles, setSelectedFiles] = useState<Array<Object>>([]);
  const [fileDelete, setFileDelete] = useState([]);
  const [imageDelete, setImageDelete] = useState([]);
  const [messsageErr, setMessageErr] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: TYPES.SET_SELECTED_WORK_ID,
      selected_work_id: data?.SK,
    });
    window.scrollTo(0, 0);
  }, []);

  const handleCreateReject = async () => {
    try {
      if (!memo) return;
      LoadingOverlayController.show();
      const { uri, type, name, templateEditInfo } = template;
      const dataUpload = {
        memo: memo,
        work_template_data: JSON.stringify(templateEditInfo),
      };
      const formData = await createFormMutiplePhotoUpload(
        selectedImages,
        selectedFiles,
        dataUpload,
      );
      // if (uri) {
      //   formData.append("work_template_file", {
      //     uri: (Platform.OS === "android" ? "file://" : "") + uri,
      //     type: type,
      //     name: name,
      //   });
      // }
      var image_paths: any[] = [];
      var file_paths: any[] = [];
      // 添付画像アップロード
      if (selectedImages.length) {
        const formDataImages = new FormData();
        for (const image of selectedImages) {
          const item: any = image;
          if ("lastModifiedDate" in item) {
            formDataImages.append("files", item);
          } else {
            const f = await getFileByUrl(
              item.uri,
              item.fileName ? item.fileName : item.name,
              item.type,
            );
            formDataImages.append("files", f);
          }
        }
        formDataImages.append("object_type", "WORK");
        const response = await getMultipleS3UploadUrl(formDataImages);
        for (let index = 0; index < response.length; index++) {
          const item = response[index];
          const url = item.url;
          const base64 = item.file;
          const data = item.data;
          const arrayBuffer = decodeBase64toArrayBuffer(base64);
          // upload image
          await fetch(url, {
            method: "PUT",
            body: arrayBuffer,
            headers: {
              "Content-Type": "image/jpeg",
            },
          });
          image_paths.push({
            path_file: data?.path_file,
            path_file_thumb: data?.path_file_thumb,
          });
        }
      }
      // 添付ファイルアップロード
      if (selectedFiles.length) {
        const formDataFiles = new FormData();
        for (const file of selectedFiles) {
          const item: any = file;
          const fileUri = item?.fileCopyUri ?? item?.uri ?? "";
          item.uri = fileUri;
          if ("lastModifiedDate" in item) {
            formDataFiles.append("files", item);
          } else {
            const f = await getFileByUrl(
              fileUri,
              encodeURIComponent(item?.name ?? ""),
              item.type,
            );
            formDataFiles.append("files", f);
          }
        }
        formDataFiles.append("object_type", "WORK");
        const response = await getMultipleS3UploadUrl(formDataFiles);
        for (let index = 0; index < response.length; index++) {
          const item = response[index];
          const file: any = selectedFiles[index];
          const url = item.url;
          const base64 = item.file;
          const data = item.data;
          const arrayBuffer = decodeBase64toArrayBuffer(base64);
          // upload image
          await fetch(url, {
            method: "PUT",
            body: arrayBuffer,
            headers: {
              "Content-Type": file?.type,
            },
          });
          file_paths.push({
            path_file: data?.path_file,
            path_file_thumb: data?.path_file_thumb,
          });
        }
      }
      formData.append("file_paths", JSON.stringify(file_paths));
      formData.append("image_paths", JSON.stringify(image_paths));
      const params = {
        project_id: data?.PK,
        work_id: data?.SK,
      };
      const res = await createWorkRejectApi(formData, params);
      if (res) {
        ModalController.show({
          message: `報告を差し戻しました`,
          visibleButton2: true,
          handlePressButton2: () => {
            dispatch({
              type: TYPES.SET_SELECTED_WORK_ID,
              selected_work_id: "",
              selected_work_name: "",
            });
            setIsEdited(false);
            setTimeout(() => {
              if (mail_work_id !== "") {
                //トップページに遷移
                window.location.href = "/";
              } else {
                // 作業一覧に戻る
                window.history.go(-2);
              }
            }, 500);
          },
        });
      }
    } catch (error: any) {
      console.log("error", error);
      if (error?.detail?.code == 9) {
        ModalController.show({
          message: error?.detail?.message ?? "",
          visibleButton2: true,
        });
      } else
        ModalController.show({
          message: error?.response?.detail.message,
          visibleButton2: true,
        });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const createReject = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    await handleCreateReject();
  };

  useEffect(() => {
    if (memo) {
      setDisableUpload(false);
    } else setDisableUpload(true);
  }, [memo]);

  const checkMemo = () => {
    const message = Validation.validate({
      type: "text",
      name: "内容",
      value: memo,
      required: true,
    });
    const isError = message.length > 0;
    setMessageErr(message);
    return isError;
  };

  const hasUpdateData = () => {
    if (memo?.length || selectedFiles.length || selectedImages.length) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // 変更有無をチェック
    setIsEdited(hasUpdateData());
  }, [memo, selectedFiles, selectedImages]);

  // 保存確認ポップアップ
  const { setIsEdited } = useRouterPrompt({
    onOK: async () => {
      await createReject();
    },
  });

  return (
    <GenericTemplate title="差し戻し内容作成" showMenu={false}>
      <Stack>
        <Card>
          <CardHeader title="内容" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <TextField
                label="内容"
                multiline
                rows={3}
                inputProps={{
                  maxLength: 200,
                }}
                onChange={(e) => {
                  setMemo(e.target.value);
                }}
                onBlur={() => checkMemo()}
                placeholder="差し戻し内容を記入（最大200文字）"
                error={messsageErr.length > 0}
                helperText={messsageErr}
              />
            </FormGroup>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="添付" sx={styles.header} />
          <CardContent>
            <SelectImageFile
              selectedImages={selectedImages}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              setSelectedImages={setSelectedImages}
              setFileDelete={setFileDelete}
              setImageDelete={setImageDelete}
              fileDelete={fileDelete}
              imageDelete={imageDelete}
            />
          </CardContent>
        </Card>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            color="secondary"
            onClick={() => setOpen(true)}
            disabled={disableHistory}
            sx={{ width: 230 }}
          >
            差し戻し内容一覧
          </Button>
          <Button
            disabled={disabledUpload}
            sx={{ width: 230, ml: 2 }}
            onClick={() => {
              ModalController.show({
                message: "差し戻してもよろしいですか？",
                visibleButton1: true,
                visibleButton2: true,
                handlePressButton2: async () => {
                  await createReject();
                },
              });
            }}
          >
            OK
          </Button>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="h6" sx={{ px: 3 }}>
            作業内容
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>

        <FormContextReportConfirm
          data={data}
          template={template}
          editableTemplate={false}
        />
      </Stack>
      <HistoryRejectWork open={open} setOpen={setOpen} data={data} />
    </GenericTemplate>
  );
};

export default WorkRejectTlogScreen;
