const COMMON = {
  MSG_COMMON_TEXT_ERROR: "エラー",
  MSG_COMMON_TEXT_SUCCESS: "成功",
  MSG_COMMON_TEXT_WARN: "注意",
  MSG_COMMON_TEXT_INFO: "お知らせ",
  MSG_COMMON_TEXT_CLOSE: "閉じる",
  BUTTON: {
    CONFIRM: "はい",
    CANCEL: "いいえ",
    CONFIRM_DATE: "設定",
    CANCEL_002: "キャンセル",
    OK: "OK",
  },
  MSG_COMMON_SUCCESS_001: (name: string) => {
    return `${name}に成功しました`;
  },
  MSG_COMMON_ERROR_001: "予期せぬエラーが発生しました",
  MSG_COMMON_ERROR_002: (name: string) => {
    return `${name}の更新に失敗しました`;
  },
  MSG_COMMON_TEXT_001: "既読",
  MSG_COMMON_DELETE_CONFIRM_001: "選択されたものを削除しますか？",
  MSG_COMMON_DELETE_SUCCESS_001: (name: string) => {
    return `${name}を削除しました`;
  },
  MSG_COMMON_DELETE_CONFIRM_SORT_001:
    "並べ替えがリセットされますが、選択されたものを削除しますか？",
  MSG_COMMON_UPDATE_CONFIRM_SORT_001:
    "並べ替えがリセットされますが、編集してもよろしいですか？",
  MSG_COMMON_DISPLAY_CONFIRM_SORT_001:
    "並べ替えがリセットされますが、よろしいですか？",
  NETWORK_ERROR:
    "ネットワークに接続されていません\n接続を確認してもう一度お試しください",
  NETWORK_WARNING: "通信状態が不安定です",
  MSG_LOGOUT_MY_PROFILE_UPDATE:
    "アカウント情報を変更したため、ログアウトします",
  MSG_LOGOUT_USER_PROFILE_UPDATE:
    "アカウント情報が変更されたため、ログアウトします",
  MSG_LOGOUT_USER_DELETE: "アカウント情報が削除されたため、ログアウトします",
  MSG_LOGOUT_OTHER_LOGIN: "他端末からログインされたため、ログアウトします",
  MSG_CONFIRM_CONTRACT_LOGOUT: "この契約からログアウトしますか?",
  MSG_CONFIRM_DELETE: "削除しますか？",
  MSG_CONFIRM_GO_BACK: "内容が変更されています。保存しますか？",
  ERROR: {
    MSG_RQUIRED: (name: string) => `${name}を入力してください`,
    MSG_MIN_LENGTH: (name: string, len: number) =>
      `${name}は${len}文字以上で入力してください`,
    MSG_MAX_LENGTH: (name: string, len: number) =>
      `${name}は${len}文字以内で入力してください`,
    MSG_EMAIL: "正しいメールアドレスの形式ではありません",
    MSG_NUM: (name: string) => `${name}は数字のみで入力してください`,
    MSG_DATE: (name: string) => `${name}は日付の形式で入力してください`,
    MSG_TIME: (name: string) => `${name}は時刻の形式で入力してください`,
    MSG_ALPHA_NUM: (name: string) => `${name}は半角英数のみで入力してください`,
    MSG_ALPHA: (name: string) => `${name}は半角英字のみで入力してください`,
    MSG_DATE_RANGE: "日付の範囲が誤りです",
    MSG_PASSWORD: (name: string) =>
      `${name}にスペース、または利用できない記号が含まれています`,
    MSG_LOGIN_ID_EXISTS: "利用されているログインIDが入力されています",
    MSG_RQUIRED_SELECT: (name: string) => `${name}を選択してください`,
    MSG_EXISTING: (name: string) => `${name}が重複しています`,
  },
  MSG_NOT_EXIST: (text: string) => `${text}がありません`,
  MSG_NON_SEARCH_RESULT: (text: string) =>
    `「${text}」に一致する結果はありませんでした`,
  MSG_CONFIRM_DELETE_OK: `編集した内容が破棄されますが、画面を移動してもよろしいですか?`,
  MSG_ERROR_FILE_OPEN:
    "ファイルの読み取りに失敗しました\nもう一度ファイルを選択してください",
};

const RESET_PASSWORD = {
  MSG_ERROR_CONFIRM_PASSWORD: "新しいパスワードが一致しません",
};

const OTHER_SCREEN = {
  MSG_MEMBERS_MANAGEMENT_ERROR_ADD_BUTTON:
    "メンバー枠が不足しています\nサポートまたは担当者へご連絡頂き、メンバー枠追加をご相談ください",
  MSG_CONFIRM_RESET_PASSWORD:
    "パスワードをリセット（初期化）\nしてもよろしいですか？",
  MSG_CONFIRM_DELETE_ACCOUNT: "アカウントを削除しますか?",
  MSG_CONFIRM_DELETE_ACCOUNT_ATTENTION:
    "アカウントに関係する全データが\n削除され、ログインができなくなります",
  MSG_CONFIRM_DELETE_ACCOUNT_ACCEPT: "アカウントの削除に同意する",
  MSG_CONFIRM_DELETE_INVITE: "招待をキャンセルしてもよろしいですか？",
  MSG_BANNER_NOT_EXIST: "お知らせはありません",
};

const TEMPLATE = {
  MSG_TEMPLATE_EXIST: "同一帳票があります",
  MSG_TEMPLATE_DELETE_ALL_CONFIRM: "ゴミ箱を空にしますか？",
  MSG_TEMPLATE_RESTORE_ALL_CONFIRM: "全て復元しますか？",
  MSG_TEMPLATE_RESTORE_CONFIRM: "選択されたものを復元しますか？",
  MSG_DEFECTIVE_PRODUCT_MISMATCH:
    "不適合数と不適合内容の数量が一致していません",
  MSG_IDENTICAL_DEFECTIVE_PRODUCTS: "同一の不適合内容が選択されています",
  MSG_CONFIRM_TOTAL_MISMATCH:
    "合計数が予定搬入数と一致しない機種が存在しますが、作成完了してよろしいですか？",
  MSG_CONFIRM_CHANGE_COUNT_TYPE:
    "切り替えると入力済みの数量が消去されますが、切り替えてもよろしいですか？",
  MSG_CONFIRM_COPY_MASTER:
    "所属拠点以外の帳票を複製します\n複製元の帳票で利用しているユーザー作成マスタを複製しますか？",
  MSG_COPY_MASTER_DATA: "マスタに登録されているデータも同時に複製する",
  MSG_COMFIRM_DELETE_UNEXPECTED_ITEM:
    "予定外入力欄を削除してもよろしいですか？",
  MSG_CONFIRM_CANCEL_SIGNATURE:
    "入力内容を保存せずに閉じてよろしいでしょうか？",
  MSG_ERROR_GET_TEMPLATE:
    "帳票取得に失敗しました\nしばらく待って再度お試しください",
  MSG_CONFIRM_EMPTY_TAB: "空のタブは削除されます\n保存してよろしいですか？",
  MSG_TEMPLATE_EXCEL_DISP_STEP: "必須承認ステップと表示設定があっていません",
};

const WORK_ITEM = {
  MSG_CONFIRM_EMAIL_INSPECTION_FAILD: "異常申請しますか？",
  MSG_COMPLETE_GROUP_SUCCESS: "選択された作業を完了しました",
  MSG_DELETE_GROUP_SUCCESS: "選択された作業を削除しました",
  MSG_APPROVED_GROUP_SUCCESS: "選択された作業を承認しました",
  MSG_CONFIRM_COMPLETE_WORK: "選択された作業を完了しますか？",
  MSG_CONFIRM_DELETE_WORK: "選択された作業を削除しますか？",
  MSG_CONFIRM_FINISH_WORK: "作業を完了しますか？",
  MSG_CONFIRM_APPROVED_WORK: "選択された作業を承認しますか？",
  MSG_CREATE_SUCCESS: (name: string) => `報告「${name}」を作成しました`,
  MSG_CONFIRM_FINAL_APPROVED_WORK:
    "最終申請を行うと承認後に引継ぎ帳票対象外となります\n本当に申請してもよろしいですか？", // Tlog
  MSG_CANCEL_WORK: "キャンセルしますか？\n（キャンセルは取り消せません）", // Tlog
  MSG_REJECTION_WORK: "却下しますか？", // Tlog
  MSG_CONFIRM_REQUIRED_TEMPLATE: "申請するには帳票を作成してください", // Tlog
  MSG_APPROVED_LIMIT: (count: string | number) =>
    `一括承認は${count}件まで選択可能です`, // Tlog
  MSG_ERROR_APPROVED_LIMIT:
    "処理時間が長引いたため、一部承認ができませんでした\nもう一度一括承認を行ってください", // Tlog
  MSG_ERROR_NOT_LIST_APPROVED:
    "選択可能な承認フローが存在しないため、一時保存・申請はできません", // Tlog
  MSG_DEVAN_IS_DELIVERED: "選択されたデバン予定は搬入済みです",
};

const ManageFolder = {
  MSG_CAN_NOT_CREATE_MORE_FOLDER:
    "フォルダはこれ以上作成できません（3階層まで）",
  MSG_CREATE_FOLDER_SUCCESS: "フォルダを作成しました",
  MSG_UPLOAD_IMG_SUCCESS: "フォルダに画像を追加しました",
  MSG_UPLOAD_FILE_SUCCESS: "フォルダにファイルを追加しました",
  MSG_SELECT_LIMIT_10: "ファイルは最大10まで添付できます",
  MSG_SELECT_LIMIT: (count: string | number) =>
    `最大${count}ファイルまで添付できます`,
  MSG_SECLECT_LIMIT_30MB: "既定の容量（1ファイル 30MB）を超えています",
  MSG_SELECT_FILE_NOT_AVAILABLE:
    "選択されたファイルはゲンバスターでは利用できません",
  MSG_FOLDER_CAN_NOT_BE_MOVE_OR_SHARE:
    "フォルダの移動・共有はできません。選択を外してください",
  MSG_FOLDER_DATA_IN_FOLDER_WILL_BE_DELETED:
    "フォルダは格納データも全て削除されます",
  MSG_FOLDER_DO_YOU_WANT_TO_DELETE: "選択されたものを全て削除しますか？",
  MSG_DELETE_SUCCESS: "選択物を削除しました",
  MSG_MOVE_SUCCESS: "選択物を移動しました",
  MSG_FILE_NOT_EXIST: (fileName: string) => {
    return `「${fileName}」は削除されたファイルです`;
  },
  MSG_FOLDER_NAME_NOT_ALL_SPACE: "空白だけのフォルダ名は使用できません",
  MSG_ERROR_FILE_WAS_DELETED_BEFORE: (fileName: string) => {
    return `「${fileName}」は削除されたファイルです`;
  },
  MSG_ERROR_CREATEDOCUMENT_MISSING_DOCUMENTNAME: "資料名を入力してください",
  MSG_ERROR_CREATEDOCUMENT_MISSING_DATE: "作成日を選択してください",
  MSG_ERROR_CREATEDOCUMENT_MISSING_USER: "作成者を入力してください",
  MSG_ERROR_OTHER_USER_DELETED_SELECTED_FILE_BEFORE:
    "選択された格納物を他のユーザーが削除しました",
  UPLOAD_FILE_NAME_FAIL: (fileName: string) => {
    return `「${fileName}」のアップロードに失敗しました`;
  },
  MSG_ERROR_CONTAIN_SPECIAL_CHARACTER: `資料名に以下の文字は使用できません\n
  \ / : * ? " < > |`,
  MSG_ERROR_FOLDER_CONTAIN_SPECIAL_CHARACTER: `フォルダ名に以下の文字は使用できません\n
  \ / : * ? " < > |`,
};
const PROJECT = {
  MSG_CREATE_SUCCESS: (name: string) => `帳票「${name}」を作成しました`,
  MSG_UPDATE_SUCCESS: (name: string) => `帳票「${name}」を更新しました`,
  MSG_ONLY_END_DATE: "期間は終了日のみの入力はできません",
  MSG_MAX_FAVORITE: `お気に入り数が上限に達しているため登録できません`,
  MSG_PROJECT_DELETD: "選択された帳票は削除されました",
  MSG_HANDOVER_PROJECT_NOT_EXIST: "引継ぎできる帳票がありません",
  MSG_PROJECT_IS_INVALID: "選択された帳票は対応していません",
};

const ManageScreen = {
  MSG_UNSAVE_CHANGE: "変更を保存しますか？",
  MSG_SAVE_NOTICE_EMAIL_SUCCESS: "メール通知を保存しました",
  MSG_SAVE_FLOW_SUCCESS: "承認フローを保存しました",
  MSG_TITLE_REQUIRED: "題名を入力してください",
  MSG_CONTENT_REQUIRED: "本文を入力してください",
  MSG_PLEASE_INPUT_ITEM_REQUIRED: "必須項目に入力してください",
  MSG_CONFIRM_DELETE_APPROVER_FIELD: "承認者欄を削除しますか？",
  MSG_AT_LEAST_1_COLUMNS_ARE_SELECTED: "出力データを1列以上選択してください",
  MSG_SAVE_NOTICE_CSV_SUCCESS: "出力データを保存しました",
  MSG_DUPLICATED_POSITION: "職位が重複しています",
  MSG_NO_ITEM: "該当するアカウントは存在しません",
  MSG_DUPLICATE_FLOW_NAME: "存在する承認フロー名のため利用できません",
  MSG_CONFIRM_DELETE_STEP: "承認ステップを削除しますか？",
  MSG_GREATER_THAN_MAX_APPROVERS:
    "拠点に所属するアカウント数以上の値は設定できません",
};

const ACCOUNT = {
  MSG_NON_VALID_ACCOUNT: "選択可能な担当が存在しません",
  MSG_ERROR_IF_ITEM_SELECTED_DUPLICATE: "所属グループが重複しています",
};

const PREVIEW = {
  MSG_ERROR: "PDFファイルの読み込みに失敗しました",
  MSG_NODATA: "PDFファイルを選択してください",
  MSG_NOPDF: "PDFファイルがありません",
};

const DEVAN = {
  MSG_UPDATE_DEVAN_LIST: `登録済みのデバン予定が変更されますが、よろしいですか?`,
  MSG_NOT_EXIST_DEVAN_PLAN: "取込対象日のデバン予定がありません",
  MSG_ERROR_DEVAN_PLAN:
    "デバン予定として認識できない明細が存在するため取り込めません\nデバン予定の内容をご確認ください",
  MSG_ERROR_FILE: "デバン予定表以外のファイルは取り込めません",
  MSG_MISMATCHED_VALUE: (container_no: string) =>
    `コンテナ番号「${container_no}」の情報が揃っていません\nデバン予定の内容をご確認ください`,
  MSG_DELETE_SELECT_DEVAN_PLAN: "選択中のデバン予定を削除しますか？",
  MSG_CLEAR_SEARCH: "検索結果をクリアしますか？",
  MSG_CONFIRM_SAVE:
    "搬入年月日、コンテナ番号が同一の明細も変更されますがよろしいですか？",
};

const MASTER = {
  MSG_ERROR_CSV: "正しいCSVの形式ではありません",
  MSG_ERROR_BARCODE_LIMIT_1: "バーコード検索する項目は1つだけ選択できます",
  MSG_ERROR_KEYITEM_LIMIT_1: "キー項目は1つだけ選択できます",
};

const messages = {
  COMMON,
  RESET_PASSWORD,
  OTHER_SCREEN,
  PROJECT,
  WORK_ITEM,
  ManageFolder,
  ManageScreen,
  ACCOUNT,
  PREVIEW,
  DEVAN,
  TEMPLATE,
  MASTER,
};

export default messages;
