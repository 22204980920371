import { useEffect, useMemo, useState, VFC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Box, Grid, Typography } from "@mui/material";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import GenericTemplate from "@template/index";
import { getHandoverWorkList } from "@api/work";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ScrollLoader from "components/atoms/ScrollLoader";
import FilterInput from "components/molecules/FilterInput";
import { LIST_TYPE_SORT_WORK, STATUS_WORK_TLOG } from "@shared-constants";
import messages from "config/messages";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import { Work } from "services/models";
import { getSortOfScreen } from "selector/sortSelector";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import { getParamsFilter, getUserInfo } from "@utils/index";
import { useLocation } from "react-router-dom";
import WorkItemTlog from "components/molecules/WorkItem/WorkItemTlog";

let paramsFilter: any;

const HandoverReportListScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------

  const location = useLocation<any>();
  const template_id = location.state?.template_id;
  const project_id = location.state?.project_id;
  const project_location_id = location.state?.project_location_id;
  const template_project_id = location.state?.template_project_id;

  // const work_type = location.state?.work_type;
  // const title = location.state?.title;
  // const type = location.state?.type;

  const { selected_project_id, selected_project_name } = useSelector(
    (state: RootState) => state.projectInfo,
  );
  const [data, setData] = useState([]);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [PK, setPK] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const workFilter = useSelector(getConditionFilter("handover_report"));
  const typeSort = useSelector(getSortOfScreen("handover_report"));
  const [searchedKeyword, setSearchKeyword] = useState<string>("");

  const history = useHistoryCustom();
  const dispatch = useDispatch();

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchListWorkItem = async (
    keyWord?: string,
    sortType?: string,
    lastKeyword?: string,
    sort_by?: string,
    isMerge: boolean = false,
    pk?: string,
  ) => {
    setLoading(true);
    if (data.length === 0) {
      LoadingOverlayController.show();
    }
    let params = {
      SK: project_id,
      template_id: template_id,
      project_location_id: project_location_id,
      keyword: keyWord ?? searchedKeyword,
      sort_type: sortType ?? typeSort,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy,
      pk: pk ?? PK,
      filter_condition: paramsFilter,
    };

    await getHandoverWorkList(params)
      .then((res: any) => {
        if (res?.data.length === 0 && !res?.lastKey) {
          setLoadMore(false);
        }

        if (isMerge) {
          setData(data.concat(res?.data));
        } else {
          setData(res?.data);
        }
        if (res?.last_key) {
          setLastKey(res?.last_key);
          setSortBy(res?.last_key?.sort_by ?? null);
          setPK(res?.last_key?.PK ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setPK(null);
          setLoadMore(false);
        }
        if (res?.length < 10 && res.last_key) {
          fetchListWorkItem(
            params.keyword,
            params.sort_type,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
            res?.last_key?.PK,
          );
        }
        if (!res?.last_key || (res.data.length >= 10 && res.last_key)) {
          LoadingOverlayController.hide();
        }
      })
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && data.length > 0;
  }, [data, loadMore, lastKey]);

  useEffect(() => {
    paramsFilter = getParamsFilter(workFilter);
    fetchListWorkItem();
  }, []);

  const handleOnClick = (data: any) => {
    data.work_status = STATUS_WORK_TLOG.DRAFT;
    data.project_id = project_id;
    data.template_project_id = template_project_id;
    history.pushWithRef("/work/create", {
      data: data,
      is_from_handover_report: true,
    });
  };

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy(null);
    }
    if (lastKey) {
      setLastKey(null);
    }
    if (PK) {
      setPK(null);
    }
    paramsFilter = getParamsFilter(workFilter);
  }, [workFilter]);

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (str: string) => {
    setSearchKeyword(str);
    setLoadMore(true);
    setLastKey(null);
    setSortBy(null);
    setPK(null);
    LoadingOverlayController.show();
    fetchListWorkItem(str, undefined, "", "");
  };

  return (
    <GenericTemplate
      title={"引継ぎ帳票選択"}
      isSearch={true}
      searchPlaceholder="帳票番号で検索"
      onSearch={(v) => _handleSearch(v)}
      onSearchClear={() => _handleSearch("")}
    >
      <Box width={{ xs: "100%", md: "50%" }} ml={"auto"}>
        {/* =================== 絞り込み =================== */}
        <FilterInput
          typeScreen="handover_report"
          onFetchData={() => {
            LoadingOverlayController.show();
            fetchListWorkItem(undefined, undefined, "", "");
          }}
        />
      </Box>

      {/* =================== 作業 =================== */}
      <InfiniteScroll
        loadMore={() => {
          if (loadMore && lastKey && data.length > 0 && !loading) {
            fetchListWorkItem(undefined, undefined, lastKey, undefined, true);
          }
        }}
        hasMore={showLoadMore}
        loader={<ScrollLoader key={0} />}
      >
        <Grid container spacing={3} sx={{ pt: 2, pb: 5 }}>
          {data.map((d: Work, i) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={i}>
                <WorkItemTlog
                  data={d}
                  onClick={(d) => {
                    handleOnClick(d);
                  }}
                  showPrinted={false}
                  key={i}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {data.length === 0 && !loading && (
        <Typography>
          {searchedKeyword === ""
            ? messages.COMMON.MSG_NOT_EXIST("作業")
            : messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)}
        </Typography>
      )}
    </GenericTemplate>
  );
};

export default HandoverReportListScreen;
