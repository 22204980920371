import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import PopupSort from "components/atoms/PopupSort";
import { LIST_TYPE_SORT_TEMPLATE } from "@shared-constants";
import { Template } from "services/models";
import { getListTemplate } from "@api/template";
import ModalController from "@shared-components/modal/ModalController";
import TemplateItem from "./TemplateItem";
import { useDispatch, useSelector } from "react-redux";
import { getSortOfScreen } from "selector/sortSelector";
import { TYPES } from "store/types";
import FullScreenDialog from "components/atoms/FullScreenDialog";
import { STORAGE, storeData } from "@utils/Storage";

type IDataType = "TEMPLATE_WORK" | "TEMPLATE_LEDGER" | "TEMPLATE_BLACKBOARD";

type ITypeScreen = "REPORT";

interface IProps {
  open: boolean;
  setOpen: Function;
  templateType: IDataType;
  onChooseTemplate?: Function;
  typeScreen?: ITypeScreen;
  templateId?: string;
  //   isFromFolderCreate?:boolean
}

const ModalListTemplate = ({
  open,
  setOpen,
  templateType,
  onChooseTemplate = () => {},
  typeScreen = "REPORT",
  templateId = "",
}: IProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [listTemplate, setListTemplate] = useState<Array<Template>>([]);
  const typeSort = useSelector(getSortOfScreen("template"));
  const dispatch = useDispatch();

  const getParams = (template_type: string, _sortType?: string) => {
    let filter_condition: string = "";
    let sort_type = "last_use_sort";
    if (typeScreen) {
      filter_condition = typeScreen;
    }
    if (!_sortType) {
      if (typeSort !== "last_use_sort") {
        sort_type = typeSort;
      }
    }
    if (_sortType) {
      sort_type = _sortType;
    }
    let params = {
      template_type,
      sort_type: sort_type,
      filter_condition,
    };

    return params;
  };

  const fetchListTemplate = async (sortType?: string) => {
    if (!isLoading) {
      setLoading(true);
    }

    const successCallback = (res: any) => {
      setListTemplate(res?.data);
    };
    const errorCallback = (error: any) => {
      ModalController.show({
        message: error?.response?.detail?.message || "内部サーバーエラー",
        visibleButton2: true,
      });
    };
    const finallyCallback = () => {
      setLoading(false);
    };
    try {
      await getListTemplate(getParams(templateType, sortType))
        .then((res) => successCallback(res))
        .catch((error) => errorCallback(error))
        .finally(() => finallyCallback());
    } catch (error: any) {
      console.log("error get list template", error?.response?.detail?.message);
    }
  };

  useEffect(() => {
    if (open) fetchListTemplate();
  }, [open]);

  const onClickItem = (item: Template) => {
    // if (isFromFolderCreate && screenIdSupport[item?.screen_id]) {
    //   NavigationService.navigate(screenIdSupport[item?.screen_id], {
    //     template_path: item?.template_path ?? "",
    //     template_type: item.template_type,
    //     isCreateDocument: true,
    //     screenId: item.screen_id,
    //   });
    //   return;
    // }
    if (item && onChooseTemplate) {
      onChooseTemplate(item);
      handleClose();
    }
  };

  const onClickGlass = (item: Template) => {
    if (item) {
      //別タブで開く
      const data = {
        type: "url",
        html: item?.template_preview_path,
      };
      storeData(STORAGE.PREVIEW_DATA, JSON.stringify(data));
      window.open("/preview");
    }
  };

  const handleSort = (type_sort: string) => {
    const payload = {
      type_screen: "template",
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchListTemplate(type_sort);
  };

  const sortDefaultIndex = useMemo(
    () => {
      const LIST_SORT_TEMPLATE = LIST_TYPE_SORT_TEMPLATE[templateType];
      const defaultSort = Object.values(LIST_SORT_TEMPLATE).filter(
        (item) => item.value === typeSort,
      );

      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_SORT_TEMPLATE).indexOf(defaultSort[0]);
      }
    },
    [open] /* 画面初期表示時のみ判定 */,
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FullScreenDialog
      open={open}
      onClose={handleClose}
      title={"テンプレート選択"}
    >
      <Typography>テンプレートを選択してください</Typography>

      <PopupSort
        data={Object.values(LIST_TYPE_SORT_TEMPLATE[templateType]).map(
          (item) => {
            return {
              title: item.title,
              onClick: () => handleSort(item.value),
            };
          },
        )}
        defaultIndex={sortDefaultIndex}
        disabled={listTemplate.length === 0}
      />

      <Grid container spacing={3} sx={{ pb: 5 }}>
        {listTemplate.map((item: Template) => {
          return (
            <Grid item xs={6} md={3} lg={3} key={item?.PK}>
              <TemplateItem
                selectedTemplateId={templateId}
                data={item}
                onClick={() => onClickItem(item)}
                onClickGlass={() => onClickGlass(item)}
              />
            </Grid>
          );
        })}
      </Grid>

      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ bgcolor: "white", borderRadius: 1 }}>
          <Button onClick={handleClose} variant="outlined">
            キャンセル
          </Button>
        </Box>
      </Box>
    </FullScreenDialog>
  );
};
export default ModalListTemplate;
