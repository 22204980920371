type size = "Byte" | "KB" | "MB" | "GB";
type digitsRange = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
interface Props {
  val: number;
  from: size;
  to: size;
  digits?: digitsRange;
}
const FILE_SIZE_UNIT: Array<size> = ["Byte", "KB", "MB", "GB"];

export const formatBytes = ({ val, from, to, digits = 1 }: Props) => {
  const k = 1024;
  let val_tmp = val;
  let round = Math.pow(10, digits);

  if (to !== from) {
    const from_index = FILE_SIZE_UNIT.indexOf(from);
    const to_index = FILE_SIZE_UNIT.indexOf(to);

    if (from_index > to_index) {
      val_tmp = val_tmp * Math.pow(k, from_index - to_index);
    } else {
      val_tmp = val_tmp / Math.pow(k, to_index - from_index);
    }
  }

  return Number((Math.floor(val_tmp * round) / round).toFixed(digits));
};
