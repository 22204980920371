import { getColorData } from "@api/color";
import { STORAGE, storeData } from "@utils/Storage";

export function* setColorSage(action: any): any {
  /*
        マスタのカラーを内部に保持する関数
    */
  try {
    var data = yield getColorData();
    storeData(STORAGE.COLOR_DATA, JSON.stringify(data?.data));
  } catch (error) {
    console.log(error);
  }
}
