import { useEffect, useState, useMemo, VFC } from "react";
import GenericTemplate from "@template/index";
import { Grid } from "@mui/material";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getListFolderFileApi } from "@api/manageFolder";
import FolderFileItem from "components/molecules/FolderFileItem";
import PopupSort from "components/atoms/PopupSort";
import { LIST_TYPE_SORT_FOLDER } from "@shared-constants";
import { getSortOfScreen } from "selector/sortSelector";
import { TYPES } from "store/types";

export type DataFolderAndFile = {
  LastEvaluatedKeyOldDate: {
    PK: string;
    SK: string;
    created_at: string;
  };
  flag_file_exits_folder: boolean;
  list_files: any[];
  list_folders: any[];
  total: number;
  total_inside: number;
};

export type DataFolder = {
  PK: string;
  SK: string;
  created_at: string;
  creator_id: string;
  deleted_at: string;
  folder_id: string;
  folder_inside: any[];
  folder_name: string;
  is_delete: boolean;
  is_user_free_folder?: boolean;
  total_inside: number;
  updated_at: string;
};

export const defaultFolder = {
  PK: "",
  SK: "",
  created_at: "",
  creator_id: "",
  deleted_at: "",
  folder_id: "",
  folder_inside: [],
  folder_name: "",
  is_delete: false,
  is_user_free_folder: false,
  total_inside: 0,
  updated_at: "",
};

export type DataFile = {
  PK: string;
  SK: string;
  blackboard_info: any | null;
  category_id: any | null;
  contract_id: string;
  created_at: string;
  creator_id: string;
  exif_info: any | null;
  file_name: string;
  file_name_filter: string;
  file_size: number;
  file_type: string;
  latitude: string | null;
  longitude: string | null;
  order_category_last_update: string | null;
  path_file: string;
  path_file_thumb: string | null;
  work_status: string | null;
};

export const defaultData = {
  LastEvaluatedKeyOldDate: {
    PK: "",
    SK: "",
    created_at: "",
  },
  flag_file_exits_folder: false,
  list_files: [],
  list_folders: [],
  total: 0,
  total_inside: 0,
};

export type LastKeyType = {
  PK: string;
  SK: string;
  created_at: string;
  updated_at: string;
  folder_name: string;
};

const ManageFolderScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const { selected_project_id } = useSelector(
    (state: RootState) => state.projectInfo,
  );
  const [data, setData] = useState([]);
  const [title_btn_sort, setTitleBtnSort] = useState<string>("default");
  const typeSort = useSelector(getSortOfScreen("folder"));
  const history = useHistoryCustom();
  const dispatch = useDispatch();

  const buttonSortHandle = async (title_btn_sort: string) => {
    setTitleBtnSort(title_btn_sort);
    const payload = {
      type_screen: "folder",
      value: title_btn_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchdata(title_btn_sort);
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_FOLDER.TOP).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_FOLDER.TOP).indexOf(defaultSort[0]);
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  const handleOnClick = (folderInfo: DataFolder) => {
    let folderLevel = { currentLevel: 1, folderID: { level1: folderInfo } };
    dispatch({
      type: TYPES.SET_FOLDERID_LEVEL,
      payload: folderLevel,
    });
    history.pushWithRef("/folder/detail");
  };

  useEffect(() => {
    fetchdata();
  }, [selected_project_id]);

  const fetchdata = async (sort_by?: string) => {
    LoadingOverlayController.show();
    var sort_key = sort_by ?? typeSort;
    return await getListFolderFileApi(
      selected_project_id.split("#")[2],
      sort_key,
    )
      .then((res: any) => {
        const combinedFolderFile = res.list_folders.concat(res.list_files);
        // console.log(combinedFolderFile);
        setData(combinedFolderFile);
      })
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  return (
    <GenericTemplate title="資料一覧" projectMenu="folder">
      <PopupSort
        data={Object.values(LIST_TYPE_SORT_FOLDER.TOP).map((item) => {
          return {
            title: item.title,
            onClick: () => {
              buttonSortHandle(item.value);
            },
          };
        })}
        defaultIndex={sortDefaultIndex}
      />

      <Grid container spacing={3} sx={{ pt: 2 }}>
        {data.map((d: any, i) => {
          return (
            <Grid item xs={12} md={4} lg={4} key={i}>
              <FolderFileItem
                data={d}
                onClick={(d) => {
                  handleOnClick(d);
                }}
                isListDisplay={true}
              />
            </Grid>
          );
        })}
      </Grid>
    </GenericTemplate>
  );
};

export default ManageFolderScreen;
