import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GenericTemplate from "@template/index";
import PopupMenu from "components/atoms/PopupMenu";
import PopupSort from "components/atoms/PopupSort";
import {
  HeadCell,
  TableButton,
  TableInfiniteScroll,
} from "components/organisms/TableInfiniteScroll";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { LIST_TYPE_SORT_CUSTOM_TEMPLATE_TRASH } from "@shared-constants";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { TYPES } from "store/types";
import messages from "config/messages";
import { getSortOfScreen } from "selector/sortSelector";
import {
  getParamsFilter,
  getUserInfo,
  isAuthorizedDeleteCustomTemplate,
} from "@utils/index";
import { RootState } from "store/reducer";
import FilterInput from "components/molecules/FilterInput";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import {
  deleteProject,
  getListProjectUserMade,
  getListUserCompanyApi,
  updateRestoreProject,
} from "@api/project";
import { getListActivityBase } from "@api/groupMaster";
import ModalController from "@shared-components/modal/ModalController";

let paramsFilter: any;

const headCells: HeadCell[] = [
  {
    id: "is_corp_standard_disp",
    label: "全社",
    width: 60,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "report_code_name",
    label: "帳票分類",
    width: 200,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "project_name",
    label: "帳票名",
    width: 200,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "activity_base_name",
    label: "拠点",
    width: 100,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "created_user_name",
    label: "作成者",
    width: 130,
    isIgnoreExtractRuleCell: true,
  },
  {
    id: "last_used_at",
    label: "最終利用日時",
    width: 155,
    disablePadding: true,
    isIgnoreExtractRuleCell: true,
    format: "YMDHms_sl",
  },
  {
    id: "deleted_at",
    label: "削除日時",
    width: 155,
    disablePadding: true,
    isIgnoreExtractRuleCell: true,
    format: "YMDHms_sl",
  },
  {
    id: "detail",
    label: "",
    width: 80,
    isIgnoreExtractRuleCell: true,
    disablePadding: true,
  },
];

const DELETE_BUTTON_TITLE = {
  all: "ゴミ箱を空にする",
  selected: "削除",
} as const;

const RESTORE_BUTTON_TITLE = {
  all: "全て復元する",
  selected: "復元",
} as const;

const CustomTemplateTrachScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonList, setButtonList] = useState<TableButton[]>([]);

  const TYPE_SCREEN = "custom_template_trash";
  const hasCondition = useSelector(getHasFilterConditionProject(TYPE_SCREEN));
  const templateFilter = useSelector(getConditionFilter(TYPE_SCREEN));
  const typeSort = useSelector(getSortOfScreen(TYPE_SCREEN));
  const [isDisableFilter, setIsDisableFilter] = useState<boolean>(false);

  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const check_list = useSelector(
    (state: RootState) => state.tableCustom.check_list,
  );

  const refListGroup = useRef<any[]>();
  const refListUser = useRef<any[]>();

  const user_info = getUserInfo();

  const history = useHistoryCustom();
  const dispatch = useDispatch();

  const DeleteButtonTitle = useMemo(() => {
    return check_list.length > 0
      ? DELETE_BUTTON_TITLE.selected
      : DELETE_BUTTON_TITLE.all;
  }, [check_list]);
  const RestoreButtonTitle = useMemo(() => {
    return check_list.length > 0
      ? RESTORE_BUTTON_TITLE.selected
      : RESTORE_BUTTON_TITLE.all;
  }, [check_list]);

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchListTemplate = async (
    keyWord?: string,
    sortType?: string,
    lastKeyword?: string,
    sort_by?: string,
    isMerge: boolean = false,
  ) => {
    setLoading(true);
    if (data.length === 0) {
      LoadingOverlayController.show();
    }

    // マスタ情報取得
    if (!refListGroup.current) {
      await getListGroup();
    }
    if (!refListUser.current) {
      await getListUser();
    }

    const params = {
      keyword: "",
      sort_type: sortType ?? typeSort,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy ?? undefined,
      filter_condition: paramsFilter,
      is_deleted: true,
    };

    return await getListProjectUserMade(params)
      .then((res) => {
        const res_data = addDisplayData(res?.data);
        if (isMerge) {
          setData((preValue) => [...preValue, ...res_data]);
        } else {
          setData(res_data);
        }
        if (res?.last_key) {
          setLastKey(res?.last_key?.SK ?? null);
          setSortBy(res?.last_key?.sort_by ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setLoadMore(false);
        }
        if (res?.data.length < 10 && res?.last_key) {
          fetchListTemplate(
            params.keyword,
            params?.sort_type,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
          );
        }
        if (!res?.last_key || (res.data.length >= 10 && res.last_key)) {
          LoadingOverlayController.hide();
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  const getListGroup = useCallback(async () => {
    await getListActivityBase()
      .then((res) => {
        if (res) {
          refListGroup.current = res;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refListGroup]);

  const getListUser = useCallback(async () => {
    const params = {
      keyword: "",
      location_id: "",
      ignore_me: false,
    };
    await getListUserCompanyApi(params)
      .then((res) => {
        if (res?.data) {
          refListUser.current = res.data;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [refListUser]);

  const getActivityBaseName = useCallback(
    (location_id?: string) => {
      if (!location_id || !refListGroup.current) return "";
      const group = refListGroup.current.find((item) => item.SK == location_id);
      if (group) {
        return group.activity_base_name;
      } else {
        return "";
      }
    },
    [refListGroup],
  );

  const getUserFullName = useCallback(
    (user_id?: string) => {
      if (!user_id || !refListUser.current) return "";
      const user = refListUser.current.find(
        (item) => item.SK == user_id || item.SK == "USER#" + user_id,
      );
      if (user) {
        return user.full_name;
      } else {
        return "";
      }
    },
    [refListUser],
  );

  const addDisplayData = useCallback(
    (data?: any[]) => {
      if (!data) return [];

      var response: any[] = [];
      data.forEach((item) => {
        // 参照可否
        if (
          isAuthorizedDeleteCustomTemplate(
            user_info.user_role,
            user_info.location_id,
            item.project_location_id,
          )
        ) {
          response.push({
            ...item,
            is_corp_standard_disp: item.is_corp_standard ? "○" : "",
            report_code_name:
              item.report_type_code +
              item.report_number +
              ":" +
              item.report_name,
            activity_base_name: getActivityBaseName(item.project_location_id),
            created_user_name: getUserFullName(item.created_by),
          });
        }
      });
      return response;
    },
    [getActivityBaseName, getUserFullName],
  );

  useEffect(() => {
    const list_button: TableButton[] = [];
    data.forEach((d) => {
      list_button.push({
        cell_id: "detail",
        button: (
          <Button
            size="small"
            sx={{ minWidth: "60px" }}
            onClick={handleClickDetail(d)}
          >
            詳細
          </Button>
        ),
        id: d.SK,
      });
    });
    setButtonList(list_button);
    setDisabledButton(data.length == 0);
  }, [data]);

  useEffect(() => {
    dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
    fetchListTemplate();
  }, []);

  const handleClickDetail = (data: any) => (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch({
      type: TYPES.SET_SELECTED_PROJECT_ID,
      selected_project_id: data.SK,
      selected_project_name: data.project_name,
    });
    history.pushWithRef("/project/view");
  };

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!data.length && !hasCondition) {
      setIsDisableFilter(true);
    } else {
      setIsDisableFilter(false);
    }
  }, [data]);

  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy(null);
    }
    paramsFilter = getParamsFilter(templateFilter);
  }, [templateFilter]);

  // ------------------------------------------------------------------
  // 並べ替え
  // ------------------------------------------------------------------
  const handleSort = (type_sort: string) => {
    LoadingOverlayController.show();
    setSortBy(type_sort);
    const payload = {
      type_screen: TYPE_SCREEN,
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchListTemplate(undefined, type_sort, "", "");
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(
        LIST_TYPE_SORT_CUSTOM_TEMPLATE_TRASH,
      ).filter((item) => item.value === typeSort);
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_CUSTOM_TEMPLATE_TRASH).indexOf(
          defaultSort[0],
        );
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && data.length > 0;
  }, [data, loadMore, lastKey]);

  // ------------------------------------------------------------------
  // 復元
  // ------------------------------------------------------------------
  const handleClickRestore = () => {
    ModalController.show({
      message:
        check_list.length > 0
          ? messages.TEMPLATE.MSG_TEMPLATE_RESTORE_CONFIRM
          : messages.TEMPLATE.MSG_TEMPLATE_RESTORE_ALL_CONFIRM,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: async () => await handleRestore(),
    });
  };

  const handleRestore = async () => {
    LoadingOverlayController.show();

    const params = {
      restore_sk:
        check_list.length > 0 ? [...check_list] : data.map((item) => item.SK),
    };
    await updateRestoreProject(params)
      .then((res) => {
        if (res?.data) {
          ModalController.show({
            message: messages.COMMON.MSG_COMMON_SUCCESS_001("復元"),
            visibleButton2: true,
            handlePressButton2: () => {
              LoadingOverlayController.show();
              fetchListTemplate();
            },
          });
          dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  // ------------------------------------------------------------------
  // 削除
  // ------------------------------------------------------------------
  const handleClickDelete = () => {
    ModalController.show({
      message:
        check_list.length > 0
          ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
          : messages.TEMPLATE.MSG_TEMPLATE_DELETE_ALL_CONFIRM,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: async () => await handleDelete(),
    });
  };

  const handleDelete = async () => {
    LoadingOverlayController.show();

    const params = {
      delete_sk:
        check_list.length > 0 ? [...check_list] : data.map((item) => item.SK),
    };
    await deleteProject(params)
      .then((res) => {
        if (res?.data) {
          ModalController.show({
            message:
              messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001("ユーザー作成帳票"),
            visibleButton2: true,
            handlePressButton2: () => {
              LoadingOverlayController.show();
              fetchListTemplate();
            },
          });
          dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  return (
    <GenericTemplate title={"ユーザー作成帳票一覧（ゴミ箱）"}>
      <Typography>
        [ゴミ箱]のユーザー作成帳票は、30日後に自動的に削除されます。
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mt: 1 }}>
        <Button
          color="error"
          disabled={disabledButton}
          sx={{ width: 145 }}
          onClick={handleClickDelete}
        >
          {DeleteButtonTitle}
        </Button>
        <Button
          disabled={disabledButton}
          sx={{ width: 145 }}
          onClick={handleClickRestore}
        >
          {RestoreButtonTitle}
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "flex-start",
        }}
        mt={1}
      >
        {/* <PopupMenu data={[]} disabled /> */}
        <Box>
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_CUSTOM_TEMPLATE_TRASH).map(
              (item) => {
                return {
                  title: item.title,
                  onClick: () => handleSort(item.value),
                };
              },
            )}
            defaultIndex={sortDefaultIndex}
            disabled={data.length === 0}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box width={{ xs: "100%", md: "40%" }}>
          <FilterInput
            typeScreen={TYPE_SCREEN}
            disabled={isDisableFilter}
            onFetchData={() => {
              LoadingOverlayController.show();
              dispatch({ type: TYPES.SET_CHECK_TABLE, check_list: [] });
              fetchListTemplate(undefined, undefined, "", "");
            }}
          />
        </Box>
      </Box>

      {/* =================== ユーザー作成帳票 =================== */}
      <Paper elevation={0} sx={{ py: 1, mt: 1 }}>
        <TableInfiniteScroll
          rows={data}
          headCells={headCells}
          buttons={buttonList}
          isCheckRow
          idName="SK"
          maxHeight={"60vh"}
          showTooltip
          noWrap
          initialLoad={false}
          isLoading={loading}
          hasMore={showLoadMore}
          loadMore={() => {
            if (showLoadMore && !loading)
              fetchListTemplate(
                undefined,
                undefined,
                undefined,
                undefined,
                true,
              );
          }}
          message={
            data.length === 0 && !loading
              ? messages.COMMON.MSG_NOT_EXIST("ユーザー作成帳票")
              : ""
          }
        />
      </Paper>
    </GenericTemplate>
  );
};

export default CustomTemplateTrachScreen;
