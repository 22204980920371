import { getSignedUrlFile } from "@api/template";
import { EXCEL_TEMPLATE_INPUT } from "@shared-constants";
import { createFileNameFollowUri } from "@utils/index";
import {
  getInputGroupKey,
  getPageKey,
  getTemplateKey,
} from "@utils/template/excelTemplate";
import messages from "config/messages";
import { TemplateExcelDataInfo } from "services/models";

const getImageDataFromPathFile = async (pathFile: string) => {
  try {
    const res = await getSignedUrlFile(pathFile);
    if (res?.link_url) {
      return res.link_url;
    } else {
      throw Error("fail to get image data");
    }
  } catch (err) {
    throw Error("fail to get image data");
  }
};

const getImageUrlReportType2 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async () => {
    try {
      const originalImageArray = newStateFormEditInfo?.image_array;
      for (const [index, item] of originalImageArray.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalImageArray];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            image_array: new_image_array,
          };
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalImageArray];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            image_array: new_image_array,
          };
        }
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  if (newStateFormEditInfo?.image_array?.length > 0) {
    await getUrlImage();
  }
  return newStateFormEditInfo;
};

const getImageUrlReportType3 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async () => {
    try {
      const originalAbnomalityImage = newStateFormEditInfo?.abnomality_image;
      for (const [index, item] of originalAbnomalityImage.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          const new_image_array = [...originalAbnomalityImage];
          new_image_array[index].uri = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            abnomality_image: new_image_array,
          };
          // }
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          const new_image_array = [...originalAbnomalityImage];
          new_image_array[index].uri_jpg = newUri;
          new_image_array[index].non_Local = true;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            abnomality_image: new_image_array,
          };
        }
      }
      const container_status = newStateFormEditInfo?.container_status;
      for (const [index, item] of container_status.entries()) {
        if (item?.container_status_img) {
          const path_file =
            "images/" + createFileNameFollowUri(item.container_status_img);
          const newUri = await getImageDataFromPathFile(path_file);
          const new_container_status = [...container_status];
          new_container_status[index].container_status_img = newUri;
          newStateFormEditInfo = {
            ...newStateFormEditInfo,
            container_status: new_container_status,
          };
        }
      }
    } catch (err) {
      console.log("err :>> ", err);
    }
  };
  if (newStateFormEditInfo?.abnomality_image?.length > 0) {
    await getUrlImage();
  }
  return newStateFormEditInfo;
};

const getImageUrlReportType4 = async (newStateFormEditInfo: any) => {
  const getUrlImage = async (image_array: any) => {
    try {
      for (const [index, item] of image_array.entries()) {
        if (item?.path_file) {
          const newUri = await getImageDataFromPathFile(item.path_file);
          image_array[index].uri = newUri;
          image_array[index].non_Local = true;
        }
        if (item?.path_file_jpg) {
          const newUri = await getImageDataFromPathFile(item.path_file_jpg);
          image_array[index].uri_jpg = newUri;
          image_array[index].non_Local = true;
        }
      }
      return image_array;
    } catch (err) {
      console.log("err :>> ", err);
      return image_array;
    }
  };

  const keys = Object.keys(newStateFormEditInfo);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (key == "image_array" || key == "custom_image") {
      const url_image = await getUrlImage(newStateFormEditInfo[key]);
      newStateFormEditInfo = {
        ...newStateFormEditInfo,
        [key]: url_image,
      };
    } else if (
      newStateFormEditInfo[key].path_file ||
      newStateFormEditInfo[key].path_file_jpg
    ) {
      const url_image = await getUrlImage([newStateFormEditInfo[key]]);
      newStateFormEditInfo = {
        ...newStateFormEditInfo,
        [key]: url_image.length > 0 ? url_image[0] : newStateFormEditInfo[key],
      };
    }
  }

  return newStateFormEditInfo;
};

const getImageUrlReportType5 = async (newStateFormEditInfo: any) => {
  const {
    images,
    vehicle_appearance,
    customer_signature,
    destination_signature,
  } = newStateFormEditInfo;
  const getUrlImage = async (imageItem: Type.ImageInfoType) => {
    const { path_file, path_file_jpg } = imageItem;
    try {
      if (path_file) {
        const newUri = await getImageDataFromPathFile(path_file);
        imageItem = { ...imageItem, uri: newUri, non_Local: true };
      }
      if (path_file_jpg) {
        const newUri = await getImageDataFromPathFile(path_file_jpg);
        imageItem = { ...imageItem, uri_jpg: newUri, non_Local: true };
      }
      return imageItem;
    } catch (err) {
      console.log("err :>> ", err);
      return imageItem;
    }
  };

  const originImage: Type.ImageInfoType = {
    uri: "",
    name: "",
    type: "",
  };

  let newImages: Array<Type.ImageInfoType> = [];
  let newVehicleAppearance: Type.ImageInfoType = { ...originImage };
  let newCustomerSignature: Type.ImageInfoType = { ...originImage };
  let newDestinationSignature: Type.ImageInfoType = { ...originImage };

  if (images.length > 0) {
    for (const imageItem of images) {
      const newImageItem = await getUrlImage(imageItem);
      if (newImageItem?.uri) {
        newImages.push(newImageItem);
      }
    }
  }
  if (vehicle_appearance?.uri) {
    newVehicleAppearance = await getUrlImage(vehicle_appearance);
  }
  if (customer_signature?.uri) {
    newCustomerSignature = await getUrlImage(customer_signature);
  }
  if (destination_signature?.uri) {
    newDestinationSignature = await getUrlImage(destination_signature);
  }

  return {
    ...newStateFormEditInfo,
    images: newImages,
    vehicle_appearance: newVehicleAppearance,
    customer_signature: newCustomerSignature,
    destination_signature: newDestinationSignature,
  };
};

export const screenIdSupportGetImageUrl: { [fieldName: string]: Function } = {
  // SCREEN_TEMPLATE_01: getImageUrlReportType1,
  SCREEN_TEMPLATE_06: getImageUrlReportType2,
  SCREEN_TEMPLATE_07: (newStateFormEditInfo: any) => newStateFormEditInfo,
  default: (newStateFormEditInfo: any) => newStateFormEditInfo,
  SCREEN_TEMPLATE_08: getImageUrlReportType3,
  SCREEN_TEMPLATE_09: getImageUrlReportType4,
  SCREEN_TEMPLATE_10: getImageUrlReportType5,
};

const checkType = {
  input: "input",
  checkbox: "checkbox",
  is_manual_input: "is_manual_input",
  array: "array",
  input_bool: "input_bool",
};

const checkInput = (dataAttribute: any) => {
  if (
    dataAttribute === undefined ||
    dataAttribute === null ||
    dataAttribute === "" ||
    dataAttribute === false ||
    dataAttribute?.length === 0
  ) {
    return true;
  }
};

const checkCheckbox = (dataAttributeFirst: any, dataAttributeSecond: any) => {
  return !(dataAttributeFirst || dataAttributeSecond);
};

const checkInputBool = (dataAttribute: any) => {
  if (
    dataAttribute === undefined ||
    dataAttribute === null ||
    dataAttribute === ""
  ) {
    return true;
  }
};

const checkInputInfo = (checkInfo: any, templateEditInfo: any) => {
  let isError = false;
  let message = [];
  for (const item of checkInfo) {
    const { type } = item;
    if (type === checkType.input) {
      const { attribute, title } = item;
      const dataAttribute = templateEditInfo[attribute];
      if (checkInput(dataAttribute)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
      }
    } else if (type === checkType.checkbox) {
      const { attributeFirst, attributeSecond, title } = item;
      const dataAttributeFirst = templateEditInfo[attributeFirst];
      const dataAttributeSecond = templateEditInfo[attributeSecond];
      if (checkCheckbox(dataAttributeFirst, dataAttributeSecond)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
      }
    } else if (type === checkType.is_manual_input) {
      const { attributeFirst, attributeSecond, title } = item;
      const dataAttributeFirst = templateEditInfo[attributeFirst];
      const dataAttributeSecond = templateEditInfo[attributeSecond];
      if (dataAttributeFirst) {
        if (dataAttributeFirst["is_manual_input"]) {
          if (checkInput(dataAttributeSecond)) {
            isError = true;
            message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
          }
        }
      }
    } else if (type === checkType.array) {
      const { attributeFirst, attributeSecond, title } = item;
      const dataAttributeFirst = templateEditInfo[attributeFirst];
      const dataAttributeSecond = templateEditInfo[attributeSecond];
      if (Array.isArray(dataAttributeFirst)) {
        if (dataAttributeFirst.some((elm) => elm?.is_manual_input)) {
          if (checkInput(dataAttributeSecond)) {
            isError = true;
            message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
          }
        }
      } else if (
        typeof dataAttributeFirst === "object" &&
        dataAttributeFirst !== null
      ) {
        if (dataAttributeFirst.is_manual_input) {
          if (checkInput(dataAttributeSecond)) {
            isError = true;
            message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
          }
        }
      }
    } else if (type === checkType.input_bool) {
      const { attribute, title } = item;
      const dataAttribute = templateEditInfo[attribute];
      if (checkInputBool(dataAttribute)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
      }
    }
  }

  return { isError, message };
};

const CheckRequiredFieldReportType7 = (templateEditInfo: any) => {
  const checkInfo = [
    {
      attribute: "vehicle_id",
      title: "車体番号",
      type: checkType.input,
    },
    {
      attribute: "driver",
      title: "担当者名",
      type: checkType.input,
    },
    {
      attributeFirst: "driver",
      attributeSecond: "driver_other",
      title: "その他(担当者名)",
      type: checkType.is_manual_input,
    },
    {
      attribute: "year_month",
      title: "実施年月",
      type: checkType.input,
    },
  ];
  const checkInfo_result = [
    {
      attribute: "check_user",
      title: "点検実施者",
      type: checkType.input,
    },
    {
      attributeFirst: "check_user",
      attributeSecond: "check_user_other",
      title: "その他(点検実施者)",
      type: checkType.is_manual_input,
    },
  ];
  let isError = false;
  let message: string[] = [];
  const res = checkInputInfo(checkInfo, templateEditInfo);

  isError = res.isError;
  message = res.message;
  Object.keys(templateEditInfo.check_result).map((key) => {
    const res_check = checkInputInfo(
      checkInfo_result,
      templateEditInfo.check_result[key],
    );
    if (res_check.message.length > 0) {
      res_check.message[0] += `（${Number(key) + 1}日）`;
    }

    isError = res_check.isError || isError;
    message = message.concat(res_check.message);
  });

  return { isError, message };
};

const CheckRequiredFieldReportType8 = (templateEditInfo: any) => {
  const checkInfo = [
    { attribute: "container_no", title: "デバン", type: checkType.input },
    {
      attribute: "sealed",
      title: "封印",
      type: checkType.input_bool,
    },
    {
      attribute: "person_worker",
      title: "作業者名",
      type: checkType.input,
    },
    {
      attributeFirst: "person_worker",
      attributeSecond: "person_worker_other",
      title: "その他(作業者名)",
      type: checkType.is_manual_input,
    },
  ];
  if (templateEditInfo["seal_no"].length > 0) {
    checkInfo.push({
      attribute: "bool_seal_no",
      title: "シールNo.確認",
      type: checkType.input,
    });
  }

  return checkInputInfo(checkInfo, templateEditInfo);
};

const CheckRequiredFieldReportType9 = (
  templateEditInfo: any,
  template: any,
  step: number,
) => {
  let isError: boolean = false;
  let message: string[] = [];
  let data_info = [...template.data_info];
  // 追加ページの入力項目設定をdata_infoに追加
  if (templateEditInfo["page"]) {
    const page = Number(templateEditInfo["page"] ?? 1);
    for (let index = 1; index < page; index++) {
      template.data_info.forEach((item: TemplateExcelDataInfo) => {
        const cloneItem = { ...item };
        cloneItem.coordinate = getPageKey(item.coordinate, index + 1);
        data_info.push(cloneItem);
      });
    }
  }
  // 追加テンプレートの入力項目設定をdata_infoに追加
  template.extra_template?.map((extra_template: any, template_no: number) => {
    extra_template?.data_info?.forEach((item: TemplateExcelDataInfo) => {
      const page_key = getTemplateKey("page", template_no + 1);
      const all_page = templateEditInfo[page_key] ?? 1;
      for (let index = 0; index < all_page; index++) {
        const cloneItem = { ...item };
        const coordinate = getPageKey(item.coordinate, index + 1);
        cloneItem.coordinate = getTemplateKey(coordinate, template_no + 1);
        data_info.push(cloneItem);
      }
    });
  });
  data_info.forEach((item: TemplateExcelDataInfo) => {
    if (item.input == EXCEL_TEMPLATE_INPUT.INPUT_GROUP && item.group_info) {
      // 入力グループの入力項目設定をdata_infoに追加
      let tmpDataInfo: TemplateExcelDataInfo[] = [];
      const listGroupIndex: number[] =
        templateEditInfo[item.coordinate + "_group"] ?? [];
      listGroupIndex.forEach((count) => {
        item.group_info?.forEach((e) => {
          let setting = { ...e };
          setting.coordinate = getInputGroupKey(
            item.coordinate,
            setting.coordinate,
            count,
          );
          tmpDataInfo.push(setting);
        });
      });
      data_info = data_info.concat(tmpDataInfo);
    }
  });

  data_info.forEach((item: TemplateExcelDataInfo) => {
    const required =
      item.required && item.required_step
        ? item.required_step <= step
        : item.required;
    if (required) {
      var dataAttribute = "";
      switch (item.input) {
        case EXCEL_TEMPLATE_INPUT.TEXT:
        case EXCEL_TEMPLATE_INPUT.DATE:
        case EXCEL_TEMPLATE_INPUT.NUMBER:
        case EXCEL_TEMPLATE_INPUT.TIME:
        case EXCEL_TEMPLATE_INPUT.BARCODE:
        case EXCEL_TEMPLATE_INPUT.INPUT_GROUP:
          dataAttribute = templateEditInfo[item.coordinate];
          break;
        case EXCEL_TEMPLATE_INPUT.MASTER:
          dataAttribute = templateEditInfo[item.coordinate]?.name;
          break;
        case EXCEL_TEMPLATE_INPUT.IMAGE:
        case EXCEL_TEMPLATE_INPUT.DRAW_IMAGE:
          dataAttribute = templateEditInfo[item.coordinate]?.path_file;
          break;
        case EXCEL_TEMPLATE_INPUT.USER_NAME:
          dataAttribute = templateEditInfo[item.coordinate]?.name;
          break;
        case EXCEL_TEMPLATE_INPUT.SIGNATURE:
          dataAttribute = templateEditInfo[item.coordinate]?.path_file;
          break;
        case EXCEL_TEMPLATE_INPUT.CHECKBOX:
          dataAttribute = templateEditInfo[item.coordinate]?.checked;
          break;
      }
      if (checkInput(dataAttribute)) {
        isError = true;
        message.push(messages.COMMON.ERROR.MSG_RQUIRED(item.name));
      }
    }
  });

  return { isError, message };
};

export const screenIdSupportCheckRequiredFieldTemplate: {
  [fieldName: string]: Function;
} = {
  SCREEN_TEMPLATE_06: (templateEditInfo: any) => {
    const checkInfo = [
      {
        attribute: "place_found_issue",
        title: "発行元",
        type: checkType.input,
      },
      {
        attributeFirst: "place_found_issue",
        attributeSecond: "place_found_issue_other",
        title: "その他(発行元)",
        type: checkType.is_manual_input,
      },
      {
        attribute: "incident_date",
        title: "発生年月日",
        type: checkType.input,
      },
      {
        attributeFirst: "is_product_issue",
        attributeSecond: "is_packaging_issue",
        title: "事故区分",
        type: checkType.checkbox,
      },
      {
        attribute: "responsible_person",
        title: "事故当事者（事故責任）",
        type: checkType.input,
      },
      {
        attributeFirst: "responsible_person",
        attributeSecond: "responsible_person_other",
        title: "その他(事故当事者)",
        type: checkType.is_manual_input,
      },
      {
        attribute: "person_found_issue",
        title: "発見者氏名（当事者氏名）",
        type: checkType.input,
      },
      {
        attributeFirst: "person_found_issue",
        attributeSecond: "person_found_issue_other",
        title: "その他(発見者氏名)",
        type: checkType.is_manual_input,
      },
      {
        attributeFirst: "product_type",
        attributeSecond: "product_type_other",
        title: "その他(分類)",
        type: checkType.is_manual_input,
      },
      { attribute: "product_name", title: "商品名", type: checkType.input },
      { attribute: "amount_of_product", title: "数量", type: checkType.input },
      {
        attribute: "product_packaging_status",
        title: "荷姿状態",
        type: checkType.input,
      },
      {
        attributeFirst: "product_packaging_status",
        attributeSecond: "product_packaging_status_other",
        title: "その他(荷姿状態)",
        // type: checkType.is_manual_input,
        type: checkType.array,
      },
      {
        attribute: "reason_cause_issue",
        title: "事故発生理由",
        type: checkType.input,
      },
      {
        attributeFirst: "reason_cause_issue",
        attributeSecond: "reason_cause_issue_other",
        title: "その他(事故発生理由)",
        type: checkType.is_manual_input,
      },
      {
        attribute: "is_move_to_KF",
        title: "不良ロケに移動しましたか",
        type: checkType.input,
      },
      {
        attribute: "is_move_product",
        title: "現物移動しましたか",
        type: checkType.input,
      },
    ];

    let isError = false;
    let message = [];

    for (const item of checkInfo) {
      const { type } = item;
      if (type === checkType.input) {
        const { attribute, title } = item;
        if (!attribute) continue;
        const dataAttribute = templateEditInfo[attribute];
        if (checkInput(dataAttribute)) {
          isError = true;
          message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
        }
      } else if (type === checkType.checkbox) {
        const { attributeFirst, attributeSecond, title } = item;
        if (!attributeFirst) continue;
        const dataAttributeFirst = templateEditInfo[attributeFirst];
        const dataAttributeSecond = templateEditInfo[attributeSecond];
        if (checkCheckbox(dataAttributeFirst, dataAttributeSecond)) {
          isError = true;
          message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
        }
      } else if (type === checkType.is_manual_input) {
        const { attributeFirst, attributeSecond, title } = item;
        if (!attributeFirst) continue;
        const dataAttributeFirst = templateEditInfo[attributeFirst];
        const dataAttributeSecond = templateEditInfo[attributeSecond];
        if (dataAttributeFirst) {
          if (dataAttributeFirst["is_manual_input"]) {
            if (checkInput(dataAttributeSecond)) {
              isError = true;
              message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
            }
          }
        }
      } else if (type === checkType.array) {
        const { attributeFirst, attributeSecond, title } = item;
        if (!attributeFirst) continue;
        const dataAttributeFirst = templateEditInfo[attributeFirst];
        const dataAttributeSecond = templateEditInfo[attributeSecond];
        if (Array.isArray(dataAttributeFirst)) {
          if (dataAttributeFirst.some((elm) => elm?.is_manual_input)) {
            if (checkInput(dataAttributeSecond)) {
              isError = true;
              message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
            }
          }
        } else if (
          typeof dataAttributeFirst === "object" &&
          dataAttributeFirst !== null
        ) {
          if (dataAttributeFirst.is_manual_input) {
            if (checkInput(dataAttributeSecond)) {
              isError = true;
              message.push(messages.COMMON.ERROR.MSG_RQUIRED(title));
            }
          }
        }
      }
    }

    return { isError, message };
  },
  SCREEN_TEMPLATE_07: CheckRequiredFieldReportType7,
  SCREEN_TEMPLATE_08: CheckRequiredFieldReportType8,
  SCREEN_TEMPLATE_09: CheckRequiredFieldReportType9,
};
