import React from "react";
import { TemplateExcelDataInfo } from "services/models";
import { IStepperInput } from "components/atoms/StepperInput";
import StepperInputLabel from "components/atoms/StepperInputLabel";
import { labeltextBreak } from "@utils/index";

type INumberInputField = Omit<IStepperInput, "setting" | "onChange"> & {
  setting: TemplateExcelDataInfo;
  onChange: (text: string) => void;
};

const NumberInputField = ({
  setting,
  value,
  onChange,
  label,
  ...props
}: INumberInputField) => {
  const maxValue = React.useMemo(() => {
    if (!setting.maxlen) {
      return 9999999;
    }
    return 10 ** Number(setting.maxlen) - 1;
  }, [setting]);

  return (
    <StepperInputLabel
      label={labeltextBreak(setting.name)}
      value={value}
      required={setting.required}
      onChange={onChange}
      maxValue={maxValue}
      {...props}
    />
  );
};

export default NumberInputField;
