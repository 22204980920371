import { METHOD_API, createConnector, prefixApi } from "./Connector";
import { User } from "services/models";
interface sortData {
  PK: String;
  SK: String;
  sort: Number;
  user: User;
}

export const updateProjectStatusSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/status/sort`,
    dataBody,
  );
};

export const deleteProjectStatus = (project_status_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/status/${encodeURIComponent(project_status_id)}`,
    null,
  );
};
