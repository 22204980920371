import React, { useEffect, useRef, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import _ from "lodash";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getCsvData, getUserInfo } from "@utils/index";
import { downloadXlsx } from "@utils/CSV";
import { Validation } from "@validation";
import Dropzone from "components/atoms/Dropzone";
import LabelRequired from "components/atoms/LabelRequired";
import SelectLabel from "components/atoms/SelectLabel";
import ListCancelBadge from "components/molecules/ListCancelBadge";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { importMaster } from "@api/master";
import { checkMasterTypeExist, getMasterType } from "@api/masterType";
import { Colors } from "@template/style";
import {
  getColumnMaxlength,
  getDefaultColumnIndex,
  getRequiredColumn,
  IMasterList,
  LIST_EXPORT_CSV,
  LIST_GROUP,
  LIST_MASTER,
  NewUserMaster,
} from "./MasterInfo";

interface IStateForm {
  master: string;
  master_name: string;
  input_master_name: string;
  keyitem: boolean;
}
interface IErrorForm {
  master: string;
  input_master_name: string;
  csv: string;
  custom_item: string[];
}
const initialStateForm: IStateForm = {
  master: "",
  master_name: "",
  input_master_name: "",
  keyitem: false,
};

interface ICustomItem {
  name: string;
  barcode: boolean;
  required: boolean;
  keyitem: boolean;
}

const MasterImportScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [listMaster, setListMaster] = useState<IMasterList[]>([]);
  const [createFlg, setCreateFlg] = useState<boolean>(false);
  const [stateForm, setStateForm] = useState<IStateForm>({
    ...initialStateForm,
  });
  const [selectedCSV, setSelectedCSV] = useState<File>();
  const [formError, setFormError] = useState<IErrorForm>({
    master: "",
    input_master_name: "",
    csv: "",
    custom_item: [],
  });
  const [activityBaseId, setActivityBaseId] = useState<string>("");
  const [listMasterType, setListMasterType] = useState<Array<any>>([]);
  const [listCustomItem, setListCustomItem] = useState<Array<ICustomItem>>([]);
  const dropzoneRef = useRef<any>(null);

  useEffect(() => {
    const user_info = getUserInfo();
    setActivityBaseId(user_info.location_id);
    getMasterTypeList(user_info.location_id);
  }, []);

  const getMasterTypeList = async (activityBaseId: string) => {
    LoadingOverlayController.show();
    await getMasterType(activityBaseId, true)
      .then((res) => {
        var new_list_master = [...LIST_MASTER];
        if (res?.data) {
          const list_user_master = res.data.map((v: any) => ({
            id: v.SK,
            name: v.name,
            group: LIST_GROUP.USER_MASTER.name,
          }));
          new_list_master = new_list_master.concat(list_user_master);
          setListMasterType(res.data);
        }
        new_list_master.push(NewUserMaster);
        setListMaster(new_list_master);
      })
      .finally(() => LoadingOverlayController.hide());
  };

  const handleClear = () => {
    setStateForm({ ...initialStateForm });
    setSelectedCSV(undefined);
    setCreateFlg(false);
    setListCustomItem([]);
    if (dropzoneRef.current) {
      dropzoneRef.current.delData();
    }
  };

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateForm((prev) => ({
      ...prev,
      input_master_name: e.target.value,
    }));
  };

  const changeKeyitem = (value: boolean) => {
    setStateForm((prev) => ({
      ...prev,
      keyitem: value,
    }));
  };

  const changeCustomItem = (
    field: keyof ICustomItem,
    index: number,
    value: any,
  ) => {
    setListCustomItem((prev) => {
      var new_value = _.cloneDeep(prev);
      new_value[index] = { ...new_value[index], [field]: value };
      return new_value;
    });
  };

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, stateForm[field]);
    setFormError({ ...formError, [field]: mess });
  };

  const onValidCustomItemName = (index: number) => () => {
    const mess = validator("custom_item_name", listCustomItem[index]["name"]);
    var newErrorCustomItem = _.cloneDeep(formError["custom_item"]);
    newErrorCustomItem[index] = mess;
    setFormError({ ...formError, custom_item: newErrorCustomItem });
  };

  const validator = (field: string, value: any) => {
    let mess: string = "";
    switch (field) {
      case "master":
        mess = Validation.validate({
          type: "text",
          name: "マスタ",
          value: stateForm.master,
          required: true,
        });
        break;
      case "input_master_name":
        mess = Validation.validate({
          type: "text",
          name: "ユーザー作成マスタ名",
          value: stateForm.input_master_name,
          required: true,
          max_length: 30,
        });
        break;
      case "custom_item_name":
        mess = Validation.validate({
          type: "text",
          name: "項目名",
          value: value,
          required: true,
          max_length: 30,
        });
        break;
      case "csv":
        if (!value) {
          mess = messages.COMMON.ERROR.MSG_RQUIRED_SELECT("CSV");
        }
        break;
    }
    return mess;
  };

  const validationAllCheck = async () => {
    // 入力チェック
    const master_error = validator("master", stateForm.master);
    const input_master_name_error = createFlg
      ? validator("input_master_name", stateForm.input_master_name)
      : "";
    const csv_error = validator("csv", selectedCSV);
    const custom_item_error = listCustomItem.map((value) => {
      return validator("custom_item_name", value.name);
    });
    setFormError({
      ...formError,
      master: master_error,
      input_master_name: input_master_name_error,
      csv: csv_error,
      custom_item: custom_item_error,
    });

    // マスタ名の重複チェック
    if (createFlg && input_master_name_error == "") {
      let params = {
        master_type_name: stateForm.input_master_name,
        activity_base_id: activityBaseId,
        master_type_id: "",
      };
      const master_type_exist = await checkMasterTypeExist(params);
      if (master_type_exist) {
        ModalController.show({
          message: messages.COMMON.ERROR.MSG_EXISTING("マスタ名"),
          visibleButton2: true,
        });
        return false;
      }
    }

    // バーコード検索する項目は1つのみ
    const count_barcode = listCustomItem.filter(
      (value) => value.barcode == true,
    ).length;
    if (count_barcode > 1) {
      ModalController.show({
        message: messages.MASTER.MSG_ERROR_BARCODE_LIMIT_1,
        visibleButton2: true,
      });
      return false;
    }

    // キー項目は1つのみ
    const count_keyitem = listCustomItem.filter(
      (value) => value.keyitem == true,
    ).length;
    if (count_keyitem > 1 || (count_keyitem == 1 && stateForm.keyitem)) {
      ModalController.show({
        message: messages.MASTER.MSG_ERROR_KEYITEM_LIMIT_1,
        visibleButton2: true,
      });
      return false;
    }

    if (
      master_error !== "" ||
      input_master_name_error !== "" ||
      csv_error !== "" ||
      custom_item_error.some((v) => v !== "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  // ------------------------------------------------------------------
  // 雛形CSVダウンロード
  // ------------------------------------------------------------------
  const handleExportCSV = async () => {
    if (!stateForm.master) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED("マスタ"),
        visibleButton2: true,
      });
    } else {
      var columns = LIST_EXPORT_CSV[stateForm.master] ?? [];
      // ユーザー作成マスタの場合は、マスタデータから任意項目を追加
      const master = listMaster.find((v) => v.id === stateForm.master);
      if (master?.group == LIST_GROUP.USER_MASTER.name) {
        columns = [...LIST_EXPORT_CSV[LIST_GROUP.USER_MASTER.id]];
        const master_type = listMasterType.find(
          (v) => v.SK == stateForm.master,
        );
        if (master_type && master_type.custom_item) {
          master_type.custom_item.forEach((v: any) => {
            columns.push({ header: v.name });
          });
        }
      }
      if (stateForm.master == NewUserMaster.id) {
        // 新規作成の場合は、入力した任意項目を追加
        listCustomItem.forEach((v) => {
          columns.push({ header: v.name });
        });
      }
      await downloadXlsx({
        data: [],
        columns: columns,
        filename: `${stateForm.master_name}マスタ雛形.csv`,
      });
    }
  };

  // ------------------------------------------------------------------
  // 取込み
  // ------------------------------------------------------------------
  const handleImportCSV = async () => {
    try {
      LoadingOverlayController.show();
      // 入力チェック
      if (!(await validationAllCheck()) || !selectedCSV) {
        return false;
      }

      // CSVデータ取得
      const csv_data = await getCsvData(selectedCSV);
      if (csv_data.length == 0) {
        ModalController.show({
          message: messages.COMMON.MSG_ERROR_FILE_OPEN,
          visibleButton2: true,
        });
        LoadingOverlayController.hide();
        return;
      }

      // CSVチェック
      const error_message = checkCsvData(csv_data);
      if (error_message.length > 0) {
        ModalController.show({
          message: error_message.join("\n"),
          visibleButton2: true,
        });
        LoadingOverlayController.hide();
        return;
      }

      // 保存
      const formData = {
        master: stateForm.master,
        master_name: stateForm.input_master_name,
        csv: csv_data.splice(1),
        activity_base_id: activityBaseId,
        is_create_master_type: createFlg,
        custom_item: listCustomItem,
        keyitem: stateForm.keyitem,
      };
      await importMaster(formData)
        .then(() => {
          ModalController.show({
            message: messages.COMMON.MSG_COMMON_SUCCESS_001("CSV取込"),
            visibleButton2: true,
            handlePressButton2: () => {
              handleClear();
              getMasterTypeList(activityBaseId);
            },
          });
        })
        .catch((e) => {
          if (e.detail) {
            ModalController.show({ message: e.detail, visibleButton2: true });
          } else {
            ModalController.show({
              message: messages.COMMON.MSG_COMMON_ERROR_001,
              visibleButton2: true,
            });
          }
        });
      LoadingOverlayController.hide();
    } catch (e) {
      console.log("handleImportCSV error", e);
      LoadingOverlayController.hide();
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const checkCsvData = (csv_data: string[][]) => {
    var response: string[] = [];
    const master = listMaster.find((v) => v.id === stateForm.master);
    const id =
      master?.group == LIST_GROUP.USER_MASTER.name
        ? LIST_GROUP.USER_MASTER.id
        : stateForm.master;
    const columns = LIST_EXPORT_CSV[id];
    var req = getRequiredColumn(id);
    const max_length = getColumnMaxlength(id);
    const def_index = getDefaultColumnIndex(id);

    if (csv_data.length == 0 || csv_data[0].length < 1) {
      response.push(messages.MASTER.MSG_ERROR_CSV);
    }

    if (id == LIST_GROUP.USER_MASTER.id) {
      if (stateForm.master == NewUserMaster.id) {
        // 新規作成
        listCustomItem.forEach((v, i) => {
          if (v.required) {
            req.push(i + 3);
          }
        });
      } else {
        // 既存ユーザー作成マスタ
        const master_type = listMasterType.find(
          (v) => v.SK == stateForm.master,
        );
        if (master_type && master_type.custom_item) {
          master_type.custom_item.forEach((v: any, i: number) => {
            if (v.required) {
              req.push(i + 3);
            }
          });
        }
      }
    }

    // 入力チェック
    csv_data.forEach((row, n) => {
      // ヘッダはチェックしない
      if (n > 0) {
        row.forEach((value, i) => {
          // 必須チェック
          var mess = Validation.validate({
            type: "text",
            value: value,
            name:
              columns[i]?.header !== undefined
                ? new String(columns[i].header).toString() + `（${n + 1}行目）`
                : `${n + 1}行目${i + 1}列目`,
            required: req.includes(i),
            max_length: max_length[i]?.maxlen,
          });
          if (mess) response.push(mess);
        });
      }
    });
    const not_exist_req_column =
      csv_data.length > 0 ? req.filter((v) => v > csv_data[0].length - 1) : [];
    if (not_exist_req_column.length > 0) {
      not_exist_req_column.forEach((v) => {
        response.push(messages.COMMON.ERROR.MSG_RQUIRED(`${v + 1}列目`));
      });
    }

    // 名前の重複チェック
    if (!["CHECK_SECTION", "CHECK_ITEM"].includes(id)) {
      var count_name = csv_data
        .map((row) => row[0])
        .filter((value, i, self) => {
          return (
            self.indexOf(value) === i &&
            i !== self.lastIndexOf(value) &&
            value !== ""
          );
        }).length;
      if (count_name > 0) {
        response.push(messages.COMMON.ERROR.MSG_EXISTING("名称"));
      }
    }

    // デフォルト値の重複チェック
    if (def_index !== null) {
      const count_def = csv_data
        .map((row) => row[def_index])
        .filter((value) => {
          return value == "1";
        }).length;
      if (count_def > 1) {
        response.push(messages.COMMON.ERROR.MSG_EXISTING("デフォルト値"));
      }
    }
    return response;
  };

  const handleAdd = () => {
    var newListCustomItem = _.cloneDeep(listCustomItem);
    newListCustomItem.push({
      name: "",
      barcode: false,
      required: false,
      keyitem: false,
    });
    var newErrorCustomItem = _.cloneDeep(formError.custom_item);
    newErrorCustomItem.push("");
    setListCustomItem(newListCustomItem);
    setFormError({ ...formError, custom_item: newErrorCustomItem });
  };

  const handleDelelte = (index: number) => {
    var newListCustomItem = _.cloneDeep(listCustomItem);
    newListCustomItem.splice(index, 1);
    var newErrorCustomItem = _.cloneDeep(formError.custom_item);
    newErrorCustomItem.splice(index, 1);
    setListCustomItem(newListCustomItem);
    setFormError({ ...formError, custom_item: newErrorCustomItem });
  };

  const renderListItem = (value: ICustomItem, index: number) => {
    return (
      <Stack sx={{ width: "100%", mt: 1 }} spacing={1}>
        <TextField
          label={<LabelRequired title={"項目名"} />}
          value={value.name}
          onChange={(e) => {
            changeCustomItem("name", index, e.target.value);
          }}
          onBlur={onValidCustomItemName(index)}
          error={formError.custom_item[index]?.length > 0}
          helperText={formError.custom_item[index]}
          inputProps={{
            maxLength: 30,
          }}
        />
        <FormGroup sx={{ flexDirection: "row" }}>
          <CheckboxLabel
            label="キー項目"
            checked={value.keyitem}
            onChange={(_, checked) => {
              changeCustomItem("keyitem", index, checked);
              if (checked) changeCustomItem("required", index, checked);
            }}
          />
          <CheckboxLabel
            label="バーコード検索する"
            checked={value.barcode}
            onChange={(_, checked) => {
              changeCustomItem("barcode", index, checked);
              if (checked) changeCustomItem("required", index, checked);
            }}
          />
          <CheckboxLabel
            label="必須"
            checked={value.required}
            onChange={(_, checked) => {
              changeCustomItem("required", index, checked);
            }}
          />
        </FormGroup>
      </Stack>
    );
  };

  return (
    <GenericTemplate title="CSV取込">
      <Card>
        <CardContent>
          <Stack>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{ width: { xs: "100%", md: "50%" } }}
            >
              <SelectLabel
                label={<LabelRequired title="マスタ" />}
                fullWidth
                value={stateForm.master}
                onChange={(e) => {
                  const item = listMaster.find((v) => v.id === e.target.value);

                  if (item) {
                    setCreateFlg(item.is_new ?? false);
                    setStateForm({
                      ...stateForm,
                      master: item.id,
                      master_name: item.name,
                      input_master_name: "",
                      keyitem: false,
                    });
                    setFormError({
                      ...formError,
                      master: "",
                      input_master_name: "",
                    });
                    setListCustomItem([]);
                  }
                }}
                MenuProps={{ style: { maxHeight: 300 } }}
                error={formError.master.length > 0}
                helperText={formError.master}
              >
                {listMaster.map((item, index) => {
                  var subheader = false;
                  var response = [];
                  if (index == 0 || listMaster[index - 1].group != item.group) {
                    subheader = true;
                    response.push(
                      <ListSubheader
                        sx={{ color: Colors.LIGHT_GRAY, fontStyle: "italic" }}
                        key={index}
                      >
                        {item.group}
                      </ListSubheader>,
                    );
                  }
                  response.push(
                    <MenuItem
                      value={item.id}
                      key={subheader ? undefined : index}
                    >
                      {item.name}
                    </MenuItem>,
                  );
                  return response;
                })}
              </SelectLabel>
            </Box>
            <TextField
              label="ユーザー作成マスタ名"
              value={stateForm.input_master_name}
              disabled={!createFlg}
              onChange={changeName}
              onBlur={() => onValidateText("input_master_name")}
              error={formError.input_master_name.length > 0}
              helperText={formError.input_master_name}
              inputProps={{
                maxLength: 30,
              }}
            />
            <CheckboxLabel
              label="名称をキー項目にする"
              checked={stateForm.keyitem}
              onChange={(_, checked) => changeKeyitem(checked)}
              disabled={!createFlg}
            />
            <ListCancelBadge
              data={listCustomItem}
              renderItem={renderListItem}
              handleCancel={handleDelelte}
              cancelBtnDisabled={!createFlg}
              addBtnTitle="項目追加"
              handleAdd={handleAdd}
              addBtnDisabled={!createFlg}
            />
            <FormControl error={formError.csv.length > 0}>
              <InputLabel>
                <LabelRequired title="CSV" />
              </InputLabel>
              <Dropzone
                ref={dropzoneRef}
                accept="csv"
                maxFiles={1}
                onChoose={(res) => {
                  setSelectedCSV(res[0]);
                  setFormError({ ...formError, csv: "" });
                }}
                onDeleteFile={() => {
                  setSelectedCSV(undefined);
                }}
              />
              <FormHelperText error={formError.csv.length > 0}>
                {formError.csv}
              </FormHelperText>
            </FormControl>
            <Alert severity="info" color="warning">
              手入力のときは、手入力有りに1を入力してください
              <br />
              デフォルトのときは、デフォルト値に1を入力してください
            </Alert>
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleExportCSV}>雛形CSVダウンロード</Button>
          <Button color="secondary" onClick={handleImportCSV}>
            取込
          </Button>
        </CardActions>
      </Card>
    </GenericTemplate>
  );
};

export default MasterImportScreen;
