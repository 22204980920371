import React, { Component } from "react";
import { CircularProgress, Backdrop } from "@mui/material";

export type LoadingOverlayControllerConfig = {
  presentationStyle?: string;
  onDismiss?: () => null;
  animationType?: () => null;
  transparent?: () => null;
  onShow?: () => null;
  onRequestClose?: () => null;
};

class LoadingOverlayController extends Component<any, any> {
  state = {
    visible: false,
    config: {},
  };

  shouldComponentUpdate(nextProps: any, nextState: any, nextContext: any) {
    return this.state.visible !== nextState.visible;
  }

  static overlayInstance: LoadingOverlayController;

  static isShowing() {
    return (
      LoadingOverlayController.overlayInstance &&
      LoadingOverlayController.overlayInstance.state.visible
    );
  }

  static setRef(ref: any) {
    LoadingOverlayController.overlayInstance = ref;
  }

  static show(config?: LoadingOverlayControllerConfig) {
    LoadingOverlayController.overlayInstance._hide();
    if (!config) config = {};
    LoadingOverlayController.overlayInstance._show(config);
  }

  static hide() {
    LoadingOverlayController.overlayInstance._hide();
  }

  _show(config: LoadingOverlayControllerConfig) {
    this.setState({ visible: true, config });
  }

  _hide() {
    this.setState({ visible: false, config: {} });
  }

  constructor(props: any) {
    super(props);
    this._show = this._show.bind(this);
    this._hide = this._hide.bind(this);
  }

  render() {
    const { visible } = this.state;
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={visible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default LoadingOverlayController;
