import { call, put } from "redux-saga/effects";
import { TYPES } from "store/types";
import { METHOD_API, createConnector, prefixApi } from "@api/Connector";
import ModalController from "@shared-components/modal/ModalController";

export function* getListStatusProjectSaga(action: any): any {
  try {
    const res = yield call(
      createConnector,
      METHOD_API.GET,
      `${prefixApi}/status`,
      null,
    );
    yield put({ type: TYPES.SET_STATUS_PROJECT, payload: res?.data ?? [] });
  } catch (error: any) {
    ModalController.show({
      message: error?.response?.detail?.message,
      visibleButton2: true,
    });
  }
}

export function* getListLocationProjectSaga(action: any): any {
  try {
    const res = yield call(
      createConnector,

      METHOD_API.GET,
      `${prefixApi}/location`,
      null,
    );
    yield put({ type: TYPES.SET_LOCATION_PROJECT, payload: res?.data ?? [] });
  } catch (error: any) {
    ModalController.show({
      message: error?.response?.detail?.message,
      visibleButton2: true,
    });
  }
}
