import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { MAX_CAPACITY, screenIdSupport } from "@shared-constants";

import { Colors } from "@template/style";
import FileItem from "components/molecules/FileItem";
import TemplateSelectBox from "components/molecules/TemplateSelectBox";
import EditTemplate from "screens/EditTemplate";
import { STORAGE, storeData } from "@utils/Storage";
import styles from "./styles";

interface IProps {
  template: any;
  setTemplate?: any;
  errorMessage?: string[];
  editableTemplate?: boolean;
  data: any;
}

interface ISelectImageFileConfirmProps {
  selectedImages: any;
  selectedFiles: any;
}

const SelectFileReportConfirm: React.VFC<IProps> = ({
  template,
  setTemplate,
  errorMessage = [],
  editableTemplate = false,
  data,
}: IProps) => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [errorMessageList, setErrorMessageList] = useState(errorMessage);

  useEffect(() => {
    if (!openTemplate) setErrorMessageList([]);
  }, [openTemplate]);

  useEffect(() => {
    setErrorMessageList(errorMessage);
  }, [errorMessage]);

  const step = useMemo(() => {
    const str = data?.approval_status;
    const index = str?.indexOf("/");
    if (index != -1) {
      const start = Number(str?.slice(0, index));
      return start;
    }
    return 0;
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader title="内容" sx={styles.header} />
        <CardContent>
          {/* ============= テンプレート ============= */}
          {template ? (
            <TemplateSelectBox
              template={template}
              onClick={() => {
                storeData(STORAGE.PREVIEW_DATA, JSON.stringify(template));
                window.open("/preview");
              }}
              onClick2={() => {
                setOpenTemplate(true);
              }}
              buttonType={editableTemplate ? "preview-edit" : "preview"}
              helperText={errorMessageList.map((v, i) => (
                <React.Fragment key={i}>
                  <span>{v}</span>
                  <br />
                </React.Fragment>
              ))}
              error={errorMessage.length > 0}
            />
          ) : null}
        </CardContent>
        <EditTemplate
          open={openTemplate}
          setOpen={setOpenTemplate}
          type={screenIdSupport[template?.screen_id]}
          infoToEdit={template?.templateEditInfo}
          template_path={template?.template_path}
          onHandleTemplate={(newTemplate, template_image) => {
            setTemplate((prev: any) => ({
              ...prev,
              ...newTemplate,
              template_image: template_image,
            }));
          }}
          isManage={true}
          template_id={data?.work_template_id?.template_id ?? ""}
          work_id={data?.SK ?? ""}
          data_info={data?.work_template_id?.data_info}
          is_landscape={data?.is_landscape}
          step={step + 1} //起票者をstep0とするので、+1する
          isMultiple={data?.work_template_id?.is_multiple}
          extraPage={data?.work_template_id?.extra_template ?? []}
        />
      </Card>
      {/* <Card>
        <CardHeader title="添付" sx={styles.header} />
        <CardContent>
          <SelectImageFileConfirm
            selectedImages={selectedImages}
            selectedFiles={selectedFiles}
          />
        </CardContent>
      </Card> */}
    </>
  );
};

// ====================================
// 添付コンポーネント
// ====================================
export const SelectImageFileConfirm: React.VFC<
  ISelectImageFileConfirmProps
> = ({ selectedImages, selectedFiles }: ISelectImageFileConfirmProps) => {
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  return (
    <Stack>
      {/* =================== 画像 =================== */}
      <FormGroup>
        <FormLabel
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          画像
          <Typography
            sx={{ ml: 1, color: Colors.DISABLE_BTN_TEXT }}
            variant="body2"
          >
            {MAX_CAPACITY}点まで
          </Typography>
        </FormLabel>
        <Grid container spacing={2} my={1}>
          {selectedImages.map((item: any, index: number) => (
            <Grid item xs={4} sm={2} key={index}>
              <Box
                component="img"
                onClick={() => {
                  setOpen(true);
                  setImageSrc(
                    item?.file_type === "image/svg+xml"
                      ? item.path_file_thumb
                      : item.path_file,
                  );
                }}
                src={
                  item?.file_type === "image/svg+xml"
                    ? item.path_file_thumb
                    : item.path_file
                }
                key={item.size}
                sx={{
                  width: "100%",
                  height: { xs: 80, md: 100 },
                  objectFit: "cover",
                  borderRadius: 1,
                  cursor: "pointer",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      <Dialog open={open} onClick={() => setOpen(false)}>
        <Box
          component={"img"}
          src={imageSrc}
          sx={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
        />
      </Dialog>

      {/* =================== 資料 =================== */}
      <FormGroup>
        <FormLabel
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          資料
          <Typography
            sx={{ ml: 1, color: Colors.DISABLE_BTN_TEXT }}
            variant="body2"
          >
            {MAX_CAPACITY}点まで
          </Typography>
        </FormLabel>
        <FileItem data={selectedFiles} editable={false} />
      </FormGroup>
    </Stack>
  );
};

export default SelectFileReportConfirm;
