import { TYPES } from "store/types";

const initialState = {
  currentLevel: 0,
  folderID: {
    level1: {
      PK: "",
      SK: "",
      created_at: "",
      creator_id: "",
      deleted_at: "",
      folder_id: "",
      folder_inside: [],
      folder_name: "",
      is_delete: false,
      total_inside: 0,
      updated_at: "",
    },
    level2: {
      PK: "",
      SK: "",
      created_at: "",
      creator_id: "",
      deleted_at: "",
      folder_id: "",
      folder_inside: [],
      folder_name: "",
      is_delete: false,
      total_inside: 0,
      updated_at: "",
    },
    level3: {
      PK: "",
      SK: "",
      created_at: "",
      creator_id: "",
      deleted_at: "",
      folder_id: "",
      folder_inside: [],
      folder_name: "",
      is_delete: false,
      total_inside: 0,
      updated_at: "",
    },
  },
};

export const FolderLevelReducer = (
  state = initialState,
  { payload, type }: any,
) => {
  switch (type) {
    case TYPES.SET_FOLDERID_LEVEL: {
      let cloneState = {
        currentLevel: payload.currentLevel,
        folderID: { ...state.folderID, ...payload.folderID },
      };
      state = cloneState;
      return state;
    }
    default:
      return state;
  }
};
