import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Card,
  Typography,
  CardContent,
  Divider,
  TextField,
  FormGroup,
  FormControl,
  MenuItem,
  Stack,
  Button,
  SelectChangeEvent,
  // Alert,
  // Snackbar,
} from "@mui/material";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import SelectLabel from "components/atoms/SelectLabel";
import { Colors } from "@template/style";
import { useSize } from "shared/hook/useSize";
import {
  MAX_LENGTH_CONTENT,
  MAX_LENGTH_TITLE,
  TEXT_EMAIL_PAGE,
} from "@shared-constants";
import { cloneDeep, isEqual, sortBy } from "lodash";
import {
  getListDataNotificationEmail,
  saveDataNotificationEmail,
} from "@api/notificationEmail";
import { ResponseSaveNoticeEmail } from "./TypeNoticeEmail";
import { TypeNoticeEmail } from "./TypeNoticeEmail";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { clearData, STORAGE, storeData } from "@utils/Storage";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";

type contentText = {
  [fieldName: string]: string;
};

const initContentText: contentText = {
  [TEXT_EMAIL_PAGE.title]: "",
  [TEXT_EMAIL_PAGE.content]: "",
};

const initContentError: contentText = {
  [TEXT_EMAIL_PAGE.title]: "",
  [TEXT_EMAIL_PAGE.content]: "",
};

interface IInsertTextButton {
  name: string;
  value: string;
  disabledTypeContent?: string[];
  onClick?: Function;
}
const INSERT_TEXT_BUTTON: IInsertTextButton[] = [
  { name: "帳票名", value: "{report_name}" },
  { name: "報告者名", value: "{reporter_name}" },
  { name: "タイトル", value: "{report_front_item}" },
  { name: "通知先名", value: "{notification_name}" },
  {
    name: "異常申請",
    value: "{error_request}",
    disabledTypeContent: [
      TEXT_EMAIL_PAGE.approved,
      TEXT_EMAIL_PAGE.resend,
      TEXT_EMAIL_PAGE.done,
      TEXT_EMAIL_PAGE.deny,
      TEXT_EMAIL_PAGE.cancel,
    ],
  },
  {
    name: "コメント",
    value: "{comment}",
    disabledTypeContent: [TEXT_EMAIL_PAGE.timeReport, TEXT_EMAIL_PAGE.cancel],
  },
  {
    name: "承認ステップ",
    value: "{approval_step}",
    disabledTypeContent: [TEXT_EMAIL_PAGE.timeReport],
  },
  {
    name: "WebURL",
    value: "{web_url}",
    disabledTypeContent: [TEXT_EMAIL_PAGE.deny, TEXT_EMAIL_PAGE.cancel],
  },
];

const InsBtnW = 200;
const InsBtnM = 1;
const InsBtnML = 2;

export const EmailNotification: React.FC = () => {
  const { isSmUp } = useSize();
  const [contentOptions, setContentOption] = useState<TypeNoticeEmail[]>([]);
  const [selectedOptionValue, setSelectedOptionValue] = useState<string>("");
  const [contentText, setContentText] = useState<contentText>(initContentText);
  const [contentError, setContentError] =
    useState<contentText>(initContentError);
  const [originContentOptions, setOriginContentOptions] = useState<
    TypeNoticeEmail[]
  >([]);
  const [originContentText, setOriginContentText] =
    useState<contentText>(initContentText);
  // const [displayAlert, setDisplayAlert] = useState({
  //   isDisplay: false,
  //   message: "",
  // });
  const titleRef = React.useRef<any>();
  const contentRef = React.useRef<any>();
  const [insertTarget, setInsertTarget] = useState<string>("");
  const menuItems = useMemo(
    () =>
      contentOptions.map((item: TypeNoticeEmail) => {
        return (
          <MenuItem key={item.SK} value={item.SK}>
            {item.type_content}
          </MenuItem>
        );
      }),
    [contentOptions],
  );

  const handleDisableBtn = () => {
    if (
      contentText[TEXT_EMAIL_PAGE.title].trim().length < 1 ||
      contentText[TEXT_EMAIL_PAGE.content].trim().length < 1 ||
      !selectedOptionValue ||
      contentError[TEXT_EMAIL_PAGE.title].length > 0 ||
      contentError[TEXT_EMAIL_PAGE.content].length > 0
    ) {
      return true;
    }
    return false;
  };

  const getDataNoticeEmail = async () => {
    LoadingOverlayController.show();
    try {
      const res: TypeNoticeEmail[] = await getListDataNotificationEmail();
      if (res.length > 0) {
        res.forEach((item, index) => {
          if (item.type_content == TEXT_EMAIL_PAGE.timeReport) {
            setSelectedOptionValue(item.SK);
            // item.notification_status = true;
            const contentTextCopy = cloneDeep(contentText);
            contentTextCopy[TEXT_EMAIL_PAGE.title] = item.title;
            contentTextCopy[TEXT_EMAIL_PAGE.content] = item.content;
            setContentText(contentTextCopy);
            setOriginContentText(contentTextCopy);
          }
        });
        setContentOption(sortBy(res, ["seq"]));
        setOriginContentOptions(sortBy(res, ["seq"]));
      }
    } catch (error: any) {
      console.log(error, "error get list data notice");
      ModalController.show({
        message: error.response.data.detail,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getDataNoticeEmail();
  }, []);

  const isChangedData = useMemo(() => {
    if (
      !isEqual(contentOptions, originContentOptions) ||
      !isEqual(contentText, originContentText)
    ) {
      return false;
    }
    return true;
  }, [
    contentOptions,
    selectedOptionValue,
    contentText,
    originContentOptions,
    originContentText,
  ]);
  // 保存確認ポップアップ
  const { setIsEdited } = useRouterPrompt({
    onOK: async () => {
      try {
        if (!handleDisableBtn() && !isChangedData) {
          let result = await handleForwardEmail();
          return result;
        }
        return false;
      } catch (error) {
        console.error("Error in onOK:", error);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_ERROR_001,
          visibleButton2: true,
        });
        return false;
      }
    },
    onCancel: () => {
      clearData(STORAGE.IS_CHANGED);
      clearData(STORAGE.EMAIL_INFO);
      clearData(STORAGE.FLOW_APPROVE_INFO);
      clearData(STORAGE.USER_ROLE);
      clearData(STORAGE.INVALID);
      return true;
    },
    message: messages.ManageScreen.MSG_UNSAVE_CHANGE,
  });
  const checkDataIsChange = useCallback(() => {
    if (
      !isEqual(contentOptions, originContentOptions) ||
      !isEqual(contentText, originContentText)
    ) {
      setIsEdited(true);
      storeData(STORAGE.IS_CHANGED, JSON.stringify(true));
      const payload = {
        contentOptions,
        selectedOptionValue,
        contentText,
      };
      storeData(STORAGE.EMAIL_INFO, JSON.stringify(payload));
      return true;
    } else {
      setIsEdited(false);
      clearData(STORAGE.IS_CHANGED);
      clearData(STORAGE.EMAIL_INFO);
      return false;
    }
  }, [
    contentOptions,
    selectedOptionValue,
    contentText,
    originContentOptions,
    originContentText,
  ]);
  useEffect(() => {
    checkDataIsChange();
  }, [checkDataIsChange]);

  const handleCheck = useCallback(
    (event: any) => {
      const cloneContentOption = cloneDeep(contentOptions);
      cloneContentOption.forEach((item, index) => {
        if (item.SK == event.target.id) {
          item.notification_status = !item.notification_status;
        }
      });
      setContentOption(cloneContentOption);
    },
    [contentOptions],
  );

  const checkBoxList = useMemo(() => {
    const result = [];
    for (const field of contentOptions) {
      const item = (
        <Box key={field.SK} sx={{ mr: 2 }}>
          <CheckboxLabel
            id={field.SK}
            onChange={handleCheck}
            key={field.SK}
            checked={field.notification_status}
            label={field.type_content}
          />
        </Box>
      );
      result.push(item);
    }
    return result;
  }, [contentOptions]);

  const updateTitleContentText = (item: TypeNoticeEmail) => {
    const contentTextCopy = cloneDeep(contentText);
    contentTextCopy[TEXT_EMAIL_PAGE.title] = item.title;
    contentTextCopy[TEXT_EMAIL_PAGE.content] = item.content;
    setContentText(contentTextCopy);
    setOriginContentText(contentTextCopy);
  };

  const handleChangeContentOption = (event: SelectChangeEvent) => {
    const itemOldSelected = contentOptions.find(
      (item: TypeNoticeEmail) => item.SK === selectedOptionValue,
    );

    const itemSelected = contentOptions.find(
      (item: TypeNoticeEmail) => item.SK === event.target.value,
    );
    setInsertTarget("");

    if (itemOldSelected) {
      if (
        itemOldSelected.title !== contentText[TEXT_EMAIL_PAGE.title] ||
        itemOldSelected.content !== contentText[TEXT_EMAIL_PAGE.content]
      ) {
        ModalController.show({
          message: messages.ManageScreen.MSG_UNSAVE_CHANGE,
          visibleButton1: true,
          visibleButton2: true,
          disableFeedback: true,
          button1: { title: messages.COMMON.BUTTON.CANCEL },
          handlePressButton1: () => {
            if (itemSelected) {
              updateTitleContentText(itemSelected);
              setSelectedOptionValue(event.target.value);
            }
          },
          handlePressButton2: async () => {
            // if (contentText[TEXT_EMAIL_PAGE.title].trim().length < 1) {
            //   setDisplayAlert((pre) => {
            //     return {
            //       isDisplay: true,
            //       message: messages.ManageScreen.MSG_TITLE_REQUIRED,
            //     };
            //   });
            //   return;
            // }
            // if (contentText[TEXT_EMAIL_PAGE.content].trim().length < 1) {
            //   setDisplayAlert((pre) => {
            //     return {
            //       isDisplay: true,
            //       message: messages.ManageScreen.MSG_CONTENT_REQUIRED,
            //     };
            //   });
            //   return;
            // }
            // if (
            //   contentText[TEXT_EMAIL_PAGE.title].trim().length < 1 &&
            //   contentText[TEXT_EMAIL_PAGE.content].trim().length < 1
            // ) {
            //   setDisplayAlert((pre) => {
            //     return {
            //       isDisplay: true,
            //       message: messages.ManageScreen.MSG_PLEASE_INPUT_ITEM_REQUIRED,
            //     };
            //   });
            //   return;
            // }
            const error_title = validateText(TEXT_EMAIL_PAGE.title);
            const error_content = validateText(TEXT_EMAIL_PAGE.content);
            if (error_title.length > 0 || error_content.length > 0) {
              const contentErrorCopy = cloneDeep(contentError);
              contentErrorCopy[TEXT_EMAIL_PAGE.title] = error_title;
              contentErrorCopy[TEXT_EMAIL_PAGE.content] = error_content;
              setContentError(contentErrorCopy);
              return;
            }

            setSelectedOptionValue(event.target.value);
            await handleForwardEmail();
            if (itemSelected) {
              updateTitleContentText(itemSelected);
              setSelectedOptionValue(event.target.value);
            }
          },
        });
        return;
      }
    }

    if (itemSelected) {
      updateTitleContentText(itemSelected);
    }

    setSelectedOptionValue(event.target.value);
  };

  const handleOnBlur = (event: any) => {
    const textFieldID = event.target.id;
    if (!contentText[textFieldID]) {
      const contentTextCopy = cloneDeep(contentText);
      contentTextCopy[textFieldID] = initContentText[textFieldID];
      setContentText(contentTextCopy);
    }

    const message = validateText(textFieldID);
    const contentErrorCopy = cloneDeep(contentError);
    contentErrorCopy[textFieldID] = message;
    setContentError(contentErrorCopy);

    setInsertTarget(textFieldID);
  };

  const handleOnFocus = (event: any) => {
    const textFieldID = event.target.id;
    if (contentText[textFieldID] === initContentText[textFieldID]) {
      const contentTextCopy = cloneDeep(contentText);
      contentTextCopy[textFieldID] = "";
      setContentText(contentTextCopy);
    }
  };

  const handleTextChange = useCallback(
    (event: any) => {
      const text = event.target.value;
      const fieldID = event.target.id;
      const contentTextCopy = cloneDeep(contentText);
      contentTextCopy[fieldID] = text;
      setContentText(contentTextCopy);
    },
    [contentText],
  );

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    // setDisplayAlert((pre) => {
    //   return { isDisplay: false, message: "" };
    // });
    return;
  };

  const handleForwardEmail = useCallback(async () => {
    let result = false;
    setIsEdited(false);
    clearData(STORAGE.IS_CHANGED);
    clearData(STORAGE.EMAIL_INFO);
    LoadingOverlayController.show();
    let PK = "";
    let type_content = "";
    const arrayCheckBox = contentOptions.map((item, index) => {
      if (selectedOptionValue == item.SK) {
        PK = item.PK;
        type_content = item.type_content;
      }
      return {
        PK: item.PK,
        SK: item.SK,
        notification_status: item.notification_status,
      };
    });

    const data = {
      object_data: {
        PK,
        SK: selectedOptionValue,
        type_content,
        title: contentText[TEXT_EMAIL_PAGE.title],
        content: contentText[TEXT_EMAIL_PAGE.content],
      },
      list_checkbox_status: arrayCheckBox,
    };

    try {
      const res: ResponseSaveNoticeEmail = await saveDataNotificationEmail(
        data,
      );
      if (res) {
        const cloneContentOption = cloneDeep(contentOptions);
        result = true;
        cloneContentOption.forEach((item, index) => {
          if (item.SK == res.SK) {
            item.title = res.title;
            item.content = res.content;
          }
        });

        setContentOption(cloneContentOption);
        setOriginContentOptions(cloneContentOption);
        setOriginContentText(contentText);

        ModalController.show({
          message: messages.ManageScreen.MSG_SAVE_NOTICE_EMAIL_SUCCESS,
          visibleButton2: true,
        });
      }
    } catch (error: any) {
      console.log(error, "error save notice email ???");
    } finally {
      LoadingOverlayController.hide();
      return result;
    }
  }, [selectedOptionValue, contentText, contentOptions]);

  const validateText = useCallback(
    (fieldID: string, value: string = "") => {
      if (value === "") value = contentText[fieldID];
      let message = "";

      switch (fieldID) {
        case TEXT_EMAIL_PAGE.title:
          message = Validation.validate({
            type: "text",
            value: value,
            name: fieldID,
            required: true,
            max_length: MAX_LENGTH_TITLE,
          });
          break;
        case TEXT_EMAIL_PAGE.content:
          message = Validation.validate({
            type: "text",
            value: value,
            name: fieldID,
            required: true,
            max_length: MAX_LENGTH_CONTENT,
          });
          break;
      }
      return message;
    },
    [contentText],
  );

  const insertText = useCallback(
    (ins_text: string) => {
      let ref;
      if (insertTarget === TEXT_EMAIL_PAGE.title) {
        ref = titleRef;
      } else if (insertTarget === TEXT_EMAIL_PAGE.content) {
        ref = contentRef;
      }

      if (!ref) return;

      const e = ref.current;
      const selectionStart = e.selectionStart;
      const selectionEnd = e.selectionEnd;
      const afterSelectionStart = selectionStart + ins_text.length;

      // insert text
      const text =
        e.value.slice(0, selectionStart) +
        ins_text +
        e.value.slice(selectionEnd);
      const message = validateText(insertTarget, text);

      const contentErrorCopy = cloneDeep(contentError);
      contentErrorCopy[insertTarget] = message;
      setContentError(contentErrorCopy);

      // set text
      const contentTextCopy = cloneDeep(contentText);
      contentTextCopy[insertTarget] = text;
      setContentText(contentTextCopy);

      // set selection
      setTimeout(() => {
        e.focus({ preventScroll: true });
        e.setSelectionRange(afterSelectionStart, afterSelectionStart);
      }, 10);
    },
    [insertTarget, titleRef, contentRef, contentText, contentError],
  );

  const InsertTextButton: React.FC<IInsertTextButton> = useCallback(
    ({ name, value, disabledTypeContent = [], onClick = () => {} }) => {
      let disabled = false;

      if (disabledTypeContent.length > 0) {
        const type_content =
          contentOptions.find((item) => item.SK === selectedOptionValue)
            ?.type_content ?? "";
        if (disabledTypeContent.indexOf(type_content) != -1) {
          disabled = true;
        }
      }

      return (
        <Button
          sx={{
            alignSelf: "flex-start",
            ml: { md: InsBtnML, xs: 0 },
            m: InsBtnM,
            minWidth: InsBtnW + "px",
          }}
          onClick={() => onClick(value)}
          disabled={disabled}
        >
          {`${name}を挿入する`}
        </Button>
      );
    },
    [contentOptions, selectedOptionValue],
  );

  return (
    <Box>
      <Card
        sx={{
          ...styles.card,
          borderRadius: 0,
          boxShadow: 0,
          width: "100%",
          minHeight: "70vh",
          borderColor: Colors.MAIN_GREEN,
          borderStyle: "solid",
          borderWidth: 0.5,
        }}
      >
        {/* <Snackbar
          open={displayAlert.isDisplay}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%", height: "100%" }}
          >
            {displayAlert.message}
          </Alert>
        </Snackbar> */}
        <CardContent>
          <Typography
            variant="inherit"
            sx={{ color: Colors.MAIN_GREEN, mb: 1 }}
          >
            {TEXT_EMAIL_PAGE.targetNotiField}
          </Typography>
          <FormGroup row={isSmUp ? true : false} sx={{ ml: 2 }}>
            {checkBoxList}
          </FormGroup>
          <Divider sx={{ my: 2 }} />
          <Typography
            variant="inherit"
            sx={{ color: Colors.MAIN_GREEN, mb: 1 }}
          >
            {TEXT_EMAIL_PAGE.contentField}
          </Typography>
          <FormControl fullWidth>
            <Stack sx={{ ml: 2 }}>
              {contentOptions.length > 0 ? (
                <Stack direction={{ md: "row", xs: "column" }}>
                  <div style={{ minWidth: styles.selectLabel.width }}>
                    <SelectLabel
                      value={selectedOptionValue}
                      onChange={handleChangeContentOption}
                      label={TEXT_EMAIL_PAGE.targetOption}
                      sx={styles.selectLabel}
                      defaultValue={""}
                      isIgnoreExtractRuleLabel={false}
                      isIgnoreExtractRuleSelect={false}
                    >
                      {menuItems}
                    </SelectLabel>
                  </div>
                  <Stack
                    flexWrap="wrap"
                    direction={{ md: "row", xs: "column" }}
                    flexGrow={1}
                    alignItems="center"
                    maxWidth={`${
                      (InsBtnW + (InsBtnM + InsBtnML) * 8) * 4
                    }px`} /* 1行にボタンを4つまで表示 */
                    spacing={0}
                  >
                    {INSERT_TEXT_BUTTON.map((item, index) => (
                      <InsertTextButton
                        name={item.name}
                        value={item.value}
                        disabledTypeContent={item.disabledTypeContent}
                        onClick={() => {
                          insertText(item.value);
                        }}
                        key={index}
                      />
                    ))}
                  </Stack>
                </Stack>
              ) : null}

              <TextField
                id={TEXT_EMAIL_PAGE.title}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={handleTextChange}
                value={contentText[TEXT_EMAIL_PAGE.title]}
                label={
                  <>
                    <LabelRequired title={TEXT_EMAIL_PAGE.title} />
                  </>
                }
                inputProps={{ maxLength: MAX_LENGTH_TITLE }}
                inputRef={titleRef}
                helperText={contentError[TEXT_EMAIL_PAGE.title]}
                error={contentError[TEXT_EMAIL_PAGE.title].length > 0}
              />
              <TextField
                id={TEXT_EMAIL_PAGE.content}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={handleTextChange}
                value={contentText[TEXT_EMAIL_PAGE.content]}
                label={
                  <>
                    <LabelRequired title={TEXT_EMAIL_PAGE.content} />
                  </>
                }
                multiline
                rows={5}
                inputProps={{ maxLength: MAX_LENGTH_CONTENT }}
                inputRef={contentRef}
                helperText={contentError[TEXT_EMAIL_PAGE.content]}
                error={contentError[TEXT_EMAIL_PAGE.content].length > 0}
              />
            </Stack>
          </FormControl>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleForwardEmail}
          color="secondary"
          sx={styles.button}
          disabled={handleDisableBtn() || isChangedData}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  card: { mb: 2, bgcolor: Colors.BG_GRAY },
  title: { mt: 2, mb: 1 },
  selectLabel: { width: 200 },
  button: { mr: 1, my: 2 },
} as const;
