import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  ButtonTypeMap,
} from "@mui/material";
import { useCallback } from "react";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";

interface IProps {
  open: boolean;
  setOpen: Function;
}

interface IOption {
  title: string;
  color: ButtonTypeMap["props"]["color"];
  location: string;
  extra_data: number;
}

const options: Array<IOption> = [
  {
    title: "報告  新規作成",
    color: "primary",
    location: "/work/create",
    extra_data: 1,
  },
  {
    title: "報告  コピー作成",
    color: "primary",
    location: "/work/copy",
    extra_data: 1,
  },
];

const SelectCreationWorkDialog = ({ open, setOpen }: IProps) => {
  const history = useHistoryCustom();
  const renderOptions = () => {
    return options.map((item, index) => {
      const { title, color, location, extra_data } = item;
      return (
        <Button
          color={color}
          onClick={() => {
            handleClose();
            history.pushWithRef(location, { typeAction: extra_data });
          }}
          key={index}
        >
          {title}
        </Button>
      );
    });
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      sx={{ "& .MuiDialog-paper": { width: "50%", py: 2 } }}
    >
      <DialogContent>
        <Stack>
          <Typography>何を作成しますか？</Typography>
          {renderOptions()}
          <Button onClick={() => handleClose()} variant="outlined">
            キャンセル
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SelectCreationWorkDialog;
