import { useState, VFC } from "react";
import dayjs, { Dayjs } from "dayjs";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Typography,
  CardContent,
  Divider,
  TextField,
  Checkbox,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { AddCircle, Cancel, PlaylistAddCheck } from "@mui/icons-material";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import PasswordInput from "components/atoms/PasswordInput";
import SelectLabel from "components/atoms/SelectLabel";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import PopupSort from "components/atoms/PopupSort";
import Dropzone from "components/atoms/Dropzone";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { downloadXlsx, Tformat } from "@utils/CSV";
import { Colors } from "@template/style";
import { useSize } from "shared/hook/useSize";
import { Validation } from "@validation";
import PopupMenu from "components/atoms/PopupMenu";

// dummy data
const data = [
  { id: "ID-1", name: "name1" },
  { id: "ID-2", name: "name2" },
];
const columns = [
  { header: "ID", key: "id" },
  { header: "名前", key: "name" },
];

const SampleScreen: VFC = () => {
  const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));
  const [date_error, setDateError] = useState<string>("");
  const { isSmUp } = useSize();

  const handleChangeDate = (newValue: Date | null) => {
    setDateError("");
    if (newValue) {
      const error = Validation.validateDate(newValue, "Date", false);
      setDateError(error);
    }
    setDate(dayjs(newValue));
  };

  const handleMessage = () => {
    ModalController.show({
      message: "Message",
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () => console.log("Click OK"),
    });
  };

  const handleLoading = () => {
    LoadingOverlayController.show();
    setTimeout(() => {
      LoadingOverlayController.hide();
    }, 3000);
  };

  const handleDownload = async (format: Tformat) => {
    LoadingOverlayController.show();
    await downloadXlsx({
      data: data,
      columns: columns,
      filename: "TEST." + format,
      format: format,
    }).finally(() => {
      LoadingOverlayController.hide();
    });
  };

  return (
    <GenericTemplate title="Sample">
      <Card sx={styles.card}>
        <CardHeader title="Inputs" />
        <CardContent>
          {/* ======================= TextField ======================= */}
          <Typography variant="subtitle1" sx={styles.title}>
            TextField
          </Typography>

          <FormGroup>
            {/* stack default props: { spacing: 2 } */}
            <Stack>
              <TextField label="Label" value="Value" />
              <TextField label="Required" value="Value" required />
            </Stack>
          </FormGroup>
          <FormGroup row sx={{ mt: 2 }}>
            <Stack direction={isSmUp ? "row" : "column"}>
              <TextField label="Disabled" value="Value" disabled />
              <TextField
                label="Error"
                value="Value"
                error
                helperText="Error text..."
              />
              <TextField label="Multiline" multiline rows={4} />
              <PasswordInput helperText="<PasswordInput />" />
            </Stack>
          </FormGroup>

          {/* ======================= Checkbox ======================= */}
          <Typography variant="subtitle1" sx={styles.title}>
            Checkbox
          </Typography>
          <FormGroup sx={{ alignItems: "flex-start" }}>
            <Checkbox />
            <CheckboxLabel label="End Label" defaultChecked />
            <CheckboxLabel label="Disabled" disabled />
            <CheckboxLabel label="Start Label" labelPlacement="start" />
          </FormGroup>

          {/* ======================= Select ======================= */}
          <Typography variant="subtitle1" sx={styles.title}>
            Select
          </Typography>
          <FormGroup>
            <FormControl>
              <Select defaultValue={"Item1"}>
                <MenuItem value={"Item1"}>Item1</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
          <FormGroup row sx={{ mt: 2 }}>
            <Stack direction={isSmUp ? "row" : "column"}>
              <SelectLabel label="Label" sx={styles.selectLabel}>
                <MenuItem value={"Item1"}>Item1</MenuItem>
              </SelectLabel>
              <SelectLabel label="Required" sx={styles.selectLabel} required>
                <MenuItem value={"Item1"}>Item1</MenuItem>
              </SelectLabel>
              <SelectLabel label="Disabled" sx={styles.selectLabel} disabled>
                <MenuItem value={"Item1"}>Item1</MenuItem>
              </SelectLabel>
              <SelectLabel
                label="Error"
                helperText="Error text..."
                error
                sx={styles.selectLabel}
              >
                <MenuItem value={"Error"}>Error</MenuItem>
              </SelectLabel>
            </Stack>
          </FormGroup>

          {/* ======================= DatePicker ======================= */}
          <Typography variant="subtitle1" sx={styles.title}>
            DatePicker
          </Typography>
          <FormGroup row>
            <Stack direction={isSmUp ? "row" : "column"}>
              <DatePickerCustom
                value={date}
                onChange={handleChangeDate}
                label="Label"
                helperText={date_error}
                error={date_error.length > 0}
              />
              <DatePickerCustom
                value={date}
                onChange={handleChangeDate}
                label="Required"
                required
              />
              <DatePickerCustom
                value={date}
                onChange={handleChangeDate}
                label="Disabled"
                disabled
              />
              <DatePickerCustom
                value={date}
                onChange={handleChangeDate}
                label="Error"
                helperText="Error text..."
                error
              />
            </Stack>
          </FormGroup>
        </CardContent>
      </Card>

      <Card sx={styles.card}>
        <CardHeader title="Button" />
        <CardContent>
          {/* ======================= Button ======================= */}
          <Button color="primary" sx={styles.button}>
            primary
          </Button>
          <Button color="secondary" sx={styles.button}>
            secondary
          </Button>
          <Button color="success" sx={styles.button}>
            success
          </Button>
          <Button color="error" sx={styles.button}>
            error
          </Button>
          <Button color="warning" sx={styles.button}>
            warning
          </Button>
          <Button color="primary" variant="outlined" sx={styles.button}>
            outlined
          </Button>
          <br />
          <Button
            color="secondary"
            startIcon={<AddCircle />}
            sx={styles.button}
          >
            startIcon
          </Button>
          <Button color="secondary" endIcon={<AddCircle />} sx={styles.button}>
            endIcon
          </Button>
        </CardContent>
      </Card>

      <Card sx={styles.card}>
        <CardHeader title="Modal" />
        <CardContent>
          {/* ======================= Modal ======================= */}
          <Button variant="text" onClick={handleMessage} sx={styles.button}>
            Message
          </Button>
          <Button variant="text" onClick={handleLoading} sx={styles.button}>
            Loading
          </Button>
        </CardContent>
      </Card>

      <Card sx={styles.card}>
        <CardHeader title="Download" />
        <CardContent>
          {/* ======================= CSV ======================= */}
          <Button
            variant="text"
            onClick={() => {
              handleDownload("csv");
            }}
            sx={styles.button}
          >
            CSV
          </Button>
          <Button
            variant="text"
            onClick={() => {
              handleDownload("xlsx");
            }}
            sx={styles.button}
          >
            Xlsx
          </Button>
        </CardContent>
      </Card>

      <Card sx={styles.card}>
        <CardHeader title="Dropzone" />
        <CardContent>
          {/* ======================= Dropzone ======================= */}
          <Dropzone />
        </CardContent>
      </Card>

      <Card sx={styles.card}>
        <CardHeader title="Menu / Sort" />
        <CardContent>
          {/* ======================= Menu / Sort ======================= */}
          <PopupMenu
            data={[
              {
                name: "一括完了",
                onClick: () => {},
                icon: <PlaylistAddCheck />,
              },
              {
                name: "削除",
                onClick: () => {},
                icon: <Cancel />,
                itemSx: { color: Colors.ERROR_TEXT },
              },
            ]}
          />
          <PopupSort
            data={[
              {
                title: "更新が新しい順",
                onClick: () => {},
              },
              {
                title: "更新が古い順",
                onClick: () => {},
              },
            ]}
            defaultIndex={0}
          />
        </CardContent>
      </Card>

      <Card sx={styles.card}>
        <CardHeader title="Example - メール通知" />
        <CardContent>
          <Typography
            variant="inherit"
            sx={{ color: Colors.MAIN_GREEN, mb: 1 }}
          >
            通知対象
          </Typography>
          <FormGroup row={isSmUp ? true : false}>
            <CheckboxLabel label="報告時" />
            <CheckboxLabel label="差し戻し時" />
            <CheckboxLabel label="完了時" />
          </FormGroup>
          <Divider sx={{ my: 2 }} />
          <Typography
            variant="inherit"
            sx={{ color: Colors.MAIN_GREEN, mb: 1 }}
          >
            通知内容
          </Typography>
          <FormControl fullWidth>
            <Stack>
              <SelectLabel label="内容選択" sx={styles.selectLabel}>
                <MenuItem>報告時</MenuItem>
              </SelectLabel>
              <TextField label="題名" required />
              <TextField label="本文" required multiline rows={5} />
            </Stack>
          </FormControl>
        </CardContent>
      </Card>
    </GenericTemplate>
  );
};

export default SampleScreen;

const styles = {
  card: { mb: 2 },
  title: { mt: 2, mb: 1 },
  selectLabel: { width: 200 },
  button: { mr: 1, my: 1 },
} as const;
