import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { Template } from "services/models";
import { Colors } from "@template/style";
import { ZoomIn } from "@mui/icons-material";
import { useSize } from "shared/hook/useSize";

interface IProps {
  data: Template;
  onClick: Function;
  onClickGlass: Function;
  selectedTemplateId: string;
}
const TemplateItem: React.FC<IProps> = ({
  data,
  onClick = () => {},
  onClickGlass = () => {},
  selectedTemplateId,
}) => {
  const { isSmUp } = useSize();
  return (
    <Card
      sx={[
        {
          position: "relative",
          cursor: "pointer",
        },
        data?.SK === selectedTemplateId && styles.selected,
      ]}
      onClick={() => onClick()}
    >
      <div>
        <CardMedia
          component="img"
          src={data?.template_image_thumb ?? ""}
          alt="template"
          height={isSmUp ? 180 : 130}
        />

        <IconButton
          sx={{
            bgcolor: "white",
            position: "absolute",
            top: 10,
            right: 10,
            "&:hover, &:focus": {
              backgroundColor: Colors.HEADER_COLOR_GREEN,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            (document.activeElement as HTMLElement).blur();
            onClickGlass();
          }}
        >
          <ZoomIn />
        </IconButton>
      </div>
      <CardContent>
        <Typography className={"IgnoreExtractRuleTarget"}>
          {data?.template_name ?? ""}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TemplateItem;

const styles = {
  selected: {
    borderWidth: 10,
    border: "solid",
    borderColor: Colors.MAIN_GREEN,
    bgcolor: Colors.HEADER_COLOR_GREEN,
  },
} as const;
