import { combineReducers } from "redux";
import { projectReducer } from "./ProjectReducer";
import { sortReducer } from "./SortReducer";
import { manageReducer } from "./manageReducer";
import { authReducer } from "./AuthReducer";
import { filterReducer } from "./FilterReducer";
import { userInfoReducer } from "./UserInfoReducer";
import { managerMasterReducer } from "./ManagerMaster";
import { FolderLevelReducer } from "./FolderLevelReducer";
import { approveFlowReducer } from "./ApproveFlowReducer";
import { TableCustomReducer } from "./TableCustom";
import { MaillConfirmReducer } from "./MaillConfirm";
import { favoriteProjectInfoReducer } from "./FavoriteProjectInfoReducer";
import { devanSearchReducer } from "./DevanSearchReducer";
import { contractReducer } from "./ContractReducer";
import { editTemplateReducer } from "./EditTemplateReducer";
import { masterYuasaReducer } from "./MasterYuasaReducer";

export const rootReducer = combineReducers({
  authInfo: authReducer,
  managerMaster: managerMasterReducer,
  projectInfo: projectReducer,
  sort: sortReducer,
  manageInfo: manageReducer,
  filter: filterReducer,
  userInfo: userInfoReducer,
  folderLevel: FolderLevelReducer,
  approveFlow: approveFlowReducer,
  tableCustom: TableCustomReducer,
  mailConfirm: MaillConfirmReducer,
  favoriteProjectInfo: favoriteProjectInfoReducer,
  devanSearch: devanSearchReducer,
  contract: contractReducer,
  editTemplate: editTemplateReducer,
  masterYuasa: masterYuasaReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
