import { METHOD_API, createConnector, getParamsHelp } from "./Connector";
const prefixApi = "api/v1";

export type paramGetDataOfFolder = {
  sort_key_folder: string;
  keyword_file: string;
  last_key: string;
  flag_sort: string;
  filter_condition: string;
};

// type pramGetDataTreeMenu = {
//   project_id: string;
// };

// type paramGetFilterDataOfFolder = {
//   folder_id: string;
//   filter_condition: string;
// };

// type paramDeleteFolderAndFile = {
//   list_sort_key_folder?: {
//     SKS: string[];
//   };
//   list_sk_file?: any;
// };

export type paramGetListFileByKeywordSearch = {
  folder_id: string;
  keyword_file: string;
};
export const getListFolderFileApi = (projectId: string, sort?: string) => {
  const flagSort = sort ? `&flag_sort=${sort}` : "";
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-folders-files-by-projectid?${getParamsHelp({
      project_id: projectId,
    })}${flagSort}`,
    null,
  );
};

export const getDataOfFolderApi = (params: paramGetDataOfFolder) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-data-folder?${getParamsHelp(params)}`,
    null,
  );
};

// export const getListFileByKeywordSearchApi = (
//   params: paramGetListFileByKeywordSearch,
// ) => {
//   return callHttpClientMethod(
//     `${prefixApi}/search-file?${getParamsHelp(params)}`,
//     HttpMethod.Get,
//   );
// };

export const createFolderApi = (data: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/create-folder`,
    data
  );
};

// export const getTreeFolderAPI = (params: pramGetDataTreeMenu) => {
//   return callHttpClientMethod(
//     `${prefixApi}/get-tree-folder?${getParamsHelp(params)}`,
//     HttpMethod.Get,
//   );
// };
// export const uploadMultipleFileAPI = async (data: FormData) => {
//   try {
//     const response = await callHttpClientMethod(
//       `${prefixApi}/upload-multiple-files-for-folder`,
//       HttpMethod.Post,
//       data,
//       // {
//       // "Content-Type": "multipart/form-data",
//       // Accept: "application/json",
//       // },
//     );
//     return response;
//   } catch (error: any) {
//     console.log(error, "error api upload multi file apiiiiiiiiiiiii");
//   }
// };
// export const uploadMultipleFileFolderAutoAPI = async (data: FormData) => {
//   const response = await callHttpClientMethod(
//     `${prefixApi}/upload-multiple-files-for-folder-not-folder-auto`,
//     HttpMethod.Post,
//     data,
//   );
//   return response;
// };

// export const getFilterFileOnFolder = (params: paramGetFilterDataOfFolder) => {
//   return callHttpClientMethod(
//     `${prefixApi}/filter-file-on-folder?${getParamsHelp(params)}`,
//     HttpMethod.Get,
//   );
// };

export const getListTypeFile = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/get-type-file`, null);
};

// export const moveFileApi = (
//   data: { PK: string; SK: string }[],
//   params: { folderid_destination: string },
// ) => {
//   return callHttpClientMethod(
//     `${prefixApi}/move-file-on-folder?${getParamsHelp(params)}`,
//     HttpMethod.Post,
//     data,
//   );
// };

// export const deleteFolderAndFileAPI = (
//   data: paramDeleteFolderAndFile,
//   params: { folderid_current: string },
// ) => {
//   return callHttpClientMethod(
//     `${prefixApi}/delete-folder?${getParamsHelp(params)}`,
//     HttpMethod.Delete,
//     data,
//   );
// };

// export const getDetailFreeFolder = (params: { project_id: string }) => {
//   return callHttpClientMethod(
//     `${prefixApi}/get-detail-free-folder?${getParamsHelp(params)}`,
//     HttpMethod.Get,
//   );
// };
