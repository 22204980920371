import { useEffect, useState, VFC } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemText,
  Switch,
} from "@mui/material";
import GenericTemplate from "@template/index";
import {
  ExpandLess,
  ExpandMore,
  Folder,
  Star,
  StarBorder,
} from "@mui/icons-material";
import { Colors } from "@template/style";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { TYPES } from "store/types";
import { useDispatch, useSelector } from "react-redux";
import { getExistsProject, getProjectByRreportType } from "@api/project";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { cloneDeep } from "lodash";
import { getUserInfo } from "@utils/index";
import { MAX_FAVORITE, STATUS_WORK_TLOG } from "@shared-constants";
import TreeViewCustom from "components/atoms/TreeViewCustom";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { RootState } from "store/reducer";
import "./index.css";
import { getCountHandoverWorkList, getHandoverWorkList } from "@api/work";

const SelectReportScreen: VFC = () => {
  const [listReport, setListReport] = useState<any[]>([]);
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [isOnFavoriteList, setIsOnFavoriteList] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<any>();
  const userInfo = getUserInfo();
  const history = useHistoryCustom();
  const favorite = useSelector((state: RootState) => state.favoriteProjectInfo);
  const dispatch = useDispatch();

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const getData = async () => {
    LoadingOverlayController.show();
    try {
      await getProjectByRreportType().then((res) => {
        if (res?.data) {
          // title、階層を追加
          const data = res.data.map((item0: any) => {
            item0.title = "";
            item0.level = 0;
            item0.folders_inside = item0.folders_inside.map((item1: any) => {
              item1.title = "";
              item1.level = 1;
              item1.folders_inside = item1.folders_inside.map((item2: any) => {
                item2.title = "";
                item2.level = 2;
                return item2;
              });
              return item1;
            });
            return item0;
          });
          setListReport(data);
          isOnFavoriteList
            ? setSelectedList(getOnlyFavoriteList(data))
            : setSelectedList(data);
        }
      });
    } catch (e: any) {
      console.log(e);
      ModalController.show({
        message: e.res?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getOnlyFavoriteList = (reportData: any[]) => {
    const copyListReport = cloneDeep(reportData);
    //お気に入り帳票を含むフォルダ(第一階層)を絞り込む
    const favorite_list = copyListReport.filter((el) =>
      favorite.some((item: any) => item.report_type_id === el.SK),
    );
    if (favorite_list.length) {
      favorite_list.forEach((el) => {
        //お気に入り帳票を含むフォルダ(第二階層)を絞り込む
        const new_folder_list = el.folders_inside.filter((e: any) =>
          favorite.some((item: any) => item.report_id === e.SK),
        );
        el.folders_inside = new_folder_list;
        new_folder_list.forEach((element: any) => {
          //お気に入り帳票(第三階層)を絞り込む
          const new_folder_list = element.folders_inside.filter((e: any) =>
            favorite.some((item: any) => item.SK === e.SK),
          );
          element.folders_inside = new_folder_list;
        });
      });
      return favorite_list;
    }
    return [];
  };

  useEffect(() => {
    getData();
  }, []);

  // ------------------------------------------------------------------
  // ツリービュー
  // ------------------------------------------------------------------
  const switcherIcon = (obj: any) => {
    if (obj.isLeaf) {
      return <Box sx={{ width: "24px" }} />;
    }
    return obj.expanded ? <ExpandLess /> : <ExpandMore />;
  };

  const titleRender = (node: any) => {
    let is_favorite = favorite?.some((el) => el.SK === node.SK);
    let is_selected = node.SK == selectedReport?.SK;
    return (
      <ListItem
        sx={{ py: node.level == 2 ? 0 : 1 }}
        onClick={(e) => {
          if (node.level == 2) {
            e.stopPropagation();
            handleSelectReport(node);
          }
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {(node.level == 0 || node.level == 1) && <Folder sx={{ mr: 1 }} />}
          {node.level == 2 && (
            <Checkbox
              checked={is_selected}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectReport(node);
              }}
            />
          )}
          <span
            className="IgnoreExtractRuleTarget"
            style={{
              color: is_selected ? Colors.MAIN_GREEN_LIGHT : undefined,
            }}
          >
            {node.level == 0
              ? `${node.report_type_code}:${node.report_type_name}`
              : node.level == 1
              ? `${node.report_number}:${node.report_name}`
              : node.level == 2
              ? `${node.project_name}`
              : null}
          </span>
          <Box sx={{ flexGrow: 1 }} />
          {node.level == 2 && (
            <FavoriteButton item={node} isFavorite={is_favorite} />
          )}
        </ListItemText>
      </ListItem>
    );
  };

  const FavoriteButton = ({
    item,
    isFavorite,
  }: {
    item: any;
    isFavorite: boolean;
  }) => {
    return (
      <IconButton
        sx={{ mr: 1 }}
        onClick={(e) => {
          e.stopPropagation();
          handleUpdateFavorite(item);
        }}
      >
        {isFavorite ? (
          <Star sx={{ color: Colors.SECONDARY }} />
        ) : (
          <StarBorder sx={{ color: Colors.BORDER }} />
        )}
      </IconButton>
    );
  };

  const handleSelectReport = (value: any) => {
    setSelectedReport(selectedReport?.SK == value.SK ? undefined : value);
  };

  const handleUpdateFavorite = (item: any) => {
    let clone_favorite = [...favorite];
    let exist = favorite.findIndex((el) => el.SK === item.SK);
    if (exist >= 0) {
      // delete
      clone_favorite.splice(exist, 1);
    } else {
      // add
      if (favorite.length < MAX_FAVORITE) {
        clone_favorite.push({
          PK: item.PK,
          SK: item.SK,
          report_id: item.report_id,
          report_type_id: item.report_type_id,
        });
      } else {
        if (!ModalController.isShowing()) {
          ModalController.show({
            message: messages.PROJECT.MSG_MAX_FAVORITE,
            visibleButton2: true,
          });
        }
        return;
      }
    }
    // update favorite
    dispatch({
      type: TYPES.SET_FAVORITE_PROJECT_INFO,
      payload: clone_favorite,
    });
    dispatch({ type: TYPES.SET_FAVORITE_CONDITION });
  };

  // ------------------------------------------------------------------
  // お気に入りのみ表示
  // ------------------------------------------------------------------
  useEffect(() => {
    if (isOnFavoriteList) {
      const updateList = getOnlyFavoriteList(listReport);
      setSelectedList(updateList);
    } else {
      setSelectedList(listReport);
    }
  }, [isOnFavoriteList, listReport, favorite]);

  // ------------------------------------------------------------------
  // ボタン
  // ------------------------------------------------------------------
  const checkExistProject = async (
    showMessage: boolean = true,
  ): Promise<boolean> => {
    const formData = {
      project_sk: selectedReport["SK"],
      project_location_id: selectedReport["project_location_id"],
    };
    const res = await getExistsProject(formData);

    if (!res?.data && showMessage) {
      ModalController.show({
        message: messages.PROJECT.MSG_PROJECT_DELETD,
        visibleButton2: true,
      });
    }
    return res?.data;
  };

  const checkExistHandoverWorkList = async (showMessage: boolean = true) => {
    let count = 0;
    const formData = {
      SK: selectedReport["SK"],
      project_location_id: selectedReport["project_location_id"],
    };
    await getCountHandoverWorkList(formData).then((res) => {
      count = res.data;
    });

    if (count === 0 && showMessage) {
      ModalController.show({
        message: messages.PROJECT.MSG_HANDOVER_PROJECT_NOT_EXIST,
        visibleButton2: true,
      });
    }
    return count;
  };

  const handleClickCreateReport = async () => {
    try {
      LoadingOverlayController.show();
      if (!(await checkExistProject())) return;
      dispatch({
        type: TYPES.SET_PROJECT_INFO,
        payload: selectedReport,
      });
      history.pushWithRef("work/create");
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleClickOpenHandover = async () => {
    try {
      LoadingOverlayController.show();
      if (!(await checkExistProject())) return;
      const count_handover_work_list = await checkExistHandoverWorkList();
      if (count_handover_work_list === 0) return;

      dispatch({
        type: TYPES.SET_PROJECT_INFO,
        payload: selectedReport,
      });

      if (count_handover_work_list === 1) {
        // 引継ぎ帳票が1件のときは帳票作成画面へ遷移
        const formData = {
          SK: selectedReport["SK"],
          template_id: selectedReport["template_project_id"]["template_id"],
          project_location_id: selectedReport["project_location_id"],
        };
        await getHandoverWorkList(formData).then((res) => {
          if (res?.data) {
            res.data[0]["work_status"] = STATUS_WORK_TLOG.DRAFT;
            res.data[0]["project_id"] = selectedReport["SK"];
            res.data[0]["template_project_id"] =
              selectedReport["template_project_id"];
            history.pushWithRef("work/create", {
              data: res.data[0],
              is_from_handover_report: true,
            });
          }
        });
      } else {
        // 引継ぎ帳票が複数のときは引継ぎ帳票選択画面へ遷移
        dispatch({
          type: TYPES.SET_EACH_FILTER_CONDITION,
          payload: {
            type_screen: "handover_report",
            data: {
              author: [
                {
                  id: userInfo?.SK,
                  name: userInfo?.full_name,
                },
              ],
              location: [],
            },
          },
        });
        const timerId = setInterval(() => {
          if (!LoadingOverlayController.isShowing()) {
            history.pushWithRef("select-report/handover", {
              template_id: selectedReport["template_project_id"]["template_id"],
              project_location_id: selectedReport["project_location_id"],
              project_id: selectedReport["SK"],
              template_project_id: selectedReport["template_project_id"],
            });
            clearInterval(timerId);
          }
        }, 100);
      }
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <GenericTemplate title="帳票選択">
      <FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={isOnFavoriteList}
              onChange={(_, checked) => {
                setIsOnFavoriteList(checked);
              }}
            />
          }
          label="お気に入りのみ表示"
        />
      </FormControl>
      <TreeViewCustom
        prefixCls="rc-tree-select-report"
        fieldNames={{ key: "SK", children: "folders_inside" }}
        style={{
          padding: "8px 0px",
          marginBottom: "30px",
        }}
        treeData={selectedList}
        showIcon={false}
        switcherIcon={switcherIcon}
        titleRender={titleRender}
        selectable={false}
        expandAction="click"
      />
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          justifyContent: "center",
          width: "100%",
          gap: 1,
        }}
      >
        <Button
          onClick={handleClickCreateReport}
          color="secondary"
          disabled={!selectedReport}
        >
          新規起票
        </Button>
        <Button
          onClick={handleClickOpenHandover}
          disabled={!selectedReport?.is_daily}
        >
          引継いで起票
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default SelectReportScreen;
