import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import GenericTemplate from "@template/index";
import { clearData, getDataStorage, STORAGE } from "@utils/Storage";
import "./preview.css";
import messages from "config/messages";
import { useSize } from "shared/hook/useSize";
import { createObjectURL } from "@utils/index";
import { getDetailProjectByWorkId } from "@api/project";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const DownloadButtonWidth = "120px";

const PreviewTlogScreen = () => {
  const [data, setData] = useState<any>({});
  const [totalPages, setTotalPages] = useState(0);
  const { isSmUp, isMdUp } = useSize();
  const reportNameRef = useRef<string>("");

  useEffect(() => {
    const data_str = getDataStorage(STORAGE.PREVIEW_DATA, "{}");
    setData(JSON.parse(data_str));
    clearData(STORAGE.PREVIEW_DATA);
  }, []);

  function onLoadSuccess({ numPages }: { numPages: number }) {
    setTotalPages(numPages);
  }

  const _renderPdfPages = () => {
    return (
      <Stack>
        {[...Array(totalPages)].map((_, i) => (
          <Page
            pageNumber={i + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            error={messages.PREVIEW.MSG_ERROR}
            width={1200}
            key={i}
          />
        ))}
      </Stack>
    );
  };

  const getReportName = async (work_id: string) => {
    const res = await getDetailProjectByWorkId(work_id);
    if (res.data) {
      reportNameRef.current = res.data.project_name;
    }
  };

  const handleDownload = async (path_file: string) => {
    // ダウンロードファイル名を設定
    if (!reportNameRef.current || reportNameRef.current === "")
      await getReportName(data?.PK);
    const template_data = JSON.parse(data?.work_template_data);
    const risky_id = template_data?.risky_id
      ? `(${template_data?.risky_id})`
      : "";
    const filename = `${reportNameRef.current}${risky_id}.pdf`;

    // ダウンロード
    const response = await fetch(path_file);
    const blob = await response.blob();
    const a = document.createElement("a");
    const url = createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    a.remove();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  return (
    <GenericTemplate title="プレビュー" showMenu={false}>
      {data?.path_pdf ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Document
              file={data?.path_pdf}
              onLoadSuccess={onLoadSuccess}
              loading={<CircularProgress color="inherit" />}
              error={messages.PREVIEW.MSG_ERROR}
              noData={
                _.isEmpty(data)
                  ? messages.PREVIEW.MSG_NODATA
                  : messages.PREVIEW.MSG_NOPDF
              }
            >
              {_renderPdfPages()}
            </Document>
          </Box>
          <Box sx={{ height: 40 }} />
          <Button
            color="secondary"
            onClick={() => handleDownload(data?.path_pdf)}
            sx={{
              position: "fixed",
              margin: 0,
              top: "auto",
              right: `calc(50% - ${DownloadButtonWidth} / 2)`,
              bottom: 20,
              left: "auto",
              width: DownloadButtonWidth,
            }}
          >
            ダウンロード
          </Button>
        </>
      ) : (
        data?.type === "url" && (
          <Box
            sx={{
              position: "relative",
              height: isMdUp ? "80vh" : "68vh",
              overflow: "hidden",
            }}
          >
            <iframe
              src={data.html}
              data-type="text/html"
              style={{
                borderWidth: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: isSmUp ? "100%" : "200%",
                height: isSmUp ? "100%" : "200%",
                WebkitTransform: `scale(${isSmUp ? "1" : "0.5"})`,
                transformOrigin: "0 0",
              }}
            />
          </Box>
        )
      )}
    </GenericTemplate>
  );
};

export default PreviewTlogScreen;
