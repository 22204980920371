import { useEffect, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import GroupLevelEditDialog, { IData } from "./GroupLevelEditDialog";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import {
  createGroupMaster,
  deleteGroupMaster,
  getListGroupMaster,
  updateGroupMaster,
} from "@api/groupMaster";
import { GroupMaster } from "services/models";

const initial_data: IData = {
  name: "",
};

const GroupLevelScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<GroupMaster[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<GroupMaster>();
  const [editData, setEditData] = useState<IData>(initial_data);

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    LoadingOverlayController.show();
    await getListGroupMaster()
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  // ------------------------------------------------------------------
  // 追加・更新
  // ------------------------------------------------------------------
  const handleAdd = () => {
    setEditData(initial_data);
    setOpen(true);
  };

  const handleEdit = (d: GroupMaster) => {
    setEditData({
      name: d.group_master_name,
    });
    setSelectedData(d);
    setOpen(true);
  };

  const handleChange = async (value: IData) => {
    if (value.name === editData.name) return;
    try {
      LoadingOverlayController.show();
      let res = null;
      let message = "";

      if (!selectedData) {
        const data = { group_master_name: value.name };
        res = await createGroupMaster(data);
        message = "グループ階層の作成";
      } else {
        const data = {
          PK: selectedData.PK,
          SK: selectedData.SK,
          group_master_name: value.name,
        };
        res = await updateGroupMaster(data);
        message = "グループ階層の更新";
      }

      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleChange", error);
    } finally {
      LoadingOverlayController.hide();
      setSelectedData(undefined);
    }
  };

  // ------------------------------------------------------------------
  // 削除
  // ------------------------------------------------------------------
  const handleDelete = (d: GroupMaster) => {
    ModalController.show({
      message: messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: async () => {
        await handleDeleteConfirm(JSON.parse(JSON.stringify(d)));
      },
    });
  };

  const handleDeleteConfirm = async (d: GroupMaster) => {
    try {
      LoadingOverlayController.show();
      let data = {
        PK: d.PK,
        SK: d.SK,
        groupName: d.group_master_name,
      };
      const res = await deleteGroupMaster(data);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(
            d.group_master_name,
          ),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleDeleteConfirm", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <GenericTemplate title="グループ階層管理">
      <GroupLevelEditDialog
        data={editData}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedData(undefined);
        }}
        onChange={handleChange}
      />

      <Box sx={{ textAlign: "right" }}>
        <Button onClick={handleAdd} endIcon={<AddCircle />}>
          追加
        </Button>
      </Box>

      <List>
        {data.map((item) => (
          <ListItem
            sx={{ bgcolor: "white" }}
            secondaryAction={
              <>
                <Tooltip title="編集">
                  <IconButton
                    edge="end"
                    sx={{ mx: 1 }}
                    onClick={() => handleEdit(item)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="削除">
                  <IconButton edge="end" onClick={() => handleDelete(item)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </>
            }
            divider
            key={item.SK}
          >
            <span className="IgnoreExtractRuleTarget">
              <ListItemText
                primary={item.group_master_name}
                primaryTypographyProps={{
                  style: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginRight: "40px",
                  },
                }}
              />
            </span>
          </ListItem>
        ))}
      </List>
    </GenericTemplate>
  );
};

export default GroupLevelScreen;
