import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";

export const getBarcodeLayoutData = (location_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/barcode-layout?${getParamsHelp({
      location_id: location_id,
    })}`,
    null,
  );
};

export const getBarcodeLayoutDetail = (
  location_id: string,
  project_id: string,
) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/barcode-layout/detail/${encodeURIComponent(
      project_id,
    )}?${getParamsHelp({ location_id: location_id })}`,
    null,
  );
};

export const createBarcodeLayout = (params: {
  location_id: string;
  project_id: string;
  display_items: string;
}) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/barcode-layout`,
    params,
  );
};

export const updateBarcodeLayout = (params: {
  SK: string;
  display_items: string;
}) => {
  return createConnector(METHOD_API.PUT, `${prefixApi}/barcode-layout`, params);
};
