import React, { VFC, useState, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import PasswordInput from "components/atoms/PasswordInput";
import LabelRequired from "components/atoms/LabelRequired";
import messages from "config/messages";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { updatePasswordOthersApi } from "@api/User";
import ModalController from "@shared-components/modal/ModalController";

interface IStateForm {
  new_password: string;
  confirm_password: string;
}

const initialStateForm: IStateForm = {
  new_password: "",
  confirm_password: "",
};

const initalFormError = {
  new_password: "",
  confirm_password: "",
};

interface IProps {
  open: boolean;
  setOpen: Function;
  usernameCognito: string;
  successMessage?: string;
}

const PasswordChangeOthersDialog: VFC<IProps> = ({
  open,
  setOpen,
  usernameCognito = "",
  successMessage,
}: IProps) => {
  const [stateForm, setStateForm] = useState(initialStateForm);
  const [formError, setFormError] = useState(initalFormError);
  const [isErrorConfirmPassword, setIsErrorConfirmPassword] = useState(false);

  useEffect(() => {
    if (open) {
      setStateForm(initialStateForm);
      setFormError(initalFormError);
      setIsErrorConfirmPassword(false);
    }
  }, [open]);

  // 入力制御 ------------------------------------------------
  const onChangeText = (field: keyof IStateForm) => (newText: string) => {
    setStateForm({ ...stateForm, [field]: newText });
  };

  const onValidateText = (field: keyof IStateForm) => {
    let newFormError = formError;

    newFormError = {
      ...newFormError,
      [field]: validator(field, stateForm[field]),
    };

    if (field === "new_password" && stateForm.confirm_password.length > 0) {
      newFormError = {
        ...newFormError,
        confirm_password: check_confirm_password(),
      };
    }

    setFormError(newFormError);
  };

  const validator = (field: keyof IStateForm, value: string) => {
    let mess: string = "";

    switch (field) {
      case "new_password":
        mess = Validation.validatePassword(value, "新しいパスワード");
        break;
      case "confirm_password":
        mess = Validation.validate({
          type: "text",
          name: "新しいパスワードの確認",
          value: value,
          required: true,
        });
        if (mess.length === 0) {
          mess = check_confirm_password();
        }

        break;
    }

    return mess;
  };

  const check_confirm_password = (mess?: string) => {
    let confirm_error = messages.RESET_PASSWORD.MSG_ERROR_CONFIRM_PASSWORD;

    if (stateForm.new_password !== stateForm.confirm_password) {
      setIsErrorConfirmPassword(true);
    } else {
      confirm_error = "";
      setIsErrorConfirmPassword(false);
    }
    return confirm_error;
  };

  const btnDisabled = useMemo(() => {
    const form_value = Object.values(stateForm).filter(
      (item) => item.length > 0,
    );
    const error_messages = Object.values(formError).filter(
      (item) => item.length > 0,
    );
    if (
      form_value.length !== Object.keys(stateForm).length ||
      error_messages.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }, [stateForm, formError]);

  // パスワード変更 ------------------------------------------------
  const handleUpdatePassword = async () => {
    try {
      LoadingOverlayController.show();

      const data = {
        new_password: stateForm.new_password,
        username_cognito: usernameCognito,
      };

      const res = await updatePasswordOthersApi(data);
      if (res) {
        // ログアウト
        let message = "";
        if (successMessage) {
          message = successMessage;
        } else {
          message = messages.COMMON.MSG_COMMON_SUCCESS_001("パスワードの変更");
        }
        ModalController.show({
          message: message,
          visibleButton2: true,
          handlePressButton2: () => {
            setOpen(false);
          },
          disableFeedback: true,
        });
      }
    } catch (error: any) {
      console.log("error handleUpdatePassword", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // キャンセル ------------------------------------------------
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <PasswordInput
              label={
                <>
                  <LabelRequired title={"新しいパスワード"} />
                </>
              }
              autoComplete="new-password"
              id="new_password"
              value={stateForm.new_password}
              onChange={(e) => onChangeText("new_password")(e.target.value)}
              onBlur={() => onValidateText("new_password")}
              error={formError.new_password.length > 0}
              helperText={formError.new_password}
              placeholder="半角英数記号6～30 スペース利用不可"
            />
            <PasswordInput
              label={
                <>
                  <LabelRequired title={"新しいパスワードの確認"} />
                </>
              }
              autoComplete="new-password"
              id="confirm_password"
              value={stateForm.confirm_password}
              onChange={(e) => onChangeText("confirm_password")(e.target.value)}
              onBlur={() => onValidateText("confirm_password")}
              error={
                formError.confirm_password.length > 0 || isErrorConfirmPassword
              }
              helperText={formError.confirm_password}
              placeholder="半角英数記号6～30 スペース利用不可"
            />
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleCancel}>
          キャンセル
        </Button>
        <Button onClick={handleUpdatePassword} disabled={btnDisabled}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordChangeOthersDialog;
