import {
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  SxProps,
  Theme,
} from "@mui/material";

type CheckboxLabelProps = Omit<
  CheckboxProps,
  "label" | "labelPlacement" | "formSx"
> & {
  label: React.ReactNode;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  formSx?: SxProps<Theme>;
};

const CheckboxLabel = ({
  label,
  labelPlacement = "end",
  formSx,
  ...args
}: CheckboxLabelProps) => {
  const { disabled, ...props } = args;
  return (
    <FormControlLabel
      control={<Checkbox {...props} disabled={disabled} />}
      label={label}
      labelPlacement={labelPlacement}
      disabled={disabled}
      sx={formSx}
    />
  );
};

export default CheckboxLabel;
