import axios from "axios";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { store } from "store";
import { TYPES } from "store/types";
import { GetTokenRevokedBy } from "./auth";
import { getUserInfo } from "@utils/index";
import SnackbarController from "@shared-components/snackBar/SnackbarController";

const currentBaseUrl = process.env.REACT_APP_API_URL;
export let currentAccessToken: string | null = null;

declare module "axios" {
  export interface AxiosRequestConfig {
    retries?: number;
    retryCount?: number;
  }
}

export const httpClient = axios.create({
  baseURL: currentBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
  retries: 3,
  retryCount: 0,
});

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Disp messages --------------------------------->>
    const statusCode = error.response ? error.response.status : null;
    // Network Error
    if (error.toJSON().message === "Network Error") {
      if ((error.config.retries ?? 0) > (error.config.retryCount ?? 0)) {
        error.config.retryCount = (error.config.retryCount ?? 0) + 1;
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 1�b�҂�
        return httpClient.request(error.config); // ���g���C
      } else {
        SnackbarController.show({
          severity: "warning",
          message: messages.COMMON.NETWORK_WARNING,
      });
    }
    }
    // Unauthorized
    if (statusCode === 401) {
      let message = "";
      if (
        typeof error.response.data.detail === "object" &&
        error.response.data.detail.length > 1
      ) {
        const code = error.response.data.detail[1];
        if (code === "Revoked") {
          const revoked_by = await getRevokedBy();
          if (revoked_by?.profile) {
            message = messages.COMMON.MSG_LOGOUT_USER_PROFILE_UPDATE;
          } else {
            message = messages.COMMON.MSG_LOGOUT_OTHER_LOGIN;
          }
        }
        if (code === "NotFound") {
          message = messages.COMMON.MSG_LOGOUT_USER_DELETE;
        }
        if (code === "Expired") {
        }
      }
      // logout
      setTimeout(() => {
        if (message !== "") {
          ModalController.show({
            message: message,
            visibleButton2: true,
          });
        }
        store.dispatch({ type: TYPES.LOGOUT });
      }, 10);

      error.response.data.code = statusCode;
    }
    // Disp messages <<---------------------------------
    if (error.response) {
      return Promise.reject(error.response.data);
    } else if (error.request) {
      return Promise.reject(new Error(error.request));
    } else {
      return Promise.reject(error);
    }
  },
);

export function updateAccessToken(token: string | null) {
  if (token) {
    currentAccessToken = token;
    httpClient.defaults.headers.common["x-authorization"] = `Bearer ${token}`;
  } else {
    currentAccessToken = null;
    httpClient.defaults.headers.common["x-authorization"] = null;
  }
}

export function getCurrentAccessToken() {
  return currentAccessToken;
}

async function getRevokedBy() {
  const userInfo: any = await getUserInfo();

  const res: any = await GetTokenRevokedBy({
    preferred_username_cognito: userInfo?.preferred_username_cognito,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
    });
  return res?.data;
}
