import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Grid,
  Card,
  CardHeader,
  CardContent,
  FormGroup,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Colors } from "@template/style";
import {
  STATUS_WORK_TLOG,
  TEMPLATE_EDIT_TYPE,
  screenIdImageList,
  screenIdSupport,
} from "@shared-constants";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import LabelValueText from "components/atoms/LabelValueText";
import TemplateSelectBox from "components/molecules/TemplateSelectBox";
import { renderUserFlowTlog } from "components/atoms/StatusUserFlow/StatusUserFlowTlog";
import {
  getCompressedTemplatePDF,
  getListHistoryRejectApi,
  getWorkByIdApi,
} from "@api/work";
import formatDateToString from "@utils/DateFormat";
import { STORAGE, storeData } from "@utils/Storage";
import { TYPES } from "store/types";
import { createObjectURL } from "@utils/index";

interface IProps {
  open: boolean;
  data?: any;
  onClose: Function;
}

const WorkDetailDialog: React.FC<IProps> = ({
  open,
  data,
  onClose = () => {},
}: IProps) => {
  // const [work, setWork] = useState<null | Work>(null);
  const [template, setTemplate] = useState<any>();
  const [listReject, setListReject] = useState<Array<Object>>([]);
  const [flow_approve_data, setFlowApproveData] = useState<Type.FlowDataType>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getStepApprove = () => {
    const str = data?.approval_status;
    const index = str?.indexOf("/");
    if (index != -1) {
      const start = Number(str?.slice(0, index));
      return start;
    }
    return 0;
  };

  useEffect(() => {
    if (open) {
      getData();
    }
    return () => {
      // setWork(null);
      setTemplate(undefined);
      setListReject([]);
      setFlowApproveData(undefined);
      setIsEditable(false);
    };
  }, [open]);

  const getData = async () => {
    try {
      LoadingOverlayController.show();
      await getListRejectWork();
      await getWorkById();
    } catch (e: any) {
      ModalController.show({
        message: e?.response?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getListRejectWork = async () => {
    try {
      const res = await getListHistoryRejectApi(data.SK);
      if (res.data) {
        setListReject(res.data);
      }
    } catch (e: any) {
      if (e?.detail.message) {
        ModalController.show({
          message: e?.detail.message,
          visibleButton2: true,
        });
      }
    }
  };

  const getWorkById = async () => {
    try {
      const res = await getWorkByIdApi({
        project_id: data.PK,
        work_id: data.SK,
      });
      if (res.data) {
        // setWork(res.data);
        setFlowApproveData(res.data?.flow_approve_data);
        setTemplate({
          ...res.data?.work_template_id,
        });
        setIsEditable(
          (res.data?.work_status == STATUS_WORK_TLOG.NOT_APPROVED &&
            res.data?.checking_approve_flag) ||
            (res.data?.work_status == STATUS_WORK_TLOG.REJECT &&
              res.data?.is_recreate_from_reject),
        );
      }
    } catch (e: any) {
      if (e?.detail.message) {
        ModalController.show({
          message: e?.detail.message,
          visibleButton2: true,
        });
      }
    }
  };

  const renderReject = (item: any) => {
    if (!item) return;
    const dateCreate = item?.created_at
      ? formatDateToString(item?.created_at, "YMD_sl")
      : "";
    return (
      <>
        <Card sx={{ mb: 3 }}>
          <CardHeader
            title="差し戻し情報"
            sx={styles.headerReject}
            titleTypographyProps={styles.headerTypo}
          />
          <CardContent>
            <FormGroup>
              <Stack sx={{ pb: 0 }}>
                <LabelValueText
                  label="対応日付"
                  value={dateCreate}
                  isIgnoreExtractRule={true}
                />
                <LabelValueText
                  label="否認者"
                  value={item?.reject_by_name}
                  isIgnoreExtractRule={true}
                />
                <LabelValueText
                  label="内容"
                  value={item?.memo}
                  isIgnoreExtractRule={true}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>
      </>
    );
  };

  const handleClickEdit = () => {
    // 作業内容確認を開く
    dispatch({
      type: TYPES.SET_SELECTED_WORK_ID,
      selected_work_id: data.SK,
    });
    dispatch({
      type: TYPES.SET_SELECTED_PROJECT_ID,
      selected_project_id: data.PK,
    });
    window.open("/work/confirm");
  };

  // 添付画像の配列
  const image_array: Array<any> = useMemo(() => {
    let response: any[] = [];
    if (!template || !template.work_template_data) {
      return response;
    } else {
      const template_data = JSON.parse(template.work_template_data || "null");
      if (!template_data) {
        return response;
      }
      // 帳票毎の添付画像を取得
      if (screenIdImageList[template.screen_id] in template_data) {
        response = template_data[screenIdImageList[template.screen_id]];
      }
      const isExistMultiple =
        screenIdImageList[template.screen_id].indexOf(",");
      if (isExistMultiple > 0) {
        const imageKeys = screenIdImageList[template.screen_id].split(",");
        imageKeys.forEach((key) => {
          if (key in template_data) {
            response = (response ?? []).concat(template_data[key]);
          }
        });
      }
      return response;
    }
  }, [template]);

  const handleClickDownload = async () => {
    if (!image_array || image_array.length <= 0) {
      // 添付画像がない場合は何もしない
      return;
    }

    // 添付画像をダウンロード
    try {
      LoadingOverlayController.show();
      const template_data = JSON.parse(template?.work_template_data ?? "null");
      // zipファイル取得
      let list_path_image: string[] = [];
      let list_file_name: string[] = [];
      image_array.forEach((item, i) => {
        let index_str = (i + 1).toString().padStart(2, "0");
        list_path_image.push(item.path_file_jpg || item.path_file);
        list_file_name.push(`${template_data.risky_id}_${index_str}.jpg`); // 帳票番号 + "_"+ 連番 + ".jpg"
      });
      const response = await getCompressedTemplatePDF({
        list_path_pdf: list_path_image,
        list_file_name,
      });
      // zipダウンロード
      const a = document.createElement("a");
      const url = createObjectURL(response);
      a.href = url;
      a.download = `${template_data.risky_id}_image.zip`; // 帳票番号 + "_image.zip"
      a.click();
      a.remove();
      LoadingOverlayController.hide();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (e) {
      console.log(e);
      LoadingOverlayController.hide();
    }
  };

  return (
    <Dialog
      maxWidth="md"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="body1">作業内容確認</Typography>
      </DialogTitle>

      <DialogContent dividers>
        <Grid
          container
          spacing={3}
          sx={{ flexWrap: { xs: undefined, md: "nowrap" } }}
        >
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              minWidth: { xs: undefined, md: "200px" },
              maxWidth: { xs: undefined, md: "200px" },
            }}
          >
            {data?.work_status != "FINISH" ? renderReject(listReject[0]) : null}
            <Card>
              <CardHeader
                title="基本情報"
                sx={styles.header}
                titleTypographyProps={styles.headerTypo}
              />
              <CardContent>
                <Stack>
                  <LabelValueText
                    label={
                      screenIdSupport[template?.screen_id || ""] ==
                      TEMPLATE_EDIT_TYPE.RISKY_REPORT
                        ? "事故番号"
                        : "帳票番号"
                    }
                    value={data?.risky_id}
                    isIgnoreExtractRule={true}
                  />
                  <LabelValueText
                    label="報告者"
                    value={data?.created_by_name}
                    isIgnoreExtractRule={true}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card sx={{ mb: 3 }}>
              <CardHeader
                title="承認フロー"
                sx={styles.header}
                titleTypographyProps={styles.headerTypo}
              />
              <CardContent>
                <Typography className="IgnoreExtractRuleTarget">
                  {data?.flow_approve_name}
                </Typography>
                <Box
                  component={"div"}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  mb={1}
                >
                  <Typography>バージョン {data?.version}</Typography>
                </Box>
                <FormGroup>
                  {flow_approve_data
                    ? renderUserFlowTlog(
                        flow_approve_data,
                        getStepApprove(),
                        data?.work_status == STATUS_WORK_TLOG.CANCEL,
                      )
                    : null}
                </FormGroup>
              </CardContent>
            </Card>
            <Card>
              <CardHeader
                title="内容"
                sx={styles.header}
                titleTypographyProps={styles.headerTypo}
              />
              <CardContent>
                {template && (
                  <>
                    <TemplateSelectBox
                      template={template}
                      buttonType={"none"}
                    />
                    <Stack
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 1,
                      }}
                      direction="row"
                    >
                      <Button
                        onClick={() => {
                          storeData(
                            STORAGE.PREVIEW_DATA,
                            JSON.stringify(template),
                          );
                          window.open("/preview");
                        }}
                      >
                        プレビュー
                      </Button>
                      <Button
                        onClick={handleClickDownload}
                        color="secondary"
                        startIcon={<Download />}
                        disabled={image_array.length <= 0}
                      >
                        添付画像
                      </Button>
                      {isEditable && (
                        <Button color="warning" onClick={handleClickEdit}>
                          編集
                        </Button>
                      )}
                    </Stack>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  dialogHeader: {
    bgcolor: Colors.MAIN_GREEN,
    py: 1,
    px: 2,
    display: "flex",
    alignItems: "center",
    borderRadius: 1,
  },
  header: { bgcolor: Colors.MAIN_GREEN_LIGHT },
  headerReject: { bgcolor: Colors.YELLOW },
  headerTypo: { fontSize: "1rem" },
} as const;

export default WorkDetailDialog;
