import React, { useState } from "react";
import { Box, Button, FormControl, InputLabel, Stack } from "@mui/material";
import messages from "config/messages";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import Dropzone from "components/atoms/Dropzone";
import ModalController from "@shared-components/modal/ModalController";
import { createObjectURL } from "@utils/index";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";

interface IImagePickerList {
  addButtonText: string;
  maxImage: number;
  imagesList: Type.ImageInfoType[];
  onChange: (image_array: Type.ImageInfoType[]) => void;
  deleteConfirmMessage?: string;
}

const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

const ImagePickerList = ({
  addButtonText = "",
  imagesList = [],
  maxImage = 10,
  onChange = () => {},
  deleteConfirmMessage = "報告内容を削除しますか?",
}: IImagePickerList) => {
  const [imagesArray, setImagesArray] = useState<Type.ImageInfoType[]>([
    ...imagesList,
  ]);

  useDeepCompareEffect(() => {
    setImagesArray([...imagesList]);
  }, [imagesList]);

  const handleAddImageItem = () => {
    if (imagesArray.length < maxImage) {
      onChange([...imagesArray, imageDefault]);
    }
  };

  const getUrlImage = (value: Type.ImageInfoType) => {
    let url = "";
    if (value?.type === "image/svg+xml") {
      if (value?.non_Local) {
        url = value.uri_jpg ?? "";
      } else {
        url = value.uriThumb ?? "";
      }
    } else {
      url = value?.uri;
    }
    return url;
  };

  const onChangeImage = (index: number) => (imageInfo: Type.ImageInfoType) => {
    const updatedImagesList = [...imagesArray];
    updatedImagesList[index] = imageInfo;
    onChange(updatedImagesList);
  };

  const handleDeleteImageItem = (index: number) => {
    if (index >= 0) {
      const updatedImagesList = [...imagesArray];
      updatedImagesList.splice(index, 1);
      onChange(updatedImagesList);
    }
  };

  const renderImageItem = (imagesArray: Type.ImageInfoType[]) => {
    return imagesArray.map((item, index) => (
      <CancelBtnBadge
        onClick={() => {
          if (item.uri !== "") {
            onChangeImage(index)(imageDefault);
          } else {
            ModalController.show({
              message: deleteConfirmMessage,
              visibleButton1: true,
              visibleButton2: true,
              button1: { title: messages.COMMON.BUTTON.CANCEL },
              handlePressButton2: () => handleDeleteImageItem(index),
            });
          }
        }}
        key={index}
        sx={{ width: "100%" }}
        componentsProps={{ badge: { style: { right: "7%" } } }}
      >
        {item.uri !== "" ? (
          <Box
            component="img"
            src={getUrlImage(item)}
            sx={{
              height: 140,
              width: "100%",
              objectFit: "cover",
              borderRadius: 1,
            }}
          />
        ) : (
          <Box width={"100%"}>
            <Dropzone
              onChoose={(res) => {
                const uri = createObjectURL(res[0]);
                res[0]["uri"] = uri;
                onChangeImage(index)(res[0]);
              }}
              maxFiles={1}
              showFileList={false}
              key={index}
            />
          </Box>
        )}
      </CancelBtnBadge>
    ));
  };

  return (
    <Stack>
      {renderImageItem(imagesArray)}
      <Button
        onClick={handleAddImageItem}
        disabled={imagesArray.length >= maxImage}
      >
        {addButtonText}
      </Button>
    </Stack>
  );
};

export default ImagePickerList;
