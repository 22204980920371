import { Project } from "services/models";
import { TYPES } from "store/types";

export interface State extends ProjectInfo {
  selected_project_id: string;
  selected_project_name: string;
  selected_work_id: string;
  selected_work_name: string;
}

interface ProjectInfo extends Project {
  PK: string;
  SK: string;
  created_by: string;
  project_id: string;
  project_name: string;
  template_project_id: string | any;
  project_location_id: string;
}

const initialState: State = {
  selected_project_id: "",
  selected_project_name: "",
  selected_work_id: "",
  selected_work_name: "",
  is_daily: false,
  PK: "",
  SK: "",
  created_by: "",
  project_id: "",
  project_name: "",
  project_status_id: {
    PK: "",
    SK: "",
    created_at: "",
    status_color: "",
    status_name: "",
  },
  template_project_id: "",
  project_location_id: "",

  project_start_date: "",
  project_end_date: "",
  is_favorite: false,
  memo: "",
  path_file: "",
  path_file_thumb: "",
  report_type_code: "",
  report_number: "",
  report_name: "",
};

const set_selected_project_id = (payload: {
  selected_project_id: string;
  selected_project_name: string;
}) => ({
  type: TYPES.SET_SELECTED_PROJECT_ID,
  ...payload,
});
const set_selected_work_id = (payload: {
  selected_work_id: string;
  selected_work_name: string;
}) => ({
  type: TYPES.SET_SELECTED_WORK_ID,
  ...payload,
});
const set_project_info = (payload: ProjectInfo) => ({
  type: TYPES.SET_PROJECT_INFO,
  payload,
});

export type Actions = ReturnType<
  | typeof set_selected_project_id
  | typeof set_selected_work_id
  | typeof set_project_info
>;

export const projectReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TYPES.SET_SELECTED_PROJECT_ID:
      return {
        ...state,
        selected_project_id: action.selected_project_id,
        selected_project_name: action.selected_project_name,
      };

    case TYPES.SET_SELECTED_WORK_ID:
      return {
        ...state,
        selected_work_id: action.selected_work_id,
        selected_work_name: action.selected_work_name,
      };

    case TYPES.SET_PROJECT_INFO: {
      return {
        ...initialState,
        selected_project_id: state.selected_project_id,
        selected_project_name: state.selected_project_name,
        selected_work_id: state.selected_work_id,
        selected_work_name: state.selected_work_name,
        ...action.payload,
        template_project_id:
          typeof action.payload?.template_project_id === "string"
            ? action.payload?.template_project_id
            : action.payload?.template_project_id?.SK,
      };
    }

    default:
      return state;
  }
};
