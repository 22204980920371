import { all } from "redux-saga/effects";
import watcher from "./watcher";
const allSagas = [...watcher];

export default function* rootSaga() {
  try {
    yield all(allSagas);
  } catch (error) {
    console.log("Error in Saga", error);
  }
}
