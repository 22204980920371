import { TYPES } from "store/types";

const initialState = {
  status_project: [],
  location_project: [],
  type_work_item: [],
  is_loading_project: true,
};

export const managerMasterReducer = (
  state = initialState,
  { type, payload }: any,
) => {
  switch (type) {
    case TYPES.SET_STATUS_PROJECT:
      return { ...state, status_project: payload };
    case TYPES.SET_LOCATION_PROJECT:
      return { ...state, location_project: payload };
    case TYPES.SET_TYPE_WORK_ITEM:
      return { ...state, type_work_item: payload };
    // case TYPES.SET_IS_LOADING_PROJECT:
    //   return { ...state, is_loading_project: payload };
    default:
      return state;
  }
};
