import React from "react";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import { TemplateExcelDataInfo } from "services/models";
import LabelRequired from "components/atoms/LabelRequired";
import formatDateToString from "@utils/DateFormat";
import { labeltextBreak } from "@utils/index";

interface IDateInputField {
  setting: TemplateExcelDataInfo;
  value?: string;
  onChange?: (text: string) => void;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
}

const DateInputField = ({
  setting,
  value,
  onChange = () => {},
  onBlur = () => {},
  error,
  helperText,
}: IDateInputField) => {
  return (
    <DatePickerCustom
      label={
        setting.required ? (
          <LabelRequired title={labeltextBreak(setting.name)} />
        ) : (
          labeltextBreak(setting.name)
        )
      }
      onChange={(v) => {
        let date_string = "";
        if (v) date_string = formatDateToString(v, "YMD_sl");
        onChange(date_string);
      }}
      value={value && value.length > 0 ? value : null}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
    />
  );
};

export default DateInputField;
