import { METHOD_API, createConnector, prefixApi } from "./Connector";
interface IDataUpdateMaster {
  type: string;
  name: string;
  color: string;
  color_id: string;
  color_name: string;
  index: string;
  parent: string;
  activity_base_id: string;
}

// 登録
export const insertMaster = (dataBody: IDataUpdateMaster) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/master`,
    dataBody,
  );
};

// 更新
export const updateMaster = (dataBody: IDataUpdateMaster, SK: string) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/master/${encodeURIComponent(SK)}`,
    dataBody,
  );
};

// CSV取込
export const importMaster = (dataBody: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/master/import`,
    dataBody,
  );
};
