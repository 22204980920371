import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import FullScreenDialog from "components/atoms/FullScreenDialog";
import { Work } from "services/models";
import { getListHistoryRejectApi } from "@api/work";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import InfiniteScroll from "react-infinite-scroller";
import ScrollLoader from "components/atoms/ScrollLoader";
import LabelValueText from "components/atoms/LabelValueText";
import formatDateToString from "@utils/DateFormat";
import { Colors } from "@template/style";
import { SelectImageFileConfirm } from "screens/WorkConfirm/FormContextReportConfirm/SelectFileReportConfirm";
import messages from "config/messages";

interface IProps {
  open: boolean;
  setOpen: Function;
  data: Work | null;
}

const HistoryRejectWork = ({ open, setOpen, data }: IProps) => {
  const [listReject, setListReject] = useState<Array<Object>>([]);
  const [lastKey, setLastKey] = useState<any>(null);
  const [loading, setLoading] = useState<Boolean>(false);
  const [loadMore, setLoadMore] = useState<Boolean>(false);

  const getListRejectWork = async (isMore?: boolean) => {
    try {
      setLoading(true);
      // if (!loading && loadMore && listReject.length === 0) {
      if (listReject.length === 0) {
        LoadingOverlayController.show();
      }
      const res = await getListHistoryRejectApi(data?.SK, lastKey);
      if (res.data) {
        if (!isMore) {
          setListReject(res.data);
        } else {
          let updatedResult = [...listReject, ...res?.data];
          setListReject(updatedResult);
        }
        if (res?.last_key) {
          setLastKey(res.last_key?.SK);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setLoadMore(false);
        }
      }
    } catch (error: any) {
      ModalController.show({
        message: error?.response?.detail.message,
        visibleButton2: true,
      });
    } finally {
      setLoading(false);
      LoadingOverlayController.hide();
    }
  };

  const checkDate = (date1: Date, date2: Date) => {
    if (
      date1.getFullYear() == date2.getFullYear() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getDate() == date2.getDate()
    ) {
      return true;
    }
    return false;
  };

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && listReject.length > 0;
  }, [listReject, loadMore, lastKey]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && listReject.length === 0) {
      getListRejectWork();
    }
  }, [open, listReject]);

  // useEffect(() => {
  //   if (!open) {
  //     setListReject([]);
  //     setLastKey(null);
  //     setLoading(false);
  //     setLoadMore(false);
  //   }
  // }, [open]);

  const renderRejectItem = (item: any, index: number) => {
    const dateCreate = new Date(item?.created_at);
    const itemRejectBef: any = index ? listReject[index - 1] : undefined;

    return (
      <>
        {(index &&
          !checkDate(
            new Date(item?.created_at),
            new Date(itemRejectBef?.created_at),
          )) ||
        !index ? (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Divider sx={{ flexGrow: 1 }} />
            <Typography variant="h6" sx={{ px: 3, display: "flex" }}>
              <div className="IgnoreExtractRuleTarget">
                {formatDateToString(dateCreate, "YMD_sl")}
              </div>
              &nbsp;{`(差し戻し 対応日付)`}
            </Typography>
            <Divider sx={{ flexGrow: 1 }} />
          </Box>
        ) : (
          <Divider />
        )}
        <RejectItem data={item} />
      </>
    );
  };

  return (
    <FullScreenDialog
      open={open}
      onClose={handleClose}
      title="差し戻し内容一覧"
    >
      <Box
        sx={{
          width: "100%",
          maxHeight: {
            xs: "calc(100vh - 244px)",
            md: "calc(100vh - 177px)",
          },
          overflow: "auto",
          p: 1,
        }}
      >
        <InfiniteScroll
          useWindow={false}
          loadMore={() => {
            if (loadMore && lastKey && listReject.length > 0 && !loading) {
              getListRejectWork(true);
            }
          }}
          initialLoad={false}
          hasMore={showLoadMore}
          loader={<ScrollLoader key={0} />}
        >
          <Stack>
            {listReject.map((item, index) => (
              <React.Fragment key={index}>
                {renderRejectItem(item, index)}
              </React.Fragment>
            ))}
            {listReject.length <= 0 && !loading && (
              <Typography>
                {messages.COMMON.MSG_NOT_EXIST("差し戻し")}
              </Typography>
            )}
          </Stack>
        </InfiniteScroll>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button onClick={handleClose}>戻る</Button>
      </Box>
    </FullScreenDialog>
  );
};

const RejectItem = ({ data }: { data: any }) => {
  const [open, setOpen] = useState(true);

  return (
    <Card>
      <CardHeader
        title="差し戻し情報"
        sx={{ bgcolor: Colors.YELLOW }}
        action={
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack sx={{ pb: 0 }}>
            <LabelValueText
              label="否認者"
              value={data?.reject_by_name ?? ""}
              isIgnoreExtractRule={true}
            />
            <LabelValueText
              label="内容"
              value={data?.memo ?? ""}
              isIgnoreExtractRule={true}
            />
          </Stack>
        </CardContent>

        <CardHeader title="添付" sx={{ bgcolor: Colors.YELLOW }} />
        <CardContent>
          <SelectImageFileConfirm
            selectedImages={data?.images}
            selectedFiles={data?.files}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default HistoryRejectWork;
