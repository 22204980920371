import React, { useState } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Work } from "services/models";
import { getWorkByIdApi, updateWorkPdf } from "@api/work";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { createFileNameFollowUri, getFileByUrl } from "@utils/index";
import { screenIdSupportGetImageUrl } from "screens/CreateReportTlog/templateFunctionSupport";
import { screenIdSupportMergeTemplate } from "@shared-constants";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";

const RestorePdfScreen: React.VFC = () => {
  const [PK, setPK] = useState<string>("");
  const [SK, setSK] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      LoadingOverlayController.show();

      // 報告データ取得
      const res = await getWorkByIdApi({ project_id: PK, work_id: SK }).catch(
        (e) => {
          setError(e.detail?.message ?? "システムエラー");
        },
      );
      if (!res?.data) return;

      const workData: Work = res.data;

      // テンプレート取得
      const templateData = workData?.work_template_id;
      if (!templateData) {
        return;
      }
      const { template_path, screen_id, work_template_data } = templateData;
      let newStateFormEditInfo = JSON.parse(work_template_data ?? "null");
      const fileName = createFileNameFollowUri(template_path);
      let localFile = template_path;
      let newPath: string | null = null;
      if (newStateFormEditInfo) {
        newStateFormEditInfo = await screenIdSupportGetImageUrl[screen_id](
          newStateFormEditInfo,
        );
        newPath = await screenIdSupportMergeTemplate[screen_id](
          localFile,
          newStateFormEditInfo,
          templateData,
        );
      }
      if (!newPath || newPath == localFile) {
        setError(messages.TEMPLATE.MSG_ERROR_GET_TEMPLATE);
        return;
      }

      // PDF復元
      const formData = new FormData();
      const f = await getFileByUrl(newPath, fileName, "text/html");
      formData.append("work_template_file", f);
      await updateWorkPdf(PK, SK, formData).then((res) => {
        if (res?.data) {
          ModalController.show({
            message: messages.COMMON.MSG_COMMON_SUCCESS_001(
              "ファイル名：" + res.data + "\nPDF復元",
            ),
            visibleButton2: true,
          });
          setError("");
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <GenericTemplate title="PDF復元" showMenu={false}>
      <Typography pb={0.5}>復元する帳票の情報を入力してください</Typography>
      <Card>
        <CardContent>
          <Box component="form" action="post" onSubmit={handleSubmit}>
            <Stack>
              <TextField
                label="project_id"
                value={PK}
                placeholder="PROJECT#"
                onChange={(e) => {
                  setPK(e.target.value);
                }}
                required
              />
              <TextField
                label="work_id"
                value={SK}
                placeholder="WORK#"
                onChange={(e) => {
                  setSK(e.target.value);
                }}
                required
              />
              <Box sx={{ justifyContent: "end" }}>
                <Button type="submit" children={"復元"} />
              </Box>
              {error.length > 0 && (
                <FormHelperText error>{error}</FormHelperText>
              )}
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </GenericTemplate>
  );
};

export default RestorePdfScreen;
