import { cloneDeep, toArray, toPlainObject } from "lodash";
import { Box } from "@mui/material";
import {
  BarcodeLayout,
  Project,
  ResInfoGroup,
  TemplateExcelDataInfo,
  User,
} from "services/models";
import { getDataStorage, STORAGE, storeData } from "./Storage";
import { ISelectedData as IGroupSelectedData } from "components/organisms/AccountGroupInfoOption";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { EXCEL_TEMPLATE_INPUT, USER_ROLES } from "@shared-constants";
import formatDateToString from "./DateFormat";

export const sort = <T,>(list: T[], before: number, after: number): T[] => {
  const copy = [...list];
  const [moving] = copy.splice(before, 1);
  copy.splice(after, 0, moving);

  return copy;
};

export const checkArray = (value: any) => {
  return value && Array.isArray(value) && value.length > 0;
};

export const checkObject = (objCheck: any) => {
  return (
    objCheck &&
    Object.keys(objCheck).length > 0 &&
    objCheck.constructor === Object
  );
};

export const createFormUpload = (photo: any, body: any = {}) => {
  const data = new FormData();

  if (photo) {
    data.append("file", photo);
  }

  Object.keys(body).forEach((key) => {
    if (
      (body[key] && typeof body[key] === "string") ||
      checkArray(body[key]) ||
      typeof body[key] === "boolean"
    ) {
      let dataOfKey = body[key];
      if (checkArray(body[key]) || checkObject(body[key])) {
        dataOfKey = JSON.stringify(body[key]);
      }
      data.append(key, dataOfKey);
    }
  });

  return data;
};

export const getFileByUrl = async (
  url: string,
  fileName: string,
  type: string,
) => {
  const f = await fetch(url)
    .then((r) => r.blob())
    .then((blobFile) => {
      let file = new File([blobFile], fileName, {
        type: type,
        lastModified: new Date().getTime(),
      });
      return file;
      // let container = new DataTransfer();
      // container.items.add(file);
      // return container.files[0] ?? "";
    });
  return f;
};

export const createFormMutiplePhotoUpload = async (
  photos: any,
  files: any,
  body: any = {},
) => {
  const data = new FormData();
  if (photos?.length) {
    // photos.forEach((item: any) => {
    //   // if (item.type == "image/svg+xml") {
    //   //   data.append("images", {
    //   //     name: item?.name,
    //   //     type: item?.type,
    //   //     uri: item?.uri,
    //   //   });
    //   // } else {
    //   data.append("images", item);
    //   // }
    // });
    for (const item of photos) {
      if ("lastModifiedDate" in item) {
        data.append("images", item);
      } else {
        const f = await getFileByUrl(
          item.uri,
          item.fileName ? item.fileName : item.name,
          item.type,
        );
        data.append("images", f);
      }
    }
  }

  let fileIdArray: Array<{ SK: string; PK: string }> = [];

  if (files?.length > 0) {
    for (const item of files) {
      if (item?.PK && item?.SK) {
        fileIdArray.push({ PK: item?.PK, SK: item?.SK });
      } else {
        const fileUri = item?.fileCopyUri ?? item?.uri ?? "";
        item.uri = fileUri;
        // data.append("files", item);

        if ("lastModifiedDate" in item) {
          data.append("files", item);
        } else {
          const f = await getFileByUrl(
            fileUri,
            encodeURIComponent(item?.name ?? ""),
            item.type,
          );
          data.append("files", f);
        }
      }
    }
  }

  if (fileIdArray.length > 0) {
    data.append("file_of_folder", JSON.stringify(fileIdArray));
  }

  Object.keys(body).forEach((key) => {
    if (
      (body[key] && typeof body[key] === "string") ||
      checkArray(body[key]) ||
      typeof body[key] === "boolean"
    ) {
      let dataOfKey = body[key];
      if (checkArray(body[key]) || checkObject(body[key])) {
        dataOfKey = JSON.stringify(body[key]);
      }
      data.append(key, dataOfKey);
    }
  });

  return data;
};

export const createObjectURL = (window.URL || window.webkitURL).createObjectURL;

export const getUserInfo = () => {
  const userInfo: any = JSON.parse(getDataStorage(STORAGE.USER_INFO, "{}"));
  return userInfo;
};

export const getIdProject = (item: Project) => {
  return item.PK.startsWith("PROJECT#") ? item.PK : item.SK;
};

export const getIdTemplateProject = (item: Project) => {
  return item.template_project_id ?? "";
};

export const storeCondition = (type: string, data: any, userId: string) => {
  try {
    storeData(`@${type}-${encodeURIComponent(userId)}`, JSON.stringify(data));
  } catch (error) {
    console.log("storeCondition error");
  }
};

export const updateFilterProject = (state: any, payload: any) => {
  const typeScreen = payload?.typeScreen;
  if (
    payload.type === "updated_at" ||
    payload.type === "created_at" ||
    payload.type === "expired" ||
    payload.type === "printed"
  ) {
    let valueUpdate = payload.values;
    // if (state[typeScreen][payload.type]?.id === payload.values?.id) {
    if (!payload.values?.id) {
      valueUpdate = null;
    }
    return {
      ...state,
      [typeScreen]: {
        ...state[typeScreen],
        [payload.type]: valueUpdate,
      },
    };
  }
  return {
    ...state,
    [typeScreen]: {
      ...state[typeScreen],
      [payload.type]: payload.values,
    },
  };
};

export const removeConditionFilterHelp = (
  state: any,
  payload: any,
  type_screen: string,
) => {
  const dataTypeFilter = cloneDeep(state[type_screen]);
  const dataOfType = dataTypeFilter[payload.keyType];
  if (checkArray(dataOfType)) {
    return {
      ...state,
      [type_screen]: {
        ...dataTypeFilter,
        [payload.keyType]: dataOfType.filter(
          (item: any) => item.id !== payload.value.id,
        ),
      },
    };
  }
  return {
    ...state,
    [type_screen]: {
      ...dataTypeFilter,
      [payload.keyType]: null,
    },
  };
};

export const getItemTypeFilter = (
  arr: string[],
  data: any,
  typeScreen?: string,
) => {
  const dataClone = cloneDeep(data);
  return arr.map((item) => {
    if (typeScreen === "work_group" && item === "作業状況") {
      dataClone[item]?.data?.splice(-1, 1);
    }
    return dataClone[item];
  });
};

export const getParamsFilter = (data: any, typeScreen?: string) => {
  let result: string[] = [];
  if (typeScreen === "work_group") {
    result = ["work_status_not_finish"];
  }
  if (typeScreen === "assignment_copy") {
    result = ["of_assignment", "work_status/FINISH"];
  }
  if (typeScreen === "report_copy") {
    result = ["work_status/FINISH"];
  }
  for (const [keyType, values] of Object.entries(data)) {
    if (checkArray(values)) {
      result.push(
        `${keyType}/${toArray(values)
          ?.map((item: any) => item.id)
          ?.join(",")}`,
      );
    }
    if (checkObject(values)) {
      result.push(`${keyType}/${toPlainObject(values)?.id}`);
    }
  }
  return result.join(";");
};

export const getParamsFilterV1 = (data: any) => {
  let result = [];
  for (const [keyType, values] of Object.entries(data)) {
    if (checkArray(values)) {
      result.push(
        `${keyType}/${toArray(values)
          ?.map((item: any) =>
            item.id.includes("USER#") ? item.id.replace("USER#", "") : item.id,
          )
          ?.join(",")}`,
      );
    }
    if (checkObject(values)) {
      result.push(
        `${keyType}/${
          toPlainObject(values)?.id.includes("USER#")
            ? toPlainObject(values)?.id.replace("USER#", "")
            : toPlainObject(values)?.id
        }`,
      );
    }
  }
  return result.join(";");
};

export const getConditionFromStore = (type: string, userId: string) => {
  try {
    const res: any = getDataStorage(`@${type}-${encodeURIComponent(userId)}`);
    return res === "" ? null : JSON.parse(res) || null;
  } catch (error) {
    console.log("storeFilterCondition error");
  }
};

export const checkFilterConditionEmpty = (state: any) => {
  if (state) {
    for (const filterScreen of Object.values(state)) {
      if (filterScreen) {
        for (const value of Object.values(filterScreen)) {
          if (checkArray(value) || checkObject(value)) {
            return false;
          }
        }
      } else {
        return true;
      }
    }
    return true;
  }
  return true;
};

export const checkHasChangeSortCondition = (state: any) => {
  if (state) {
    for (const sortScreen of Object.values(state)) {
      if (sortScreen !== "update_time_latest_sort") {
        return true;
      }
    }
    return false;
  }
  return false;
};

export const getUserId = (user: User) => {
  return user?.SK ?? user?.user_id;
};

export const createFileNameFollowUri = (uri: string) => {
  return uri.replace(/^.*[\\\/]/, "").split("?")[0];
};

export const parseString = (data: string) => {
  return JSON.parse(data.replace(/\"/g, '\\"').replaceAll(`'`, `"`));
};

export const getGroupSelectedData = (
  data: ResInfoGroup[],
  group_level_count?: number,
) => {
  if (!group_level_count) {
    group_level_count = data.length;
  }
  const selectedData: IGroupSelectedData = {};

  [...Array(group_level_count)].forEach((_, i) => {
    selectedData[i + 1] = data[i]?.SK ?? "";
  });

  return selectedData;
};

export const getBase64Image = async (imageUrl: string) => {
  return new Promise<any>((resolve, reject) => {
    fetch(imageUrl, { cache: "no-cache" })
      .then((res) => res.blob())
      .then((blob) => {
        var reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            resolve(reader.result);
          },
          false,
        );

        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      });
  });
};

export const getHtmlToPath = (
  html: string,
  width: number,
  height: number,
  image_type: "svg" | "png" = "svg",
): Promise<string> => {
  // html要素作成
  const doc = document.createElement("div");
  doc.appendChild(document.createRange().createContextualFragment(html));
  var serializeHtml = new XMLSerializer().serializeToString(doc);
  const data = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0, ${width}, ${height}"
       width="${width}" height="${height}" style="background-color: white;">
    <foreignObject width="100%" height="100%">
      ${serializeHtml}
    </foreignObject>
  </svg>
`;

  // svgファイルに変換
  const svg = new Blob([data], { type: "image/svg+xml" });
  const url = createObjectURL(svg);
  if (image_type == "svg") {
    return new Promise((resolve) => {
      resolve(url);
    });
  }

  // svgをpngに変換
  var reader = new FileReader();
  reader.readAsDataURL(svg);
  return new Promise((resolve) => {
    reader.onloadend = function () {
      var base64 = reader.result;
      const img = new Image();
      img.addEventListener("load", () => {
        const canvas = document.createElement("canvas");

        canvas.setAttribute("width", width.toString());
        canvas.setAttribute("height", height.toString());

        const context = canvas.getContext("2d");
        context?.drawImage(img, 0, 0, width, height);

        const dataUrl = canvas.toDataURL("image/png");
        resolve(dataUrl);
      });

      img.src = typeof base64 === "string" ? base64 : "";
    };
  });
};

export const getBarcodeLayoutDataByProject = async (project_id: string) => {
  var list_data = await getDataStorage(STORAGE.BARCODE_LAYOUT);
  list_data = list_data === "" ? null : JSON.parse(list_data) || null;
  const data = list_data.find((value: any) => value.SK.includes(project_id));
  if (data) {
    return JSON.parse(data.display_items);
  } else {
    return undefined;
  }
};

export const getBarcodeData = (
  layout: BarcodeLayout,
  data: any,
  key?: string,
) => {
  const getValue = (layout_key: string) => {
    const item = layout[layout_key];
    var value = data;
    for (let index = 0; index < item.value_key.length; index++) {
      const key = item.value_key[index];
      if (!value || value[key] === undefined) {
        value = "";
        break;
      } else {
        value = value[key];
        if (value && value["is_manual_input"] === true && item.sub_key) {
          // 手入力の場合
          value = data;
          item.sub_key.forEach((other_key) => {
            value = value[other_key];
          });
          break;
        }
      }
    }
    value = typeof value !== "string" ? "" : value;
    return { label: item.label, value };
  };

  var response: { [key: string]: { label: string; value: string } } = {};
  if (key) {
    response[key] = getValue(key);
    return response;
  } else {
    ["title", "detail"].forEach((type) => {
      var i = 1;
      while (true) {
        if (!layout || !layout[type + i]) {
          break;
        }
        response[type + i] = getValue(type + i);
        i++;
      }
    });
    return response;
  }
};

export const getBarcodeLayoutByTemplateData = (
  data_info: TemplateExcelDataInfo,
) => {
  const ListUseNameValue: Array<number> = [
    EXCEL_TEMPLATE_INPUT.MASTER,
    EXCEL_TEMPLATE_INPUT.USER_NAME,
  ];

  let value_key = [data_info.coordinate];
  if (ListUseNameValue.includes(data_info.input)) {
    value_key.push("name");
  }
  if (data_info.input === EXCEL_TEMPLATE_INPUT.RADIO) {
    value_key.push("value");
  }
  if (data_info.input === EXCEL_TEMPLATE_INPUT.CHECKBOX) {
    value_key.push("value");
  }
  if (data_info.input === EXCEL_TEMPLATE_INPUT.REPORT_NO) {
    value_key = ["risky_id"];
  }
  return { label: data_info.name, value_key: value_key };
};

export const isOpenProject = (
  open_state?: boolean,
  start_date?: string,
  end_date?: string,
) => {
  if (open_state === false) {
    return false;
  } else {
    if (start_date === undefined && end_date === undefined) return true;
    return checkBetweenDates(
      formatDateToString(new Date(), "YMD"),
      start_date,
      end_date,
    );
  }
};

export const checkBetweenDates = (
  target_date: dayjs.ConfigType,
  start_date: dayjs.ConfigType,
  end_date: dayjs.ConfigType,
) => {
  if (!target_date) return false;
  const target = dayjs(target_date);
  if (start_date && end_date) {
    dayjs.extend(isBetween);
    return target.isBetween(start_date, end_date);
  } else if (start_date) {
    const start = dayjs(start_date);
    return target >= start;
  } else if (end_date) {
    const end = dayjs(end_date);
    return target <= end;
  } else {
    return true;
  }
};

export const isAuthorizedViewCustomTemplate = (
  user_role: string,
  open_state: boolean,
  user_location_id: string,
  project_location_id: string,
) => {
  switch (user_role) {
    case USER_ROLES.OWNER.value:
      return true;
    case USER_ROLES.ADMIN.value:
      if (!open_state && user_location_id !== project_location_id) {
        return false;
      } else {
        return true;
      }
    case USER_ROLES.EDITOR.value:
      if (user_location_id !== project_location_id) {
        return false;
      } else {
        return true;
      }
    case USER_ROLES.MEMBER.value:
      if (open_state && user_location_id === project_location_id) {
        return true;
      } else {
        return false;
      }
  }
};

export const isAuthorizedDeleteCustomTemplate = (
  user_role: string,
  user_location_id: string,
  project_location_id: string,
) => {
  switch (user_role) {
    case USER_ROLES.OWNER.value:
      return true;
    case USER_ROLES.ADMIN.value:
      if (user_location_id === project_location_id) {
        return true;
      } else {
        return false;
      }
    case USER_ROLES.EDITOR.value:
    case USER_ROLES.MEMBER.value:
      return false;
  }
};

export const fileDownload = async (file_name: string, uri: string) => {
  if (file_name == "" || uri == "") return;
  const response = await fetch(uri);
  const blob = await response.blob();
  const a = document.createElement("a");
  const url = createObjectURL(blob);
  a.href = url;
  a.download = file_name;
  a.click();
  a.remove();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
};

export const isConfirmCopyMaster = (user_info: User, data: Project) => {
  return (
    user_info.user_role !== USER_ROLES.OWNER.value &&
    user_info.location_id !== data.project_location_id
  );
};

export const decodeBase64toArrayBuffer = (base64: string): ArrayBuffer => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  const lookup = typeof Uint8Array === "undefined" ? [] : new Uint8Array(256);
  for (let i = 0; i < chars.length; i++) {
    lookup[chars.charCodeAt(i)] = i;
  }
  let bufferLength = base64.length * 0.75,
    len = base64.length,
    i,
    p = 0,
    encoded1,
    encoded2,
    encoded3,
    encoded4;

  if (base64[base64.length - 1] === "=") {
    bufferLength--;
    if (base64[base64.length - 2] === "=") {
      bufferLength--;
    }
  }

  const arraybuffer = new ArrayBuffer(bufferLength),
    bytes = new Uint8Array(arraybuffer);

  for (i = 0; i < len; i += 4) {
    encoded1 = lookup[base64.charCodeAt(i)];
    encoded2 = lookup[base64.charCodeAt(i + 1)];
    encoded3 = lookup[base64.charCodeAt(i + 2)];
    encoded4 = lookup[base64.charCodeAt(i + 3)];

    bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
    bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
    bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
  }

  return arraybuffer;
};

export const getCsvData = (file: File): Promise<string[][]> => {
  var reader = new FileReader();
  reader.readAsText(file);
  return new Promise((resolve) => {
    // CSVファイル読み込み
    reader.onloadend = function (e: any) {
      const csv_text: string = e.target?.result;
      if (csv_text) {
        const csv_arr = csv_text
          .replace(/\r/g, "")
          .split("\n")
          .map((line, index, x) => splitCsvRow(line, index == x.length - 1))
          .filter((row) => row.length > 0);
        resolve(csv_arr);
      } else {
        resolve([]);
      }
    };
  });
};

export const splitCsvRow = (line: string, is_end: boolean) => {
  if (!line && is_end) return new Array();
  var c = "";
  var s = new String();
  var data = new Array();
  var doubleQuoteFlg = false;
  var doubleQuoteAdded = false;

  for (var i = 0; i < line.length; i++) {
    c = line.charAt(i);
    if (c == "," && !doubleQuoteFlg) {
      data.push(s.toString());
      s = "";
    } else if (c == "," && doubleQuoteFlg) {
      s = s + c;
    } else if (c == '"') {
      doubleQuoteFlg = !doubleQuoteFlg;
      if (i > 0 && line.charAt(i - 1) == '"' && !doubleQuoteAdded) {
        s = s + c;
        doubleQuoteAdded = !doubleQuoteAdded;
      } else {
        doubleQuoteAdded = !doubleQuoteAdded;
      }
    } else {
      s = s + c;
    }
  }
  data.push(s.toString());
  return data;
};

export const labeltextBreak = (text?: string) => {
  if (!text) return null;
  const text_array = text.split(/<br>|\n/g);
  return text_array.map((item, i) => (
    <Box component={"div"} key={i}>
      {item}
    </Box>
  ));
};
