import { useEffect, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
  Typography,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import {
  DraggableProvided,
  DroppableProvided,
  DropResult,
  Draggable,
  Droppable,
  DragDropContext,
} from "react-beautiful-dnd";
import { AddCircle, ConstructionOutlined, Delete, Edit } from "@mui/icons-material";
import { sort } from "@utils/index";
import WreckerEditDialog, {
  IWreckerEditData,
} from "../Wrecker/WreckerEditDialog";
import WreckerStoreEditDialog, {
  IWreckerStoreEditData,
} from "../Wrecker/WreckerStoreEditDialog";
import {
  getListWrecker,
  deleteWrecker,
  updateWreckerSort,
  createWrecker,
  updateWrecker,
  updateWreckerStore,
  createWreckerStore
} from "@api/wrecker";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import SelectLabel from "components/atoms/SelectLabel";
import { getUserInfo } from "@utils/index";
import { WRECKER_TYPE } from "@shared-constants";

const initial_data: IWreckerEditData = {
  wrecker_name: "",
};

const initial_shop_data: IWreckerStoreEditData = {
  wrecker_name: "",
  wrecker_address: "",
  wrecker_tel: "",
}

const WreckerEditScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [storeOpen, setStoreOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editData, setEditData] = useState<IWreckerEditData>(initial_data);
  const [editStoreData, setEditStoreData] = useState<IWreckerStoreEditData>(initial_shop_data);
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [wreckerTypeId, setWreckerTypeId] = useState<string>("");
  const [wreckerTypeName, setWreckerTypeName] = useState<string>("");

  const isStoreMaster = (masterTypeName: string) => {
    return masterTypeName === WRECKER_TYPE.WRECKER_STORE_CAR
      || masterTypeName === WRECKER_TYPE.WRECKER_TOWING_SHOP;
  }

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    const sorted = sort(data, result.source.index, result.destination.index);
    setData(sorted);
  };

  const handleAdd = () => {
    if (isStoreMaster(wreckerTypeName)) {
      setEditStoreData(initial_shop_data);
      setStoreOpen(true);
    } else {
      setEditData(initial_data);
      setOpen(true);
    }
  };

  const handleDelete = (SK: string) => {
    if (!ModalController.isShowing()) {
      const confirmMessage = disabled
        ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
        : messages.COMMON.MSG_COMMON_DELETE_CONFIRM_SORT_001;
      ModalController.show({
        message: confirmMessage,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handleDeleteSettings(SK),
      });
    }
    return;
  };

  const handleDeleteSettings = async (SK: string) => {
    try {
      LoadingOverlayController.show();
      const res = await deleteWrecker(SK, wreckerTypeId);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(wreckerTypeName),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleDeleteSettings", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleEdit = (d: any, i: number) => {
    setEditData({
      wrecker_name: d.wrecker_name,
    });
    setEditIndex(i);
    showConfirm(setOpen);
  };

  const handleStoreEdit = (d: any, i: number) => {
    setEditStoreData({
      wrecker_name: d.wrecker_name,
      wrecker_address: d.wrecker_address,
      wrecker_tel: d.wrecker_tel,
    });
    setEditIndex(i);
    showConfirm(setStoreOpen);
  };

  const showConfirm = (f: (flg: boolean)=> void) => {
    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_UPDATE_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          f(true);
        },
      });
    } else {
      f(true);
    }
  }

  const handleChange = async (value: IWreckerEditData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      wrecker_name: value.wrecker_name,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateWrecker(formData, newData[editIndex].SK);
        if (res) {
          message = wreckerTypeName + "の更新";
        }
      } else {
        res = await createWrecker(formData, wreckerTypeId);
        if (res) {
          message = wreckerTypeName + "の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleChange", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleStoreChange = async (value: IWreckerStoreEditData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const formData = {
      wrecker_name: value.wrecker_name,
      wrecker_address: value.wrecker_address,
      wrecker_tel: value.wrecker_tel,
      index: "",
      is_manual_input: false,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateWreckerStore(formData, newData[editIndex].SK);
        if (res) {
          message = wreckerTypeName + "の更新";
        }
      } else {
        res = await createWreckerStore(formData, wreckerTypeId);
        if (res) {
          message = wreckerTypeName + "の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleMaChange", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const handleStoreCancel = () => {
    setStoreOpen(false);
    setEditIndex(-1);
  };

  const handleSave = async () => {
    try {
      LoadingOverlayController.show();
      let formData: any[] = [];
      data.map((item) => {
        const datilData = {
          PK: item.PK,
          SK: item.SK,
        };
        formData.push(datilData);
      });
      const res = await updateWreckerSort(formData);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(
            wreckerTypeName + "の並び順の更新",
          ),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleSave", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleChangeMasterType = (e: SelectChangeEvent<string>) => {
    const matchData = Object.entries(WRECKER_TYPE).find(([key, value]) => value == e.target.value);
    if (matchData == undefined) {
      {/* 選択肢はWRECKER_TYPEから作成しているため、MissMatchはありえない */}
      setWreckerTypeId("");
      setWreckerTypeName("");
      return;
    }
    setWreckerTypeId(matchData[0]);
    setWreckerTypeName(e.target.value);
  };

  const fetchData = async () => {
    LoadingOverlayController.show();

    await getListWrecker({wrecker_kind: wreckerTypeId})
      .then((res) => {
        if (res?.data) {
          setData(res.data);
          setOriginData(res.data);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  const fetchDataConfirm = async () => {
    if (!ModalController.isShowing() && !wreckerTypeId) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED_SELECT("マスタ種類"),
        visibleButton2: true,
      });
    }

    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DISPLAY_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          fetchData();
          setData([]);
          setOriginData([]);
          setSelectDisabled(true);
          if (wreckerTypeName === "") {
          }
        },
      });
    }
    if (disabled) {
      if (selectDisabled) {
        if (wreckerTypeId) {
          setSelectDisabled(false);
          fetchData();
          if (wreckerTypeName === "") {
          }
        }
      } else {
        setData([]);
        setOriginData([]);
        setSelectDisabled(true);
      }
    }
  };

  useEffect(() => {
    setDisabled(JSON.stringify(data) === JSON.stringify(originData));
  }, [data, originData]);

  return (
    <GenericTemplate title="レッカー受付マスタ管理">

      {/* 店以外マスタ編集ダイアログ */}
      <WreckerEditDialog
        data={editData}
        open={open}
        onChange={handleChange}
        onClose={handleCancel}
      />

      {/* 店マスタ編集ダイアログ */}
      <WreckerStoreEditDialog
        data={editStoreData}
        open={storeOpen}
        onChange={handleStoreChange}
        onClose={handleStoreCancel}
      />

      <Box
          sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: 3,
              mb: 3,
          }}
      >
        <SelectLabel
          label="マスタ種類"
          value={wreckerTypeName}
          isIgnoreExtractRuleLabel={false}
          isIgnoreExtractRuleSelect={true}
          onChange={(e) => handleChangeMasterType(e)}
          sx={{ width: 500 }}
          disabled={!selectDisabled}
        >
          {Object.keys(WRECKER_TYPE).map(key => (
            <MenuItem value={WRECKER_TYPE[key]} key={key}>
              <span className="IgnoreExtractRuleTarget">
                {WRECKER_TYPE[key]}
              </span>
            </MenuItem>
          ))}
        </SelectLabel>
        <Button
            onClick={fetchDataConfirm}
            sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
            ml: 2,
            }}
        >
            {selectDisabled ? "選択" : "解除"}
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Button onClick={handleAdd} disabled={selectDisabled} endIcon={<AddCircle />}>
          追加
        </Button>
      </Box>
      <Box>
        <DragDropContext onDragEnd={handleDnd}>
          <Droppable droppableId="droppableId-1">
            {(provided: DroppableProvided) => (
              <List ref={provided.innerRef} {...provided.droppableProps}>
                {data.map((item, i) => (
                  <Draggable key={item.SK} draggableId={item.SK} index={i}>
                    {(provided: DraggableProvided) => (
                      <ListItem
                        sx={{ bgcolor: "white" }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        secondaryAction={
                          <>
                            <Tooltip title="編集">
                              <IconButton
                                edge="end"
                                sx={{ mx: 1 }}
                                onClick={() => {
                                  if (isStoreMaster(wreckerTypeName)) {
                                    handleStoreEdit(item, i);
                                  } else {
                                    handleEdit(item, i);
                                  };
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="削除">
                              <IconButton
                                edge="end"
                                onClick={() => handleDelete(item.SK)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        divider
                      >
                        <span className="IgnoreExtractRuleTarget">
                          <ListItemText
                            primary={wreckerTypeName === "" ? item.nonconformity_detail : item.wrecker_name}
                            primaryTypographyProps={{
                              style: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                marginRight: "40px",
                              },
                            }}
                          />
                        </span>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box sx={{ height: 30 }} />
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Button onClick={handleSave} color="secondary" disabled={disabled}>
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default WreckerEditScreen;
