import { useRef, useMemo, useState, VFC, useEffect } from "react";
import GenericTemplate from "@template/index";
import { Tabs, Tab, Box, Snackbar, Alert } from "@mui/material";
import DataDownloadScreen from "./DataDownload";
import "./ManageScreen.css";
import { EmailNotification } from "./EmailNotification";
import { Colors } from "@template/style";
import { TEXT_EMAIL_PAGE, USER_ROLES } from "@shared-constants";

import ModalController from "@shared-components/modal/ModalController";
import { ResponseSaveNoticeEmail } from "./TypeNoticeEmail";
import { cloneDeep } from "lodash";
import { saveDataNotificationEmail } from "@api/notificationEmail";
import { saveUserExportCsv } from "@api/menuAdmin";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import messages from "config/messages";
import { clearData, getDataStorage, STORAGE } from "@utils/Storage";
import {
  FlowInfo,
  ParamApiPostCreateNewFlow,
  ResponseGetListApprove,
} from "services/models/models";
import { apiPostApprovalFLow, apiPostCreateNewFlow } from "@api/flowApproval";
import FlowApproval from "./FlowApproval/FlowApproval";
import { useDispatch } from "react-redux";
import { TYPES } from "store/types";
import { getUserInfo } from "@utils/index";
import { userInfo } from "os";

const ManageScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const EmailNotificationPageNum = 0;
  const FlowApprovalPageNum = 1;
  const dispatch = useDispatch();
  const [value, setValue] = useState(EmailNotificationPageNum); //初期画面はメール通知
  const [displayAlert, setDisplayAlert] = useState({
    isDisplay: false,
    message: "",
  });
  const { user_role } = getUserInfo();
  const isAdmin = user_role === USER_ROLES.ADMIN.value;
  const handleForwardEmail = async () => {
    const condition = getDataStorage(STORAGE.EMAIL_INFO);

    if (!condition) return;
    const { contentOptions, selectedOptionValue, contentText } =
      JSON.parse(condition);
    // =================handle if title or content empty
    if (contentText[TEXT_EMAIL_PAGE.title].trim().length < 1) {
      // setDisplayAlert((pre) => {
      //   return {
      //     isDisplay: true,
      //     message: messages.ManageScreen.MSG_TITLE_REQUIRED,
      //   };
      // });
      return;
    }
    if (contentText[TEXT_EMAIL_PAGE.content].trim().length < 1) {
      // setDisplayAlert((pre) => {
      //   return {
      //     isDisplay: true,
      //     message: messages.ManageScreen.MSG_CONTENT_REQUIRED,
      //   };
      // });
      return;
    }
    if (
      contentText[TEXT_EMAIL_PAGE.title].trim().length < 1 &&
      contentText[TEXT_EMAIL_PAGE.content].trim().length < 1
    ) {
      // setDisplayAlert((pre) => {
      //   return {
      //     isDisplay: true,
      //     message: messages.ManageScreen.MSG_PLEASE_INPUT_ITEM_REQUIRED,
      //   };
      // });
      return;
    }
    // ======================
    let PK = "";
    let type_content = "";
    const arrayCheckBox = contentOptions?.map((item: any) => {
      if (selectedOptionValue == item.SK) {
        PK = item.PK;
        type_content = item.type_content;
      }
      return {
        PK: item.PK,
        SK: item.SK,
        notification_status: item.notification_status,
      };
    });
    const data = {
      object_data: {
        PK,
        SK: selectedOptionValue,
        type_content,
        title: contentText[TEXT_EMAIL_PAGE.title],
        content: contentText[TEXT_EMAIL_PAGE.content],
      },
      list_checkbox_status: arrayCheckBox,
    };
    try {
      const res: ResponseSaveNoticeEmail = await saveDataNotificationEmail(
        data,
      );
      if (res) {
        const cloneContentOption = cloneDeep(contentOptions);
        cloneContentOption.forEach((item: any) => {
          if (item.SK == res.SK) {
            item.title = res.title;
            item.content = res.content;
          }
        });
        ModalController.show({
          message: messages.ManageScreen.MSG_SAVE_NOTICE_EMAIL_SUCCESS,
          visibleButton2: true,
        });
        clearData(STORAGE.IS_CHANGED);
        clearData(STORAGE.EMAIL_INFO);
      }
    } catch (error: any) {
      console.log(error, "error save notice email ???");
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleForwardCsv = async () => {
    // CSV出力の出力データ保存
    const data = JSON.parse(getDataStorage(STORAGE.CSV_INFO));
    if (!data) return;

    if (data.list_column.length < 1) {
      setDisplayAlert((pre) => {
        return {
          isDisplay: true,
          message: messages.ManageScreen.MSG_AT_LEAST_1_COLUMNS_ARE_SELECTED,
        };
      });
      return;
    }
    try {
      const res = await saveUserExportCsv(data);
      if (res) {
        ModalController.show({
          message: messages.ManageScreen.MSG_SAVE_NOTICE_CSV_SUCCESS,
          visibleButton2: true,
        });
        clearData(STORAGE.IS_CHANGED);
        clearData(STORAGE.CSV_INFO);
      }
    } catch (error: any) {
      console.log(error, "error save csv info ???");
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleSubmitDataFlow = async () => {
    // get value from local storage
    const rawApprroveData = getDataStorage(STORAGE.FLOW_APPROVE_INFO);
    let approveData = new ParamApiPostCreateNewFlow();
    if (rawApprroveData) {
      approveData = JSON.parse(rawApprroveData);
    }
    try {
      await apiPostApprovalFLow(approveData);
      ModalController.show({
        message: messages.ManageScreen.MSG_SAVE_FLOW_SUCCESS,
        visibleButton2: true,
      });
    } catch (error) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_ERROR_001,
        visibleButton2: true,
      });
      console.log("error", error);
    } finally {
      clearData(STORAGE.IS_CHANGED);
      clearData(STORAGE.FLOW_APPROVE_INFO);
      dispatch({
        type: TYPES.INIT_APPROVE_STATE,
      });
    }
  };
  const handleChangeIndex = (event: React.SyntheticEvent, newValue: number) => {
    const isChanged = Boolean(getDataStorage(STORAGE.IS_CHANGED));
    if (isChanged) {
      ModalController.show({
        message: messages.ManageScreen.MSG_UNSAVE_CHANGE,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton1: () => {
          clearData(STORAGE.IS_CHANGED);
          clearData(STORAGE.EMAIL_INFO);
          clearData(STORAGE.FLOW_APPROVE_INFO);
          clearData(STORAGE.USER_ROLE);
          clearData(STORAGE.INVALID);
          setValue(newValue);
        },
        handlePressButton2: () => {
          switch (value) {
            //CSV出力画面非表示
            // case 0:
            //   handleForwardCsv();
            //   setValue(newValue);
            //   break;
            case EmailNotificationPageNum:
              const condition = Boolean(getDataStorage(STORAGE.INVALID));
              if (!condition) {
                dispatch({
                  type: TYPES.RE_CHECK,
                });
              } else {
                handleForwardEmail();
                setValue(newValue);
              }
              break;
            case FlowApprovalPageNum:
              const invalid = Boolean(getDataStorage(STORAGE.INVALID));
              if (invalid) {
                dispatch({
                  type: TYPES.RE_CHECK,
                });
              } else {
                handleSubmitDataFlow();
                setValue(newValue);
              }
              break;
            default:
              break;
          }
        },
      });
    } else {
      setValue(newValue);
      dispatch({
        type: TYPES.INIT_APPROVE_STATE,
      });
    }
  };
  useEffect(() => {
    clearData(STORAGE.IS_CHANGED);
  }, []);
  const TabPanel = (props: {
    children?: React.ReactNode;
    // dir?: string;
    index: number;
    value: number;
  }) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  };
  const customSelectedTab = useMemo(() => {
    return {
      width: 200,
      mx: 0,
      backgroundColor: "#595959",
      color: "white",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    };
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    setDisplayAlert((pre) => {
      return { message: "", isDisplay: false };
    });
  };

  return (
    <GenericTemplate title="管理者メニュー">
      <Snackbar
        open={displayAlert.isDisplay}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", height: "100%" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
      <Tabs
        TabIndicatorProps={{ style: { background: Colors.MAIN_GREEN } }}
        value={value}
        onChange={handleChangeIndex}
        sx={{
          marginLeft: 0,
        }}
      >
        {/*CSV出力非表示 <Tab
          sx={customSelectedTab}
          className="customSelectedTab"
          label="CSV出力"
        /> */}
        <Tab
          sx={customSelectedTab}
          className="customSelectedTab"
          label="メール通知"
        />
        <Tab
          disabled={!isAdmin}
          sx={customSelectedTab}
          className="customSelectedTab"
          label="承認フロー"
        />
      </Tabs>
      {/* <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          borderColor: Colors.MAIN_GREEN,
          borderStyle: "solid",
          borderWidth: 0.5,
        }}
      > */}
      {/*CSV出力非表示 <TabPanel value={value} index={0}>
        <DataDownloadScreen />
      </TabPanel> */}
      <TabPanel value={value} index={EmailNotificationPageNum}>
        <EmailNotification />
      </TabPanel>
      <TabPanel value={value} index={FlowApprovalPageNum}>
        <FlowApproval />
        {/* <FlowApproveBackup /> */}
      </TabPanel>
      {/* </Box> */}
    </GenericTemplate>
  );
};

export default ManageScreen;

const styles = {
  card: { mb: 2 },
  title: { mt: 2, mb: 1 },
  selectLabel: { width: 200 },
  button: { mr: 1, my: 1 },
} as const;
