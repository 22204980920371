import { useState, VFC } from "react";
import GenericTemplate from "@template/index";
import Dropzone from "components/atoms/Dropzone";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  InputLabel,
} from "@mui/material";

const TraslationScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);

  const chooseCsv = (res: any) => {
    if (res[0] != null) {
      setCsvFile(res[0]);
      setDisabled(false);
    }
  };

  const fetchDataConfirm = async () => {
    var reader = new FileReader();
    if (csvFile) {
      reader.readAsText(csvFile);
      reader.addEventListener("load", function () {
        let lines = String(reader.result)
          .replace(/",/g, "\n")
          .replace(/"/g, "");
        let csvArray = lines.split(/\n/);
        setData(csvArray);
      });
    }
  };

  const deletFile = () => {
    setData([]);
    setCsvFile(null);
    setDisabled(true);
  };

  return (
    <GenericTemplate title="WOVN 動的コンテンツ登録">
      <Box
        sx={{
          mt: 3,
          mb: 3,
        }}
      >
        <InputLabel>翻訳対象リストを選択してください</InputLabel>
      </Box>
      <div>
        <Dropzone
          accept="csv"
          maxFiles={1}
          onChoose={chooseCsv}
          onDeleteFile={deletFile}
        />
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: 3,
          mb: 3,
        }}
      >
        <Button
          onClick={fetchDataConfirm}
          sx={{ flex: 1 }}
          size="large"
          disabled={disabled}
        >
          アップロード
        </Button>
      </Box>
      {data.length > 0 && (
        <>
          <Box>
            <List>
              {data.map(
                (item, i) =>
                  item !== "" && (
                    <ListItem key={i} sx={{ bgcolor: "white" }} divider>
                      <div>{item}</div>
                    </ListItem>
                  ),
              )}
            </List>
          </Box>
        </>
      )}
    </GenericTemplate>
  );
};

export default TraslationScreen;
