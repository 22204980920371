import React, { VFC, useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  MenuItem,
  Stack,
} from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";
import { useEffect } from "react";
import { Validation } from "@validation";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { COUNTRY_CODE_JP, USER_ROLES, LIST_USER_ROLE } from "@shared-constants";
import { insertInviteUserApi } from "@api/inviteUser";
import { checkUserJoinContractApi } from "@api/User";

export type IInviteType = "email" | "phone";

interface IProps {
  open: boolean;
  setOpen: Function;
  inviteType: IInviteType;
  setFetchData: Function;
}

interface IStateForm {
  phone_number: string;
  email: string;
  user_role: string;
}

const initialStateForm: IStateForm = {
  phone_number: "",
  email: "",
  user_role: USER_ROLES.MEMBER.value,
};

interface IFormError {
  phone_number: string;
  email: string;
  user_role: string;
}

const initialFormError: IFormError = {
  phone_number: "",
  email: "",
  user_role: "",
};

const AddInviteDialog: VFC<IProps> = ({
  open,
  setOpen,
  inviteType,
  setFetchData,
}: IProps) => {
  const [stateForm, setStateForm] = useState<IStateForm>(initialStateForm);
  const [formError, setFormError] = useState<IFormError>(initialFormError);
  const [inviteBtnDisabled, setInviteBtnDisabled] = useState(true);

  const setClear = useCallback(() => {
    setStateForm(initialStateForm);
    setFormError(initialFormError);
    setInviteBtnDisabled(true);
  }, []);

  useEffect(() => {
    if (open) {
      setClear();
    }
  }, [open, setClear]);

  // 入力制御 ------------------------------------------------
  const onChangeText =
    (field: keyof IStateForm) => (newText: string | null) => {
      setStateForm({ ...stateForm, [field]: newText });
    };

  const onValidateText = (field: keyof IStateForm, value?: any) => {
    if (value === undefined) value = stateForm[field];

    const newFormError = { ...formError, [field]: validator(field, value) };
    setFormError(newFormError);

    // 招待ボタンの活性判定
    const messages = Object.values(newFormError).filter(
      (item) => item.length > 0,
    );
    setInviteBtnDisabled(messages.length > 0);
  };
  const validator = useCallback((field: keyof IStateForm, value: string) => {
    let message: string = "";

    switch (field) {
      case "email":
        message = Validation.validateEmail(value);
        break;
      case "phone_number":
        message = Validation.validatePhone(value);
        break;
      case "user_role":
        message = Validation.validate({
          type: "text",
          value: value,
          name: "権限",
          required: true,
        });
        break;
    }

    return message;
  }, []);

  // 招待 ------------------------------------------------
  const handleInviteMember = async (goBack: boolean) => {
    try {
      LoadingOverlayController.show();
      setInviteBtnDisabled(true);

      // メンバー数のチェック
      const response_check = await checkAddUser();
      if (response_check === null) return;
      if (!response_check) {
        ModalController.show({
          message:
            messages.OTHER_SCREEN.MSG_MEMBERS_MANAGEMENT_ERROR_ADD_BUTTON,
          visibleButton2: true,
        });
        setInviteBtnDisabled(false);
        return;
      }

      const data = {
        phone_number: stateForm.phone_number,
        email: stateForm.email,
        user_role: stateForm.user_role,
        country_code: stateForm.phone_number ? COUNTRY_CODE_JP : undefined,
      };
      const res = await insertInviteUserApi(data);

      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001("メンバーの招待"),
          visibleButton2: true,
          handlePressButton2: () => {
            setFetchData(true);
            if (goBack) {
              setOpen(false);
            } else {
              setClear();
            }
          },
          disableFeedback: true,
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleInviteMember", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const checkAddUser = async () => {
    LoadingOverlayController.show();
    return await checkUserJoinContractApi()
      .then((res) => {
        return res;
      })
      .catch((error) => {
        if (!ModalController.isShowing())
          ModalController.show({
            message: error?.response?.detail.message,
            visibleButton2: true,
          });
        console.log("error checkAddUser", error);
        return null;
      })
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            {inviteType === "email" ? (
              <TextField
                label="メールアドレス"
                name="email"
                value={stateForm.email}
                onChange={(e) => onChangeText("email")(e.target.value)}
                required
                onBlur={() => onValidateText("email")}
                error={formError.email.length > 0}
                helperText={formError.email}
                inputProps={{
                  maxLength: 256,
                }}
                placeholder="xxx@example.com"
              />
            ) : (
              <TextField
                label="電話番号"
                name="phone_number"
                value={stateForm.phone_number}
                onChange={(e) => onChangeText("phone_number")(e.target.value)}
                required
                onBlur={() => onValidateText("phone_number")}
                error={formError.phone_number.length > 0}
                helperText={formError.phone_number}
                inputProps={{
                  maxLength: 11,
                }}
                placeholder="電話番号 ※ハイフン（-）不要"
              />
            )}
            <SelectLabel
              label="権限"
              name="user_role"
              value={stateForm.user_role}
              onChange={(e) => onChangeText("user_role")(e.target.value)}
              required
              onBlur={() => onValidateText("user_role")}
              error={formError.user_role.length > 0}
              helperText={formError.user_role}
            >
              {LIST_USER_ROLE.map((item) => {
                if (item.value === USER_ROLES.OWNER.value) return null;
                return (
                  <MenuItem value={item.value} key={item.value}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </SelectLabel>
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            handleInviteMember(true);
          }}
          disabled={inviteBtnDisabled}
        >
          招待
        </Button>

        <Button
          onClick={() => {
            handleInviteMember(false);
          }}
          disabled={inviteBtnDisabled}
        >
          続けて招待
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInviteDialog;
