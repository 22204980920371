import { VFC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormLabel,
  TextField,
  Stack,
  Select, // TLOG
  MenuItem, // TLOG
} from "@mui/material";
import logoImg from "assets/logo.png";
import PasswordInput from "components/atoms/PasswordInput";
import { TYPES } from "store/types";
import PasswordChangeDialog from "screens/Account/PasswordChangeDialog";
import { getDataStorage, STORAGE } from "@utils/Storage";
import { RootState } from "store/reducer";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { useSize } from "shared/hook/useSize";
import { getListTopGroupAllByContractInfo } from "@api/groupMaster"; // TLOG
import { Colors, theme } from "@template/style"; // TLOG
import CheckboxLabel from "components/atoms/CheckboxLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { ActivityBase } from "services/models";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { createFormUpload } from "@utils/index";

const LoginScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [username_id, setUsernameId] = useState("");
  const [password, setPassword] = useState("");
  const [open_password, setOpenPassword] = useState(false);
  const is_password_reset = useSelector(
    (state: RootState) => state.authInfo.is_password_reset,
  );
  const contract_info = getDataStorage(STORAGE.CONTRACT_INFO);
  const contractInfoObject = JSON.parse(contract_info);
  const dispatch = useDispatch();
  const { isSmUp } = useSize();
  // --->> TLOG
  const [group_master_name, setGroupMasterName] = useState<string>("");
  const [locationCode, setLocationCode] = useState<string>("");
  const [listGroup, setListGroup] = useState<ActivityBase[]>([]);
  const [isOwner, setIsOwner] = useState(false);
  const [disableBtn, setDisableBtn] = useState<boolean>(true);

  useEffect(() => {
    getListGroup();
  }, []);

  const getListGroup = async () => {
    //contract_login_id
    let formData = createFormUpload(null, contractInfoObject);
    await getListTopGroupAllByContractInfo(
      formData,
      contractInfoObject.contract_login_id,
    )
      .then((res) => {
        setListGroup(res.data);
        setGroupMasterName(res.group_master_name);
        setLocationCode(res.data[0]["activity_base_code"]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // <<--- TLOG

  const theme_label: any = theme.components?.MuiInputLabel?.styleOverrides;
  const styles = {
    label: {
      minWidth: 130,
      width: isSmUp ? 130 : "100%",
      alignSelf: isSmUp ? "center" : "flex-start",
      paddingRight: 1,
      mb: isSmUp ? undefined : theme_label.formControl.marginBottom,
    },
  } as const;

  // ------------------------------------------------------------------
  // ログイン
  // ------------------------------------------------------------------
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: TYPES.LOGIN,
      payload: {
        username_id: username_id,
        password: password,
        location_code: locationCode, // TLOG
        is_owner: isOwner, // TLOG
        contract_login_id: contractInfoObject.contract_login_id,
      },
    });
  };

  useEffect(() => {
    if (is_password_reset) {
      LoadingOverlayController.hide();
      setOpenPassword(true);
    }
  }, [is_password_reset]);

  const comfirmLogout = () => {
    ModalController.show({
      message: messages.COMMON.MSG_CONFIRM_CONTRACT_LOGOUT,
      visibleButton1: true,
      visibleButton2: true,
      button1: { title: messages.COMMON.BUTTON.CANCEL_002 },
      handlePressButton2: () => {
        dispatch({ type: TYPES.CONTRACT_LOGOUT });
      },
      disableFeedback: true,
    });
  };

  // ------------------------------------------------------------------
  // ボタン制御
  // ------------------------------------------------------------------
  useEffect(() => {
    if (username_id && password) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [username_id, password]);

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logoImg} alt="logo" height="252px" width="441px" />
        </Box>
        <Box
          sx={{
            marginTop: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flexDirection: {
                xs: "row",
                md: "column",
                alignItems: "left",
                justifyContent: "left",
                  },
            }}
          >
            <Typography display="inline" sx={{ mr: 10 }}>
              契約名
            </Typography>
            <Typography display="inline">
              {contractInfoObject.contract_name}
            </Typography>
          </Box>
          <Box
            component="form"
            action="post"
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              mt: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
            onSubmit={handleSubmit}
          >
            {/* --->> TLOG */}
            <FormControl fullWidth margin="normal">
              <Stack direction={isSmUp ? "row" : "column"} spacing={0}>
                {group_master_name ? (
                  <FormLabel
                    sx={{
                      ...styles.label,
                      "&.Mui-focused": {
                        color: Colors.TEXT,
                      },
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {group_master_name}
                  </FormLabel>
                ) : (
                  <Box sx={styles.label} />
                )}
                <Select
                  fullWidth
                  autoFocus
                  value={locationCode}
                  onChange={(e) => setLocationCode(e.target.value)}
                >
                  {listGroup.map((item, index) => (
                    <MenuItem
                      value={item.activity_base_code}
                      key={item.activity_base_code + index}
                    >
                      <span className="IgnoreExtractRuleTarget">
                        {item.activity_base_name}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </FormControl>
            {/* <<--- TLOG */}
            <FormControl fullWidth margin="normal">
              <Stack direction={isSmUp ? "row" : "column"} spacing={0}>
                <FormLabel sx={styles.label}>
                  <LabelRequired title={"ログインID"} />
                </FormLabel>
                <TextField
                  id="username_id"
                  name="username_id"
                  type="text"
                  autoComplete="username"
                  fullWidth
                  onChange={(e) => setUsernameId(e.target.value)}
                  inputProps={{ maxLength: 30 }}
                />
              </Stack>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Stack direction={isSmUp ? "row" : "column"} spacing={0}>
                <FormLabel sx={styles.label}>
                  <LabelRequired title={"パスワード"} />
                </FormLabel>
                <PasswordInput
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  label=""
                  fullWidth={true}
                />
              </Stack>
            </FormControl>
            {/* --->> TLOG */}
            <CheckboxLabel
              label="スーパーユーザーとしてログイン"
              checked={isOwner}
              onChange={(e) => {
                setIsOwner(e.target.checked);
              }}
            />
            {/* <<--- TLOG */}
            <Box
              sx={{
                flexDirection: {
                  xs: "row",
                  md: "column",
                  alignItems: "center",
                },
              }}
            >
              <Button
                disabled={disableBtn}
                type="submit"
                sx={{ mt: 3, mr: 5, mb: 2, py: 2, px: 5 }}
              >
                ログイン
              </Button>
              <Button
                onClick={comfirmLogout}
                color="error"
                sx={{ mt: 3, mb: 2, py: 2, px: 5 }}
              >
                契約からログアウト
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <PasswordChangeDialog
        open={open_password}
        setOpen={setOpenPassword}
        isLogin={true}
      />
    </>
  );
};

export default LoginScreen;
