import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { IMasterValue } from "shared/utils/template/excelTemplate";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";
import {
  CUSTOM_MASTER_NAME_ATTRIBUTE,
  EXCEL_TEMPLATE_MASTER_NAME,
} from "@shared-constants";
import { TemplateExcelDataInfo } from "services/models";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getCustomMasterByBarcode } from "@api/masterType";
import { labeltextBreak } from "@utils/index";

interface IMasterInputField {
  setting: TemplateExcelDataInfo;
  value: IMasterValue;
  onChange: (value: IMasterValue) => void;
  reportItem: any;
  listMasterType: any;
  activityBaseId: string;
}

interface ICustomItem {
  name: string;
  barcode: boolean;
  required: boolean;
  isName?: boolean;
}

const MasterInputField = ({
  setting,
  value,
  onChange,
  reportItem,
  listMasterType,
  activityBaseId,
}: IMasterInputField) => {
  const [list, setList] = useState<Array<any>>([]);
  const [hasManualInput, setHasManualInput] = useState<boolean>(false);
  const [masterType, setMasterType] = useState<Array<ICustomItem>>([]);
  const [barcodeFlg, setBarcodeFlg] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const nameField = useMemo(() => {
    return setting.master && EXCEL_TEMPLATE_MASTER_NAME[setting.master]
      ? EXCEL_TEMPLATE_MASTER_NAME[setting.master]
      : CUSTOM_MASTER_NAME_ATTRIBUTE;
  }, [setting]);
  const otherTitle = (
    <>
      {"その他（"}
      {setting.name.includes("\n")
        ? labeltextBreak(setting.name)
        : setting.name}
      {"）"}
    </>
  );

  useEffect(() => {
    if (setting.master) setDataList(setting.master);
  }, [setting, reportItem, listMasterType]);

  const setDataList = (master: string) => {
    if (reportItem && reportItem[master]) {
      const data_list = addWhiteSpace([...reportItem[master]]);
      setList(data_list);
      setHasManualInput(
        data_list.some((item) => item?.is_manual_input === true),
      );
    }
    // 表示する項目を取得
    if (listMasterType && listMasterType[master]) {
      const custom_item: ICustomItem[] = listMasterType[master].custom_item;
      if (custom_item) {
        const barcode_flg = custom_item.some((v) => v.barcode);
        setBarcodeFlg(barcode_flg);
        setMasterType(custom_item);
      }
    }
  };

  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  // マスタ検索
  const handleSearch = async (text?: string) => {
    try {
      LoadingOverlayController.show();
      var data = {
        SK: activityBaseId + "|" + setting.master,
        barcode_item: text ?? "",
      };
      const response = await getCustomMasterByBarcode(data);
      if (response.data && response.data.length > 0) {
        handleChangeValue(response.data[0]);
        setHasManualInput(response.data[0]?.is_manual_input);
        setList([response.data[0]]);
      } else {
        handleChangeValue();
        setHasManualInput(false);
        setList([]);
        setSearch("");
      }
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 入力
  const handleChangeValue = (selectedItem?: any) => {
    let newValue: any = {};
    if (selectedItem) {
      newValue = {
        id: selectedItem.SK,
        name: selectedItem.is_manual_input ? "" : selectedItem[nameField],
        is_manual_input: selectedItem.is_manual_input,
      };
    } else {
      newValue = {
        id: undefined,
        name: "",
        is_manual_input: false,
      };
    }
    // マスタ追加項目をマスタデータに追加
    masterType.forEach((custom_item, index) => {
      if (!custom_item.isName) {
        const key = custom_item.barcode ? "barcode_item" : `item${index + 1}`;
        newValue[key] = selectedItem ? selectedItem[key] : "";
      }
    });
    onChange(newValue);
  };

  return (
    <>
      {!barcodeFlg ? (
        <SelectLabel
          label={
            setting.required ? (
              <LabelRequired title={labeltextBreak(setting.name)} />
            ) : (
              labeltextBreak(setting.name)
            )
          }
          value={value?.id ?? ""}
          onChange={(e) => {
            if (e.target.value != "") {
              const selectionItem = list.find(
                (item) => item?.SK === e.target.value,
              );
              handleChangeValue(selectionItem);
            } else {
              handleChangeValue();
            }
          }}
        >
          {list.map((item, index) => {
            if (!item) {
              return (
                <MenuItem value={""} key={index}>
                  <em>未選択</em>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem value={item?.SK} key={index}>
                  {item[nameField]}
                </MenuItem>
              );
            }
          })}
        </SelectLabel>
      ) : (
        <Box sx={{ display: "flex" }}>
          <TextField
            label={
              setting.required ? (
                <LabelRequired title={labeltextBreak(setting.name)} />
              ) : (
                labeltextBreak(setting.name)
              )
            }
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            sx={{ flex: 1 }}
          />
          <Box
            sx={{ display: "flex", alignSelf: "flex-end", ml: 1, mb: "10px" }}
          >
            <Button sx={{ minWidth: 60 }} onClick={() => handleSearch(search)}>
              検索
            </Button>
          </Box>
        </Box>
      )}
      {hasManualInput && (
        <TextField
          label={otherTitle}
          value={value?.is_manual_input ? value.name : ""}
          inputProps={{
            maxLength: 30,
          }}
          onChange={(e) => {
            onChange({ ...value, name: e.target.value });
          }}
          disabled={!value?.is_manual_input}
        />
      )}
      {barcodeFlg && !hasManualInput && (
        <TextField label={"名称"} value={value?.name} disabled />
      )}
      {masterType.map((custom_item, index) => {
        if (custom_item.barcode) return null;
        const selectionItem: any = value;
        const key = custom_item.isName ? "name" : `item${index + 1}`;
        return (
          <TextField
            label={custom_item.name}
            value={selectionItem ? selectionItem[key] : ""}
            disabled
            key={index}
          />
        );
      })}
    </>
  );
};

export default MasterInputField;
