import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import SignatureCanvas from "react-signature-canvas";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { Colors } from "@template/style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";

const SliderCustom = styled(Slider)(() => ({
  "& .MuiSlider-thumb": {
    color: "rgb(180, 180, 180)",
    "&:hover, &.Mui-active": {
      boxShadow: "0 0 0 8px rgba(180, 180, 180, 0.26)",
    },
  },
  "& .MuiSlider-track": {
    color: "rgb(180, 180, 180)",
    height: 8,
  },
  "& .MuiSlider-rail": {
    color: "rgb(180, 180, 180)",
    height: 8,
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange?: (file: any, base64: any) => void;
  title?: React.ReactNode;
  disabledNext?: boolean;
  onOpenNext?: () => void;
  disabledPrev?: boolean;
  onOpenPrev?: () => void;
}

const ModalSignatureCanvas = (
  {
  open,
  onClose,
  onChange = () => {},
    title,
    disabledNext = false,
    onOpenNext = () => {},
    disabledPrev = false,
    onOpenPrev = () => {},
  }: IProps,
  ref: any,
) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [canvasHistory, setCanvasHistory] = useState<any[]>([]);
  const [originCanvas, setOriginCanvas] = useState<any[]>([]);
  const minWidth = useSelector(
    (state: RootState) => state.editTemplate.signature_pen_min_width,
  );
  const maxWidth = useSelector(
    (state: RootState) => state.editTemplate.signature_pen_max_width,
  );
  const dispatch = useDispatch();
  const signatureRef = useRef<any>();

  useEffect(() => {
    if (open) {
      setOriginCanvas([...canvasHistory]);
    }
  }, [open]);

  useImperativeHandle(ref, () => ({
    clearCanvas: () => {
      setCanvasHistory([]);
    },
  }));

  // ------------------------------------------------------------------
  // サイン入力
  // ------------------------------------------------------------------
  const saveHistory = () => {
    const point = signatureRef.current.toData();
    setCanvasHistory([...point]);
  };

  // ------------------------------------------------------------------
  // 線の太さ
  // ------------------------------------------------------------------
  const handleSliderChange = (e: number | number[]) => {
    const v = typeof e == "number" ? e : e[0];
    const minW = v - 2;
    const maxW = v;
    dispatch({
      type: TYPES.SET_SIGNATURE_PEN_SIZE,
      payload: {
        signature_pen_min_width: minW,
        signature_pen_max_width: maxW,
      },
    });
    dispatch({
      type: TYPES.SET_EDIT_TEMPLATE,
      payload: {
        signature_pen_min_width: minW,
        signature_pen_max_width: maxW,
      },
    });
  };

  // ------------------------------------------------------------------
  // クリア
  // ------------------------------------------------------------------
  const handleClear = () => {
    setCanvasHistory([]);
    signatureRef.current.fromData([]);
  };

  // ------------------------------------------------------------------
  // １つ前に戻す
  // ------------------------------------------------------------------
  const handleUndo = () => {
    const arr = canvasHistory.slice(0, canvasHistory.length - 1);
    setCanvasHistory([...arr]);
    signatureRef.current.fromData(arr);
  };

  // ------------------------------------------------------------------
  // 閉じる
  // ------------------------------------------------------------------
  const handleClose = () => {
    onClose();
  };

  // ------------------------------------------------------------------
  // キャンセル
  // ------------------------------------------------------------------

  const handleConfirmClose = () => {
    if (_.isEqual(originCanvas, canvasHistory)) {
      handleCancel();
    } else {
      ModalController.show({
        message: messages.TEMPLATE.MSG_CONFIRM_CANCEL_SIGNATURE,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: handleCancel,
      });
    }
  };

  const handleCancel = () => {
    setCanvasHistory([...originCanvas]);
    handleClose();
  };

  // ------------------------------------------------------------------
  // 保存
  // ------------------------------------------------------------------
  const handleChange = () => {
    if (signatureRef.current && signatureRef.current.toData().length > 0) {
      const base64 = signatureRef.current.toDataURL("image/jpeg");
      const blob = atob(base64.replace(/^.*,/, ""));
      let buffer = new Uint8Array(blob.length);
      for (let i = 0; i < blob.length; i++) {
        buffer[i] = blob.charCodeAt(i);
      }
      let image_file: any = new File([buffer.buffer], `sign_${uuidv4()}.jpeg`, {
        type: "image/jpeg",
      });
      image_file["uri"] = base64;
      onChange(image_file, base64);
    } else {
      onChange(undefined, undefined);
    }
    handleClose();
  };

  const handleOpenNext = () => {
    // 保存して次へ
    handleChange();
    onOpenNext();
  };

  const handleOpenPrev = () => {
    // 保存して前へ
    handleChange();
    onOpenPrev();
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{ "& .MuiDialog-paper": { width: 800, height: title ? 320 : 290 } }}
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ pb: 1, pt: 2 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: 0.5,
          }}
        >
          {title && <Typography>{title}</Typography>}
          <Box sx={{ flex: 1 }} />
          <IconButton onClick={handleConfirmClose} sx={{ p: 0.5 }}>
            <Cancel />
          </IconButton>
        </Box>
        <Box>
          <SignatureCanvas
            penColor="black"
            backgroundColor="white"
            canvasProps={{
              width: 750,
              height: 190,
              style: {
                borderRadius: 8,
                border: "solid",
                borderColor: Colors.BORDER,
                borderWidth: 1,
              },
              className: "sigCanvas",
            }}
            minWidth={minWidth}
            maxWidth={maxWidth}
            onEnd={saveHistory}
            ref={(e) => {
              signatureRef.current = e;
              if (e && open) {
                e.fromData([...canvasHistory]);
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start", mb: 1, px: 3 }}>
        <Stack direction="row" alignItems="center" width="100%">
          <Stack direction="row" alignItems="center" width={200} spacing={1.5}>
            <Typography fontSize={14}>細</Typography>
            <SliderCustom
              defaultValue={maxWidth}
              max={10}
              min={2.5}
              step={0.5}
              onChange={(_, v) => {
                handleSliderChange(v);
              }}
            />
            <Typography fontSize={14}>太</Typography>
          </Stack>
          <Box sx={{ display: "flex", flexGrow: 1 }} />
        <Button
            onClick={handleOpenPrev}
            color="secondary"
            disabled={disabledPrev}
        >
            保存して前
          </Button>
          <Button onClick={handleClear} disabled={canvasHistory.length == 0}>
            クリア
        </Button>
        <Button onClick={handleChange} color="secondary">
            保存後終了
        </Button>
          <Button
            onClick={handleOpenNext}
            color="secondary"
            disabled={disabledNext}
          >
            保存して次
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ModalSignatureCanvas);
