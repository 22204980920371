import { useEffect, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
  Typography,
  MenuItem,
} from "@mui/material";
import {
  DraggableProvided,
  DroppableProvided,
  DropResult,
  Draggable,
  Droppable,
  DragDropContext,
} from "react-beautiful-dnd";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import { sort } from "@utils/index";
import ReportEditDialog, { IData } from "screens/ReportEdit/ReportEditDialog";
import {
  getListReport,
  deleteReport,
  updateReportSort,
  getListReportType,
} from "@api/itemReport";
import { insertMaster, updateMaster } from "@api/master";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import SelectLabel from "components/atoms/SelectLabel";

const initial_data: IData = {
  type: "帳票名",
  name: "",
  index: "",
  index_label: "帳票番号",
  index_type: "number",
  max_length: 2,
};

const ReportEditScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [originData, setOriginData] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editData, setEditData] = useState<IData>(initial_data);
  const [listReportType, setListReportType] = useState<any[]>([]);
  const [reportType, setReportType] = useState<string>("");
  const [selectDisabled, setSelectDisabled] = useState<boolean>(true);
  const [reportTypeCodeList, setReportTypeCodeList] = useState<any[]>([]);

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sorted = sort(data, result.source.index, result.destination.index);
    setData(sorted);
  };

  const handleAdd = () => {
    setEditData(initial_data);
    setOpen(true);
  };

  const handleDelete = (SK: string, report_type: string) => {
    if (!ModalController.isShowing()) {
      const confirmMessage = disabled
        ? messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001
        : messages.COMMON.MSG_COMMON_DELETE_CONFIRM_SORT_001;
      ModalController.show({
        message: confirmMessage,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => handledeleteReport(SK, report_type),
      });
    }
    return;
  };

  const handleCancel = () => {
    setOpen(false);
    setEditIndex(-1);
  };

  const handledeleteReport = async (SK: string, report_type: string) => {
    try {
      LoadingOverlayController.show();
      const res = await deleteReport(SK, report_type);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001("帳票"),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleEdit = (d: any, i: number) => {
    setEditData({
      type: "帳票名",
      name: d.report_name,
      index: d.report_number,
      index_label: "帳票番号",
      index_type: "number",
      max_length: 2,
    });
    setEditIndex(i);
    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_UPDATE_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          setOpen(true);
        },
      });
    } else {
      setOpen(true);
    }
  };

  const handleChange = async (value: IData) => {
    let newData = JSON.parse(JSON.stringify(data));
    let message = "";
    let res = null;
    const selected_report_type = listReportType.filter(
      (item) => item.SK === reportType,
    );
    const formData = {
      type: "REPORT",
      name: value.name,
      color: "",
      color_id: "",
      color_name: "",
      index: value.index,
      parent: reportType,
      activity_base_id: "",
      report_type_code: selected_report_type[0].report_type_code,
    };
    try {
      LoadingOverlayController.show();
      if (editIndex >= 0) {
        res = await updateMaster(formData, newData[editIndex].SK);
        if (res) {
          message = "帳票の更新";
        }
      } else {
        res = await insertMaster(formData);
        if (res) {
          message = "帳票の登録";
        }
      }
      setEditIndex(-1);
      if (res) {
        setData(newData);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleSave = async () => {
    try {
      LoadingOverlayController.show();
      let formData: any[] = [];
      data.map((item) => {
        const datilData = {
          PK: item.PK,
          SK: item.SK,
        };
        formData.push(datilData);
      });

      const res = await updateReportSort(formData);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001("帳票の並び順の更新"),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const fetchDataConfirm = async () => {
    if (!ModalController.isShowing() && !reportType) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED_SELECT("帳票種類"),
        visibleButton2: true,
      });
    }

    if (!ModalController.isShowing() && !disabled) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_DISPLAY_CONFIRM_SORT_001,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => {
          fetchData();
          setData([]);
          setOriginData([]);
          setSelectDisabled(true);
        },
      });
    }
    if (disabled) {
      if (selectDisabled) {
        if (reportType) {
          setSelectDisabled(false);
          fetchData();
        }
      } else {
        setData([]);
        setOriginData([]);
        setSelectDisabled(true);
      }
    }
  };

  const fetchData = async () => {
    LoadingOverlayController.show();
    await getListReport(reportType)
      .then((res) => {
        if (res?.data) {
          let report_type_code_list: any[] = [];
          setData(res.data);
          Object.values(res.data).forEach((d: any, index) => {
            report_type_code_list.push(d["report_number"]);
          });
          setReportTypeCodeList(report_type_code_list);
          setOriginData(res.data);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  const changeReportType = async (val: string) => {
    setReportType(val);
  };

  useEffect(() => {
    setDisabled(JSON.stringify(data) === JSON.stringify(originData));
  }, [data, originData]);

  useEffect(() => {
    getListReportType().then((res) => {
      if (res?.data) {
        setListReportType(res.data);
      }
    });
  }, []);

  return (
    <GenericTemplate title="マスタ管理">
      <Typography variant="h6">帳票</Typography>

      <ReportEditDialog
        data={editData}
        open={open}
        onChange={handleChange}
        onClose={handleCancel}
        duplicationCheckList={reportTypeCodeList}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: 3,
          mb: 3,
        }}
      >
        <SelectLabel
          label="帳票種類"
          value={reportType}
          isIgnoreExtractRuleLabel={false}
          isIgnoreExtractRuleSelect={true}
          onChange={(e) => changeReportType(e.target.value)}
          sx={{ width: 500 }}
          disabled={!selectDisabled}
        >
          {listReportType.map((item: any) => (
            <MenuItem value={item.SK} key={item.SK}>
              <span className="IgnoreExtractRuleTarget">
                {item.report_type_code + ":" + item.report_type_name}
              </span>
            </MenuItem>
          ))}
        </SelectLabel>
        <Button
          onClick={fetchDataConfirm}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: 3,
            ml: 2,
          }}
        >
          {selectDisabled ? "選択" : "解除"}
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography variant="body2">ドラッグ＆ドロップで並べ替え</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={handleAdd}
          endIcon={<AddCircle />}
          disabled={selectDisabled}
        >
          追加
        </Button>
      </Box>

      {data.length > 0 && !selectDisabled && (
        <>
          <Box>
            <DragDropContext onDragEnd={handleDnd}>
              <Droppable droppableId="droppableId-1">
                {(provided: DroppableProvided) => (
                  <List ref={provided.innerRef} {...provided.droppableProps}>
                    {data.map((item, i) => (
                      <Draggable key={item.SK} draggableId={item.SK} index={i}>
                        {(provided: DraggableProvided) => (
                          <ListItem
                            sx={{ bgcolor: "white" }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            secondaryAction={
                              <>
                                <Tooltip title="編集">
                                  <IconButton
                                    edge="end"
                                    sx={{ mx: 1 }}
                                    onClick={() => handleEdit(item, i)}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="削除">
                                  <IconButton
                                    edge="end"
                                    onClick={() =>
                                      handleDelete(item.SK, reportType)
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                            divider
                          >
                            <span className="IgnoreExtractRuleTarget">
                              <ListItemText
                                primary={
                                  item.report_number + ":" + item.report_name
                                }
                                primaryTypographyProps={{
                                  style: {
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    marginRight: "40px",
                                  },
                                }}
                              />
                            </span>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box sx={{ height: 30 }} />
          <Box
            sx={{
              position: "fixed",
              margin: 0,
              right: 0,
              bottom: 20,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Button onClick={handleSave} color="secondary" disabled={disabled}>
              保存
            </Button>
          </Box>
        </>
      )}
    </GenericTemplate>
  );
};

export default ReportEditScreen;
