import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { anshinCallOptions } from "../fakeData";
import SelectLabelYuasa from "../components/SelectLabelYuasa";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import { RootState } from "store/reducer";
import { StateFormType } from "@utils/template/yuasa";

interface IProps {
  stateForm: StateFormType;
  onChangeText: (field: keyof StateFormType) => (newText: any) => void;
  formError: { [key: string]: string };
}

interface IFormData {
  departure_time: any;
  arrival_time: any;
  completion_time: any;
  transport_end_time: any;
  matter_end_time: any;
}

const InfoDispatch: React.FC<IProps> = ({
  stateForm,
  onChangeText,
  formError,
}) => {
  const [formData, setFormData] = useState<IFormData>({
    departure_time: stateForm.departure_time,
    arrival_time: stateForm.arrival_time,
    completion_time: stateForm.completion_time,
    transport_end_time: stateForm.transport_end_time,
    matter_end_time: stateForm.matter_end_time,
  });
  const { listDispatchVehicle } = useSelector(
    (state: RootState) => state.masterYuasa,
  );

  const updateFormData = (field: keyof IFormData, str_time: string) => {
    if (str_time) {
      let dt = new Date();
      let time = str_time.split(":");
      dt.setHours(Number(time[0]), Number(time[1]), 0);
      if (dt !== formData[field]) {
        setFormData((prev) => ({ ...prev, [field]: dt }));
      }
    }
  };

  useEffect(() => {
    updateFormData("departure_time", stateForm.departure_time);
  }, [stateForm.departure_time]);

  useEffect(() => {
    updateFormData("arrival_time", stateForm.arrival_time);
  }, [stateForm.arrival_time]);

  useEffect(() => {
    updateFormData("completion_time", stateForm.completion_time);
  }, [stateForm.completion_time]);

  useEffect(() => {
    updateFormData("transport_end_time", stateForm.transport_end_time);
  }, [stateForm.transport_end_time]);

  useEffect(() => {
    updateFormData("matter_end_time", stateForm.matter_end_time);
  }, [stateForm.matter_end_time]);

  const errorForwarding = useMemo(
    () => formError.forwarding_distance.length > 0,
    [formError],
  );
  const errorTransportation = useMemo(
    () => formError.transportation_distance.length > 0,
    [formError],
  );

  const errorSecondary = useMemo(
    () => formError.secondary_transportation.length > 0,
    [formError],
  );

  return (
    <Stack sx={styles.formStack}>
      <TextField
        label="出動隊員名"
        value={stateForm.dispatcher_name}
        onChange={(e) => onChangeText("dispatcher_name")(e.target.value)}
        inputProps={{ maxLength: 20 }}
      />
      <TextField
        label="お客様電話番号"
        value={stateForm.customer_number}
        disabled
      />
      <SelectLabelYuasa
        label="安心コール"
        value={stateForm.anshin_call?.SK}
        data={anshinCallOptions}
        onChange={onChangeText("anshin_call")}
        valueFieldName="value"
      />
      <FormControl>
        <InputLabel error={errorForwarding}> 回送距離</InputLabel>
        <OutlinedInput
          onChange={(e) => onChangeText("forwarding_distance")(e.target.value)}
          value={stateForm.forwarding_distance}
          inputProps={{ maxLength: 4 }}
          endAdornment={<InputAdornment position="end">km</InputAdornment>}
          error={errorForwarding}
        />
        <FormHelperText error>{formError.forwarding_distance}</FormHelperText>
      </FormControl>
      <FormControl>
        <InputLabel error={errorTransportation}>搬送距離</InputLabel>
        <OutlinedInput
          onChange={(e) =>
            onChangeText("transportation_distance")(e.target.value)
          }
          value={stateForm.transportation_distance}
          inputProps={{ maxLength: 4 }}
          endAdornment={<InputAdornment position="end">km</InputAdornment>}
          error={errorTransportation}
        />
        <FormHelperText error>
          {formError.transportation_distance}
        </FormHelperText>
      </FormControl>
      <FormControl>
        <InputLabel error={errorSecondary}>二次搬送</InputLabel>
        <OutlinedInput
          onChange={(e) =>
            onChangeText("secondary_transportation")(e.target.value)
          }
          value={stateForm.secondary_transportation}
          inputProps={{ maxLength: 4 }}
          endAdornment={<InputAdornment position="end">km</InputAdornment>}
          error={errorSecondary}
        />
        <FormHelperText error>
          {formError.secondary_transportation}
        </FormHelperText>
      </FormControl>
      <SelectLabelYuasa
        label="出動車両"
        value={stateForm.dispatch_vehicle?.SK}
        data={listDispatchVehicle}
        onChange={onChangeText("dispatch_vehicle")}
      />
      <TimePickerCustom
        label="出発時間"
        value={formData.departure_time}
        onChange={(v) => {
          setFormData((prev) => ({ ...prev, departure_time: v }));
          onChangeText("departure_time")(v);
        }}
        helperText={formError.departure_time}
        error={formError.departure_time.length > 0}
      />
      <TimePickerCustom
        label="到着時間"
        value={formData.arrival_time}
        onChange={(v) => {
          setFormData((prev) => ({ ...prev, arrival_time: v }));
          onChangeText("arrival_time")(v);
        }}
        helperText={formError.arrival_time}
        error={formError.arrival_time.length > 0}
      />
      <TimePickerCustom
        label="完了時間"
        value={formData.completion_time}
        onChange={(v) => {
          setFormData((prev) => ({ ...prev, completion_time: v }));
          onChangeText("completion_time")(v);
        }}
        helperText={formError.completion_time}
        error={formError.completion_time.length > 0}
      />
      <TimePickerCustom
        label="搬送終了時間"
        value={formData.transport_end_time}
        onChange={(v) => {
          setFormData((prev) => ({ ...prev, transport_end_time: v }));
          onChangeText("transport_end_time")(v);
        }}
        helperText={formError.transport_end_time}
        error={formError.transport_end_time.length > 0}
      />
      <TimePickerCustom
        label="案件終了時間"
        value={formData.matter_end_time}
        onChange={(v) => {
          setFormData((prev) => ({ ...prev, matter_end_time: v }));
          onChangeText("matter_end_time")(v);
        }}
        helperText={formError.matter_end_time}
        error={formError.matter_end_time.length > 0}
      />
    </Stack>
  );
};

export default InfoDispatch;

const styles = {
  formStack: { pb: 0, width: "100%" },
};
