import { TYPES } from "store/types";

const initialState = {
  listRequestFor: [],
  listCityName: [],
  listCarNumberPlace: [],
  listTypeOfWork: [],
  listDispatchVehicle: [],
  listTransportWork: [],
  listDrivingOnShoulder: [],
  listHighTeam: [],
  listRequestLine: [],
  listTowingShop: [],
  listStoreCar: [],
  inter_limit: 10,
};

export const masterYuasaReducer = (
  state = initialState,
  { payload, type }: any,
) => {
  switch (type) {
    case TYPES.SET_MASTER_ITEM_YUASA: {
      return {
        ...state,
        ...payload?.data,
      };
    }
    case TYPES.RESET_MASTER_ITEM_YUASA: {
      return { ...initialState };
    }
    case TYPES.SET_INTER_LIMIT: {
      return { ...state, ...payload?.data };
    }
    default:
      return state;
  }
};
