import { METHOD_API, createConnector, getParamsHelp } from "./Connector";
const prefixApi = "api/v1";

export const apiCreateUser = (data: any) =>
  createConnector(METHOD_API.POST, `${prefixApi}/user`, data);

export const apiUpdateUser = (data: any) =>
  createConnector(METHOD_API.PUT, `${prefixApi}/user`, data);

export const apiRemoveUser = (user_id: string) =>
  createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/user/${encodeURIComponent(user_id)}`,
  );

export const apiRemoveInviteUser = (contract_id: string, user_id: string) =>
  createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/invite/user?${getParamsHelp({
      contract_id: contract_id,
      SK: user_id,
    })}`,
  );

export const apiGetLoginUserInfo = () =>
  createConnector(METHOD_API.GET, `${prefixApi}/user`);

export const apiGetUserInfo = (user_id: string) =>
  createConnector(
    METHOD_API.GET,
    `${prefixApi}/user/member-info?${getParamsHelp({ user_id: user_id })}`,
  );

export const apiCheckLoginId = (value: string) =>
  createConnector(METHOD_API.POST, `${prefixApi}/check-user-register-exist`, {
    preferred_username: value,
  });

export const updateUserApi = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/user`,
    dataBody,
  );
};

export const updatePasswordApi = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/update-password`,
    dataBody,
  );
};

export const updatePasswordOthersApi = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/update-password-others`,
    dataBody,
  );
};

export const checkUserJoinContractApi = (contract_id?: string) => {
  let url_params = "";
  if (contract_id !== undefined) {
    const params = { contract_id: contract_id };
    url_params = `?${getParamsHelp(params)}`;
  }
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/check-user-join-contract${url_params}`,
    null,
  );
};

export const getUserInfoByIDApi = (user_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/user/member-info?${getParamsHelp({ user_id: user_id })}`,
    null,
  );
};

export const deleteUserApi = (user_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/user/${encodeURIComponent(user_id)}`,
    null,
  );
};

export const resetPasswordApi = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/reset-password`,
    dataBody,
  );
};

export const getListPositionApi = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/get-list-position`);
};

export const createUserApi = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/user`,
    dataBody,
  );
};

export const getListUserByLocationId = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-user-by-location-id`,
    null,
  );
};
