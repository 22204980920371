import React from "react";
import { Button, Divider, List, ListItem } from "@mui/material";
import { Colors } from "@template/style";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";

interface IProps {
  data: any[]; // リストデータ
  renderItem: (value: any, index: number) => React.ReactNode;
  handleCancel?: (index: number) => void;
  cancelBtnDisabled?: boolean;
  addBtnTitle?: React.ReactNode;
  handleAdd?: () => void;
  addBtnDisabled?: boolean;
}

const ListCancelBadge: React.FC<IProps> = ({
  data,
  renderItem,
  handleCancel = () => {},
  cancelBtnDisabled = false,
  addBtnTitle = "追加",
  handleAdd = () => {},
  addBtnDisabled = false,
}) => {
  return (
    <List
      sx={{
        width: "100%",
        py: 0,
        borderRadius: 2,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
      }}
    >
      {data.map((value, index) => {
        return (
          <React.Fragment key={index}>
            <ListItem sx={{ color: Colors.TEXT }}>
              <CancelBtnBadge
                onClick={() => handleCancel(index)}
                sx={{ width: "100%" }}
                componentsProps={{
                  badge: { style: { top: 13, right: 3 } },
                }}
                disabled={cancelBtnDisabled}
              >
                {renderItem(value, index)}
              </CancelBtnBadge>
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        );
      })}
      <ListItem disablePadding sx={{ py: 1 }}>
        <Button
          variant="text"
          fullWidth
          onClick={handleAdd}
          disabled={addBtnDisabled}
          children={addBtnTitle}
        />
      </ListItem>
    </List>
  );
};

export default ListCancelBadge;
