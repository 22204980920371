import { useEffect, useMemo, useState, VFC } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Button, Grid, Typography } from "@mui/material";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";
import {
  AddCircle as AddCircleIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import GenericTemplate from "@template/index";
import { getListWorkApi, getListWorkV2Api } from "@api/work";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { Colors } from "@template/style";
import WorkItem from "components/molecules/WorkItem";
import PopupMenu, { IMenuData } from "components/atoms/PopupMenu";
import PopupSort from "components/atoms/PopupSort";
import ScrollLoader from "components/atoms/ScrollLoader";
import FilterInput from "components/molecules/FilterInput";
import {
  LIST_TYPE_SORT_WORK,
  StatisticalBoardType,
  STATUS_WORK_TLOG,
} from "@shared-constants";
import messages from "config/messages";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import { Work } from "services/models";
import { getSortOfScreen } from "selector/sortSelector";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import { getParamsFilter, getUserInfo } from "@utils/index";
import { useLocation } from "react-router-dom";
import { toString } from "lodash";
import WorkItemTlog from "components/molecules/WorkItem/WorkItemTlog";
import { getDetailProjectByWorkId } from "@api/project";

let paramsFilter: any;

const WorkStatusScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------

  const location = useLocation<any>();
  const work_type = location.state?.work_type;
  const title = location.state?.title;
  const type = location.state?.type;

  const { selected_project_id, selected_project_name } = useSelector(
    (state: RootState) => state.projectInfo,
  );
  const [data, setData] = useState([]);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [PK, setPK] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const hasCondition = useSelector(getHasFilterConditionProject("work_status"));
  const workFilter = useSelector(getConditionFilter("work_status"));
  const typeSort = useSelector(getSortOfScreen("work_status"));
  const [searchedKeyword, setSearchKeyword] = useState<string>("");
  const [isDisableFilter, setIsDisableFilter] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [menuItem, setMenuItem] = useState<Array<IMenuData>>([]);
  const user = getUserInfo();

  const history = useHistoryCustom();
  const dispatch = useDispatch();

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchListWorkItem = async (
    keyWord?: string,
    sortType?: string,
    lastKeyword?: string,
    sort_by?: string,
    isMerge: boolean = false,
    pk?: string,
  ) => {
    setLoading(true);
    if (data.length === 0) {
      LoadingOverlayController.show();
    }
    let params = {
      keyword: keyWord ?? searchedKeyword,
      sort_type: sortType ?? typeSort,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy,
      pk: pk ?? PK,
      filter_condition: paramsFilter,
      work_type: work_type,
      is_work_owner: toString(type === StatisticalBoardType.register),
    };

    await getListWorkV2Api(params)
      .then((res) => {
        if (res?.data.length === 0 && !res?.lastKey) {
          setLoadMore(false);
        }

        if (isMerge) {
          setData(data.concat(res?.data));
        } else {
          setData(res?.data);
        }
        if (res?.last_key) {
          setLastKey(res?.last_key?.SK ?? null);
          setSortBy(res?.last_key?.sort_by ?? null);
          setPK(res?.last_key?.PK ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setPK(null);
          setLoadMore(false);
        }
        if (res?.length < 10 && res.last_key) {
          fetchListWorkItem(
            params.keyword,
            params.sort_type,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
            res?.last_key?.PK,
          );
        }
        if (!res?.last_key || (res.data.length >= 10 && res.last_key)) {
          LoadingOverlayController.hide();
        }
      })
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && data.length > 0;
  }, [data, loadMore, lastKey]);

  useEffect(() => {
    paramsFilter = getParamsFilter(workFilter);
    // dispatch({ type: TYPES.GET_LIST_LOCATION });
    fetchListWorkItem();
  }, []);

  useEffect(() => {
    getMenuItem();
  }, [work_type, type]);

  const handleOnClick = async (work_id: string, data: Work) => {
    if (
      data.work_status === STATUS_WORK_TLOG.DRAFT &&
      data?.created_by === user.user_id
    ) {
      const res = await getDetailProjectByWorkId(work_id);
      if (res?.data) {
        dispatch({ type: TYPES.SET_PROJECT_INFO, payload: res?.data });
      }
      history.pushWithRef("/work/create", {
        data: data,
      });
    } else {
      dispatch({
        type: TYPES.SET_SELECTED_WORK_ID,
        selected_work_id: work_id,
        selected_work_name: data.work_name,
      });
      dispatch({
        type: TYPES.SET_SELECTED_PROJECT_ID,
        selected_project_id: data?.project_id,
      });
      history.pushWithRef("/work/confirm", { request_or_register: type });
    }
  };

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!data.length && !hasCondition) {
      setIsDisableFilter(true);
    } else {
      setIsDisableFilter(false);
    }
  }, [data]);

  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy(null);
    }
    if (lastKey) {
      setLastKey(null);
    }
    if (PK) {
      setPK(null);
    }
    paramsFilter = getParamsFilter(workFilter);
  }, [workFilter]);

  // ------------------------------------------------------------------
  // 並べ替え
  // ------------------------------------------------------------------
  const handleSort = (type_sort: string) => {
    LoadingOverlayController.show();
    setSortBy(type_sort);
    const payload = {
      type_screen: "work_status",
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchListWorkItem(undefined, type_sort, "", "");
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_WORK).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_WORK).indexOf(defaultSort[0]);
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (str: string) => {
    setSearchKeyword(str);
    setLoadMore(true);
    setLastKey(null);
    setSortBy(null);
    setPK(null);
    LoadingOverlayController.show();
    fetchListWorkItem(str, undefined, "", "");
  };

  const getMenuItem = () => {
    if (
      type == StatisticalBoardType.register &&
      work_type == STATUS_WORK_TLOG.DRAFT
    ) {
      setMenuItem([
        {
          name: "削除",
          onClick: () => {
            history.pushWithRef("/work/group", {
              typeAction: "2",
              work_type: work_type,
              type: type,
            });
          },
          icon: <CancelIcon />,
          itemSx: { color: Colors.ERROR_TEXT },
        },
      ]);
    } else if (
      type == StatisticalBoardType.request &&
      work_type == STATUS_WORK_TLOG.NOT_APPROVED
    ) {
      setMenuItem([
        {
          name: "一括承認",
          onClick: () => {
            history.pushWithRef("/work/group", {
              typeAction: "3",
              work_type: work_type,
              type: type,
            });
          },
          itemSx: { color: Colors.MAIN_GREEN },
        },
      ]);
    }
  };

  return (
    <GenericTemplate
      title={title + "一覧"}
      isSearch={true}
      searchPlaceholder="帳票番号、品名で検索"
      onSearch={(v) => _handleSearch(v)}
      onSearchClear={() => _handleSearch("")}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md="auto">
          {/* =================== メニュー =================== */}
          <PopupMenu
            data={menuItem}
            disabled={
              (type == StatisticalBoardType.register &&
                work_type != STATUS_WORK_TLOG.DRAFT) ||
              (type == StatisticalBoardType.request &&
                work_type != STATUS_WORK_TLOG.NOT_APPROVED)
            }
          />
          {/* =================== 並べ替え =================== */}
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_WORK).map((item) => {
              return {
                title: item.title,
                onClick: () => handleSort(item.value),
              };
            })}
            defaultIndex={sortDefaultIndex}
            disabled={data.length === 0}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ width: { md: "40%" }, marginLeft: "auto" }}
        >
          {/* =================== 絞り込み =================== */}
          <FilterInput
            typeScreen="work_status"
            disabled={isDisableFilter}
            onFetchData={() => {
              LoadingOverlayController.show();
              fetchListWorkItem(undefined, undefined, "", "");
            }}
          />
        </Grid>
      </Grid>

      {/* =================== 作業 =================== */}
      <InfiniteScroll
        loadMore={() => {
          if (loadMore && lastKey && data.length > 0 && !loading) {
            fetchListWorkItem(undefined, undefined, lastKey, undefined, true);
          }
        }}
        hasMore={showLoadMore}
        loader={<ScrollLoader key={0} />}
      >
        <Grid container spacing={3} sx={{ pt: 2, pb: 5 }}>
          {data.map((d: Work, i) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={i}>
                <WorkItemTlog
                  data={d}
                  onClick={async (d) => {
                    // console.log(d);
                    const work_id = d.SK?.startsWith("WORK#") ? d.SK : d.PK;
                    await handleOnClick(work_id, d);
                  }}
                  key={i}
                  showPrinted={d.print_2d_barcode}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {data.length === 0 && !loading && (
        <Typography>
          {searchedKeyword === ""
            ? messages.COMMON.MSG_NOT_EXIST("作業")
            : messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)}
        </Typography>
      )}
    </GenericTemplate>
  );
};

export default WorkStatusScreen;
