import { METHOD_API, createConnector, getParamsHelp } from "./Connector";
const prefixApi = "api/v1";

type DataParamSaveNoticeEmail = {
  object_data: {
    PK: string;
    SK: string;
    type_content: string;
    title: string;
    content: string;
  };
  list_checkbox_status: {
    PK: string;
    SK: string;
    notification_status: boolean;
  }[];
};

export const getListDataNotificationEmail = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-data-notification-email`,
    null,
  );
};

export const saveDataNotificationEmail = (data: DataParamSaveNoticeEmail) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/save-data-notification-email`,
    data,
  );
};
