import React, { ReactElement, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { Colors } from "@template/style";

export interface IMenuData {
  name: string;
  onClick: () => void;
  icon?: ReactElement;
  itemSx?: SxProps<Theme>;
  disabled?: boolean;
}

interface PopupMenuProps {
  data: IMenuData[];
  title?: string;
  disabled?: boolean;
}

const PopupMenu = ({ data, title = "メニュー", disabled }: PopupMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: Colors.TEXT, pl: 0 }}
        disabled={disabled}
      >
        <MoreVert />
        <Typography>{title}</Typography>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {data.map((value: IMenuData, index) => {
          return (
            <MenuItem
              onClick={() => {
                value.onClick();
                handleClose();
              }}
              sx={value.itemSx}
              key={index}
              disabled={value.disabled}
            >
              {value.icon}
              <Typography sx={{ ml: 1 }}>{value.name}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default PopupMenu;
