import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { STORAGE } from "@utils/Storage";
import { getUserInfo } from "@utils/index";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";

const MailConfirmScreen = () => {
  let { work_id, user_id } = useParams<{ work_id: string; user_id: string }>();
  user_id = decodeURIComponent(user_id);
  work_id = decodeURIComponent(work_id);

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: RootState) => state.authInfo.loggedIn);
  const token = localStorage.getItem(STORAGE.ACCESS_TOKEN);
  const user_info = getUserInfo();

  dispatch({
    type: TYPES.SET_MAIL_WORK_CONFIRM,
    work_id: work_id,
    user_id: user_id,
  });

  if (isLoggedIn && token) {
    if (user_info.SK === user_id) {
      // メール通知ユーザーがログイン
      // 作業内容確認画面
      return <Redirect to="/work/confirm" />;
    } else {
      // 別のユーザーがログイン
      // 作業内容確認画面
      return <Redirect to="/work/confirm" />;
    }
  } else {
    // 未ログイン
    // ログイン画面
    return <Redirect to="/" />;
  }
};

export default MailConfirmScreen;
