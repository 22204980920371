import {
  METHOD_API,
  createConnector,
  prefixApi,
  getParamsHelp,
} from "./Connector";

export const getMemberListApi = (
  contract_id?: string,
  keyword?: string,
  flag_sort?: string,
  filter_condition?: string,
) => {
  let url_params = "";
  let params = {
    contract_id: "",
    keyword: "",
    flag_sort: "",
    filter_condition: "",
  };
  if (contract_id !== undefined) {
    params.contract_id = contract_id;
  }
  if (keyword !== undefined) {
    params.keyword = keyword;
  }
  if (flag_sort !== undefined) {
    params.flag_sort = flag_sort;
  }
  if (filter_condition !== undefined) {
    params.filter_condition = filter_condition;
  }
  url_params = `?${getParamsHelp(params)}`;

  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/contract-members${url_params}`,
    null,
  );
};
