import Axios from "axios";
import jwtDecode from "jwt-decode";
import { getDataStorage, STORAGE, storeData } from "@utils/Storage";
import { httpClient, updateAccessToken } from "./HttpClient";
//require("dotenv").config();

export const prefixApi = "api/v1";

export enum METHOD_API {
  GET = 1,
  POST = 2,
  DELETE = 3,
  PUT = 4,
  PATCH = 5,
  UPLOAD_FORM_POST = 6,
  UPLOAD_FORM_PUT = 7,
}

export const createConnector = async (
  type: METHOD_API,
  url: string,
  data?: any,
) => {
  const DOMAIN = process.env.REACT_APP_API_URL;
  let token = "";

  const getData = async () => {
    try {
      const current_access_token = getDataStorage(STORAGE.ACCESS_TOKEN);
      if (current_access_token !== "") {
        // localStoage.setItem("token", data.token);
        token = current_access_token;
        updateAccessToken(token);
        let token_decode = jwtDecode(token);
        const currentTime = new Date().getTime() / 1000;

        const token_exp = Number(
          JSON.parse(JSON.stringify(token_decode))["exp"],
        );
        const remainTime = token_exp - currentTime;

        if (remainTime <= 0) {
          let refresh_token = getDataStorage(STORAGE.REFRESH_TOKEN);
          if (refresh_token !== "") {
            try {
              const res = await Axios.post(
                `${DOMAIN}/${prefixApi}/refresh-token`,
                {
                  refresh_token,
                },
              );
              token = res.data.AuthenticationResult.AccessToken;
              updateAccessToken(token);
              storeData(STORAGE.ACCESS_TOKEN, token);
            } catch (error: any) {
              if (error?.message === "Network Error") {
                console.log(error);
                return;
              }
              updateAccessToken(null);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  await getData();
  // const config: AxiosRequestConfig = {};
  // if (token) {
  //   config.headers = {
  //     "x-authorization": "Bearer " + token,
  //   };
  // }

  const obAxios = {
    [METHOD_API.GET]: async () =>
      await httpClient
        .get(`${DOMAIN}/${url}`)
        .then((x) => (x ? x.data : undefined)),
    [METHOD_API.POST]: async () =>
      await httpClient
        .post(`${DOMAIN}/${url}`, data)
        .then((x) => (x ? x.data : undefined)),
    [METHOD_API.DELETE]: async () =>
      await httpClient
        .delete(`${DOMAIN}/${url}`, { data })
        .then((x) => (x ? x.data : undefined)),
    [METHOD_API.PATCH]: async () =>
      await httpClient
        .patch(`${DOMAIN}/${url}`, data)
        .then((x) => (x ? x.data : undefined)),
    [METHOD_API.PUT]: async () =>
      await httpClient
        .put(`${DOMAIN}/${url}`, data)
        .then((x) => (x ? x.data : undefined)),
    [METHOD_API.UPLOAD_FORM_POST]: async () =>
      await httpClient
        .post(`${DOMAIN}/${url}`, data, {
          headers: {
            "x-authorization": "Bearer " + token,
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((x) => (x ? x.data : undefined)),
    [METHOD_API.UPLOAD_FORM_PUT]: async () =>
      await httpClient
        .put(`${DOMAIN}/${url}`, data, {
          headers: {
            "x-authorization": "Bearer " + token,
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((x) => (x ? x.data : undefined)),
  };

  return await obAxios[type]();
};

export const getParamsHelp = (params: any) => {
  const urlParams = [];
  for (const keys of Object.keys(params)) {
    if (params[keys]) {
      urlParams.push(`${keys}=${encodeURIComponent(params[keys])}`);
    }
  }
  return urlParams.join("&");
};

export const createFormUpload = (body: any = {}) => {
  const data = new FormData();

  Object.keys(body).forEach((key) => {
    if ((body[key] && typeof body[key] === "string") || checkArray(body[key])) {
      let dataOfKey = body[key];
      if (checkArray(body[key]) || checkObject(body[key])) {
        dataOfKey = JSON.stringify(body[key]);
      }
      data.append(key, dataOfKey);
    }
  });

  return data;
};

const checkArray = (value: any) => {
  return value && Array.isArray(value) && value.length > 0;
};

const checkObject = (objCheck: any) => {
  return (
    objCheck &&
    Object.keys(objCheck).length > 0 &&
    objCheck.constructor === Object
  );
};
