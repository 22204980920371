import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Validation, ValidateType } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";

export interface IData {
  type: string;
  name: string;
  index: string;
  index_label: string;
  index_type: ValidateType;
  max_length: number;
}

interface IProps {
  data: IData;
  open: boolean;
  onChange: Function;
  onClose: Function;
  duplicationCheckList?: any[];
}

const ReportEditDialog: VFC<IProps> = ({
  data,
  open,
  onChange,
  onClose,
  duplicationCheckList,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [index, setIndexValue] = useState("");
  const [index_label, setIndexLabel] = useState("");
  const [max_length, setMaxLength] = useState(0);
  const [index_type, setIndexType] = useState<ValidateType>("text");
  const [errorTextReport, setErrorTextReport] = useState("");
  const [errorTextIndex, setErrorTextIndex] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [type, setType] = useState("");
  const dCheckList =
    duplicationCheckList !== undefined
      ? duplicationCheckList.filter((item) => item !== data.index)
      : [];
  useEffect(() => {
    setIndexValue(data.index);
    setIndexLabel(data.index_label);
    setMaxLength(data.max_length);
    setName(data.name);
    setType(data.type);
    setIndexType(data.index_type);
    setErrorTextReport("");
    setErrorTextIndex("");
    if (data.name !== "" && data.index !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeIndexValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIndexValue(e.target.value);
  };

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: type,
      value: name,
      required: true,
      max_length: 30,
    });
    setErrorTextReport(error_text);
    setDisabled(
      error_text.length > 0 || errorTextIndex.length > 0 || index.length == 0,
    );
  };

  const validIndexValue = () => {
    let value = index;
    if (index_label == "帳票番号" && index) {
      value = index.padStart(2, "0");
      setIndexValue(value);
    }
    const error_text = Validation.validate({
      type: index_type,
      name: index_label,
      value: value,
      required: true,
      max_length: max_length,
      existing: dCheckList,
    });
    setErrorTextIndex(error_text);
    setDisabled(
      index.length == 0 || error_text.length > 0 || errorTextReport.length > 0,
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <TextField
              label={
                <>
                  <LabelRequired title={index_label} />
                </>
              }
              name="report_type_index"
              value={index}
              onChange={changeIndexValue}
              onBlur={validIndexValue}
              error={errorTextIndex.length > 0}
              helperText={errorTextIndex}
              inputProps={{
                maxLength: max_length,
              }}
              sx={{
                "& .MuiInputBase-formControl": {
                  width: "60px",
                },
                "& .MuiInputBase-input": {
                  textAlign: max_length == 1 ? "center" : "",
                },
              }}
            />
            <TextField
              label={
                <>
                  <LabelRequired title={type} />
                </>
              }
              name="report_type_name"
              value={name}
              onChange={changeName}
              onBlur={validName}
              error={errorTextReport.length > 0}
              helperText={errorTextReport}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
            setDisabled(true);
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const response: IData = {
              type: type,
              name: name,
              index: index,
              index_label: data.index_label,
              max_length: data.max_length,
              index_type: data.index_type,
            };
            onChange(response);
            onClose();
            setDisabled(true);
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportEditDialog;
