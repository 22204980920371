import { createObjectURL, getFileByUrl } from "..";
import { createHtmlForMultipleText } from "./index";

export type StateFormType = {
  risky_id: string;
  vehicle_id: string;
  vehicles_frame_no: string;
  vehicles_workplace: string;
  vehicles_model: string;
  vehicles_fuel_type: string;
  vehicles_fuel_type_id: string;
  vehicles_name: string;
  driver: any;
  driver_other: string;
  year_month: string;
  check_item: Array<ICheckItem>;
  check_result: {
    [date: number]: ICheckResult;
  };
  memo: string;
};

//チェック項目
export interface ICheckItem {
  id: string;
  section: string;
  item: string;
}

//日次チェック結果
export interface ICheckResult {
  check_user: any;
  check_user_other: string;
  result: {
    [check_item_id: string]: boolean | undefined;
  };
  approver: string;
  approved: boolean;
}

export const getInfoFromTemplate = (htmlString: string) => {
  let addPageExample: string = "";
  const addPageExampleArray = htmlString.split("<!-- {main_page} -->");
  if (addPageExampleArray.length >= 3) {
    addPageExample = addPageExampleArray[1];
  }
  return { addPageExample };
};

const max_row = 20 as const;
const max_string_count = 57 as const;

export const makeNewHtml = (
  newHtml: string,
  data: StateFormType,
  addPageExample: string,
) => {
  let content = insertData(newHtml, data);
  newHtml = content[0];

  while (content[1]) {
    content = insertData(addPageExample, data, content[1]);
    newHtml = newHtml.replace(
      "<!-- {add_more_area} -->",
      `
      <div class="div-page-break"></div>
      ${content[0]}
      <!-- {add_more_area} -->
    `,
    );
  }

  return newHtml;
};

const insertData = (
  newHtml: string,
  data: StateFormType,
  start: number = 0,
) => {
  let {
    vehicles_fuel_type,
    vehicles_model,
    vehicles_workplace,
    vehicles_name,
    driver,
    driver_other,
    year_month,
    check_item,
    check_result,
    memo,
  } = data;

  memo = createHtmlForMultipleText(memo);

  if (vehicles_fuel_type) {
    newHtml = newHtml.replace(
      "<!-- {vehicles_fuel_type} -->",
      vehicles_fuel_type,
    );
  }
  // if (vehicles_model) {
  //   newHtml = newHtml.replace("<!-- {vehicles_model} -->", vehicles_model);
  // }
  if (vehicles_workplace) {
    newHtml = newHtml.replace(
      "<!-- {place} -->",
      countString(vehicles_workplace) > 20
        ? `<div style='font-size: 11pt;'>${vehicles_workplace}</div>`
        : vehicles_workplace,
    );
  }
  if (vehicles_name) {
    newHtml = newHtml.replace("<!-- {vehicle} -->", vehicles_name);
  }
  if (driver) {
    let driver_string = driver.is_manual_input
      ? driver_other
      : driver?.full_name ?? "";
    driver_string =
      countString(driver_string) > 20
        ? `<div style='font-size:7pt; line-height: 12px;'>${driver_string}</div>`
        : driver_string;
    newHtml = newHtml.replace("<!-- {driver} -->", driver_string);
  }
  if (year_month) {
    if (year_month.split("-").length > 1) {
      newHtml = newHtml.replace("<!-- {year} -->", year_month.split("-")[0]);
      newHtml = newHtml.replace("<!-- {month} -->", year_month.split("-")[1]);
    }
  }
  if (memo) {
    newHtml = newHtml.replace("<!-- {memo} -->", memo);
  }

  let item_section = "";
  let merged_flg = false;
  let row = 0;
  let last_index: any = null;
  let max_date = Math.max(...Object.keys(check_result).map((d) => Number(d)));
  check_item.forEach((item, index) => {
    if (start > index) return;

    if (merged_flg) {
      row++;
      merged_flg = false;
    }

    row++;

    // 最大行数以降
    if (row > max_row) {
      if (!last_index) last_index = index;
      return;
    }

    // 点検項目が折り返すとき、行の高さを広げる
    if (countString(item.item) > max_string_count) {
      if (row >= max_row) {
        last_index = index;
        return;
      } else {
        newHtml = newHtml.replace(`<!-- {table_row_height_${row}} -->`, "200%");
        merged_flg = true;
      }
    }

    // 点検箇所が同じ行を結合して表示する
    if (item_section != item.section) {
      let count_item = check_item.filter(
        (v, i) => v.section == item.section && start <= i,
      ).length;

      if (count_item > 1) {
        count_item += check_item.filter(
          (v, i) =>
            v.section == item.section && start <= i && countString(v.item) > 56,
        ).length;

        const row_count =
          max_row - row + 1 < count_item ? max_row - row + 1 : count_item;
        const rate = merged_flg ? 50 : 100;

        newHtml = newHtml.replace(
          `<!-- {part_height_${row}} -->`,
          row_count * rate + "%",
        );
      }
    }

    newHtml = newHtml.replace(
      `<!-- {row_no_${row}} -->`,
      (index + 1).toString(),
    );
    newHtml = newHtml.replace(`<!-- {part_${row}} -->`, item.section);
    newHtml = newHtml.replace(`<!-- {item_${row}} -->`, item.item);
    [...Array(31)].forEach((_, i) => {
      var check = "";
      if (check_result[i]) {
        check = check_result[i].result[item.id]
          ? "✓"
          : check_result[i].result[item.id] === false
          ? "×"
          : "－";
      } else if (i < max_date) {
        check = "－";
      }

      if (check != "")
        newHtml = newHtml.replace(`<!-- {day_${row}_${i + 1}} -->`, check);
    });

    item_section = item.section;
  });

  return [newHtml, last_index];
};

export const mergeTemplateForkliftReport = async (
  templateUri: string,
  data: StateFormType,
) => {
  try {
    const f = await getFileByUrl(templateUri, "template.html", "text/html");
    let html_string = await f.text();
    const res = getInfoFromTemplate(html_string);
    const { addPageExample } = res;
    html_string = makeNewHtml(html_string, data, addPageExample);

    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    const html_blob = new Blob([html_string], { type: "text/html" });
    templateUri = createObjectURL(html_blob);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};

export const handleEditInfoForkliftReport = (templateEditInfo: any) => {
  const newTemplateEditInfo = { ...templateEditInfo };
  return newTemplateEditInfo;
};

export const deleteImageDataWhenFinishRiskyId = (templateEditInfo: any) => {
  if (templateEditInfo) {
    const image_array = templateEditInfo.image_array;
    const newImageArray = image_array.map(() => {
      return {
        name: "",
        type: "",
        uri: "",
      };
    });
    templateEditInfo = {
      ...templateEditInfo,
      image_array: newImageArray,
    };
    return templateEditInfo;
  } else return templateEditInfo;
};

const countString = (text: string) => {
  return text.split("").reduce((count, char) => {
    const len = Math.min(new Blob([char]).size, 2);
    return count + len;
  }, 0);
};
