import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { StateFormType } from "@utils/template/yuasa";
import { RootState } from "store/reducer";
import { weatherOptions } from "../fakeData";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import SelectLabelYuasa from "../components/SelectLabelYuasa";

interface IPropsRequestTab {
  stateForm: StateFormType;
  onChangeText: (field: keyof StateFormType) => (newText: any) => void;
  formError: { [key: string]: string };
}

const RequestTab: React.FC<IPropsRequestTab> = ({
  stateForm,
  onChangeText,
  formError,
}) => {
  const {
    listRequestFor,
    listTypeOfWork,
    listCityName,
    listCarNumberPlace,
    listRequestLine,
  } = useSelector((state: RootState) => state.masterYuasa);
  const [reception_date, setReceptionDate] = useState<any>(
    stateForm.reception_date,
  );
  const [reception_time, setReceptionTime] = useState<any>("");

  useEffect(() => {
    if (stateForm.reception_time) {
      let dt = new Date();
      let time = stateForm.reception_time.split(":");
      dt.setHours(Number(time[0]), Number(time[1]), 0);
      if (dt !== reception_time) setReceptionTime(dt);
    }
  }, [stateForm.reception_time]);

  return (
    <Card>
      <CardContent>
        <Stack sx={styles.formStack}>
          <SelectLabelYuasa
            label="天候"
            value={stateForm.weather?.SK}
            data={weatherOptions}
            onChange={onChangeText("weather")}
            valueFieldName="value"
          />
          <TextField
            label="受付者"
            value={stateForm.receptionist}
            onChange={(e) => onChangeText("receptionist")(e.target.value)}
            inputProps={{ maxLength: 20 }}
          />
          <SelectLabelYuasa
            label="要請依頼"
            value={stateForm.request?.SK}
            data={listRequestFor}
            onChange={onChangeText("request")}
          />
          <TextField
            value={stateForm.request_other}
            onChange={(e) => onChangeText("request_other")(e.target.value)}
            inputProps={{ maxLength: 30 }}
            disabled={!stateForm.request?.is_manual_input}
          />
          <TextField
            label="お客様名"
            value={stateForm.customer_name}
            onChange={(e) => onChangeText("customer_name")(e.target.value)}
            inputProps={{ maxLength: 20 }}
          />
          <FormControl>
            <InputLabel>受付日</InputLabel>
            <Box sx={styles.timeRow}>
              <Typography variant="body2" sx={styles.timeKey}>
                年月日
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <DatePickerCustom
                  value={reception_date}
                  onChange={(v) => {
                    setReceptionDate(v);
                    onChangeText("reception_date")(v);
                  }}
                  helperText={formError.reception_date}
                  error={formError.reception_date.length > 0}
                />
              </Box>
            </Box>
            <Box sx={{ ...styles.timeRow, mt: 0.5 }}>
              <Typography variant="body2" sx={styles.timeKey}>
                時間
              </Typography>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <TimePickerCustom
                  value={reception_time}
                  onChange={(v) => {
                    setReceptionTime(v);
                    onChangeText("reception_time")(v);
                  }}
                  helperText={formError.reception_time}
                  error={formError.reception_time.length > 0}
                />
              </Box>
            </Box>
          </FormControl>
          <SelectLabelYuasa
            label="作業内容"
            value={stateForm.work_content?.SK}
            data={listTypeOfWork}
            onChange={onChangeText("work_content")}
          />
          <div style={{ position: "relative" }}>
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: 0, right: 0.5 }}
            >
              {`${stateForm.work_detail_reception.length}/100`}
            </Typography>
            <TextField
              label="作業詳細"
              value={stateForm.work_detail_reception}
              onChange={(e) =>
                onChangeText("work_detail_reception")(e.target.value)
              }
              fullWidth
              multiline={true}
              inputProps={{ maxLength: 100 }}
            />
          </div>
          <SelectLabelYuasa
            label="現場名"
            value={stateForm.location?.SK}
            data={listCityName}
            onChange={onChangeText("location")}
          />
          <TextField
            value={stateForm.location_other}
            onChange={(e) => onChangeText("location_other")(e.target.value)}
            inputProps={{ maxLength: 30 }}
          />
          <TextField
            label="TEL"
            value={stateForm.customer_number}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[0-9-]*$/;
              if (regex.test(value)) {
                onChangeText("customer_number")(value);
              }
            }}
            inputProps={{ maxLength: 13 }}
          />
          <SelectLabelYuasa
            label="登録番号地名"
            value={stateForm.registration_number_place?.SK}
            data={listCarNumberPlace}
            onChange={onChangeText("registration_number_place")}
          />
          <TextField
            label="登録番号"
            onChange={(e) =>
              onChangeText("registration_number")(e.target.value)
            }
            value={stateForm.registration_number}
            inputProps={{ maxLength: 10 }}
          />
          <TextField
            label="車名"
            onChange={(e) => onChangeText("car_name")(e.target.value)}
            value={stateForm.car_name}
            inputProps={{ maxLength: 30 }}
          />
          <SelectLabelYuasa
            label="ライン"
            value={stateForm.line?.SK}
            data={listRequestLine}
            onChange={onChangeText("line")}
          />
          <TextField
            label="受付No."
            onChange={(e) => onChangeText("reception")(e.target.value)}
            value={stateForm.reception}
            inputProps={{ maxLength: 20 }}
          />
          <FormControl>
            <InputLabel>現着</InputLabel>
            <OutlinedInput
              onChange={(e) =>
                onChangeText("arrival_time_scene")(e.target.value)
              }
              value={stateForm.arrival_time_scene}
              inputProps={{ maxLength: 4 }}
              endAdornment={<InputAdornment position="end">分</InputAdornment>}
            />
          </FormControl>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default RequestTab;

const styles = {
  formStack: { pb: 0, width: "100%" },
  timeRow: { display: "flex", alignItems: "center" },
  timeKey: { width: "60px", textAlign: "center", pr: 1 },
} as const;
