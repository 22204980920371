import { RootState } from "store/reducer";
import { checkArray, checkObject } from "shared/utils";

export const getConditionFilter =
  (type_screen: string = "project") =>
  (state: RootState) => {
    return state.filter[type_screen];
  };

export const getHasFilterConditionProject =
  (typeScreen: string = "project") =>
  (state: RootState) => {
    const filter = state.filter[typeScreen];
    if (!filter) {
      return false;
    }
    for (const value of Object.values(filter)) {
      if (checkObject(value)) {
        return true;
      }
      if (checkArray(value)) {
        return true;
      }
    }
    return false;
  };
