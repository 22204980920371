import { useEffect, useMemo, useState, VFC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { TYPES } from "store/types";
import GenericTemplate from "@template/index";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getDataOfFolderApi, paramGetDataOfFolder } from "@api/manageFolder";
import PopupSort from "components/atoms/PopupSort";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import InfiniteScroll from "react-infinite-scroller";
import ScrollLoader from "components/atoms/ScrollLoader";
import { LIST_TYPE_SORT_FOLDER, FOLDER_KIND } from "@shared-constants";
import FilterInput from "components/molecules/FilterInput";
import { getSortOfScreen } from "selector/sortSelector";
import messages from "config/messages";
import {
  DataFolder,
  DataFolderAndFile,
  defaultData,
  defaultFolder,
} from "screens/ManageFolder";
import { RootState } from "store/reducer";
import FolderFileItem from "components/molecules/FolderFileItem";
import { getParamsFilterV1 } from "shared/utils";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import { List, Apps, AddCircle } from "@mui/icons-material";
import { Colors } from "@template/style";
import ButtonCreateScreenDialog from "../ButtonCreateScreenDialog";

function countOccurences(string: string, word: string) {
  return string.split(word).length - 1;
}

let paramsFilter: any;

enum sortHandle {
  created_at_new = "created_at_new",
  created_at_old = "created_at_old",
  name = "name",
  file_created_at_folder_updated_at_new = "file_created_at_folder_updated_at_new",
  file_created_at_folder_updated_at_old = "file_created_at_folder_updated_at_old",
}

const ListFileScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [sortBy, setSortBy] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  const [searchedKeyword, setSearchKeyword] = useState<string>("");

  const [isDisableFilter, setIsDisableFilter] = useState<boolean>(false);
  const [enableListView, setEnableListView] = useState<boolean>(true);
  const [levelScreen, setLevelScreen] = useState<string>("");
  const [dataFolderApi, setDataFolderApi] =
    useState<DataFolderAndFile>(defaultData);
  const folderLevel = useSelector(
    (state: RootState) => state.folderLevel.folderID,
  );
  const currentLevel = useSelector(
    (state: RootState) => state.folderLevel.currentLevel,
  );
  const [data, setData] = useState<DataFolderAndFile>(defaultData);
  const [flagFileExitsFolder, setflagFileExitsFolder] =
    useState<boolean>(false);
  const [totalData, setTotalData] = useState<any[]>([]);
  const [currentFolderInfo, setCurrentFolderInfo] =
    useState<DataFolder>(defaultFolder);
  const isSaveFreeFolder =
    currentFolderInfo.is_user_free_folder ||
    levelScreen.includes(FOLDER_KIND.SUBFOLDER);

  const hasCondition = useSelector(getHasFilterConditionProject("folder"));
  const projectFilter = useSelector(getConditionFilter("folder"));
  const typeSortSave = useSelector(getSortOfScreen("folder_save_free"));
  const typeSortNotSave = useSelector(getSortOfScreen("folder_not_save_free"));
  const history = useHistoryCustom();
  const dispatch = useDispatch();

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  useEffect(() => {
    handleClearData();
    getInfoScreen(false);
  }, [currentLevel, sortBy, searchedKeyword]);

  // 初期化
  const handleClearData = async () => {
    setDataFolderApi(defaultData);
  };

  // 階層ごとの表示
  const getInfoScreen = async (isMerge: boolean) => {
    switch (currentLevel) {
      case 1:
        setLevelScreen(folderLevel.level1.SK);
        setCurrentFolderInfo(folderLevel.level1);
        await getDataHandle(folderLevel.level1.SK, isMerge);
        break;
      case 2:
        setLevelScreen(folderLevel.level2.SK);
        setCurrentFolderInfo(folderLevel.level2);
        await getDataHandle(folderLevel.level2.SK, isMerge);
        break;
      default:
        setLevelScreen(folderLevel.level3.SK);
        setCurrentFolderInfo(folderLevel.level3);
        await getDataHandle(folderLevel.level3.SK, isMerge);
        break;
    }
  };

  //　階層ごとのデータ取得
  const getDataHandle = (folderID: string, isMerge: boolean) => {
    var sort_key = sortBy;
    var typeSort =
      folderID.includes(FOLDER_KIND.SAVEFREEFOLDER) ||
      folderID.includes(FOLDER_KIND.SUBFOLDER)
        ? typeSortSave
        : typeSortNotSave;
    if (!sort_key && !typeSort) {
      sort_key =
        folderID.includes(FOLDER_KIND.SAVEFREEFOLDER) ||
        folderID.includes(FOLDER_KIND.SUBFOLDER)
          ? sortHandle.file_created_at_folder_updated_at_new
          : sortHandle.created_at_new;
    } else if (typeSort) {
      sort_key = typeSort;
    }

    return fetchSearchList(
      folderID,
      lastKey,
      sort_key,
      searchedKeyword,
      isMerge,
    );
  };

  // データ取得
  const fetchSearchList = async (
    folderID: string = levelScreen,
    lastKeyword: string,
    sortKey?: string,
    keyword?: string,
    isMerge?: boolean,
  ) => {
    let params: paramGetDataOfFolder = {
      sort_key_folder: folderID ? folderID : getIdFolder(),
      keyword_file: keyword ? keyword : searchedKeyword,
      last_key: isMerge ? lastKeyword : "",
      flag_sort: sortKey ? sortKey : sortBy,
      filter_condition: paramsFilter,
    };

    if (isMerge) {
      setLoading(true);
    } else {
      LoadingOverlayController.show();
    }
    // console.log("params-------------------", params);
    return await getDataOfFolderApi(params)
      .then((res) => {
        // console.log("res-------------------", res);
        setflagFileExitsFolder(res?.flag_file_exits_folder);
        // console.log("lastKey-------------------", lastKey);
        if (lastKey && isMerge) {
          setDataFolderApi(res);
          const dataClone = JSON.parse(JSON.stringify(data));
          // console.log(res.list_files, "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
          dataClone.list_folders = dataClone.list_folders.concat(
            res.list_folders,
          );
          dataClone.list_files = dataClone.list_files.concat(res.list_files);
          setData(dataClone);
          setTotalData(dataClone.list_folders.concat(dataClone.list_files));
        } else {
          setDataFolderApi(res);
          setData(res);
          setTotalData(res.list_folders.concat(res.list_files));
        }

        // lastkey生成
        let convertLastKeyToString = "";
        let totalLength =
          res.list_folders.length + res.list_files.length + totalData.length;
        if (res.list_folders.length + res.list_files.length >= 30) {
          let SK = "";
          let PK = "";
          let other = "";
          SK = res?.LastEvaluatedKeyOldDate.SK
            ? res?.LastEvaluatedKeyOldDate.SK
            : "";
          PK = res?.LastEvaluatedKeyOldDate.PK
            ? res?.LastEvaluatedKeyOldDate.PK
            : "";
          if (res?.LastEvaluatedKeyOldDate.created_at) {
            other = res?.LastEvaluatedKeyOldDate.created_at;
          } else if (res?.LastEvaluatedKeyOldDate.updated_at) {
            other = res?.LastEvaluatedKeyOldDate.updated_at;
          } else if (res?.LastEvaluatedKeyOldDate.folder_name) {
            other = res?.LastEvaluatedKeyOldDate.folder_name;
          } else if (res?.LastEvaluatedKeyOldDate.file_name) {
            other = res?.LastEvaluatedKeyOldDate.file_name;
          }

          convertLastKeyToString = `${SK}&${other}&${PK}`;
          setLastKey(convertLastKeyToString);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setLoadMore(false);
        }

        if (totalLength === 0 && !convertLastKeyToString) {
          setLoadMore(false);
        }

        return;
      })
      .catch((e) => console.log(e))
      .finally(() => {
        if (isMerge) {
          setLoading(false);
        } else {
          LoadingOverlayController.hide();
        }
      });
  };

  const getIdFolder = () => {
    switch (currentLevel) {
      case 1:
        return folderLevel.level1.SK;
      case 2:
        return folderLevel.level2.SK;
      default:
        return folderLevel.level3.SK;
    }
  };

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && totalData.length > 0;
  }, [dataFolderApi, loadMore, lastKey]);

  useEffect(() => {
    paramsFilter = getParamsFilterV1(projectFilter);
    dispatch({ type: TYPES.GET_LIST_LOCATION });
  }, []);

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useEffect(() => {
    if (totalData.length === 0 && !hasCondition) {
      setIsDisableFilter(true);
    } else {
      setIsDisableFilter(false);
    }
  }, [totalData]);

  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy("");
    }
    if (lastKey) {
      setLastKey(null);
    }
    paramsFilter = getParamsFilterV1(projectFilter);
  }, [projectFilter]);

  // ------------------------------------------------------------------
  // 並べ替え
  // ------------------------------------------------------------------
  const handleSort = (type_sort: string) => {
    setSortBy(type_sort);
    var type_screen =
      currentFolderInfo.SK.includes(FOLDER_KIND.SAVEFREEFOLDER) ||
      currentFolderInfo.SK.includes(FOLDER_KIND.SUBFOLDER)
        ? "folder_save_free"
        : "folder_not_save_free";
    const payload = {
      type_screen: type_screen,
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
  };

  const sortDefaultIndex = useMemo(
    () => {
      const isSave =
        currentFolderInfo.SK.includes(FOLDER_KIND.SAVEFREEFOLDER) ||
        currentFolderInfo.SK.includes(FOLDER_KIND.SUBFOLDER)
          ? true
          : false;
      const sortDefault = isSave
        ? LIST_TYPE_SORT_FOLDER.SAVE_FREE
        : LIST_TYPE_SORT_FOLDER.NOT_SAVE_FREE;

      const typeSort = isSave ? typeSortSave : typeSortNotSave;
      const defaultSort = Object.values(sortDefault).filter(
        (item) => item.value === typeSort,
      );

      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(sortDefault).indexOf(defaultSort[0]);
      }
    },
    [currentFolderInfo] /* フォルダクリック時のみ判定 */,
  );

  const onPressList = () => {
    setEnableListView(!enableListView);
  };

  // ------------------------------------------------------------------
  // 資料選択
  // ------------------------------------------------------------------
  const handleOnClick = (folderClick: DataFolder) => {
    let folder;
    switch (currentLevel) {
      case 1:
        folder = {
          currentLevel: currentLevel + 1,
          folderID: { level2: folderClick },
        };
        dispatch({
          type: TYPES.SET_FOLDERID_LEVEL,
          payload: folder,
        });
        break;
      case 2:
        folder = {
          currentLevel: currentLevel + 1,
          folderID: { level3: folderClick },
        };
        dispatch({
          type: TYPES.SET_FOLDERID_LEVEL,
          payload: folder,
        });
        break;
      case 3:
        folder = {
          currentLevel: currentLevel + 1,
        };
        dispatch({
          type: TYPES.SET_FOLDERID_LEVEL,
          payload: folder,
        });
        break;

      default:
    }
  };

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (str: string) => {
    setSearchKeyword(str);
    setLastKey(null);
    setSortBy("");
    setLoadMore(true);
  };

  return (
    <GenericTemplate
      title={currentFolderInfo.folder_name}
      isSearch={true}
      searchPlaceholder="資料名で検索"
      onSearch={(v) => _handleSearch(v)}
      onSearchClear={() => _handleSearch("")}
    >
      <ButtonCreateScreenDialog
        folderInfo={levelScreen}
        isDisableCreateFolder={
          countOccurences(levelScreen, FOLDER_KIND.SUBFOLDER) > 1 ? true : false
        }
        open={open}
        setOpen={setOpen}
        getInfoScreen={getInfoScreen}
      />
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} md={12}>
          <Button
            sx={{
              ml: 1,
              mt: 0.5,
              maxWidth: 150,
              color: Colors.MAIN_GREEN,
              backgroundColor: "white",
              borderColor: Colors.MAIN_GREEN,
              border: 1,
              "&:hover": {
                backgroundColor: "lightgray",
              },
            }}
            onClick={() => onPressList()}
            disabled={!flagFileExitsFolder}
            endIcon={
              enableListView ? (
                <List style={{ marginLeft: 5 }} />
              ) : (
                <Apps style={{ marginLeft: 5 }} />
              )
            }
          >
            表示切替
          </Button>
        </Grid>
        <Grid item xs={12} md="auto">
          {/* =================== 並べ替え =================== */}
          <PopupSort
            data={Object.values(
              currentFolderInfo.SK.includes(FOLDER_KIND.SAVEFREEFOLDER) ||
                currentFolderInfo.SK.includes(FOLDER_KIND.SUBFOLDER)
                ? LIST_TYPE_SORT_FOLDER.SAVE_FREE
                : LIST_TYPE_SORT_FOLDER.NOT_SAVE_FREE,
            ).map((item) => {
              return {
                title: item.title,
                onClick: () => handleSort(item.value),
              };
            })}
            defaultIndex={sortDefaultIndex}
            disabled={!flagFileExitsFolder}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ width: { md: "40%" }, marginLeft: "auto" }}
        >
          {/* =================== 絞り込み =================== */}
          <FilterInput
            typeScreen="folder"
            disabled={isDisableFilter}
            isFolderReportAssign={
              levelScreen.includes("SAVEASSREP") ? true : false
            }
            onFetchData={() => {
              getDataHandle(levelScreen, false);
            }}
          />
        </Grid>
      </Grid>

      {/* =================== 資料 =================== */}
      <InfiniteScroll
        loadMore={() => {
          if (loadMore && lastKey && totalData.length > 0 && !loading) {
            getInfoScreen(true);
          }
        }}
        hasMore={showLoadMore}
        loader={<ScrollLoader key={0} />}
      >
        <Grid container spacing={3} sx={{ pb: 5 }}>
          {totalData.map((d: any, i) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={i}>
                <FolderFileItem
                  data={d}
                  onClick={handleOnClick}
                  isListDisplay={enableListView}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {totalData.length === 0 && !loading && (
        <Typography>
          {searchedKeyword === ""
            ? messages.COMMON.MSG_NOT_EXIST("資料")
            : messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)}
        </Typography>
      )}

      {/* =================== 案件作成ボタン =================== */}
      {isSaveFreeFolder && (
        <Button
          onClick={() => {
            setOpen(true);
          }}
          color="secondary"
          sx={{
            position: "fixed",
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 20,
            left: "auto",
          }}
          size="large"
          endIcon={<AddCircle />}
        >
          作成
        </Button>
      )}
    </GenericTemplate>
  );
};

export default ListFileScreen;
