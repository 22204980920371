import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Circle, Tune } from "@mui/icons-material";
import { Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import _, { cloneDeep, concat } from "lodash";
import { TYPES } from "store/types";
import { RootState } from "store/reducer";
import {
  ALL_LIST_TYPE_FILTER,
  LIST_TYPE_FILTER_SCREEN,
  USER_ROLES,
} from "@shared-constants";
import { getListCategory } from "@api/category";
import { getListUserProjectApi } from "@api/work";
import {
  getListLocationApi,
  getListReport,
  getListStatusProjectApi,
  getListUserCompanyApi,
  getProjectLocation,
} from "@api/project";
import { getListTypeFile } from "@api/manageFolder";
import { getUserInfo, updateFilterProject } from "@utils/index";
import ModalController from "@shared-components/modal/ModalController";
import AvatarRes from "components/atoms/AvatarRes";
import { getListActivityBase } from "@api/groupMaster";

interface IFilterInputProps {
  typeScreen: keyof typeof LIST_TYPE_FILTER_SCREEN;
  isFolderReportAssign?: boolean;
  disabled?: boolean;
  onFetchData?(sortItem: any): void;
}

interface IOptionsData {
  id: string | number;
  name: string;
  group: string;
  typeFilter: string;
}

const TAG_WIDTH = 100 as const;

const ignoreExtractRuleTargetTypes = [
  // 翻訳除外のフィルター値
  "author",
  "category",
  "status",
  "format",
  "location",
  "type_report",
  "project",
];

const add_data_attr = [ALL_LIST_TYPE_FILTER["拠点_all"]["type_filter"]];

const FilterInput = ({
  typeScreen,
  isFolderReportAssign,
  disabled,
  onFetchData = () => {},
}: IFilterInputProps) => {
  const [data, setData] = useState<IOptionsData[]>([]);
  const [selectItem, setSelectItem] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [limitTags, setLimitTags] = useState(-1);
  let refData: IOptionsData[] = [];
  const { selected_project_id } = useSelector(
    (state: RootState) => state.projectInfo,
  );
  const filter_condition = useSelector((state: RootState) => state.filter);
  const dispatch = useDispatch();
  const loading = open && data.length === 0;
  const userProfile = getUserInfo();
  let ref: any;

  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  useEffect(() => {
    let initialFilter: any[] = [];
    const filter_current = filter_condition[typeScreen];
    if (filter_current) {
    Object.values(filter_current).forEach((data: any, index) => {
      if (data) {
        if (
          Object.keys(filter_current)[index] ===
          ALL_LIST_TYPE_FILTER["期限"].type_filter
        ) {
          initialFilter.push(data);
        } else {
          initialFilter.push(...data);
        }
      }
    });
    }
    setSelectItem(initialFilter);
  }, []);

  // ------------------------------------------------------------------
  // 条件取得
  // ------------------------------------------------------------------
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        await getFilterData();
        setData(refData);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const getFilterData = async () => {
    refData = [];
    const listFilter = cloneDeep(LIST_TYPE_FILTER_SCREEN[typeScreen]);
    for (let i = 0; i < listFilter.length; i++) {
      const item = listFilter[i];
      if (item.enableRole && !item.enableRole.includes(userProfile.user_role)) {
        // ログインアカウントの権限が表示対象外のとき何もしない
        continue;
      }
      if (item.data) {
        // 絞り込み条件が固定値
        const formattedData = item.data.map((d: any) => {
          return { ...d, group: item.title, typeFilter: item.type_filter };
        });
        handleChangeData(formattedData);
      } else {
        // 絞り込み条件を取得する
        await getDataFromApi(item.type_filter, item.title);
      }
    }
  };

  const getDataFromApi = async (typeFilter: string, title: string) => {
    try {
      //  setIsLoading(true);
      let api: any;
      let params;
      if (typeFilter === "author" && title !== "報告者") {
        api = getListUserProjectApi;
        params = {
          keyword: "",
          ignore_me: false,
          project_id: selected_project_id,
        };
      }
      if (typeFilter === "author" && title === "報告者") {
        api = getListUserCompanyApi;
        params = {
          keyword: "",
          location_id: userProfile?.location_id ?? "",
          ignore_me: false,
        };
      }
      if (typeFilter === "author_all") {
        api = getListUserCompanyApi;
        params = {
          keyword: "",
          location_id: "",
          ignore_me: false,
        };
      }
      if (typeFilter === "category") {
        api = getListCategory;
      }
      if (typeFilter === "status") {
        api = getListStatusProjectApi;
      }
      if (typeFilter === "format") {
        api = getListTypeFile;
      }
      // --->> TLOG
      if (typeFilter === "location") {
        api = getListLocationApi;
        // params = userProfile?.PK ?? "";
      }
      if (typeFilter === "location_all") {
        api = getListActivityBase;
      }
      if (typeFilter === "report") {
        api = getListReport;
      }
      if (typeFilter === "project") {
        api = getProjectLocation;
      }
      // <<--- TLOG
      if (api) {
        let res = await api(params);
        if (add_data_attr.includes(typeFilter)) {
          res["data"] = [...res];
        }
        if (res.data) {
          let callback;
          if (typeFilter === "author") {
            callback = (user: any) => {
              return {
                ...user,
                id: user.SK,
                name: user.full_name,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          if (typeFilter === "author_all") {
            callback = (user: any) => {
              return {
                ...user,
                id: user.SK,
                name: user.full_name,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          if (typeFilter === "category") {
            callback = (category: any) => {
              return {
                ...category,
                id: category.SK,
                name: category.category_name,
                color: category?.category_color,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          if (typeFilter === "status") {
            callback = (status: any) => {
              return {
                ...status,
                id: status.SK,
                name: status.status_name,
                color: status?.status_color,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          // --->> TLOG
          if (typeFilter === "location") {
            callback = (location: any) => {
              return {
                ...location,
                id: location.SK,
                name: location.activity_base_name,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          if (typeFilter === "location_all") {
            callback = (location: any) => {
              return {
                ...location,
                id: location.SK,
                name: location.activity_base_name,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          if (typeFilter === "report") {
            callback = (type_report: any) => {
              return {
                ...type_report,
                id: type_report.SK,
                name:
                  type_report.report_type_code +
                  type_report.report_number +
                  ":" +
                  type_report.report_name,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          if (typeFilter === "project") {
            callback = (project: any) => {
              return {
                ...project,
                id: project.SK,
                name: project.project_name,
                group: title,
                typeFilter: typeFilter,
              };
            };
          }
          // <<--- TLOG
          let formattedData = [];
          if (typeFilter === "category") {
            formattedData.push({
              id: "CATEGORY#",
              name: "未選択",
              color: "white",
              group: title,
              typeFilter: typeFilter,
            });
          }
          formattedData = concat(formattedData, res.data.map(callback));
          handleChangeData(formattedData);
        }
        if (typeFilter === "format") {
          let callback = (type: string) => {
            return {
              id: type,
              name: type,
              group: title,
              typeFilter: typeFilter,
            };
          };
          let formattedData = res.map(callback);
          handleChangeData(formattedData);
        }
      }
      // --->> TLOG
      if (typeFilter === "type_report") {
        let formattedData = [
          {
            id: "",
            name: "未実装",
            group: title,
            typeFilter: typeFilter,
          },
        ];
        handleChangeData(formattedData);
      }
      // <<--- TLOG
    } catch (error: any) {
      ModalController.show({ message: error?.details, visibleButton2: true });
    }
  };

  const handleChangeData = (newData: IOptionsData[]) => {
    const tmpData = [...refData, ...newData];

    if (!isFolderReportAssign) {
      const folderFilter = tmpData.filter(
        (item: any) => item.typeFilter !== "documents",
      );
      refData = folderFilter;
      return;
    }
    refData = tmpData;
  };

  // ------------------------------------------------------------------
  // 選択
  // ------------------------------------------------------------------
  const handleChange = (
    event: any,
    value: any[],
    reason: AutocompleteChangeReason,
    details?: any,
  ) => {
    if (reason === "selectOption") {
      const newValue: IOptionsData = value.at(-1);
      if (
        newValue &&
        (newValue.typeFilter === ALL_LIST_TYPE_FILTER["期限"].type_filter ||
          newValue.typeFilter === ALL_LIST_TYPE_FILTER["印刷状態"].type_filter)
      ) {
        // 単一選択
        const selected = value.filter(
          (d: any) => d.typeFilter === newValue.typeFilter,
        );
        if (selected.length > 1) {
          value.splice(value.indexOf(selected[0]), 1);
        }
      }
    } else if (reason === "clear") {
      (document.activeElement as HTMLElement).blur();
      handleConfirmFilter(value);
    }

    setSelectItem(value);
  };

  // ------------------------------------------------------------------
  // レイアウト
  // ------------------------------------------------------------------
  const getElementProperty = useCallback(
    (targetProperty: keyof DOMRect): number => {
      const clientRect = ref.getBoundingClientRect();
      if (clientRect) {
        return clientRect[targetProperty];
      }
      return 0;
    },
    [ref],
  );

  useEffect(() => {
    if (ref) {
      setLimitTags(Math.floor(getElementProperty("width") / (TAG_WIDTH + 6)));
    }
  }, [ref, getElementProperty]);

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  const handleConfirmFilter = (filter?: any) => {
    if (!filter) filter = selectItem;

    const groups = _(filter).groupBy("typeFilter").value();
    const listFilter = cloneDeep(LIST_TYPE_FILTER_SCREEN[typeScreen]);

    let state: any = cloneDeep(filter_condition);
    for (let i = 0; i < listFilter.length; i++) {
      const item = listFilter[i];
      const group = groups[item.type_filter];

      let values: any[];
      if (!group || group.length === 0) {
        values = [];
      } else if (
        item.type_filter === ALL_LIST_TYPE_FILTER["期限"].type_filter
      ) {
        values = group[0];
      } else {
        values = [...group];
      }

      state = updateFilterProject(state, {
        type: item.type_filter,
        values,
        typeScreen,
      });
    }

    // 条件を保存
    dispatch({ type: TYPES.SET_ALL_FILTER_CONDITION, payload: state });
    dispatch({ type: TYPES.SET_FILTER_CONDITION });

    setTimeout(() => {
      onFetchData(filter);
    }, 10);
  };

  const getIsIgnoreExtractRuleTarget = useCallback(
    (typeFilter: string) => {
      return (
        ignoreExtractRuleTargetTypes.findIndex((d) => d === typeFilter) >= 0
      );
    },
    [ignoreExtractRuleTargetTypes],
  );

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Autocomplete
        multiple
        limitTags={limitTags}
        disableCloseOnSelect
        options={data as readonly any[]}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <React.Fragment key={index}>
              <span
                className={
                  getIsIgnoreExtractRuleTarget(option.typeFilter)
                    ? "IgnoreExtractRuleTarget"
                    : ""
                }
              >
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  sx={{ width: TAG_WIDTH }}
                />
              </span>
            </React.Fragment>
          ))
        }
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.name}
        renderOption={(props: any, option: any) => (
          <li {...props} key={option.id}>
            {option.hasOwnProperty("avatar") && (
              <AvatarRes src={option?.avatar} size={"sm"} sx={{ mr: 1 }} />
            )}
            {option.hasOwnProperty("status_color") && (
              <Circle
                sx={{ color: option.status_color, mr: 1 }}
                fontSize="small"
              />
            )}
            {!getIsIgnoreExtractRuleTarget(option.typeFilter) ? (
              <div>{option.name}</div>
            ) : (
              <div className="IgnoreExtractRuleTarget">{option.name}</div>
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder={selectItem.length === 0 ? "条件を選択" : ""}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            inputRef={(input) => (ref = input)}
          />
        )}
        onChange={handleChange}
        defaultValue={selectItem}
        value={selectItem}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        readOnly={disabled}
        disabled={disabled}
        isOptionEqualToValue={(option, value) =>
          value === undefined || value === "" || option.id === value.id
        }
        fullWidth
        sx={{
          flexGrow: 1,
          minHeight: "39px",
          display: "flex",
          alignItems: "flex-end",
        }}
      />
      <Button
        size="medium"
        sx={{ ml: 1, alignSelf: "center", minWidth: 120 }}
        endIcon={<Tune />}
        onClick={() => handleConfirmFilter()}
        disabled={disabled}
      >
        絞り込み
      </Button>
    </Box>
  );
};

export default FilterInput;
