import React from "react";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import { TemplateExcelDataInfo } from "services/models";
import LabelRequired from "components/atoms/LabelRequired";
import formatDateToString from "@utils/DateFormat";

interface ITimeInputField {
  setting: TemplateExcelDataInfo;
  value?: string;
  onChange?: (text: string, keyboardInputValue?: string) => void;
  onBlur?: () => void;
  error?: boolean;
  helperText?: string;
}

const TimeInputField = ({
  setting,
  value,
  onChange = () => {},
  onBlur = () => {},
  error,
  helperText,
}: ITimeInputField) => {
  const [time, setTime] = React.useState<Date | null>(null);

  React.useEffect(() => {
    if (value && !time) {
      // デフォルト値をtimeに設定
      setTime(new Date(formatDateToString(new Date(), "YMD") + "T" + value));
    }
  }, [value]);

  return (
    <TimePickerCustom
      label={
        setting.required ? <LabelRequired title={setting.name} /> : setting.name
      }
      onChange={(v, keyboardInputValue) => {
        let time_string = "";
        if (!Number.isNaN(v?.getTime())) {
          time_string = formatDateToString(v, "HHmm");
        }
        onChange(
          time_string,
          time_string !== "" ? time_string : keyboardInputValue,
        );
        setTime(v);
      }}
      value={time}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
    />
  );
};

export default TimeInputField;
