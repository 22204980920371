import { User } from "services/models";
import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";

interface sortData {
  PK: String;
  SK: String;
  sort: Number;
  user: User;
}

interface IDataUpdateWreckerMaster {
  wrecker_name: string;
}

type pramGetList = {
  wrecker_kind: string;
};

export const getListWrecker = (params: pramGetList) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/wrecker?${getParamsHelp(params)}`,
    null,
  );
};

export const updateWreckerSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/wrecker/sort`,
    dataBody,
  );
};

export const deleteWrecker = (wrecker_id: string, wrecker_kind: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/wrecker/${encodeURIComponent(
      wrecker_id,
    )}/${encodeURIComponent(wrecker_kind)}`,
    null,
  );
};

export const createWrecker = (dataBody: IDataUpdateWreckerMaster,  wrecker_kind: string) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/wrecker/${encodeURIComponent(wrecker_kind)}`,
    dataBody,
  );
};

export const updateWrecker = (dataBody: IDataUpdateWreckerMaster, SK: string) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/wrecker/${encodeURIComponent(SK)}`,
    dataBody,
  );
}

export const createWreckerStore = (dataBody: IDataUpdateWreckerMaster,  wrecker_kind: string) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/wrecker-store/${encodeURIComponent(wrecker_kind)}`,
    dataBody,
  );
};

export const updateWreckerStore = (dataBody: IDataUpdateWreckerMaster, SK: string) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/wrecker-store/${encodeURIComponent(SK)}`,
    dataBody,
  );
}
