import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";

interface IProps {
  open: boolean;
  text: string;
  onChange: (value: string) => void;
  onClose: () => void;
}

const TextEditDialog = ({ open, text, onChange, onClose }: IProps) => {
  const [textValue, setTextValue] = useState(text);

  const handleSave = () => {
    onChange(textValue);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setTextValue(text);
    }
  }, [open, text]);

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "50%" } }}
      open={open}
    >
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            label="テキスト入力"
            value={textValue}
            onChange={(e) => {
              setTextValue(e.target.value);
            }}
            multiline
            rows={2}
          />
        </FormControl>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <Stack direction="row">
            <Button variant="outlined" onClick={onClose}>
              キャンセル
            </Button>
            <Button color="secondary" onClick={handleSave}>
              保存
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TextEditDialog;
