import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";

export interface IData {
  group_name: string;
}

interface IProps {
  data: IData;
  open: boolean;
  setOpen: Function;
  handleButtonOK: Function;
}

const GroupEditDialog: VFC<IProps> = ({
  data,
  open,
  setOpen,
  handleButtonOK,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [errorText, setErrorText] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const setInitialData = async () => {
      setName(data.group_name);
      setErrorText("");
      setDisabled(true);
    };
    if (open) {
      setInitialData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: "グループ名",
      value: name,
      required: true,
      max_length: 20,
    });
    setErrorText(error_text);
    setDisabled(error_text.length > 0 || name === data.group_name);
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <TextField
              label={
                <>
                  <LabelRequired title={"グループ名"} />
                </>
              }
              name="master_name"
              value={name}
              onChange={changeName}
              onBlur={validName}
              error={errorText.length > 0}
              helperText={errorText}
              inputProps={{
                maxLength: 20,
              }}
            />
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const response: IData = {
              group_name: name,
            };
            handleButtonOK(response);
            setOpen(false);
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupEditDialog;
