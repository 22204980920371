import { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  CardContent,
  IconButton,
  Stack,
} from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import dayjs from "dayjs";
import { Colors } from "@template/style";
import formatDateToString from "@utils/DateFormat";
import { Project } from "services/models";
import { getIdProject } from "@utils/index";

interface IProps {
  data: Project;
  handleOnClick: (data: Project) => void;
  listGroup: Array<any>;
}
const ConstructionItem: React.FC<IProps> = ({
  data,
  handleOnClick,
  listGroup,
}: IProps) => {
  const endTime = data.project_end_date ? new Date(data.project_end_date) : "";
  const startTime = data.project_start_date
    ? new Date(data.project_start_date)
    : "";
  return (
    <Card
      sx={{ position: "relative", cursor: "pointer" }}
      onClick={() => {
        handleOnClick(data);
      }}
    >
      <CardContent sx={{ ":last-child": { pb: 2 } }}>
        <Stack spacing={1}>
          {/* =================== 帳票名 =================== */}
          <span className="IgnoreExtractRuleTarget">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography display="inline" noWrap sx={{ mr: 1 }}>
                {data.project_name}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
            </Box>
          </span>
          {/* =================== 拠点 =================== */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              display="inline"
              sx={{ color: Colors.DISABLE_BTN_TEXT }}
            >
              帳票番号
            </Typography>
            <Typography display="inline" sx={{ pl: 2, flex: 1 }}>
              <span className="IgnoreExtractRuleTarget">
                {data.report_type_code}
                {data.report_number}:{data.report_name}
              </span>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              display="inline"
              sx={{ color: Colors.DISABLE_BTN_TEXT }}
            >
              拠点
            </Typography>
            <Typography display="inline" sx={{ pl: 6, flex: 1 }}>
              <span className="IgnoreExtractRuleTarget">
                {listGroup.find((item) => {
                  return item?.SK === data?.project_location_id;
                })?.activity_base_name ?? ""}
              </span>
            </Typography>
          </Box>
          {startTime || endTime ? (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                display="inline"
                sx={{ color: Colors.DISABLE_BTN_TEXT }}
              >
                期間
              </Typography>
              <Typography display="inline" sx={{ pl: 6, flex: 1 }}>
                <span className="IgnoreExtractRuleTarget">
                  {`${formatDateToString(startTime, "YMD_sl")}${
                    (startTime || endTime) && " ~ "
                  }${formatDateToString(endTime, "YMD_sl")}`}
                </span>
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ConstructionItem;
