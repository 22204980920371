import {
  Card,
  Typography,
  Box,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import { Folder, InsertDriveFile, ImportContacts } from "@mui/icons-material";
import { Colors } from "@template/style";
import formatDateToString from "@utils/DateFormat";

interface FolderFileItemProps {
  data?: any;
  onClick?: (data: any) => void;
  isListDisplay?: boolean;
}

const FolderFileItem = ({
  data,
  onClick = () => {},
  isListDisplay,
}: FolderFileItemProps) => {
  return (
    <Card
      sx={{ cursor: "pointer" }}
      onClick={() => {
        // ファイル表示は未実装のため
        if (data.folder_name) {
          onClick(data);
        }
      }}
    >
      {data.folder_name ? (
        // フォルダ表示
        isListDisplay ? (
          <CardContent sx={{ p: 1, minHeight: 90 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 1,
                height: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Folder />
              </Box>
              <Box sx={{ pl: 2 }}>
                <Typography sx={{ wordBreak: "break-all" }}>
                  {data.folder_name}
                </Typography>
                <Typography sx={{ color: Colors.DISABLE_BTN_TEXT }}>
                  {formatDateToString(data.updated_at, "YMD_jp")}
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1 }} />

              <Typography>{data.total_inside}</Typography>
            </Box>
          </CardContent>
        ) : (
          // 画像切替表示
          <CardContent sx={{ ":last-child": { pb: 1 }, minHeight: 220 }}>
            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  sx={{ display: "grid", placeContent: "center", height: 170 }}
                >
                  <Folder sx={{ fontSize: "3em" }} />
                </Grid>
                <Typography
                  sx={{
                    color: Colors.MAIN_GREEN,
                    fontSize: 18,
                    wordBreak: "break-all",
                    p: 2,
                  }}
                >
                  {data.folder_name}
                </Typography>
              </Grid>
            </Box>
          </CardContent>
        )
      ) : // ファイル表示
      isListDisplay ? (
        // リスト表示
        <CardContent sx={{ ":last-child": { pb: 1 }, p: 1, minHeight: 90 }}>
          <Box sx={{ pl: 2 }}>
            <Grid container spacing={1}>
              <Grid item md={1} xs={1}>
                {data.file_type.includes("image") ? (
                  <InsertDriveFile sx={{ color: Colors.MAIN_GREEN }} />
                ) : (
                  <ImportContacts sx={{ color: Colors.DANGER }} />
                )}
              </Grid>
              <Grid item md={11} xs={11}>
                <Typography
                  sx={{
                    color: Colors.MAIN_GREEN,
                    fontSize: 18,
                    wordBreak: "break-all",
                  }}
                >
                  {data.file_name_filter}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              sx={{
                color: "white",
                backgroundColor: data.category_id.category_color,
                borderRadius: 1,
                maxWidth: 120,
                textAlign: "center",
                marginBottom: "5px",
                p: "5px",
              }}
              noWrap
            >
              {data.category_id.category_name}
            </Typography>
            <Typography sx={{ color: Colors.DISABLE_BTN_TEXT }}>
              {formatDateToString(data.created_at, "YMD_jp")}
            </Typography>
          </Box>
        </CardContent>
      ) : (
        // 画像切替表示
        <div>
          {data.file_type.includes("image") ? (
            <>
              <CardMedia
                component="img"
                image={data.path_file_thumb}
                alt={data.file_name_filter}
                height="170"
                sx={{
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <CardContent sx={{ minHeight: 20 }}>
                <Typography
                  sx={{
                    color: Colors.MAIN_GREEN,
                    fontSize: 18,
                    wordBreak: "break-all",
                  }}
                >
                  {data.file_name_filter}
                </Typography>
              </CardContent>
            </>
          ) : (
            <CardContent
              sx={{ ":last-child": { pb: 1 }, p: 0, minHeight: 220 }}
            >
              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    sx={{
                      display: "grid",
                      placeContent: "center",
                      height: 170,
                    }}
                  >
                    <ImportContacts
                      sx={{ fontSize: "3em", color: Colors.DANGER }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography
                      sx={{
                        color: Colors.MAIN_GREEN,
                        fontSize: 18,
                        wordBreak: "break-all",
                        p: 2,
                      }}
                    >
                      {data.file_name_filter}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          )}
        </div>
      )}
    </Card>
  );
};

export default FolderFileItem;
