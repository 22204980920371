import { useEffect, useRef } from "react";
import { Ellipse, Transformer } from "react-konva";
import { DrawItem } from "services/models";
import { itemWidth } from "@shared-constants";

interface IProps {
  data: DrawItem;
  onClick: () => void;
  selected: boolean;
  onChange: (v: any) => void;
}

const MIN_SIZE = 25 as const;

/**
 * 円形
 */
const DrawEllipse = ({ data, onClick, selected, onChange }: IProps) => {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();

  const attachTransformer = () => {
    trRef.current.nodes([shapeRef.current]);
    trRef.current.getLayer().batchDraw();
  };

  function handleResize() {
    if (shapeRef.current !== null) {
      const node = shapeRef.current;
      const newWidth = node.width() * node.scaleX();
      const newHeight = node.height() * node.scaleY();

      node.setAttrs({
        x: node.x(),
        y: node.y(),
        width: newWidth,
        height: newHeight,
        scaleX: 1,
        scaleY: 1,
      });
    }
  }

  const updateDrawItem = () => {
    const node = shapeRef.current;
    onChange({
      x: node.x(),
      y: node.y(),
      width: node.width(),
      height: node.height(),
    });
  };

  useEffect(() => {
    if (selected && trRef.current) {
      attachTransformer();
    }
  }, [selected]);

  return (
    <>
      <Ellipse
        ref={shapeRef}
        x={data.data?.x ?? 50}
        y={data.data?.y ?? 50}
        width={data.data?.width ?? 100}
        height={data.data?.height ?? 100}
        radiusX={(data.data?.width ?? 100) / 2}
        radiusY={(data.data?.height ?? 100) / 2}
        stroke={data.colorMode}
        strokeWidth={itemWidth[data.strokeWidthMode]}
        onClick={onClick}
        draggable
        onTransform={handleResize}
        onTransformEnd={() => {
          updateDrawItem();
          attachTransformer();
        }}
        onDragStart={selected ? undefined : onClick}
        onDragEnd={updateDrawItem}
      />
      {selected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            newBox.width = Math.max(MIN_SIZE, newBox.width);
            newBox.height = Math.max(MIN_SIZE, newBox.height);
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default DrawEllipse;
