import { put } from "redux-saga/effects";
import { getListWrecker } from "@api/wrecker";
import { listMasterItem } from "screens/EditTemplate/TemplateYuasa/fakeData";
import { TYPES } from "store/types";

export function* setMasterYuasaSage(): any {
  try {
    const reducerYuasa: any = {};

    for (const item of listMasterItem) {
      const resData = yield getListWrecker({
        wrecker_kind: item?.type,
      });

      if (resData?.data && resData?.data.length > 0) {
        if (item?.reducerKey) {
          reducerYuasa[item?.reducerKey] = resData?.data;
        }
      }
    }

    yield put({
      type: TYPES.SET_MASTER_ITEM_YUASA,
      payload: { data: reducerYuasa },
    });
  } catch (error) {
    console.log(error);
  }
}
