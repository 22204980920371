import CryptoJS from "crypto-js";

const SECRETKEY = "SecretKeyDattWebV2";
const IV = CryptoJS.enc.Utf8.parse("0123456789abcede");

const Encrypt = (value: string) => {
  if (value === null || value === undefined) return "";
  return CryptoJS.AES.encrypt(value, SECRETKEY, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

const Decrypt = (value: string) => {
  if (value === null || value === undefined) return "";
  return CryptoJS.AES.decrypt(value, SECRETKEY, {
    iv: IV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
};

const encryptor = { Encrypt, Decrypt };

export default encryptor;
