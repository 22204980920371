import React from "react";
import { MenuItem } from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";

interface ISelectLabelYuasa {
  label: React.ReactNode;
  value: any;
  data: Array<any>;
  onChange?: (value: any) => void;
  valueFieldName?: string;
}

const SelectLabelYuasa: React.FC<ISelectLabelYuasa> = ({
  label,
  value,
  data,
  onChange = () => {},
  valueFieldName = "wrecker_name",
}) => {
  return (
    <SelectLabel
      label={label}
      value={value || ""}
      onChange={(e) => {
        if (e.target.value == "") {
          onChange(undefined);
        } else {
          const selected = data.find(
            (v) => v.SK.toString() === e.target.value.toString(),
          );
          onChange(selected);
        }
      }}
    >
      <MenuItem value={""}>
        <em>未選択</em>
      </MenuItem>
      {data.map((v, i) => (
        <MenuItem key={i} value={v.SK}>
          {v[valueFieldName]}
        </MenuItem>
      ))}
    </SelectLabel>
  );
};

export default SelectLabelYuasa;
