import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { TemplateExcelDataInfo } from "services/models";
import { labeltextBreak } from "@utils/index";
import { ICheckboxValue } from "@utils/template/excelTemplate";

interface ICheckboxInputField {
  setting: TemplateExcelDataInfo;
  value: ICheckboxValue;
  onChange: (value: ICheckboxValue) => void;
}

const CheckboxInputField = ({
  setting,
  value,
  onChange,
}: ICheckboxInputField) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(value?.checked ?? false);
  }, [value]);

  return (
    <FormControl>
      <CheckboxLabel
        label={
          setting.required ? (
            <LabelRequired title={labeltextBreak(setting.name)} />
          ) : (
            labeltextBreak(setting.name)
          )
        }
        checked={checked}
        onChange={(_, checked) => {
          const check_txt = checked
            ? setting.checkbox?.checked_text
            : setting.checkbox?.unchecked_text;
          const new_value: ICheckboxValue = {
            checked: checked,
            value: check_txt ?? "",
          };
          onChange(new_value);
        }}
      />
    </FormControl>
  );
};

export default CheckboxInputField;
