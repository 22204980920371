import React, { VFC, useState } from "react";
import { Button, Dialog, DialogContent, Typography, Grid } from "@mui/material";

import ModalController from "@shared-components/modal/ModalController";
import { createFolderApi } from "@api/manageFolder";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import messages from "config/messages";
import FolderEditDialog from "./FolderEditDialog";

export interface IData {
  type: string;
  name: string;
  color: string;
  color_id: string;
  color_name: string;
}

interface IProps {
  folderInfo: string;
  isDisableCreateFolder: boolean;
  open: boolean;
  setOpen: Function;
  getInfoScreen: Function;
}

const ButtonCreateScreenDialog: VFC<IProps> = ({
  folderInfo,
  isDisableCreateFolder,
  open,
  setOpen,
  getInfoScreen,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [openFolderEdit, setOpenFolderEdit] = useState(false);

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "70%" } }}
      open={open}
    >
      <FolderEditDialog
        folderInfo={folderInfo}
        openFolderEdit={openFolderEdit}
        setOpenFolderEdit={setOpenFolderEdit}
        getInfoScreen={getInfoScreen}
      />

      <DialogContent style={{ textAlign: "center" }}>
        <Grid container spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="body1"
              sx={{ pb: 2, pl: 3, textAlign: "left" }}
            >
              何を追加・作成しますか？
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              onClick={() => {}}
              color="primary"
              sx={{ width: "90%", marginVertical: 10 }}
            >
              画像・動画の追加
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              onClick={() => {}}
              color="success"
              sx={{ width: "90%", marginVertical: 10 }}
            >
              資料の追加
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              onClick={() => {}}
              color="success"
              sx={{ width: "90%", marginVertical: 10 }}
            >
              資料を作成して追加
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              color="secondary"
              sx={{ width: "90%", marginVertical: 10 }}
              onClick={() => {
                if (isDisableCreateFolder) {
                  if (!ModalController.isShowing())
                    ModalController.show({
                      message:
                        messages.ManageFolder.MSG_CAN_NOT_CREATE_MORE_FOLDER,
                      visibleButton2: true,
                    });
                } else {
                  setOpenFolderEdit(true);
                }
              }}
            >
              フォルダを追加
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="outlined"
              sx={{ width: "90%", marginVertical: 10 }}
            >
              キャンセル
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ButtonCreateScreenDialog;
