import { CheckUserSignUpAPITlog } from "@api/auth";
import messages from "config/messages";

export type ValidateType =
  | "text"
  | "number"
  | "alpha_num"
  | "date"
  | "time"
  | "email"
  | "password"
  | "alpha"
  | "login_id";

export interface IValidationProps {
  type: ValidateType;
  value: any;
  name: string;
  required?: boolean;
  max_value?: number;
  min_value?: number;
  max_length?: number;
  min_length?: number;
  existing?: any[];
}

// 最小桁数
const isMinLength = (value: string, min_length: number): boolean => {
  return value.length >= min_length;
};

// 最大桁数
const isMaxLength = (value: string, max_length: number): boolean => {
  return value.length <= max_length;
};

// 数値
const isNumber = (value: string): boolean => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

// 半角英数
const isAlphaNum = (value: string): boolean => {
  const regex = /^[a-zA-Z0-9]+$/;
  return regex.test(value);
};

// 半角英字のみ
const isAlpha = (value: string): boolean => {
  const regex = /^[a-zA-Z]+$/;
  return regex.test(value);
};

// 半角のみ
export const isHalfWidthCharacter = (char: string): boolean => {
  let code = char.charCodeAt(0);
  if (
    (code >= 0x0020 && code <= 0x1fff) ||
    (code >= 0xff61 && code <= 0xff9f)
  ) {
    return true;
  } else return false;
};

// 日付
const isDate = (value: Date | string): boolean => {
  const date = new Date(value);
  return !Number.isNaN(date.getTime());
};

// 時刻
const isTime = (value: string): boolean => {
  return value.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/) !== null;
};

// メールアドレス
const isEmail = (value: string): boolean => {
  const regex =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
  return regex.test(value);
};

// パスワード(半角英数記号)
const isPassword = (value: string): boolean => {
  // 使用可能記号
  //! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _ ` { | } ~
  const regex = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@\\[\]^_`{|}~]*$/;
  return regex.test(value);
};

// ログインID(半角英数記号)
const isLoginId = (value: string): boolean => {
  // 使用可能記号
  //! " # $ % & ' ( ) * + , - . / : ; < = > ? [ \ ] ^ _ ` { | } ~
  const regex = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?\\[\]^_`{|}~]*$/;
  return regex.test(value);
};

export const checkUserSignUpTlog = async (
  value: string,
  location_code: string,
  contract_login_id: string,
): Promise<string> => {
  const dataCheckUser = {
    preferred_username: value,
    location_code: location_code,
    contract_login_id: contract_login_id,
  };
  return await CheckUserSignUpAPITlog(dataCheckUser)
    .then((res) => {
      const resData = JSON.parse(JSON.stringify(res.data));
      return resData.exists ? messages.COMMON.ERROR.MSG_LOGIN_ID_EXISTS : "";
    })
    .catch((error) => {
      console.log("CheckUserSignUpAPITlog", error);
      if (error.toJSON().message === "Network Error") {
        return messages.COMMON.NETWORK_ERROR;
      }
      return messages.COMMON.MSG_COMMON_ERROR_001;
    });
};

export namespace Validation {
  /**
   * password validator
   */
  export const validatePassword = (
    value: string,
    name: string = "パスワード",
    required: boolean = true,
  ) => {
    const props: IValidationProps = {
      type: "password",
      value: value,
      name: name,
      required: required,
      min_length: 6,
      max_length: 30,
    };
    return validate(props);
  };

  export const validateLoginIDTlog = async (
    value: string,
    old_value: string,
    location_code: string,
    old_location_code: string,
    name: string = "ログインID",
    required: boolean = true,
    contract_login_id: string,
  ) => {
    const props: IValidationProps = {
      type: "login_id",
      value: value,
      name: name,
      required: required,
      min_length: 6,
      max_length: 30,
    };
    const response = validate(props);

    if (
      response.length === 0 &&
      (value !== old_value || location_code !== old_location_code)
    ) {
      const id_error_message = await checkUserSignUpTlog(
        value,
        location_code,
        contract_login_id,
      );
      if (id_error_message.length > 0) return id_error_message;
    }

    return response;
  };

  /**
   * email validator
   */
  export const validateEmail = (
    value: string,
    name: string = "メールアドレス",
    required: boolean = true,
  ) => {
    const props: IValidationProps = {
      type: "email",
      value: value,
      name: name,
      required: required,
      max_length: 256,
    };
    return validate(props);
  };

  /**
   * phone number validator
   */
  export const validatePhone = (
    value: string,
    name: string = "電話番号",
    required: boolean = true,
  ) => {
    const props: IValidationProps = {
      type: "number",
      value: value,
      name: name,
      required: required,
      min_length: 10,
      max_length: 11,
    };
    return validate(props);
  };

  /**
   * date validator
   */
  export const validateDate = (
    value: Date | string,
    name: string = "日付",
    required: boolean = true,
  ) => {
    const props: IValidationProps = {
      type: "date",
      value: value,
      name: name,
      required: required,
    };
    return validate(props);
  };

  /**
   * date range validator
   */
  export const validateDateRange = (from: Date, to: Date) => {
    if (from > to) {
      return messages.COMMON.ERROR.MSG_DATE_RANGE;
    } else {
      return "";
    }
  };

  /**
   * validator
   */
  export const validate = ({
    type,
    value,
    name,
    required,
    max_value,
    min_value,
    max_length,
    min_length,
    existing,
  }: IValidationProps) => {
    if (value === null || value === undefined) value = "";

    // required
    if (value.length === 0) {
      if (required) {
        return messages.COMMON.ERROR.MSG_RQUIRED(name);
      } else {
        return "";
      }
    }

    // length
    if (min_length) {
      if (!isMinLength(value, min_length)) {
        return messages.COMMON.ERROR.MSG_MIN_LENGTH(name, min_length);
      }
    }
    if (max_length) {
      if (!isMaxLength(value, max_length)) {
        return messages.COMMON.ERROR.MSG_MAX_LENGTH(name, max_length);
      }
    }

    //existing
    if (
      existing !== undefined &&
      existing.findIndex((d: any) => d === value) >= 0
    ) {
      return messages.COMMON.ERROR.MSG_EXISTING(name);
    }

    // type
    switch (type) {
      case "number":
        if (!isNumber(value)) return messages.COMMON.ERROR.MSG_NUM(name);

        // value range
        if (min_value) {
          if (Number(value) < min_value) {
            return name + "は" + min_value + "以上で入力してください";
          }
        }
        if (max_value) {
          if (Number(value) > max_value) {
            return name + "は" + max_value + "以内で入力してください";
          }
        }
        break;

      case "alpha_num":
        if (!isAlphaNum(value))
          return messages.COMMON.ERROR.MSG_ALPHA_NUM(name);
        break;

      case "date":
        if (!isDate(value)) return messages.COMMON.ERROR.MSG_DATE(name);
        break;

      case "time":
        if (!isTime(value)) return messages.COMMON.ERROR.MSG_TIME(name);
        break;

      case "email":
        if (!isEmail(value)) return messages.COMMON.ERROR.MSG_EMAIL;
        break;

      case "password":
        if (!isPassword(value)) return messages.COMMON.ERROR.MSG_PASSWORD(name);
        break;

      case "login_id":
        if (!isLoginId(value)) return messages.COMMON.ERROR.MSG_PASSWORD(name);
        break;

      case "alpha":
        if (!isAlpha(value)) return messages.COMMON.ERROR.MSG_ALPHA(name);
        break;

      default:
        break;
    }

    return "";
  };
}
