import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { TemplateExcelDataInfo } from "services/models";
import LabelRequired from "components/atoms/LabelRequired";
import { labeltextBreak } from "@utils/index";
type ITextInputField = Omit<TextFieldProps, "setting" | "onChange"> & {
  setting: TemplateExcelDataInfo;
  onChange: (text: string) => void;
};

const TextInputField = ({
  setting,
  value,
  onChange,
  ...props
}: ITextInputField) => {
  return (
    <TextField
      value={value}
      label={
        setting.required ? (
          <LabelRequired title={labeltextBreak(setting.name)} />
        ) : (
          labeltextBreak(setting.name)
        )
      }
      inputProps={{
        maxLength:
          setting.maxlen && setting.maxlen.length > 0
            ? setting.maxlen
            : undefined,
      }}
      multiline={setting.multiline}
      rows={setting.multiline ? setting.lineRows : 1}
      onChange={(e) => {
        if (e.target.value.split("\n").length > setting.lineRows) {
          // 指定行数以上の改行不可
          return;
        }
        onChange(e.target.value);
      }}
      {...props}
    />
  );
};

export default TextInputField;
