import React from "react";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  darken,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Colors } from "@template/style";
import SearchCustom from "components/atoms/SearchCustom";

interface HeaderProps {
  onDrawerToggle: () => void;
  title: string;
  projectMenu: string;
  isSearch: boolean;
  searchPlaceholder: string;
  onSearch: (v: string) => void;
  onSearchClear: () => void;
  showMenu?: boolean;
}

const categories = [
  { id: "work", title: "作業", path: "/work" },
  { id: "folder", title: "資料", path: "/folder" },
  { id: "edit", title: "情報", path: "/project/info" },
];

export default function Header(props: HeaderProps) {
  const { onDrawerToggle, title, projectMenu, showMenu = true } = props;
  const history = useHistoryCustom();

  const movePage = (path: string) => {
    history.pushWithRef(path);
  };

  const HeaderButtons = ({ ...props }) => {
    return (
      <Box {...props}>
        {categories.map(({ id, title, path }, n) => (
          <Button
            onClick={() => {
              movePage(path);
            }}
            sx={{
              my: 2,
              color: "white",
              bgcolor:
                projectMenu === id
                  ? darken(Colors.MAIN_GREEN, 0.33)
                  : Colors.MAIN_GREEN,
            }}
            key={id}
          >
            {title}
          </Button>
        ))}
      </Box>
    );
  };

  const MenuIcon = () => {
    return (
      <Tooltip title="メニュー">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerToggle}
          edge="end"
        >
          <Menu />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <React.Fragment>
      <AppBar
        color="primary"
        elevation={0}
        sx={{ position: { xs: "absolute", md: "fixed" } }}
      >
        <Toolbar>
          <Typography variant="h5" noWrap>
            {title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {props.isSearch && (
            <SearchCustom
              placeholder={props.searchPlaceholder}
              onSearch={props.onSearch}
              onClear={props.onSearchClear}
            />
          )}
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <HeaderButtons
              sx={{ display: projectMenu !== "" ? "flex" : "none" }}
            />
            {showMenu && <MenuIcon />}
          </Box>
        </Toolbar>
        <Toolbar sx={{ display: { xs: "absolute", md: "none" } }}>
          <Box sx={{ flexGrow: 1 }} />
          <HeaderButtons
            sx={{ display: projectMenu !== "" ? "flex" : "none" }}
          />
          {showMenu && <MenuIcon />}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
