import React from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import AccordionSection from "components/molecules/AccordionSection";
import { StateFormType } from "@utils/template/yuasa";
import { RootState } from "store/reducer";

interface IProps {
  stateForm: StateFormType;
  onChangeText: (field: keyof StateFormType) => (newText: any) => void;
}

type IkeyStateForm = "forward_fee" | "transport_fee" | "return_fee";

interface IcNameFeeItem {
  IC_name?: string;
  fee?: string;
  handleChange?: (type: any, value: any) => void;
  handleDelete?: () => void;
}

interface IGoGreeItem {
  keyStateForm: IkeyStateForm;
  stateForm: StateFormType;
  onChangeText: (field: keyof StateFormType) => (newText: any) => void;
  stateMaxConfig: number;
}

const IcNameFeeItem: React.FC<IcNameFeeItem> = ({
  IC_name,
  fee,
  handleChange = () => {},
  handleDelete = () => {},
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", mb: 1 }}>
      <TextField
        label="IC名"
        value={IC_name}
        onChange={(e) => handleChange("IC_name", e.target.value)}
        inputProps={{ maxLength: 10 }}
        sx={{ flex: 1, mr: 0.5 }}
      />
      <TextField
        label="料金"
        value={fee}
        onChange={(e) => handleChange("fee", e.target.value)}
        inputProps={{ maxLength: 10 }}
        sx={{ flex: 1 }}
      />
      <Box sx={{ display: "flex", alignItems: "flex-end", mb: "13px" }}>
        <IconButton sx={{ p: 0.5 }} onClick={handleDelete}>
          <Cancel />
        </IconButton>
      </Box>
    </Box>
  );
};

const GoFeeItem: React.FC<IGoGreeItem> = (props) => {
  const { keyStateForm, stateForm, onChangeText, stateMaxConfig } = props;

  const handleChange = (
    index: any,
    property: any,
    value: any,
    KeyStateForm: IkeyStateForm,
  ) => {
    const newData: any = [...stateForm[KeyStateForm]];
    newData[index][property] = value;
    onChangeText(KeyStateForm)(newData);
  };

  const handleButton = (KeyStateForm: IkeyStateForm) => {
    const newData = [...stateForm[KeyStateForm], { IC_name: "", fee: "" }];
    onChangeText(KeyStateForm)(newData);
  };

  const handleDelete = (KeyStateForm: IkeyStateForm, index: any) => {
    const newData = [...stateForm[KeyStateForm]];
    newData.splice(index, 1);
    onChangeText(KeyStateForm)(newData);
  };

  return (
    <>
      {stateForm[keyStateForm].map((item, index) => (
        <IcNameFeeItem
          IC_name={item?.IC_name}
          fee={item?.fee}
          handleChange={(type: any, value: any) =>
            handleChange(index, type, value, keyStateForm)
          }
          handleDelete={() => handleDelete(keyStateForm, index)}
          key={index}
        />
      ))}
      <Button
        onClick={() => handleButton(keyStateForm)}
        disabled={stateForm[keyStateForm].length >= stateMaxConfig}
      >
        インター詳細を追加
      </Button>
    </>
  );
};

const TransportFee: React.FC<IProps> = ({ stateForm, onChangeText }) => {
  const stateMaxConfig = useSelector(
    (state: RootState) => state.masterYuasa.inter_limit ?? 10,
  );

  return (
    <AccordionSection title="通行料金">
      <Stack sx={styles.formStack}>
        <TextField
          label="通行料金"
          value={stateForm.go_fee}
          onChange={(e) => onChangeText("go_fee")(e.target.value)}
          inputProps={{ maxLength: 10 }}
        />
        <FormControl>
          <InputLabel>インター詳細 </InputLabel>
          <Stack sx={styles.formStack}>
            <FormGroup>
              <InputLabel>回送</InputLabel>
              <GoFeeItem
                keyStateForm="forward_fee"
                stateForm={stateForm}
                onChangeText={onChangeText}
                stateMaxConfig={stateMaxConfig}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>搬送</InputLabel>
              <GoFeeItem
                keyStateForm="transport_fee"
                stateForm={stateForm}
                onChangeText={onChangeText}
                stateMaxConfig={stateMaxConfig}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>帰り</InputLabel>
              <GoFeeItem
                keyStateForm="return_fee"
                stateForm={stateForm}
                onChangeText={onChangeText}
                stateMaxConfig={stateMaxConfig}
              />
            </FormGroup>
          </Stack>
        </FormControl>
      </Stack>
    </AccordionSection>
  );
};

export default TransportFee;

const styles = {
  formStack: { pb: 0, width: "100%" },
};
