import { useHistory } from "react-router-dom";
import { History } from "history";

type IHistoryCustom = Omit<History, "pushWithRef"> & {
  pushWithRef(location: History.Path, state?: object): void;
};

const useHistoryCustom = () => {
  const history: IHistoryCustom = {
    ...useHistory(),
    pushWithRef: (location: History.Path, state?: object) => {
      history.push(location, { referrer: history.location.pathname, ...state });
    },
  };

  return history;
};

export { useHistoryCustom };
