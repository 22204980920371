import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  CssBaseline,
  Box,
  // Typography,
  // Link,
  Container,
  Toolbar,
} from "@mui/material";
import Navigator from "./navigator";
import Header from "./header";
import { theme, navigatorWidth } from "./style";
import { useSize } from "shared/hook/useSize";

export interface GenericTemplateProps {
  children: React.ReactNode;
  title: string;
  projectMenu?: string;
  isSearch?: boolean;
  searchPlaceholder?: string;
  onSearch?: (v: string) => void;
  onSearchClear?: () => void;
  showMenu?: boolean;
}

const GenericTemplate: React.FC<GenericTemplateProps> = ({
  children,
  title,
  projectMenu = "",
  isSearch = false,
  searchPlaceholder = "",
  onSearch = () => {},
  onSearchClear = () => {},
  showMenu = true,
}) => {
  const [open, setOpen] = React.useState(false);
  const { isSmUp } = useSize();

  React.useEffect(() => {
    if (!isSmUp) setOpen(false);
  }, [isSmUp]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // const Copyright = React.useMemo(() => {
  //   return (
  //     <Typography variant="body2" color="text.secondary" align="center">
  //       {"Copyright © "}
  //       <Link color="inherit" href={process.env.REACT_APP_APP_URL}>
  //         GenbaStar
  //       </Link>
  //       {` ${new Date().getFullYear()}`}.
  //     </Typography>
  //   );
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <CssBaseline />

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100% ",
          }}
        >
          {/*--- ヘッダー ------------------------------------------------*/}
          <Header
            onDrawerToggle={handleDrawerToggle}
            title={title}
            projectMenu={projectMenu}
            isSearch={isSearch}
            searchPlaceholder={searchPlaceholder}
            onSearch={onSearch}
            onSearchClear={onSearchClear}
            showMenu={showMenu}
          />
          {/*--- メイン --------------------------------------------------*/}
          <Container
            sx={{ flex: 1, py: 4, px: 4 }}
            className={
              window.location.pathname == "/manage" ? "sizeContainer" : ""
            }
          >
            <Toolbar />
            <Toolbar sx={{ display: { xs: "flex", md: "none" } }} />
            {children}
          </Container>

          {/*--- メニュー ------------------------------------------------*/}
          <Box
            component="nav"
            sx={{
              width: { sm: navigatorWidth },
              flexShrink: { sm: 0 },
              ...(open && { display: "none" }),
            }}
          >
            <Navigator
              variant="temporary"
              open={open}
              anchor="right"
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: 400 },
              }}
            />
          </Box>
          {/*--- フッター ------------------------------------------------*/}
          {/* <Box component="footer" sx={{ p: 2 }}>
            {Copyright}
          </Box> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default GenericTemplate;
