import axios from "axios";
import { prefixApi } from "./Connector";
const DOMAIN = process.env.REACT_APP_API_URL;

export type DataCheckUserSignUp = {
  preferred_username: string;
};

export type DataCheckUserSignUpTlog = {
  preferred_username: string;
  location_code?: string;
};

export const CheckUserSignUpAPI = (data: DataCheckUserSignUp) => {
  return axios({
    method: "post",
    url: `${DOMAIN}/${prefixApi}/check-user-register-exist`,
    data,
  });
};

export const GetTokenRevokedBy = (data: {
  preferred_username_cognito: string;
}) => {
  return axios({
    method: "post",
    url: `${DOMAIN}/${prefixApi}/token-revoked`,
    data,
  });
};

export const CheckUserSignUpAPITlog = (data: DataCheckUserSignUpTlog) => {
  return axios({
    method: "post",
    url: `${DOMAIN}/${prefixApi}/check-user-register-exist`,
    data,
  });
};
