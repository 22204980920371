import { fontSizeText, itemWidth } from "@shared-constants";
import { useEffect, useRef } from "react";
import { Text, Transformer } from "react-konva";
import { DrawItem } from "services/models";

interface IProps {
  data: DrawItem;
  onClick: () => void;
  selected: boolean;
  onChange: (v: any) => void;
  onDblClick?: () => void;
}

const MIN_SIZE = 15 as const;

/**
 * 文字
 */
const DrawText = ({
  data,
  onClick,
  selected,
  onChange,
  onDblClick = () => {},
}: IProps) => {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();

  const attachTransformer = () => {
    trRef.current.nodes([shapeRef.current]);
    trRef.current.getLayer().batchDraw();
  };

  const handleResize = () => {
    const node = shapeRef.current;
    node.setAttrs({
      width: node.width() * node.scaleX(),
      height: undefined,
      scaleX: 1,
    });
  };

  const updateDrawItem = () => {
    const node = shapeRef.current;
    onChange({
      x: node.x(),
      y: node.y(),
      width: node.width(),
      height: node.height(),
    });
  };

  useEffect(() => {
    if (selected && trRef.current) {
      attachTransformer();
    }
  }, [selected]);

  useEffect(() => {
    shapeRef.current.setAttrs({
      width: undefined,
      height: undefined,
    });
    updateDrawItem();
  }, [data.strokeWidthMode, data.type == "text" && data.text]);

  return data.type == "text" ? (
    <>
      <Text
        ref={shapeRef}
        x={data.data?.x ?? 0}
        y={data.data?.y ?? 0}
        width={data.data?.width}
        height={data.data?.height}
        text={data.text}
        fill={data.colorMode}
        fontSize={fontSizeText + itemWidth[data.strokeWidthMode]}
        fontFamily="sans-serif"
        onClick={onClick}
        draggable
        onTransform={handleResize}
        onTransformEnd={() => {
          updateDrawItem();
          attachTransformer();
        }}
        onDragStart={selected ? undefined : onClick}
        onDragEnd={updateDrawItem}
        onDblClick={onDblClick}
      />
      {selected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotateEnabled={false}
          enabledAnchors={["middle-left", "middle-right"]}
          boundBoxFunc={(oldBox, newBox) => {
            newBox.width = Math.max(MIN_SIZE, newBox.width);
            return newBox;
          }}
        />
      )}
    </>
  ) : null;
};

export default DrawText;
