import { FilterMemberArea } from "./FilterMemberArea";
import ListMemberArea from "./ListMemberArea";
import InputNameMemberArea from "./InputNameMemberArea";

const MemberSearch = () => {
  return (
    <>
      <InputNameMemberArea />
      <FilterMemberArea />
      <ListMemberArea />
    </>
  );
};

export default MemberSearch;
