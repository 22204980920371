import { UserInfo } from "services/models/models";
import { TYPES } from "store/types";

const initialState: Type.UserInfo = new UserInfo();

export const userInfoReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPES.SET_USER_INFO:
      return { ...action.payload };

    default:
      return state;
  }
};
