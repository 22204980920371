import { TYPES } from "store/types";

interface IStateTableCustom {
  check_list: Array<string>;
}

const initialState: IStateTableCustom = {
  check_list: [],
};

const set_check_table = (payload: { check_list: Array<string> }) => ({
  type: TYPES.SET_CHECK_TABLE,
  ...payload,
});

type Actions = ReturnType<typeof set_check_table>;

export const TableCustomReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TYPES.SET_CHECK_TABLE:
      return { ...state, check_list: [...action.check_list] };
    default:
      return state;
  }
};
