import React, { ReactElement, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Typography,
  SxProps,
  Theme,
} from "@mui/material";
import { ImportExport } from "@mui/icons-material";
import { Colors } from "@template/style";
import { useEffect } from "react";

interface ISortData {
  title: string;
  onClick: () => void;
  icon?: ReactElement;
  itemSx?: SxProps<Theme>;
}

interface PopupSortProps {
  data: ISortData[];
  defaultIndex: number;
  disabled?: boolean;
}

const PopupSort = ({ data, defaultIndex = 0, disabled }: PopupSortProps) => {
  const [title, setTitle] = useState<string>("");
  const [selectIndex, setSelectIndex] = useState<number>(defaultIndex);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectIndex(defaultIndex);
  }, [defaultIndex]);

  useEffect(() => {
    if (data.length < selectIndex) return;
    setTitle(data[selectIndex].title);
  }, [data, selectIndex]);

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: Colors.TEXT, pl: 0 }}
        disabled={disabled}
      >
        <ImportExport />
        <Typography>{title}</Typography>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {data.map((value: ISortData, index) => {
          return (
            <MenuItem
              onClick={() => {
                // setTitle(value.name);
                setSelectIndex(index);
                value.onClick();
                handleClose();
              }}
              sx={value.itemSx}
              key={index}
            >
              {value.icon}
              <Typography sx={{ ml: value.icon ? 0.5 : 0 }}>
                {value.title}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default PopupSort;
