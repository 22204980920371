import { call, select } from "redux-saga/effects";
import { getUserInfo, storeCondition } from "shared/utils";

export function* setFilterConditionSaga(action: any): any {
  try {
    const updatedConditionFilter = yield select((state: any) => state.filter);
    const userId = yield getUserInfo()?.user_id;
    yield call(storeCondition, "filter", updatedConditionFilter, userId);
  } catch (error) {
    console.log("function*setFilterConditionSaga ~ error", error);
  }
}
