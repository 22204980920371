import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, TextField } from "@mui/material";
import SelectLabelYuasa from "../components/SelectLabelYuasa";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import AccordionSection from "components/molecules/AccordionSection";
import { RootState } from "store/reducer";
import { StateFormType } from "@utils/template/yuasa";

interface IProps {
  stateForm: StateFormType;
  onChangeText: (field: keyof StateFormType) => (newText: any) => void;
  formError: { [key: string]: string };
}

interface IFormData {
  secondary_departure_time: any;
  secondary_arrival_time: any;
  secondary_transport_end_time: any;
  secondary_matter_end_time: any;
  secondary_date: any;
}

const SecondaryTransport: React.FC<IProps> = ({
  stateForm,
  onChangeText,
  formError,
}) => {
  const [formData, setFormData] = useState<IFormData>({
    secondary_departure_time: stateForm.secondary_departure_time,
    secondary_arrival_time: stateForm.secondary_arrival_time,
    secondary_transport_end_time: stateForm.secondary_transport_end_time,
    secondary_matter_end_time: stateForm.secondary_matter_end_time,
    secondary_date: stateForm.secondary_date,
  });
  const { listDispatchVehicle } = useSelector(
    (state: RootState) => state.masterYuasa,
  );

  const updateFormData = (field: keyof IFormData, str_time: string) => {
    if (str_time) {
      let dt = new Date();
      let time = str_time.split(":");
      dt.setHours(Number(time[0]), Number(time[1]), 0);
      if (dt !== formData[field]) {
        setFormData((prev) => ({ ...prev, [field]: dt }));
      }
    }
  };

  useEffect(() => {
    updateFormData(
      "secondary_departure_time",
      stateForm.secondary_departure_time,
    );
  }, [stateForm.secondary_departure_time]);

  useEffect(() => {
    updateFormData("secondary_arrival_time", stateForm.secondary_arrival_time);
  }, [stateForm.secondary_arrival_time]);

  useEffect(() => {
    updateFormData(
      "secondary_transport_end_time",
      stateForm.secondary_transport_end_time,
    );
  }, [stateForm.secondary_transport_end_time]);

  useEffect(() => {
    updateFormData(
      "secondary_matter_end_time",
      stateForm.secondary_matter_end_time,
    );
  }, [stateForm.secondary_matter_end_time]);

  useEffect(() => {
    if (stateForm.secondary_date) {
      let dt = new Date();
      let date = stateForm.secondary_date.split("-");
      dt.setFullYear(Number(date[0]));
      dt.setMonth(Number(date[1]) - 1);
      dt.setDate(Number(date[2]));
      if (
        formData.secondary_date === "" ||
        dt.getFullYear() !== formData.secondary_date.getFullYear() ||
        dt.getMonth() !== formData.secondary_date.getMonth() ||
        dt.getDate() !== formData.secondary_date.getDate()
      ) {
        setFormData((prev) => ({ ...prev, secondary_date: dt }));
      }
    }
  }, [stateForm.secondary_date, formData]);

  return (
    <AccordionSection title="二次搬送">
      <Stack sx={styles.formStack}>
        <TextField
          label="二次隊員名"
          value={stateForm.secondary_dispatcher_name}
          onChange={(e) =>
            onChangeText("secondary_dispatcher_name")(e.target.value)
          }
          inputProps={{ maxLength: 20 }}
        />
        <SelectLabelYuasa
          label="出動車両"
          value={stateForm.secondary_dispatch_vehicle?.SK}
          data={listDispatchVehicle}
          onChange={onChangeText("secondary_dispatch_vehicle")}
        />
        <TimePickerCustom
          label="出発時間"
          value={formData.secondary_departure_time}
          onChange={(v) => {
            setFormData((prev) => ({ ...prev, secondary_departure_time: v }));
            onChangeText("secondary_departure_time")(v);
          }}
          helperText={formError.secondary_departure_time}
          error={formError.secondary_departure_time.length > 0}
        />
        <TimePickerCustom
          label="到着時間"
          value={formData.secondary_arrival_time}
          onChange={(v) => {
            setFormData((prev) => ({ ...prev, secondary_arrival_time: v }));
            onChangeText("secondary_arrival_time")(v);
          }}
          helperText={formError.secondary_arrival_time}
          error={formError.secondary_arrival_time.length > 0}
        />
        <TimePickerCustom
          label="搬送終了時間"
          value={formData.secondary_transport_end_time}
          onChange={(v) => {
            setFormData((prev) => ({
              ...prev,
              secondary_transport_end_time: v,
            }));
            onChangeText("secondary_transport_end_time")(v);
          }}
          helperText={formError.secondary_transport_end_time}
          error={formError.secondary_transport_end_time.length > 0}
        />
        <TimePickerCustom
          label="案件終了時間"
          value={formData.secondary_matter_end_time}
          onChange={(v) => {
            setFormData((prev) => ({ ...prev, secondary_matter_end_time: v }));
            onChangeText("secondary_matter_end_time")(v);
          }}
          helperText={formError.secondary_matter_end_time}
          error={formError.secondary_matter_end_time.length > 0}
        />
        <DatePickerCustom
          label="二次日時"
          value={formData.secondary_date}
          onChange={(v) => {
            setFormData((prev) => ({ ...prev, secondary_date: v }));
            onChangeText("secondary_date")(v);
          }}
          helperText={formError.secondary_date}
          error={formError.secondary_date.length > 0}
        />
      </Stack>
    </AccordionSection>
  );
};

export default SecondaryTransport;

const styles = {
  formStack: { pb: 0, width: "100%" },
};
