import React, { useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  Tooltip,
  ListItem,
  Typography,
} from "@mui/material";
import {
  AddCircle,
  Delete,
  Edit,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import _ from "lodash";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import {
  deleteGroupMaster,
  getListActivityBase,
  getListGroup,
  getListGroupMaster,
  postCreateGroup,
  putSortGroupApi,
  putUpdateGroupInfo,
} from "@api/groupMaster";
import { ActivityBase, GroupInfo, GroupMaster } from "services/models";
import "./index.css";
import GroupEditDialog, { IData } from "./GroupEditDialog";
import TreeViewCustom from "components/atoms/TreeViewCustom";
import { Colors } from "@template/style";

interface IStateForm extends GroupInfo {
  children?: IStateForm[];
  title?: string;
  isLeaf?: boolean;
  level?: number;
  loaded?: boolean;
}

type ILoopMatch = (
  item: IStateForm,
  isChild: boolean | undefined,
  check_child: { val: boolean },
  data: IStateForm[],
) => boolean;

type ILoopCallback = (
  item: IStateForm,
  index: number,
  data: IStateForm[],
  level: number,
  isChild: boolean | undefined,
) => any;

/**
 * ツリーデータループ処理
 */
const loop = (
  data: IStateForm[],
  isMatch: ILoopMatch,
  callback: ILoopCallback,
  level: number = 1,
  isChild?: boolean,
) => {
  for (let index = 0; index < data.length; index++) {
    let item = data[index];
    let check_child = { val: false };
    if (isMatch(item, isChild, check_child, data)) {
      callback(item, index, data, level, isChild);
      return;
    }
    if (item.children) {
      loop(item.children, isMatch, callback, level + 1, check_child.val);
    }
  }
};

const GroupInfoScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [stateForm, setStateForm] = useState<IStateForm[]>([]);
  const [originData, setOriginData] = useState<IStateForm[]>([]);
  const [listGroupMaster, setListGroupMaster] = useState<GroupMaster[]>([]);
  const [listActivityBase, setListActivityBase] = useState<ActivityBase[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<IData>({ group_name: "" });
  const [maxLevel, setMaxLevel] = useState<number>(0);

  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<IStateForm | undefined>();

  const treeRef = React.useRef<any>(null);
  const [loadDataFlg, setLoadDataFlg] = useState(false);

  useEffect(() => {
    fetchData();
    getActivityBase();
  }, []);

  // データ取得 ------------------------------------------------
  const fetchData = async (groupParentID?: string) => {
    try {
      LoadingOverlayController.show();
      let isOpen = groupParentID === undefined;
      let isLevel1 =
        listGroupMaster.length > 0 && groupParentID === listGroupMaster[0].PK;
      let max_level = maxLevel;
      let res: GroupInfo[] = [];

      if (isOpen) {
        // グループ階層を取得
        const res_master: GroupMaster[] = await getListGroupMaster();
        groupParentID = res_master[0].PK;
        max_level = res_master.length;
        isLevel1 = true;

        setListGroupMaster(res_master);
        setMaxLevel(res_master.length);
      }

      res = await getListGroup({
        group_parentid: groupParentID ?? "CONTRACT#",
      });

      if (res) {
        if (isOpen) {
          const value = setInitialDataArr(res, max_level === 1, 1);
          setStateForm(JSON.parse(JSON.stringify(value)));
          setOriginData(JSON.parse(JSON.stringify(value)));
        } else {
          let new_data: IStateForm[] = JSON.parse(JSON.stringify(stateForm));
          let new_oData: IStateForm[] = JSON.parse(JSON.stringify(originData));
          const callback: ILoopCallback = (item, index, data, level) => {
            const value = setInitialDataArr(
              res,
              maxLevel === level + 1,
              level + 1,
            );

            // 取得済みの下階層データで上書き
            if (item.children) {
              item.children.forEach((c) => {
                if (c.children) {
                  const tmp_value = value.find((v) => v.SK === c.SK);

                  if (tmp_value) {
                    tmp_value.children = JSON.parse(JSON.stringify(c.children));
                  }
                }
              });
            }
            item.children = value;
            item.isLeaf = value.length > 0 ? false : true;
          };

          //　表示データに取得したグループを追加
          if (isLevel1) {
            new_data = [];
            new_oData = [];
            res.forEach((value: IStateForm) => {
              const d = stateForm.find((r) => r.SK === value.SK);
              const d_o = originData.find((r) => r.SK === value.SK);

              if (d && d_o) {
                // 取得済みの下階層データで上書き
                value.children = d.children;
                setInitialData(value, d.isLeaf, d.level);

                const index = stateForm.indexOf(d);
                const index_o = originData.indexOf(d_o);
                new_data.splice(index, 0, JSON.parse(JSON.stringify(value)));
                new_oData.splice(index_o, 0, JSON.parse(JSON.stringify(value)));
              } else {
                setInitialData(value, max_level === 1, 1);
                new_data.push(JSON.parse(JSON.stringify(value)));
                new_oData.push(JSON.parse(JSON.stringify(value)));
              }
            });
          } else {
            loop(new_data, (item) => item.SK === groupParentID, callback, 1);
            loop(new_oData, (item) => item.SK === groupParentID, callback, 1);
          }

          setStateForm(new_data);
          setOriginData(new_oData);
        }
      }
      return res;
    } catch (error: any) {
      console.log("error fetchData", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  /**
   * 初期データ一括設定
   */
  const setInitialDataArr = (
    data: IStateForm[],
    isLeaf?: boolean,
    level?: number,
  ) => {
    const initial_data: IStateForm[] = [];
    data.forEach((item) => {
      const new_item = Object.assign({}, item);
      setInitialData(new_item, isLeaf, level);
      initial_data.push(new_item);
    });
    return initial_data;
  };

  /**
   * 初期データ設定
   */
  const setInitialData = (
    data: IStateForm,
    isLeaf?: boolean,
    level?: number,
  ) => {
    data.title = "";
    data.isLeaf = isLeaf;
    data.level = level;
    data.loaded = true;
  };

  // 拠点情報取得
  const getActivityBase = async () => {
    try {
      LoadingOverlayController.show();

      const res = await getListActivityBase();
      if (res) {
        setListActivityBase(res);
      }
    } catch (error: any) {
      console.log("error getActivityBase", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // ツリービュー設定 ------------------------------------------------
  const fieldNames = {
    key: "SK",
  };

  // 開閉アイコン
  const switcherIcon = (obj: any) => {
    if (obj.isLeaf) {
      return <Box sx={{ width: "24px" }} />;
    }
    return obj.expanded ? <ExpandLess /> : <ExpandMore />;
  };

  const titleRender = (node: IStateForm) => {
    const activityBase = listActivityBase.find((d) => d.group_sk === node.SK);

    return (
      <ListItem sx={{ py: 0 }}>
        <ListItemText
          primaryTypographyProps={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="IgnoreExtractRuleTarget">{node.group_name}</span>
          {activityBase && (
            <Box
              borderRadius={2}
              bgcolor={Colors.IMAGE_SELECT_BLOCK}
              px={1}
              py={0.5}
              ml={1}
              minWidth={0}
            >
              <Typography
                fontSize={14}
                color={Colors.DISABLE_BTN_TEXT}
                noWrap
                className="IgnoreExtractRuleTarget"
              >
                {`${activityBase.activity_base_code}：${activityBase.activity_base_name}`}
              </Typography>
            </Box>
          )}
        </ListItemText>

        <Box sx={{ flexGrow: 1 }} />
        {node.level && node.level < maxLevel && (
          <Tooltip title="追加">
            <IconButton sx={{ mr: 1 }} onClick={handleAdd(node)}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="編集">
          <IconButton sx={{ mr: 1 }} onClick={handleEdit(node)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="削除">
          <IconButton onClick={handleDelete(node)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  };

  // データ読み込み
  const onLoadData = (treeNode: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        // StrictModeの2回発火を回避
        if (!treeRef.current) {
          treeRef.current = treeNode;
          setLoadDataFlg(true);
        }
        resolve(undefined);
      }, 500);
    });
  };

  useEffect(() => {
    if (loadDataFlg && treeRef.current) {
      fetchData(treeRef.current.SK).then(() => {
        setLoadDataFlg(false);
        treeRef.current = null;
      });
    }
  }, [treeRef.current, loadDataFlg]);

  // 入力制御 ------------------------------------------------
  const hasUpdateData = useMemo(() => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  }, [stateForm, originData]);

  // ドラッグ & ドロップ ---------------------------------------
  const onDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const drag_level = info.dragNode.level;
    const drop_level = info.node.level;
    let dragObj: any;
    const new_stateForm = JSON.parse(JSON.stringify(stateForm));

    // 移動データを検索、削除
    loop(
      new_stateForm,
      (item) => item.SK === dragKey,
      (item, index, data) => {
        if (data.length === 1) {
          return;
        }
        data.splice(index, 1);
        dragObj = item;
      },
    );

    if (!dragObj) return;

    // 移動データを挿入
    loop(
      new_stateForm,
      (item, isChild, check_child) => {
        check_child.val = item.SK === dropKey;
        return isChild || (item.SK === dropKey && drag_level === drop_level);
      },
      (item, index, data, level, isChild) => {
        if (isChild) {
          data.splice(index, 0, dragObj);
        } else {
          data.splice(index + 1, 0, dragObj);
        }
      },
    );

    setStateForm(new_stateForm);
  };

  // 入力ダイアログ -------------------------------------------
  const handleUpdateDialog = async (value: IData) => {
    if (editData.group_name === value.group_name) return;

    try {
      LoadingOverlayController.show();
      let res = undefined;
      if (isCreateMode) {
        // 追加
        res = await createGroup(value.group_name);
      } else {
        // 編集
        res = await UpdateGroup(value.group_name);
      }
    } catch (error: any) {
      console.log("error", error);
      ModalController.show({
        message: error && error?.detail,
        visibleButton2: true,
      });
    } finally {
      setSelectedGroup(undefined);
      LoadingOverlayController.hide();
    }
  };

  // 追加 ------------------------------------------------
  const handleAdd = (data?: IStateForm) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedGroup(data ? JSON.parse(JSON.stringify(data)) : undefined);
    setEditData({ group_name: "" });
    setIsCreateMode(true);
    setOpen(true);
  };

  const createGroup = async (group_name: string) => {
    let data = {
      group_parentid: "",
      group_master_id: "",
      group_name: "",
    };

    if (!selectedGroup?.PK || !selectedGroup.level) {
      data = {
        group_parentid: listGroupMaster[0].PK,
        group_master_id: listGroupMaster[0].SK,
        group_name: group_name,
      };
    } else {
      let level = selectedGroup.level;
      data = {
        group_parentid: selectedGroup.SK,
        group_master_id: listGroupMaster[level].SK,
        group_name: group_name,
      };
    }
    const res = await postCreateGroup(data);
    if (res) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_SUCCESS_001("グループの作成"),
        visibleButton2: true,
        handlePressButton2: async () => {
          await fetchData(data.group_parentid);
        },
      });
    }
  };

  // 更新 ------------------------------------------------
  const handleEdit = (data: IStateForm) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedGroup(JSON.parse(JSON.stringify(data)));
    setEditData({ group_name: data.group_name });
    setIsCreateMode(false);
    setOpen(true);
  };

  const UpdateGroup = async (group_name: string) => {
    let res = undefined;
    let group_parentid =
      selectedGroup?.level && selectedGroup?.level > 1
        ? selectedGroup.group_parentid
        : listGroupMaster[0].PK;

    if (!selectedGroup?.PK) return res;

    let data = {
      PK: selectedGroup.PK,
      SK: selectedGroup.SK,
      group_name: group_name,
    };
    res = await putUpdateGroupInfo(data);

    if (res) {
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_SUCCESS_001("グループの更新"),
        visibleButton2: true,
        handlePressButton2: async () => {
          await fetchData(group_parentid);
        },
      });
    }
    return res;
  };

  // 削除 ------------------------------------------------
  const handleDelete = (data: IStateForm) => (e: React.MouseEvent) => {
    e.stopPropagation();
    ModalController.show({
      message: messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: async () => {
        await handleDeleteConfirm(JSON.parse(JSON.stringify(data)));
      },
    });
  };

  const handleDeleteConfirm = async (deleteData: IStateForm) => {
    try {
      LoadingOverlayController.show();

      let group_parentid =
        deleteData?.level && deleteData?.level > 1
          ? deleteData.group_parentid
          : listGroupMaster[0].PK;

      let data = {
        PK: deleteData.PK,
        SK: deleteData.SK,
        groupName: deleteData.group_name,
      };
      const res = await deleteGroupMaster(data, false);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(
            data.groupName,
          ),
          visibleButton2: true,
          handlePressButton2: async () => {
            await fetchData(group_parentid);
          },
        });
      }
    } catch (error: any) {
      console.log("error delete group", error);

      ModalController.show({
        message: error && error?.detail,
        visibleButton2: true,
      });
    } finally {
      setSelectedGroup(undefined);
      LoadingOverlayController.hide();
    }
  };

  // 並べ替え --------------------------------------------
  const handleSortUpdate = async () => {
    const loop_sort = async (data: IStateForm[], callback: Function) => {
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        await callback(item, data);
        if (item.children) {
          await loop_sort(item.children, callback);
        }
      }
    };

    try {
      LoadingOverlayController.show();
      let dataUpload: { PK: string; SK: string }[] = [];
      let sortedData: IStateForm[] = [];
      let listUploadedID: string[] = [];
      let res: any = undefined;
      await loop_sort(
        stateForm,
        async (item: IStateForm, data: IStateForm[]) => {
          if (listUploadedID.indexOf(item.group_parentid) === -1) {
            listUploadedID.push(item.group_parentid);
            dataUpload = [];
            sortedData = [];

            sortedData = JSON.parse(JSON.stringify(data));
            sortedData.sort((a, b) => a.seq - b.seq);

            // 並べ替えの有無をチェック
            if (!_.isEqual(data, sortedData)) {
              // 更新データ作成
              data.forEach((item) => {
                dataUpload.push({ PK: item.PK, SK: item.SK });
              });
              if (dataUpload.length > 1)
                res = await putSortGroupApi(dataUpload);
            }
          }
        },
      );
      if (res?.data) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001("グループの並べ替え"),
          visibleButton2: true,
          handlePressButton2: () => {
            setOriginData(JSON.parse(JSON.stringify(stateForm)));
          },
        });
      }
    } catch (error: any) {
      console.log("error handleSortUpdate", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <GenericTemplate title="グループ管理">
      <GroupEditDialog
        data={editData}
        open={open}
        setOpen={setOpen}
        handleButtonOK={handleUpdateDialog}
      />

      <Box sx={{ textAlign: "right" }}>
        <Button endIcon={<AddCircle />} onClick={handleAdd()}>
          拠点追加
        </Button>
      </Box>

      <TreeViewCustom
        prefixCls="rc-tree-group-info"
        fieldNames={fieldNames}
        draggable
        style={{
          padding: "8px 0px",
          marginBottom: "30px",
        }}
        treeData={stateForm}
        onDrop={onDrop}
        dropIndicatorRender={() => <></>}
        showIcon={false}
        switcherIcon={switcherIcon}
        loadData={onLoadData}
        titleRender={titleRender}
        allowDrop={({ dragNode, dropNode }) => {
          // 自階層内のみ並べ替え可
          if (
            dragNode.level === dropNode.level &&
            dragNode.group_parentid === dropNode.group_parentid
          )
            return true;

          if (
            Number(dragNode.level) - 1 === Number(dropNode.level) &&
            dragNode.group_parentid === dropNode.SK
          )
            return true;
          return false;
        }}
        selectable={false}
        expandAction="click"
      />

      <Box
        position={"fixed"}
        sx={{ m: 0, right: 0, bottom: 20, textAlign: "center", width: "100%" }}
      >
        <Button
          onClick={handleSortUpdate}
          color="secondary"
          disabled={!hasUpdateData}
        >
          保存
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default GroupInfoScreen;
