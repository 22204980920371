import React from "react";
import { Provider } from "react-redux";
import "./App.css";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import Router from "./routes";
import { theme } from "./shared/template/style";
import { store, persistor } from "store";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import SnackbarController from "@shared-components/snackBar/SnackbarController";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
          <LoadingOverlayController ref={LoadingOverlayController.setRef} />
          <ModalController ref={ModalController.setRef} />
          <SnackbarController ref={SnackbarController.setRef} />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
