import { useCallback, useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Colors } from "@template/style";
import "../ManageScreen.css";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import { SelectTargetFolowArea } from "./FlowApprovalArea/SelectTargetFolowArea";
import { NameApprovalFlowInput } from "./FlowApprovalArea/NameApprovalFlowInput";
import { FlowStepArea } from "./FlowApprovalArea/FlowStepArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ApproveState } from "store/reducer/ApproveFlowReducer";
import { apiPostApprovalFLow, apiGetIsDaily } from "@api/flowApproval";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { TYPES } from "store/types";
import { clearData, STORAGE, storeData } from "@utils/Storage";
import { ParamApiPostCreateNewFlow } from "services/models/models";
import messages from "config/messages";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { cloneDeep } from "lodash";

export type ApprovalParams = {
  object_data: {
    template_id: string;
    template_name: string;
    flow_name: string;
    sk_flow: string;
  };
  list_data_approve: {
    approver_id: string;
    list_user_approver_id: string[];
  }[];
  list_delete_approve: {
    PK: string;
    SK: string;
  }[];
};

export type IProps = {
  setIsEdited: Function;
};

const FlowApproval = () => {
  const approvalState: ApproveState = useSelector(
    (state: RootState) => state.approveFlow,
  );
  const dispatch = useDispatch();
  const {
    templateInfo,
    flowInfo,
    stepList,
    editedFlowName,
    deletedStepList,
    stepsReadyForSubmit,
    flowNameReadyForSubmit,
    onClickCreateNew,
    maxUserLocation,
    isTheSameOrigin,
    deleteStepFlowList,
  } = approvalState;

  // 保存確認ポップアップ
  const { setIsEdited } = useRouterPrompt({
    onOK: async () => {
      try {
        let result = await handlingSubmit();
        return result;
      } catch (error) {
        console.error("Error in onOK:", error);
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_ERROR_001,
          visibleButton2: true,
        });
        return false;
      }
    },
    onCancel: () => {
      clearData(STORAGE.IS_CHANGED);
      clearData(STORAGE.EMAIL_INFO);
      clearData(STORAGE.FLOW_APPROVE_INFO);
      clearData(STORAGE.USER_ROLE);
      clearData(STORAGE.INVALID);
      return true;
    },
    message: messages.ManageScreen.MSG_UNSAVE_CHANGE,
  });

  const [disableDefaultStep, setDisableDefaultStep] = useState<boolean>(true);
  const [isDaily, setisDaily] = useState<boolean>(false);
  const [isUsedDaily, setIsUsedDaily] = useState<boolean>(true);
  const [isUsedFinal, setIsUsedFinal] = useState<boolean>(true);

  useEffect(() => {
    if (
      !isTheSameOrigin ||
      flowInfo.flow_name !== editedFlowName ||
      onClickCreateNew ||
      (flowInfo.is_used_daily_application ?? true) !== isUsedDaily ||
      (flowInfo.is_used_final_application ?? true) !== isUsedFinal
    ) {
      storeData(STORAGE.IS_CHANGED, JSON.stringify(true));
      setIsEdited(true);
      let params = new ParamApiPostCreateNewFlow();
      params.object_data = {
        template_id: templateInfo.SK,
        template_name: templateInfo.template_name,
        flow_name: editedFlowName,
        sk_flow: flowInfo.SK,
        is_used_daily_application: isUsedDaily,
        is_used_final_application: isUsedFinal,
      };
      params.list_data_approve = handlingGetAddNewAprrovers(stepList);
      params.list_delete_approve = deletedStepList;
      const clonedApprovalSteps = cloneDeep(stepList);
      clonedApprovalSteps.forEach((item, index) => {
        const errorMessage = handlingAddErrorMessageForStep(
          item.numOfApprover,
          item.approvers,
          index,
        );
        item.errorMessage = errorMessage;
        item.isValidStep = errorMessage.length === 0;
      });
      const allValidSteps = clonedApprovalSteps.every(
        (item) => item.isValidStep,
      );
      if (
        flowNameReadyForSubmit &&
        allValidSteps &&
        (isUsedDaily || isUsedFinal)
      ) {
        storeData(STORAGE.FLOW_APPROVE_INFO, JSON.stringify(params));
        clearData(STORAGE.INVALID);
        setDisableDefaultStep(false);
      } else {
        dispatch({
          type: TYPES.RE_CHECK,
        });
        storeData(STORAGE.INVALID, JSON.stringify(true));
        setDisableDefaultStep(true);
      }
    } else {
      setDisableDefaultStep(true);
      clearData(STORAGE.IS_CHANGED);
      setIsEdited(false);
      clearData(STORAGE.FLOW_APPROVE_INFO);
      clearData(STORAGE.INVALID);
    }
  }, [
    isTheSameOrigin,
    deleteStepFlowList,
    editedFlowName,
    flowInfo,
    stepList,
    flowNameReadyForSubmit,
    isUsedDaily,
    isUsedFinal,
  ]);

  const handlingGetAddNewAprrovers = (stepListParams: Type.StepDetail[]) => {
    const listDataApprove: {
      approver_id: string;
      max_user: number;
      list_user_approver_id: {
        user_id: string;
        group_id: string;
      }[];
      is_send_approved_mail: boolean;
      is_auto_approval_performed: boolean;
    }[] = [];
    stepListParams.forEach((step) => {
      const listApproverInStep: {
        user_id: string;
        group_id: string;
      }[] = [];
      step.approvers.forEach((item) => {
        if (!item.SK && !item.PK) {
          const target = {
            user_id: "",
            group_id: "",
          };
          target.user_id = item.memberId.split("|")[0];
          target.group_id = item.groupId;
          listApproverInStep.push(target);
        }
      });
      const newApprover = {
        approver_id: step.stepId.startsWith("APPROVER") ? step.stepId : "",
        max_user: parseInt(step.numOfApprover.toString()),
        list_user_approver_id: listApproverInStep,
        is_send_approved_mail: step.isSendApprovedMail,
        is_auto_approval_performed: step.isAutoApprovalPerformed,
      };
      listDataApprove.push(newApprover);
    });
    return listDataApprove;
  };

  const handlingAddErrorMessageForStep = (
    numOfApprover: number,
    approvers: Type.Member[],
    index: number,
  ) => {
    let result = "";
    const pattern = /^[1-9]\d*$/;
    if (!pattern.test(numOfApprover.toString()) || approvers.length === 0) {
      result = `承認ステップ${index + 1}に承認者が設定されていません`;
    }

    if (numOfApprover > maxUserLocation) {
      result = messages.ManageScreen.MSG_GREATER_THAN_MAX_APPROVERS;
    } else if (numOfApprover > approvers.length) {
      result = `承認ステップ${index + 1}の承認者が不足しています`;
    }

    return result;
  };

  useEffect(() => {
    if (templateInfo.SK) {
    const getIsDaily = async () => {
        let daily = await apiGetIsDaily({
          template_project_id: templateInfo.SK,
        });
      setisDaily(daily.is_daily);
    };
    if (templateInfo.SK) {
      getIsDaily();
      }
    }
  }, [templateInfo]);

  useEffect(() => {
    setIsUsedDaily(flowInfo.is_used_daily_application ?? true);
    setIsUsedFinal(flowInfo.is_used_final_application ?? true);
  }, [flowInfo]);

  const handlingSubmit = useCallback(async () => {
    let result = false;
    let params = new ParamApiPostCreateNewFlow();
    (params.object_data = {
      template_id: templateInfo.SK,
      template_name: templateInfo.template_name,
      flow_name: editedFlowName,
      sk_flow: flowInfo.SK,
      is_used_daily_application: isUsedDaily,
      is_used_final_application: isUsedFinal,
    }),
      (params.list_data_approve = handlingGetAddNewAprrovers(stepList)),
      (params.list_delete_approve = deletedStepList);
    params.list_delete_step_flow_list = deleteStepFlowList;
    try {
      LoadingOverlayController.show();
      const clonedApprovalSteps = cloneDeep(stepList);
      clonedApprovalSteps.forEach((item, index) => {
        const errorMessage = handlingAddErrorMessageForStep(
          item.numOfApprover,
          item.approvers,
          index,
        );
        item.errorMessage = errorMessage;
        item.isValidStep = errorMessage.length === 0;
      });
      const allValidSteps = clonedApprovalSteps.every(
        (item) => item.isValidStep,
      );
      if (
        (stepsReadyForSubmit && flowNameReadyForSubmit) ||
        (flowNameReadyForSubmit && allValidSteps)
      ) {
        const { sk_flow } = await apiPostApprovalFLow(params);
        const selectedTemplate = { ...templateInfo };
        dispatch({
          type: TYPES.SET_SAVE_APPROVE,
          payload: { selectedTemplate, sk_flow },
        });
        ModalController.show({
          message: messages.ManageScreen.MSG_SAVE_FLOW_SUCCESS,
          visibleButton2: true,
        });
        result = true;
      } else {
        dispatch({
          type: TYPES.RE_CHECK,
        });
        result = false;
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_ERROR_001,
          visibleButton2: true,
        });
        console.log(error.message);
      }
    } finally {
      LoadingOverlayController.hide();
      return result;
    }
  }, [
    stepsReadyForSubmit,
    flowNameReadyForSubmit,
    deletedStepList,
    deleteStepFlowList,
    isUsedDaily,
    isUsedFinal,
    stepList,
    templateInfo,
    editedFlowName,
    flowInfo,
  ]);
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          minHeight: "70vh",
          borderColor: Colors.MAIN_GREEN,
          borderStyle: "solid",
          borderWidth: 0.5,
        }}
      >
        <Box sx={{ ml: 3 }}>
          <Box sx={{ my: 1 }} className="Wrapper">
            <SelectTargetFolowArea />
          </Box>
        </Box>

        <Divider
          sx={{ m: 2, backgroundColor: Colors.HELPER_TEXT, borderWidth: "1px" }}
        />

        <Typography
          variant="inherit"
          sx={{
            marginY: 2,
            color: Colors.MAIN_GREEN,
            ml: 3,
          }}
        >
          承認フロー内容
        </Typography>

        <Box sx={{ mx: 3 }}>
          <Box sx={{ mb: 2 }}>
            <NameApprovalFlowInput />
          </Box>
        </Box>
        {isDaily ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckboxLabel
              label="日次承認で使用する"
              sx={{ ml: 5 }}
              checked={isUsedDaily}
              onChange={(_, checked) => {
                setIsUsedDaily(checked);
              }}
            />
            <CheckboxLabel
              label="最終承認で使用する"
              sx={{ ml: 5 }}
              checked={isUsedFinal}
              onChange={(_, checked) => {
                setIsUsedFinal(checked);
              }}
            />
          </Box>
        ) : null}
        <FlowStepArea />
      </Box>
      <Box sx={{ display: "flex", flex: 1, my: 2, justifyContent: "center" }}>
        <Button
          color="secondary"
          onClick={handlingSubmit}
          disabled={disableDefaultStep}
        >
          保存
        </Button>
      </Box>
    </Box>
  );
};

export default FlowApproval;
