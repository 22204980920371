import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import LabelRequired from "components/atoms/LabelRequired";
import ModalSignatureCanvas from "components/molecules/ModalSignatureCanvas";
import { Colors } from "@template/style";

interface ISignatureInput {
  signPath?: string;
  label?: React.ReactNode;
  required?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  onChange?: (
    text: Type.ImageInfoType | undefined,
    base64: string | undefined,
  ) => void;
  buttonLabel?: React.ReactNode;
  disabledNext?: boolean;
  onOpenNext?: () => void;
  disabledPrev?: boolean;
  onOpenPrev?: () => void;
  beforeOpenSignatureCanvas?: () => boolean;
}

const SignatureInput = (
  {
    signPath,
    label,
    required,
    error,
    helperText,
    onChange = () => {},
    buttonLabel = "サイン入力",
    disabledNext = false,
    onOpenNext = () => {},
    disabledPrev = false,
    onOpenPrev = () => {},
    beforeOpenSignatureCanvas = () => true,
  }: ISignatureInput,
  ref: any,
) => {
  const [open, setOpen] = useState(false);
  const refModal = useRef<any>();

  useImperativeHandle(ref, () => ({
    openModal: () => {
      setOpen(true);
    },
  }));

  const handleSignatureTouch = () => {
    setOpen(true);
  };

  return (
    <FormControl>
      {label && (
        <InputLabel error={error}>
          {required && typeof label == "string" ? (
            <LabelRequired title={label} />
          ) : (
            label
          )}
        </InputLabel>
      )}
      {signPath ? (
        <Box sx={{ textAlign: "center" }}>
          <CancelBtnBadge
            onClick={() => {
              onChange(undefined, undefined);
              if (refModal.current) refModal.current.clearCanvas();
            }}
            componentsProps={{ badge: { style: { top: 15, right: 15 } } }}
            sx={{ width: "100%" }}
          >
            <Box
              component="img"
              src={signPath}
              onClick={handleSignatureTouch}
              sx={{
                height: 90,
                objectFit: "contain",
                borderRadius: 1,
                width: "100%",
                border: "solid",
                borderColor: Colors.BORDER,
                borderWidth: 1,
              }}
            />
          </CancelBtnBadge>
        </Box>
      ) : (
        <Button
          onClick={() => {
            const before_open_check = beforeOpenSignatureCanvas();
            if (before_open_check) {
              setOpen(true);
            }
          }}
        >
          {buttonLabel}
        </Button>
      )}
      <FormHelperText error={error}>{helperText}</FormHelperText>
      <ModalSignatureCanvas
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(file, base64) => {
          onChange(file, base64);
        }}
        title={label}
        onOpenNext={onOpenNext}
        disabledNext={disabledNext}
        onOpenPrev={onOpenPrev}
        disabledPrev={disabledPrev}
        ref={refModal}
      />
    </FormControl>
  );
};

export default forwardRef(SignatureInput);
