import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Dropzone, { IDropZoneProps } from "components/atoms/Dropzone";
import { useRef } from "react";

type IProps = Omit<IDropZoneProps, "open" | "onClose" | "isMultiple"> & {
  open: boolean;
  onClose?: () => void;
  onAccept?: (response: File[]) => void;
  isMultiple?: boolean;
};

const DropzoneDialog = ({
  open,
  onClose = () => {},
  onAccept = () => {},
  isMultiple = false,
  ...props
}: IProps) => {
  const ref = useRef(null);
  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent sx={{ p: 3, pb: 1 }}>
        <DialogContentText>ファイルを選択してください</DialogContentText>
        <Dropzone
          showFileList={isMultiple ? true : false}
          ref={ref}
          {...props}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={onClose}>
          キャンセル
        </Button>
        {isMultiple && (
          <Button
            onClick={() => {
              const current: any = ref.current;
              onAccept(current?.getData());
            }}
          >
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DropzoneDialog;
