import { Box, CircularProgress } from "@mui/material";

const ScrollLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        bgcolor: "transparent",
        p: 1,
        pt: 2,
      }}
      key={0}
    >
      <CircularProgress color="info" size={20} />
    </Box>
  );
};

export default ScrollLoader;
