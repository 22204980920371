import React, { useEffect, useState } from "react";
import { Box, FormControl, InputLabel } from "@mui/material";
import LabelRequired from "components/atoms/LabelRequired";
import { DrawItem, TemplateExcelDataInfo } from "services/models";
import DrawPhotoDialog from "screens/DrawPhotoDialog";
import { convertSvgContentToData, getDrawSourceFromDataSVG } from "@utils/draw";
import { labeltextBreak } from "@utils/index";

interface IDrawImageInputField {
  setting: TemplateExcelDataInfo;
  value: any;
  onChange: (text: Type.ImageInfoType | undefined) => void;
}

const DrawImageInputField = ({
  setting,
  value,
  onChange,
}: IDrawImageInputField) => {
  const [open, setOpen] = useState(false);
  const [drawData, setDrawData] = useState<DrawItem[]>();

  const getUrlImage = () => {
    let url = "";
    if (value?.type === "image/svg+xml") {
      if (value?.non_Local) {
        url = value.uri_jpg ?? "";
      } else {
        url = value.uriThumb ?? "";
      }
    } else {
      url = value?.uri;
    }
    return url;
  };

  const getDrawData = async () => {
    let draw_data: DrawItem[] = [];
    if (value?.type === "image/svg+xml") {
      let xml;
      if (value?.non_Local) {
        xml = await fetch(value.uri).then((response) => response.text());
      } else {
        xml = value.xmlSvg ?? "";
      }
      const svgData = await convertSvgContentToData(xml);
      if (svgData) {
        draw_data = getDrawSourceFromDataSVG(svgData, {
          width: window.innerWidth * 0.5,
          height: window.innerHeight * 0.8,
        });
      }
    } else {
      draw_data = [];
    }
    return draw_data;
  };

  useEffect(() => {
    getDrawData().then((res) => setDrawData(res));
  }, [value]);

  return (
    <FormControl>
      <InputLabel>
        {setting.required ? (
          <LabelRequired title={labeltextBreak(setting.name)} />
        ) : (
          labeltextBreak(setting.name)
        )}
      </InputLabel>
      <DrawPhotoDialog
        uri={setting.image?.uri ?? getUrlImage()}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onChange={onChange}
        drawData={drawData}
      />
      <Box
        sx={{ textAlign: "center", cursor: "pointer" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Box
          component="img"
          src={getUrlImage()}
          sx={{
            height: 140,
            objectFit: "contain",
            borderRadius: 1,
            width: "100%",
          }}
        />
      </Box>
    </FormControl>
  );
};

export default DrawImageInputField;
