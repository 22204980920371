export class Headquarter {
  id: string;
  name: string;
  isSelected: boolean;
  constructor() {
    this.id = "";
    this.name = "";
    this.isSelected = false;
  }
}

export class Option {
  id: string;
  name: string;
  constructor() {
    this.id = "";
    this.name = "";
  }
}

export class Column {
  id: string;
  name: string;
  options: Array<Option>;
  constructor(id: string, name: string, options: Array<Option>) {
    this.id = id;
    this.name = name;
    this.options = options;
  }
}
export class CSV {
  startDate: string;
  endDate: string;
  headquarters: Array<Headquarter>;
  columns: Array<Column>;
  constructor(headquarters: Array<Headquarter>, columns: Array<Column>) {
    this.endDate = "";
    this.startDate = "";
    this.headquarters = headquarters;
    this.columns = columns;
  }
}
export class UserInfo {
  avatar: string;
  belong_company_name: string;
  created_at: string;
  position_name: string;
  is_delete: boolean;
  username_cognito: string;
  preferred_username_cognito: string;
  address: string;
  full_name: string;
  user_role: string;
  email: string;
  group_id: string;
  updated_at: string;
  position_id: string;
  user_id: string;
  is_update_profile: boolean;
  phone_number: string;
  SK: string;
  PK: string;
  country_code: string;
  contract_id: string;
  path_group: string;
  location_id: string;
  constructor() {
    this.avatar = "";
    this.belong_company_name = "";
    this.created_at = "";
    this.position_name = "";
    this.is_delete = false;
    this.username_cognito = "";
    this.preferred_username_cognito = "";
    this.address = "";
    this.full_name = "";
    this.user_role = "";
    this.email = "";
    this.group_id = "";
    this.updated_at = "";
    this.position_id = "";
    this.user_id = "";
    this.is_update_profile = false;
    this.phone_number = "";
    this.SK = "";
    this.PK = "";
    this.country_code = "";
    this.contract_id = "";
    this.path_group = "";
    this.location_id = "";
  }
}

export type TypePosition = {
  PK: string;
  SK: string;
  created_at: string;
  is_default: boolean;
  position_id: string;
  position_name: string;
  seq: string;
  updated_at: string;
};

export class FlowInfo {
  template_name: string;
  updated_at: string;
  flow_id: string;
  created_at: string;
  template_id: string;
  flow_name: string;
  SK: string;
  PK: string;
  constructor() {
    this.PK = "";
    this.SK = "";
    this.created_at = "";
    this.flow_id = "";
    this.flow_name = "";
    this.template_id = "";
    this.template_name = "";
    this.updated_at = "";
  }
}

export class ApproveField {
  updated_at: string;
  position_id: string;
  created_at: string;
  position_name: string;
  SK: string;
  PK: string;
  user_approver_name: string;
  user_approver_id: string;
  name?: string;
  constructor() {
    this.updated_at = "";
    this.position_id = "null";
    this.created_at = "";
    this.position_name = "";
    this.SK = "";
    this.PK = "";
    this.user_approver_name = "";
    this.user_approver_id = "";
    this.name = "";
  }
}

export class ResponseGetListApprove {
  flow_info: Type.FlowInfo;
  list_approve: Type.ApproveDetail[];
  constructor(flow_info: Type.FlowInfo) {
    this.flow_info = flow_info;
    this.list_approve = [];
  }
}

export class ParamApiPostCreateNewFlow {
  object_data: {
    template_id: string;
    template_name: string;
    flow_name: string;
    sk_flow: string;
    is_used_daily_application: boolean;
    is_used_final_application: boolean;
  };
  list_data_approve: {
    approver_id: string;
    list_user_approver_id: {
      user_id: string;
      group_id: string;
    }[];
    max_user: number;
  }[];
  list_delete_approve: Type.DeleteApprove[];
  list_delete_step_flow_list: {
    sk_flow: string;
    approver_id: string;
  }[];
  constructor() {
    this.object_data = {
      template_id: "",
      template_name: "",
      flow_name: "",
      sk_flow: "",
      is_used_daily_application: true,
      is_used_final_application: true,
    };

    this.list_data_approve = [];
    this.list_delete_approve = [];
    this.list_delete_step_flow_list = [];
  }
}
