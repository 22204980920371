import Tree, { BasicDataNode, TreeProps } from "rc-tree";
import { DataNode } from "rc-tree/lib/interface";
import "./index.css";

/*******************************************
  Usage
  import "rc-tree/assets/index.css"
*********************************************/

type TreeViewCustomProps<TreeDataType extends BasicDataNode = DataNode> = Omit<
  TreeProps<TreeDataType>,
  "prefixCls"
> & {
  prefixCls?: string;
};

const TreeViewCustom = <TreeDataType extends BasicDataNode = DataNode>(
  props: TreeViewCustomProps<TreeDataType>,
) => {
  // 開閉アニメーション
  const motion = {
    motionName: "node-motion",
    motionAppear: false,
    onAppearStart: () => ({ height: 0 }),
    onAppearActive: (node: any) => ({ height: node.scrollHeight }),
    onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
    onLeaveActive: () => ({ height: 0 }),
  };

  return <Tree motion={motion} {...props} />;
};

export default TreeViewCustom;
