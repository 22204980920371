import { useState } from "react";
import {
  OutlinedInput,
  InputAdornment,
  InputLabel,
  IconButton,
  FormHelperText,
  FormControl,
  OutlinedInputProps,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type PasswordInputProps = Omit<OutlinedInputProps, "helperText"> & {
  helperText?: string;
};

const PasswordInput = ({
  id = "password",
  label = "パスワード",
  required = false,
  error = false,
  disabled = false,
  helperText = "",
  fullWidth = false,
  ...props
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  // パスワードマスク表示
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      {label !== "" && (
        <InputLabel required={required} error={error} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id={id}
        label={label !== "" ? label : undefined}
        type={showPassword ? "text" : "password"}
        inputProps={{ maxLength: 30 }}
        autoComplete="current-password"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              edge="end"
              disabled={disabled}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        required={required}
        error={error}
        disabled={disabled}
        fullWidth={fullWidth}
        {...props}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;
