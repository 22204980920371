import {
  DCP_ANNOTATION,
  DCP_ORG_IMAGE,
  fontSizeText,
  itemColor,
  itemWidth,
} from "@shared-constants";
import { DrawItem, StrokeWidthMode } from "services/models";
import { createObjectURL, getBase64Image } from "./index";
import { INode as SvgData, parse } from "svgson";
const parsePath = require("parse-svg-path");

type Size = { width: number; height: number };
const spaceFirstRatio: number = 0.9;
const spaceLineRatio: number = 1;
const spaceBlankRatio: number = 0.025;

export const transformPointsToPath = (draw_point: any[]) => {
  // draw_pointを[x, y]に分割し、{x: n, y: n}に成型する
  const length = Math.ceil(draw_point.length / 2);
  const points = [...Array(length)]
    .map((_, i) => draw_point.slice(i * 2, (i + 1) * 2))
    .map((item) => ({ x: item[0], y: item[1] }));

  return points.length > 0
    ? points.reduce(
        (acc, point) => `${acc} L ${point.x},${point.y}`,
        `M ${points[0].x},${points[0].y}`,
      )
    : "";
};

export const textToTspan = async (
  x: number,
  text: string,
  widthImage: number,
  fontSize: number,
) => {
  const fontSpecs = {
    fontFamily: "sans-serif",
    fontSize: fontSize,
  };
  const lineArray = text.split(/\r?\n/);
  let resultArray: Array<string> = [];
  let blankLine: number = 0;
  for (const line of lineArray) {
    //if line don't have word will be set to a line
    if (line.trim() === "" && resultArray.length > 0) {
      blankLine += 1;
    } else {
      let newLine = true;
      let lineExample = "";
      // const wordArray = line.split('')
      const wordArray = line.split("");
      let spaceStorage: Array<number> = [0];
      for (const word of wordArray) {
        if (word === " " && lineExample === "") continue;
        if (word === " ") {
          spaceStorage[spaceStorage.length - 1] += 1;
        } else {
          spaceStorage.push(0);
        }
        const newLineExample = lineExample + word;
        const text_width = measureText({ text: newLineExample, ...fontSpecs });

        if (text_width > widthImage) {
          let dx: string = "";
          for (let i = 0; i < spaceStorage.length - 1; i++) {
            dx += `${spaceStorage[i] * spaceBlankRatio * fontSize}px `;
          }
          resultArray.push(
            `<tspan ${newLine ? `class="newLine"` : ""} x="${x}" dy="${
              (resultArray.length === 0
                ? spaceFirstRatio * fontSize
                : spaceLineRatio * fontSize) +
              blankLine * spaceLineRatio * fontSize
            }" dx="${dx}">${lineExample}</tspan>`,
          );
          newLine = false;
          lineExample = `${word}`;
          spaceStorage = [0];
          blankLine = 0;
        } else {
          lineExample = newLineExample;
        }
      }
      if (lineExample.trim() !== "") {
        let dx: string = "";
        for (let i = 0; i < spaceStorage.length - 1; i++) {
          dx += `${spaceStorage[i] * spaceBlankRatio * fontSize}px `;
        }
        resultArray.push(
          `<tspan ${newLine ? `class="newLine"` : ""} x="${x}" dy="${
            (resultArray.length === 0
              ? spaceFirstRatio * fontSize
              : spaceLineRatio * fontSize) +
            blankLine * spaceLineRatio * fontSize
          }" dx="${dx}">${lineExample}</tspan>`,
        );
        blankLine = 0;
      }
    }
  }
  return resultArray;
};

export const measureText = ({
  text,
  fontFamily,
  fontSize,
}: {
  text: string;
  fontFamily: string;
  fontSize: number;
}) => {
  let text_width = 0;
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context) {
    context.font = `normal ${fontSize}px ${fontFamily}`;
    const metrics = context.measureText(text);
    text_width = metrics.width;
  } else {
    text_width = 15 * text.length;
  }
  return text_width;
};

export const convertDataToSvg = async (
  uri: string,
  dataDraw: DrawItem[],
  imageSize: Size,
  originalImageSize: Size,
): Promise<Type.ImageInfoType | null> => {
  const imageWidth = imageSize?.width;
  const imageHeight = imageSize?.height;

  const originalImageWidth = originalImageSize?.width;
  const originalImageHeight = originalImageSize?.height;

  let imageBase64 = await getBase64Image(uri);
  let markerPaths: string = "";

  const svgPaths = await Promise.all(
    dataDraw.map(async (item, index) => {
      const { type, data, strokeWidthMode, colorMode } = item;
      if (type === "pen") {
        const path = transformPointsToPath(data.points as any[]);
        return `<path d="${path}" stroke="${
          itemColor[colorMode] ?? "rgb(0,0,0)"
        }" stroke-width="${
          itemWidth[strokeWidthMode] ?? 0
        }" strokeWidthMode="${strokeWidthMode}"
          fill-opacity="${0}" colorMode="${colorMode}" />`;
      }
      if (type === "ellipse") {
        const cx = data.x,
          cy = data.y,
          rx = data.width / 2,
          ry = data.height / 2;
        let rxParsed: number;
        if (typeof rx === "number") {
          rxParsed = rx ? Math.abs(rx) : 0;
        } else {
          rxParsed = rx ? Math.abs(Number(rx)) : 0;
        }
        return `<ellipse  cx="${cx}" cy="${cy}" rx="${Math.abs(
          rxParsed,
        )}" ry="${ry ? Math.abs(ry) : 0}" fill="none" stroke="${
          itemColor[colorMode] ?? "rgb(0,0,0)"
        }" strokeWidthMode="${strokeWidthMode}" stroke-width="${
          itemWidth[strokeWidthMode] ?? 0
        }" colorMode="${colorMode}"/>`;
      }
      if (type === "rectangle") {
        const { x, y, width, height } = data; //as RectProps;
        let widthPared: number = 0;
        const XInt: number = x ? (typeof x === "string" ? Number(x) : x) : 0;
        let xParsed: number = XInt;
        if (width) {
          if (typeof width === "number") {
            widthPared = Math.abs(width);
            if (width < 0) {
              xParsed = XInt - widthPared;
            }
          } else {
            widthPared = Math.abs(Number(width));
            if (Number(width) < 0) {
              xParsed = XInt - widthPared;
            }
          }
        }
        return `<rect x="${xParsed}" y="${y}" width="${widthPared}" height="${Math.abs(
          height,
        )}" fill="none" stroke="${
          itemColor[colorMode] ?? "rgb(0,0,0)"
        }" stroke-width="${
          itemWidth[strokeWidthMode] ?? 0
        }" strokeWidthMode="${strokeWidthMode}" colorMode="${colorMode}"/>`;
      }
      if (type === "line") {
        const x1 = data.x,
          y1 = data.y,
          x2 = data.x + data.points[2],
          y2 = data.y + data.points[3];
        return `
          <line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="${
          itemColor[colorMode] ?? "rgb(0,0,0)"
        }" strokeWidthMode="${strokeWidthMode}" stroke-width="${
          itemWidth[strokeWidthMode] ?? 0
        }" colorMode="${colorMode}"/>
          `;
      }
      if (type === "text") {
        const { x, y, width, height } = data
          ? data
          : { x: -10, y: -10, width: 0, height: 0 };
        const newX = parseFloat(x);
        const newY = parseFloat(y);
        const tspanCombination: Array<string> = await textToTspan(
          newX,
          item.type == "text" ? item.text ?? "" : "",
          width,
          fontSizeText + itemWidth[strokeWidthMode] ?? 0,
        );
        return `
          <text x="${newX}" y="${newY}" fill="${
          itemColor[colorMode] ?? "rgb(0,0,0)"
        }" font-size="${
          fontSizeText + itemWidth[strokeWidthMode] ?? 0
        }" strokeWidthMode="${strokeWidthMode}" width="${
          width ?? 500
        }" height="${height ?? 500}" colorMode="${colorMode}" createdBy="web">
            ${tspanCombination.join("")}
          </text>
          `;
      }
    }),
  );

  const annotationLayer =
    svgPaths?.length > 0
      ? `
          <g id="${DCP_ANNOTATION}" visible="true" isDattProduct="true">
            ${svgPaths.join("")}
          </g>
          `
      : "";

  const blackboardPart = "";

  const svgDone = `<?xml version="1.0" encoding="utf-8"?>
        <svg
        xmlns:xlink="http://www.w3.org/1999/xlink" 
        width="${originalImageWidth}"
        height="${originalImageHeight}"
        viewBox="0 0 ${imageWidth} ${imageHeight}"
        xmlns="http://www.w3.org/2000/svg"
        >
  
         <!-- Info meta -->
         <x:xmpmeta xmlns:x="adobe:ns:meta/">
         <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
         <rdf:Description rdf:about="" xmlns:dcpm="http://dcpadv.org/schema/3.1/metadata">
         <dcpm:vender>DATT JAPAN Inc.</dcpm:vender>
         <dcpm:software>Genbastar Ver1.0.0</dcpm:software>
         <dcpm:metaVersion>3.1</dcpm:metaVersion>
         <dcpm:stdVersion>1.0</dcpm:stdVersion>
         </rdf:Description>
         </rdf:RDF>
         </x:xmpmeta>
  
         <!-- define part -->
          <defs>
            ${markerPaths}
          </defs>
  
         <!-- Subject layer (image) -->
          <g id="${DCP_ORG_IMAGE}" visible="true">
            <image 
            xlink:href="data:image/jpeg;base64,${
              imageBase64.split("base64,")[1]
            }"
            width="${imageWidth}" 
            height="${imageHeight}"
            x="0"
            y="0"></image>
          </g>
  
         <!-- Annotation layer -->
          ${annotationLayer}
  
         <!-- Blackboard layer -->
          ${blackboardPart}
        </svg>
      `;
  // <g id="dcp_annotation" visible="true" isDattProduct="true">
  //   ${svgPaths.join('')}
  //   </g>

  // console.log("svgDone", svgDone);

  // svgファイルに変換
  const svg = new Blob([svgDone], { type: "image/svg+xml" });
  const path = createObjectURL(svg);

  const fileName = `Genbastar_${new Date().getTime().toString()}.svg`;
  const fileType = "image/svg+xml";
  return {
    uri: path,
    uri_jpg: path,
    uriThumb: path,
    name: fileName,
    type: fileType,
    non_Local: false,
    xmlSvg: svgDone,
    size: {
      width: imageWidth,
      height: imageHeight,
    },
  };
};

export const convertSvgContentToData = async (xml: string) => {
  return parse(xml)
    .then((json: SvgData) => {
      return json;
    })
    .catch(() => {
      return null;
    });
};

export const getDrawSourceFromDataSVG = (
  svgData: SvgData,
  windowSize: Size,
): DrawItem[] => {
  if (!svgData || (svgData.children && svgData.children.length === 0)) {
    return [];
  }
  const viewBox: string = svgData.attributes.viewBox;
  const arrayViewBox: Array<string> = viewBox?.split(" ");

  const drawRegionWith = +arrayViewBox[2] || 0;
  const drawRegionHeight = +arrayViewBox[3] || 0;
  // const ratioWidth = (deviceWidth - drawRegionWith) / deviceWidth;
  const ratioWidth = windowSize.width / drawRegionWith;
  const ratioHeight = windowSize.height / drawRegionHeight;
  const ratio = ratioHeight < ratioWidth ? ratioHeight : ratioWidth;
  const drawDone: DrawItem[] = [];
  svgData.children.map((item) => {
    if (item?.attributes?.id === DCP_ANNOTATION) {
      (item as any)?.children?.map((drawItem: any) => {
        const { name, attributes } = drawItem;
        switch (name) {
          case "rect":
            const rectItem: DrawItem = {
              type: "rectangle",
              strokeWidthMode: attributes["strokeWidthMode"],
              colorMode: attributes["colorMode"],
              data: {
                x: Number(attributes?.x) * ratio,
                y: Number(attributes?.y) * ratio,
                width: Number(attributes?.width) * ratio,
                height: Number(attributes?.height) * ratio,
              },
            };
            drawDone.push(rectItem);
            break;
          case "ellipse":
            const ellipseItem: DrawItem = {
              type: "ellipse",
              strokeWidthMode: attributes["strokeWidthMode"],
              colorMode: attributes["colorMode"],
              data: {
                x: Number(attributes?.cx) * ratio,
                y: Number(attributes?.cy) * ratio,
                width: Number(attributes?.rx) * 2 * ratio,
                height: Number(attributes?.ry) * 2 * ratio,
              },
            };
            drawDone.push(ellipseItem);
            break;
          case "path":
            const penItem: DrawItem = {
              type: "pen",
              strokeWidthMode: attributes["strokeWidthMode"],
              colorMode: attributes["colorMode"],
              data: { points: normalizePath(attributes.d, ratio) },
            };
            drawDone.push(penItem);
            break;
          case "line":
            const singleHeadItem: DrawItem = {
              type: "line",
              strokeWidthMode: attributes["strokeWidthMode"],
              colorMode: attributes["colorMode"],

              data: {
                x: Number(attributes?.x1) * ratio,
                y: Number(attributes?.y1) * ratio,
                points: [
                  0,
                  0,
                  Number(attributes?.x2) - Number(attributes?.x1) * ratio,
                  Number(attributes?.y2) - Number(attributes?.y1) * ratio,
                ],
              },
            };
            drawDone.push(singleHeadItem);
            break;
          case "text":
            const tspans: Array<any> = drawItem?.children;
            let textArray: Array<string> = [];
            let width = 0;
            const isApp = attributes["createdBy"] == "app";
            const strokeWidthMode: StrokeWidthMode =
              attributes["strokeWidthMode"];
            tspans.map((item, index) => {
              if (item?.attributes["class"] === "newLine") {
                textArray.push(item?.children[0]?.value);
              } else {
                textArray[textArray.length - 1] = `${
                  textArray[textArray.length - 1]
                }${item?.children[0]?.value}`;
              }
              // アプリで作成したSVGの場合、幅を再計算
              if (isApp) {
                let w = measureText({
                  text: item?.children[0]?.value,
                  fontFamily: "sans-serif",
                  fontSize: fontSizeText + itemWidth[strokeWidthMode],
                });
                if (width < w) {
                  width = w;
                }
              }
            });

            const textItem: DrawItem = {
              type: "text",
              data: {
                x: Number(attributes?.x) * ratio,
                y: Number(attributes?.y) * ratio,
                width: isApp ? width : Number(attributes?.width) * ratio,
                height: isApp ? undefined : Number(attributes?.height) * ratio,
              },
              strokeWidthMode: attributes["strokeWidthMode"],
              colorMode: attributes["colorMode"],
              text: textArray.join("\n") ?? "",
            };
            drawDone.push(textItem);
            break;

          default:
          // code block
        }
      });
    }
  });
  return drawDone;
};

export const getImageSourceFromDataSvg = (
  svgData: SvgData,
): {
  uri: string;
  width?: number;
  height?: number;
  isBase64?: boolean;
} | null => {
  if (!svgData || (svgData.children && svgData.children.length === 0)) {
    return null;
  }
  let imageData = { uri: "", width: 0, height: 0, isBase64: false };
  svgData.children.map((item) => {
    if (item?.attributes?.id === DCP_ORG_IMAGE) {
      (item as any)?.children?.map((img: any) => {
        imageData.uri = img.attributes["xlink:href"];
        imageData.width = +img.attributes.width;
        imageData.height = +img.attributes.height;
        imageData.isBase64 = true;
      });
    }
  });
  return imageData;
};

export const normalizePath = (
  path: string,
  ratioScale: number = 1,
): Array<number> => {
  const pathPared = parsePath(path);
  const result: Array<number> = [];
  pathPared.forEach((item: any) => {
    result.push(Number(item[1]) * ratioScale);
    result.push(Number(item[2]) * ratioScale);
  });
  return result;
};
