import {
  METHOD_API,
  createConnector,
  prefixApi,
  getParamsHelp,
} from "./Connector";

type paramGetInviteUserInfo = {
  contract_id: string;
  username?: string;
  email?: string;
  phone?: string;
  SK?: string;
};

type paramInsertInviteUser = {
  contract_id?: string;
  email?: string;
  phone_number?: string;
  user_role: string;
};

export const getInviteUserInfoApi = (params: paramGetInviteUserInfo) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/invite/user?${getParamsHelp(params)}`,
    null
  );
};

export const deleteInviteUserApi = (data: any) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/invite/user?${getParamsHelp(data)}`,
    null
  );
};

export const insertInviteUserApi = (params: paramInsertInviteUser) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/invite/user`,
    params
  );
};

export const resendInviteUserApi = (invite_id: string) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/invite/user/resend`,
    { invite_id: invite_id }
  );
};
