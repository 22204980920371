import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";
interface sortData {
  PK: String;
  SK: String;
}

// Place

export const getListPlace = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/occurrence-location-class/${encodeURIComponent(
      activity_base_id,
    )}`,
    null,
  );
};

export const updatePlaceSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/occurrence-location-class/sort`,
    dataBody,
  );
};

export const deletePlace = (place_id: string, activity_base_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/occurrence-location-class/${encodeURIComponent(
      place_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

// Accident
export const getListAccidentClass = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/accident-class`, null);
};

export const updateAccidentClassSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/accident-class/sort`,
    dataBody,
  );
};

export const deleteAccidentClass = (accident_class_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/accident-class/${encodeURIComponent(accident_class_id)}`,
    null,
  );
};

// Exterior

export const getListExterior = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/exterior`, null);
};

export const updateExteriorSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/exterior/sort`,
    dataBody,
  );
};

export const deleteExterior = (exterior_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/exterior/${encodeURIComponent(exterior_id)}`,
  );
};

// CauseSituation

export const getListCauseSituation = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/cause-situation`, null);
};

export const updateCauseSituationSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/cause-situation/sort`,
    dataBody,
  );
};

export const deleteCauseSituation = (cause_situation_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/cause-situation/${encodeURIComponent(cause_situation_id)}`,
    null,
  );
};

// Packing

export const getListPacking = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/packing`, null);
};

export const updatePackingSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/packing/sort`,
    dataBody,
  );
};

export const deletePacking = (packing_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/packing/${encodeURIComponent(packing_id)}`,
    null,
  );
};

//Publisher

export const getListPublisher = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/publisher/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

export const updatePublisherSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/publisher/sort`,
    dataBody,
  );
};

export const deletePublisher = (
  publisher_id: string,
  activity_base_id: string,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/publisher/${encodeURIComponent(
      publisher_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

//ProductStatus

export const getListProductStatus = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/product_status/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

export const updateProductStatusSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/product_status/sort`,
    dataBody,
  );
};

export const deleteProductStatus = (
  product_status_id: string,
  activity_base_id: string,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/product_status/${encodeURIComponent(
      product_status_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

//Product

export const getListProduct = (activity_base_id: string, last_key?: string) => {
  let url_params = "";
  if (last_key !== undefined) {
    const params = { last_key: last_key };
    url_params = `?${getParamsHelp(params)}`;
  }
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/product/${encodeURIComponent(activity_base_id)}${url_params}`,
    null,
  );
};

export const updateProductSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/product/sort`,
    dataBody,
  );
};

export const deleteProduct = (
  product_sort_key: string,
  activity_base_id: string,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/product/${encodeURIComponent(
      product_sort_key,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

//AccidentReason

export const getListAccidentReason = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/accident_reason/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

export const updateAccidentReasonSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/accident_reason/sort`,
    dataBody,
  );
};

export const deleteAccidentReason = (
  accident_reason_id: string,
  activity_base_id: string,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/accident_reason/${encodeURIComponent(
      accident_reason_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

//AccidentHandling

export const getListAccidentHandling = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/accident_handling/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

export const updateAccidentHandlingSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/accident_handling/sort`,
    dataBody,
  );
};

export const deleteAccidentHandling = (
  accident_handling_id: string,
  activity_base_id: string,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/accident_handling/${encodeURIComponent(
      accident_handling_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

//Shipper

export const getListShipper = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/shipper/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

export const updateShipperSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/shipper/sort`,
    dataBody,
  );
};

export const deleteShipper = (shipper_id: string, activity_base_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/shipper/${encodeURIComponent(
      shipper_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

//Span
export const getListSpan = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/span/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

export const updateSpanSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/span/sort`,
    dataBody,
  );
};

export const deleteSpan = (span_id: string, activity_base_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/span/${encodeURIComponent(span_id)}/${encodeURIComponent(
      activity_base_id,
    )}`,
    null,
  );
};

//Import_division

export const getListImportDivision = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/import_division/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

export const updateImportDivisionSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/import_division/sort`,
    dataBody,
  );
};

export const deleteImportDivision = (
  import_division_id: string,
  activity_base_id: string,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/import_division/${encodeURIComponent(
      import_division_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

// Printing

export const getListPrinting = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/printing`, null);
};

export const updatePrintingSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/printing/sort`,
    dataBody,
  );
};

export const deletePrinting = (printing_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/printing/${encodeURIComponent(printing_id)}`,
    null,
  );
};

// Complete

export const getListComplete = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/complete`, null);
};

export const updateCompleteSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/complete/sort`,
    dataBody,
  );
};

export const deleteComplete = (complete_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/complete/${encodeURIComponent(complete_id)}`,
    null,
  );
};

//ReportType

export const getListReportType = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/report_type`, null);
};

export const updateReportTypeSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/report_type/sort`,
    dataBody,
  );
};

export const deleteReportType = (report_type_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/report_type/${encodeURIComponent(report_type_id)}`,
    null,
  );
};

//Report

export const getListReport = (reportType: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/report/${encodeURIComponent(reportType)}`,
    null,
  );
};

export const updateReportSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/report/sort`,
    dataBody,
  );
};

export const deleteReport = (report_id: string, report_type: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/report/${encodeURIComponent(report_id)}/${encodeURIComponent(
      report_type,
    )}`,
    null,
  );
};

export const getAllReportItemUpdatedAt = (activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-all-report-item-updateat/${encodeURIComponent(
      activity_base_id,
    )}`,
    null,
  );
};

export const getAllReportItem = (activity_base_id: string, params?: any) => {
  let url_params = "";
  if (params) {
    url_params = `?${getParamsHelp(params)}`;
  }

  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-all-report-item/${encodeURIComponent(
      activity_base_id,
    )}${url_params}`,
    null,
  );
};

export const getVehiclesByFrameNo = (dataBody: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/vehicles-by-frame?${getParamsHelp(dataBody)}`,
    null,
  );
};

export const getListForkLiftCheckItemAll = (
  activity_base_id: string,
  fuel_id: string,
) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/check_item/${encodeURIComponent(
      activity_base_id,
    )}?${getParamsHelp({ fuel_id: fuel_id })}`,

    null,
  );
};

export const getListMaster = (type: string, activity_base_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/${encodeURIComponent(type)}/${encodeURIComponent(
      activity_base_id,
    )}`,
    null,
  );
};

export const updateMasterSort = (type: string, dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/${encodeURIComponent(type)}/sort`,
    dataBody,
  );
};

export const deleteMaster = (
  master_id: string,
  type: string,
  activity_base_id: string,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/${encodeURIComponent(type)}/${encodeURIComponent(
      master_id,
    )}/${encodeURIComponent(activity_base_id)}`,
    null,
  );
};

// Vehicles
export const insertVehicles = (dataBody: any) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/vehicles`,
    dataBody,
  );
};

export const updateVehicles = (dataBody: any, SK: string) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/vehicles/${encodeURIComponent(SK)}`,
    dataBody,
  );
};

export const getFuelAll = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/fuel`, null);
};

// Container
export const getListContainerApi = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/container`, null);
};

// Container Defect
export const getListContainerDefectApi = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/container-defect`, null);
};

export const getListForkLiftCheckSection = (
  activity_base_id: string,
  fuel_id: string,
) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/check_section/${encodeURIComponent(
      activity_base_id,
    )}?${getParamsHelp({
      fuel_id: fuel_id,
    })}`,
    null,
  );
};

export const getListForkLiftCheckItem = (
  activity_base_id: string,
  section_id: string,
  fuel_id: string,
) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/check_item/${encodeURIComponent(
      activity_base_id,
    )}/${encodeURIComponent(section_id)}?${getParamsHelp({
      fuel_id: fuel_id,
    })}`,
    null,
  );
};

export const deleteMasterContract = (master_id: string, type: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/${encodeURIComponent(type)}/${encodeURIComponent(master_id)}`,
    null,
  );
};
