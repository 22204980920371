import { Box, TextField } from "@mui/material";
import { MAX_LENGTH_30 } from "@shared-constants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { TYPES } from "store/types";

const InputNameMemberArea = () => {
  const dispatch = useDispatch();
  const approveFlowState = useSelector((state: RootState) => state.approveFlow);
  const { textSearch } = approveFlowState;
  const [nameMember, setNameMember] = useState<string>(textSearch);
  const handleChange = (event: any) => {
    setNameMember(event.target.value);
    dispatch({
      type: TYPES.SET_NAME_MEMBER_SEARCH,
      payload: event.target.value,
    });
  };
  return (
    <Box sx={{ my: 2, mx: 3 }}>
      <TextField
        label="アカウント名"
        value={nameMember}
        sx={{ width: "45%" }}
        onChange={handleChange}
        inputProps={{ maxLength: MAX_LENGTH_30 }}
        className="TextInputFlowName"
      />
    </Box>
  );
};

export default InputNameMemberArea;

const styles = {} as const;
