import { useCallback, useLayoutEffect, useState } from "react";

export const useScrollable = (rows: any[]) => {
  const [scrollComponent, setScrollComponent] = useState<HTMLDivElement>();
  const ref = useCallback((elm: HTMLDivElement) => {
    setScrollComponent(elm);
  }, []);

  const [scrollable, setScrollable] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (!scrollComponent) return;

    setScrollable(scrollComponent.scrollHeight > scrollComponent.clientHeight);
  }, [rows, scrollComponent]);

  useLayoutEffect(() => {
    if (!scrollComponent) return;

    const handleWindowResize = () => {
      setScrollable(
        scrollComponent.scrollHeight > scrollComponent.clientHeight,
      );
    };

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [scrollComponent]);

  return [scrollable, ref, scrollComponent] as const;
};
