import React from "react";
import { StateFormType } from "@utils/template/yuasa";
import { Card, CardContent, Stack } from "@mui/material";
import InfoDispatch from "./InfoDispatch";
import SecondaryTransport from "./SecondaryTransport";
import StatusCar from "./StatusCar";
import TransportFee from "./TransportFee";
import ShipmentDetail from "./ShipmentDetail";

interface IPropsReportTab {
  stateForm: StateFormType;
  onChangeText: (
    field: keyof StateFormType,
    checkHalfWidth?: boolean,
  ) => (newText: any) => void;
  formError: { [key: string]: string };
}

const ReportTab: React.FC<IPropsReportTab> = ({
  stateForm,
  onChangeText,
  formError,
}) => {
  return (
    <Card>
      <CardContent>
        <InfoDispatch
          stateForm={stateForm}
          onChangeText={onChangeText}
          formError={formError}
        />
      </CardContent>
      <Stack sx={{ mb: 2 }}>
        <SecondaryTransport
          stateForm={stateForm}
          onChangeText={onChangeText}
          formError={formError}
        />
        <StatusCar stateForm={stateForm} onChangeText={onChangeText} />
        <TransportFee stateForm={stateForm} onChangeText={onChangeText} />
        <ShipmentDetail
          stateForm={stateForm}
          onChangeText={onChangeText}
          formError={formError}
        />
      </Stack>
    </Card>
  );
};

export default ReportTab;
