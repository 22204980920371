import React from "react";
import { FormControl, InputLabel } from "@mui/material";
import ImagePickerList from "components/molecules/ImagePickerList";

interface IImagePageField {
  addButtonText: string;
  maxImage: number;
  imagesList: Type.ImageInfoType[];
  onChange: (image_array: Type.ImageInfoType[]) => void;
  deleteConfirmMessage?: string;
  title?: string;
}

const ImagePageField = ({
  addButtonText = "",
  imagesList = [],
  maxImage = 10,
  onChange = () => {},
  deleteConfirmMessage = "報告内容を削除しますか?",
  title = "",
}: IImagePageField) => {
  return (
    <FormControl>
      <InputLabel>{title}</InputLabel>
      <ImagePickerList
        addButtonText={addButtonText}
        maxImage={maxImage}
        imagesList={imagesList}
        onChange={onChange}
        deleteConfirmMessage={deleteConfirmMessage}
      />
    </FormControl>
  );
};

export default ImagePageField;
