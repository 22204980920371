import { useEffect, useRef } from "react";
import { Group, Line, Transformer } from "react-konva";
import { DrawItem } from "services/models";
import { itemWidth } from "@shared-constants";

interface IProps {
  data: DrawItem;
  onClick: () => void;
  selected: boolean;
  onChange: (v: any) => void;
}

/**
 * フリーハンド
 */
const DrawFreehand = ({ data, onClick, selected, onChange }: IProps) => {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();
  const groupRef = useRef<any>();

  const attachTransformer = () => {
    trRef.current.nodes([shapeRef.current]);
    trRef.current.getLayer().batchDraw();
  };

  const updateDrawItem = () => {
    // ドラッグした位置へpointsを移動
    const node = shapeRef.current;
    const group = groupRef.current;
    const groupPos = group.getPosition();
    const newPoints = node.points().map((item: number, index: number) => {
      if (index % 2 == 0) {
        return item + groupPos.x;
      } else {
        return item + groupPos.y;
      }
    });
    group.setAttrs({
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    });
    onChange({
      points: newPoints,
    });
  };

  useEffect(() => {
    if (selected && trRef.current) {
      attachTransformer();
    }
  }, [selected]);

  return (
    <Group
      ref={groupRef}
      draggable
      onDragStart={selected ? undefined : onClick}
      onDragEnd={updateDrawItem}
    >
      <Line
        ref={shapeRef}
        points={data.data?.points}
        stroke={data.colorMode}
        strokeWidth={itemWidth[data.strokeWidthMode]}
        onClick={onClick}
        tension={0.5}
        lineCap="round"
        lineJoin="round"
        // globalCompositeOperation={
        //   line.tool === "eraser" ? "destination-out" : "source-over"
        // }
      />
      {selected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotateEnabled={false}
          onTransformStart={(e) => {
            trRef.current.stopTransform();
          }}
        />
      )}
    </Group>
  );
};

export default DrawFreehand;
