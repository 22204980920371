import {
  useState,
  useCallback,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDropzone, Accept, FileRejection } from "react-dropzone";
import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Snackbar,
  Typography,
} from "@mui/material";
import { Colors } from "@template/style";
import { Cancel, UploadFile } from "@mui/icons-material";
import messages from "config/messages";
import { MAX_CAPACITY } from "@shared-constants";

type IAccept = "image" | "all" | "csv" | "excel";

export interface IDropZoneProps {
  accept?: IAccept;
  maxFiles?: number;
  showFileList?: boolean;
  onChoose?: (response: any) => void;
  onDeleteFile?: () => void;
}

const Dropzone = (
  {
    accept = "image",
    maxFiles = MAX_CAPACITY,
    showFileList = true,
    onChoose,
    onDeleteFile,
  }: IDropZoneProps,
  ref: any,
) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const onDrop = useCallback(
    (acceptedFiles, fileRejections: FileRejection[]) => {
      // 追加不可
      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0].code === "file-too-large") {
          setSnackbarMessage(messages.ManageFolder.MSG_SECLECT_LIMIT_30MB);
          setSnackbar(true);
        }
        if (fileRejections[0].errors[0].code === "too-many-files") {
          setSnackbarMessage(messages.ManageFolder.MSG_SELECT_LIMIT(maxFiles));
          setSnackbar(true);
        }
        if (fileRejections[0].errors[0].code === "file-invalid-type") {
        }
        return;
      }

      if (selectedFiles.length + acceptedFiles.length > maxFiles) {
        setSnackbarMessage(messages.ManageFolder.MSG_SELECT_LIMIT(maxFiles));
        setSnackbar(true);
        return;
      }

      // ファイル追加
      setSelectedFiles([...selectedFiles, ...acceptedFiles]);

      if (acceptedFiles.length > 0) {
        if (typeof onChoose === "function") onChoose(acceptedFiles);
      }
    },
    [selectedFiles, maxFiles, onChoose],
  );

  const dropzpne_accept = useMemo(() => {
    let response: Accept;
    switch (accept) {
      case "image":
        response = { "image/*": [] };
        break;
      case "csv":
        response = { "text/csv": [] };
        break;
      case "excel":
        response = {
          "application/vnd.ms-excel": [],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            [],
        };
        break;
      case "all":
      default:
        response = {};
        break;
    }
    return response;
  }, [accept]);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, open } =
    useDropzone({
      accept: dropzpne_accept,
      onDrop,
      maxFiles: maxFiles,
      multiple: maxFiles > 1,
      maxSize: 31457280, // byte(30MB)
      noClick: true,
      noKeyboard: true,
    });

  const files = useMemo(() => {
    const handleDelete = (file: any) => {
      const newFiles = [...selectedFiles];
      newFiles.splice(newFiles.indexOf(file), 1);
      setSelectedFiles(newFiles);
      if (typeof onDeleteFile === "function") onDeleteFile();
    };

    return selectedFiles.map((file: any, index) => (
      <ListItem
        key={index}
        sx={{ display: "list-item", p: 0, color: Colors.TEXT }}
      >
        {file.path}
        <IconButton onClick={() => handleDelete(file)}>
          <Cancel />
        </IconButton>
      </ListItem>
    ));
  }, [selectedFiles]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      return selectedFiles;
    },
    delData: () => {
      setSelectedFiles([]);
    },
  }));

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: 1.5,
          borderStyle: "dashed",
          borderRadius: 1,
          bgcolor: Colors.IMAGE_SELECT_BLOCK,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          py: 2,
          borderColor: isDragAccept
            ? Colors.MAIN_GREEN
            : isDragActive
            ? Colors.ERROR_TEXT
            : "#ccc",
        }}
      >
        <input {...getInputProps()} />
        <UploadFile fontSize="large" />
        <Typography variant="body2">
          ここにファイルをドラッグ&ドロップ
        </Typography>
        <Button
          sx={{ mt: 2 }}
          onClick={open}
          disabled={selectedFiles.length >= maxFiles}
        >
          ファイル選択
        </Button>
      </Box>
      {showFileList && selectedFiles.length > 0 && (
        <List sx={{ listStyleType: "disc", ml: 3 }}>{files}</List>
      )}
      <Snackbar
        open={snackbar}
        autoHideDuration={5000}
        onClose={() => setSnackbar(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default forwardRef(Dropzone);
