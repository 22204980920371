import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { AddCircle, Cancel } from "@mui/icons-material";
import { DataFile } from "services/models";
import { useSize } from "shared/hook/useSize";
import { Colors } from "@template/style";
import { createObjectURL } from "@utils/index";

interface IProps {
  data: DataFile[] | any;
  onAdd?: Function;
  onRemove?: Function;
  disabled?: boolean;
  btnTitle?: string;
  editable?: boolean;
}

const FileItem = ({
  data,
  onAdd = () => {},
  onRemove = () => {},
  disabled,
  btnTitle = "資料を追加",
  editable = true,
}: IProps) => {
  const { isSmUp } = useSize();

  const renderFileItem = (
    item: DataFile | any,
    index: number,
    showLine: boolean = true,
  ) => {
    return (
      <Box key={index}>
        <Stack direction="row" sx={{ p: 1, alignItems: "center" }}>
          {editable ? (
            <Typography
              sx={{
                flexGrow: 1,
                wordBreak: "break-all",
                color: Colors.MAIN_GREEN,
              }}
            >
              {item?.name ?? item?.file_name_filter ?? item?.file_name ?? ""}
            </Typography>
          ) : (
            <Link
              variant="body1"
              component="button"
              onClick={async () => {
                // ダウンロード
                const response = await fetch(item?.path_file);
                const blob = await response.blob();
                const a = document.createElement("a");
                const url = createObjectURL(blob);
                a.href = url;
                a.download = item?.file_name;
                a.click();
                a.remove();

                setTimeout(() => {
                  URL.revokeObjectURL(url);
                }, 100);
              }}
              sx={{
                flexGrow: 1,
                wordBreak: "break-all",
                textAlign: "left",
              }}
            >
              {item?.name ?? item?.file_name_filter ?? item?.file_name ?? ""}
            </Link>
          )}
          {editable && (
            <IconButton onClick={() => onRemove(index, item)}>
              <Cancel />
            </IconButton>
          )}
        </Stack>
        {showLine && <Divider />}
      </Box>
    );
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: isSmUp ? "50%" : "100%",
        px: 1,
        display: data.length === 0 && !editable ? "none" : undefined,
      }}
    >
      {data.map((item: DataFile, index: number) =>
        renderFileItem(item, index, editable || index + 1 < data.length),
      )}
      {editable && (
        <Button
          variant="text"
          endIcon={<AddCircle />}
          fullWidth
          sx={{ my: 1 }}
          onClick={() => onAdd()}
          disabled={disabled}
        >
          {btnTitle}
        </Button>
      )}
    </Card>
  );
};

export default FileItem;
