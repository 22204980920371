import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  DatePickerProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jaLocale from "date-fns/locale/ja";

type DatePickerCustomProps<TInputDate, TDate> = Omit<
  DatePickerProps<TInputDate, TDate>,
  "renderInput" | "required" | "helperText" | "error" | "onBlur"
> & {
  renderInput?: (props: TextFieldProps) => React.ReactElement;
  required?: boolean;
  helperText?: React.ReactNode;
  error?: boolean;
  onBlur?: Function;
};

const DatePickerCustom = <TInputDate, TDate = TInputDate>(
  datePickerCustomProps: DatePickerCustomProps<TInputDate, TDate>,
) => {
  const {
    required,
    helperText,
    error,
    onBlur = () => {},
    ...props
  } = datePickerCustomProps;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale}>
      <DatePicker
        inputFormat="yyyy/MM/dd"
        mask="____/__/__"
        toolbarTitle="日付選択"
        toolbarFormat="yyyy/MM/dd"
        rightArrowButtonText="次月"
        leftArrowButtonText="前月"
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            helperText={helperText}
            onBlur={() => onBlur()}
            error={error}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DatePickerCustom;
