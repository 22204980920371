import { applyMiddleware, createStore, compose } from "redux";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer, RootState } from "./reducer";
import { useDispatch } from "react-redux";
import rootSaga from "sagas";
import createSagaMiddleware from "redux-saga";
import encryptors from "@utils/Encryptor";

const encryptor = createTransform(
  (inboundState: RootState, key) => {
    if (!inboundState) return inboundState;
    return encryptors.Encrypt(JSON.stringify(inboundState));
  },
  (outboundState: string, key) => {
    if (!outboundState) return outboundState;
    return JSON.parse(encryptors.Decrypt(outboundState));
  },
);

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["tableCustom"],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(sagaMiddleware)),
);
sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export { store, persistor };
