import { TYPES } from "store/types";

interface IStateMailConfirm {
  work: {
    work_id: string;
    user_id: string;
  };
}

const initialState: IStateMailConfirm = {
  work: { work_id: "", user_id: "" },
};

const set_mail_work_confirm = (payload: {
  work_id: string;
  user_id: string;
}) => ({
  type: TYPES.SET_MAIL_WORK_CONFIRM,
  ...payload,
});

type Actions = ReturnType<typeof set_mail_work_confirm>;

export const MaillConfirmReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TYPES.SET_MAIL_WORK_CONFIRM:
      return {
        ...state,
        work: { work_id: action.work_id, user_id: action.user_id },
      };
    default:
      return state;
  }
};
