import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { Colors } from "@template/style";
import formatDateToString from "@utils/DateFormat";
import { createObjectURL, getUserInfo } from "@utils/index";
import {
  StateFormType,
  getImageArray,
  getInfoFromTemplate,
  insertReportLocal,
  issueType,
  makeNewHtml,
} from "@utils/template/riskyId";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import Dropzone from "components/atoms/Dropzone";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { cloneDeep, debounce, isEqual } from "lodash";
import { getProjectLocationIdStore } from "selector/projectSelector";
import {
  AllReportItem,
  ImportDivision,
  OccurrenceLocation,
  Product,
  ProductStatus,
  SpanMaster,
  User,
} from "services/models";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getNewReportItem } from "sagas/setting";
import { STORAGE, getDataStorage, storeData } from "@utils/Storage";
import { getListUserByLocationId } from "@api/User";
import { getMaxNumberImage, getMaxNumberMachine } from "@api/template";
import { Cancel } from "@mui/icons-material";
import { Validation, isHalfWidthCharacter } from "@validation";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import SelectMultipleOption from "./ModalSelectMultiple";
import { uploadImageToS3 } from "@utils/template";

const originData: StateFormType = {
  risky_id: "",
  place_found_issue: null,
  place_found_issue_other: "",
  incident_date: formatDateToString(new Date(), "YMD"),
  issue_place_floor: null,
  issue_place_span: null,
  is_product_issue: false,
  is_packaging_issue: false,
  responsible_person: null,
  responsible_person_other: "",
  person_found_issue: null,
  person_found_issue_other: "",
  product_type: null,
  product_type_other: "",
  product_name: null,
  amount_of_product: "",
  product_id: [""],
  product_packaging_status: [],
  product_packaging_status_other: "",
  reason_cause_issue: null,
  reason_cause_issue_other: "",
  memo: "",
  is_move_to_KF: false,
  is_move_product: false,
  image_array: [],
  warehouse_place_floor: null,
  warehouse_place_span: null,
  bad_place: "",
  importance_type: null,
  date_shipper_take: "",
  shipper_name: null,
  receiver: "",
  instruction_solve_issue: null,
  instruction_solve_issue_other: "",
  completion_confirm_date: "",
  completion_confirm_person_in_charge: null,
  expense_claim: "",
  translate_product: "",
  translate_packaging: "",
  translate_other: "",
};

const maxNumberImageDefault: number = 12;
const maxNumberProductIdDefault: number = 10;
const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};
const trans_preview: Itrans = {
  ja: {
    product: issueType.product,
    packaging: issueType.packaging,
    other: "その他",
  },
  en: {
    product: "Body",
    packaging: "Carton (Contents uninspected)",
    other: "Others",
  },
  th: {
    product: "ตัวสินค้า",
    packaging: "ตัวกล่องสินค้า",
    other: "อื่นๆ",
  },
};

interface IFormError {
  incident_date: string;
  date_shipper_take: string;
  completion_confirm_date: string;
}

interface IRiskyReport {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: Function;
  isManage?: boolean;
  open: boolean;
}

interface Itrans {
  [key: string]: { product: string; packaging: string; other: string };
}

const RiskyReport = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    isManage = false,
    open,
  }: IRiskyReport,
  ref: React.Ref<unknown>,
) => {
  const [stateForm, setStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [formError, setFormError] = useState<IFormError>({
    incident_date: "",
    date_shipper_take: "",
    completion_confirm_date: "",
  });
  const [maxImage, setMaxImage] = useState<number>(maxNumberImageDefault);
  const [maxProductId, setMaxProductId] = useState<number>(
    maxNumberProductIdDefault,
  );
  const [listUser, setListUser] = useState<Array<any>>([]);
  const [addIsManualListUser, setAddIsManualListUser] = useState<Array<any>>(
    [],
  );
  const [listReportItem, setListReportItem] = useState<{
    listPublisher: Array<any>;
    listProductStatus: Array<any>;
    listAccidentReason: Array<any>;
    listAccidentHandling: Array<any>;
    listOccurrenceLocation: Array<OccurrenceLocation>;
    listSpanMaster: Array<SpanMaster>;
    listImportDivision: Array<ImportDivision>;
    listShipper: Array<any>;
  }>({
    listPublisher: [],
    listProductStatus: [],
    listAccidentReason: [],
    listAccidentHandling: [],
    listOccurrenceLocation: [],
    listSpanMaster: [],
    listImportDivision: [],
    listShipper: [],
  });
  const [completeGetData, setCompleteGetData] = useState<boolean>(false);
  const [isMultipleOption, setIsMultipleOption] = useState(false);
  const [listSelectedTempt, setListSelectedTempt] = useState<
    Array<ProductStatus>
  >([]);
  const location_id = useSelector(getProjectLocationIdStore);
  const user: User = getUserInfo();

  useImperativeHandle(ref, () => ({
    getStateForm: () => {
      // プレビュー用の値を削除
      let cloneStateForm = cloneDeep(stateForm);
      cloneStateForm.image_array.map((item: any) => {
        delete item.base64;
      });
      return cloneStateForm;
    },
    getOriginStateForm: () => originStateForm,
    onSubmit: onSubmit,
  }));

  // マスタデータ取得 =====================================
  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  const addIsManual = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(1, 0, {
      PK: "0",
      SK: "0",
      full_name: "その他(手入力)",
      is_manual_input: true,
      is_vertical_writing_sign: false,
      seal_display_name: "",
      seal_file_path: null,
    });
    return data;
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();

      // マスタの更新チェック
      let resReportItem: AllReportItem = await getNewReportItem(location_id);
      storeData(STORAGE.ALL_REPORT_ITEM, JSON.stringify(resReportItem));

      let listReportItemClone = cloneDeep(listReportItem);

      if (resReportItem?.publisher) {
        listReportItemClone.listPublisher = addWhiteSpace(
          resReportItem.publisher,
        );
      }
      if (resReportItem?.occurrenceLocation) {
        listReportItemClone.listOccurrenceLocation = addWhiteSpace(
          resReportItem.occurrenceLocation,
        );
      }
      if (resReportItem?.spanMaster) {
        listReportItemClone.listSpanMaster = addWhiteSpace(
          resReportItem.spanMaster,
        );
      }
      const resListUser = await getListUserByLocationId();
      if (resListUser?.length) {
        const newListUser = addWhiteSpace(resListUser).concat();
        setListUser(resListUser);
        setAddIsManualListUser(addIsManual(newListUser));
      }
      if (resReportItem?.shipper) {
        listReportItemClone.listShipper = addWhiteSpace(resReportItem.shipper);
      }
      if (resReportItem?.productStatus) {
        listReportItemClone.listProductStatus = addWhiteSpace(
          resReportItem.productStatus,
        );
      }
      if (resReportItem?.accidentReason) {
        listReportItemClone.listAccidentReason = addWhiteSpace(
          resReportItem.accidentReason,
        );
      }
      if (resReportItem?.importDivision) {
        listReportItemClone.listImportDivision = addWhiteSpace(
          resReportItem.importDivision,
        );
      }
      if (resReportItem?.accidentHandling) {
        listReportItemClone.listAccidentHandling = addWhiteSpace(
          resReportItem.accidentHandling,
        );
      }
      setListReportItem(listReportItemClone);
      // 230406 使ってない..?
      // const resAccidentClass = await getListAccidentClass();
      // if (resAccidentClass?.data) {
      //   setListAccidentClass(resAccidentClass.data);
      // }

      let maxProductId = getDataStorage(STORAGE.MAX_PRODUCT_ID);
      if (!maxProductId) {
        const resMaxNumberMachine = await getMaxNumberMachine();
        const maxMachineServer = +resMaxNumberMachine[0]?.max_machine_no_limit;
        if (
          typeof maxMachineServer === "number" &&
          isFinite(maxMachineServer)
        ) {
          setMaxProductId(maxMachineServer);
          storeData(
            STORAGE.MAX_PRODUCT_ID,
            JSON.stringify({ data: maxMachineServer }),
          );
        }
      } else {
        setMaxProductId(Number(JSON.parse(maxProductId).data));
      }

      let maxImageRiskyReport = getDataStorage(STORAGE.MAX_IMAGE_RISKY_REPORT);
      if (!maxImageRiskyReport) {
        const resMaxNumberImage = await getMaxNumberImage();
        const maxImageServer = +resMaxNumberImage[0]?.max_images_limit;
        if (typeof maxImageServer === "number" && isFinite(maxImageServer)) {
          setMaxImage(maxImageServer);
          storeData(
            STORAGE.MAX_IMAGE_RISKY_REPORT,
            JSON.stringify({ data: maxImageServer }),
          );
        }
      } else {
        setMaxImage(Number(JSON.parse(maxImageRiskyReport).data));
      }
      setCompleteGetData(true);
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    getData();
    return () => {
      setCompleteGetData(false);
    };
  }, []);

  useEffect(() => {
    if (open && completeGetData) {
      let newLang: string = WOVN.io.getCurrentLang().code;
      let trans_product = trans_preview[newLang].product;
      let trans_packaging = trans_preview[newLang].packaging;
      let trans_other = trans_preview[newLang].other;
      if (typeof infoToEdit?.memo === "string") {
        let tmpInfoToEdit = cloneDeep(infoToEdit);
        if (!Array.isArray(infoToEdit?.product_packaging_status)) {
          const tmpArr = infoToEdit?.product_packaging_status
            ? [infoToEdit?.product_packaging_status]
            : [];
          tmpInfoToEdit = {
            ...tmpInfoToEdit,
            product_packaging_status: tmpArr,
          };
        }
        tmpInfoToEdit = {
          ...tmpInfoToEdit,
          translate_product: trans_product,
          translate_packaging: trans_packaging,
          translate_other: trans_other,
        };
        setStateForm(tmpInfoToEdit);
        setOriginStateForm(infoToEdit);
      } else {
        const info_edit = {
          ...cloneDeep(originData),
          risky_id: infoToEdit?.risky_id ?? "",
          place_found_issue: setDefaultValue(
            "place_found_issue",
            listReportItem.listPublisher,
          ),
          issue_place_floor: setDefaultValue(
            "issue_place_floor",
            listReportItem.listOccurrenceLocation,
          ),
          issue_place_span: setDefaultValue(
            "issue_place_span",
            listReportItem.listSpanMaster,
          ),
          responsible_person: setDefaultValue(
            "responsible_person",
            listReportItem.listPublisher,
          ),
          person_found_issue: getSelectUserDefault(
            "person_found_issue",
            addIsManualListUser,
          ),
          product_type: setDefaultValue(
            "product_type",
            listReportItem.listShipper,
          ),
          // 複数選択では初期値は使用しない
          // product_packaging_status: setDefaultValue(
          //   "product_packaging_status",
          //   listReportItem.listProductStatus,
          // ),
          reason_cause_issue: setDefaultValue(
            "reason_cause_issue",
            listReportItem.listAccidentReason,
          ),
          warehouse_place_floor: setDefaultValue(
            "warehouse_place_floor",
            listReportItem.listOccurrenceLocation,
          ),
          warehouse_place_span: setDefaultValue(
            "warehouse_place_span",
            listReportItem.listSpanMaster,
          ),
          importance_type: setDefaultValue(
            "importance_type",
            listReportItem.listImportDivision,
          ),
          shipper_name: setDefaultValue("shipper_name", listUser),
          instruction_solve_issue: setDefaultValue(
            "instruction_solve_issue",
            listReportItem.listAccidentHandling,
          ),
          completion_confirm_person_in_charge: setDefaultValue(
            "completion_confirm_person_in_charge",
            listUser,
          ),
          translate_product: trans_product,
          translate_packaging: trans_packaging,
          translate_other: trans_other,
        };

        setStateForm(cloneDeep(info_edit));
        setOriginStateForm(cloneDeep(info_edit));
      }
    }
  }, [open, completeGetData, listReportItem, listUser, addIsManualListUser]);

  const getSelectUserDefault = (
    field: keyof StateFormType,
    listObject: Array<any>,
    SK?: string,
  ) => {
    const default_SK = SK ? SK : user.SK;
    const default_data = listObject.find((item) => default_SK === item?.SK);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  const setDefaultValue = (
    field: keyof StateFormType,
    listObject: Array<any>,
  ) => {
    const default_data = listObject.find((item) => item?.is_default === true);

    if (default_data) {
      return default_data;
    } else {
      return originData[field];
    }
  };

  // HTML取得 =====================================
  const getHtmlString = useCallback(
    async (state_form: StateFormType, html_String: string) => {
      const res = getInfoFromTemplate(html_String);
      const image_array = await getImageArray(state_form.image_array);
      let newHtml: string = makeNewHtml(html_String, state_form);

      newHtml = insertReportLocal(
        newHtml,
        state_form,
        res.imageProductExample,
        res.pageImageExample,
        image_array,
      );
      setHtmlString(newHtml);

      if (!isEqual(image_array, state_form.image_array)) {
        setStateForm({ ...state_form, image_array: image_array });
      }
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString);
  }, [stateForm, htmlString]);

  // 入力制御 =====================================
  const onChangeText =
    (field: string) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setStateForm({ ...stateForm, [field]: e.target.value });
    };

  const onChangeSelect =
    (field: string) => (list: any[], e: SelectChangeEvent<string>) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({ ...prev, [field]: selected }));
    };

  const onChangeDate = (field: string) => (value: string | null) => {
    const mess = Validation.validateDate(value ?? "", "", false);
    setFormError({ ...formError, [field]: mess });

    setStateForm((prev) => ({ ...prev, [field]: value }));
  };

  const onBlurDate = (field: keyof IFormError) => {
    if (formError[field]?.length > 0) {
      setStateForm((prev) => ({ ...prev, [field]: "" }));
    }
  };

  const onChangeTextArray = (index: number) => (newText: string) => {
    let result = "";

    for (const character of newText) {
      if (isHalfWidthCharacter(character) || !isNaN(parseInt(character))) {
        result += character;
      }
    }

    setStateForm((prevState) => {
      const new_product_id = prevState.product_id;
      new_product_id[index] = result;
      return { ...prevState, product_id: new_product_id };
    });
  };

  const onChangeImage = async (
    index: number,
    imageInfo: Type.ImageInfoType,
  ) => {
    const newStateForm: StateFormType = { ...stateForm };
    newStateForm.image_array[index] = imageInfo;
    setStateForm(newStateForm);
  };

  const onChangeBooleanOption = (field: string, value: boolean) => {
    setStateForm((prevState) => {
      return { ...prevState, [field]: value };
    });
  };

  const getPersonFoundIssueOther = useCallback(
    (person_found_issue_other: string) => {
      const otherSelectionItem: any = {
        PK: "0",
        SK: "0",
        full_name: "入力",
        is_manual_input: true,
        is_vertical_writing_sign: false,
        seal_display_name: person_found_issue_other,
        seal_file_path: null,
      };
      return otherSelectionItem;
    },
    [],
  );

  const handleAddNewProductId = () => {
    if (stateForm?.product_id.length < maxProductId)
      setStateForm((prevState) => {
        const new_product_id = [...prevState.product_id, ""];
        return { ...prevState, product_id: new_product_id };
      });
  };

  const handleRemoveProductId = (index: number) => {
    setStateForm((prevState) => {
      const new_product_id = prevState.product_id;
      new_product_id.splice(index, 1);
      return { ...prevState, product_id: new_product_id };
    });
  };

  const renderListProductId = (data: Array<string>) => {
    return data.map((item, index) => (
      <OutlinedInput
        id={"product_id_" + index}
        inputProps={{ maxLength: 30 }}
        value={item}
        sx={{ mb: 1 }}
        fullWidth
        onChange={(e) => {
          onChangeTextArray(index)(e.target.value);
        }}
        endAdornment={
          index > 0 && (
            <IconButton
              onClick={() => {
                handleRemoveProductId(index);
              }}
            >
              <Cancel />
            </IconButton>
          )
        }
        key={index}
      />
    ));
  };

  const handleAddImageItem = () => {
    if (stateForm?.image_array.length < maxImage) {
      setStateForm((prevState) => {
        const new_image_array = [...prevState.image_array, imageDefault];
        return { ...prevState, image_array: new_image_array };
      });
    }
  };

  const handleDeleteImageItem = (index: number) => {
    if (index >= 0) {
      const newStateForm: StateFormType = { ...stateForm };
      newStateForm.image_array.splice(index, 1);
      setStateForm(newStateForm);
    }
  };

  const renderImageItem = (data: Array<Type.ImageInfoType>) => {
    return data.map((item, index) => (
      <CancelBtnBadge
        onClick={() => {
          if (item.uri !== "") {
            onChangeImage(index, imageDefault);
          } else {
            ModalController.show({
              message: "報告内容を削除しますか?",
              visibleButton1: true,
              visibleButton2: true,
              button1: { title: messages.COMMON.BUTTON.CANCEL },
              handlePressButton2: () => handleDeleteImageItem(index),
            });
          }
        }}
        key={index}
        sx={{ width: "100%" }}
        componentsProps={{ badge: { style: { right: "7%" } } }}
      >
        {item.uri !== "" ? (
          <Box
            component="img"
            src={
              item?.type === "image/svg+xml"
                ? item?.non_Local
                  ? item.uri_jpg
                  : item.uriThumb
                : item?.uri
            }
            sx={{
              height: 140,
              width: "100%",
              objectFit: "cover",
              borderRadius: 1,
            }}
          />
        ) : (
          <Box width={"100%"}>
            <Dropzone
              onChoose={(res) => {
                const uri = createObjectURL(res[0]);
                res[0]["uri"] = uri;
                onChangeImage(index, res[0]);
              }}
              maxFiles={1}
              showFileList={false}
              key={index}
            />
          </Box>
        )}
      </CancelBtnBadge>
    ));
  };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              {item ? (
                item[value] !== "0" ? (
                  <span className="IgnoreExtractRuleTarget">{item[name]}</span>
                ) : (
                  // 発見者氏名のその他(手入力)は翻訳
                  <em>{item[name]}</em>
                )
              ) : (
                <em>未選択</em>
              )}
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  const renderProductPackagingStatusChip = useCallback(
    (data: ProductStatus, index: number) => {
      return (
        <ListItem sx={{ p: 0, pt: index == 0 ? 0 : 1 }}>
          <Chip
            label={data.product_status_name ?? ""}
            onDelete={() => {
              let tmp: Array<ProductStatus> = [];
              let product_packaging_status_other =
                stateForm.product_packaging_status_other;
              if (Array.isArray(stateForm.product_packaging_status)) {
                tmp = stateForm.product_packaging_status.filter(
                  (item) => item.SK !== data.SK,
                );
                product_packaging_status_other =
                  stateForm.product_packaging_status.some(
                    (item) => item.is_manual_input && item.SK !== data.SK,
                  )
                    ? stateForm.product_packaging_status_other
                    : "";
              } else {
                if (data.is_manual_input) product_packaging_status_other = "";
              }
              setStateForm((prev) => ({
                ...prev,
                product_packaging_status: tmp,
                product_packaging_status_other: product_packaging_status_other,
              }));
            }}
            className="IgnoreExtractRuleTarget"
          />
        </ListItem>
      );
    },
    [stateForm],
  );

  window.addEventListener("wovnLangChanged", function (evt) {
    // 翻訳切替時にテンプレートの固定文言を翻訳後の言語に切り替える
    var newLang = WOVN.io.getCurrentLang().code;
    setStateForm((prev) => ({
      ...prev,
      translate_product: trans_preview[newLang].product,
      translate_packaging: trans_preview[newLang].packaging,
      translate_other: trans_preview[newLang].other,
    }));
  });

  // 保存 =====================================
  const onSubmit = async () => {
    // 画像アップロード
    let newImageArray: Array<Type.ImageInfoType> = stateForm.image_array ?? [];
    if (stateForm.image_array) {
      newImageArray = await uploadImageToS3(stateForm.image_array);
    }
    let newStateForm = {
      ...stateForm,
      image_array: newImageArray,
    };
    await setStateForm(newStateForm);
  };

  return (
    <Box height={"auto"}>
      <SelectMultipleOption
        isVisible={isMultipleOption}
        closeModal={() => setIsMultipleOption(false)}
        originData={listReportItem.listProductStatus}
        onPressButton={(data: any) => {
          setStateForm((prev) => ({ ...prev, product_packaging_status: data }));
        }}
        listItemSelected={listSelectedTempt}
        setListItemSelected={(item: any) => setListSelectedTempt(item)}
      />
      <Stack>
        <Card>
          <CardHeader title="基本情報" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <TextField
                  label="事故番号"
                  value={stateForm.risky_id}
                  disabled
                />
                <SelectLabel
                  label={
                    <>
                      <LabelRequired title={"発行元"} />
                    </>
                  }
                  value={stateForm.place_found_issue?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("place_found_issue")(
                      listReportItem.listPublisher,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listPublisher,
                    "SK",
                    "publisher_name",
                  )}
                </SelectLabel>
                <TextField
                  label="その他（発行元）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.place_found_issue_other}
                  disabled={
                    !stateForm.place_found_issue?.is_manual_input ?? true
                  }
                  onChange={onChangeText("place_found_issue_other")}
                />
                <DatePickerCustom
                  label={
                    <>
                      <LabelRequired title={"発生年月日"} />
                    </>
                  }
                  value={stateForm.incident_date}
                  onChange={onChangeDate("incident_date")}
                  onBlur={() => onBlurDate("incident_date")}
                />
                <SelectLabel
                  label="発生場所（フロア）"
                  value={stateForm.issue_place_floor?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("issue_place_floor")(
                      listReportItem.listOccurrenceLocation,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listOccurrenceLocation,
                    "SK",
                    "occurrence_location_name",
                  )}
                </SelectLabel>
                <SelectLabel
                  label="発生場所（スパン）"
                  value={stateForm.issue_place_span?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("issue_place_span")(
                      listReportItem.listSpanMaster,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listSpanMaster,
                    "SK",
                    "span_name",
                  )}
                </SelectLabel>
                <FormControl>
                  <LabelRequired title={"事故区分"} />
                  <CheckboxLabel
                    label={issueType.product}
                    checked={stateForm.is_product_issue}
                    onChange={(_, checked) => {
                      onChangeBooleanOption("is_product_issue", checked);
                    }}
                  />
                  <CheckboxLabel
                    label={issueType.packaging}
                    checked={stateForm.is_packaging_issue}
                    onChange={(_, checked) => {
                      onChangeBooleanOption("is_packaging_issue", checked);
                    }}
                  />
                </FormControl>
                <SelectLabel
                  label={
                    <>
                      <LabelRequired title={"事故当事者（事故責任）"} />
                    </>
                  }
                  value={stateForm.responsible_person?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("responsible_person")(
                      listReportItem.listPublisher,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listPublisher,
                    "SK",
                    "publisher_name",
                  )}
                </SelectLabel>
                <TextField
                  label="その他（事故当事者）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.responsible_person_other}
                  disabled={
                    !stateForm.responsible_person?.is_manual_input ?? true
                  }
                  onChange={onChangeText("responsible_person_other")}
                />
                <SelectLabel
                  label={
                    <>
                      <LabelRequired title={"発見者氏名（当事者氏名）"} />
                    </>
                  }
                  value={stateForm.person_found_issue?.SK ?? ""}
                  onChange={(e) => {
                    const selected = addIsManualListUser.find(
                      (item: any) => item && item.SK === e.target.value,
                    );
                    if (selected?.PK == "0") {
                      const otherSelectionItem = getPersonFoundIssueOther(
                        stateForm.person_found_issue_other,
                      );
                      setStateForm({
                        ...stateForm,
                        person_found_issue: otherSelectionItem,
                      });
                    } else {
                      onChangeSelect("person_found_issue")(
                        addIsManualListUser,
                        e,
                      );
                    }
                  }}
                >
                  {renderMenuItem(addIsManualListUser, "SK", "full_name")}
                </SelectLabel>
                <TextField
                  label="その他（発見者氏名）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.person_found_issue_other}
                  disabled={
                    !stateForm.person_found_issue?.is_manual_input ?? true
                  }
                  onChange={(e) => {
                    const person_found_issue = getPersonFoundIssueOther(
                      e.target.value,
                    );
                    setStateForm({
                      ...stateForm,
                      person_found_issue_other: e.target.value,
                      person_found_issue: person_found_issue,
                    });
                  }}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="事故商品" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <SelectLabel
                  label="分類"
                  value={stateForm.product_type?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("product_type")(
                      listReportItem.listShipper,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listShipper,
                    "SK",
                    "shipper_name",
                  )}
                </SelectLabel>
                <TextField
                  label="その他（分類）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.product_type_other}
                  disabled={!stateForm.product_type?.is_manual_input ?? true}
                  onChange={onChangeText("product_type_other")}
                />
                <TextField
                  label={
                    <>
                      <LabelRequired title={"商品名"} />
                    </>
                  }
                  inputProps={{ maxLength: 50 }}
                  value={stateForm.product_name?.product_name ?? ""}
                  onChange={(e) => {
                    const selectionItem: Product = {
                      PK: "0",
                      SK: "0",
                      product_name: e.target.value,
                      is_manual_input: true,
                      jan_code: "",
                    };
                    setStateForm({ ...stateForm, product_name: selectionItem });
                  }}
                />
                <TextField
                  label={
                    <>
                      <LabelRequired title={"数量"} />
                    </>
                  }
                  inputProps={{ maxLength: 10 }}
                  value={stateForm.amount_of_product}
                  onChange={onChangeText("amount_of_product")}
                />
                <Box>
                  <FormLabel>機番</FormLabel>
                  {renderListProductId(stateForm.product_id)}
                </Box>
                <Button
                  onClick={handleAddNewProductId}
                  disabled={stateForm?.product_id.length >= maxProductId}
                >
                  機番追加
                </Button>
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="事故内容" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                {/* <SelectLabel
                  label="荷姿状態"
                  value={stateForm.product_packaging_status?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("product_packaging_status")(
                      listReportItem.listProductStatus,
                      e,
                    );
                  }}
                  required
                >
                  {renderMenuItem(
                    listReportItem.listProductStatus,
                    "SK",
                    "product_status_name",
                  )}
                </SelectLabel> */}
                <FormControl>
                  <LabelRequired title={"荷姿状態"} />
                  {stateForm?.product_packaging_status &&
                    (!Array.isArray(stateForm?.product_packaging_status) ||
                      stateForm.product_packaging_status.length > 0) && (
                      <Card
                        variant="outlined"
                        sx={{ p: 1, m: 0 }}
                        component={"ul"}
                      >
                        {Array.isArray(stateForm?.product_packaging_status)
                          ? stateForm.product_packaging_status?.map((item, i) =>
                              renderProductPackagingStatusChip(item, i),
                            )
                          : renderProductPackagingStatusChip(
                              stateForm.product_packaging_status,
                              0,
                            )}
                      </Card>
                    )}
                </FormControl>
                <Button
                  onClick={() => {
                    setIsMultipleOption(true);
                    if (stateForm.product_packaging_status) {
                      if (Array.isArray(stateForm.product_packaging_status)) {
                        setListSelectedTempt([
                          ...stateForm.product_packaging_status,
                        ]);
                      } else {
                        setListSelectedTempt([
                          stateForm.product_packaging_status,
                        ]);
                      }
                    }
                  }}
                >
                  荷姿状態選択
                </Button>
                <TextField
                  label="その他（荷姿状態）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.product_packaging_status_other}
                  disabled={
                    Array.isArray(stateForm?.product_packaging_status)
                      ? !stateForm?.product_packaging_status.some(
                          (item) => item.is_manual_input == true,
                        )
                      : !stateForm.product_packaging_status?.is_manual_input ??
                        true
                  }
                  onChange={onChangeText("product_packaging_status_other")}
                />
                <SelectLabel
                  label={
                    <>
                      <LabelRequired title={"事故発生理由"} />
                    </>
                  }
                  value={stateForm.reason_cause_issue?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("reason_cause_issue")(
                      listReportItem.listAccidentReason,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listAccidentReason,
                    "SK",
                    "accident_reason_name",
                  )}
                </SelectLabel>
                <TextField
                  label="その他（事故発生理由）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.reason_cause_issue_other}
                  disabled={
                    !stateForm.reason_cause_issue?.is_manual_input ?? true
                  }
                  onChange={onChangeText("reason_cause_issue_other")}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="備考" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <TextField
                  label="備考"
                  name="memo"
                  multiline
                  value={stateForm.memo}
                  rows={3}
                  inputProps={{
                    maxLength: 150,
                  }}
                  placeholder="メモや備考を記入できます（最大150文字）"
                  onChange={(e) => {
                    if (e.target.value.split("\n").length > 3) {
                      return;
                    }
                    onChangeText("memo")(e);
                  }}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="不良個所（写真）" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                {renderImageItem(stateForm.image_array)}
                <Button
                  onClick={handleAddImageItem}
                  disabled={stateForm?.image_array.length >= maxImage}
                >
                  写真を追加
                </Button>
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="ロケ変チェック欄" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <CheckboxLabel
                  label={
                    <>
                      <LabelRequired title={"不良ロケに移動しましたか？"} />
                    </>
                  }
                  labelPlacement="start"
                  checked={stateForm.is_move_to_KF}
                  onChange={(_, checked) => {
                    onChangeBooleanOption("is_move_to_KF", checked);
                  }}
                  formSx={{ justifyContent: "flex-end", m: 0 }}
                />
                <CheckboxLabel
                  label={
                    <>
                      <LabelRequired title={"現物移動しましたか？"} />
                    </>
                  }
                  labelPlacement="start"
                  checked={stateForm.is_move_product}
                  onChange={(_, checked) => {
                    onChangeBooleanOption("is_move_product", checked);
                  }}
                  formSx={{ justifyContent: "flex-end", m: 0 }}
                />
                <SelectLabel
                  label="格納場所（フロア）"
                  value={stateForm.warehouse_place_floor?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("warehouse_place_floor")(
                      listReportItem.listOccurrenceLocation,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listOccurrenceLocation,
                    "SK",
                    "occurrence_location_name",
                  )}
                </SelectLabel>
                <SelectLabel
                  label="格納場所（スパン）"
                  value={stateForm.warehouse_place_span?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("warehouse_place_span")(
                      listReportItem.listSpanMaster,
                      e,
                    );
                  }}
                >
                  {renderMenuItem(
                    listReportItem.listSpanMaster,
                    "SK",
                    "span_name",
                  )}
                </SelectLabel>
                <TextField
                  label="不良ロケ"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.bad_place}
                  onChange={onChangeText("bad_place")}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        {isManage && (
          <Card>
            <CardHeader title="管理欄" sx={styles.header} />
            <CardContent>
              <FormGroup>
                <Stack sx={styles.formStack}>
                  <SelectLabel
                    label="重要度区分"
                    value={stateForm.importance_type?.SK ?? ""}
                    onChange={(e) => {
                      onChangeSelect("importance_type")(
                        listReportItem.listImportDivision,
                        e,
                      );
                    }}
                  >
                    {renderMenuItem(
                      listReportItem.listImportDivision,
                      "SK",
                      "import_division_name",
                    )}
                  </SelectLabel>
                  <DatePickerCustom
                    label="荷主報告日時"
                    value={stateForm.date_shipper_take}
                    onChange={onChangeDate("date_shipper_take")}
                    onBlur={() => onBlurDate("date_shipper_take")}
                  />
                  <SelectLabel
                    label="荷主報告者"
                    value={stateForm.shipper_name?.SK ?? ""}
                    onChange={(e) => {
                      onChangeSelect("shipper_name")(listUser, e);
                    }}
                  >
                    {renderMenuItem(listUser, "SK", "full_name")}
                  </SelectLabel>
                  <TextField
                    label="報告相手"
                    inputProps={{ maxLength: 30 }}
                    value={stateForm.receiver}
                    onChange={onChangeText("receiver")}
                  />
                  <SelectLabel
                    label="事故処理（指示内容）"
                    value={stateForm.instruction_solve_issue?.SK ?? ""}
                    onChange={(e) => {
                      onChangeSelect("instruction_solve_issue")(
                        listReportItem.listAccidentHandling,
                        e,
                      );
                    }}
                  >
                    {renderMenuItem(
                      listReportItem.listAccidentHandling,
                      "SK",
                      "accident_handling_name",
                    )}
                  </SelectLabel>
                  <TextField
                    label="事故処理（備考）"
                    inputProps={{ maxLength: 30 }}
                    value={stateForm.instruction_solve_issue_other}
                    disabled={
                      !stateForm.instruction_solve_issue?.is_manual_input ??
                      true
                    }
                    onChange={onChangeText("instruction_solve_issue_other")}
                  />
                  <DatePickerCustom
                    label="処理完了確認（日付）"
                    value={stateForm.completion_confirm_date}
                    onChange={onChangeDate("completion_confirm_date")}
                    onBlur={() => onBlurDate("completion_confirm_date")}
                  />
                  <SelectLabel
                    label="処理完了確認（担当）"
                    value={
                      stateForm.completion_confirm_person_in_charge?.SK ?? ""
                    }
                    onChange={(e) => {
                      onChangeSelect("completion_confirm_person_in_charge")(
                        listUser,
                        e,
                      );
                    }}
                  >
                    {renderMenuItem(listUser, "SK", "full_name")}
                  </SelectLabel>
                  <TextField
                    label="事故費請求費用"
                    inputProps={{ maxLength: 10 }}
                    value={stateForm.expense_claim}
                    onChange={onChangeText("expense_claim")}
                  />
                </Stack>
              </FormGroup>
            </CardContent>
          </Card>
        )}
      </Stack>
    </Box>
  );
};

export default forwardRef(RiskyReport);

const styles = {
  header: { bgcolor: Colors.MAIN_GREEN_LIGHT },
  formStack: { pb: 0, width: "100%" },
} as const;
