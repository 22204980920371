import { VFC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  TableContainer,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import AvatarRes from "components/atoms/AvatarRes";
import EditMemberDialog from "./EditMemberDialog";
import { ActivityBase, User } from "services/models";
import { USER_ROLES } from "@shared-constants";
import { getUserInfo } from "@utils/index";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { getListActivityBase } from "@api/groupMaster";

interface IProps {
  data: User[];
  setFetchData: Function;
  searchResult?: string;
}

const MemberList: VFC<IProps> = ({
  data,
  setFetchData,
  searchResult = "",
}: IProps) => {
  const [editData, setEditData] = useState<User>({
    SK: "",
    avatar: "",
    full_name: "",
  });
  const [dialog, setDialog] = useState(false);
  const [listLocation, setListLocation] = useState<ActivityBase[]>([]);
  const [fetchListLocation, setFetchListLocation] = useState<boolean>(false);
  const history = useHistoryCustom();
  const userInfo: User = getUserInfo();

  const handleEdit = (user: User) => {
    // const userInfo = getUserInfo();
    if (user.SK === userInfo?.SK) {
      history.pushWithRef("/account");
    } else {
      setEditData(user);
      setDialog(true);
    }
  };

  const getLocationName = (locationId?: String) => {
    let idx = listLocation.findIndex((item: any) => locationId === item.SK);
    return idx >= 0 ? listLocation[idx].activity_base_name : "";
  };

  const getActivityBase = async () => {
    try {
      const res = await getListActivityBase();
      if (res) {
        setListLocation(res);
      }
    } catch (error: any) {
      console.log("error getActivityBase", error);
    } finally {
      setFetchListLocation(true);
    }
  };

  useEffect(() => {
    if (!fetchListLocation) getActivityBase();
  }, [fetchListLocation]);

  return (
    <TableContainer style={{ maxHeight: 400 }}>
      {data.length > 0 ? (
        <Table
          sx={{ minWidth: 890 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <TableCell sx={styles.headerCell} width={250}>
                名前
              </TableCell>
              {/* <<--- TLOG
              <TableCell sx={styles.headerCell} width={120}>
                電話番号
              </TableCell>
              --->> TLOG */}
              <TableCell sx={styles.headerCell} width={250}>
                メールアドレス
              </TableCell>
              <TableCell sx={styles.headerCell} width={130}>
                権限
              </TableCell>
              <TableCell sx={styles.headerCell} width={120}>
                拠点
              </TableCell>
              <TableCell sx={styles.headerCell} width={140}>
                ユーザー作成帳票
              </TableCell>
              <TableCell sx={styles.headerCell} width={140}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user, index) => {
              // --->> TLOG
              if (userInfo.user_role === USER_ROLES.ADMIN.value) {
                if (user.user_role === USER_ROLES.OWNER.value) {
                  return null;
                }
              }
              // <<--- TLOG
              return (
                <TableRow key={user.SK}>
                  <TableCell sx={styles.bodyCell}>
                    <Stack direction="row" sx={{ alignItems: "center" }}>
                      <AvatarRes
                        sx={{ mr: 1 }}
                        src={user.avatar ? user.avatar : undefined}
                      />
                      <span className="IgnoreExtractRuleTarget">
                        {user.full_name}
                      </span>
                    </Stack>
                  </TableCell>
                  {/* <<--- TLOG
                  <TableCell>{user.phone_number}</TableCell>
                  --->> TLOG */}

                  <TableCell sx={styles.bodyCell}>
                    <span className="IgnoreExtractRuleTarget">
                      {user.email}
                    </span>
                  </TableCell>

                  <TableCell sx={styles.bodyCell}>
                    {USER_ROLES[String(user.user_role).toUpperCase()]?.name}
                  </TableCell>
                  <TableCell sx={styles.bodyCell}>
                    <span className="IgnoreExtractRuleTarget">
                      {getLocationName(user.location_id)}
                    </span>
                  </TableCell>
                  <TableCell sx={styles.bodyCell}>
                    <Box style={{ textAlign: "center" }}>
                      {user.can_create_user_report ? "〇" : ""}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{ mx: 1 }}
                      onClick={() => handleEdit(user)}
                      endIcon={<Edit />}
                    >
                      編集
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Typography sx={{ p: 1 }} alignItems="center">
          {searchResult !== "" ? searchResult : "データがありません"}
        </Typography>
      )}
      <EditMemberDialog
        open={dialog}
        setOpen={setDialog}
        data={editData}
        setFetchData={setFetchData}
      />
    </TableContainer>
  );
};

export default MemberList;

const styles = {
  headerCell: { bgcolor: "white" },
  bodyCell: { wordBreak: "break-all" },
} as const;
