import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Stack,
  Collapse,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
  Select,
  MenuItem,
  Box,
  ButtonGroup,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import messages from "config/messages";
import { Colors } from "@template/style";
import {
  CountType,
  DevanModelInfo,
  PalletCount,
} from "@utils/template/inspectionReport";
import { Validation } from "@validation";
import StepperInputLabel from "components/atoms/StepperInputLabel";
import LabelValueText from "components/atoms/LabelValueText";
import StepperInput from "components/atoms/StepperInput";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import { id } from "date-fns/locale";
import ModalController from "@shared-components/modal/ModalController";

interface IProduStatusItemProps {
  item: DevanModelInfo;
  defectList: any[];
  formError: any;
  setItem: (d: DevanModelInfo) => void;
  validation: (field: any, value: string, index?: number) => void;
  setFormError: (value: any) => void;
  unexpected: boolean; //予定外か？
}

type ProductsCountDiv = "good" | "defective";

const ProduStatusItem: React.FC<IProduStatusItemProps> = ({
  item,
  defectList,
  formError,
  setItem,
  validation,
  setFormError,
  unexpected,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [progressColor, setProgressColor] = useState<string>(
    unexpected ? Colors.LIGHT_BLUE : Colors.HEADER_COLOR_GREEN,
  );
  const maxNumberDefault: number = 10;

  const onChangeText = (field: string) => (newText: any) => {
    setItem({ ...item, [field]: newText });
    if (field === "defective_products_number") {
      countValidation(field, newText);
    }
  };

  const onChangeFaults = (field: string, index: number) => (newText: any) => {
    var new_item = { ...item };
    new_item.defective_products[index] = {
      ...new_item.defective_products[index],
      [field]: newText,
    };
    setItem(new_item);
    countValidation(field, "");
  };

  const onChangePallet =
    (div: ProductsCountDiv, field: keyof PalletCount, index: number) =>
    (newText: any) => {
      var new_item = { ...item };
      var devan_field: keyof DevanModelInfo;
      var products_number_field: keyof DevanModelInfo;
      var current_item: PalletCount;
      if (div == "good") {
        current_item = new_item.good_products_pallet[index];
        devan_field = "good_products_pallet";
        products_number_field = "good_products_number";
      } else {
        current_item = new_item.defective_products_pallet[index];
        devan_field = "defective_products_pallet";
        products_number_field = "defective_products_number";
      }

      // 入力値
      current_item[field] = newText;
      // 合計
      if (
        isNumber(current_item["count_pallet"]) &&
        isNumber(current_item["pallet"])
      ) {
        current_item["total"] = (
          Number(current_item["count_pallet"]) * Number(current_item["pallet"])
        ).toString();
      } else {
        current_item["total"] = "0";
      }
      // 総合計
      new_item[devan_field][index] = { ...current_item };
      new_item[products_number_field] = new_item[devan_field]
        .reduce((a, x) => a + Number(x.total), 0)
        .toString();

      setItem(new_item);
      if (div === "defective") {
        countValidation(
          "defective_products_number",
          new_item.defective_products_number,
        );
      }
    };

  const onSelectSwitch = (value: keyof typeof CountType) => {
    if (value == item.count_type) return;
    ModalController.show({
      message: messages.TEMPLATE.MSG_CONFIRM_CHANGE_COUNT_TYPE,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () => {
        setItem({
          ...item,
          count_type: value,
          // 数量をクリア
          good_products_number: item.number_of_moves,
          good_products_pallet: [
            {
              count_pallet: item.number_of_moves,
              pallet: "1",
              total: item.number_of_moves,
            },
          ],
          defective_products_number: "",
          defective_products_pallet: [],
          defective_products: [],
        });
        // エラーをクリア
        var new_error = { ...formError };
        new_error.count_good_products = [];
        new_error.count_defective_products = [];
        new_error.pallet_good_products = [];
        new_error.pallet_defective_products = [];
        new_error.count = [];
        new_error.defective_products_number_count = [];
        setFormError(new_error);
      },
    });
  };

  useEffect(() => {
    let total = "";
    let good_products_number = 0;
    let defective_products_number = 0;
    if (
      item.good_products_number != "" &&
      isNumber(item.good_products_number)
    ) {
      good_products_number = Number(item.good_products_number);
    }
    if (
      item.defective_products_number != "" &&
      isNumber(item.defective_products_number)
    ) {
      defective_products_number = Number(item.defective_products_number);
    }
    total = (good_products_number + defective_products_number).toString();
    setItem({ ...item, total_number: total });
  }, [item.good_products_number, item.defective_products_number]);

  useEffect(() => {
    // 合計数によるヘッダー色変更
    const total = Number(item.total_number);
    if (!unexpected && (!total || total == 0)) {
      setProgressColor(Colors.HEADER_COLOR_GREEN);
    } else if (unexpected || item.total_number === item.number_of_moves) {
      setProgressColor(Colors.LIGHT_BLUE);
    } else {
      setProgressColor(Colors.LIGHT_RED);
    }
  }, [item.total_number, item.number_of_moves]);

  const ProgressColorText = useMemo(() => {
    return progressColor == Colors.HEADER_COLOR_GREEN ? undefined : "white";
  }, [progressColor]);

  const existsSameValue = (arr: Array<string>) => {
    var s = new Set(arr);
    return s.size != arr.length;
  };

  const SelectValidation = () => {
    if (!formError) return;
    let is_count_select = false;
    let arr: any[] = [];
    if (item.defective_products.length > 1) {
      item.defective_products.map((i) => {
        if (i.SK) arr.push(i.SK);
      });
      is_count_select = existsSameValue(arr);
    }
    let formErrorTmp = cloneDeep(formError);
    formErrorTmp.count_select = is_count_select
      ? [messages.TEMPLATE.MSG_IDENTICAL_DEFECTIVE_PRODUCTS]
      : [];
    setFormError(formErrorTmp);
  };

  const handleRemoveProductId = (index: number) => {
    var new_item = { ...item };
    new_item.defective_products.splice(index, 1);
    setItem(new_item);

    var new_error = { ...formError };
    new_error.count?.splice(index, 1);
    setFormError(new_error);
    countValidation("count", "");
  };

  const handleRemovePalletProduct = (index: number, div: ProductsCountDiv) => {
    var new_item = { ...item };
    var devan_field: keyof DevanModelInfo;
    var products_number_field: keyof DevanModelInfo;
    var error_field_count, error_field_pallet: string;
    if (div == "good") {
      devan_field = "good_products_pallet";
      products_number_field = "good_products_number";
      error_field_count = "count_good_products";
      error_field_pallet = "pallet_good_products";
    } else {
      devan_field = "defective_products_pallet";
      products_number_field = "defective_products_number";
      error_field_count = "count_defective_products";
      error_field_pallet = "pallet_defective_products";
    }
    new_item[devan_field].splice(index, 1);
    new_item[products_number_field] = new_item[devan_field]
      .reduce((a: any, x: any) => a + Number(x.total), 0)
      .toString();
    setItem(new_item);

    var new_error = { ...formError };
    new_error[error_field_count]?.splice(index, 1);
    new_error[error_field_pallet]?.splice(index, 1);
    setFormError(new_error);
    if (div === "defective") {
      countValidation(
        "defective_products_number",
        new_item.defective_products_number,
      );
    }
  };

  useEffect(() => {
    SelectValidation();
  }, [item.defective_products.length]);

  const countValidation = (key: string, value: string) => {
    let defective_products_number_count = 0;
    let defective_products_number =
      key == "defective_products_number"
        ? value
        : item.defective_products_number;
    if (
      defective_products_number != "" &&
      isNumber(defective_products_number)
    ) {
      defective_products_number_count = Number(defective_products_number);
    }

    let defective_products_count = 0;
    item.defective_products.map((i) => {
      if (i.count != "" && isNumber(i.count))
        defective_products_count += Number(i.count);
    });
    let formErrorTmp = cloneDeep(formError);
    formErrorTmp.defective_products_number_count =
      defective_products_count != defective_products_number_count
        ? [messages.TEMPLATE.MSG_DEFECTIVE_PRODUCT_MISMATCH]
        : [];
    setFormError(formErrorTmp);
  };

  const isNumber = (value: string) => {
    const error = Validation.validate({
      type: "number",
      value: value,
      name: "dummy",
    });
    return error.length > 0 ? false : true;
  };

  const handleAddNewProductId = () => {
    if (item?.defective_products.length < maxNumberDefault) {
      var init = { SK: "", defect: "", count: "0" };
      var new_item = { ...item };
      new_item.defective_products.push(init);
      setItem(new_item);

      var new_error = { ...formError };
      new_error.count?.push("");
      setFormError(new_error);
    }
  };

  const handleAddNewGoodProduct = () => {
    var init = { count_pallet: "0", pallet: "0", total: "0" };
    var new_item = { ...item };
    new_item.good_products_pallet.push(init);
    setItem(new_item);

    var new_error = { ...formError };
    new_error.count_good_products?.push("");
    new_error.pallet_good_products?.push("");
    setFormError(new_error);
  };

  const handleAddNewDefectiveProduct = () => {
    var init = { count_pallet: "0", pallet: "0", total: "0" };
    var new_item = { ...item };
    new_item.defective_products_pallet.push(init);
    setItem(new_item);

    var new_error = { ...formError };
    new_error.count_defective_products?.push("");
    new_error.pallet_defective_products?.push("");
    setFormError(new_error);
  };

  const renderDefectiveList = (data: Array<any>, defectList: Array<any>) => {
    return data.map((item_defect, index) => (
      <CancelBtnBadge
        onClick={() => {
          handleRemoveProductId(index);
        }}
        sx={{
          "& .MuiBadge-badge": {
            top: 0,
            right: 5,
          },
        }}
        key={index}
      >
        <Paper
          variant="outlined"
          sx={{ width: "100%", bgcolor: Colors.BG_GRAY, py: 1, px: 1.5 }}
        >
          <FormControl fullWidth>
            <Select
              value={item_defect.SK}
              onChange={(e) => {
                const SK = e.target.value;
                const selectedItem = defectList.find((value) => value.SK == SK);
                if (selectedItem) {
                  var new_item = { ...item };
                  new_item.defective_products[index] = {
                    ...new_item.defective_products[index],
                    SK: selectedItem.SK,
                    defect: selectedItem.container_defect_detail_name,
                  };
                  setItem(new_item);
                  SelectValidation();
                }
              }}
            >
              {defectList.map((value, index) => (
                <MenuItem value={value.SK} key={index}>
                  {value.container_defect_detail_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 1, display: "flex", justifyContent: "flex-end" }}>
            <StepperInput
              value={item_defect.count}
              onChange={onChangeFaults("count", index)}
              onBlur={() => validation("count", item_defect.count, index)}
              error={
                formError?.count?.length > index &&
                formError.count[index]?.length > 0
              }
              maxValue={9999999}
            />
          </Box>
          {formError?.count?.length > index && (
            <FormHelperText error sx={{ mx: 0 }}>
              {formError?.count[index] ?? ""}
            </FormHelperText>
          )}
        </Paper>
      </CancelBtnBadge>
    ));
  };

  const renderListPalletProducts = (
    data: PalletCount[],
    div: ProductsCountDiv,
  ) => {
    return data.map((item_pallet, index) => (
      <CancelBtnBadge
        onClick={() => {
          handleRemovePalletProduct(index, div);
        }}
        sx={{ "& .MuiBadge-badge": { top: 0, right: 5 } }}
        key={index}
      >
        <Paper
          variant="outlined"
          sx={{
            width: "100%",
            bgcolor: Colors.BG_GRAY,
            py: 1,
            px: 0.5,
          }}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            alignItems={"center"}
            flexWrap={"wrap"}
            spacing={0}
            sx={{ gap: 0.5 }}
          >
            <FormControl>
              <InputLabel sx={{ maxWidth: "100%", textAlign: "center" }}>
                数量
              </InputLabel>
              <StepperInput
                value={item_pallet.count_pallet}
                onChange={onChangePallet(div, "count_pallet", index)}
                onBlur={() =>
                  validation(
                    `count_${div}_products`,
                    item_pallet.count_pallet,
                    index,
                  )
                }
                textFieldWidth={85}
                error={
                  formError[`count_${div}_products`]?.length > 0 &&
                  formError[`count_${div}_products`][index]?.length > 0
                }
                maxValue={999999}
              />
            </FormControl>
            <Box>
              <Box style={{ height: 25 }} />
              <Typography>×</Typography>
            </Box>
            <FormControl>
              <InputLabel sx={{ maxWidth: "100%", textAlign: "center" }}>
                パレット/箱数
              </InputLabel>
              <StepperInput
                value={item_pallet.pallet}
                onChange={onChangePallet(div, "pallet", index)}
                onBlur={() =>
                  validation(
                    `pallet_${div}_products`,
                    item_pallet.pallet,
                    index,
                  )
                }
                textFieldWidth={55}
                error={
                  formError[`pallet_${div}_products`]?.length > 0 &&
                  formError[`pallet_${div}_products`][index]?.length > 0
                }
                maxValue={999}
              />
            </FormControl>
          </Stack>
          <Typography sx={{ mt: 1 }}>合計：{item_pallet.total}</Typography>
          {formError[`count_${div}_products`]?.length > index && (
            <FormHelperText error sx={{ mx: 0 }}>
              {formError[`count_${div}_products`][index] ?? ""}
            </FormHelperText>
          )}
          {formError[`pallet_${div}_products`]?.length > index && (
            <FormHelperText error sx={{ mx: 0 }}>
              {formError[`pallet_${div}_products`][index] ?? ""}
            </FormHelperText>
          )}
        </Paper>
      </CancelBtnBadge>
    ));
  };

  const renderUnitCount = () => {
    return (
      <>
        <StepperInputLabel
          label="良品数"
          value={item.good_products_number}
          onChange={onChangeText("good_products_number")}
          onBlur={() =>
            validation("good_products_number", item.good_products_number)
          }
          error={good_products_number_error_message.length > 0}
          helperText={good_products_number_error_message}
          maxValue={9999999}
        />
        <StepperInputLabel
          label="不適合数"
          value={item.defective_products_number}
          onChange={onChangeText("defective_products_number")}
          onBlur={() =>
            validation(
              "defective_products_number",
              item.defective_products_number,
            )
          }
          error={defective_products_number_error_message.length > 0}
          helperText={defective_products_number_error_message}
          maxValue={9999999}
        />
        <LabelValueText label="合計" value={item?.total_number ?? "0"} />
        <FormControl>
          <InputLabel>不適合内容</InputLabel>
          <Stack>
            {renderDefectiveList(item.defective_products, defectList)}
            <Button onClick={handleAddNewProductId}>不適合内容を追加</Button>
          </Stack>
        </FormControl>
      </>
    );
  };

  const renderPalletCount = () => {
    return (
      <>
        <FormControl>
          <InputLabel sx={styles.palletCountTitle}>
            良品数：{item.good_products_number}
          </InputLabel>
          <Stack>
            {renderListPalletProducts(item.good_products_pallet, "good")}
            <Button onClick={handleAddNewGoodProduct}>良品数登録を追加</Button>
          </Stack>
        </FormControl>
        <FormControl>
          <InputLabel sx={styles.palletCountTitle}>
            不適合数：{item.defective_products_number}
          </InputLabel>
          <Stack>
            {renderListPalletProducts(
              item.defective_products_pallet,
              "defective",
            )}
            <Button onClick={handleAddNewDefectiveProduct}>
              不適合数登録を追加
            </Button>
          </Stack>
        </FormControl>
        <FormControl>
          <InputLabel sx={styles.palletCountTitle}>不適合内容</InputLabel>
          <Stack>
            {renderDefectiveList(item.defective_products, defectList)}
            <Button onClick={handleAddNewProductId}>不適合内容を追加</Button>
          </Stack>
        </FormControl>
      </>
    );
  };

  const good_products_number_error_message = useMemo(() => {
    return formError?.good_products_number?.length > 0
      ? formError?.good_products_number[0]
      : "";
  }, [formError]);

  const defective_products_number_error_message = useMemo(() => {
    return formError?.defective_products_number?.length > 0
      ? formError?.defective_products_number[0]
      : "";
  }, [formError]);

  const defective_count_error_message = useMemo(() => {
    return formError?.defective_products_number_count?.length > 0
      ? formError?.defective_products_number_count[0]
      : "";
  }, [formError]);

  const count_select_error_message = useMemo(() => {
    return formError?.count_select?.length > 0
      ? formError?.count_select[0]
      : "";
  }, [formError]);

  return (
    <Card variant="outlined">
      {/* =================== ヘッダ =================== */}
      <CardHeader
        title={
          <Box
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h6"
              sx={{ wordBreak: "break-all", fontWeight: "bold" }}
              color={ProgressColorText}
            >
              {item.model_name}
            </Typography>
            {show ? (
              <ExpandLess sx={{ color: ProgressColorText }} />
            ) : (
              <ExpandMore sx={{ color: ProgressColorText }} />
            )}
          </Box>
        }
        titleTypographyProps={{
          sx: {
            pb: 0.5,
          },
        }}
        subheader={
          <Box
            flexDirection="row"
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            {unexpected ? (
              <>
                <Typography variant="subtitle1" color={ProgressColorText}>
                  実績数(※予定外)
                </Typography>
                <Typography variant="subtitle1" color={ProgressColorText}>
                  {item.total_number ? item.total_number : 0}
                </Typography>
              </>
            ) : (
              <>
            <Typography variant="subtitle1" color={ProgressColorText}>
              実績数/予定数
            </Typography>
            <Typography variant="subtitle1" color={ProgressColorText}>
                  {item.total_number ? item.total_number : 0}/
                  {item.number_of_moves}
            </Typography>
              </>
            )}
          </Box>
        }
        subheaderTypographyProps={{
          sx: {
            borderTop: "solid 1px ",
            borderTopColor: ProgressColorText,
            pt: 0.5,
          },
        }}
        sx={{
          bgcolor: progressColor,
          cursor: "pointer",
          border: unexpected ? "2px solid" : "none",
          borderColor: Colors.REQUIRE,
          borderRadius: "5px",
        }}
        onClick={() => {
          setShow(!show);
        }}
      />
      {/* =================== 内容 =================== */}
      <Collapse in={show} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack>
            <ButtonGroup>
              <Button
                variant={
                  item.count_type === CountType.UNIT ||
                  item.count_type === undefined
                    ? "contained"
                    : "outlined"
                }
                onClick={() => onSelectSwitch("UNIT")}
                sx={{ width: "45%" }}
              >
                バラカウント
              </Button>
              <Button
                variant={
                  item.count_type === CountType.PALLET
                    ? "contained"
                    : "outlined"
                }
                onClick={() => onSelectSwitch("PALLET")}
                sx={{ width: "55%" }}
              >
                パレット/箱カウント
              </Button>
            </ButtonGroup>
            <TextField
              label="数量カウント"
              value={item.count}
              multiline
              rows={3}
              inputProps={{ maxLength: 40 }}
              onChange={(e) => {
                if (e.target.value.split("\n").length > 3) {
                  return;
                }
                onChangeText("count")(e.target.value);
              }}
            />
            {item.count_type == CountType.UNIT || item.count_type == undefined
              ? renderUnitCount()
              : item.count_type == CountType.PALLET
              ? renderPalletCount()
              : null}
            <TextField
              label="備考欄"
              value={item.memo}
              multiline
              rows={5}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                onChangeText("memo")(e.target.value);
              }}
            />
            {/* =================== エラーメッセージ =================== */}
            {(defective_count_error_message.length > 0 ||
              count_select_error_message.length > 0) && (
              <Box>
                {defective_count_error_message.length > 0 && (
                  <FormHelperText error>
                    {defective_count_error_message}
                  </FormHelperText>
                )}
                {count_select_error_message.length > 0 && (
                  <FormHelperText error>
                    {count_select_error_message}
                  </FormHelperText>
                )}
              </Box>
            )}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ProduStatusItem;

const styles = {
  palletCountTitle: {
    maxWidth: "calc(100% + 32px)",
    bgcolor: Colors.YELLOW,
    marginX: -2,
    paddingX: 2,
    mb: 2,
  },
} as const;
