import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import AvatarRes from "components/atoms/AvatarRes";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import React, { useCallback, useEffect, useState } from "react";

interface Imultiple {
  id: string;
  name: string;
}

interface ISelectDialog {
  open: boolean;
  data: Array<any>;
  selectedData: Array<Imultiple>;
  nameAttribute: string;
  onClose: Function;
  onChoose: Function;
}

const SelectDialog = ({
  open,
  data,
  selectedData,
  nameAttribute,
  onClose,
  onChoose,
}: ISelectDialog) => {
  const [selectedItem, setSelectedItem] = useState<Imultiple[]>([]);

  useEffect(() => {
    if (open) {
      setSelectedItem(selectedData);
    }
  }, [open, selectedData]);

  const onchange = useCallback(
    (checked: boolean, id: string, name: string) => {
      let cloneSelectedItem = [...selectedItem];
      if (checked) {
        cloneSelectedItem.push({
          id: id,
          name: name,
        });
      } else {
        cloneSelectedItem.splice(
          cloneSelectedItem.findIndex((item) => item.id === id),
          1,
        );
      }
      setSelectedItem(cloneSelectedItem);
    },
    [selectedItem],
  );

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
      onClose={() => onClose()}
    >
      <DialogTitle>選択して下さい</DialogTitle>
      <DialogContent>
        <Box display={"flex"} flexDirection={"column"}>
          {data.map((item, index) => {
            return (
              <React.Fragment key={index}>
              <>
                <CheckboxLabel
                  label={
                    <Box display={"flex"} flexDirection={"row"}>
                      {item?.avatar !== undefined && (
                        <AvatarRes
                          src={item?.avatar}
                          size={"sm"}
                          sx={{ mr: 1 }}
                        />
                      )}
                      <Typography>{item[nameAttribute]}</Typography>
                    </Box>
                  }
                  checked={selectedItem.some((d) => d.id === item.SK)}
                  onChange={(_, checked) =>
                    onchange(checked, item.SK, item[nameAttribute])
                  }
                  key={index}
                />
                <Divider />
              </>
              </React.Fragment>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            onChoose(selectedItem);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectDialog;
