import { TYPES } from "store/types";

export type contract = {
  is_contract_logged_in: boolean;
};

const initialState: contract = {
  is_contract_logged_in: false,
};

export const contractReducer = (
  state = initialState,
  { payload, type }: any,
) => {
  switch (type) {
    case TYPES.SET_CONTRACT_INFO: {
      return { ...state, ...payload };
    }
    case TYPES.CLEAR_CONTRACT_INFO: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
