import { getS3UploadUrl } from "@api/template";
import formatDateToString from "@utils/DateFormat";
import { Validation } from "@validation";
import { decodeBase64toArrayBuffer } from "..";

export function chunkArray(myArray: Array<any>, chunk_size: number) {
  let index: number = 0;
  const arrayLength: number = myArray.length;
  let tempArray: Array<any> = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
}

export const createHtmlForMultipleText = (text: string) => {
  const text_array: Array<string> = text.split(/\r?\n/);
  return text_array.join(`
    <br/>
  `);
};

export const createDateText = (date: string) => {
  if (date) {
    if (Validation.validateDate(date, "", false).length > 0) return "";

    return formatDateToString(date, "YMD_sl");
  } else return "";
};

export const uploadImageToS3 = async (
  imageArray: Array<Type.ImageInfoType>,
  startTime: number = 0,
) => {
  let newImageArray: Array<Type.ImageInfoType> = imageArray;
  try {
    const delay = 300;
    var timeout = 0;
    for (const imageItem of imageArray) {
      if (imageItem?.uri?.startsWith("http")) {
        continue;
      } else if (imageItem?.uri) {
        const imagePath: any = imageItem;
        const formData = new FormData();
        formData.append("object_type", "PROJECT");
        // formData.append("is_image", "true");
        if (imagePath.xmlSvg) {
          // SVGのxmlからFileを作成
          const svg = new Blob([imagePath.xmlSvg], { type: "image/svg+xml" });
          var file = new File([svg], imagePath.name, { type: "image/svg+xml" });
          delete imageItem["xmlSvg"];
          formData.append("file", file);
        } else {
          formData.append("file", imagePath);
        }
        // const data = await uploadImageForTemplate(formData);
        timeout = delay - (Date.now() - startTime);
        if (timeout > 0)
          await new Promise((resolve) => setTimeout(resolve, timeout));
        const response = await getS3UploadUrl(formData);
        startTime = Date.now();
        const url = response.url;
        const base64 = response.file;
        const data = response.data;
        const arrayBuffer = decodeBase64toArrayBuffer(base64);
        // upload image
        await fetch(url, {
          method: "PUT",
          body: arrayBuffer,
          headers: {
            "Content-Type": "image/jpeg",
          },
        });
        if (data?.link_url_file) {
          newImageArray.map((itemArr, indexArr) => {
            if (itemArr?.uri == imageItem?.uri) {
              newImageArray[indexArr] = {
                ...imageItem,
                path_file: data?.path_file,
                path_file_jpg: data?.path_file_jpg ?? "",
                uri: data?.link_url_file,
                uri_jpg: data?.link_url_file_jpg,
                non_Local: true,
              };
            }
          });
        }
      } else {
        continue;
      }
    }
  } catch (err) {
    throw new Error("Fail to upload the image");
  }
  return newImageArray;
};
