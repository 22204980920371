import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import AvatarRes from "components/atoms/AvatarRes";
import messages from "config/messages";
import { getListUserCompanyApi, getUserByProjectApi } from "@api/project";
import { User } from "services/models";
import { getUserId, getUserInfo } from "@utils/index";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { USER_ROLES } from "@shared-constants";
import { RootState } from "store/reducer";
import FullScreenDialog from "components/atoms/FullScreenDialog";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";

interface IProps {
  visibleUser: boolean;
  setVisibleUser: Function;
  onBack: Function;
  onUpdateUser: Function;
  idAdmin?: string;
  selected: User[];
  setSelected: Function;
  ignore_me?: boolean;
  isCheckMultiple?: boolean;
  usersDefault?: User[];
  usersSelectedFilter?: User[];
  locationId?: string; //TLOG
}

const ModalSearchUser = ({
  visibleUser,
  setVisibleUser,
  onBack,
  onUpdateUser,
  idAdmin,
  selected,
  setSelected,
  ignore_me = true,
  isCheckMultiple = false,
  usersDefault = [],
  usersSelectedFilter = [],
  locationId = "", //TLOG
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [userProfile, setUserProfile] = useState<User>();
  const [listUsers, setListUsers] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const project_id = useSelector(
    (state: RootState) => state.projectInfo.selected_project_id,
  );

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchData = useCallback(
    async (projectId: any, keyword?: string) => {
      setLoading(true);
      LoadingOverlayController.show();
      if (!keyword) keyword = "";
      const params = {
        keyword: keyword,
        ignore_me: userProfile?.user_role === USER_ROLES.MEMBER.value,
        location_id: locationId, //TLOG
      };
      try {
        let res: any;
        if (!ignore_me) {
          let paramsPj = {
            keyword: keyword,
          };
          res = await getUserByProjectApi({
            project_id: projectId,
            params: paramsPj,
          });
        } else {
          res = await getListUserCompanyApi(params);
        }
        if (idAdmin != null) {
          setListUsers(res?.data?.filter((item: User) => item.SK !== idAdmin));
        } else {
          setListUsers(res?.data);
        }
      } catch (error) {
        console.log("error fetchData list user chat", error);
      }
      setLoading(false);
      LoadingOverlayController.hide();
    },
    [idAdmin, ignore_me, userProfile],
  );

  useEffect(() => {
    if (visibleUser) {
      const userInfo: User = getUserInfo();
      setUserProfile(userInfo);
      fetchData(project_id);
    }
  }, [visibleUser]);

  const filterUsersNotAccept = (arr: Array<User>) => {
    return arr.filter((item: User) => {
      return (
        usersDefault.findIndex(
          (user) => getUserId(item) === getUserId(user),
        ) === -1
      );
    });
  };

  // ------------------------------------------------------------------
  // チェック
  // ------------------------------------------------------------------
  const handleCheckedUser = useCallback(
    (item: User) => {
      let idx = selected.findIndex((userItem: User) => userItem.SK === item.SK);
      let updateSelected;
      if (idx !== -1) {
        updateSelected = selected.filter(
          (userItem: User) => userItem?.SK !== item?.SK,
        );
      } else {
        const itemAdd = {
          SK: item.SK,
          full_name: item.full_name,
          avatar: item.avatar,
          user_role: item.user_role,
        };
        isCheckMultiple
          ? (updateSelected = [itemAdd])
          : (updateSelected = [...selected, itemAdd]);
      }

      setSelected(updateSelected);
    },
    [selected, isCheckMultiple, setSelected],
  );

  // ------------------------------------------------------------------
  // アバター
  // ------------------------------------------------------------------
  const renderSelectedUser = useCallback(
    (user: User, index: number) => {
      if (user?.SK === idAdmin) {
        return null;
      }

      return (
        <Stack width={80} spacing={1} key={index}>
          <CancelBtnBadge
            onClick={() => {
              handleCheckedUser(user);
            }}
            sx={{ width: 60, alignSelf: "center" }}
          >
            <AvatarRes src={user.avatar ? user.avatar : undefined} size={60} />
          </CancelBtnBadge>
          <Typography fontSize={12} noWrap textAlign="center">
            {user?.full_name}
          </Typography>
        </Stack>
      );
    },
    [usersSelectedFilter, idAdmin],
  );

  // ------------------------------------------------------------------
  // 一覧
  // ------------------------------------------------------------------
  const renderUserList = useCallback(
    (user: User, index: number) => {
      let idx = selected.findIndex((userItem: User) => userItem.SK === user.SK);
      return (
        <Box key={index}>
          <Stack
            direction="row"
            sx={{
              px: 1,
              py: index === 0 ? 2 : 1,
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleCheckedUser(user)}
          >
            <AvatarRes src={user.avatar ? user.avatar : undefined} size="md" />
            <Typography
              sx={{ flexGrow: 1 }}
              className="IgnoreExtractRuleTarget"
            >
              {user.full_name}
            </Typography>
            <Checkbox
              onClick={() => handleCheckedUser(user)}
              checked={idx !== -1}
            />
          </Stack>
          <Divider />
        </Box>
      );
    },
    [selected],
  );

  // ------------------------------------------------------------------
  // 閉じる
  // ------------------------------------------------------------------
  const handleClose = () => {
    setVisibleUser(false);
  };

  return (
    <FullScreenDialog
      open={visibleUser}
      onClose={handleClose}
      title={"担当者を選択"}
      isSearch={true}
      searchPlaceholder={"担当者名で検索"}
      onSearch={(v: string) => {
        setKeyword(v.trim());
        fetchData(project_id, v.trim());
      }}
      onSearchClear={() => {
        setKeyword("");
        fetchData(project_id, "");
      }}
    >
      <Card>
        {/* =================== アバター =================== */}
        <CardContent sx={{ overflowX: "auto" }}>
          <Stack direction="row">
            {usersSelectedFilter.map((user, index) =>
              renderSelectedUser(user, index),
            )}
          </Stack>
        </CardContent>

        {/* =================== 一覧 =================== */}
        <CardContent sx={{ height: "50vh", overflowY: "auto" }}>
          {filterUsersNotAccept(listUsers).map((user, index) =>
            renderUserList(user, index),
          )}
          {!loading &&
            (!filterUsersNotAccept(listUsers) ||
              filterUsersNotAccept(listUsers).length === 0) && (
              <Typography>
                {keyword.trim()
                  ? messages.COMMON.MSG_NON_SEARCH_RESULT(keyword.trim())
                  : messages.ACCOUNT.MSG_NON_VALID_ACCOUNT}
              </Typography>
            )}
        </CardContent>

        {/* =================== ボタン =================== */}
        <CardContent>
          <Stack direction="row" sx={{ justifyContent: "center" }}>
            <Button
              variant="outlined"
              onClick={() => {
                onBack();
                handleClose();
              }}
            >
              キャンセル
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                onUpdateUser();
                handleClose();
              }}
            >
              確定
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </FullScreenDialog>
  );
};

export default ModalSearchUser;
