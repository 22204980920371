export const weatherOptions = [
  { value: "晴れ", SK: 1 },
  { value: "雨", SK: 2 },
  { value: "曇り", SK: 3 },
  { value: "雪", SK: 4 },
];

export const anshinCallOptions = [
  { value: "済", SK: 1 },
  { value: "不通", SK: 2 },
];

export const refuelingFeeTypeOptions = [
  { value: "無料", SK: 1 },
  { value: "有料 ", SK: 2 },
];

export const oilTypeOptions = [
  { value: "レギュラー", SK: 1 },
  { value: "ハイオク ", SK: 2 },
  { value: "軽油  ", SK: 3 },
];

export const signerAttributeOptions = [
  { value: "本人", SK: 1 },
  { value: "代理人", SK: 2 },
];

export const listMasterItem = [
  {
    type: "WRECKER_REQUEST_FOR",
    reducerKey: "listRequestFor",
    stateFormKey: ["request"],
  },
  {
    type: "WRECKER_CITY_NAME",
    reducerKey: "listCityName",
    stateFormKey: ["location", "transport_destination"],
  },
  {
    type: "WRECKER_CAR_NUMBER_PLACE",
    reducerKey: "listCarNumberPlace",
    stateFormKey: ["registration_number_place"],
  },
  {
    type: "WRECKER_TYPE_OF_WORK",
    reducerKey: "listTypeOfWork",
    stateFormKey: ["work_content"],
  },
  {
    type: "WRECKER_DISPATCH_VEHICLE",
    reducerKey: "listDispatchVehicle",
    stateFormKey: ["dispatch_vehicle", "secondary_dispatch_vehicle"],
  },
  {
    type: "WRECKER_TRANSPORT_WORK",
    reducerKey: "listTransportWork",
  },
  {
    type: "WRECKER_DRIVING_ON_SHOULDER",
    reducerKey: "listDrivingOnShoulder",
    stateFormKey: ["running_off_shoulder"],
  },
  {
    type: "WRECKER_HIGHWAY_TEAM",
    reducerKey: "listHighTeam",
    stateFormKey: ["high_speed_troop"],
  },
  {
    type: "WRECKER_REQUEST_LINE",
    reducerKey: "listRequestLine",
    stateFormKey: ["line"],
  },
  {
    type: "WRECKER_TOWING_SHOP",
    reducerKey: "listTowingShop",
    stateFormKey: ["towing_shop"],
  },
  {
    type: "WRECKER_STORE_CAR",
    reducerKey: "listStoreCar",
    stateFormKey: ["vehicle_custody_store"],
  },
];
