import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import _ from "lodash";
import { Colors } from "@template/style";
import LabelValueText from "components/atoms/LabelValueText";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";

import {
  ActivityBase,
  GroupMaster,
  GroupInfo,
  ResponseOriginGroup,
  Template,
} from "services/models";
import {
  GetListGroupByProjectID,
  getListActivityBase,
  getListGroupMaster,
} from "@api/groupMaster";
import GroupSelectDialog from "screens/GroupSelectDialog";
import messages from "config/messages";
import { getGroupSelectedData } from "@utils/index";

export type ISelectedData = { [key: string]: string };

export interface IGroupEditData {
  PK: string;
  SK: string;
  group_parentid?: string;
  groupName: string;
  isCreateNew?: boolean;
  index: number;
  groupNameAll?: string;
  selectedData?: ISelectedData;
}

interface IStateForm {
  project_id?: string;
  project_name: string;
  report_id: string;
  project_location_id: string;
  project_start_date: string;
  project_end_date: string;
  template: null | Template;
  memo: string;
  report_type_code: string;
  report_name: string;
  report_number: string;
  report_type_id: string;
  is_daily: boolean;
  group_info: IGroupEditData[];
}

interface IActivityBase extends ActivityBase {
  selectedData?: ISelectedData;
}

type TypeGroupInfoOption = {
  stateForm: IStateForm;
  setStateForm: Function;
  allowEditTopLocation?: boolean;
  allowEditGroup?: boolean;
  disabled?: boolean;
  showErrorMessage?: boolean;
  handleChangeLevel1?: Function;
};

const ProjectGroupInfoOption = (
  {
    stateForm,
    setStateForm,
    allowEditTopLocation = true,
    allowEditGroup = true,
    disabled = false,
    showErrorMessage = true,
    handleChangeLevel1 = () => undefined,
  }: TypeGroupInfoOption,
  ref: any,
) => {
  // 初期化 ------------------------------------------------
  const [listGM, setListGM] = useState<GroupMaster[]>([]);
  const [listActivityBase, setListActivityBase] = useState<IActivityBase[]>([]);
  const [selectedOption, setSelectedOption] = useState<IGroupEditData[]>([]);
  const [originOption, setOriginOption] = useState<IGroupEditData[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [initialGroup, setInitialGroup] = useState<ISelectedData>();
  const [dataChange, setDataChange] = useState<{
    add: IGroupEditData[];
    remove: IGroupEditData[];
    edit: IGroupEditData[];
  }>({ add: [], remove: [], edit: [] });
  const [editIndex, setEditIndex] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  React.useImperativeHandle(ref, () => ({
    handlefetchList: async () => {
      await fetchListNameGM();
    },
    validate: () => validate(),
    onChangeTextGM: onChangeTextGM,
  }));

  // データ取得 ------------------------------------------------
  const fetchListNameGM = async () => {
    try {
      const res: GroupMaster[] = await getListGroupMaster();
      if (res.length > 0) {
        setListGM(res);
      }
      const res_ab = await getListActivityBase("", true);
      if (res_ab.length > 0) {
        let list_ab: IActivityBase[] = [];
        for (let index = 0; index < res_ab.length; index++) {
          const element = res_ab[index];
          const selectedData: ISelectedData = getGroupSelectedData(
            element.list_group,
            res.length,
          );
          element["selectedData"] = selectedData;
          list_ab.push(element);
        }
        setListActivityBase(list_ab);

        if (stateForm.project_location_id) {
          let option_origin: IGroupEditData[] = [];
          if (stateForm.project_id) {
            option_origin = await getOptionOrigin(
              stateForm.project_location_id,
              res,
            );
          }

          const default_AB = list_ab.find(
            (item: ActivityBase) => item.SK === stateForm.project_location_id,
          );
          if (default_AB) handleChangeLevel1(default_AB, option_origin);
        }
      }
    } catch (error: any) {
      console.log(error, "error fetch list name GM");
    }
  };

  const getOptionOrigin = async (locationID: string, listGM: GroupMaster[]) => {
    const resOriginGroup: ResponseOriginGroup = await GetListGroupByProjectID({
      project_id: stateForm.project_id ?? "",
      location_id: locationID,
    });

    let list_info: IGroupEditData[] = [];
    if (resOriginGroup) {
      resOriginGroup.forEach((item, index) => {
        const data = item.data[item.data.length - 1];
        const selectedData: ISelectedData = getGroupSelectedData(
          item.data,
          listGM.length,
        );
        const list_group_name = item.data.map((g) => g.group_name);

        let objectInfo: IGroupEditData = {
          PK: data.PK,
          SK: data.SK,
          group_parentid: data.group_parentid,
          groupName: data.group_name,
          index: index,
          groupNameAll: list_group_name.join(" "),
          selectedData: selectedData,
        };
        list_info.push(objectInfo);
      });
      setSelectedOption(JSON.parse(JSON.stringify(list_info)));
      setOriginOption(JSON.parse(JSON.stringify(list_info)));
    }
    return list_info;
  };

  // 表示 ------------------------------------------------
  const labelAB = "拠点";

  const valueAB = useMemo(() => {
    const value = listActivityBase.find(
      (d) => d.SK === stateForm.project_location_id,
    );
    return value ? value.activity_base_name : "";
  }, [listActivityBase, stateForm]);

  // GroupSelectDialog ------------------------------------
  const initialSelectedData = useMemo(() => {
    const value = listActivityBase.find(
      (d) => d.SK === stateForm.project_location_id,
    );
    return value ? value.selectedData : undefined;
  }, [listActivityBase, stateForm]);

  const readOnlyGroup = useMemo(() => {
    const value = listActivityBase.find(
      (d) => d.SK === stateForm.project_location_id,
    );
    let response: { [key: string]: boolean } = {};

    if (value?.selectedData) {
      Object.keys(value?.selectedData).forEach((key, i) => {
        response[i + 1] = value?.selectedData
          ? value?.selectedData[i + 1] !== ""
          : false;
      });
    }
    return response;
  }, [listActivityBase, stateForm]);

  // 入力制御 ------------------------------------------------
  useEffect(() => {
    setStateForm((pre: IStateForm) => ({
      ...pre,
      group_info: JSON.parse(JSON.stringify(selectedOption)),
    }));

    setErrorMessage(validate());
  }, [selectedOption]);

  const validate = (value?: IGroupEditData[]) => {
    if (!value) value = selectedOption;

    const resGroupBy = _(selectedOption)
      .groupBy((item) => item.SK)
      .map((item) => item)
      .value();

    return resGroupBy.length === selectedOption.length
      ? ""
      : messages.ACCOUNT.MSG_ERROR_IF_ITEM_SELECTED_DUPLICATE;
  };

  const onChangeTextGM = (newText: string | undefined) => {
    const option = listActivityBase.find((d) => d.SK === newText);
    let newStateForm = {
      ...stateForm,
      project_location_id: newText,
      // location_code: option?.activity_base_code ?? "",
    };
    setStateForm(newStateForm);
    handleChangeLevel1(option);

    // グループ設定を削除
    selectedOption.forEach((item) => {
      removeDataChange(item);
    });
    setSelectedOption([]);
  };

  const handleChangeGroup = (data: {
    group: GroupInfo;
    nameAll: string;
    selectedData: ISelectedData;
  }) => {
    let objectInfo: IGroupEditData = {
      PK: data.group.PK,
      SK: data.group.SK,
      group_parentid: data.group.group_parentid,
      groupName: data.group.group_name,
      index: 0,
      groupNameAll: data.nameAll,
      selectedData: data.selectedData,
    };
    let tmp_selected_option = JSON.parse(JSON.stringify(selectedOption));

    if (editIndex === undefined) {
      //case add new group
      objectInfo.index = selectedOption.length;
      objectInfo.isCreateNew = true;
      setDataChange((pre) => {
        pre.add.push({ ...objectInfo });
        return pre;
      });
      tmp_selected_option.push({ ...objectInfo });
    } else {
      //case edit group
      objectInfo.index = editIndex;
      setDataChange((pre) => {
        pre.edit.push({ ...objectInfo });
        return pre;
      });
      tmp_selected_option[editIndex] = { ...objectInfo };
    }

    setSelectedOption(tmp_selected_option);
    setInitialGroup(undefined);
    setEditIndex(undefined);
  };

  const handleDeleteGroup = (data: IGroupEditData, index: number) => {
    removeDataChange(data);

    const selected_option_tmp = _.cloneDeep(selectedOption);
    selected_option_tmp.splice(index, 1);
    setSelectedOption(selected_option_tmp);
  };

  const removeDataChange = (data: IGroupEditData) => {
    if (!data.isCreateNew) {
      setDataChange((pre) => {
        pre.remove.push({ ...data });
        return pre;
      });
    }
  };

  useEffect(() => {
    if (!disabled) {
      if (!stateForm.project_location_id && listActivityBase.length > 0) {
        onChangeTextGM(listActivityBase[0].SK);
      }
    }
  }, [disabled]);

  return disabled ? (
    <></>
  ) : (
    <>
      {allowEditTopLocation ? (
        <SelectLabel
          label={
            <>
              <LabelRequired title={labelAB} />
            </>
          }
          value={
            listActivityBase.length > 0 ? stateForm.project_location_id : ""
          }
          isIgnoreExtractRuleLabel={false}
          isIgnoreExtractRuleSelect={true}
          onChange={(e) => onChangeTextGM(e.target.value)}
        >
          {listActivityBase.map((item) => (
            <MenuItem value={item.SK} key={item.SK}>
              <span className="IgnoreExtractRuleTarget">
                {item.activity_base_name}
              </span>
            </MenuItem>
          ))}
        </SelectLabel>
      ) : (
        <LabelValueText
          label={labelAB}
          value={valueAB}
          isIgnoreExtractRule={true}
        />
      )}

      {selectedOption.map((item, index) => (
        <GroupBox
          data={item}
          handleChange={() => {
            setInitialGroup(item.selectedData);
            setEditIndex(index);
            setOpen(true);
          }}
          handleDelete={() => handleDeleteGroup(item, index)}
          allowEditGroup={allowEditGroup}
          index={index}
          key={index}
        />
      ))}

      <Box display="flex" flexDirection="row">
        {showErrorMessage && (
          <FormHelperText error>{errorMessage}</FormHelperText>
        )}
        <Box flexGrow={1} />
        <Button
          sx={{
            alignSelf: "flex-end",
            display: allowEditGroup ? undefined : "none",
          }}
          onClick={() => {
            setInitialGroup(initialSelectedData);
            setOpen(true);
          }}
        >
          所属追加
        </Button>
      </Box>

      <GroupSelectDialog
        open={open}
        setOpen={setOpen}
        onChange={handleChangeGroup}
        initialSelect={initialGroup}
        readOnly={readOnlyGroup}
      />
    </>
  );
};

const GroupBox = ({
  data,
  handleChange,
  handleDelete,
  index,
  allowEditGroup,
}: {
  data: IGroupEditData;
  handleChange: Function;
  handleDelete: Function;
  index: number;
  allowEditGroup: boolean;
}) => {
  return (
    <Box
      border="3px solid"
      borderColor={Colors.MAIN_GREEN}
      borderRadius={1}
      padding={1}
      flex={1}
      display={"flex"}
      flexDirection="column"
      position="relative"
    >
      <IconButton
        color="inherit"
        sx={{
          position: "absolute",
          top: 1,
          right: 1,
          display: allowEditGroup ? undefined : "none",
        }}
        onClick={() => handleDelete()}
      >
        <Cancel
          sx={{
            bgcolor: "white",
            borderRadius: "50%",
          }}
        />
      </IconButton>
      <LabelValueText
        label={`所属グループ${index + 1}`}
        value={data.groupNameAll}
        isIgnoreExtractRule={true}
      />
      <Button
        sx={{
          alignSelf: "center",
          mt: 1,
          display: allowEditGroup ? undefined : "none",
        }}
        onClick={() => handleChange()}
      >
        変更
      </Button>
    </Box>
  );
};

export default React.forwardRef(ProjectGroupInfoOption);
