import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { renderUserFlow } from "components/atoms/StatusUserFlow";
import LabelValueText from "components/atoms/LabelValueText";
import formatDateToString from "@utils/DateFormat";
import styles from "./styles";
import SelectFileReportConfirm from "./SelectFileReportConfirm";
import {
  STATUS_WORK_TLOG,
  TEMPLATE_EDIT_TYPE,
  screenIdImageList,
  screenIdSupport,
} from "@shared-constants";
import { renderUserFlowTlog } from "components/atoms/StatusUserFlow/StatusUserFlowTlog";
import { Colors } from "@template/style";
import ModalApproveHistory from "../ModalApproveHistory";
import messages from "config/messages";
import { screenIdSupportCheckRequiredFieldTemplate } from "screens/CreateReportTlog/templateFunctionSupport";
import { createObjectURL } from "@utils/index";

interface IProps {
  data: any;
  template?: any;
  setTemplate?: Function;
  editableTemplate?: boolean;
}

const FormContextReportConfirm = (
  { data, template, setTemplate, editableTemplate }: IProps,
  ref?: React.Ref<any>,
) => {
  // const startDate = data?.work_start_date
  //   ? formatDateToString(data?.work_start_date, "YMD_jp")
  //   : "";
  // const endDate = data?.work_end_date
  //   ? formatDateToString(data?.work_end_date, "YMD_jp")
  //   : "";
  const flow_approve_data: Type.FlowDataType = data?.flow_approve_data;
  // ? JSON.parse(data?.flow_approve_data ?? "[]")
  // : [];

  const [open, setOpen] = React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<{ template: Array<string> }>(
    { template: [] },
  );
  const [image_url, setImageUrl] = React.useState("");

  const getStepApprove = () => {
    const str = data?.approval_status;
    const index = str?.indexOf("/");
    if (index != -1) {
      const start = Number(str?.slice(0, index));
      return start;
    }
    return 0;
  };

  const checkRequiredFieldTemplate = (
    screenId: string,
    templateEditInfo: any,
  ) => {
    const functionRequiredFieldTemplate =
      screenIdSupportCheckRequiredFieldTemplate[screenId];

    let isError = false;
    let message: string[] = [];

    if (functionRequiredFieldTemplate === undefined) {
      return false;
    }

    if (templateEditInfo === null) {
      message.push(messages.WORK_ITEM.MSG_CONFIRM_REQUIRED_TEMPLATE);
      setFormError((prev) => ({ ...prev, template: message }));
      return true;
    }

    const result = functionRequiredFieldTemplate(
      templateEditInfo,
      data?.work_template_id,
    );
    isError = result.isError;
    message = result.message;
    setFormError((prev) => ({ ...prev, template: message }));
    return isError;
  };
  const validationAllCheck = () => {
    let response = true;

    let check_template = checkRequiredFieldTemplate(
      template?.screen_id ?? "",
      template?.templateEditInfo,
    );

    response = check_template;
    return response;
  };

  useImperativeHandle(ref, () => ({
    validationAllCheck: () => validationAllCheck(),
  }));

  const handleDownload = async (file_name: string, uri: string) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    const a = document.createElement("a");
    const url = createObjectURL(blob);
    a.href = url;
    a.download = file_name;
    a.click();
    a.remove();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  const image_array = useMemo(() => {
    var response: any = undefined;
    if (template.templateEditInfo) {
      if (screenIdImageList[template.screen_id] in template.templateEditInfo) {
        response =
          template.templateEditInfo[screenIdImageList[template.screen_id]];
      }
      //画像が2種類ある場合それぞれをmergeさせる。
      const isExistMultiple =
        screenIdImageList[template.screen_id].indexOf(",");
      if (isExistMultiple > 0) {
        const imageList = screenIdImageList[template.screen_id].split(",");
        imageList.forEach((key) => {
          if (key in template.templateEditInfo) {
            response = (response ?? []).concat(template.templateEditInfo[key]);
          }
        });
      }
    }
    return response;
  }, [template.templateEditInfo]);

  return (
    <>
      <Card>
        <CardHeader title="基本情報" sx={styles.header} />
        <CardContent>
          <FormGroup>
            <Stack sx={{ pb: 0 }}>
              <LabelValueText
                label={
                  screenIdSupport[template?.screen_id || ""] ==
                  TEMPLATE_EDIT_TYPE.RISKY_REPORT
                    ? "事故番号"
                    : "帳票番号"
                }
                value={data?.risky_id}
                isIgnoreExtractRule={true}
              />
              {/* <LabelValueText
                label="種別"
                value={data?.category_id?.category_name}
              />
              <LabelValueText
                label="期間"
                value={startDate || endDate ? startDate + " ～ " + endDate : ""}
              /> */}
              <LabelValueText
                label="報告者"
                value={data?.created_by_name}
                isIgnoreExtractRule={true}
              />
            </Stack>
          </FormGroup>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="承認フロー" sx={styles.header} />
        <CardContent>
          <Typography className="IgnoreExtractRuleTarget">
            {data?.flow_approve_id?.flow_name}
          </Typography>
          <Box
            component={"div"}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            mb={1}
          >
            <Typography>バージョン {data?.version}</Typography>
            <Box flexGrow={1} />
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              履歴
            </Button>
          </Box>
          <FormGroup>
            {data?.flow_approve_data
              ? renderUserFlowTlog(
                  flow_approve_data,
                  getStepApprove(),
                  data?.work_status == STATUS_WORK_TLOG.CANCEL,
                )
              : null}
          </FormGroup>
        </CardContent>
      </Card>

      <SelectFileReportConfirm
        template={template}
        setTemplate={setTemplate}
        errorMessage={formError.template}
        editableTemplate={
          editableTemplate !== undefined
            ? editableTemplate
            : ((data?.work_status === STATUS_WORK_TLOG.NOT_APPROVED &&
                data?.checking_approve_flag) ||
                (data?.work_status === STATUS_WORK_TLOG.DRAFT &&
                  data?.is_user_in_flow_approve)) &&
              data?.work_status !== STATUS_WORK_TLOG.DRAFT
        }
        data={data}
      />

      <Card>
        <CardContent>
          <InputLabel>帳票添付画像</InputLabel>
          <Dialog
            open={image_url !== ""}
            onClose={() => setImageUrl("")}
            maxWidth="lg"
          >
            <DialogContent>
              <Box component="img" src={image_url} />
            </DialogContent>
          </Dialog>
          <Grid container my={1}>
            {image_array &&
              image_array.map((item: any, index: number) => {
                return item.path_file ? (
                  <Grid
                    item
                    xs={6}
                    md={2}
                    key={index}
                    sx={{ pr: 1, pb: 1, textAlign: "center" }}
                  >
                    <Box
                      component="img"
                      src={item.uri}
                      sx={{
                        width: "100%",
                        height: { xs: 80, md: 100 },
                        objectFit: "cover",
                        borderRadius: 1,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setImageUrl(item.uri);
                      }}
                    />
                    <Link
                      component="button"
                      onClick={async () => {
                        let input_file_name = item.name ?? item.path;
                        // 拡張子取得
                        let extension =
                          "." +
                          input_file_name.substring(
                            input_file_name.lastIndexOf(".") + 1,
                          );
                        let display_id = data?.risky_id.replace("-", "");
                        let seq = index + 1;
                        let file_name =
                          display_id + "-" + ("0" + seq).slice(-2);

                        let uri = item.uri;
                        if (item.type === "image/svg+xml") {
                          extension = ".jpg";
                          uri = item.uri_jpg;
                        }
                        file_name = file_name + extension;
                        await handleDownload(file_name, uri);
                      }}
                    >
                      ダウンロード
                    </Link>
                  </Grid>
                ) : null;
              })}
          </Grid>
        </CardContent>
      </Card>

      {/* <Card>
        <CardHeader title="備考" sx={styles.header} />
        <CardContent>
          <FormGroup>
            <LabelValueText
              // label="備考"
              value={data?.memo}
            />
          </FormGroup>
        </CardContent>
      </Card> */}

      <ModalApproveHistory open={open} setOpen={setOpen} work_id={data?.SK} />
    </>
  );
};

export default forwardRef(FormContextReportConfirm);
