import React, { VFC, useState, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  MenuItem,
  FormLabel,
  Stack,
  Grid,
  InputLabel,
  FormControl,
} from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";
import { useEffect } from "react";
import { Validation } from "@validation";
import AvatarRes from "components/atoms/AvatarRes";
import LabelRequired from "components/atoms/LabelRequired";
import { ActivityBase, User } from "services/models";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import {
  deleteUserApi,
  getListPositionApi,
  getUserInfoByIDApi,
  resetPasswordApi,
  updateUserApi,
} from "@api/User";
import { getContractApi } from "@api/contract";
import { createFormUpload, createObjectURL, getUserInfo } from "@utils/index";
import DropzoneDialog from "components/molecules/DropzoneDialog";
import { getDataStorage, STORAGE } from "@utils/Storage";
import {
  COUNTRY_CODE_JP,
  LIST_USER_ROLE,
  SEAL_MAX_ROWS,
  USER_ROLES,
  LIST_USER_ROLE_FOR_ADMIN,
  LIST_USER_ROLE_FOR_MEMBER_AND_EDITOR,
} from "@shared-constants";
import _ from "lodash";
import { Box } from "@mui/system";
import messages from "config/messages";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import LabelValueText from "components/atoms/LabelValueText";
import GroupInfoOption, {
  IGroupEditData,
} from "components/organisms/AccountGroupInfoOption";
import { TypePosition, UserInfo } from "services/models/models";
import Dropzone from "components/atoms/Dropzone";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import PasswordChangeOthersDialog from "./PasswordChangeOthersDialog";

interface IProps {
  data: User;
  open: boolean;
  setOpen: Function;
  setFetchData: Function;
}

interface IStateForm {
  PK: string;
  SK: string;
  avatar: string | null;
  preferred_username_cognito: string;
  user_role: string;
  full_name: string;
  phone_number: string;
  email: string;
  contract_name: string;
  belong_company_name: string;
  avatar_image: any;
  email_verified: boolean;
  phone_number_verified: boolean;
  // --->> TLOG
  position_id: string;
  position_name: string;
  location_id: string;
  seal_display_name: string;
  seal_file_path: string | null;
  seal_image: any;
  location_code: string | null;
  group_info: IGroupEditData[];
  username_cognito: string;
  is_vertical_writing_sign: boolean;
  can_create_user_report: boolean;
  // <<--- TLOG
}

const initialStateForm: IStateForm = {
  PK: "",
  SK: "",
  avatar: "",
  preferred_username_cognito: "",
  user_role: "",
  full_name: "",
  phone_number: "",
  email: "",
  contract_name: "",
  belong_company_name: "",
  avatar_image: undefined,
  email_verified: false,
  phone_number_verified: false,
  // --->> TLOG
  position_id: "",
  position_name: "",
  location_id: "",
  seal_display_name: "",
  seal_file_path: "",
  seal_image: undefined,
  location_code: null,
  group_info: [],
  username_cognito: "",
  is_vertical_writing_sign: false,
  can_create_user_report: false,
  // <<--- TLOG
};

const initalFormError = {
  preferred_username_cognito: "",
  full_name: "",
  phone_number: "",
  email: "",
  belong_company_name: "",
  // --->> TLOG
  group_info: "",
  seal_display_name: "",
  position_name: "",
  // <<--- TLOG
};

const POSITION_SEP = "@" as const; // TLOG

const EditMemberDialog: VFC<IProps> = ({
  data,
  open,
  setOpen,
  setFetchData,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [stateForm, setStateForm] = useState(initialStateForm);
  const [originData, setOriginData] = useState(initialStateForm);
  const [formError, setFormError] = useState(initalFormError);
  const [is_reset, setIsReset] = useState(false);
  const [delete_avatar, setDeleteAvatar] = useState(false);
  const [fileOpen, setFileOpen] = useState<boolean>(false);
  // --->> TLOG
  const [isDeleteSeal, setIsDeleteSeal] = useState<boolean>(false);
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [listPosition, setListPosition] = useState<TypePosition[]>([]);
  const groupInfoRef = React.useRef<any>();
  const userInfo: UserInfo = getUserInfo();
  // <<--- TLOG
  const contract_info = getDataStorage(STORAGE.CONTRACT_INFO);
  const contractInfoObject = JSON.parse(contract_info);

  // データ取得 ------------------------------------------------
  useEffect(() => {
    let updateStateForm: IStateForm = initialStateForm;

    const fetchUserInfo = async () => {
      LoadingOverlayController.show();
      const res = await getUserInfoByIDApi(data.SK)
        .then((res) => {
          updateStateForm = {
            ...updateStateForm,
            PK: res?.PK,
            SK: res?.SK,
            avatar: res?.avatar,
            preferred_username_cognito: res?.preferred_username_cognito,
            user_role: res?.user_role,
            full_name: res?.full_name,
            phone_number: res?.phone_number !== null ? res?.phone_number : "",
            email: res?.email !== null ? res?.email : "",
            contract_name: res?.contract_name,
            belong_company_name:
              res?.belong_company_name !== null ? res?.belong_company_name : "",
            avatar_image: undefined,
            // --->> TLOG
            // email_verified: res?.email_verified,
            email_verified: false,
            // <<--- TLOG
            phone_number_verified: res?.phone_number_verified,
            // --->> TLOG
            position_id: res?.position_id,
            position_name: res?.position_name,
            location_id: res?.location_id,
            seal_display_name: res?.seal_display_name
              ? res?.seal_display_name.replace(/\r\n/g, "\n")
              : "",
            seal_file_path: res?.seal_file_path,
            seal_image: undefined,
            username_cognito: res?.username_cognito,
            is_vertical_writing_sign:
              res?.is_vertical_writing_sign !== undefined
                ? res?.is_vertical_writing_sign
                : false,
            can_create_user_report:
              res?.can_create_user_report !== undefined
                ? res?.can_create_user_report
                : false,
            // <<--- TLOG
          };

          setOriginData(updateStateForm);
          setStateForm(updateStateForm);
          setIsReset(res?.is_password_reset);
          fetchContractInfo();
          // fetchListPosition(); // TLOG
          return true;
        })
        .catch((error) => {
          if (!ModalController.isShowing())
            ModalController.show({
              message: error?.response?.detail.message,
              visibleButton2: true,
            });
          console.log(error, "error getUserInfoByIDApi");
          LoadingOverlayController.hide(); // TLOG
        })
        .finally(() => {
          // LoadingOverlayController.hide(); // TLOG
        });

      // --->> TLOG
      if (res) {
        if (groupInfoRef && groupInfoRef.current) {
          await groupInfoRef.current.handlefetchList().finally(() => {
            LoadingOverlayController.hide();
          });
        }
      }
      // <<--- TLOG
    };

    const fetchContractInfo = async () => {
      await getContractApi()
        .then((res) => {
          updateStateForm = {
            ...updateStateForm,
            contract_name: res?.contract_name,
          };

          setOriginData(updateStateForm);
          setStateForm(updateStateForm);
        })
        .catch((error) => {
          if (!ModalController.isShowing())
            ModalController.show({
              message: error?.response?.detail.message,
              visibleButton2: true,
            });
          console.log(error, "error getContractApi");
        });
    };

    // --->> TLOG
    // const fetchListPosition = async () => {
    //   try {
    //     const res: TypePosition[] = await getListPositionApi();
    //     if (res.length > 0) {
    //       setListPosition(res);
    //     }
    //   } catch (error: any) {
    //     if (!ModalController.isShowing())
    //       ModalController.show({
    //         message: error?.response?.detail.message,
    //         visibleButton2: true,
    //       });
    //     console.log(error, "error getContractApi");
    //   }
    // };
    // <<--- TLOG

    if (open) {
      fetchUserInfo();
      setFormError(initalFormError);
      setDeleteAvatar(false);
      setIsDeleteSeal(false); // TLOG
    } else {
      setStateForm(initialStateForm);
      setOriginData(initialStateForm);
    }
  }, [open, data]);

  // 入力制御 ------------------------------------------------
  const hasUpdateData = useMemo(() => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  }, [stateForm, originData]);

  const chooseImage = (response: any) => {
    setStateForm({ ...stateForm, avatar_image: response[0] });
    setFileOpen(false);
  };

  const getUrlAvatar = () => {
    if (typeof stateForm.avatar_image === "object") {
      return createObjectURL(stateForm.avatar_image);
    }
    return stateForm.avatar ? stateForm.avatar : undefined;
  };

  // --->> TLOG
  const chooseImageSeal = (response: any) => {
    setStateForm({ ...stateForm, seal_image: response[0] });
    setFormError({ ...formError, seal_display_name: "" });
  };

  const getUrlSeal = () => {
    if (typeof stateForm.seal_image === "object") {
      return createObjectURL(stateForm.seal_image);
    }
    return stateForm.seal_file_path ? stateForm.seal_file_path : undefined;
  };
  // <<--- TLOG

  const onChangeText =
    (field: keyof IStateForm) => (newText: string | undefined) => {
      let newStateForm = { ...stateForm, [field]: newText };

      if (field === "avatar_image")
        newStateForm = { ...newStateForm, avatar: null };
      // --->> TLOG
      if (field === "seal_image")
        newStateForm = { ...newStateForm, seal_file_path: null };
      // <<--- TLOG
      setStateForm(newStateForm);
    };

  // --->> TLOG
  const onChangePosition = (value: string) => {
    const value_sep = value.split(POSITION_SEP);
    setStateForm((pre) => {
      return {
        ...pre,
        position_id: value_sep[0],
        position_name: value_sep.length > 1 ? value_sep[1] : "",
      };
    });
  };

  const onChangeLevel1 = (item: ActivityBase, group_info: IGroupEditData[]) => {
    setStateForm((pre) => {
      return {
        ...pre,
        location_code: item?.activity_base_code ?? "",
      };
    });
    if (originData.location_code === null) {
      setOriginData((pre) => {
        return {
          ...pre,
          location_code: item?.activity_base_code ?? "",
          group_info: group_info ?? [],
        };
      });
    }

    onValidateText(
      "preferred_username_cognito",
      item?.activity_base_code ?? "",
    );
  };
  // <<--- TLOG

  const onValidateText = async (
    field: keyof IStateForm,
    location_code?: string, //  TLOG
  ) => {
    const mess = await validator(field, stateForm[field], location_code);
    setFormError({ ...formError, [field]: mess });
  };

  const validator = async (
    field: keyof IStateForm,
    value: string,
    location_code?: string, //  TLOG
  ) => {
    let mess: string = "";

    switch (field) {
      case "preferred_username_cognito":
        let locationCode = location_code ?? stateForm.location_code ?? "";
        mess = await Validation.validateLoginIDTlog(
          value,
          originData.preferred_username_cognito,
          locationCode,
          originData.location_code ?? locationCode,
          "ログインID",
          true,
          contractInfoObject.contract_login_id,
        );
        // <<--- TLOG
        break;
      case "email":
        mess = Validation.validateEmail(
          value,
          "メールアドレス",
          originData.email_verified,
        );
        break;
      case "phone_number":
        mess = Validation.validatePhone(
          value,
          "電話番号",
          originData.phone_number_verified,
        );
        break;
      case "full_name":
        mess = Validation.validate({
          type: "text",
          name: "名前",
          value: value,
          required: true,
          max_length: 30,
        });
        break;
      case "belong_company_name":
        mess = Validation.validate({
          type: "text",
          // --->> TLOG
          // name: "所属企業",
          name: "グループ名",
          // <<--- TLOG
          value: value,
          required: false,
          max_length: 30,
        });
        break;
      // --->> TLOG
      case "position_id":
        mess = Validation.validate({
          type: "text",
          name: "職位",
          value: value,
          required: true,
        });
        break;
      case "position_name":
        mess = Validation.validate({
          type: "text",
          name: "役職名",
          value: value,
          required: false,
          max_length: 30,
        });
        break;
      case "seal_display_name":
      case "seal_image": // 電子印画像削除時
        mess = Validation.validate({
          type: "text",
          name: "電子印情報",
          value: value.replace(/\r?\n/g, ""), // 改行は桁数に含まない
          required: field === "seal_image" ? true : !getUrlSeal(),
          max_length: 30,
        });
        // <<--- TLOG
        break;
    }
    return mess;
  };

  // --->> TLOG
  const validateGroup = () => {
    let mess: string = "";
    if (groupInfoRef && groupInfoRef.current) {
      mess = groupInfoRef.current.validate();
    }
    return mess;
  };

  useEffect(() => {
    const mess = validateGroup();
    setFormError({ ...formError, group_info: mess });
  }, [stateForm.group_info]);
  // <<--- TLOG

  const validationAllCheck = async () => {
    let response = true;
    const mess_id = await validator(
      "preferred_username_cognito",
      stateForm.preferred_username_cognito,
    );
    const mess_name = await validator("full_name", stateForm.full_name);
    const mess_phone = await validator("phone_number", stateForm.phone_number);
    const mess_email = await validator("email", stateForm.email);
    const mess_company = await validator(
      "belong_company_name",
      stateForm.belong_company_name,
    );
    // --->> TLOG
    let mess_group = validateGroup();
    let mess_seal = await validator(
      "seal_display_name",
      stateForm.seal_display_name,
    );
    if (stateForm.user_role === USER_ROLES.OWNER.value) {
      mess_seal = "";
    }
    // <<--- TLOG

    if (
      mess_id.length > 0 ||
      mess_name.length > 0 ||
      mess_phone.length > 0 ||
      mess_email.length > 0 ||
      mess_company.length > 0 ||
      mess_group.length > 0 || // TLOG
      mess_seal.length > 0 // TLOG
    )
      response = false;

    setFormError({
      ...formError,
      preferred_username_cognito: mess_id,
      full_name: mess_name,
      phone_number: mess_phone,
      email: mess_email,
      belong_company_name: mess_company,
      group_info: mess_group, // TLOG
      seal_display_name: mess_seal, // TLOG
    });
    return response;
  };

  const disabledSaveBtn = useMemo(() => {
    const error_messages = Object.values(formError).filter(
      (item) => item.length > 0,
    );
    if (!hasUpdateData || error_messages.length > 0) return true;
    return false;
  }, [formError, hasUpdateData]);

  // 保存 ------------------------------------------------
  const handleUpdateMember = async () => {
    try {
      // 入力チェック
      if (!(await validationAllCheck())) {
        return;
      }

      LoadingOverlayController.show();

      const data = {
        contract_id: stateForm.PK,
        user_id: stateForm.SK,
        preferred_username_cognito: stateForm.preferred_username_cognito,
        user_role: stateForm.user_role,
        full_name: stateForm.full_name,
        phone_number: stateForm.phone_number,
        email: stateForm.email,
        belong_company_name: stateForm.belong_company_name,
        avatar_status:
          stateForm.avatar_image === undefined && delete_avatar
            ? "false"
            : "true",
        country_code: stateForm.phone_number ? COUNTRY_CODE_JP : undefined,
        // --->> TLOG
        position_id: stateForm.position_id,
        position_name: stateForm.position_name,
        user_stamp:
          stateForm.seal_image === undefined && isDeleteSeal ? "false" : "true",
        seal_display_name: stateForm.seal_display_name,
        location_code: stateForm.location_code,
        location_id: stateForm.location_id,
        is_vertical_writing_sign: stateForm.is_vertical_writing_sign
          ? "true"
          : "false",
        can_create_user_report: stateForm.can_create_user_report
          ? "true"
          : "false",
        // <<--- TLOG
        contract_login_id: contractInfoObject.contract_login_id,
      };

      let fileUpload = null;
      if (stateForm.avatar_image !== originData.avatar_image) {
        fileUpload = stateForm.avatar_image;
      }
      let formData = createFormUpload(fileUpload, data);
      // --->> TLOG
      if (
        stateForm.seal_image !== originData.seal_image &&
        stateForm.seal_image
      ) {
        formData.append("seal_display_file", stateForm.seal_image);
      }
      // <<--- TLOG

      const res = await updateUserApi(formData);

      // --->> TLOG
      if (groupInfoRef && groupInfoRef.current) {
        const resUpdateGroup = await groupInfoRef.current.handleUpdateGroup();
      } // <<--- TLOG

      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001("メンバーの更新"),
          visibleButton2: true,
          handlePressButton2: () => checkLogout(1),
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateMember", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // パスワードリセット ------------------------------------------------
  const showResetPasswordConfirmDialog = () => {
    ModalController.show({
      message: messages.OTHER_SCREEN.MSG_CONFIRM_RESET_PASSWORD,
      visibleButton1: true,
      visibleButton2: true,
      button1: { title: messages.COMMON.BUTTON.CANCEL },
      handlePressButton2: handleConfirmResetPassword,
    });
  };

  const handleConfirmResetPassword = async () => {
    try {
      LoadingOverlayController.show();

      const data = {
        PK: stateForm.PK,
        SK: stateForm.SK,
      };
      const res = await resetPasswordApi(data);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001("パスワードリセット"),
          visibleButton2: true,
          handlePressButton2: () => {
            setIsReset(true);
            checkLogout(3);
          },
        });
      }
    } catch (error) {
      console.log("error handleConfirmResetPassword", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 削除 ------------------------------------------------
  const showDeleteConfirmDialog = () => {
    const getAddContents = () => {
      return (
        <CheckboxLabel
          label={messages.OTHER_SCREEN.MSG_CONFIRM_DELETE_ACCOUNT_ACCEPT}
          onClick={() => {
            ModalController.setDisableButton2(
              !ModalController.overlayInstance.state.config.disableButton2,
            );
          }}
        />
      );
    };

    ModalController.show({
      message: messages.OTHER_SCREEN.MSG_CONFIRM_DELETE_ACCOUNT,
      attention: messages.OTHER_SCREEN.MSG_CONFIRM_DELETE_ACCOUNT_ATTENTION,
      addContents: getAddContents,
      visibleButton1: true,
      visibleButton2: true,
      disableButton2: true,
      handlePressButton2: async () => await handleConfirmDelete(),
    });
  };

  const handleConfirmDelete = async () => {
    LoadingOverlayController.show();
    await deleteUserApi(stateForm.SK)
      .then((res) => {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001("メンバーの削除"),
          visibleButton2: true,
          handlePressButton2: () => checkLogout(2),
        });
      })
      .catch((error) => {
        if (!ModalController.isShowing())
          ModalController.show({
            message: error?.detail,
            visibleButton2: true,
          });
        console.log(error, "error deleteUserApi");
      })
      .finally(() => {
        LoadingOverlayController.hide();
      });
  };

  /**
   * checkLogout
   * @param type (1:update/2:delete/3:reset_password)
   */
  const checkLogout = async (type: number) => {
    if (type === 3) return;
    setOpen(false);
    setFetchData(true);
  };

  const handleCancel = () => {
    if (disabledSaveBtn) {
      setOpen(false);
      return;
    }

    // 確認ダイアログ
    ModalController.show({
      message: messages.COMMON.MSG_CONFIRM_GO_BACK,
      visibleButton1: true,
      visibleButton2: true,
      button1: {
        title: messages.COMMON.BUTTON.CANCEL,
      },
      disableFeedback: true,
      handlePressButton1: () => {
        setOpen(false);
      }, // 閉じる
      handlePressButton2: handleUpdateMember, // 保存
    });
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <DropzoneDialog
          open={fileOpen}
          onClose={() => setFileOpen(false)}
          onChoose={chooseImage}
          maxFiles={1}
        />
        <Grid
          container
          spacing={3}
          sx={{ flexWrap: { xs: undefined, md: "nowrap" } }}
        >
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              minWidth: { xs: undefined, md: "275px" },
              maxWidth: { xs: undefined, md: "275px" },
            }}
          >
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <CancelBtnBadge
                invisible={getUrlAvatar() === undefined}
                onClick={() => {
                  onChangeText("avatar_image")(undefined);
                  setDeleteAvatar(true);
                }}
              >
                <AvatarRes size={"lg"} src={getUrlAvatar()} />
              </CancelBtnBadge>
              <div />
              <Button
                variant="text"
                onClick={() => {
                  setFileOpen(true);
                }}
              >
                写真を変更
              </Button>
            </Box>
            <FormGroup sx={{ mt: 2.8 }}>
              <Stack>
                <TextField
                  label={
                    <>
                      <LabelRequired title={"ログインID"} />
                    </>
                  }
                  name="preferred_username_cognito"
                  value={stateForm.preferred_username_cognito}
                  onChange={(e) =>
                    onChangeText("preferred_username_cognito")(e.target.value)
                  }
                  onBlur={() => onValidateText("preferred_username_cognito")}
                  error={formError.preferred_username_cognito.length > 0}
                  helperText={formError.preferred_username_cognito}
                  inputProps={{
                    maxLength: 30,
                  }}
                  placeholder="半角英数記号 6~30 スペース利用不可"
                  sx={{
                    "& ::placeholder": { fontSize: { xs: undefined, md: 11 } },
                  }}
                />
                <FormLabel>パスワード</FormLabel>
                {/* --->> TLOG
                <Button
                  onClick={showResetPasswordConfirmDialog}
                  sx={{ width: 110 }}
                  disabled={is_reset}
                >
                  {is_reset ? "リセット中" : "リセット"}
                </Button>
                */}
                <Button
                  sx={{ width: 100 }}
                  onClick={() => {
                    setOpenPassword(true);
                  }}
                >
                  変更
                </Button>
                <PasswordChangeOthersDialog
                  open={openPassword}
                  setOpen={setOpenPassword}
                  usernameCognito={originData.username_cognito}
                />
                {/* <<--- TLOG */}
                <SelectLabel
                  label={
                    <>
                      <LabelRequired title={"権限"} />
                    </>
                  }
                  value={stateForm.user_role}
                  isIgnoreExtractRuleLabel={false}
                  isIgnoreExtractRuleSelect={false}
                  onChange={(e) => {
                    onChangeText("user_role")(e.target.value);
                    if (e.target.value === USER_ROLES.OWNER.value) {
                      if (groupInfoRef && groupInfoRef.current) {
                        setTimeout(
                          () => groupInfoRef.current.onChangeTextGM(""),
                          500,
                        );
                      }
                    }
                  }}
                >
                  {userInfo.user_role === USER_ROLES.OWNER.value
                    ? LIST_USER_ROLE.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.name}
                        </MenuItem>
                      ))
                    : userInfo.user_role === USER_ROLES.ADMIN.value
                    ? LIST_USER_ROLE_FOR_ADMIN.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.name}
                        </MenuItem>
                      ))
                    : userInfo.user_role === USER_ROLES.EDITOR.value ||
                      userInfo.user_role === USER_ROLES.MEMBER.value
                    ? LIST_USER_ROLE_FOR_MEMBER_AND_EDITOR.map((item) => (
                        <MenuItem value={item.value} key={item.value}>
                          {item.name}
                        </MenuItem>
                      ))
                    : null}
                </SelectLabel>
                <LabelValueText
                  label="契約企業"
                  value={stateForm.contract_name}
                  isIgnoreExtractRule={true}
                />
              </Stack>
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={9}>
            <FormGroup>
              <Stack>
                <TextField
                  label={
                    <>
                      <LabelRequired title={"名前"} />
                    </>
                  }
                  name="full_name"
                  value={stateForm.full_name}
                  onChange={(e) => onChangeText("full_name")(e.target.value)}
                  onBlur={() => onValidateText("full_name")}
                  error={formError.full_name.length > 0}
                  helperText={formError.full_name}
                  inputProps={{
                    maxLength: 30,
                  }}
                  placeholder="現場　太郎"
                />
                {/* --->> TLOG
                <TextField
                  label="電話番号"
                  name="phone_number"
                  value={stateForm.phone_number}
                  onChange={(e) => onChangeText("phone_number")(e.target.value)}
                  required={stateForm.phone_number_verified}
                  onBlur={() => onValidateText("phone_number")}
                  error={formError.phone_number.length > 0}
                  helperText={formError.phone_number}
                  inputProps={{
                    maxLength: 11,
                  }}
                  placeholder="電話番号 ※ハイフン（-）不要"
                />
                <<--- TLOG */}
                <TextField
                  label="メールアドレス"
                  name="email"
                  value={stateForm.email}
                  onChange={(e) => onChangeText("email")(e.target.value)}
                  required={stateForm.email_verified}
                  onBlur={() => onValidateText("email")}
                  error={formError.email.length > 0}
                  helperText={formError.email}
                  inputProps={{
                    maxLength: 256,
                  }}
                  placeholder="xxx@example.com"
                />
                {/* --->> TLOG */}
                {/* {stateForm.user_role === USER_ROLES.OWNER.value ? null : (
                  <SelectLabel
                    label="職位"
                    value={
                      listPosition.length > 0 && stateForm.position_id
                        ? stateForm.position_id +
                          POSITION_SEP +
                          stateForm.position_name
                        : ""
                    }
                    defaultValue=""
                    onChange={(e) => onChangePosition(e.target.value)}
                    onBlur={() => onValidateText("position_id")}
                  >
                    {listPosition.map((item) => (
                      <MenuItem
                        value={item.SK + POSITION_SEP + item.position_name}
                        key={item.SK}
                      >
                        {item.position_name}
                      </MenuItem>
                    ))}
                  </SelectLabel>
                )} */}
                {/* <<--- TLOG */}
                <TextField
                  // --->> TLOG
                  // label="所属企業"
                  label="グループ名"
                  // <<--- TLOG
                  name="belong_company_name"
                  onChange={(e) =>
                    onChangeText("belong_company_name")(e.target.value)
                  }
                  value={stateForm.belong_company_name}
                  onBlur={() => onValidateText("belong_company_name")}
                  error={formError.belong_company_name.length > 0}
                  helperText={formError.belong_company_name}
                  inputProps={{
                    maxLength: 30,
                  }}
                  // --->> TLOG
                  // placeholder="企業名"
                  placeholder="グループ名"
                  // <<--- TLOG
                />
                {/* --->> TLOG */}
                <TextField
                  label="役職名"
                  name="position_name"
                  onChange={(e) =>
                    onChangeText("position_name")(e.target.value)
                  }
                  value={stateForm.position_name}
                  onBlur={() => onValidateText("position_name")}
                  error={formError.position_name.length > 0}
                  helperText={formError.position_name}
                  inputProps={{
                    maxLength: 30,
                  }}
                  placeholder="役職名"
                />
                <CheckboxLabel
                  checked={stateForm.can_create_user_report}
                  onClick={() => {
                    setStateForm((pre) => ({
                      ...pre,
                      can_create_user_report: !pre.can_create_user_report,
                    }));
                  }}
                  label="ユーザー作成帳票を作成可能"
                  sx={{ ml: -1 }}
                  formSx={{ alignSelf: "flex-start" }}
                />
                <GroupInfoOption
                  ref={groupInfoRef}
                  stateForm={stateForm}
                  setStateForm={setStateForm}
                  allowEditTopLocation={
                    userInfo.user_role === USER_ROLES.OWNER.value
                  }
                  allowEditGroup={true}
                  disabled={stateForm.user_role === USER_ROLES.OWNER.value}
                  handleChangeLevel1={onChangeLevel1}
                />

                {stateForm.user_role !== USER_ROLES.OWNER.value && (
                  <>
                    <TextField
                      label={
                        <>
                          <LabelRequired
                            title={"電子印の表示名(3行、30桁まで)"}
                          />
                        </>
                      }
                      multiline
                      rows={SEAL_MAX_ROWS}
                      value={stateForm.seal_display_name}
                      onChange={(e) => {
                        if (e.target.value.split("\n").length > SEAL_MAX_ROWS) {
                          return;
                        }
                        onChangeText("seal_display_name")(e.target.value);
                      }}
                      onBlur={() => onValidateText("seal_display_name")}
                      inputProps={{
                        maxLength: 32,
                        style: { textAlign: "center" },
                      }}
                      helperText={formError.seal_display_name}
                      error={formError.seal_display_name.length > 0}
                    />
                    <CheckboxLabel
                      checked={stateForm.is_vertical_writing_sign}
                      onClick={() => {
                        setStateForm((pre) => ({
                          ...pre,
                          is_vertical_writing_sign:
                            !pre.is_vertical_writing_sign,
                        }));
                      }}
                      label="電子印で表示名を縦書きで表示する"
                      sx={{ ml: -1 }}
                      formSx={{ alignSelf: "flex-start" }}
                    />
                    <FormControl>
                      <InputLabel>電子印画像</InputLabel>
                      {getUrlSeal() ? (
                        <Box sx={{ textAlign: "center" }}>
                          <CancelBtnBadge
                            onClick={async () => {
                              onChangeText("seal_image")(undefined);
                              setIsDeleteSeal(true);
                              const mess = await validator(
                                "seal_image",
                                stateForm.seal_display_name,
                              );
                              setFormError({
                                ...formError,
                                seal_display_name: mess,
                              });
                            }}
                          >
                            <Box
                              component="img"
                              src={getUrlSeal()}
                              sx={{
                                height: 100,
                                objectFit: "contain",
                                borderRadius: 1,
                              }}
                            />
                          </CancelBtnBadge>
                        </Box>
                      ) : (
                        <Dropzone onChoose={chooseImageSeal} maxFiles={1} />
                      )}
                    </FormControl>
                  </>
                )}
                {/* <<--- TLOG */}
              </Stack>
            </FormGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleCancel}>
          キャンセル
        </Button>
        {originData.user_role !== USER_ROLES.OWNER.value && (
          <Button onClick={showDeleteConfirmDialog} color="error">
            アカウント削除
          </Button>
        )}
        <Button onClick={handleUpdateMember} disabled={disabledSaveBtn}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMemberDialog;
