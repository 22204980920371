import React, { VFC, useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import LabelRequired from "components/atoms/LabelRequired";
import { cloneDeep } from "lodash";

export interface IWreckerStoreEditData {
  wrecker_name: string;
  wrecker_address: string;
  wrecker_tel: string;
}

interface IWreckerStoreEditProps {
  data: IWreckerStoreEditData;
  open: boolean;
  onClose: Function;
  onChange: Function;
}

const WreckerStoreEditDialog: VFC<IWreckerStoreEditProps> = ({
  data,
  open,
  onClose,
  onChange,
}: IWreckerStoreEditProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [disabled, setDisabled] = useState(true);
  const [stateForm, setStateForm] = useState<IWreckerStoreEditData>(cloneDeep(data));
  const [originData, setOriginData] = useState<IWreckerStoreEditData>(cloneDeep(data));

  const [formError, setFormError] = useState<{
    wrecker_name: string;
    wrecker_address: string;
    wrecker_tel: string;
  }>({
    wrecker_name: "",
    wrecker_address: "",
    wrecker_tel: "",
  });

  useEffect(() => {
    const setInitialData = async () => {
      setDisabled(true);
      setStateForm(data);
      setOriginData(data);
      setFormError({
        wrecker_name: "",
        wrecker_address: "",
        wrecker_tel: "",
      });
    };

    if (open) {
      setInitialData();
    }
  }, [open, data]);

  const arrayDataError = useMemo(() => {
    let messages: Array<string> = [];
    Object.values(formError).forEach((errorText) => {
      if (errorText !== "") messages.push(errorText);
    });
    return messages;
  }, [formError]);

  useEffect(() => {
    if (
      arrayDataError.length > 0 ||
      JSON.stringify(stateForm) === JSON.stringify(originData)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [stateForm, arrayDataError]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const onChangeText = (field: keyof IWreckerStoreEditData) => (newText: string) => {
    let newStateForm = { ...stateForm, [field]: newText };
    setStateForm(newStateForm);
  };

  const onValidateText = (field: keyof IWreckerStoreEditData, value?: string) => {
    const mess = validator(field, value ?? stateForm[field]);
    setFormError({ ...formError, [field]: mess });
  };

  const validator = (field: keyof IWreckerStoreEditData, value?: string) => {
    let mess: string = "";
    if (!value) value = "";

    switch (field) {
      case "wrecker_name":
        mess = Validation.validate({
          type: "text",
          name: "名称",
          value: value,
          required: true,
          max_length: 30,
        });
        break;
      case "wrecker_address":
        mess = Validation.validate({
          type: "text",
          name: "住所",
          value: value,
          required: false,
          max_length: 30,
        });
        break;
      case "wrecker_tel":
        mess = Validation.validate({
          type: "text",
          name: "TEL",
          value: value,
          required: false,
          max_length: 15,
        });
        break;
    }
    return mess;
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <TextField
            label={
              <>
                <LabelRequired title={"名称"} />
              </>
            }
            value={stateForm.wrecker_name}
            onChange={(e) => {
              onChangeText("wrecker_name")(e.target.value);
            }}
            onBlur={() => onValidateText("wrecker_name")}
            error={formError.wrecker_name.length > 0}
            helperText={formError.wrecker_name}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            label={"住所"}
            value={stateForm.wrecker_address}
            onChange={(e) => {
              onChangeText("wrecker_address")(e.target.value);
            }}
            onBlur={() => onValidateText("wrecker_address")}
            error={formError.wrecker_address.length > 0}
            helperText={formError.wrecker_address}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            label={"TEL"}
            value={stateForm.wrecker_tel}
            onChange={(e) => {
              onChangeText("wrecker_tel")(e.target.value);
            }}
            onBlur={() => onValidateText("wrecker_tel")}
            error={formError.wrecker_tel.length > 0}
            helperText={formError.wrecker_tel}
            inputProps={{
              maxLength: 15,
            }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            onChange(stateForm);
            onClose();
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WreckerStoreEditDialog;
