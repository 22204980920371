import React, { Component } from "react";
import { Alert, AlertColor, Snackbar, SnackbarOrigin } from "@mui/material";

export type SnackbarControllerConfig = {
  message?: string;
  anchorOrigin?: SnackbarOrigin;
  autoHideDuration?: number | null;
  color?: AlertColor;
  severity?: AlertColor;
  variant?: "standard" | "filled" | "outlined";
};

class SnackbarController extends Component<any, any> {
  state: { visible: boolean; config: SnackbarControllerConfig } = {
    visible: false,
    config: {},
  };

  shouldComponentUpdate() {
    return true;
  }

  static overlayInstance: SnackbarController;

  static isShowing() {
    return (
      SnackbarController.overlayInstance &&
      SnackbarController.overlayInstance.state.visible
    );
  }

  static setRef(ref: any) {
    SnackbarController.overlayInstance = ref;
  }

  static show(config?: SnackbarControllerConfig) {
    SnackbarController.overlayInstance._hide();
    if (!config) config = {};
    SnackbarController.overlayInstance._show(config);
  }

  static hide() {
    SnackbarController.overlayInstance._hide();
  }

  _show(config: SnackbarControllerConfig) {
    this.setState({ visible: true, config });
  }

  _hide() {
    this.setState({ visible: false, config: {} });
  }

  constructor(props: any) {
    super(props);
    this._show = this._show.bind(this);
    this._hide = this._hide.bind(this);
  }

  render() {
    const { visible, config } = this.state;
    return (
      <Snackbar
        open={visible}
        anchorOrigin={config.anchorOrigin}
        autoHideDuration={
          config.autoHideDuration === undefined ? 5000 : config.autoHideDuration
        }
        onClose={(e, reason) => {
          if (reason === "clickaway") {
            return;
          }
          this._hide();
        }}
      >
        <Alert
          color={config.color}
          severity={config.severity}
          variant={config.variant}
          sx={{ width: "100%" }}
          onClose={this._hide}
        >
          {config.message?.split("\n").map((v, i) => [v, <br key={i} />])}
        </Alert>
      </Snackbar>
    );
  }
}

export default SnackbarController;
