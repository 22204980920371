import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { Validation } from "@validation";
import ModalController from "@shared-components/modal/ModalController";
import { createFolderApi } from "@api/manageFolder";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import messages from "config/messages";
import { FOLDER_KIND } from "@shared-constants";

interface IProps {
  folderInfo: string;
  openFolderEdit: boolean;
  setOpenFolderEdit: Function;
  getInfoScreen: Function;
}

const FolderEditDialog: VFC<IProps> = ({
  folderInfo,
  openFolderEdit,
  setOpenFolderEdit,
  getInfoScreen,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [errorText, setErrorText] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (openFolderEdit) {
      setName("");
      setErrorText("");
      setDisabled(true);
    }
  }, [openFolderEdit]);

  // ------------------------------------------------------------------
  // フォルダ名入力
  // ------------------------------------------------------------------

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const containsSpecialChars = (str: string) => {
    const specialChars = /[*:"|<>\/?\\]/;
    return specialChars.test(str);
  };

  const validName = () => {
    let error_text = "";
    error_text = Validation.validate({
      type: "text",
      name: "フォルダ名",
      value: name,
      required: true,
      max_length: 50,
    });
    if (containsSpecialChars(name)) {
      error_text =
        messages.ManageFolder.MSG_ERROR_FOLDER_CONTAIN_SPECIAL_CHARACTER;
    }
    setErrorText(error_text);
    setDisabled(error_text.length > 0);
  };

  // ------------------------------------------------------------------
  // フォルダ登録
  // ------------------------------------------------------------------

  const onConfirmCreateFolder = async () => {
    LoadingOverlayController.show();
    const data = new FormData();
    data.append("folder_name", name);
    data.append("prefix_folder_childent", FOLDER_KIND.SUBFOLDER);
    data.append("folder_parentid", folderInfo);
    try {
      const res = await createFolderApi(data);
      if (res) {
        ModalController.show({
          message: messages.ManageFolder.MSG_CREATE_FOLDER_SUCCESS,
          visibleButton2: true,
          handlePressButton2: () => {
            setOpenFolderEdit(false);
            getInfoScreen(false);
          },
        });
      }
    } catch (error: any) {
      console.log(error, "error create folder api");
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={openFolderEdit}
    >
      <DialogContent>
        <FormGroup>
          <Typography variant="body1" sx={{ pb: 2 }}>
            フォルダの追加
          </Typography>
          <Stack>
            <TextField
              name="folder_name"
              placeholder="フォルダ名"
              value={name}
              onChange={changeName}
              required
              onBlur={validName}
              error={errorText.length > 0}
              helperText={errorText}
              inputProps={{
                maxLength: 50,
              }}
            />
          </Stack>
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            setOpenFolderEdit(false);
          }}
          variant="outlined"
        >
          キャンセル
        </Button>
        <Button
          onClick={() => {
            setDisabled(true);
            onConfirmCreateFolder();
          }}
          disabled={disabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FolderEditDialog;
