import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const DATE_FORMAT_INFO = [
  {
    format: "YYYY",
    example: "2025",
    info: "4桁の西暦を表示します。",
  },
  {
    format: "YY",
    example: "25",
    info: "2桁の西暦を表示します。",
  },
  {
    format: "MM",
    example: "04",
    info: "月を数字で表示します。1~9は0をつけて、2桁で表示します。",
  },
  {
    format: "DD",
    example: "01",
    info: "日付を数字で表示します。1~9は0をつけて、2桁で表示します。",
  },
  {
    format: "HH",
    example: "09",
    info: "時間を24時間で表示します。1~9は0をつけて、2桁で表示します。",
  },
  {
    format: "hh",
    example: "08",
    info: "時間を12時間で表示します。1~9は0をつけて、2桁で表示します。",
  },
  {
    format: "mm",
    example: "05",
    info: "分を表示します。1~9は0をつけて、2桁で表示します。",
  },
  {
    format: "ss",
    example: "03",
    info: "秒を表示します。1~9は0をつけて、2桁で表示します。",
  },
  {
    format: "ddd",
    example: "Wed",
    info: "曜日を英文字3字で表示します。",
  },
];

interface IDateFormatInfoDialog {
  open: boolean;
  onClose: () => void;
}

const DateFormatInfoDialog = ({ open, onClose }: IDateFormatInfoDialog) => {
  return (
    <Dialog
      maxWidth="md"
      sx={{ "& .MuiDialog-paper": { width: "50%" }, zIndex: 1300 }}
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Typography>カスタマイズ形式</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={60}>書式</TableCell>
              <TableCell width={60}>例</TableCell>
              <TableCell>説明</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {DATE_FORMAT_INFO.map((item) => (
              <TableRow key={item.format}>
                <TableCell>{item.format}</TableCell>
                <TableCell>{item.example}</TableCell>
                <TableCell>{item.info}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateFormatInfoDialog;
