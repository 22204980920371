import { useMediaQuery } from "@mui/material";
import { theme } from "@template/style";

const useSize = () => {
  // サイズ判定
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return { isSmUp, isMdUp };
};

export { useSize };
