import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  MenuItem,
  SelectChangeEvent,
  ListItemIcon,
  Stack,
} from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { Circle } from "@mui/icons-material";
import { useEffect } from "react";
import { Validation } from "@validation";
import { getDataStorage, STORAGE } from "@utils/Storage";

export interface IData {
  type: string;
  name: string;
  color: string;
  color_id: string;
  color_name: string;
  color_needs: boolean;
  is_manual_input: boolean;
  is_default?: boolean;
}

interface IProps {
  data: IData;
  open: boolean;
  onChange: Function;
  onClose: Function;
}

var colorDatas: any[] = [];

const MasterEditDialog: VFC<IProps> = ({
  data,
  open,
  onChange,
  onClose,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [errorText, setErrorText] = useState("");
  const [type, setType] = useState("");
  const [isManualInput, setIsManualInput] = useState(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [originName, setOriginName] = useState<string>("");
  const [originIsManualInput, setOriginIsManualInput] =
    useState<boolean>(false);
  const [originIsDefault, setOriginIsDefault] = useState<boolean>(false);

  useEffect(() => {
    const setColorData = async () => {
      colorDatas = JSON.parse(await getDataStorage(STORAGE.COLOR_DATA, "[]"));
      const defaltTempData = colorDatas.filter(
        (data: any) => data.is_default === true,
      );
      setColor(
        data.color_id && data.color_id === ""
          ? defaltTempData[0].color_id
          : data.color_id,
      );
    };
    const setData = () => {
      setName(data.name ?? "");
      setType(data.type);
      setOriginName(data.name ?? "");
      setIsManualInput(data.is_manual_input ?? false);
      setOriginIsManualInput(data.is_manual_input ?? false);
      setIsDefault(data.is_default ?? false);
      setOriginIsDefault(data.is_default ?? false);
      setErrorText("");
    };

    if (open) {
      if (data.color_needs) setColorData();
      setData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeColor = (e: SelectChangeEvent) => {
    setColor(e.target.value);
  };

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: type,
      value: name,
      required: true,
      max_length: 30,
    });
    setErrorText(error_text);
  };

  const isDisableOKButton = React.useMemo(() => {
    const isDataChanged =
      name !== originName ||
      isManualInput !== originIsManualInput ||
      isDefault !== originIsDefault;

    return name?.trim().length === 0 || !isDataChanged || errorText.length > 0;
  }, [
    name,
    isManualInput,
    isDefault,
    originName,
    originIsManualInput,
    originIsDefault,
    errorText,
  ]);

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <TextField
              label={
                <>
                  <LabelRequired title={type} />
                </>
              }
              name="master_name"
              value={name}
              onChange={changeName}
              onBlur={validName}
              error={errorText.length > 0}
              helperText={errorText}
              inputProps={{
                maxLength: 30,
              }}
            />
            {data.color_needs && (
              <SelectLabel
                label="色"
                name="master_color"
                value={color}
                isIgnoreExtractRuleLabel={false}
                isIgnoreExtractRuleSelect={true}
                onChange={changeColor}
                inputProps={{
                  sx: {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                required
              >
                {colorDatas.map((item) => (
                  <MenuItem value={item.color_id} key={item.color_id}>
                    <ListItemIcon sx={{ mr: 1 }}>
                      <Circle sx={{ color: item.color_code }} />
                    </ListItemIcon>
                    <span className="IgnoreExtractRuleTarget">
                      {item.color_name}
                    </span>
                  </MenuItem>
                ))}
              </SelectLabel>
            )}
          </Stack>
          {!data.color_needs && (
            <div>
              <div>
                <CheckboxLabel
                  label="手入力有り"
                  checked={isManualInput}
                  onChange={(e) => {
                    setIsManualInput(e.target.checked);
                  }}
                  formSx={{ pt: 2 }}
                />
              </div>
              <div>
                <CheckboxLabel
                  label="デフォルト値"
                  checked={isDefault}
                  onChange={(e) => {
                    setIsDefault(e.target.checked);
                  }}
                  // formSx={{ pt: 2 }}
                />
              </div>
            </div>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const selected_color = colorDatas.filter(
              (item) => item.color_id === color,
            );
            const response: IData = {
              type: type,
              name: name,
              color: data.color_needs ? selected_color[0].color_code : "",
              color_id: data.color_needs ? selected_color[0].color_id : "",
              color_name: data.color_needs ? selected_color[0].color_name : "",
              color_needs: data.color_needs,
              is_manual_input: isManualInput,
              is_default: isDefault,
            };
            onChange(response);
            onClose();
          }}
          disabled={isDisableOKButton}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MasterEditDialog;
