import { Box, FormLabel, Typography } from "@mui/material";

interface ILabelValueText {
  label?: string;
  value?: string;
  addBreak?: boolean;
  isIgnoreExtractRule?: boolean;
}

const BodyText = ({
  text,
  addBreak,
}: {
  text?: string;
  addBreak?: boolean;
}) => {
  if (!text) return null;

  const text_array = addBreak ? text.split(/<br>|\n/g) : [text];

  return (
    <>
      {text_array.map((item, i) => {
        return (
          <Typography
            variant="body2"
            key={i}
            sx={{
              wordBreak: "break-all",
              minHeight: "1.43em",
            }}
          >
            {item}
          </Typography>
        );
      })}
    </>
  );
};

const LabelValueText = ({
  label,
  value,
  addBreak = true,
  isIgnoreExtractRule,
}: ILabelValueText) => {
  const ignoreExtractRuleClass = isIgnoreExtractRule
    ? "IgnoreExtractRuleTarget"
    : "";
  return (
    <Box>
      <FormLabel sx={{ pb: "3px" }}>{label}</FormLabel>
      <div className={ignoreExtractRuleClass}>
        <BodyText text={value} addBreak={addBreak} />
      </div>
    </Box>
  );
};

export default LabelValueText;
