import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormGroup,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import LabelValueText from "components/atoms/LabelValueText";
import messages from "config/messages";
import { RootState } from "store/reducer";
import GenericTemplate from "@template/index";
import { Colors } from "@template/style";
import { Work } from "services/models";
import { getDetailProjectTlog } from "@api/project";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import {
  getListHistoryRejectApi,
  getWorkByIdApi,
  updateWorkByIdApi,
} from "@api/work";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { createFormUpload, getFileByUrl } from "shared/utils";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { getUserInfo } from "@utils/index";
import formatDateToString from "@utils/DateFormat";
import FormContextReportConfirm from "./FormContextReportConfirm";
import { SelectImageFileConfirm } from "./FormContextReportConfirm/SelectFileReportConfirm";
import HistoryRejectWork from "screens/HistoryRejectWork";
import {
  STATUS_WORK_TLOG,
  TYPE_USER_REPORT,
  deleteImageDataWhenFinish,
  screenIdSupportMergeTemplate,
  StatisticalBoardType,
} from "@shared-constants";
import _, { cloneDeep } from "lodash";
import { createFileNameFollowUri } from "shared/utils";
import { screenIdSupportGetImageUrl } from "screens/CreateReportTlog/templateFunctionSupport";
import { TYPES } from "store/types";
import { getDetailProjectByWorkId } from "@api/project";
import { useLocation } from "react-router-dom";

interface CreateReportWork extends Work {
  work_template_id: any;
  files?: Array<{
    SK?: string;
    file_name?: string;
    file_type?: string;
    path_file?: string;
  }>;
  images?: Array<{
    SK?: string;
    file_name?: string;
    file_type?: string;
    path_file?: string;
    path_file_thumb?: string;
    file_size?: string;
  }>;
}

const templateDefault = {
  screen_id: "",
  template_path: "",
  uri: "",
  type: "",
  name: "",
  templateEditInfo: null,
};

const WorkConfirmTlogScreen = ({ route, navigation }: any) => {
  const { work_id: mail_work_id, user_id: mail_user_id } = useSelector(
    (state: RootState) => {
      return state.mailConfirm.work;
    },
  );
  const { selected_project_id, selected_work_id } = useSelector(
    (state: RootState) => {
      return state.projectInfo;
    },
  );
  const location = useLocation<any>();
  const request_or_register =
    location.state === undefined
      ? StatisticalBoardType.register
      : location.state.request_or_register;
  const [work, setWork] = useState<null | Work>(null);
  const [template, setTemplate] = useState(cloneDeep(templateDefault));
  const [originTemplate, setOriginTemplate] = useState(
    cloneDeep(templateDefault),
  );
  const [listReject, setListReject] = useState<Array<Object>>([]);
  const [openHistoryReject, setOpenHistoryReject] = useState<boolean>(false);
  const [comment, setComment] = useState<String>("");
  const [showComment, setShowComment] = useState<boolean>(false);
  const [mailWorkIdConfig, setMailWorkIdConfig] = useState<String>("");

  const history = useHistoryCustom();
  const dispatch = useDispatch();

  const refFormContext = useRef<any>(null);
  const refRevoked = useRef<boolean>(false);

  const user = getUserInfo();

  const getWorkById = async () => {
    try {
      const res = await getWorkByIdApi({
        project_id: selected_project_id,
        work_id: selected_work_id,
      });
      if (res.data) {
        setWork(res.data);
        mergeTemplate(res.data);
      }
    } catch (e: any) {
      if (e.code && e.code === 401) {
        refRevoked.current = true;
      } else if (e?.detail.message) {
        ModalController.show({
          message: e?.detail.message,
          visibleButton2: true,
        });
      }
    }
  };

  const getListRejectWork = async () => {
    try {
      const res = await getListHistoryRejectApi(selected_work_id);
      if (res.data) {
        setListReject(res.data);
      }
    } catch (e: any) {
      if (e.code && e.code === 401) {
        refRevoked.current = true;
      } else if (e?.detail.message) {
        ModalController.show({
          message: e?.detail.message,
          visibleButton2: true,
        });
      }
    }
  };

  const mergeTemplate = async (workData: Work | null) => {
    if (!workData) {
      return;
    }
    const templateData = workData?.work_template_id;
    if (!templateData) {
      return;
    }
    const { template_path, screen_id, work_template_data } = templateData;
    let newStateFormEditInfo = JSON.parse(work_template_data ?? "null");
    const fileName = createFileNameFollowUri(template_path);
    let localFile = template_path;
    let newPath: string | null = null;
    if (newStateFormEditInfo) {
      newStateFormEditInfo = await screenIdSupportGetImageUrl[screen_id](
        newStateFormEditInfo,
      );
      newPath = await screenIdSupportMergeTemplate[screen_id](
        localFile,
        newStateFormEditInfo,
        templateData,
      );
    }
    if (!newPath || newPath == localFile) {
      ModalController.show({
        message: messages.TEMPLATE.MSG_ERROR_GET_TEMPLATE,
        visibleButton2: true,
        handleClose: () => {
          history.goBack();
        },
      });
      return;
    }
    const newTemplate = {
      screen_id: screen_id,
      template_path: template_path,
      uri: newPath ? newPath : localFile,
      name: fileName,
      type: "text/html",
      templateEditInfo: newStateFormEditInfo,
    };
    setTemplate((prev) => ({ ...prev, ...templateData, ...newTemplate }));
    setOriginTemplate((prev) => ({ ...prev, ...templateData, ...newTemplate }));
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();
      await getListRejectWork();
      await getWorkById();
    } catch (e: any) {
      ModalController.show({
        message: e?.response?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    if (selected_work_id == "" && mail_work_id == "") {
      // 直接画面を起動した場合は、トップ画面へ遷移
      history.pushWithRef("/");
    }

    window.scrollTo(0, 0);

    return () => {
      dispatch({
        type: TYPES.SET_SELECTED_WORK_ID,
        selected_work_id: "",
        selected_work_name: "",
      });
      if (
        (mail_work_id !== "" || mail_user_id !== "") &&
        !refRevoked?.current
      ) {
        dispatch({
          type: TYPES.SET_MAIL_WORK_CONFIRM,
          work_id: "",
          user_id: "",
        });
      }
    };
  }, []);

  useEffect(() => {
    if (selected_work_id != "" && selected_project_id != "") getData();
  }, [selected_work_id, selected_project_id]);

  useEffect(() => {
    // メール通知のURLから遷移したとき
    const dispatchWorkInfo = async () => {
      try {
        // const res = await getProjectDefaultApi();
        const res = await getDetailProjectByWorkId(mail_work_id);
        if (res?.data) {
          dispatch({ type: TYPES.SET_PROJECT_INFO, payload: res?.data });
          dispatch({
            type: TYPES.SET_SELECTED_PROJECT_ID,
            selected_project_id: res?.data.SK,
            selected_project_name: res?.data.project_name,
          });
          dispatch({
            type: TYPES.SET_SELECTED_WORK_ID,
            selected_work_id: mail_work_id,
            //   selected_work_name: work_name,
          });

          dispatch({
            type: TYPES.SET_MAIL_WORK_CONFIRM,
            work_id: "",
            user_id: "",
          });
        }
      } catch (e: any) {
        if (e.code && e.code === 401) {
          refRevoked.current = true;
        }
      }
    };

    if (mail_work_id !== "") {
      // if (user.SK === mail_user_id) return; // メール通知ユーザー以外が開く時は何もしない
      setMailWorkIdConfig(mail_user_id);
      dispatchWorkInfo();
    }
  }, [mail_work_id, mail_user_id]);

  useEffect(() => {
    // 変更有無をチェック
    setIsEdited(!_.isEqual(originTemplate, template));
  }, [template, originTemplate]);

  // 保存確認ポップアップ
  const { setIsEdited, gobackRef } = useRouterPrompt({
    onCancel: () => {
      setIsEdited(true);
    },
    onOK: () => {
      if (gobackRef.current) {
        window.history.pushState(null, "", null);
        setIsEdited(false);
        setTimeout(() => {
          if (mail_work_id !== "") {
            window.location.href = "/";
          } else {
            window.history.go(-1);
          }
        }, 10);
        return false;
      }
      return true;
    },
    message: messages.COMMON.MSG_CONFIRM_DELETE_OK,
  });

  const renderReject = (item: any) => {
    if (!item) return;
    const dateCreate = item?.created_at
      ? formatDateToString(item?.created_at, "YMD_sl")
      : "";
    return (
      <>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="h6" sx={{ px: 3, display: "flex" }}>
            <div className="IgnoreExtractRuleTarget">{dateCreate} </div>
            &nbsp;{`(差し戻し 対応日付)`}
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>

        <Card>
          <CardHeader title="差し戻し情報" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={{ pb: 0 }}>
                <LabelValueText
                  label="否認者"
                  value={item?.reject_by_name}
                  isIgnoreExtractRule={true}
                />
                <LabelValueText
                  label="内容"
                  value={item?.memo}
                  isIgnoreExtractRule={true}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="添付" sx={styles.header} />
          <CardContent>
            <SelectImageFileConfirm
              selectedImages={item?.images}
              selectedFiles={item?.files}
            />
          </CardContent>
        </Card>
      </>
    );
  };

  const renderUserReport = () => {
    switch (work?.work_status) {
      case STATUS_WORK_TLOG.CANCEL:
        return !work?.is_confirm ? <ConfirmButton /> : null;
      case STATUS_WORK_TLOG.DRAFT:
        return null;
      case STATUS_WORK_TLOG.NOT_APPROVED:
        return work?.checking_approve_flag ? (
          <ButtonContextApprove showCancel={work?.is_redo_work} />
        ) : work?.is_redo_work ? (
          <CancelButton />
        ) : null;
      case STATUS_WORK_TLOG.REJECT:
        return work?.is_recreate_from_reject &&
          work?.rejected_in_step === "1" ? (
          <Stack direction={"row"} spacing={1}>
            <CreateReportButton
              title="申請修正"
              userType={TYPE_USER_REPORT.CREATE}
            />
            <Button
              color="error"
              onClick={() => {
                if (request_or_register === "register") {
                  //申請から入った場合
                  handleCancelWorkReject();
                } else if (request_or_register === "request") {
                  //承認依頼から入った場合
                  handleConfirmModal(
                    "REDO",
                    messages.WORK_ITEM.MSG_CANCEL_WORK,
                  );
                }
              }}
              children="キャンセル"
            />
          </Stack>
        ) : work?.is_recreate_from_reject ? (
          <CreateReportButton
            title="報告"
            userType={TYPE_USER_REPORT.APPROVE}
            showCancel={work?.is_redo_work && request_or_register === "request"}
          />
        ) : work?.is_redo_work && request_or_register === "request" ? (
          <CancelButton />
        ) : null;
      case STATUS_WORK_TLOG.FINISH:
        return !work?.is_confirm ? <ConfirmButton /> : null;
      default:
        null;
    }
  };

  const CheckFinish = () => {
    const str = work?.approval_status;
    const index = str?.indexOf("/");
    if (index !== undefined && index != -1) {
      const start = Number(str?.slice(0, index));
      const end = Number(str?.slice(index + 1));
      return start + 1 == end;
    }
  };

  const renderUserApprove = () => {
    switch (work?.work_status) {
      case STATUS_WORK_TLOG.CANCEL:
        return !work?.is_confirm && work?.is_user_in_flow_approve ? (
          <ConfirmButton />
        ) : null;
      case STATUS_WORK_TLOG.DRAFT:
        return null;
      case STATUS_WORK_TLOG.NOT_APPROVED:
        return work?.checking_approve_flag ? (
          <ButtonContextApprove showCancel={work?.is_redo_work} />
        ) : work?.is_redo_work ? (
          <CancelButton />
        ) : null;
      case STATUS_WORK_TLOG.FINISH:
        return <ConfirmButton />;
      case STATUS_WORK_TLOG.REJECT:
        return work?.is_recreate_from_reject ? (
          <CreateReportButton
            title="報告"
            userType={TYPE_USER_REPORT.APPROVE}
            showCancel={work?.is_redo_work}
          />
        ) : work?.is_redo_work ? (
          <CancelButton />
        ) : null;
      default:
        null;
    }
  };

  const ButtonContextApprove = ({ showCancel }: { showCancel?: boolean }) => {
    useEffect(() => {
      setShowComment(true); // コメント欄を表示
    }, []);
    return (
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        spacing={0}
        justifyContent={"center"}
        width={{ xs: 235, md: "100%" }}
      >
        <Button color="warning" sx={{ mx: 1 }} onClick={handleReject}>
          差戻
        </Button>
        <Button
          color="error"
          sx={{ mx: 1 }}
          onClick={() =>
            handleConfirmModal(
              STATUS_WORK_TLOG.CANCEL,
              messages.WORK_ITEM.MSG_REJECTION_WORK,
            )
          }
        >
          却下
        </Button>
        <Button
          sx={{
            mx: 1,
            width: { xs: showCancel ? 100 : "100%", md: 100 },
            mt: { xs: 1, md: 0 },
          }}
          onClick={() => {
            if (CheckFinish()) {
              ModalController.show({
                message: messages.WORK_ITEM.MSG_CONFIRM_FINISH_WORK,
                visibleButton1: true,
                visibleButton2: true,
                handlePressButton2: () => updateStatusFinishWork(),
              });
            } else {
              updateStatusFinishWork();
            }
          }}
        >
          承認
        </Button>
        {showCancel && <CancelButton sx={{ mx: 1, mt: { xs: 1, md: 0 } }} />}
      </Stack>
    );
  };

  const CreateReportButton = ({
    title,
    userType,
    showCancel,
  }: {
    title: string;
    userType: string;
    showCancel?: boolean;
  }) => {
    return (
      <Stack direction={"row"} spacing={1}>
        <Button
          onClick={async () => {
            if (work?.SK) {
              // 帳票情報を取得する
              const res = await getDetailProjectByWorkId(work.SK);
              if (res?.data) {
                dispatch({ type: TYPES.SET_PROJECT_INFO, payload: res?.data });
              }
            }
            setIsEdited(false);
            setTimeout(() => {
              history.pushWithRef("/work/create", {
                data: work,
                typeAction: "1",
                work_type: "",
                userType: userType,
                mail_work_id: mailWorkIdConfig,
              });
            }, 10);
          }}
        >
          {title}
        </Button>
        {showCancel && <CancelButton />}
      </Stack>
    );
  };

  const ConfirmButton = () => {
    return (
      <Button onClick={() => updateStatusFinishWork("CONFIRM")}>確認</Button>
    );
  };

  const CancelButton = ({ sx }: { sx?: SxProps<Theme> }) => {
    return (
      <Button
        color="error"
        onClick={() =>
          handleConfirmModal("REDO", messages.WORK_ITEM.MSG_CANCEL_WORK)
        }
        sx={sx}
      >
        キャンセル
      </Button>
    );
  };

  const handleCancelWorkReject = () => {
    if (!work || !work.flow_approve_data || !work.flow_approve_id?.SK) {
      return;
    }

    const { flow_approve_data, flow_approve_id } = work;
    const dataAdd = {
      flow_data: JSON.stringify({ data: flow_approve_data }),
      flow_id: flow_approve_id?.SK,
    };
    handleConfirmModal(
      STATUS_WORK_TLOG.CANCEL_REQUEST,
      messages.WORK_ITEM.MSG_CANCEL_WORK,
      dataAdd,
    );
  };

  const handleConfirmModal = (
    status_work: string,
    confirm_message: string,
    dataAdd?: any,
  ) => {
    if (!ModalController.isShowing())
      ModalController.show({
        message: confirm_message,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton2: () => updateStatusFinishWork(status_work, dataAdd),
      });
  };

  const getIsUseDevanSchedule = async () => {
    try {
      const res = await getDetailProjectTlog(selected_project_id);
      return res.data?.is_use_devan_schedule ?? false;
    } catch (e: any) {
      console.log(e);
      ModalController.show({
        message: messages.COMMON.MSG_COMMON_ERROR_001,
        visibleButton2: true,
      });
      return;
    }
  };

  const updateStatusFinishWork = async (
    status: string = "FINISH",
    dataAdd?: any,
  ) => {
    /*** バリデーション ***/
    if (refFormContext.current) {
      if (status === "FINISH" && refFormContext.current.validationAllCheck()) {
        return;
      }
    }
    if (!work) {
      return;
    }

    try {
      LoadingOverlayController.show();
      let work_data;
      const { PK, SK, work_template_id, flow_approve_data, flow_approve_id } =
        work;
      const { templateEditInfo, uri, type, name } = template;
      let templateEditInfoNew = cloneDeep(templateEditInfo);

      if (CheckFinish() && work_template_id) {
        templateEditInfoNew =
          deleteImageDataWhenFinish[work_template_id?.screen_id ?? ""](
            templateEditInfoNew,
          );
      }
      work_data = {
        work_status: status ?? "FINISH",
        files: null,
        images: null,
        memo: comment ?? null,
        work_template_data: JSON.stringify(templateEditInfoNew),
        work_template_id: `TEMPLATE_WORK#${work_template_id?.template_id}`,
        template_work_sk: work_template_id?.SK,
        flow_data: JSON.stringify({ data: flow_approve_data }),
        flow_id: flow_approve_id?.SK,
      };
      if (dataAdd) {
        work_data = { ...work_data, ...dataAdd };
      }
      const formData = createFormUpload(null, work_data);
      if (uri) {
        const f = await getFileByUrl(uri, name, type);
        formData.append("work_template_file", f);
      }
      //帳票情報を取得
      const isUseDevanSchedule = await getIsUseDevanSchedule();
      formData.append("is_use_devan_schedule", isUseDevanSchedule);
      formData.append("request_or_register", request_or_register);
      const res = await updateWorkByIdApi(PK ?? "", SK ?? "", formData);
      //確認ダイアログがでないようにする
      setIsEdited(false);
      setTimeout(() => {
        if (res) {
          if (mailWorkIdConfig !== "") {
            //トップページに遷移
            history.pushWithRef("/");
          } else {
            //前ページに遷移
            history.goBack();
          }
        }
      }, 10);
    } catch (e: any) {
      console.log("e :>> ", e);
      if (e?.detail?.code == 9 || e?.detail?.code == 11) {
        ModalController.show({
          message: e?.detail?.message ?? "",
          visibleButton2: true,
        });
      } else
        ModalController.show({
          message: e?.response?.detail.message,
          visibleButton2: true,
        });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 差戻
  const handleReject = () => {
    if (refFormContext.current && refFormContext.current.validationAllCheck()) {
      return;
    }
    setIsEdited(false);
    setTimeout(() => {
      history.pushWithRef("/work/reject", {
        data: work,
        template: template,
        disable: listReject.length ? false : true,
        mail_work_id: mailWorkIdConfig,
      });
    }, 10);
  };

  return (
    <GenericTemplate title="作業内容確認">
      <Stack>
        {work?.work_status != "FINISH" ? renderReject(listReject[0]) : null}
        <FormContextReportConfirm
          ref={refFormContext}
          data={work}
          template={template}
          setTemplate={setTemplate}
        />
        {showComment && (
          <Card>
            <CardContent>
              <TextField
                label="コメント"
                fullWidth
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </CardContent>
          </Card>
        )}
      </Stack>
      <Box sx={styles.buttonArea}>
        {work?.created_by == user?.user_id
          ? renderUserReport()
          : renderUserApprove()}
      </Box>
      <HistoryRejectWork
        open={openHistoryReject}
        setOpen={setOpenHistoryReject}
        data={work}
      />
    </GenericTemplate>
  );
};

export default WorkConfirmTlogScreen;

const styles = {
  header: { bgcolor: Colors.YELLOW },
  buttonArea: { mt: 2, display: "flex", justifyContent: "center" },
} as const;
