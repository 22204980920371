import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";

type SelectLabelProps<T = unknown> = Omit<
  SelectProps<T>,
  "label" | "helperText"
> & {
  label: React.ReactNode;
  helperText?: string;
  isIgnoreExtractRuleLabel?: boolean;
  isIgnoreExtractRuleSelect?: boolean;
};

const SelectLabel = <T,>({
  helperText,
  isIgnoreExtractRuleLabel, //ラベル翻訳
  isIgnoreExtractRuleSelect, //セレクタ翻訳
  ...props
}: SelectLabelProps<T>) => {
  const ignoreExtractRuleLabelClass = isIgnoreExtractRuleLabel
    ? "IgnoreExtractRuleTarget"
    : "";

  const ignoreExtractRuleSelectClass = isIgnoreExtractRuleSelect
    ? "IgnoreExtractRuleTarget"
    : "";
  return (
    <FormControl disabled={props.disabled} error={props.error}>
      <span className={ignoreExtractRuleLabelClass}>
        <InputLabel required={props.required}>{props.label}</InputLabel>
      </span>
      <Select
        {...props}
        className={
          props.value !== "defaultValue" && props.value !== ""
            ? ignoreExtractRuleSelectClass
            : ""
        }
      />
      {helperText && helperText.length > 0 && (
        <FormHelperText error={props.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectLabel;
