import { TypeNoticeEmail } from "screens/Manage/TypeNoticeEmail";
import { TYPES } from "store/types";

export type State = {
  selected_tab_index: number;
  isChanged: boolean;
};

const initialState: State = {
  selected_tab_index: 0,
  isChanged: false,
};

const set_tab_index = (payload: { selected_tab_index: number }) => ({
  type: TYPES.SET_TAB_INDEX,
  ...payload,
});

const set_is_changed = (payload: { isChanged: boolean }) => ({
  type: TYPES.SET_IS_CHANGED,
  ...payload,
});

export type Actions = ReturnType<typeof set_tab_index | typeof set_is_changed>;

export const manageReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case TYPES.SET_TAB_INDEX:
      return {
        ...state,
        selected_tab_index: action.selected_tab_index,
      };
    case TYPES.SET_IS_CHANGED:
      return {
        ...state,
        isChanged: action.isChanged,
      };
    default:
      return state;
  }
};
