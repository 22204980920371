import { useEffect, useRef } from "react";
import { Rect, Transformer } from "react-konva";
import { DrawItem } from "services/models";
import { itemWidth } from "@shared-constants";

interface IProps {
  data: DrawItem;
  onClick: () => void;
  selected: boolean;
  onChange: (v: any) => void;
}

const MIN_SIZE = 5 as const;

/**
 * 四角
 */
const DrawRectangle = ({ data, onClick, selected, onChange }: IProps) => {
  const shapeRef = useRef<any>();
  const trRef = useRef<any>();

  const attachTransformer = () => {
    trRef.current.nodes([shapeRef.current]);
    trRef.current.getLayer().batchDraw();
  };

  const handleResize = () => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    shapeRef.current?.setAttrs({
      x: node.x(),
      y: node.y(),
      width: Math.max(MIN_SIZE, node.width() * scaleX),
      height: Math.max(node.height() * scaleY),
      scaleX: 1,
      scaleY: 1,
    });
  };

  const updateDrawItem = () => {
    const node = shapeRef.current;
    onChange({
      x: node.x(),
      y: node.y(),
      width: node.width(),
      height: node.height(),
    });
  };

  useEffect(() => {
    if (selected && trRef.current) {
      attachTransformer();
    }
  }, [selected]);

  return (
    <>
      <Rect
        ref={shapeRef}
        x={data.data?.x ?? 0}
        y={data.data?.y ?? 0}
        width={data.data?.width ?? 100}
        height={data.data?.height ?? 100}
        stroke={data.colorMode}
        strokeWidth={itemWidth[data.strokeWidthMode]}
        onClick={onClick}
        draggable
        onTransform={handleResize}
        onTransformEnd={() => {
          updateDrawItem();
          attachTransformer();
        }}
        onDragStart={selected ? undefined : onClick}
        onDragEnd={updateDrawItem}
      />
      {selected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // リサイズ制限
            if (
              Math.abs(newBox.width) < MIN_SIZE ||
              Math.abs(newBox.height) < MIN_SIZE
            ) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default DrawRectangle;
