import { TYPES } from "store/types";

type settingData = {
  signature_pen_min_width: number;
  signature_pen_max_width: number;
};

const initialState: settingData = {
  signature_pen_min_width: 0.5,
  signature_pen_max_width: 6.0,
};

export const editTemplateReducer = (
  state = initialState,
  { payload, type }: any,
) => {
  switch (type) {
    case TYPES.SET_EDIT_TEMPLATE: {
      return { ...state, ...payload };
    }
    case TYPES.CLEAR_EDIT_TEMPLATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
