import { TYPES } from "store/types";

type DevanSearch = {
  date_of_delivery: string;
  delivery_location: string;
  container_no: string;
  berth_number: string;
};

const initialDevanSearch: DevanSearch = {
  date_of_delivery: "",
  delivery_location: "",
  container_no: "",
  berth_number: "",
};

export const devanSearchReducer = (
  state = initialDevanSearch,
  { payload, type }: any,
) => {
  switch (type) {
    case TYPES.SET_DEVAN_SEARCH: {
      return { ...state, ...payload };
    }
    case TYPES.CLEAR_DEVAN_SEARCH: {
      return { ...initialDevanSearch };
    }
    default:
      return state;
  }
};
