import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "@template/style";
import _, { cloneDeep, debounce } from "lodash";
import {
  ICheckItem,
  ICheckResult,
  StateFormType,
  getInfoFromTemplate,
  makeNewHtml,
} from "@utils/template/forkliftReport";
import formatDateToString from "@utils/DateFormat";
import SelectLabel from "components/atoms/SelectLabel";
import LabelRequired from "components/atoms/LabelRequired";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getListUserByLocationId } from "@api/User";
import { getUserInfo } from "@utils/index";
import { User } from "services/models";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import { Validation } from "@validation";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import {
  getListForkLiftCheckItemAll,
  getVehiclesByFrameNo,
} from "@api/itemReport";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { checkForkliftWorkExist } from "@api/work";

interface ITemplateForkliftReport {
  infoToEdit?: StateFormType;
  htmlString: string;
  setHtmlString: Function;
  isManage?: boolean;
  open: boolean;
  template_id: string;
  work_id: string;
  is_hand_over?: boolean;
}

const originData: StateFormType = {
  risky_id: "",
  vehicle_id: "",
  vehicles_frame_no: "",
  vehicles_workplace: "",
  vehicles_model: "",
  vehicles_fuel_type: "",
  vehicles_fuel_type_id: "",
  vehicles_name: "",
  driver: null,
  driver_other: "",
  year_month: formatDateToString(new Date(), "YM"),
  check_item: [],
  check_result: {},
  memo: "",
};

let initialResultData: ICheckResult = {
  check_user: null,
  check_user_other: "",
  result: {},
  approver: "",
  approved: false,
};

const TemplateForkliftReport = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    isManage = false,
    open,
    template_id,
    work_id,
    is_hand_over,
  }: ITemplateForkliftReport,
  ref: React.Ref<unknown>,
) => {
  const [stateForm, setStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [formError, setFormError] = useState<{ [field: string]: string }>({
    year_month: "",
  });
  const [addIsManualListUser, setAddIsManualListUser] = useState<Array<any>>(
    [],
  );
  const [checkAll, setCheckAll] = useState(false);
  const [indexDate, setIndexDate] = useState<number>(new Date().getDate() - 1);
  const [listCheckItem, setListCheckItem] = useState<Array<ICheckItem>>([]);
  const refUserInfo = useRef<Object>();
  const userInfo: User = getUserInfo();
  const dateScrollViewRef = React.useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    getStateForm: () => stateForm,
    getOriginStateForm: () => originStateForm,
    disabledSubmit: () => Object.values(formError).some((v) => v != ""),
    disabledPreview: () => Object.values(formError).some((v) => v != ""),
  }));

  // マスタデータ取得 =====================================
  const addWhiteSpace = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(0, 0, undefined);
    return data;
  };

  const addIsManual = (data: Array<any>) => {
    if (data.length == 0) {
      return data;
    }
    data.splice(1, 0, {
      PK: "0",
      SK: "0",
      full_name: "その他(手入力)",
      is_manual_input: true,
      is_vertical_writing_sign: false,
      seal_display_name: "",
      seal_file_path: null,
    });
    return data;
  };

  const getData = async () => {
    try {
      LoadingOverlayController.show();

      const resListUser = await getListUserByLocationId();
      if (resListUser?.length) {
        const newListUser = addWhiteSpace(resListUser).concat();
        setAddIsManualListUser(addIsManual(newListUser));

        const find_user = newListUser.find((v) => v && v.SK === userInfo.SK);
        if (find_user) refUserInfo.current = find_user;
      }
    } catch (err) {
      console.log(err);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    if (!open) return;
    const isUpdate = typeof infoToEdit?.memo === "string";
    getData().then(() => {
      if (isUpdate) {
        let check_result = {};
        infoToEdit?.check_item.map((item: ICheckItem) => {
          check_result = { ...check_result, [item.id]: undefined };
        });
        initialResultData = {
          check_user: refUserInfo.current,
          check_user_other: "",
          result: check_result,
          approver: "",
          approved: false,
        };
        let newStateForm = { ...infoToEdit };
        if (!newStateForm.check_result[indexDate]) {
          newStateForm = {
            ...cloneDeep(newStateForm),
            check_result: {
              ...newStateForm.check_result,
              [indexDate]: initialResultData,
            },
          };
        }

        setStateForm(newStateForm);
        setOriginStateForm(newStateForm);
      } else {
        let newStateForm = {
          ...cloneDeep(originData),
          driver: refUserInfo.current,
          check_item: [...listCheckItem],
          check_result: {
            [indexDate]: initialResultData,
          },
        };

        setStateForm(newStateForm);
        setOriginStateForm(newStateForm);
      }
    });
  }, [open]);

  useEffect(() => {
    let check_result = {};
    listCheckItem.map((item) => {
      check_result = { ...check_result, [item.id]: undefined };
    });
    initialResultData = {
      check_user: refUserInfo.current,
      check_user_other: "",
      result: check_result,
      approver: "",
      approved: false,
    };

    setStateForm((prevState) => ({
      ...prevState,
      driver: refUserInfo.current,
      check_item: [...listCheckItem],
      check_result: {
        [indexDate]: initialResultData,
      },
    }));
  }, [listCheckItem]);

  // HTML取得 =====================================
  const getHtmlString = useCallback(
    async (
      state_form: StateFormType,
      html_String: string,
      index_date: number,
    ) => {
      const res = getInfoFromTemplate(html_String);

      // 変更なしの場合は保存しない
      let htmlStateForm = cloneDeep(state_form);
      if (!isEdited(index_date, htmlStateForm)) {
        delete htmlStateForm.check_result[index_date];
      }

      let newHtml: string = makeNewHtml(
        html_String,
        htmlStateForm,
        res.addPageExample,
      );
      setHtmlString(newHtml);
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString, indexDate);
  }, [stateForm, htmlString, indexDate]);

  // 入力制御 =====================================
  const onChangeText =
    (field: string) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setStateForm({ ...stateForm, [field]: e.target.value });
    };

  const onChangeTextResult =
    (field: string, index_date?: number) => (newText: any) => {
      let date = index_date ? index_date : indexDate;
      setStateForm({
        ...stateForm,
        check_result: {
          ...stateForm.check_result,
          [date]: {
            ...stateForm.check_result[date],
            [field]: newText,
          },
        },
      });
    };

  const onChangeSelect =
    (field: string) => (list: any[], e: SelectChangeEvent<string>) => {
      const selected = list.find((item) => item && item.SK === e.target.value);
      setStateForm((prev) => ({ ...prev, [field]: selected }));
    };

  const onChangeDate = (field: string) => (value: string | null) => {
    if (!value) {
      value = "";
    } else {
      value = formatDateToString(value, "YM");
    }

    let mess = "";
    if (value) {
      mess = validator(field, value);
    }
    setFormError({ ...formError, [field]: mess });
    setStateForm((prev) => ({ ...prev, [field]: value }));
    if (mess == "") checkReportExist(value, stateForm.vehicle_id);
  };

  const validator = (field: string, value: string) => {
    let mess: string = "";

    switch (field) {
      case "year_month":
        mess = Validation.validateDate(value, "実施年月", true);
        break;
    }
    return mess;
  };

  const renderMenuItem = useCallback(
    (list: Array<any>, value: string, name: string) => {
      return list
        ? list.map((item, index) => (
            <MenuItem value={item ? item[value] : ""} key={index}>
              {item ? (
                item[value] !== "0" ? (
                  <span className="IgnoreExtractRuleTarget">{item[name]}</span>
                ) : (
                  // その他(手入力)は翻訳
                  <span>{item[name]}</span>
                )
              ) : (
                <em>未選択</em>
              )}
            </MenuItem>
          ))
        : null;
    },
    [],
  );

  // 車両検索 =====================================
  const checkReportExist = async (ym: string, vehicle_id: string) => {
    if (ym == "" || vehicle_id == "") return;
    checkForkliftWorkExist({
      template_id: template_id.replace("TEMPLATE_WORK#", ""),
      project_location_id: userInfo?.location_id,
      date: ym,
      vehicle_id: vehicle_id,
      work_id: work_id,
    }).then((res) => {
      if (res) {
        ModalController.show({
          message: messages.TEMPLATE.MSG_TEMPLATE_EXIST,
          visibleButton2: true,
        });
      }
    });
  };

  const handleVehicleSearch = async () => {
    const location_id = userInfo?.location_id;
    if (!location_id) return;

    try {
      LoadingOverlayController.show();
      const fuel_id = stateForm.vehicles_fuel_type_id;
      var data = {
        SK: "",
        vehicles_frame_no: "",
        vehicles_workplace: "",
        vehicles_model: "",
        vehicles_fuel_type: "",
        vehicles_fuel_type_id: "",
        vehicles_name: "",
      };
      const value = stateForm.vehicles_frame_no;

      // 車体番号検索
      if (value) {
        const response = await getVehiclesByFrameNo({
          activity_base_id: location_id,
          frame_no: value,
        });
        if (response.data) {
          data = { ...response.data };

          // 存在チェック
          checkReportExist(stateForm.year_month, data.SK);
        }
      }

      setStateForm((prevState) => ({
        ...prevState,
        vehicle_id: data.SK,
        vehicles_frame_no: data.vehicles_frame_no,
        vehicles_workplace: data.vehicles_workplace,
        vehicles_model: data.vehicles_model,
        vehicles_fuel_type: data.vehicles_fuel_type,
        vehicles_fuel_type_id: data.vehicles_fuel_type_id,
        vehicles_name: data.vehicles_name,
      }));

      // 点検項目取得
      if (fuel_id != data.vehicles_fuel_type_id) {
        if (data.vehicles_fuel_type_id) {
          const resCheckItem = await getListForkLiftCheckItemAll(
            location_id,
            data.vehicles_fuel_type_id,
          );
          if (resCheckItem?.data) {
            setListCheckItem(resCheckItem?.data);
          }
        } else {
          setListCheckItem([]);
        }
      }
    } finally {
      LoadingOverlayController.hide();
    }
  };

  // 日付ボタン =====================================
  useEffect(() => {
    scrollDateView(71 * indexDate - 1);
  }, [dateScrollViewRef.current, indexDate]);

  const scrollDateView = (dx: number) => {
    dateScrollViewRef?.current?.scrollTo({
      left: dx,
      behavior: "smooth",
    });
  };

  const isEdited = (index_date: number, state_form?: StateFormType) => {
    if (!state_form) state_form = stateForm;
    return (
      state_form.check_result[index_date] &&
      !_.isEqual(state_form.check_result[index_date], initialResultData)
    );
  };

  const handleClickDateBtn = (index: number) => {
    if (index == indexDate) return;

    let isChange = false;
    let newStateForm = cloneDeep(stateForm);

    // 変更なしの場合は保存しない
    if (!isEdited(indexDate)) {
      isChange = true;
      delete newStateForm.check_result[indexDate];
    }

    // 未入力日は初期データを追加
    if (!stateForm.check_result[index]) {
      isChange = true;
      newStateForm = {
        ...cloneDeep(newStateForm),
        check_result: {
          ...newStateForm.check_result,
          [index]: initialResultData,
        },
      };
    }

    if (isChange) {
      setStateForm(newStateForm);
    }
    setIndexDate(index);
  };

  const _renderDateBtn = (index: number) => {
    return (
      <Box
        sx={{
          minHeight: "63px",
          minWidth: "63px",
          border: 1,
          borderRadius: 2,
          borderColor: indexDate == index ? Colors.MAIN_GREEN : Colors.BORDER,
          bgcolor: indexDate == index ? Colors.HEADER_COLOR_GREEN : "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
        }}
        component={"div"}
        key={index}
        onClick={() => handleClickDateBtn(index)}
      >
        <Typography>
          <span className="IgnoreExtractRuleTarget">{index + 1}</span>
        </Typography>
        <Typography color={Colors.LIGHT_GRAY} height={30}>
          {stateForm.check_result[index] &&
          stateForm.check_result[index].approved
            ? "承認済"
            : isEdited(index)
            ? "入力済"
            : ""}
        </Typography>
      </Box>
    );
  };

  // チェック項目 =====================================
  const handleClickBtnGroup = (
    item_id: string,
    selected: boolean | undefined,
  ) => {
    let newStateForm = cloneDeep(stateForm);
    newStateForm.check_result[indexDate].result[item_id] = selected;
    setStateForm(newStateForm);
  };

  const _renderCheckBox = () => {
    if (!stateForm.check_result[indexDate]) return <></>;

    let section = "";
    return stateForm.check_item.map((item, index) => {
      const check = stateForm.check_result[indexDate].result[item.id];
      const res = (
        <React.Fragment key={index}>
          {section != item.section ? (
            <Box sx={styles.checkSection}>
              <Typography
                sx={{ wordBreak: "break-all" }}
                className="IgnoreExtractRuleTarget"
              >
                {item.section}
              </Typography>
            </Box>
          ) : (
            <Divider />
          )}
          <Box sx={styles.checkItem}>
            <Typography
              sx={{ flexGrow: 1, wordBreak: "break-all" }}
              className="IgnoreExtractRuleTarget"
            >
              {item.item}
            </Typography>
            <ButtonGroup sx={{ ml: 1 }}>
              <Button
                variant={check ? "contained" : "outlined"}
                onClick={() =>
                  handleClickBtnGroup(item.id, check ? undefined : true)
                }
              >
                ○
              </Button>
              <Button
                variant={check === false ? "contained" : "outlined"}
                onClick={() =>
                  handleClickBtnGroup(
                    item.id,
                    check === false ? undefined : false,
                  )
                }
              >
                ×
              </Button>
            </ButtonGroup>
          </Box>
        </React.Fragment>
      );
      section = item.section;
      return res;
    });
  };

  const handleCheckAll = () => {
    let cloneStateForm = cloneDeep(stateForm);
    stateForm.check_item.forEach((item) => {
      cloneStateForm.check_result[indexDate].result[item.id] = checkAll
        ? undefined
        : true;
    });
    setCheckAll(!checkAll);
    setStateForm(cloneStateForm);
  };

  const disabledCheckAll = React.useMemo(() => {
    // １つでもXが選択されていれば、非活性
    if (!stateForm.check_result[indexDate]?.result) return true;
    return Object.values(stateForm.check_result[indexDate].result).some(
      (d) => d === false,
    );
  }, [stateForm, indexDate]);

  useEffect(() => {
    // 全て○が選択されていれば、true
    let isCheckAll = false;
    if (
      stateForm.check_result[indexDate] &&
      Object.values(stateForm.check_result[indexDate].result).length > 0
    ) {
      isCheckAll = !Object.values(
        stateForm.check_result[indexDate].result,
      ).some((d) => d === false || d === undefined);
    }
    setCheckAll(isCheckAll);
  }, [stateForm, indexDate]);

  return (
    <Box>
      <Stack>
        <Card>
          <CardHeader title="基本情報" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <TextField
                  label="帳票番号"
                  value={stateForm.risky_id}
                  disabled
                />
                <Box sx={{ display: "flex" }}>
                  <TextField
                    label={<LabelRequired title={"車体番号"} />}
                    value={stateForm.vehicles_frame_no}
                    onChange={onChangeText("vehicles_frame_no")}
                    sx={{ flex: 1 }}
                    disabled={isManage || is_hand_over}
                  />
                  <Box sx={styles.searchBtn}>
                    <Button
                      sx={{ minWidth: 60 }}
                      onClick={handleVehicleSearch}
                      disabled={isManage || is_hand_over}
                    >
                      検索
                    </Button>
                  </Box>
                </Box>
                <TextField
                  label="事業場名"
                  value={stateForm.vehicles_workplace}
                  disabled
                />
                <TextField
                  label="車種"
                  value={stateForm.vehicles_model}
                  disabled
                />
                <TextField
                  label="燃料"
                  value={stateForm.vehicles_fuel_type}
                  disabled
                />
                <TextField
                  label="車両番号"
                  value={stateForm.vehicles_name}
                  disabled
                />

                <SelectLabel
                  label={<LabelRequired title={"担当者名"} />}
                  value={stateForm.driver?.SK ?? ""}
                  onChange={(e) => {
                    onChangeSelect("driver")(addIsManualListUser, e);
                  }}
                >
                  {renderMenuItem(addIsManualListUser, "SK", "full_name")}
                </SelectLabel>
                <TextField
                  label="その他（担当者名）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.driver_other}
                  disabled={!stateForm.driver?.is_manual_input ?? true}
                  onChange={onChangeText("driver_other")}
                />
                <DatePickerCustom
                  label={<LabelRequired title="実施年月" />}
                  value={stateForm.year_month}
                  onChange={onChangeDate("year_month")}
                  openTo="year"
                  views={["year", "month"]}
                  inputFormat="yyyy/MM"
                  mask="____/__"
                  helperText={formError.year_month}
                  error={formError.year_month.length > 0}
                  disabled={isManage || is_hand_over}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="始業点検" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <FormControl>
                  <InputLabel>実施日付</InputLabel>
                  <Box
                    component={"div"}
                    sx={{ overflowX: "scroll", height: "83px", width: "100%" }}
                    ref={dateScrollViewRef}
                  >
                    <Stack direction={"row"} spacing={1}>
                      {[...Array(31)].map((_, i) => _renderDateBtn(i))}
                    </Stack>
                  </Box>
                </FormControl>
                <SelectLabel
                  label={<LabelRequired title={"点検実施者名"} />}
                  value={
                    stateForm.check_result[indexDate]?.check_user?.SK ?? ""
                  }
                  onChange={(e) => {
                    const selected = addIsManualListUser.find(
                      (item: any) => item && item.SK === e.target.value,
                    );
                    onChangeTextResult("check_user")(selected);
                  }}
                >
                  {renderMenuItem(addIsManualListUser, "SK", "full_name")}
                </SelectLabel>
                <TextField
                  label="その他（点検実施者名）"
                  inputProps={{ maxLength: 30 }}
                  value={stateForm.check_result[indexDate]?.check_user_other}
                  disabled={
                    !stateForm.check_result[indexDate]?.check_user
                      ?.is_manual_input ?? true
                  }
                  onChange={(e) => {
                    onChangeTextResult("check_user_other")(e.target.value);
                  }}
                />
                {_renderCheckBox()}
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="備考" sx={styles.header} />
          <CardContent>
            <FormGroup>
              <Stack sx={styles.formStack}>
                <TextField
                  label="備考"
                  name="memo"
                  multiline
                  value={stateForm.memo}
                  rows={2}
                  inputProps={{
                    maxLength: 50,
                  }}
                  placeholder="メモや備考を記入出来ます（最大50文字）"
                  onChange={(e) => {
                    if (e.target.value.split("\n").length > 2) {
                      return;
                    }
                    onChangeText("memo")(e);
                  }}
                />
                <CheckboxLabel
                  label="点検項目は全て確認し異常はありませんか？"
                  labelPlacement="start"
                  checked={checkAll}
                  onClick={handleCheckAll}
                  disabled={disabledCheckAll}
                />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
};

export default forwardRef(TemplateForkliftReport);

const styles = {
  header: { bgcolor: Colors.MAIN_GREEN_LIGHT },
  formStack: { pb: 0, width: "100%" },
  searchBtn: {
    display: "flex",
    alignSelf: "flex-end",
    ml: 1,
    mb: "10px",
  },
  checkSection: {
    bgcolor: Colors.HEADER_COLOR_GREEN,
    borderRadius: 1,
    padding: 1,
  },
  checkItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
} as const;
