import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import AccordionSection from "components/molecules/AccordionSection";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import _ from "lodash";
import { oilTypeOptions, refuelingFeeTypeOptions } from "../fakeData";
import SelectLabelYuasa from "../components/SelectLabelYuasa";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import SignatureInput from "components/atoms/SignatureInput";
import { RootState } from "store/reducer";
import formatDateToString from "@utils/DateFormat";
import { StateFormType } from "@utils/template/yuasa";

interface IProps {
  stateForm: StateFormType;
  onChangeText: (
    field: keyof StateFormType,
    checkHalfWidth?: boolean,
  ) => (newText: any) => void;
  formError: { [key: string]: string };
}

const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

const ShipmentDetail: React.FC<IProps> = ({
  stateForm,
  onChangeText,
  formError,
}) => {
  const {
    listDrivingOnShoulder,
    listHighTeam,
    listCityName,
    listTowingShop,
    listStoreCar,
    listTransportWork,
  } = useSelector((state: RootState) => state.masterYuasa);

  const handleCheckboxPress = (item: any) => {
    const isChecked = stateForm.transportation_work?.some(
      (checkedItem: any) => checkedItem.SK === item.SK,
    );
    const transportation_work =
      _.cloneDeep(stateForm.transportation_work) ?? [];
    if (isChecked) {
      const newArray = transportation_work.filter(
        (value: any) => value?.SK !== item?.SK,
      );
      newArray.sort((a: any, b: any) => a.seq - b.seq);
      onChangeText("transportation_work")(newArray);
    } else {
      const newArray = [...transportation_work, item];
      newArray.sort((a: any, b: any) => a.seq - b.seq);
      onChangeText("transportation_work")(newArray);
    }
  };

  const errorBefBJ = useMemo(
    () => formError.before_starting_BJ.length > 0,
    [formError],
  );

  const errorAftBJ = useMemo(
    () => formError.after_starting_BJ.length > 0,
    [formError],
  );

  return (
    <AccordionSection title="搬送情報">
      <Stack sx={styles.formStack}>
        <FormControl>
          <InputLabel>作業</InputLabel>
          <FormGroup>
            {listTransportWork.map((item: any, index: number) => (
              <CheckboxLabel
                label={item?.wrecker_name}
                labelPlacement="start"
                sx={{ mr: 1, p: 0.5 }}
                formSx={{ ".MuiFormControlLabel-label": { flex: 1 }, ml: 1 }}
                onChange={() => handleCheckboxPress(item)}
                checked={stateForm.transportation_work?.some(
                  (checkedItem) => checkedItem.SK === item.SK,
                )}
                key={index}
              />
            ))}
          </FormGroup>
        </FormControl>
        <FormControl>
          <OutlinedInput
            onChange={(e) => onChangeText("sorbent_mass")(e.target.value)}
            value={stateForm.sorbent_mass}
            inputProps={{ maxLength: 5 }}
            endAdornment={<InputAdornment position="end">kg</InputAdornment>}
            error={formError.sorbent_mass.length > 0}
          />
          <FormHelperText error>{formError.sorbent_mass}</FormHelperText>
        </FormControl>
        <Stack direction="row">
          <FormControl>
            <InputLabel error={errorBefBJ}>BJ始動前</InputLabel>
            <OutlinedInput
              onChange={(e) =>
                onChangeText("before_starting_BJ")(e.target.value)
              }
              value={stateForm.before_starting_BJ}
              inputProps={{ maxLength: 5 }}
              endAdornment={<InputAdornment position="end">V</InputAdornment>}
              error={errorBefBJ}
            />
            <FormHelperText error>
              {formError.before_starting_BJ}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel error={errorAftBJ}>BJ始動後</InputLabel>
            <OutlinedInput
              onChange={(e) =>
                onChangeText("after_starting_BJ")(e.target.value)
              }
              value={stateForm.after_starting_BJ}
              inputProps={{ maxLength: 5 }}
              endAdornment={<InputAdornment position="end">V</InputAdornment>}
              error={errorAftBJ}
            />
            <FormHelperText error>{formError.after_starting_BJ}</FormHelperText>
          </FormControl>
        </Stack>
        <Stack spacing={0.5}>
          <SelectLabelYuasa
            label="給油"
            value={stateForm.refueling_fee_type?.SK}
            data={refuelingFeeTypeOptions}
            onChange={onChangeText("refueling_fee_type")}
            valueFieldName="value"
          />
          <FormControl>
            <OutlinedInput
              onChange={(e) => onChangeText("refueling_fee")(e.target.value)}
              value={stateForm.refueling_fee}
              inputProps={{ maxLength: 6 }}
              endAdornment={<InputAdornment position="end">円</InputAdornment>}
              disabled={stateForm?.refueling_fee_type?.SK !== 2}
              error={formError.refueling_fee.length > 0}
            />
            <FormHelperText error>{formError.refueling_fee}</FormHelperText>
          </FormControl>
        </Stack>
        <Stack spacing={0.5}>
          <SelectLabelYuasa
            label="油種"
            value={stateForm.oil_type?.SK}
            data={oilTypeOptions}
            onChange={onChangeText("oil_type")}
            valueFieldName="value"
          />
          <FormControl>
            <OutlinedInput
              onChange={(e) => onChangeText("oil_volume")(e.target.value)}
              value={stateForm.oil_volume}
              inputProps={{ maxLength: 3 }}
              endAdornment={<InputAdornment position="end">ℓ</InputAdornment>}
              error={formError.oil_volume.length > 0}
            />
            <FormHelperText error>{formError.oil_volume}</FormHelperText>
          </FormControl>
        </Stack>
        <SelectLabelYuasa
          label="路肩走行はみだし"
          value={stateForm.running_off_shoulder?.SK}
          data={listDrivingOnShoulder}
          onChange={onChangeText("running_off_shoulder")}
        />
        <SelectLabelYuasa
          label="高速隊"
          value={stateForm.high_speed_troop?.SK}
          data={listHighTeam}
          onChange={onChangeText("high_speed_troop")}
        />
        <TextField
          label="保管場所"
          value={stateForm.storage_location}
          onChange={(e) => onChangeText("storage_location")(e.target.value)}
          inputProps={{ maxLength: 30 }}
        />
        <SelectLabelYuasa
          label="搬送先"
          value={stateForm.transport_destination?.SK}
          data={listCityName}
          onChange={onChangeText("transport_destination")}
        />
        <TextField
          label="搬送先住所"
          value={stateForm.address}
          onChange={(e) => onChangeText("address")(e.target.value)}
          inputProps={{ maxLength: 30 }}
        />
        <TextField
          label="搬送先TEL"
          value={stateForm.destination_number}
          onChange={(e) => onChangeText("destination_number")(e.target.value)}
          inputProps={{ maxLength: 20 }}
        />
        <FormControl>
          <InputLabel>搬送先担当</InputLabel>
          <OutlinedInput
            onChange={(e) => onChangeText("responsible_person")(e.target.value)}
            value={stateForm.responsible_person}
            inputProps={{ maxLength: 20 }}
            endAdornment={<InputAdornment position="end">様</InputAdornment>}
          />
        </FormControl>
        <TextField
          label="現場確認"
          value={stateForm.scene_confirmation}
          onChange={(e) => onChangeText("scene_confirmation")(e.target.value)}
          inputProps={{ maxLength: 50 }}
          multiline={true}
        />
        <SignatureInput
          label={"搬送先署名"}
          signPath={stateForm.destination_signature.uri}
          onChange={(value) => {
            onChangeText("destination_signature")(value || imageDefault);
            onChangeText("date_sign_destination_signature")(
              formatDateToString(new Date(), "YMD"),
            );
          }}
          disabledNext={true}
          disabledPrev={true}
        />
        <SelectLabelYuasa
          label="レッカー実施店"
          value={stateForm.towing_shop?.SK}
          data={listTowingShop}
          onChange={onChangeText("towing_shop")}
        />
        <TextField
          label="レッカー実施担当"
          value={stateForm.tow_implementation}
          onChange={(e) => onChangeText("tow_implementation")(e.target.value)}
          inputProps={{ maxLength: 20 }}
        />
        <SelectLabelYuasa
          label="車両預かり店"
          value={stateForm.vehicle_custody_store?.SK}
          data={listStoreCar}
          onChange={onChangeText("vehicle_custody_store")}
        />
      </Stack>
    </AccordionSection>
  );
};

export default ShipmentDetail;

const styles = {
  formStack: { pb: 0, width: "100%" },
};
