import React, { ReactNode, useCallback } from "react";
import { Box, Button, FormHelperText, Typography } from "@mui/material";
import { Template } from "services/models";
import { AddCircle, Edit } from "@mui/icons-material";
import { Colors } from "@template/style";
import { BUTTON_EDIT_TEMPLATE_OPEN } from "@shared-constants";

interface IProps {
  onClick?: Function;
  onClick2?: Function;
  template?: Template | null;
  helperText?: string | ReactNode;
  error?: boolean;
  disableEdit?: boolean;
  buttonType?:
    | "change"
    | "edit"
    | "confirm"
    | "preview"
    | "preview-edit"
    | "create"
    | "none";
}

const TemplateSelectBox: React.FC<IProps> = ({
  onClick,
  onClick2,
  template = null,
  helperText,
  error,
  disableEdit,
  buttonType = "change",
}) => {
  const handleClick = useCallback(() => {
    if (typeof onClick === "function") {
      onClick();
    }
  }, [onClick]);

  const handleClick2 = useCallback(() => {
    if (typeof onClick2 === "function") {
      onClick2();
    }
  }, [onClick2]);

  const renderButton = useCallback(() => {
    switch (buttonType) {
      case "change":
        return (
          <Button variant="outlined" onClick={handleClick} sx={styles.button}>
            変更
          </Button>
        );
      case "edit":
      case "create":
        const button_title =
          buttonType === "create"
            ? BUTTON_EDIT_TEMPLATE_OPEN.CREATE
            : BUTTON_EDIT_TEMPLATE_OPEN.EDIT;
        return (
          <Button onClick={handleClick} sx={styles.button} endIcon={<Edit />}>
            {button_title}
          </Button>
        );
      case "confirm":
        return (
          <Button onClick={handleClick} sx={styles.button}>
            確認
          </Button>
        );
      case "preview":
        return (
          <Button onClick={handleClick} sx={styles.button}>
            プレビュー
          </Button>
        );
      case "preview-edit":
        return (
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="center"
          >
            <Button onClick={handleClick} sx={styles.button}>
              プレビュー
            </Button>
            <Box width={8} />
            <Button
              onClick={handleClick2}
              sx={styles.button}
              endIcon={<Edit />}
            >
              編集
            </Button>
          </Box>
        );
      case "none":
        return <></>;
    }
  }, [buttonType, handleClick]);

  return (
    <>
      <Typography
        className={template?.template_name ? "IgnoreExtractRuleTarget" : ""}
      >
        {template
          ? template?.template_name ?? ""
          : "テンプレートを選択してください"}
      </Typography>
      {template ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            alt="template"
            src={template?.template_image ?? template?.path_file_thumb}
            sx={{
              border: 1.5,
              borderStyle: "solid",
              borderRadius: 1,
              borderColor: Colors.BORDER,
              width: "100%",
              height: 200,
              objectFit: "none",
              objectPosition: "left top",
              cursor: !onClick ? undefined : "pointer",
            }}
            onClick={handleClick}
          />
          {onClick && !disableEdit && renderButton()}
        </Box>
      ) : (
        <Box
          sx={{
            border: 1.5,
            borderStyle: "solid",
            borderRadius: 1,
            bgcolor: Colors.IMAGE_SELECT_BLOCK,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
            borderColor: Colors.BORDER,
            height: 200,
            cursor: disableEdit || !onClick ? undefined : "pointer",
          }}
          onClick={() => {
            if (!disableEdit) handleClick();
          }}
        >
          <Edit fontSize="large" sx={{ color: Colors.BORDER }} />
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="text"
              endIcon={<AddCircle />}
              onClick={handleClick}
              disabled={disableEdit}
            >
              テンプレートを選択
            </Button>
          </Box>
        </Box>
      )}
      {helperText && (
        <FormHelperText error={error} sx={{ mx: "14px" }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};
export default TemplateSelectBox;

const styles = {
  button: { mt: 1 },
} as const;
