import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  FormLabel,
  IconButton,
  Slide,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { Colors } from "@template/style";
import { useEffect, useMemo, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import "../../ManageScreen.css";
import { AddCircle } from "@mui/icons-material";
import MemberSearch from "../MemberSearch/MemberSearch";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { ApproveState } from "store/reducer/ApproveFlowReducer";
import { TYPES } from "store/types";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import { v4 as uuidv4 } from "uuid";
import {
  apiDeleteStep,
  apiGetMaxStepForFlow,
  apiGetStepListForFlow,
} from "@api/flowApproval";
import { cloneDeep, isEqual } from "lodash";
import { getUserInfo } from "@utils/index";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { IProps } from "../FlowApproval";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const stepDetailDefault: Type.StepDetail = {
  stepId: uuidv4(),
  stepName: `ステップ 1`,
  numOfApprover: 1,
  approvers: [],
  currentSearch: [],
  errorMessage: "",
  isValidStep: true,
  isSendApprovedMail: true,
  isAutoApprovalPerformed: false,
};

export const FlowStepArea = () => {
  const approveFlowState: ApproveState = useSelector(
    (state: RootState) => state.approveFlow,
  );
  const {
    onClickCreateNew,
    openMemberSearchDialog,
    stepList,
    recheck,
    deleteStepFlowList,
    requireGetStepListForFlow,
  } = approveFlowState;
  const flowInfo: Type.FlowInfo = useSelector(
    (state: RootState) => state.approveFlow.flowInfo,
  );
  const originApprovalSteps: Type.FlowInfo = useSelector(
    (state: RootState) => state.approveFlow.originStepList,
  );
  const dispatch = useDispatch();
  const [approvalSteps, setApprovalSteps] = useState<Type.StepDetail[]>([]);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [maxStep, setMaxStep] = useState<number>(0);
  const [maxUserLocation, setMaxUserLocation] = useState<number>(1);
  const handlingGetMaxStepForFlow = async () => {
    try {
      const maxStepRes = await apiGetMaxStepForFlow();
      setMaxStep(maxStepRes.max_step_approver);
    } catch (error: unknown) {
      if (error instanceof Error) {
        ModalController.show({
          message: error.message || "内部サーバーエラー",
          visibleButton2: true,
        });
      }
    }
  };
  useEffect(() => {
    handlingGetMaxStepForFlow();
  }, []);
  const handlingGetApprovalSteps = async () => {
    // call API
    const params = {
      sk_flow: flowInfo.SK,
      location_id: flowInfo.location_id,
    };
    try {
      dispatch({ type: TYPES.SET_API_IS_LOADING, payload: true });
      LoadingOverlayController.show();
      const { data, max_user_location } = await apiGetStepListForFlow(params);
      const result: Type.StepDetail[] = [];
      for (let i = 0; i < data.length; i++) {
        const rawStep = data[i].data_step;
        let convertedStep: Type.StepDetail = cloneDeep(stepDetailDefault);
        rawStep.forEach((item) => {
          convertedStep.stepId = item.approver_id;
          convertedStep.approvers.push({
            groupId: item.group_id,
            memberId: `${item.user_approver_id}|${item.group_id}`,
            name: item.full_name,
            linkedGroup: "defaultLinkGroup",
            isSelected: true,
            linkAvatar: item.avatar,
            SK: item.SK,
            PK: item.PK,
            isDisable: false,
          });
        });
        convertedStep.stepName = `ステップ ${i + 1}`;
        convertedStep.numOfApprover = data[i].data_max_user.max_user;
        convertedStep.isSendApprovedMail =
          data[i].data_max_user.is_send_approved_mail ?? true;
        convertedStep.isAutoApprovalPerformed =
          data[i].data_max_user.is_auto_approval_performed ?? false;
        result.push(convertedStep);
      }
      if (result.length === 0) {
        result.push(cloneDeep(stepDetailDefault));
      }
      dispatch({
        type: TYPES.SET_ORIGIN_STEP_LIST,
        payload: cloneDeep(result),
      });
      setMaxUserLocation(max_user_location);
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: result,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        ModalController.show({
          message: error.message || "内部サーバーエラー",
          visibleButton2: true,
        });
      }
    } finally {
      dispatch({ type: TYPES.SET_API_IS_LOADING, payload: false });
      LoadingOverlayController.hide();
    }
  };
  const handlingGetNumOfUsersInLocation = async () => {
    const { location_id } = getUserInfo();
    const params = {
      sk_flow: "FLOW_APPROVE",
      location_id: location_id,
    };
    const { max_user_location } = await apiGetStepListForFlow(params);
    dispatch({
      type: TYPES.SET_MAX_USER_LOCATION,
      payload: max_user_location,
    });
    setMaxUserLocation(max_user_location);
  };
  useEffect(() => {
    handlingGetNumOfUsersInLocation();
  }, []);
  useEffect(() => {
    if (!flowInfo.SK) {
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: [cloneDeep(stepDetailDefault)],
      });
      setApprovalSteps([cloneDeep(stepDetailDefault)]);
      dispatch({
        type: TYPES.SET_ORIGIN_STEP_LIST,
        payload: [cloneDeep(stepDetailDefault)],
      });
    } else {
      handlingGetApprovalSteps();
    }
  }, [flowInfo.SK, requireGetStepListForFlow]);

  const disableDefaultStep = useMemo(
    () => !flowInfo.SK && !onClickCreateNew,
    [flowInfo, onClickCreateNew],
  );

  useEffect(() => {
    if (openMemberSearchDialog === false) {
      handlingCloseSearchMemberDialog();
    }
  }, [openMemberSearchDialog]);

  useEffect(() => {
    if (stepList.length === 0 && deleteStepFlowList.length === 0) {
      setApprovalSteps([cloneDeep(stepDetailDefault)]);
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: [cloneDeep(stepDetailDefault)],
      });
      dispatch({
        type: TYPES.SET_ORIGIN_STEP_LIST,
        payload: [cloneDeep(stepDetailDefault)],
      });
    } else if (stepList.length === 0 && deleteStepFlowList.length !== 0) {
      setApprovalSteps([cloneDeep(stepDetailDefault)]);
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: [cloneDeep(stepDetailDefault)],
      });
    } else {
      setApprovalSteps(stepList);
    }
  }, [stepList]);
  useEffect(() => {
    if (isEqual(originApprovalSteps, approvalSteps)) {
      return;
    }
    const clonedApprovalSteps = [...approvalSteps];
    clonedApprovalSteps.forEach((item, index) => {
      const errorMessage = handlingAddErrorMessageForStep(
        item.numOfApprover,
        item.approvers,
        index,
      );
      item.errorMessage = errorMessage;
      item.isValidStep = errorMessage.length === 0;
    });
    const allValidSteps = clonedApprovalSteps.every((item) => item.isValidStep);
    const result =
      allValidSteps &&
      !isTheSameWithOrigin(clonedApprovalSteps, originApprovalSteps);
    dispatch({
      type: TYPES.SET_STEPS_READY_FOR_SUBMIT,
      payload: result,
    });
    if (!isEqual(clonedApprovalSteps, approvalSteps)) {
    setApprovalSteps(clonedApprovalSteps);
    }
  }, [recheck, approvalSteps, originApprovalSteps]);

  useEffect(() => {
    const allValidSteps = approvalSteps.every((item) => {
      const pattern = /^[1-9]\d*$/;
      if (
        !pattern.test(item.numOfApprover.toString()) ||
        item.approvers.length === 0
      ) {
        return false;
      }

      if (
        item.numOfApprover > maxUserLocation ||
        item.numOfApprover > item.approvers.length
      ) {
        return false;
      }
      return true;
    });
    const result =
      allValidSteps && !isTheSameWithOrigin(approvalSteps, originApprovalSteps);
    dispatch({
      type: TYPES.SET_STEPS_READY_FOR_SUBMIT,
      payload: result,
    });
  }, [approvalSteps]);

  const showSearchMemberComponent = (stepId: string) => {
    dispatch({
      type: TYPES.SET_CURRENT_STEP_ID,
      payload: stepId,
    });
    setIsOpenDialog(true);
  };
  const handlingCloseSearchMemberDialog = () => {
    dispatch({
      type: TYPES.SET_OPEN_DIALOG,
      payload: false,
    });
    setIsOpenDialog(false);
  };
  const handlingDeleteApprover = (stepId: string, memberId: string) => {
    let deletedMember: {
      SK: string;
      PK: string;
    } = { SK: "", PK: "" };
    approvalSteps.forEach((item) => {
      if (stepId === item.stepId) {
        const approver = item.approvers.find((e) => e.memberId === memberId);
        if (approver) {
          const { SK, PK } = approver;
          deletedMember = { SK, PK };
        }
        item.approvers = item.approvers.filter((e) => e.memberId !== memberId);
      }
    });
    if (deletedMember.SK && deletedMember.PK) {
      dispatch({
        type: TYPES.SET_DELETE_STEP,
        payload: [deletedMember],
      });
    }
    dispatch({
      type: TYPES.SET_STEP_LIST,
      payload: [...approvalSteps],
    });
  };
  const renderApprvers = (step: Type.StepDetail) => {
    const approvers: Type.Member[] = step.approvers;
    const buttonAddApprover = (
      <Button
        key={"btnSearch"}
        disabled={disableDefaultStep}
        onClick={() => showSearchMemberComponent(step.stepId)}
        startIcon={<AddCircle />}
        sx={{
          minWidth: "unset",
        }}
      >
        承認者を追加
      </Button>
    );
    const approverJSXs = approvers.map((item) => {
      return (
        <Box key={item.memberId} sx={styles.approverTag}>
          <FormLabel sx={{ ml: 1 }}>
            <span className="IgnoreExtractRuleTarget">{item.name}</span>
          </FormLabel>
          <IconButton
            onClick={() => handlingDeleteApprover(step.stepId, item.memberId)}
          >
            <ClearIcon style={styles.clearIcon} />
          </IconButton>
        </Box>
      );
    });
    approverJSXs.push(buttonAddApprover);
    return approverJSXs;
  };
  const handlingDeleteStep = async (stepId: string) => {
    try {
      const deletedStep = approvalSteps.find((item) => item.stepId === stepId);
      if (deletedStep && deletedStep.stepId.startsWith("APPROVER")) {
        dispatch({
          type: TYPES.SET_DELETE_STEP_FLOW_LIST,
          payload: [...[{ sk_flow: flowInfo.SK, approver_id: stepId }]],
        });
        //await apiDeleteStep({ sk_flow: flowInfo.SK, approver_id: stepId });
      }
      const theRestStep = approvalSteps.filter(
        (item) => item.stepId !== stepId,
      );
      theRestStep.forEach(
        (item, idx) => (item.stepName = `ステップ ${idx + 1}`),
      );
      setApprovalSteps(theRestStep);
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: theRestStep,
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        ModalController.show({
          message: error.message || "内部サーバーエラー",
          visibleButton2: true,
        });
      }
    }
  };
  const handlingShowConfirmDeleteStep = (stepId: string) => {
    ModalController.show({
      message: messages.ManageScreen.MSG_CONFIRM_DELETE_STEP,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: () => handlingDeleteStep(stepId),
    });
  };
  const handleChangeNumOfApprove = (event: any, step: Type.StepDetail) => {
    const targetStep = approvalSteps.find((e) => e.stepId === step.stepId);
    const index = stepList.findIndex((item) => item.stepId == step.stepId);
    if (targetStep) {
      const newNumber = event.target.value;
      targetStep.numOfApprover = newNumber;
      const errorMessage = handlingAddErrorMessageForStep(
        targetStep.numOfApprover,
        targetStep.approvers,
        index,
      );
      targetStep.errorMessage = errorMessage;
      targetStep.isValidStep = targetStep.isValidStep
        ? targetStep.isValidStep
        : errorMessage.length === 0;
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: [...approvalSteps],
      });
    }
  };
  const handleChangeIsSendApprovedMail = (
    value: boolean,
    step: Type.StepDetail,
  ) => {
    const targetStep = approvalSteps.find((e) => e.stepId === step.stepId);
    if (targetStep) {
      targetStep.isSendApprovedMail = value;
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: [...approvalSteps],
      });
    }
  };

  const handleChangeIsAutoApproval = (
    value: boolean,
    step: Type.StepDetail,
  ) => {
    const targetStep = approvalSteps.find((e) => e.stepId === step.stepId);
    if (targetStep) {
      targetStep.isAutoApprovalPerformed = value;
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: [...approvalSteps],
      });
    }
  };

  const handlingAddErrorMessageForStep = (
    numOfApprover: number,
    approvers: Type.Member[],
    index: number,
  ) => {
    let result = "";
    const pattern = /^[1-9]\d*$/;
    if (!pattern.test(numOfApprover.toString()) || approvers.length === 0) {
      result = `承認ステップ${index + 1}に承認者が設定されていません`;
    }

    if (numOfApprover > maxUserLocation) {
      result = messages.ManageScreen.MSG_GREATER_THAN_MAX_APPROVERS;
    } else if (numOfApprover > approvers.length) {
      result = `承認ステップ${index + 1}の承認者が不足しています`;
    }

    return result;
  };

  const isTheSameWithOrigin = (editedObject: any, origin: any) => {
    if (editedObject.length !== 0) {
      return isEqual(editedObject, origin);
    } else {
      return true;
    }
  };
  useEffect(() => {
    const result = isTheSameWithOrigin(approvalSteps, originApprovalSteps);
    dispatch({
      type: TYPES.SET_IS_THE_SAME_ORIGIN,
      payload: result,
    });
  }, [approvalSteps, originApprovalSteps]);

  const renderStepDetail = (item: Type.StepDetail) => {
    return (
      <Box key={item.stepId} sx={styles.formContent}>
        <Box sx={styles.topStep}>
          <FormLabel sx={{ ml: 1 }}>{item.stepName}</FormLabel>
          <IconButton
            onClick={() => handlingShowConfirmDeleteStep(item.stepId)}
            disabled={disableDefaultStep}
          >
            <ClearIcon style={styles.clearIcon} />
          </IconButton>
        </Box>
        <Box
          sx={[
            styles.numOfApproversBox,
            styles.customDistance,
            { flexDirection: "column", alignItems: "flex-start" },
          ]}
        >
          <Box sx={[styles.numOfApproversBox]}>
            <TextField
              disabled={disableDefaultStep}
              className="CustomTextAlign"
              sx={[
                styles.numOfApproversInput,
                {
                  "& .MuiFormHelperText-contained": {
                    width: "100vh",
                    marginLeft: 0,
                  },
                },
              ]}
              value={item.numOfApprover}
              onChange={(event) => handleChangeNumOfApprove(event, item)}
              // helperText={
              //   !disableDefaultStep && !item.isValidStep ? item.errorMessage : ""
              // }
              error={!item.isValidStep && !disableDefaultStep}
            />
            <FormLabel sx={{ alignSelf: "flex-end", mb: 0.5 }}>
              名の承認が必要
            </FormLabel>
            <CheckboxLabel
              disabled={disableDefaultStep}
              label="自動承認を行う"
              sx={{ ml: 5 }}
              checked={item.isAutoApprovalPerformed}
              onChange={(_, checked) => {
                handleChangeIsAutoApproval(checked, item);
              }}
            />
            <CheckboxLabel
              disabled={disableDefaultStep}
              label="承認依頼メールを受信する"
              sx={{ ml: 5 }}
              checked={item.isSendApprovedMail}
              onChange={(_, checked) => {
                handleChangeIsSendApprovedMail(checked, item);
              }}
            />
          </Box>
          <FormHelperText error={!item.isValidStep && !disableDefaultStep}>
            {!disableDefaultStep && !item.isValidStep ? item.errorMessage : ""}
          </FormHelperText>
        </Box>
        <Box sx={styles.customDistance}>
          <FormLabel>承認者</FormLabel>
        </Box>
        <Box sx={[styles.customDistance, styles.approverTagArea]}>
          {renderApprvers(item)}
        </Box>
      </Box>
    );
  };
  const renderSteps = useMemo(() => {
    if (approvalSteps.length === 0) {
      return <></>;
    }
    const result = approvalSteps.map((item) => renderStepDetail(item));
    return result;
  }, [approvalSteps]);
  const handlingAddStep = () => {
    const numOfSteps = approvalSteps.length;
    const stepDetailDefault: Type.StepDetail = {
      stepId: uuidv4(),
      stepName: `ステップ ${numOfSteps + 1}`,
      numOfApprover: 1,
      approvers: [],
      currentSearch: [],
      errorMessage: "",
      isValidStep: true,
      isSendApprovedMail: true,
      isAutoApprovalPerformed: false,
    };
    dispatch({
      type: TYPES.SET_ADD_STEP,
      payload: stepDetailDefault,
    });
    setApprovalSteps([...approvalSteps, stepDetailDefault]);
  };
  return (
    <>
      {renderSteps}
      <Button
        disabled={maxStep === approvalSteps.length || disableDefaultStep}
        onClick={handlingAddStep}
        startIcon={<AddCircle />}
        sx={{ ml: 3, mb: 4 }}
      >
        承認ステップを追加
      </Button>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={isOpenDialog}
        onClose={handlingCloseSearchMemberDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "70%",
            height: "85%",
            maxWidth: "unset",
          },
        }}
      >
        <MemberSearch />
      </Dialog>
    </>
  );
};
const styles = {
  formContent: {
    my: 2,
    mx: 3,
    pb: 2,
    border: 2,
    borderRadius: 1,
    borderColor: Colors.MAIN_GREEN,
    position: "relative",
  },
  topStep: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearIcon: {
    color: "#fff",
    backgroundColor: Colors.TEXT,
    borderRadius: 50,
  },
  numOfApproversBox: {
    display: "flex",
    alignItems: "center",
  },
  numOfApproversInput: {
    width: 55,
    marginRight: 1,
  },
  customDistance: {
    marginTop: 1,
    mx: 1,
  },
  approverAddButton: {
    backgroundColor: Colors.MAIN_GREEN,
  },
  approverTag: {
    backgroundColor: Colors.DISABLE_INPUT_BG,
    borderRadius: 1,
    mr: 1,
  },
  approverTagArea: {
    display: "flex",
    overflow: "auto",
    whiteSpace: "nowrap",
  },
} as const;
