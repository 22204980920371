import React, { useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormGroup,
  InputLabel,
  Link,
  Stack,
} from "@mui/material";
import { Colors } from "@template/style";
import { fileDownload, getUserInfo } from "@utils/index";
import { Template } from "services/models";
import TemplateSelectBox from "components/molecules/TemplateSelectBox";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { useDispatch, useSelector } from "react-redux";
import { TYPES } from "store/types";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { getDetailProjectTlog } from "@api/project";
import formatDateToString from "@utils/DateFormat";
import { RootState } from "store/reducer";
import { STORAGE, storeData } from "@utils/Storage";
import LabelValueText from "components/atoms/LabelValueText";
import ProjectGroupInfoOption, {
  IGroupEditData,
} from "components/organisms/ProjectGroupInfoOption";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import ModalCopyCustomTemplateConfirm from "components/molecules/ModalCopyCustomTemplateConfirm";

interface IProjectViewTemplate extends Template {
  excel_name: string;
  excel_path_s3: string;
}

type IStateForm = {
  SK: string;
  project_id: string;
  project_name: string;
  report_id: string;
  project_location_id: string;
  project_start_date: string;
  project_end_date: string;
  template: null | IProjectViewTemplate;
  memo: string;
  report_type_code: string;
  report_name: string;
  report_number: string;
  is_daily: boolean;
  is_user_made: boolean;
  report_type_id: string;
  is_corp_standard: boolean;
  open_state: boolean;
  template_project_id: string;
  group_info: IGroupEditData[];
};

const ProjectViewTlogScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const projectId = useSelector(
    (state: RootState) => state.projectInfo.selected_project_id,
  );
  const [stateForm, setStateForm] = useState<IStateForm>({
    SK: "",
    project_id: "",
    project_name: "",
    report_id: "",
    project_location_id: "",
    project_start_date: "",
    project_end_date: "",
    template: null,
    memo: "",
    report_type_code: "",
    report_name: "",
    report_number: "",
    report_type_id: "",
    is_daily: false,
    is_user_made: false,
    is_corp_standard: false,
    open_state: true,
    template_project_id: "",
    group_info: [],
  });
  const [openCopyMaster, setOpenCopyMaster] = useState<boolean>(false);
  const [copyData, setCopyData] = useState<any>();
  const dispatch = useDispatch();
  const history = useHistoryCustom();
  const groupInfoRef = React.useRef<any>();
  const user_info = getUserInfo();

  // データ取得 ------------------------------------------------
  const fetchDetailProject = async () => {
    try {
      LoadingOverlayController.show();
      const res = await getDetailProjectTlog(projectId);
      if (res?.data) {
        const dataRes = res?.data;
        const updateStateForm = {
          ...stateForm,
          SK: dataRes?.SK,
          project_id: projectId,
          project_name: dataRes?.project_name,
          report_id: dataRes?.report_id,
          project_location_id: dataRes?.project_location_id,
          project_start_date: dataRes?.project_start_date,
          project_end_date: dataRes?.project_end_date,
          template: dataRes?.template_project_id ?? null,
          memo: dataRes?.memo,
          report_type_code: dataRes?.report_type_code,
          report_name: dataRes?.report_name,
          report_number: dataRes?.report_number,
          is_daily: dataRes?.is_daily,
          report_type_id: dataRes?.report_type_id,
          is_corp_standard: dataRes?.is_corp_standard,
          open_state: dataRes?.open_state,
          template_project_id: dataRes?.template_project_id?.SK,
          is_user_made: dataRes?.is_user_made,
        };
        setStateForm(updateStateForm);
      }

      setTimeout(async () => {
        await fetchGroupInfo();
      }, 500);
    } catch (error: any) {
      console.log("error getDetailProject", error);
      ModalController.show({
        message: error?.detail.message,
        visibleButton2: true,
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  useEffect(() => {
    dispatch({ type: TYPES.GET_LIST_STATUS_PROJECT });
    dispatch({ type: TYPES.GET_LIST_LOCATION });

    fetchDetailProject();
  }, []);

  const fetchGroupInfo = async () => {
    if (groupInfoRef && groupInfoRef.current) {
      await groupInfoRef.current.handlefetchList();
    }
  };

  const project_date = useMemo(() => {
    let response = "";
    if (stateForm.project_start_date) {
      response +=
        formatDateToString(stateForm.project_start_date, "YMD_sl") + " ～ ";
    }
    if (stateForm.project_end_date) {
      if (response === "") response = "～ ";
      response += formatDateToString(stateForm.project_end_date, "YMD_sl");
    }
    return response;
  }, [stateForm]);

  // ダウンロード ------------------------------------------------
  const handleDownload = async (file_name: string, uri: string) => {
    await fileDownload(file_name, uri);
  };

  // 複製 ------------------------------------------------
  const handleClickCopy = () => {
    const data: any = {
      SK: projectId,
      template_project_id: stateForm.template_project_id,
      project_location_id: stateForm.project_location_id,
    };
    setCopyData(data);
    setOpenCopyMaster(true);
  };

  return (
    <GenericTemplate title="帳票情報">
      <Stack>
        <Card>
          <CardHeader
            title="基本情報"
            sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }}
          />
          <CardContent>
            <FormGroup>
              <Stack sx={{ pb: 0 }}>
                <LabelValueText label="帳票名" value={stateForm.project_name} />
                <LabelValueText
                  label="帳票"
                  value={`${stateForm.report_type_code}${stateForm.report_number}:${stateForm.report_name}`}
                />
                <ProjectGroupInfoOption
                  ref={groupInfoRef}
                  stateForm={stateForm}
                  setStateForm={setStateForm}
                  allowEditTopLocation={false}
                  allowEditGroup={false}
                />
                <LabelValueText label="帳票部類" value="日次帳票" />
                <FormControl>
                  <InputLabel>帳票様式</InputLabel>
                  <Box>
                    <CheckboxLabel
                      label={"全社標準"}
                      checked={stateForm.is_corp_standard}
                      disabled={true}
                    />
                  </Box>
                </FormControl>
                <LabelValueText
                  label="公開状態"
                  value={stateForm.open_state ? "公開" : "非公開"}
                />
                <LabelValueText label="公開期間" value={project_date} />
              </Stack>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader
            title="テンプレート"
            sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }}
          />
          <CardContent>
            <FormGroup>
              <TemplateSelectBox
                template={stateForm.template}
                onClick={() => {
                  // 別タブで開く
                  const data = {
                    type: "url",
                    html: stateForm.template?.template_preview_path,
                  };
                  storeData(STORAGE.PREVIEW_DATA, JSON.stringify(data));
                  window.open("/preview");
                }}
                disableEdit={true}
              />
              <FormControl sx={{ mt: 2 }}>
                <InputLabel>Excelファイルダウンロード</InputLabel>
                <Box>
                  <Link
                    component="button"
                    onClick={async () => {
                      if (
                        stateForm.template?.excel_name &&
                        stateForm.template?.excel_path_s3
                      ) {
                        await handleDownload(
                          stateForm.template?.excel_name,
                          stateForm.template?.excel_path_s3,
                        );
                      }
                    }}
                    variant="body1"
                  >
                    {stateForm.template?.excel_name}
                  </Link>
                </Box>
              </FormControl>
            </FormGroup>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title="備考" sx={{ bgcolor: Colors.MAIN_GREEN_LIGHT }} />
          <CardContent>
            <LabelValueText label="備考" value={stateForm.memo} />
          </CardContent>
        </Card>
      </Stack>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}>
        <Button onClick={handleClickCopy}>複製</Button>
      </Box>
      <ModalCopyCustomTemplateConfirm
        open={openCopyMaster}
        user_info={user_info}
        data={copyData}
      />
    </GenericTemplate>
  );
};
export default ProjectViewTlogScreen;
