import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, Edit, Menu } from "@mui/icons-material";
import _ from "lodash";
import { TemplateExcelDataInfo } from "services/models";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import LabelRequired from "components/atoms/LabelRequired";
import { labeltextBreak, sort } from "@utils/index";
import { Validation } from "@validation";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";

interface IProps {
  open: boolean;
  stateForm: Array<TemplateExcelDataInfo>;
  coordinate: string;
  handleMenu: () => void;
  handleSelect: (coordinate: string) => void;
  handleDelete: (coordinate: string) => void;
  setStateForm: (value: any) => void;
  disableAddTab?: boolean;
  disableAddItem?: boolean;
  handleAddInput?: (coordinate: string) => void;
}

interface ICategories {
  id: string;
  name: string;
}

const CreateTemplateNavi = (
  {
    open,
    stateForm,
    coordinate,
    handleMenu,
    handleSelect,
    handleDelete,
    setStateForm,
    disableAddTab,
    disableAddItem,
    handleAddInput = () => {},
  }: IProps,
  ref: any,
) => {
  const [openCategory, setOpenCategory] = useState(false);
  const [categoryName, setCategoryName] = useState<string>();
  const [naviList, setNaviList] = useState<Array<any>>([]);
  const [originList, setOriginList] = useState<Array<any>>([]);
  const [categories, setCategories] = useState<Array<ICategories>>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const categoriesIDRef = useRef(0);

  useImperativeHandle(ref, () => ({
    hasEmptyCategories: () => {
      const form_category = stateForm.map((item) => item.categoryTitle);
      return categories.some((item) => !form_category.includes(item.name));
    },
    clear: () => {
      setCategories([]);
      setNaviList([]);
      setOriginList([]);
    },
    hasUpdateData: () => {
      return naviList && originList && !_.isEqual(naviList, originList);
    },
  }));

  useEffect(() => {
    // naviList,categoriesを取得
    if (naviList.length == 0) {
      // 起動時
      let newNaviList: any[] = [];
      let newCategories: ICategories[] = [];
      stateForm.forEach((s) => {
        if (!s.categoryTitle) {
          // カテゴリーに属していない項目をnaviListに追加
          newNaviList.push(_.cloneDeep(s));
        } else if (!newNaviList.some((v) => v.category === s.categoryTitle)) {
          // 未追加のカテゴリーをnaviList,categoriesに追加
          let current_navi = naviList.find(
            (n) => n.category === s.categoryTitle,
          );
          let current_category = categories.find(
            (n) => n.name === s.categoryTitle,
          );
          if (current_navi && current_category) {
            newCategories.push(_.cloneDeep(current_category));
            newNaviList.push(_.cloneDeep(current_navi));
          } else {
            categoriesIDRef.current += 1;
            const id = categoriesIDRef.current.toString();
            newCategories.push({ id: id, name: s.categoryTitle });
            newNaviList.push({ category_id: id, category: s.categoryTitle });
          }
        }
      });

      if (
        !_.isEqual(naviList, newNaviList) ||
        !_.isEqual(categories, newCategories)
      ) {
        setNaviList(newNaviList);
        setCategories(newCategories);
        setOriginList(newNaviList);
      }
    } else {
      // カテゴリーと現在の項目リストに存在する項目を抽出
      let addNaviList = naviList.filter(
        (item) =>
          item.category ||
          stateForm.map((v) => v.coordinate).includes(item.coordinate),
      );
      // カテゴリーに属していない項目を抽出
      let addStateForm = stateForm.filter(
        (item) =>
          !item.categoryTitle &&
          !naviList.map((v) => v.coordinate).includes(item.coordinate),
      );
      let newNaviList = _.cloneDeep([...addNaviList, ...addStateForm]);
      // 項目名を上書き
      newNaviList.forEach((item) => {
        let formData = stateForm.find((v) => v.coordinate == item.coordinate);
        if (formData) {
          item.name = formData.name;
        }
      });
      if (!_.isEqual(naviList, newNaviList)) {
        setNaviList(newNaviList);
      }
    }
  }, [naviList, stateForm, categories]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    if (
      source.droppableId === "Categories" &&
      destination.droppableId === "Categories"
    ) {
      // --------------------------------------
      // カテゴリー移動
      // --------------------------------------
      const sortedList = sort(naviList, source.index, destination.index);

      // カテゴリーの並びに合わせてstateFormを並べ替え
      let sortedStateForm: Array<TemplateExcelDataInfo> = [];
      sortedList.forEach((item) => {
        if (item.category) {
          stateForm
            .filter((v) => v.categoryTitle == item.category)
            .forEach((v) => sortedStateForm.push(v));
        } else {
          sortedStateForm.push(item);
        }
      });
      setNaviList(sortedList);
      setStateForm(_.cloneDeep(sortedStateForm));
    } else if (
      destination.droppableId === "Categories" &&
      !draggableId.includes("category")
    ) {
      // --------------------------------------
      // 項目をカテゴリーから外す
      // --------------------------------------
      // 移動する項目のカテゴリー名を初期化
      const cloneStateForm = _.cloneDeep(stateForm);
      const category = categories.find((item) => item.id == source.droppableId);
      let source_item = cloneStateForm.filter(
        (item) => item.categoryTitle == category?.name,
      )[source.index];
      source_item.categoryTitle = undefined;

      // 移動する項目をnaviListに追加
      const sortedList = _.cloneDeep(naviList);
      sortedList.splice(destination.index, 0, source_item);

      // カテゴリーの並びに合わせてstateFormを並べ替え
      let sortedStateForm: Array<TemplateExcelDataInfo> = [];
      sortedList.forEach((item) => {
        if (item.category) {
          cloneStateForm
            .filter((v) => v.categoryTitle == item.category)
            .forEach((v) => sortedStateForm.push(v));
        } else {
          sortedStateForm.push(item);
        }
      });
      setNaviList([...sortedList]);
      setStateForm(_.cloneDeep(sortedStateForm));
    } else if (
      source.droppableId === "Categories" &&
      !draggableId.includes("category")
    ) {
      // --------------------------------------
      // 項目をカテゴリーへ追加
      // --------------------------------------
      // 移動する項目のカテゴリー名を移動先カテゴリーに上書き
      const categoryTitle = categories.find(
        (item) => item.id === destination.droppableId,
      )?.name;
      let source_item = _.cloneDeep(naviList[source.index]);
      source_item.categoryTitle = categoryTitle;

      // 移動する項目をリストから削除
      let sortedList = _.cloneDeep(naviList);
      sortedList.splice(source.index, 1);

      // カテゴリーの並びに合わせてstateFormを並べ替え
      let sortedStateForm: Array<TemplateExcelDataInfo> = [];
      naviList.forEach((item, index) => {
        if (item.category) {
          let tmp = stateForm.filter(
            (v) => (v.categoryTitle ?? "") === item.category,
          );
          if (item.category == categoryTitle) {
            // 移動する項目を追加
            tmp.splice(destination.index, 0, source_item);
          }
          tmp.forEach((v) => sortedStateForm.push(v));
        } else if (index != source.index) {
          sortedStateForm.push(item);
        }
      });
      setNaviList([...sortedList]);
      setStateForm(_.cloneDeep(sortedStateForm));
    } else if (destination.droppableId === source.droppableId) {
      // --------------------------------------
      // カテゴリー内のソート
      // --------------------------------------
      let sortedStateForm: Array<TemplateExcelDataInfo> = [];
      naviList.forEach((item) => {
        if (item.category_id) {
          let sorted = stateForm.filter(
            (v) => v.categoryTitle == item.category,
          );
          if (item.category_id === destination.droppableId) {
            sorted = sort(sorted, source.index, destination.index);
          }
          sorted.forEach((v) => sortedStateForm.push(v));
        } else {
          sortedStateForm.push(item);
        }
      });
      setStateForm(_.cloneDeep(sortedStateForm));
    } else if (
      source.droppableId != "Categories" &&
      destination.droppableId != "Categories" &&
      !draggableId.includes("category")
    ) {
      // --------------------------------------
      // カテゴリー内の項目を別のカテゴリーへ移動
      // --------------------------------------
      const sourceTabTitle = categories.find(
        (item) => item.id === source.droppableId,
      )?.name;
      const destinationTabTitle = categories.find(
        (item) => item.id === destination.droppableId,
      )?.name;
      // 移動する項目のカテゴリー名を変更
      let cloneStateForm = _.cloneDeep(stateForm);
      const [moving] = cloneStateForm
        .filter((v) => v.categoryTitle === sourceTabTitle)
        .splice(source.index, 1);
      moving.categoryTitle = destinationTabTitle;
      // 移動する項目を一時的に先頭へソート
      cloneStateForm = sort(cloneStateForm, cloneStateForm.indexOf(moving), 0);

      // カテゴリーの並びに合わせてstateFormを並べ替え
      let sortedStateForm: Array<TemplateExcelDataInfo> = [];
      naviList.forEach((item) => {
        if (item.category) {
          let tmp = cloneStateForm.filter(
            (v) => v.categoryTitle == item.category,
          );
          if (item.category_id == destination.droppableId) {
            // 一時的に先頭へ移動した項目をドロップした位置へソート
            tmp = sort(tmp, 0, destination.index);
          }
          tmp.forEach((v) => sortedStateForm.push(v));
        } else {
          sortedStateForm.push(item);
        }
      });
      setStateForm(_.cloneDeep(sortedStateForm));
    }
  };

  const handleButtonOK = (value: string) => {
    // 重複チェック
    if (
      categories
        .filter((v) => v.id != selectedCategory)
        .map((v) => v.name)
        .includes(value)
    ) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_EXISTING("タブ名"),
        visibleButton2: true,
      });
      return;
    }
    // カテゴリーを追加・変更
    let newCategories = _.cloneDeep(categories);
    let newNaviList = _.cloneDeep(naviList);
    if (selectedCategory) {
      // カテゴリー変更
      newCategories.filter((v) => v.id == selectedCategory)[0].name = value;
      newNaviList.filter((v) => v.category_id == selectedCategory)[0].category =
        value;
      const oldCategory = categories.filter((v) => v.id == selectedCategory)[0]
        .name;
      let newStateForm = _.cloneDeep(stateForm);
      newStateForm = newStateForm.map((v) => {
        if (v.categoryTitle === oldCategory) v.categoryTitle = value;
        return v;
      });
      setStateForm(newStateForm);
    } else {
      // カテゴリー追加
      categoriesIDRef.current += 1;
      const id = categoriesIDRef.current.toString();
      newCategories.push({ id: id, name: value });
      newNaviList.push({ category_id: id, category: value });
    }
    setCategories(newCategories);
    setNaviList(newNaviList);
    setSelectedCategory(undefined);
  };

  const handleDeleteCategory = (category_id: string, category_name: string) => {
    // カテゴリー削除
    let newCategories = _.cloneDeep(categories);
    newCategories = newCategories.filter((v) => v.id != category_id);
    setCategories(newCategories);

    let newNaviList = _.cloneDeep(naviList);
    let index = newNaviList.indexOf(
      newNaviList.find((v) => v.category_id == category_id),
    );
    newNaviList = newNaviList.filter((v) => v.category_id != category_id);

    let newStateForm = _.cloneDeep(stateForm);
    newStateForm = newStateForm.map((v) => {
      if (v.categoryTitle === category_name) {
        v.categoryTitle = undefined;
        // カテゴリーを削除した位置にカテゴリーに含まれていた項目を追加する
        newNaviList.splice(index, 0, _.cloneDeep(v));
        index++;
      }
      return v;
    });

    setStateForm(newStateForm);
    setNaviList(newNaviList);
  };

  /**
   * リスト項目
   * @returns
   */
  const DraggableListItem = ({
    value,
    index,
  }: {
    value: TemplateExcelDataInfo;
    index: number;
  }) => {
    return (
      <Draggable
        key={value.coordinate}
        draggableId={value.coordinate}
        index={index}
      >
        {(provided: DraggableProvided) => (
          <ListItem
            sx={{ bgcolor: "white" }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            disablePadding
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() => handleDelete(value.coordinate)}
              >
                <Close />
              </IconButton>
            }
          >
            <Tooltip title="ドラッグ&ドロップで並べ替え">
              <ListItemButton
                selected={coordinate == value.coordinate}
                onClick={() => handleSelect(value.coordinate)}
                sx={{ pl: 0.5 }}
              >
                <ListItemText
                  primary={labeltextBreak(value.name)}
                  sx={{ wordBreak: "break-all" }}
                />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        )}
      </Draggable>
    );
  };

  /**
   * カテゴリータブ
   * @returns
   */
  const DraggableCategory = ({
    value,
    index,
    children,
  }: {
    value: ICategories;
    index: number;
    children: React.ReactNode;
  }) => {
    return (
      <Draggable
        draggableId={`category-${value.id}`}
        key={`category-${value.id}`}
        index={index}
      >
        {(parentProvider) => (
          <div ref={parentProvider.innerRef} {...parentProvider.draggableProps}>
            <Card variant="outlined" sx={{ my: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    p: 1,
                    flex: 1,
                    wordBreak: "break-all",
                  }}
                  variant="body2"
                  {...parentProvider.dragHandleProps}
                >
                  {value.name}
                </Typography>
                <IconButton
                  onClick={() => {
                    // カテゴリー名編集
                    setOpenCategory(true);
                    setCategoryName(value.name);
                    setSelectedCategory(value.id);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => handleDeleteCategory(value.id, value.name)}
                >
                  <Close />
                </IconButton>
              </Box>
              <Divider />
              <Droppable droppableId={value.id}>
                {(dropProvided) => (
                  <div
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}
                    style={{ minHeight: "20px" }}
                  >
                    {children}
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </Card>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <>
      <CategoryEditDialog
        open={openCategory}
        categoryName={categoryName}
        onClose={() => {
          setOpenCategory(false);
          setCategoryName(undefined);
        }}
        onAccept={handleButtonOK}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: open ? "flex-end" : "center",
        }}
      >
        <Box sx={{ display: "flex", flex: 1 }} />
        <IconButton onClick={handleMenu}>
          <Menu />
        </IconButton>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ height: "100%", overflow: "auto" }}>
          <Droppable droppableId="Categories">
            {(provided: DroppableProvided) => (
              <List
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ height: "100%", display: open ? "inherit" : "none" }}
              >
                {naviList?.map((item, index) => {
                  if (!item.categoryTitle && !item.category) {
                    return (
                      <DraggableListItem
                        value={item}
                        index={index}
                        key={index}
                      />
                    );
                  } else if (item.category) {
                    const category = categories.find(
                      (v) => v.name == item.category,
                    );
                    if (!category) return null;
                    return (
                      <DraggableCategory
                        value={category}
                        index={index}
                        key={index}
                      >
                        {stateForm
                          .filter((f) => f.categoryTitle === category.name)
                          .map((item1, index) => (
                            <DraggableListItem
                              value={item1}
                              index={index}
                              key={index}
                            />
                          ))}
                      </DraggableCategory>
                    );
                  }
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
        }}
      >
        {open && (
          <>
            {!disableAddTab && (
              <Button
                variant="text"
                sx={{ minWidth: "initial" }}
                onClick={() => {
                  setOpenCategory(true);
                  setCategoryName(undefined);
                }}
              >
                タブ追加
              </Button>
            )}
            {!disableAddItem && (
              <Button
                variant="text"
                sx={{ minWidth: "initial" }}
                onClick={() => {
                  handleAddInput(uuidv4());
                }}
              >
                項目追加
              </Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

/**
 * タブ追加・変更ダイアログ
 */
const CategoryEditDialog = ({
  open,
  categoryName,
  onClose = () => {},
  onAccept = () => {},
}: {
  open: boolean;
  categoryName?: string;
  onClose?: () => void;
  onAccept?: (value: string) => void;
}) => {
  const [name, setName] = useState(categoryName);
  const [errorText, setErrorText] = useState("");

  const validName = () => {
    const message = Validation.validate({
      type: "text",
      value: name,
      name: "タブ名",
      required: true,
      max_length: 30,
    });
    setErrorText(message);
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      setName(categoryName ?? "");
      setErrorText("");
    }
  }, [open, categoryName]);

  const disabledBtn = useMemo(() => {
    return errorText.length > 0 || name === undefined || name.length == 0;
  }, [errorText, name]);

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent sx={{ p: 3, pb: 1 }}>
        <FormGroup>
          <TextField
            label={<LabelRequired title="タブ名" />}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={validName}
            error={errorText.length > 0}
            helperText={errorText}
            inputProps={{
              maxLength: 30,
            }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          キャンセル
        </Button>
        <Button
          onClick={() => {
            onAccept(name ?? "");
            handleClose();
          }}
          disabled={disabledBtn}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(CreateTemplateNavi);
