import formatDateToString from "@utils/DateFormat";
import { createHtmlForMultipleText } from "./index";
import { createObjectURL, getFileByUrl } from "..";

type feeType = {
  IC_name: string;
  fee: string;
};

type valueType = {
  SK: number;
  value: string;
};

type wreckerType = {
  SK: string;
  wrecker_name: string;
  is_manual_input: boolean;
};

type wreckerMasterType = {
  SK: string;
  wrecker_name: string;
  wrecker_address: string;
  wrecker_tel: string;
};

export type StateFormType = {
  weather: valueType | null;
  receptionist: string;
  request: wreckerType | null;
  request_other: string; //check
  customer_name: string;
  reception_date: string;
  reception_time: string;
  work_content: wreckerType | null; //check,
  work_detail_reception: string;
  location: wreckerType | null; //check,
  location_other: string;
  customer_number: string;
  registration_number_place: wreckerType | null;
  registration_number: string;
  car_name: string;
  line: wreckerType | null; //check,
  reception: string;
  arrival_time_scene: string;
  dispatcher_name: string;
  anshin_call: valueType | null; // check
  forwarding_distance: string;
  transportation_distance: string;
  secondary_transportation: string;
  dispatch_vehicle: wreckerType | null; // check
  departure_time: string;
  arrival_time: string;
  completion_time: string;
  transport_end_time: string;
  matter_end_time: string;
  secondary_dispatcher_name: string;
  secondary_dispatch_vehicle: wreckerType | null; // check
  secondary_departure_time: string;
  secondary_arrival_time: string;
  secondary_transport_end_time: string;
  secondary_matter_end_time: string;
  secondary_date: string;
  vehicle_appearance: Type.ImageInfoType;
  images: Array<Type.ImageInfoType>;
  customer_signature: Type.ImageInfoType;
  signer_attribute: valueType | null;
  is_check_vehicle_appearance: boolean;
  is_all_four_wheels: boolean;
  is_presence_of_key: boolean;
  is_lost_item: boolean;
  is_BT_removed: boolean;
  is_name_card: boolean;
  work_detail_scene: string;
  go_fee: string;
  forward_fee: Array<feeType>;
  transport_fee: Array<feeType>;
  return_fee: Array<feeType>;
  before_starting_BJ: string;
  after_starting_BJ: string;
  refueling_fee_type: valueType | null;
  refueling_fee: string;
  oil_type: valueType | null;
  oil_volume: string;
  running_off_shoulder: wreckerType | null;
  high_speed_troop: wreckerType | null;
  storage_location: string;
  transport_destination: wreckerType | null;
  address: string;
  responsible_person: string;
  destination_signature: Type.ImageInfoType;
  date_sign_destination_signature: string;
  towing_shop: wreckerMasterType | null;
  tow_implementation: string;
  vehicle_custody_store: wreckerMasterType | null;
  transportation_work: Array<wreckerType> | null;
  sorbent_mass: string;
  destination_number: string;
  scene_confirmation: string;
};

const createDateText = (date: string) => {
  return formatDateToString(date, "MMDD_jp");
};

const createFeeText = (feeArray: Array<feeType>) => {
  return feeArray
    .map((item) => {
      return `${item?.IC_name ?? ""}: ${item?.fee ?? ""}円`;
    })
    .join(" → ");
};

export const getInfoFromTemplate = (htmlString: string) => {
  let imageExample: string = "";
  let imageVehicleAppearanceExample: string = "";
  let imageSignatureExample: string = "";
  let checkIconExample: string = "";
  const imageExampleArray = htmlString.split("image-example");
  if (imageExampleArray.length >= 3) {
    imageExample = imageExampleArray[1];
  }
  const imageVehicleAppearanceArray = htmlString.split(
    "image-vehicle-appearance-example",
  );
  if (imageVehicleAppearanceArray.length >= 3) {
    imageVehicleAppearanceExample = imageVehicleAppearanceArray[1];
  }
  const imageSignatureArray = htmlString.split("image-signature-example");
  if (imageSignatureArray.length >= 3) {
    imageSignatureExample = imageSignatureArray[1];
  }
  const checkIconArray = htmlString.split("check-icon");
  if (checkIconArray.length >= 3) {
    checkIconExample = checkIconArray[1];
  }
  return {
    imageExample,
    imageVehicleAppearanceExample,
    imageSignatureExample,
    checkIconExample,
  };
};

export const makeNewHtml = (newHtml: string, data: StateFormType) => {
  let {
    weather,
    receptionist,
    request,
    request_other,
    customer_name,
    reception_date,
    reception_time,
    work_content,
    work_detail_reception,
    location,
    location_other,
    registration_number_place,
    registration_number,
    car_name,
    line,
    reception,
    arrival_time_scene,
    dispatcher_name,
    customer_number,
    anshin_call,
    forwarding_distance,
    transportation_distance,
    secondary_transportation,
    dispatch_vehicle,
    departure_time,
    arrival_time,
    completion_time,
    transport_end_time,
    matter_end_time,
    secondary_dispatcher_name,
    secondary_dispatch_vehicle,
    secondary_departure_time,
    secondary_arrival_time,
    secondary_transport_end_time,
    secondary_matter_end_time,
    secondary_date,
    work_detail_scene,
    go_fee,
    forward_fee,
    transport_fee,
    return_fee,
    before_starting_BJ,
    after_starting_BJ,
    refueling_fee_type,
    refueling_fee,
    oil_type,
    oil_volume,
    running_off_shoulder,
    high_speed_troop,
    storage_location,
    transport_destination,
    address,
    responsible_person,
    towing_shop,
    tow_implementation,
    vehicle_custody_store,
    transportation_work,
    sorbent_mass,
    destination_number,
    scene_confirmation,
  } = data;

  if (weather) {
    newHtml = newHtml.replace("<!-- {weather} -->", weather?.value ?? "");
  }
  if (receptionist) {
    newHtml = newHtml.replace("<!-- {receptionist} -->", receptionist);
  }
  if (request) {
    newHtml = newHtml.replace(
      "<!-- {request} -->",
      request?.is_manual_input
        ? `その他（${request_other}）`
        : request?.wrecker_name ?? "",
    );
  }
  if (customer_name) {
    newHtml = newHtml.replace("<!-- {customer_name} -->", customer_name);
  }
  if (reception_date) {
    newHtml = newHtml.replace(
      "<!-- {reception_date} -->",
      createDateText(reception_date),
    );
  }
  if (reception_time) {
    newHtml = newHtml.replace("<!-- {reception_time} -->", reception_time);
  }
  if (work_content) {
    newHtml = newHtml.replace(
      "<!-- {work_content} -->",
      work_content?.wrecker_name ?? "",
    );
  }
  if (work_detail_reception) {
    newHtml = newHtml.replace(
      "<!-- {work_detail_reception} -->",
      createHtmlForMultipleText(work_detail_reception),
    );
  }
  if (location) {
    newHtml = newHtml.replace(
      "<!-- {location} -->",
      location?.wrecker_name ?? "",
    );
  }
  if (location_other) {
    newHtml = newHtml.replace("<!-- {location_other} -->", location_other);
  }
  if (registration_number_place) {
    newHtml = newHtml.replace(
      "<!-- {registration_number_place} -->",
      registration_number_place?.wrecker_name ?? "",
    );
  }
  if (registration_number) {
    newHtml = newHtml.replace(
      "<!-- {registration_number} -->",
      `(${registration_number})`,
    );
  }
  if (car_name) {
    newHtml = newHtml.replace("<!-- {car_name} -->", car_name);
  }
  if (line) {
    newHtml = newHtml.replace("<!-- {line} -->", line?.wrecker_name ?? "");
  }
  if (reception) {
    newHtml = newHtml.replace("<!-- {reception} -->", reception);
  }
  if (arrival_time_scene) {
    newHtml = newHtml.replace(
      "<!-- {arrival_time_scene} -->",
      `${arrival_time_scene}分`,
    );
  }

  if (dispatcher_name) {
    newHtml = newHtml.replace("<!-- {dispatcher_name} -->", dispatcher_name);
  }
  if (customer_number) {
    newHtml = newHtml.replace("<!-- {customer_number} -->", customer_number);
  }
  if (anshin_call) {
    newHtml = newHtml.replace(
      "<!-- {anshin_call} -->",
      anshin_call?.value ?? "",
    );
  }
  if (forwarding_distance) {
    newHtml = newHtml.replace(
      "<!-- {forwarding_distance} -->",
      forwarding_distance,
    );
  }
  if (transportation_distance) {
    newHtml = newHtml.replace(
      "<!-- {transportation_distance} -->",
      transportation_distance,
    );
  }
  if (secondary_transportation) {
    newHtml = newHtml.replace(
      "<!-- {secondary_transportation} -->",
      secondary_transportation,
    );
  }
  if (dispatch_vehicle) {
    newHtml = newHtml.replace(
      "<!-- {dispatch_vehicle} -->",
      dispatch_vehicle?.wrecker_name ?? "",
    );
  }
  if (departure_time) {
    newHtml = newHtml.replace("<!-- {departure_time} -->", departure_time);
  }
  if (arrival_time) {
    newHtml = newHtml.replace("<!-- {arrival_time} -->", arrival_time);
  }
  if (completion_time) {
    newHtml = newHtml.replace("<!-- {completion_time} -->", completion_time);
  }
  if (transport_end_time) {
    newHtml = newHtml.replace(
      "<!-- {transport_end_time} -->",
      transport_end_time,
    );
  }
  if (matter_end_time) {
    newHtml = newHtml.replace("<!-- {matter_end_time} -->", matter_end_time);
  }
  if (secondary_dispatcher_name) {
    newHtml = newHtml.replace(
      "<!-- {secondary_dispatcher_name} -->",
      secondary_dispatcher_name,
    );
  }
  if (secondary_dispatch_vehicle) {
    newHtml = newHtml.replace(
      "<!-- {secondary_dispatch_vehicle} -->",
      secondary_dispatch_vehicle?.wrecker_name ?? "",
    );
  }
  if (secondary_departure_time) {
    newHtml = newHtml.replace(
      "<!-- {secondary_departure_time} -->",
      secondary_departure_time,
    );
  }
  if (secondary_arrival_time) {
    newHtml = newHtml.replace(
      "<!-- {secondary_arrival_time} -->",
      secondary_arrival_time,
    );
  }
  if (secondary_transport_end_time) {
    newHtml = newHtml.replace(
      "<!-- {secondary_transport_end_time} -->",
      secondary_transport_end_time,
    );
  }
  if (secondary_matter_end_time) {
    newHtml = newHtml.replace(
      "<!-- {secondary_matter_end_time} -->",
      secondary_matter_end_time,
    );
  }
  if (secondary_date) {
    newHtml = newHtml.replace(
      "<!-- {secondary_date} -->",
      createDateText(secondary_date),
    );
  }
  if (work_detail_scene) {
    newHtml = newHtml.replace(
      "<!-- {work_detail_scene} -->",
      createHtmlForMultipleText(work_detail_scene),
    );
  }
  if (go_fee) {
    newHtml = newHtml.replace(
      "<!-- {go_fee} -->",
      `通行料金: ${go_fee}円 <br/>`,
    );
  }
  if (forward_fee?.length > 0) {
    newHtml = newHtml.replace(
      "<!-- {forward_fee} -->",
      `【回送】 ${createFeeText(forward_fee)} <br/>`,
    );
  }
  if (transport_fee?.length > 0) {
    newHtml = newHtml.replace(
      "<!-- {transport_fee} -->",
      `【搬送】 ${createFeeText(transport_fee)} <br/>`,
    );
  }
  if (return_fee?.length > 0) {
    newHtml = newHtml.replace(
      "<!-- {return_fee} -->",
      `【帰り】 ${createFeeText(return_fee)} <br/>`,
    );
  }
  if (before_starting_BJ) {
    newHtml = newHtml.replace(
      "<!-- {before_starting_BJ} -->",
      `${before_starting_BJ}`,
    );
  }
  if (after_starting_BJ) {
    newHtml = newHtml.replace(
      "<!-- {after_starting_BJ} -->",
      `${after_starting_BJ}`,
    );
  }
  if (refueling_fee_type) {
    newHtml = newHtml.replace(
      "<!-- {refueling_fee_type} -->",
      refueling_fee_type?.value ?? "",
    );
  }
  if (refueling_fee && refueling_fee_type?.SK === 2) {
    newHtml = newHtml.replace("<!-- {refueling_fee} -->", refueling_fee);
  }
  if (oil_type) {
    newHtml = newHtml.replace("<!-- {oil_type} -->", oil_type?.value);
  }
  if (oil_volume) {
    newHtml = newHtml.replace("<!-- {oil_volume} -->", oil_volume);
  }
  if (running_off_shoulder) {
    newHtml = newHtml.replace(
      "<!-- {running_off_shoulder} -->",
      `（${running_off_shoulder?.wrecker_name ?? ""}）`,
    );
  }
  if (high_speed_troop) {
    newHtml = newHtml.replace(
      "<!-- {high_speed_troop} -->",
      `高速隊（${high_speed_troop?.wrecker_name ?? ""}）`,
    );
  }
  if (storage_location) {
    newHtml = newHtml.replace("<!-- {storage_location} -->", storage_location);
  }
  if (transport_destination) {
    newHtml = newHtml.replace(
      "<!-- {transport_destination} -->",
      transport_destination?.wrecker_name ?? "",
    );
  }
  if (address) {
    newHtml = newHtml.replace("<!-- {address} -->", address);
  }
  if (responsible_person) {
    newHtml = newHtml.replace(
      "<!-- {responsible_person} -->",
      responsible_person,
    );
  }
  if (towing_shop) {
    newHtml = newHtml.replace(
      "<!-- {towing_shop_name} -->",
      towing_shop?.wrecker_name,
    );
    newHtml = newHtml.replace(
      "<!-- {towing_shop_tel} -->",
      "℡. " + towing_shop?.wrecker_tel,
    );
  }
  if (tow_implementation) {
    newHtml = newHtml.replace(
      "<!-- {tow_implementation} -->",
      tow_implementation,
    );
  }
  if (vehicle_custody_store) {
    newHtml = newHtml.replace(
      "<!-- {vehicle_custody_store_name} -->",
      vehicle_custody_store?.wrecker_name,
    );
    newHtml = newHtml.replace(
      "<!-- {vehicle_custody_tel} -->",
      "℡. " + vehicle_custody_store?.wrecker_tel,
    );
    newHtml = newHtml.replace(
      "<!-- {vehicle_custody_address} -->",
      vehicle_custody_store?.wrecker_address,
    );
  }
  if (transportation_work && transportation_work?.length > 0) {
    newHtml = newHtml.replace(
      "<!-- {transportation_work} -->",
      transportation_work.map((item) => item?.wrecker_name ?? "").join("・"),
    );
  }
  if (sorbent_mass) {
    newHtml = newHtml.replace(
      "<!-- {sorbent_mass} -->",
      `（${sorbent_mass}kg）`,
    );
  }
  if (destination_number) {
    newHtml = newHtml.replace(
      "<!-- {destination_number} -->",
      destination_number,
    );
  }
  if (scene_confirmation) {
    newHtml = newHtml.replace(
      "<!-- {scene_confirmation} -->",
      createHtmlForMultipleText(scene_confirmation),
    );
  }
  return newHtml;
};

export const insertReportLocal = async (
  newHtml: string,
  data: StateFormType,
  dataExample: any,
) => {
  const {
    is_check_vehicle_appearance,
    is_all_four_wheels,
    is_presence_of_key,
    is_lost_item,
    is_BT_removed,
    is_name_card,
    images,
    vehicle_appearance,
    destination_signature,
    date_sign_destination_signature,
    customer_signature,
    signer_attribute,
  } = data;
  const {
    imageExample,
    imageVehicleAppearanceExample,
    imageSignatureExample,
    checkIconExample,
  } = dataExample;
  if (is_check_vehicle_appearance) {
    newHtml = newHtml.replace(
      "<!-- {is_check_vehicle_appearance} -->",
      checkIconExample,
    );
  }
  if (is_all_four_wheels) {
    newHtml = newHtml.replace(
      "<!-- {is_all_four_wheels} -->",
      checkIconExample,
    );
  }
  if (is_presence_of_key) {
    newHtml = newHtml.replace(
      "<!-- {is_presence_of_key} -->",
      checkIconExample,
    );
  }
  if (is_lost_item) {
    newHtml = newHtml.replace("<!-- {is_lost_item} -->", checkIconExample);
  }
  if (is_BT_removed) {
    newHtml = newHtml.replace("<!-- {is_BT_removed} -->", checkIconExample);
  }
  if (is_name_card) {
    newHtml = newHtml.replace("<!-- {is_name_card} -->", checkIconExample);
  }
  if (images?.length > 0) {
    const arrayImageVisible: Type.ImageInfoType[] = [];
    for (const imageItem of images) {
      if (arrayImageVisible?.length >= 4) {
        break;
      }
      if (imageItem?.uri) {
        arrayImageVisible.push(imageItem);
      }
    }

    for (let index = 0; index < arrayImageVisible.length; index++) {
      const item = arrayImageVisible[index];
      let imageExampleClone = imageExample;
      imageExampleClone = imageExampleClone.replace(
        "{image_link}",
        item?.uri_jpg ?? item?.uri,
      );
      newHtml = newHtml.replace("<!-- {image_area} -->", imageExampleClone);
    }
  }
  if (vehicle_appearance?.uri) {
    let imageVehicleAppearanceExampleClone = imageVehicleAppearanceExample;
    imageVehicleAppearanceExampleClone =
      imageVehicleAppearanceExampleClone.replace(
        "{image_link}",
        vehicle_appearance?.uri_jpg ?? vehicle_appearance?.uri,
      );
    newHtml = newHtml.replace(
      "<!-- {vehicle_appearance} -->",
      imageVehicleAppearanceExampleClone,
    );
  }
  if (destination_signature?.uri) {
    let imageSignatureExampleClone = imageSignatureExample;
    imageSignatureExampleClone = imageSignatureExampleClone.replace(
      "{image_link}",
      destination_signature?.uri_jpg ?? destination_signature?.uri,
    );
    newHtml = newHtml.replace(
      "<!-- {destination_signature} -->",
      imageSignatureExampleClone,
    );
    if (date_sign_destination_signature) {
      newHtml = newHtml.replace(
        "<!-- {date_sign_destination_signature} -->",
        createDateText(date_sign_destination_signature),
      );
    }
  }
  if (customer_signature?.uri) {
    let imageSignatureExampleClone = imageSignatureExample;
    imageSignatureExampleClone = imageSignatureExampleClone.replace(
      "{image_link}",
      customer_signature?.uri_jpg ?? customer_signature?.uri,
    );
    newHtml = newHtml.replace(
      "<!-- {customer_signature} -->",
      imageSignatureExampleClone,
    );
    if (signer_attribute) {
      newHtml = newHtml.replace(
        "<!-- {signer_attribute} -->",
        signer_attribute.value,
      );
    }
  }
  return newHtml;
};

export const insertDataToTemplate = async (
  htmlString: string,
  data: StateFormType,
) => {
  htmlString = makeNewHtml(htmlString, data);
  htmlString = await insertReportLocal(
    htmlString,
    data,
    getInfoFromTemplate(htmlString),
  );
  const doc = document.createElement("div");
  doc.appendChild(document.createRange().createContextualFragment(htmlString));
  var serializeHtml = new XMLSerializer().serializeToString(doc);
  const svg = new Blob([serializeHtml], { type: "text/html" });
  return createObjectURL(svg);
};

export const mergeTemplateYuasa = async (
  templateUri: string,
  data: StateFormType,
) => {
  const f = await getFileByUrl(templateUri, "template.html", "text/html");
  let html_string = await f.text();

  templateUri = await insertDataToTemplate(html_string, data);
  return templateUri;
};

export const handleEditInfoYuasa = (templateEditInfo: any) => {
  const newTemplateEditInfo = { ...templateEditInfo };
  return newTemplateEditInfo;
};
