import { getItemTypeFilter } from "@utils/index";
import {
  deleteImageDataWhenFinishRiskyId,
  mergeTemplateReportRiskyId,
} from "@utils/template/riskyId";
import {
  handleEditInfoForkliftReport,
  mergeTemplateForkliftReport,
} from "@utils/template/forkliftReport";
import { mergeTemplateInspectionReport } from "@utils/template/inspectionReport";
import { mergeTemplateExcel } from "@utils/template/excelTemplate";
import { mergeTemplateYuasa } from "@utils/template/yuasa";

const MAX_CAPACITY = 10;

//帳票選択でお気に入り登録できる最大帳票数
export const MAX_FAVORITE = 10;

type TUser_roles = {
  // --->> TLOG
  // [key in "OWNER" | "ADMIN" | "MEMBER"]: { name: string; value: string };
  [key in "OWNER" | "ADMIN" | "MEMBER" | "EDITOR"]: {
    name: string;
    value: string;
  };
  // <<--- TLOG
};
interface IUser_roles extends TUser_roles {
  [key: string]: { name: string; value: string };
}
export const USER_ROLES: IUser_roles = {
  OWNER: {
    name: "スーパーユーザー",
    // <<--- TLOG
    // name: "契約者",
    // --->> TLOG
    value: "Owner",
  },
  ADMIN: {
    name: "管理者",
    value: "Admin",
  },
  MEMBER: {
    name: "一般",
    value: "Member",
  },
  // --->> TLOG
  EDITOR: {
    name: "編集者",
    value: "Editor",
  },
  // <<--- TLOG
};

export const LIST_USER_ROLE = Object.values(USER_ROLES).slice(0, 3);

export const LIST_USER_ROLE_FOR_ADMIN = Object.values(USER_ROLES).slice(1, 3);

export const LIST_USER_ROLE_FOR_MEMBER_AND_EDITOR = Object.values(
  USER_ROLES,
).slice(2, 3);

export const COUNTRY_CODE_JP = "+81";

export const SEAL_MAX_ROWS = 3; // TLOG

const COLOR_BACKGROUND_NAME_STATUS_WORK: any = {
  ASSIGNMENT: "#F8F0D5",
  INPROGRESS: "#B7F9CB",
  REPORT: "#DBF2F1",
  REJECT: "#FFE2EA",
  FINISH: "#F3F3F3",
  WAITING: "#DBF2F1",
};

const COLOR_STATUS_WORK: any = {
  ASSIGNMENT: "#FECA07",
  INPROGRESS: "#34AC58",
  REPORT: "#7FD5D1",
  REJECT: "#DE1A56",
  FINISH: "#8A9594",
  WAITING: "#7FD5D1",
};

// --->> TLOG
const COLOR_BACKGROUND_NAME_STATUS_WORK_TLOG: any = {
  REJECT: "#FEFF81",
  CANCEL: "#FFCA81",
  DRAFT: "#78D9A9",
  FINISH: "#FFFFFF",
  NOT_APPROVED: "#BFE4FF",
};

const COLOR_STATUS_WORK_TLOG: any = {
  REJECT: "#FFF100",
  CANCEL: "#FF4B00",
  DRAFT: "#01AF7A",
  FINISH: "#84919E",
  NOT_APPROVED: "#4CC4FF",
};

const TITLE_STATUS_WORK_TLOG: any = {
  REJECT: "差戻",
  CANCEL: "却下",
  DRAFT: "一時保存",
  FINISH: "完了",
  NOT_APPROVED: "承認待ち",
};

const LIST_WORK_STATUS_TLOG = [
  {
    name: "承認待ち",
    id: "NOT_APPROVED",
    isSelected: true,
  },
  {
    name: "承認完了",
    id: "FINISH",
    isSelected: false,
  },
  {
    name: "差戻",
    id: "REJECT",
    isSelected: false,
  },
  {
    name: "却下",
    id: "CANCEL",
    isSelected: false,
  },
  {
    name: "一時保存",
    id: "DRAFT",
    isSelected: false,
  },
];

// <<--- TLOG

const TITLE_STATUS_WORK: any = {
  ASSIGNMENT: "指⽰",
  INPROGRESS: "対応中",
  REPORT: "報告",
  REJECT: "差し戻し",
  FINISH: "完了",
  WAITING: "承認待ち",
};

const STATUS_WORK = {
  ASSIGNMENT: "指示",
  INPROGRESS: "対応中",
  REPORT: "報告",
  REJECT: "差し戻し",
  FINISH: "完了",
  WAITING: "承認待ち",
};

// --->> TLOG
const STATUS_WORK_TLOG = {
  REJECT: "REJECT",
  CANCEL: "CANCEL",
  DRAFT: "DRAFT",
  FINISH: "FINISH",
  NOT_APPROVED: "NOT_APPROVED",
  CANCEL_REQUEST: "CANCEL_REQUEST",
};

export const StatisticalBoardType = {
  request: "request",
  register: "register",
};

export const TYPE_USER_REPORT = {
  CREATE: "CREATE",
  APPROVE: "APPROVE",
};
// <<--- TLOG

const PHOTO_TYPE_ACCEPT = [
  "jpeg",
  "JPEG",
  "heic",
  "HEIC",
  "png",
  "PNG",
  "jpg",
  "JPG",
];

const LIST_WORK_STATUS = [
  {
    name: "指⽰",
    id: "ASSIGNMENT",
  },
  {
    name: "対応中",
    id: "INPROGRESS",
  },
  {
    name: "承認待ち",
    id: "WAITING",
  },
  {
    name: "報告",
    id: "REPORT",
  },
  {
    name: "差し戻し",
    id: "REJECT",
  },
  {
    name: "完了",
    id: "FINISH",
  },
];

const LIST_TYPE_PERIOD_FILTER = [
  {
    name: "期限内",
    id: "false",
  },
  {
    name: "期限切れ",
    id: "true",
  },
  {
    name: "期限なし",
    id: "null",
  },
];

const LIST_DOCUMENT = [
  {
    name: "報告書",
    id: "REPORT",
  },
  {
    name: "指示書",
    id: "ASSIGNMENT",
  },
];

const LIST_ROLE_FILTER = [
  {
    name: "スーパーユーザー",
    id: USER_ROLES.OWNER.value,
  },
  {
    name: "管理者",
    id: USER_ROLES.ADMIN.value,
  },
  // {
  //   name: "一般（編集)",
  //   id: USER_ROLES.EDITOR.value,
  // },
  {
    name: "一般（起票)",
    id: USER_ROLES.MEMBER.value,
  },
];

// --->> TLOG
const STATUS_PRINTED = {
  NOT_PRINTED: "未印刷",
  PRINTED: "印刷済",
};

const LIST_STATUS_PRINTED = [
  {
    name: STATUS_PRINTED.NOT_PRINTED,
    id: "false",
  },
  {
    name: STATUS_PRINTED.PRINTED,
    id: "true",
  },
];
// <<--- TLOG

const ALL_LIST_TYPE_FILTER: any = {
  作業状況: {
    title: "作業状況",
    data: LIST_WORK_STATUS,
    type_filter: "work_status",
  },
  期限: {
    title: "期限",
    data: LIST_TYPE_PERIOD_FILTER,
    type_filter: "expired",
  },
  種別: {
    title: "種別",
    data: null,
    type_filter: "category",
  },
  作成者: {
    title: "作成者",
    data: null,
    type_filter: "author",
  },
  形式: {
    title: "形式",
    data: null,
    type_filter: "format",
  },
  書類: {
    title: "書類",
    data: LIST_DOCUMENT,
    type_filter: "documents",
  },
  拠点: {
    title: "拠点",
    data: null,
    type_filter: "location",
    enableRole: [USER_ROLES.OWNER.value],
  },
  権限: {
    title: "権限",
    data: LIST_ROLE_FILTER,
    type_filter: "role",
  },
  // --->> TLOG
  報告者: {
    title: "報告者",
    data: null,
    type_filter: "author",
  },
  帳票_project: {
    title: "帳票",
    data: null,
    type_filter: "project",
  },
  印刷状態: {
    title: "印刷状態",
    data: LIST_STATUS_PRINTED,
    type_filter: "print",
  },
  拠点_all_user: {
    title: "拠点",
    data: null,
    type_filter: "location",
  },
  帳票: {
    title: "帳票",
    data: null,
    type_filter: "report",
  },
  作成者_all: {
    title: "作成者",
    data: null,
    type_filter: "author_all",
  },
  拠点_all: {
    title: "拠点",
    data: null,
    type_filter: "location_all",
  },
  // <<--- TLOG
};

const TYPE_FILTER_SCREEN = [
  "project",
  "ledger",
  "folder",
  "work_copy",
  "work_group",
  "work_delete",
  "other",
  "work",
  "work_status", // TLOG
  "members_management",
  "handover_report",
  "custom_template",
  "custom_template_trash",
] as const;

const LIST_TYPE_FILTER_SCREEN: {
  [key in (typeof TYPE_FILTER_SCREEN)[number]]: any;
} = {
  project: getItemTypeFilter(["拠点", "期限"], ALL_LIST_TYPE_FILTER),
  ledger: getItemTypeFilter(["期限", "作成者"], ALL_LIST_TYPE_FILTER),
  folder: getItemTypeFilter(
    ["種別", "作成者", "形式", "書類"],
    ALL_LIST_TYPE_FILTER,
  ),
  work_copy: getItemTypeFilter(
    ["期限", "種別", "作成者"],
    ALL_LIST_TYPE_FILTER,
  ),
  work_group: getItemTypeFilter(
    ["作業状況", "期限", "種別", "作成者"],
    ALL_LIST_TYPE_FILTER,
    "work_group",
  ),
  work_delete: getItemTypeFilter(
    ["作業状況", "期限", "種別", "作成者"],
    ALL_LIST_TYPE_FILTER,
  ),
  other: getItemTypeFilter(["種別", "作成者", "書類"], ALL_LIST_TYPE_FILTER),
  work: getItemTypeFilter(
    ["作業状況", "期限", "種別", "作成者"],
    ALL_LIST_TYPE_FILTER,
  ),
  // --->> TLOG
  work_status: getItemTypeFilter(
    ["報告者", "帳票_project", "印刷状態"],
    ALL_LIST_TYPE_FILTER,
  ),
  members_management: getItemTypeFilter(["拠点", "権限"], ALL_LIST_TYPE_FILTER),
  handover_report: getItemTypeFilter(
    ["報告者", "拠点_all_user"],
    ALL_LIST_TYPE_FILTER,
  ),
  custom_template: getItemTypeFilter(
    ["拠点_all", "帳票", "作成者_all"],
    ALL_LIST_TYPE_FILTER,
  ),
  custom_template_trash: getItemTypeFilter(
    ["拠点_all", "帳票", "作成者_all"],
    ALL_LIST_TYPE_FILTER,
  ),
  // <<--- TLOG
};

const TEXT_EMAIL_PAGE = {
  timeReport: "申請時",
  approved: "承認時",
  resend: "差戻時",
  done: "完了時",
  deny: "却下時",
  cancel: "キャンセル時",
  targetNotiField: "通知対象",
  contentField: "通知内容",
  targetOption: "内容選択",
  title: "題名",
  content: "本文",
};

const MAX_LENGTH_TITLE = 100;
const MAX_LENGTH_CONTENT = 1000;
const MAX_LENGTH_30 = 30;

const TEMPLATE_SORT_TYPE = {
  nameSort: "name_sort",
};
const TEMPLATE_TYPE = {
  TEMPLATE_WORK: "TEMPLATE_WORK",
};
const TEMPLATE_FILTER = {
  report: "REPORT",
};

const LIST_ALL_SORT_TYPE: {
  [fieldName: string]: {
    value: string;
    title: string;
  };
} = {
  update_time_latest_sort: {
    value: "update_time_latest_sort",
    title: "更新が新しい順",
  },
  update_time_oldest_sort: {
    value: "update_time_oldest_sort",
    title: "更新が古い順",
  },
  create_time_latest_sort: {
    value: "create_time_latest_sort",
    title: "作成が新しい順",
  },
  create_time_oldest_sort: {
    value: "create_time_oldest_sort",
    title: "作成が古い順",
  },
  work_end_date_sort: { value: "work_end_date_sort", title: "期日が近い順" },
  ledger_name_sort: {
    value: "ledger_name_sort",
    title: "名前順",
  },
  ledger_number_of_images_sort: {
    value: "ledger_number_of_images_sort",
    title: "写真枚数順",
  },
  last_use_sort: {
    value: "last_use_sort",
    title: "最近利用した順",
  },
  name_sort: {
    value: "name_sort",
    title: "名前順",
  },
  default: {
    value: "default",
    title: "登録順",
  },
  picture_day_sort: {
    value: "picture_day_sort",
    title: "撮影が新しい順",
  },
  default_folder: {
    value: "default",
    title: "初期順",
  },
  updated_at_new_folder: {
    value: "updated_at_new",
    title: "更新が新しい順",
  },
  updated_at_old_folder: {
    value: "updated_at_old",
    title: "更新が古い順",
  },
  created_at_new_folder: { value: "created_at_new", title: "追加が新しい順" },
  created_at_old_folder: { value: "created_at_old", title: "追加が古い順" },
  name_folder: { value: "name", title: "名前順" },
  file_created_at_folder_updated_at_new: {
    value: "file_created_at_folder_updated_at_new",
    title: "追加・更新が新しい順",
  },
  file_created_at_folder_updated_at_old: {
    value: "file_created_at_folder_updated_at_old",
    title: "追加・更新が古い順",
  },
  last_used_latest_sort: {
    value: "last_used_latest_sort",
    title: "最終利用が近い順",
  },
  last_used_oldest_sort: {
    value: "last_used_oldest_sort",
    title: "最終利用が遠い順",
  },
  deleted_time_latest_sort: {
    value: "deleted_time_latest_sort",
    title: "削除が新しい順",
  },
  deleted_time_oldest_sort: {
    value: "deleted_time_oldest_sort",
    title: "削除が古い順",
  },
  date_of_delivery_latest_sort: {
    value: "date_of_delivery_latest_sort",
    title: "納入日時が遠い順",
  },
  date_of_delivery_oldest_sort: {
    value: "date_of_delivery_oldest_sort",
    title: "納入日時が近い順",
  },
};

const LIST_TYPE_SORT_PROJECT: typeof LIST_ALL_SORT_TYPE = {
  update_time_latest_sort: LIST_ALL_SORT_TYPE.update_time_latest_sort, //更新が新しい順
  update_time_oldest_sort: LIST_ALL_SORT_TYPE.update_time_oldest_sort, //更新が古い順
  create_time_latest_sort: LIST_ALL_SORT_TYPE.create_time_latest_sort, //作成が新しい順
  create_time_oldest_sort: LIST_ALL_SORT_TYPE.create_time_oldest_sort, //作成が古い順
};

const LIST_TYPE_SORT_WORK: typeof LIST_ALL_SORT_TYPE = {
  update_time_latest_sort: LIST_ALL_SORT_TYPE.update_time_latest_sort, //更新が新しい順
  update_time_oldest_sort: LIST_ALL_SORT_TYPE.update_time_oldest_sort, //更新が古い順
  create_time_latest_sort: LIST_ALL_SORT_TYPE.create_time_latest_sort, //作成が新しい順
  create_time_oldest_sort: LIST_ALL_SORT_TYPE.create_time_oldest_sort, //作成が古い順
  work_end_date_sort: LIST_ALL_SORT_TYPE.work_end_date_sort, //期日が近い順
};

const LIST_TYPE_SORT_IMAGE_LEDGER: typeof LIST_ALL_SORT_TYPE = {
  default: LIST_ALL_SORT_TYPE.default, //登録順
  picture_day_sort: LIST_ALL_SORT_TYPE.picture_day_sort, //撮影が新しい順
};

const LIST_TYPE_SORT_LEDGER: typeof LIST_ALL_SORT_TYPE = {
  update_time_latest_sort: LIST_ALL_SORT_TYPE.update_time_latest_sort, //更新が新しい順
  update_time_oldest_sort: LIST_ALL_SORT_TYPE.update_time_oldest_sort, //更新が古い順
  create_time_latest_sort: LIST_ALL_SORT_TYPE.create_time_latest_sort, //作成が新しい順
  create_time_oldest_sort: LIST_ALL_SORT_TYPE.create_time_oldest_sort, //作成が古い順
  ledger_name_sort: LIST_ALL_SORT_TYPE.ledger_name_sort, //名前順
  ledger_number_of_images_sort: LIST_ALL_SORT_TYPE.ledger_number_of_images_sort, //写真枚数順
};

const LIST_TYPE_SORT_TEMPLATE: {
  [fieldName: string]: typeof LIST_ALL_SORT_TYPE;
} = {
  TEMPLATE_WORK: {
    last_use_sort: LIST_ALL_SORT_TYPE.last_use_sort, //最近利用した順
    name_sort: LIST_ALL_SORT_TYPE.name_sort, //名前順
  },
  TEMPLATE_LEDGER: {
    last_use_sort: LIST_ALL_SORT_TYPE.last_use_sort, //最近利用した順
    name_sort: LIST_ALL_SORT_TYPE.name_sort, //名前順
  },
  TEMPLATE_BLACKBOARD: {
    last_use_sort: LIST_ALL_SORT_TYPE.last_use_sort, //最近利用した順
    name_sort: LIST_ALL_SORT_TYPE.name_sort, //名前順
  },
};

const LIST_TYPE_SORT_FOLDER: {
  [fieldName: string]: typeof LIST_ALL_SORT_TYPE;
} = {
  TOP: {
    default: LIST_ALL_SORT_TYPE.default_folder, //初期順
    updated_at_new: LIST_ALL_SORT_TYPE.updated_at_new_folder, //更新が新しい順
    updated_at_old: LIST_ALL_SORT_TYPE.updated_at_old_folder, //更新が古い順
  },
  NOT_SAVE_FREE: {
    created_at_new: LIST_ALL_SORT_TYPE.created_at_new_folder, //追加が新しい順
    created_at_old: LIST_ALL_SORT_TYPE.created_at_old_folder, //追加が古い順
    name_sort: LIST_ALL_SORT_TYPE.name_folder, //名前順
  },
  SAVE_FREE: {
    file_created_at_folder_updated_at_new:
      LIST_ALL_SORT_TYPE.file_created_at_folder_updated_at_new, //追加・更新が新しい順
    file_created_at_folder_updated_at_old:
      LIST_ALL_SORT_TYPE.file_created_at_folder_updated_at_old, //追加・更新が古い順
    name_sort: LIST_ALL_SORT_TYPE.name_folder, //名前順
  },
};

const LIST_TYPE_SORT_MEMBER_LIST: typeof LIST_ALL_SORT_TYPE = {
  update_time_latest_sort: LIST_ALL_SORT_TYPE.update_time_latest_sort, //更新が新しい順
  update_time_oldest_sort: LIST_ALL_SORT_TYPE.update_time_oldest_sort, //更新が古い順
};

const LIST_TYPE_SORT_CUSTOM_TEMPLATE: typeof LIST_ALL_SORT_TYPE = {
  update_time_latest_sort: LIST_ALL_SORT_TYPE.update_time_latest_sort, //更新が新しい順
  update_time_oldest_sort: LIST_ALL_SORT_TYPE.update_time_oldest_sort, //更新が古い順
  last_used_latest_sort: LIST_ALL_SORT_TYPE.last_used_latest_sort, //最終利用が近い順
  last_used_oldest_sort: LIST_ALL_SORT_TYPE.last_used_oldest_sort, //最終利用が遠い順
};

const LIST_TYPE_SORT_CUSTOM_TEMPLATE_TRASH: typeof LIST_ALL_SORT_TYPE = {
  deleted_time_latest_sort: LIST_ALL_SORT_TYPE.deleted_time_latest_sort, //削除が新しい順
  deleted_time_oldest_sort: LIST_ALL_SORT_TYPE.deleted_time_oldest_sort, //削除が古い順
};

const LIST_TYPE_SORT_DEVAN_SCHEDULE: typeof LIST_ALL_SORT_TYPE = {
  date_of_delivery_latest_sort: LIST_ALL_SORT_TYPE.date_of_delivery_latest_sort, //納入日時が遠い順
  date_of_delivery_oldest_sort: LIST_ALL_SORT_TYPE.date_of_delivery_oldest_sort, //納入日時が近い順
};

const FOLDER_KIND: any = {
  SAVEFREEFOLDER: "SAVEFREEFOLDER",
  SUBFOLDER: "SUBFOLDER",
};

// --->> お絵描き機能
export const fontSizeText = 11;

export const itemWidth = {
  light: 2,
  bold: 6,
};

export const itemColor = {
  red: "rgb(255,1,0)",
  yellow: "rgb(253,255,0)",
  black: "rgb(0,0,0)",
  white: "rgb(255,255,255)",
};

export const DCP_ORG_IMAGE = "dcp_org_img";
export const DCP_ANNOTATION = "dcp_annotation";
// <<--- お絵描き機能

// --->> TLOG
export const TEMPLATE_EDIT_TYPE = {
  TEMPLATE_REPORT_TYPE_1: "TEMPLATE_REPORT_TYPE_1",
  TEMPLATE_REPORT_TYPE_2: "TEMPLATE_REPORT_TYPE_2",
  TEMPLATE_ASSIGNMENT_TYPE_1: "TEMPLATE_ASSIGNMENT_TYPE_1",
  RISKY_REPORT: "RISKY_REPORT",
  TEMPLATE_FORKLIFT_REPORT: "TEMPLATE_FORKLIFT_REPORT",
  INSPECTION_REPORT: "INSPECTION_REPORT",
  TEMPLATE_EXCEL_REPORT_SETTING: "TEMPLATE_EXCEL_REPORT_SETTING",
  TEMPLATE_EXCEL_REPORT: "TEMPLATE_EXCEL_REPORT",
  TEMPLATE_YUASA: "TEMPLATE_YUASA",
};

export const screenIdSupport: { [fieldName: string]: string } = {
  SCREEN_TEMPLATE_01: TEMPLATE_EDIT_TYPE.TEMPLATE_REPORT_TYPE_1,
  SCREEN_TEMPLATE_06: TEMPLATE_EDIT_TYPE.RISKY_REPORT,
  SCREEN_TEMPLATE_02: TEMPLATE_EDIT_TYPE.TEMPLATE_ASSIGNMENT_TYPE_1,
  SCREEN_TEMPLATE_07: TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT,
  SCREEN_TEMPLATE_08: TEMPLATE_EDIT_TYPE.INSPECTION_REPORT,
  SCREEN_TEMPLATE_09: TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT,
  SCREEN_TEMPLATE_10: TEMPLATE_EDIT_TYPE.TEMPLATE_YUASA,
};

export const screenIdIsLandscape: { [fieldName: string]: boolean } = {
  SCREEN_TEMPLATE_01: false,
  SCREEN_TEMPLATE_06: true,
  SCREEN_TEMPLATE_02: false,
  SCREEN_TEMPLATE_07: true,
  SCREEN_TEMPLATE_08: false,
  SCREEN_TEMPLATE_10: false,
};

export const screenIdSupportMergeTemplate: { [fieldName: string]: Function } = {
  SCREEN_TEMPLATE_06: mergeTemplateReportRiskyId,
  SCREEN_TEMPLATE_07: mergeTemplateForkliftReport,
  SCREEN_TEMPLATE_08: mergeTemplateInspectionReport,
  SCREEN_TEMPLATE_09: mergeTemplateExcel,
  SCREEN_TEMPLATE_10: mergeTemplateYuasa,
};

export const deleteImageDataWhenFinish: { [fieldName: string]: Function } = {
  SCREEN_TEMPLATE_06: deleteImageDataWhenFinishRiskyId,
  SCREEN_TEMPLATE_07: (templateEditInfo: any) => templateEditInfo,
  SCREEN_TEMPLATE_08: (templateEditInfo: any) => templateEditInfo,
  SCREEN_TEMPLATE_09: (templateEditInfo: any) => templateEditInfo,
  SCREEN_TEMPLATE_10: (templateEditInfo: any) => templateEditInfo,
};

export const screenIdImageList: { [fieldName: string]: string } = {
  SCREEN_TEMPLATE_06: "image_array",
  SCREEN_TEMPLATE_07: "image_array",
  SCREEN_TEMPLATE_08: "abnomality_image",
  SCREEN_TEMPLATE_09: "image_array,custom_image",
  SCREEN_TEMPLATE_10: "images",
};

export const BUTTON_EDIT_TEMPLATE_OPEN = {
  CREATE: "作成",
  EDIT: "編集",
};

export const BUTTON_EDIT_TEMPLATE_COMPLETE = {
  CREATE: "作成完了",
  EDIT: "編集完了",
};

export const EXCEL_TEMPLATE_INPUT = {
  TEXT: 0,
  MASTER: 1,
  DATE: 2,
  IMAGE: 3,
  IMAGE_PAGE: 4,
  REPORT_NO: 5,
  USER_NAME: 6,
  USER_SEAL: 7,
  APPROVED_SEAL: 8,
  APPROVED_DATE: 9,
  SIGNATURE: 10,
  DRAW_IMAGE: 11,
  CALC: 12,
  NUMBER: 13,
  RADIO: 14,
  TIME: 15,
  BARCODE: 16,
  CHECKBOX: 17,
  INPUT_GROUP: 18,
  MASTER_CUSTOM_ITEM: 1000,
} as const;

export const EXCEL_TEMPLATE_MASTER_LIST: { [key: string]: string } = {
  publisher: "発行元",
  occurrenceLocation: "フロア",
  spanMaster: "スパン",
  shipper: "分類",
  productStatus: "荷姿状態",
  accidentReason: "事故発生理由",
  importDivision: "重要度区分",
  accidentHandling: "事故処理",
} as const;

export const EXCEL_TEMPLATE_MASTER_NAME: { [key: string]: string } = {
  publisher: "publisher_name",
  occurrenceLocation: "occurrence_location_name",
  spanMaster: "span_name",
  shipper: "shipper_name",
  productStatus: "product_status_name",
  accidentReason: "accident_reason_name",
  importDivision: "import_division_name",
  accidentHandling: "accident_handling_name",
} as const;

export const CUSTOM_MASTER_NAME_ATTRIBUTE = "name";

export const EXCEL_TEMPLATE_FONT_COLOR = [
  { value: "black", text: "黒" },
  { value: "red", text: "赤" },
  { value: "green", text: "緑" },
  { value: "blue", text: "青" },
];

export const SYSTEM_KEY = {
  PAPER_SIZE: "PAPER_SIZE",
};

export const CUSTOM_FORMAT = "customize";
export const EXCEL_DATE_FORMAT = [
  { value: "AD", text: "西暦" },
  { value: "JP", text: "和暦" },
  { value: CUSTOM_FORMAT, text: "カスタマイズ" },
];

export const EXCEL_TIME_FORMAT = [
  { value: "HHmm", text: "24時間" },
  { value: CUSTOM_FORMAT, text: "カスタマイズ" },
];

export const EXCEL_ROUND_PLACE = [
  { value: 0, text: "なし" },
  { value: 1, text: "小数点第１位" },
  { value: 2, text: "小数点第２位" },
];

export const EXCEL_ROUND = [
  { value: "round", text: "四捨五入" },
  { value: "ceil", text: "切り上げ" },
  { value: "floor", text: "切り捨て" },
];

export const LIST_FUEL = [
  { SK: "FUEL_TYPE#1", fuel_name: "バッテリー" },
  { SK: "FUEL_TYPE#2", fuel_name: "LPG" },
];

export const DEVAN_SEARCH_TYPE = {
  RECEPTIONIST: "RECEPTIONIST",
  DEVANIST: "DEVANIST",
};

export const IMAGE_EXIST_TYPE: { [key: string]: string } = {
  not_designation: "not_designation",
  attached: "attached",
  not_attached: "not_attached",
} as const;
// <<--- TLOG

export const WRECKER_TYPE: { [key: string]: string } = {
  WRECKER_REQUEST_FOR: "要請依頼",
  WRECKER_CITY_NAME: "市町村名",
  WRECKER_CAR_NUMBER_PLACE: "ナンバー地名",
  WRECKER_TYPE_OF_WORK: "作業内容",
  WRECKER_DISPATCH_VEHICLE: "出動車両",
  WRECKER_TRANSPORT_WORK: "搬送作業",
  WRECKER_DRIVING_ON_SHOULDER: "路肩走行",
  WRECKER_HIGHWAY_TEAM: "高速隊",
  WRECKER_REQUEST_LINE: "ライン",
  WRECKER_TOWING_SHOP: "レッカー実施店",
  WRECKER_STORE_CAR: "車両預かり店",
};

export {
  COLOR_BACKGROUND_NAME_STATUS_WORK,
  COLOR_STATUS_WORK,
  LIST_WORK_STATUS_TLOG,
  COLOR_BACKGROUND_NAME_STATUS_WORK_TLOG,
  COLOR_STATUS_WORK_TLOG,
  TITLE_STATUS_WORK_TLOG,
  PHOTO_TYPE_ACCEPT,
  LIST_TYPE_FILTER_SCREEN,
  ALL_LIST_TYPE_FILTER,
  LIST_TYPE_PERIOD_FILTER,
  STATUS_WORK,
  STATUS_WORK_TLOG,
  STATUS_PRINTED,
  LIST_WORK_STATUS,
  TITLE_STATUS_WORK,
  TEXT_EMAIL_PAGE,
  MAX_LENGTH_TITLE,
  MAX_LENGTH_CONTENT,
  MAX_LENGTH_30,
  TEMPLATE_SORT_TYPE,
  TEMPLATE_TYPE,
  MAX_CAPACITY,
  LIST_TYPE_SORT_PROJECT,
  LIST_TYPE_SORT_WORK,
  LIST_TYPE_SORT_LEDGER,
  LIST_TYPE_SORT_IMAGE_LEDGER,
  LIST_TYPE_SORT_TEMPLATE,
  LIST_TYPE_SORT_FOLDER,
  LIST_TYPE_SORT_MEMBER_LIST,
  LIST_TYPE_SORT_CUSTOM_TEMPLATE,
  LIST_TYPE_SORT_CUSTOM_TEMPLATE_TRASH,
  LIST_TYPE_SORT_DEVAN_SCHEDULE,
  TEMPLATE_FILTER,
  FOLDER_KIND,
};
