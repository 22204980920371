import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Colors } from "@template/style";

interface IAccordionSection {
  title: React.ReactNode;
  children?: React.ReactNode;
}
const AccordionSection = ({ title, children }: IAccordionSection) => {
  return (
    <Accordion disableGutters sx={{ boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          bgcolor: Colors.MAIN_GREEN_LIGHT,
          ":focus-visible": { bgcolor: Colors.MAIN_GREEN_LIGHT },
        }}
      >
        <Typography variant="h6" sx={{ wordBreak: "break-all" }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
