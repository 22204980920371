import React, {
  VFC,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Layer, Line, Stage } from "react-konva";
import { Box, Stack } from "@mui/material";
import { Colors } from "@template/style";
import DrawMenu from "./DrawMenu";
import {
  ColorMode,
  DrawItem,
  DrawToolMode,
  StrokeWidthMode,
} from "services/models";
import DrawRectangle from "./DrawRectangle";
import _ from "lodash";
import DrawEllipse from "./DrawEllipse";
import DrawLine from "./DrawLine";
import DrawText from "./DrawText";
import TextEditDialog from "./TextEditDialog";
import DrawFreehand from "./DrawFreehand";
import { convertDataToSvg } from "@utils/draw";

interface IProps {
  uri: string;
  drawData?: DrawItem[];
  containerStyle?: React.CSSProperties;
}

const initialData = {
  line: { x: 10, y: 10, points: [0, 0, 100, 0] },
  text: { x: 0, y: 0 },
  rectangle: { x: 0, y: 0, width: 100, height: 100 },
  ellipse: { x: 50, y: 50, width: 100, height: 100 },
};

const DrawCore = ({ uri, drawData, containerStyle }: IProps, ref: any) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [showLayer, setShowLayer] = useState<boolean>(true);
  const [color, setColor] = useState<ColorMode>("red");
  const [strokeWidth, setStrokeWidth] = useState<StrokeWidthMode>("bold");
  const [drawTool, setDrawTool] = useState<DrawToolMode>("pen");
  const [drawItem, setDrawItem] = useState<DrawItem[]>([]);
  const [drawItemHistory, setDrawItemHistory] = useState<DrawItem[][]>([]);
  const [selectedItem, setSelectedItem] = useState<DrawItem>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [text, setText] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const stageRef = useRef<any>();
  const isNewText = useRef<boolean>(true);
  const isDrawing = useRef<boolean>(false);
  const imageRef = useRef<HTMLImageElement>();

  useImperativeHandle(ref, () => ({
    getImage: async () => {
      // var dataURL = stageRef.current?.toDataURL({ pixelRatio: 3 });
      // return dataURL;
      // SVGに変換
      const img = imageRef.current;
      return await convertDataToSvg(
        uri,
        drawItem,
        { width, height },
        {
          width: img?.naturalWidth ?? width,
          height: img?.naturalHeight ?? height,
        },
      );
    },
  }));

  useEffect(() => {
    setDrawItem([...(drawData ?? [])]);
    setDrawItemHistory([[...(drawData ?? [])]]);
  }, [drawData]);

  // ------------------------------------------------------------------
  // キャンバス
  // ------------------------------------------------------------------
  /**
   * 画像の表示サイズ取得
   * @param img
   */
  const getContainedSize = (img: HTMLImageElement) => {
    var r = img.naturalWidth / img.naturalHeight;
    var w = img.height * r;
    var h = img.height;
    if (w > img.width) {
      w = img.width;
      h = img.width / r;
    }
    return [w, h];
  };

  /**
   * キャンバスサイズを設定
   * @param img
   */
  const setCanvasSize = (img: HTMLImageElement) => {
    const [w, h] = getContainedSize(img);
    setWidth(w);
    setHeight(h);
  };

  //　ウィンドウサイズ変更時、キャンバスをリサイズ
  useLayoutEffect(() => {
    const handleWindowResize = () => {
      if (!imageRef.current) return;
      setCanvasSize(imageRef.current);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // ------------------------------------------------------------------
  // フリーハンド描画
  // ------------------------------------------------------------------
  const handleMouseDown = (e: any) => {
    if (drawTool != "pen") return;
    isDrawing.current = true; //描画開始
    // 線を追加
    const pos = e.target.getStage().getPointerPosition();
    addDrawItem("pen", { points: [pos.x, pos.y] });
  };

  const handleMouseMove = (e: any) => {
    if (drawTool != "pen") return;
    if (!isDrawing.current) {
      return;
    }
    // 描画中の線にポイント追加
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = drawItem[drawItem.length - 1];
    lastLine.data.points = lastLine.data.points.concat([point.x, point.y]);

    // 描画中の線を置換
    drawItem.splice(drawItem.length - 1, 1, lastLine);
    setDrawItem(drawItem.concat());
  };

  const handleMouseUp = () => {
    if (drawTool != "pen") return;
    isDrawing.current = false; //描画終了
    // クリックのみの描画は削除
    if (drawItem[drawItem.length - 1].data.points.length == 2) {
      let new_draw_item = [...drawItem];
      let new_history = [...drawItemHistory];
      new_draw_item.pop();
      new_history.pop();
      setDrawItem(new_draw_item);
      setDrawItemHistory(new_history);
    }
  };

  // ------------------------------------------------------------------
  // 図形
  // ------------------------------------------------------------------
  /**
   * 図形追加
   * @param draw_tool
   */
  const addDrawItem = (draw_tool: DrawToolMode, data?: any, text?: string) => {
    let add_item: any = {
      type: draw_tool,
      data: data ? data : null,
      colorMode: color,
      strokeWidthMode: strokeWidth,
    };
    if (text) {
      add_item["text"] = text;
    }
    if (draw_tool !== "pen") {
      setSelectedIndex(drawItem.length);
      setSelectedItem(add_item);
    }
    let new_draw_item = [...drawItem];
    new_draw_item.push(add_item);
    setDrawItem(new_draw_item);
    addHistory(new_draw_item);
  };

  /**
   * 図形削除
   */
  const deleteDrawItem = () => {
    if (selectedIndex !== undefined) {
      let new_draw_item = [...drawItem];
      new_draw_item.splice(selectedIndex, 1);
      setDrawItem(new_draw_item);
      setSelectedIndex(undefined);
      setSelectedItem(undefined);
      addHistory(new_draw_item);
    }
  };

  /**
   * 図形更新
   * @param value
   */
  const updateDrawItem = (value: DrawItem) => {
    if (selectedIndex !== undefined) {
      setSelectedItem({ ...value });
      let new_draw_item = [...drawItem];
      new_draw_item[selectedIndex] = { ...value };
      setDrawItem(new_draw_item);
      addHistory(new_draw_item);
    }
  };

  /**
   * 図形移動・変形
   * @param value
   */
  const handleChangeDrawItem = (value: any) => {
    if (selectedItem === undefined) return;
    updateDrawItem({ ...selectedItem, data: value });
  };

  /**
   * 図形選択
   * @param value
   * @param index
   */
  const handleClickDrawItem = (value: DrawItem, index: number) => {
    if (index == selectedIndex) {
      // 図形選択解除
      setSelectedIndex(undefined);
      setSelectedItem(undefined);
    } else {
      // 図形選択
      setSelectedIndex(index);
      setSelectedItem(value);
      setColor(value.colorMode);
      setStrokeWidth(value.strokeWidthMode);
    }
  };

  /**
   * テキスト変更
   * @param value
   */
  const handleTextDblClick = (value: string) => {
    isNewText.current = false;
    setText(value);
    setOpen(true);
  };

  /**
   * テキスト編集ダイアログ
   * @param value
   */
  const handleChangeText = (value: string) => {
    if (isNewText.current) {
      if (value.length > 0) {
        // テキスト追加
        addDrawItem("text", initialData["text"], value);
      }
    } else {
      if (value.length > 0 && selectedItem?.type === "text") {
        // テキスト変更
        updateDrawItem({ ...selectedItem, text: value });
      } else {
        // テキスト削除
        deleteDrawItem();
      }
    }
  };

  /**
   * 描画内容を履歴に追加
   */
  const addHistory = (value: DrawItem[]) => {
    const new_history = [...drawItemHistory];
    new_history.push(value);
    setDrawItemHistory(new_history);
  };

  // ------------------------------------------------------------------
  // 描画メニュー
  // ------------------------------------------------------------------
  const handleClickColor = (value: ColorMode) => {
    setColor(value);
    if (selectedItem) {
      // 選択図形の色を変更
      updateDrawItem({ ...selectedItem, colorMode: value });
    }
  };

  const handleClickStrokeWidth = (value: StrokeWidthMode) => {
    setStrokeWidth(value);
    if (selectedItem) {
      // 選択図形の線の太さを変更
      updateDrawItem({ ...selectedItem, strokeWidthMode: value });
    }
  };

  const handleClickDrawTool = (value: DrawToolMode) => {
    if (value == "eraser") {
      deleteDrawItem();
    } else {
      setDrawTool(value);
      if (value == "pen") {
        return;
      } else if (value == "text") {
        // テキスト編集ダイアログを開く
        isNewText.current = true;
        setText("");
        setOpen(true);
      } else {
        // 図形追加
        addDrawItem(value, initialData[value]);
      }
    }
  };

  const handleUndo = () => {
    if (drawItemHistory.length <= 1) return;
    const newDrawItemHistory = [...drawItemHistory];
    newDrawItemHistory.pop();
    const undoDrawItem = newDrawItemHistory[newDrawItemHistory.length - 1];
    setDrawItem([...undoDrawItem]);
    setDrawItemHistory([...newDrawItemHistory]);
  };

  return (
    <>
      <TextEditDialog
        open={open}
        text={text}
        onChange={handleChangeText}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Stack direction={"row"}>
        <div style={{ ...styles.container, ...containerStyle }}>
          <Box
            ref={imageRef}
            component="img"
            src={uri}
            sx={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            onLoad={(e) => {
              setCanvasSize(e.currentTarget);
            }}
          />
          <Stage
            ref={stageRef}
            width={width}
            height={height}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%)`,
            }}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
          >
            <Layer visible={showLayer}>
              {drawItem.map((value, index) => {
                switch (value.type) {
                  case "pen":
                    return (
                      <DrawFreehand
                        data={value}
                        selected={index == selectedIndex}
                        onClick={() => handleClickDrawItem(value, index)}
                        onChange={handleChangeDrawItem}
                        key={index}
                      />
                    );
                  case "line":
                    return (
                      <DrawLine
                        data={value}
                        selected={index == selectedIndex}
                        onClick={() => handleClickDrawItem(value, index)}
                        onChange={handleChangeDrawItem}
                        key={index}
                      />
                    );
                  case "text":
                    return (
                      <DrawText
                        data={value}
                        selected={index == selectedIndex}
                        onClick={() => handleClickDrawItem(value, index)}
                        onChange={handleChangeDrawItem}
                        onDblClick={() => handleTextDblClick(value.text ?? "")}
                        key={index}
                      />
                    );
                  case "rectangle":
                    return (
                      <DrawRectangle
                        data={value}
                        selected={index == selectedIndex}
                        onClick={() => handleClickDrawItem(value, index)}
                        onChange={handleChangeDrawItem}
                        key={index}
                      />
                    );
                  case "ellipse":
                    return (
                      <DrawEllipse
                        data={value}
                        selected={index == selectedIndex}
                        onClick={() => handleClickDrawItem(value, index)}
                        onChange={handleChangeDrawItem}
                        key={index}
                      />
                    );
                }
              })}
            </Layer>
          </Stage>
        </div>
        <DrawMenu
          color={color}
          onClickColor={handleClickColor}
          strokeWidth={strokeWidth}
          onClickStrokeWidth={handleClickStrokeWidth}
          drawTool={drawTool}
          onClickDrawTool={handleClickDrawTool}
          showLayer={showLayer}
          onClickLayer={() => setShowLayer(!showLayer)}
          onClickUndo={handleUndo}
          disabledUndo={drawItemHistory.length <= 1}
        />
      </Stack>
    </>
  );
};

export default forwardRef(DrawCore);

const styles = {
  container: {
    position: "relative",
    height: "80vh",
    width: "50vw",
    border: 1,
    borderWidth: 1,
    borderColor: Colors.BORDER,
    backgroundColor: Colors.BORDER,
  },
} as const;
