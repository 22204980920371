import React, {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import { Box, Tab, Tabs } from "@mui/material";
import _, { cloneDeep, debounce } from "lodash";
import TabPanel from "components/atoms/TabPanel";
import formatDateToString from "@utils/DateFormat";
import {
  StateFormType,
  getInfoFromTemplate,
  insertReportLocal,
  makeNewHtml,
} from "@utils/template/yuasa";
import { getBase64Image, getUserInfo } from "@utils/index";
import { uploadImageToS3 } from "@utils/template";
import { TYPES } from "store/types";
import { getSignedUrlFile } from "@api/template";
import { getSystemByPK } from "@api/system";
import { Validation, isHalfWidthCharacter } from "@validation";
import ReportTab from "./ReportTab";
import RequestTab from "./RequestTab";

export const vehicleImagePath =
  "templates/thumbnails/vehicle_appearance.jpg" as const;

const originImage: Type.ImageInfoType = {
  uri: "",
  name: "",
  type: "",
};

const originData: StateFormType = {
  weather: null,
  receptionist: "",
  request: null,
  request_other: "",
  customer_name: "",
  reception_date: formatDateToString(new Date(), "YMD"),
  reception_time: "",
  work_content: null,
  work_detail_reception: "",
  location: null,
  location_other: "",
  customer_number: "",
  registration_number_place: null,
  registration_number: "",
  car_name: "",
  line: null,
  reception: "",
  arrival_time_scene: "",
  dispatcher_name: "",
  anshin_call: null,
  forwarding_distance: "",
  transportation_distance: "",
  secondary_transportation: "",
  dispatch_vehicle: null,
  departure_time: "",
  arrival_time: "",
  completion_time: "",
  transport_end_time: "",
  matter_end_time: "",
  secondary_dispatcher_name: "",
  secondary_dispatch_vehicle: null,
  secondary_departure_time: "",
  secondary_arrival_time: "",
  secondary_transport_end_time: "",
  secondary_matter_end_time: "",
  secondary_date: "",
  vehicle_appearance: {
    ...originImage,
  },
  images: [],
  customer_signature: { ...originImage },
  signer_attribute: null,
  is_check_vehicle_appearance: false,
  is_all_four_wheels: false,
  is_presence_of_key: false,
  is_lost_item: false,
  is_BT_removed: false,
  is_name_card: false,
  work_detail_scene: "",
  go_fee: "",
  forward_fee: [],
  transport_fee: [],
  return_fee: [],
  before_starting_BJ: "",
  after_starting_BJ: "",
  refueling_fee_type: null,
  refueling_fee: "",
  oil_type: null,
  oil_volume: "",
  running_off_shoulder: null,
  high_speed_troop: null,
  storage_location: "",
  transport_destination: null,
  address: "",
  responsible_person: "",
  destination_signature: { ...originImage },
  date_sign_destination_signature: "",
  towing_shop: null,
  tow_implementation: "",
  vehicle_custody_store: null,
  transportation_work: [],
  sorbent_mass: "",
  destination_number: "",
  scene_confirmation: "",
};

const LIST_DATE_FIELD = {
  reception_date: "受付日",
  secondary_date: "二次日時",
} as const;

const LIST_TIME_FIELD = {
  reception_time: "受付時間",
  departure_time: "出発時間",
  arrival_time: "到着時間",
  completion_time: "完了時間",
  transport_end_time: "搬送終了時間",
  matter_end_time: "案件終了時間",
  secondary_departure_time: "出発時間",
  secondary_arrival_time: "到着時間",
  secondary_transport_end_time: "搬送終了時間",
  secondary_matter_end_time: "案件終了時間",
} as const;

const LIST_NUM_FIELD = {
  forwarding_distance: "回送距離",
  transportation_distance: "輸送距離",
  secondary_transportation: "二次搬送",
  sorbent_mass: "吸着剤",
  before_starting_BJ: "BJ始動前",
  after_starting_BJ: "BJ始動後",
  refueling_fee: "給油料金",
  oil_volume: "給油量",
} as const;

interface IformError {
  [key: string]: string;
}

interface ITemplateYuasa {
  infoToEdit?: any;
  htmlString: string;
  setHtmlString: React.Dispatch<React.SetStateAction<string>>;
  isManage?: boolean;
  open: boolean;
}

const TemplateYuasa = (
  {
    infoToEdit,
    htmlString,
    setHtmlString,
    isManage = false,
    open,
  }: ITemplateYuasa,
  ref: React.Ref<any>,
) => {
  const [tab, setTab] = React.useState(0);
  const [stateForm, setStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [originStateForm, setOriginStateForm] = useState<StateFormType>(
    cloneDeep(originData),
  );
  const [formError, setFormError] = useState<IformError>({
    reception_date: "",
    reception_time: "",
    departure_time: "",
    arrival_time: "",
    completion_time: "",
    transport_end_time: "",
    matter_end_time: "",
    secondary_departure_time: "",
    secondary_arrival_time: "",
    secondary_transport_end_time: "",
    secondary_matter_end_time: "",
    secondary_date: "",
    forwarding_distance: "",
    transportation_distance: "",
    secondary_transportation: "",
    sorbent_mass: "",
    before_starting_BJ: "",
    after_starting_BJ: "",
    refueling_fee: "",
    oil_volume: "",
  });
  const imagePathRef = React.useRef<{ [uri: string]: string }>({});
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    getStateForm: () => stateForm,
    getOriginStateForm: () => originStateForm,
    onSubmit: onSubmit,
    getHtmlString: async () => {
      return await getHtmlString(stateForm, htmlString, false);
    },
    disabledPreview: () => Object.values(formError).some((v) => v != ""),
    disabledSubmit: () => Object.values(formError).some((v) => v != ""),
  }));

  const getInterLimit = async () => {
    try {
      const valueConfig = await getSystemByPK("INTER_LIMIT");
      if (valueConfig?.data) {
        dispatch({
          type: TYPES.SET_INTER_LIMIT,
          payload: { data: { inter_limit: valueConfig.data[0].value } },
        });
      }
    } catch (err) {
      throw Error("Fail to get inter limit");
    }
  };

  const downloadImage = async () => {
    try {
      const res = await getSignedUrlFile(vehicleImagePath);
      let signedUri = "";
      if (res?.link_url) {
        signedUri = res.link_url;
      }
      const vehicle_appearance = {
        uri: signedUri,
        name: "vehicle_appearance.jpg",
        type: "image/jpg",
        path_file: vehicleImagePath,
        non_Local: true,
      };
      onChangeText("vehicle_appearance")(vehicle_appearance);
      onChangeTextOrigin("vehicle_appearance")(vehicle_appearance);
    } catch (err) {
      throw Error("Fail to get vehicle appearance image");
    }
  };

  useEffect(() => {
    if (open) {
      getInterLimit();
      if (typeof infoToEdit?.customer_number === "string") {
        setStateForm(infoToEdit);
        setOriginStateForm(infoToEdit);
      } else {
        const userInfo = getUserInfo();
        setStateForm({
          ...stateForm,
          dispatcher_name: userInfo?.full_name,
        });
        setOriginStateForm({
          ...originStateForm,
          dispatcher_name: userInfo?.full_name,
        });
        downloadImage();
      }
    }
  }, [open, infoToEdit]);

  // HTML取得 =====================================
  const getHtmlString = useCallback(
    async (
      state_form: StateFormType,
      html_String: string,
      use_base64: boolean = true,
    ) => {
      const getBase64String = async (imageUrl: string) => {
        if (imagePathRef.current && imagePathRef.current[imageUrl]) {
          return imagePathRef.current[imageUrl];
        } else {
          const base64 = await getBase64Image(imageUrl);
          if (base64) {
            imagePathRef.current[imageUrl] = base64;
            return base64;
          } else {
            return "";
          }
        }
      };
      setHtmlString(""); // エラーメッセージの変更時に再描画する為、htmlを変更
      let clone_state_form = _.cloneDeep(state_form);
      if (use_base64) {
        // uriを変換する
        const {
          images,
          vehicle_appearance,
          customer_signature,
          destination_signature,
        } = clone_state_form;
        if (images) {
          for (let index = 0; index < images.length; index++) {
            const item = images[index];
            const uri = item?.uri_jpg ?? item?.uri;
            images[index].uri_jpg = await getBase64String(uri);
          }
        }
        if (vehicle_appearance) {
          const uri = vehicle_appearance?.uri_jpg ?? vehicle_appearance?.uri;
          vehicle_appearance.uri_jpg = await getBase64String(uri);
        }
        if (customer_signature) {
          const uri = customer_signature?.uri_jpg ?? customer_signature?.uri;
          customer_signature.uri_jpg = await getBase64String(uri);
        }
        if (destination_signature) {
          const uri =
            destination_signature?.uri_jpg ?? destination_signature?.uri;
          destination_signature.uri_jpg = await getBase64String(uri);
        }
      }
      let newHtml: string = makeNewHtml(html_String, clone_state_form);

      newHtml = await insertReportLocal(
        newHtml,
        clone_state_form,
        getInfoFromTemplate(html_String),
      );
      setHtmlString(newHtml);
      return newHtml;
    },
    [],
  );

  const changeForm = useCallback(debounce(getHtmlString, 300), [getHtmlString]);

  useEffect(() => {
    changeForm(stateForm, htmlString);
  }, [stateForm, htmlString, formError]);

  // 入力制御 =====================================
  const onChangeText =
    (field: keyof StateFormType, checkHalfWidth?: boolean) =>
    (newText: any) => {
      if (Object.keys(LIST_DATE_FIELD).includes(field)) {
        // 日付チェック
        const mess = validator(field, newText);
        setFormError((prev) => ({ ...prev, [field]: mess }));
        if (mess.length == 0) {
          newText = formatDateToString(newText, "YMD");
        } else {
          newText = "";
        }
      }
      if (Object.keys(LIST_TIME_FIELD).includes(field)) {
        // 時刻チェック
        newText = formatDateToString(newText, "HHmm");
        const mess = validator(field, newText);
        setFormError((prev) => ({ ...prev, [field]: mess }));
        if (mess.length > 0) {
          newText = "";
        }
      }
      if (Object.keys(LIST_NUM_FIELD).includes(field)) {
        // 数値チェック
        const mess = validator(field, newText);
        setFormError((prev) => ({ ...prev, [field]: mess }));
      }
      if (checkHalfWidth && typeof newText === "string") {
        let result = "";

        for (const character of newText) {
          if (isHalfWidthCharacter(character)) {
            result += character;
          }
        }

        setStateForm((prev) => {
          return { ...prev, [field]: result };
        });
      } else {
        setStateForm((prev) => {
          return { ...prev, [field]: newText };
        });
      }
    };

  const onChangeTextOrigin = (field: keyof StateFormType) => (newText: any) => {
    setOriginStateForm((prev) => {
      return { ...prev, [field]: newText };
    });
  };

  const validator = (field: keyof StateFormType, value: string) => {
    let mess: string = "";

    switch (field) {
      case "reception_date":
      case "secondary_date":
        mess = Validation.validateDate(value, LIST_DATE_FIELD[field], false);
        break;
      case "reception_time":
      case "departure_time":
      case "arrival_time":
      case "completion_time":
      case "transport_end_time":
      case "matter_end_time":
      case "secondary_departure_time":
      case "secondary_arrival_time":
      case "secondary_transport_end_time":
      case "secondary_matter_end_time":
        mess = Validation.validate({
          type: "time",
          name: LIST_TIME_FIELD[field],
          value: value,
        });
        break;
      case "forwarding_distance":
      case "transportation_distance":
      case "secondary_transportation":
      case "sorbent_mass":
      case "before_starting_BJ":
      case "after_starting_BJ":
      case "refueling_fee":
      case "oil_volume":
        mess = Validation.validate({
          type: "number",
          name: LIST_NUM_FIELD[field],
          value: value,
        });
        break;
    }
    return mess;
  };

  // 保存 =====================================
  const onSubmit = async () => {
    let newStateForm = { ...stateForm };
    // 画像アップロード
    let newImageArray: Array<Type.ImageInfoType> = stateForm.images ?? [];
    if (stateForm.images) {
      newImageArray = await uploadImageToS3(stateForm.images);
    }
    newStateForm = {
      ...newStateForm,
      images: newImageArray,
    };
    if (stateForm.vehicle_appearance) {
      const new_vehicle_appearance = await uploadImageToS3(
        [stateForm.vehicle_appearance],
        Date.now(),
      );
      newStateForm = {
        ...newStateForm,
        vehicle_appearance: new_vehicle_appearance[0],
      };
    }
    if (stateForm.customer_signature) {
      const new_customer_signature = await uploadImageToS3(
        [stateForm.customer_signature],
        Date.now(),
      );
      newStateForm = {
        ...newStateForm,
        customer_signature: new_customer_signature[0],
      };
    }
    if (stateForm.destination_signature) {
      const new_destination_signature = await uploadImageToS3(
        [stateForm.destination_signature],
        Date.now(),
      );
      newStateForm = {
        ...newStateForm,
        destination_signature: new_destination_signature[0],
      };
    }
    await setStateForm(newStateForm);
  };

  return !isManage ? (
    <RequestTab
      stateForm={stateForm}
      onChangeText={onChangeText}
      formError={formError}
    />
  ) : (
    <Box>
      <Tabs
        value={tab}
        onChange={(e, newValue: number) => {
          setTab(newValue);
        }}
        textColor="inherit"
        variant="fullWidth"
        sx={{ m: 0 }}
      >
        <Tab label="車両状況報告" />
        <Tab label="出動依頼" />
      </Tabs>
      <Box pt={0.5}>
        {/* 車両状況報告 */}
        <TabPanel value={tab} index={0}>
          <ReportTab
            stateForm={stateForm}
            onChangeText={onChangeText}
            formError={formError}
          />
        </TabPanel>
        {/* 出動依頼 */}
        <TabPanel value={tab} index={1}>
          <RequestTab
            stateForm={stateForm}
            onChangeText={onChangeText}
            formError={formError}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default forwardRef(TemplateYuasa);
