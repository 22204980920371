import { getAllReportItem, getAllReportItemUpdatedAt } from "@api/itemReport";
import { AllReportItem } from "services/models";
import { cloneDeep } from "lodash";
import { STORAGE, getDataStorage, storeData } from "@utils/Storage";

export function* setSettingReportItemData(action: any): any {
  /*
 庫内商品事故報告書のマスタデータ取得
  */
  try {
    const { activity_base_id } = action.payload;
    const resReportItem = yield getNewReportItem(activity_base_id);
    storeData(STORAGE.ALL_REPORT_ITEM, JSON.stringify(resReportItem));
  } catch (error) {
    console.log("function*setSettingReportItemData ~ error", error);
  }
}

export async function getNewReportItem(activity_base_id: string): Promise<any> {
  // マスタ更新日時取得
  const resReportItemUpdatedAt = await getAllReportItemUpdatedAt(
    activity_base_id,
  );

  // キャッシュのマスタデータ取得
  let storeReportItem: AllReportItem = JSON.parse(
    getDataStorage(STORAGE.ALL_REPORT_ITEM, "{}"),
  );
  if (!storeReportItem) {
    // マスタデータ未取得時は初期化する
    storeReportItem = {
      publisher: [],
      occurrenceLocation: [],
      spanMaster: [],
      shipper: [],
      productStatus: [],
      accidentReason: [],
      importDivision: [],
      accidentHandling: [],
    };
  }

  // マスタ再取得対象
  let listGetReportItem: Array<any> = [];

  Object.keys(resReportItemUpdatedAt).forEach((value: any) => {
    const key: keyof AllReportItem = value;

    let listStore = cloneDeep(storeReportItem[key]);
    if (listStore && listStore.length > 0) {
      if (resReportItemUpdatedAt[key] !== "") {
        listStore.sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1));
        if (resReportItemUpdatedAt[key] > listStore[0].updated_at) {
          // マスタデータ更新時
          listGetReportItem.push(key.toString());
        }
      }
    } else {
      // マスタデータ未取得時
      listGetReportItem.push(key.toString());
    }
  });

  // マスタ再取得
  if (listGetReportItem.length > 0) {
    // クエリ文字数が最大桁数を超えないように分割
    const listGetReportItemChunk: any[][] = [];
    let i = 0,
      j = listGetReportItem.length,
      size = 0;
    while (i < j) {
      size++;
      if (
        listGetReportItem.slice(i, i + size).join("").length > 1500 ||
        i + size >= j
      ) {
        listGetReportItemChunk.push(listGetReportItem.slice(i, i + size));
        i += size;
        size = 0;
      }
    }

    for (const report of listGetReportItemChunk) {
      const resReportItem = await getAllReportItem(activity_base_id, {
        master: JSON.stringify(report),
      });

      for (const value of report) {
        const key: keyof AllReportItem = value;
        storeReportItem[key] = resReportItem[key];
      }
    }
  }

  return storeReportItem;
}
