import { useEffect, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
  Typography,
} from "@mui/material";
import { AddCircle, Delete, Edit } from "@mui/icons-material";
import GroupLocationEditDialog from "./ActivityBaseEditDialog";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import messages from "config/messages";
import {
  createActivityBase,
  deleteActivityBase,
  getActivityBaseGroupName,
  getListActivityBase,
  updateActivityBase,
} from "@api/groupMaster";
import { ActivityBase } from "services/models";

interface IData extends ActivityBase {
  group_name?: "";
}

const initial_data = {
  activity_base_name: "",
  activity_base_code: "",
  group_sk: "",
  SK: "",
  group_name: "",
};

const GroupActivityBaseScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<IData[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<IData>();
  const [editData, setEditData] = useState(initial_data);
  const [searchedKeyword, setSearchKeyword] = useState<string>("");

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (keyword?: string) => {
    LoadingOverlayController.show();
    await getListActivityBase(keyword)
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .finally(() => LoadingOverlayController.hide());
  };

  // ------------------------------------------------------------------
  // 追加・更新
  // ------------------------------------------------------------------
  const handleAdd = () => {
    setEditData(initial_data);
    setOpen(true);
  };

  const handleEdit = async (d: IData) => {
    if (!d.group_name) {
      LoadingOverlayController.show();
      await getActivityBaseGroupName(d.group_sk)
        .then((res) => (d.group_name = res))
        .finally(() => {
          LoadingOverlayController.hide();
        });
    }

    setEditData({
      activity_base_code: d.activity_base_code,
      activity_base_name: d.activity_base_name,
      group_sk: d.group_sk,
      SK: d.SK,
      group_name: d.group_name ?? "",
    });
    setSelectedData(d);
    setOpen(true);
  };

  const handleChange = async (value: IData) => {
    try {
      LoadingOverlayController.show();
      let res = null;
      let message = "";

      if (!selectedData) {
        const data = {
          activity_base_code: value.activity_base_code,
          activity_base_name: value.activity_base_name,
          group_sk: value.group_sk,
        };
        res = await createActivityBase(data);
        message = "拠点の作成";
      } else {
        const data = {
          PK: selectedData.PK,
          SK: selectedData.SK,
          activity_base_code: value.activity_base_code,
          activity_base_name: value.activity_base_name,
          group_sk: value.group_sk,
        };
        res = await updateActivityBase(data);
        message = "拠点の更新";
      }

      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001(message),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleChange", error);
    } finally {
      LoadingOverlayController.hide();
      setSelectedData(undefined);
    }
  };

  // ------------------------------------------------------------------
  // 削除
  // ------------------------------------------------------------------
  const handleDelete = (d: IData) => {
    ModalController.show({
      message: messages.COMMON.MSG_COMMON_DELETE_CONFIRM_001,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton2: async () => {
        await handleDeleteConfirm(JSON.parse(JSON.stringify(d)));
      },
    });
  };

  const handleDeleteConfirm = async (d: IData) => {
    try {
      LoadingOverlayController.show();
      let data = {
        PK: d.PK,
        SK: d.SK,
        group_sk: d.group_sk,
      };
      const res = await deleteActivityBase(data);
      if (res) {
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_DELETE_SUCCESS_001(
            d.activity_base_name,
          ),
          visibleButton2: true,
          handlePressButton2: () => {
            fetchData();
          },
        });
      }
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleDeleteConfirm", error);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleSearch = (text: string) => {
    setSearchKeyword(text);
    fetchData(text);
  };

  return (
    <GenericTemplate
      title="拠点管理"
      searchPlaceholder="拠点名の検索"
      onSearch={(v) => handleSearch(v)}
      isSearch
      onSearchClear={() => handleSearch("")}
    >
      <GroupLocationEditDialog
        data={editData}
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedData(undefined);
        }}
        onChange={handleChange}
      />

      <Box sx={{ textAlign: "right" }}>
        <Button onClick={handleAdd} endIcon={<AddCircle />}>
          追加
        </Button>
      </Box>

      {data && data.length > 0 ? (
        <>
          <Box display="flex" flexDirection="row" sx={{ px: 2 }}>
            <Box width={100}>
              <Typography fontSize={14}>拠点コード</Typography>
            </Box>
            <Box>
              <Typography fontSize={14}>拠点名</Typography>
            </Box>
          </Box>
          <List>
            {data.map((item: any) => (
              <ListItem
                sx={{ bgcolor: "white", pr: 12 }}
                secondaryAction={
                  <>
                    <Tooltip title="編集">
                      <IconButton
                        edge="end"
                        sx={{ mx: 1 }}
                        onClick={() => handleEdit(item)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="削除">
                      <IconButton edge="end" onClick={() => handleDelete(item)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                }
                divider
                key={item.SK}
              >
                <span className="IgnoreExtractRuleTarget">
                  <ListItemText
                    primary={item.activity_base_code}
                    primaryTypographyProps={{
                      style: styles.primaryTypographyProps,
                    }}
                    sx={{ minWidth: 100, maxWidth: 100 }}
                  />
                </span>
                <span className="IgnoreExtractRuleTarget">
                  <ListItemText
                    primary={item.activity_base_name}
                    primaryTypographyProps={{
                      style: styles.primaryTypographyProps,
                    }}
                  />
                </span>
              </ListItem>
            ))}
          </List>
        </>
      ) : searchedKeyword === "" ? (
        messages.COMMON.MSG_NOT_EXIST("拠点")
      ) : (
        messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)
      )}
    </GenericTemplate>
  );
};

export default GroupActivityBaseScreen;

const styles = {
  primaryTypographyProps: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
} as const;
