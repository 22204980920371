import { METHOD_API, createConnector, prefixApi } from "./Connector";
import { User } from "services/models";
interface sortData {
  PK: String;
  SK: String;
  sort: Number;
  user: User;
}

export const getListPosition = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/get-list-position`, null);
};

export const updatePositionSort = (dataBody: sortData[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/position/sort`,
    dataBody,
  );
};

export const deletePosition = (position_id: string) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/position/${encodeURIComponent(position_id)}`,
    null,
  );
};
