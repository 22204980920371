import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { Component } from "react";

type TVariant = "text" | "outlined" | "contained";
type TColor =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
type ButtonConfig = {
  title?: string;
  variant?: TVariant;
  color?: TColor;
};

export type ModalControllerConfig = {
  // titleModal?: string;
  // titleStyle?: TextStyle;
  message?: string;
  // messageStyle?: TextStyle;
  attention?: string;
  // attentionStyle?: TextStyle;
  addContents?: () => React.ReactNode | JSX.Element;
  disableFeedback?: boolean;
  handleFeedback?: Function;
  handleClose?: Function;
  // isBottom?: boolean;
  //left button
  visibleButton1?: boolean;
  disableButton1?: boolean;
  button1?: ButtonConfig;
  handlePressButton1?: Function;
  //right button
  visibleButton2?: boolean;
  disableButton2?: boolean;
  button2?: ButtonConfig;
  handlePressButton2?: Function;
};

class ModalController extends Component<any, any> {
  state: {
    visible: boolean;
    config: ModalControllerConfig;
  } = {
    visible: false,
    config: {},
  };

  shouldComponentUpdate(nextProps: any, nextState: any, nextContext: any) {
    return (
      this.state.visible !== nextState.visible ||
      this.state.config.disableButton1 !== nextState.config.disableButton1 ||
      this.state.config.disableButton2 !== nextState.config.disableButton2
    );
  }

  static overlayInstance: ModalController;

  static isShowing() {
    return (
      ModalController.overlayInstance &&
      ModalController.overlayInstance.state.visible
    );
  }

  static setRef(ref: any) {
    ModalController.overlayInstance = ref;
  }

  static show(config?: ModalControllerConfig) {
    ModalController.overlayInstance._hide();
    if (!config) config = {};
    ModalController.overlayInstance._show(config);
  }

  static hide() {
    ModalController.overlayInstance._hide();
  }

  static setDisableButton1(value: boolean) {
    ModalController.overlayInstance._setDisableButton1(value);
  }

  static setDisableButton2(value: boolean) {
    ModalController.overlayInstance._setDisableButton2(value);
  }

  _show(config: ModalControllerConfig) {
    if (config.message === undefined) return;
    this.setState({ visible: true, config });
  }

  _hide() {
    typeof this.state.config.handleClose === "function" &&
      this.state.config.handleClose();
    this.setState({ visible: false, config: {} });
  }

  _setDisableButton1(value: boolean) {
    this.setState({
      ...this.state,
      config: { ...this.state.config, disableButton1: value },
    });
  }

  _setDisableButton2(value: boolean) {
    this.setState({
      ...this.state,
      config: { ...this.state.config, disableButton2: value },
    });
  }

  _textAddBreak(text: string | undefined, color?: string) {
    if (!text) return undefined;
    const text_array = text.split(/<br>|\n/g);
    return text_array.map((item, i) => {
      return (
        <Typography key={i} color={color} sx={{ wordBreak: "break-all" }}>
          {item}
        </Typography>
      );
    });
  }

  constructor(props: any) {
    super(props);
    this._show = this._show.bind(this);
    this._hide = this._hide.bind(this);
    this._setDisableButton1 = this._setDisableButton1.bind(this);
    this._setDisableButton2 = this._setDisableButton2.bind(this);
  }

  render() {
    const { visible, config } = this.state;
    const {
      message,
      attention,
      addContents,
      disableFeedback,
      handleFeedback,
      //left button
      visibleButton1,
      disableButton1,
      button1,
      handlePressButton1,
      //right button
      visibleButton2,
      disableButton2,
      button2,
      handlePressButton2,
    } = config;

    return (
      <Dialog
        maxWidth="xs"
        sx={{ "& .MuiDialog-paper": { width: "80%" } }}
        aria-labelledby="simple-dialog-title"
        open={visible}
        onClose={
          disableFeedback
            ? () => {
                typeof handleFeedback === "function" && handleFeedback();
                this._hide();
              }
            : undefined
        }
      >
        <DialogContent>
          <Box
            id="alert-dialog-description"
            display={message ? undefined : "none"}
          >
            {this._textAddBreak(message)}
          </Box>
          <Box
            id="alert-dialog-attention"
            display={attention ? undefined : "none"}
            sx={{ pt: 1 }}
          >
            {this._textAddBreak(attention, "error")}
          </Box>
          {addContents && addContents()}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          {visibleButton1 && (
            <Button
              onClick={() => {
                typeof handlePressButton1 === "function" &&
                  handlePressButton1();
                this._hide();
              }}
              color={button1?.color ? button1.color : "primary"}
              variant={button1?.variant ? button1.variant : "outlined"}
              disabled={disableButton1}
            >
              {button1?.title ? button1.title : "キャンセル"}
            </Button>
          )}
          {visibleButton2 && (
            <Button
              onClick={() => {
                typeof handlePressButton2 === "function" &&
                  handlePressButton2();
                this._hide();
              }}
              color={button2?.color ? button2.color : "primary"}
              variant={button2?.variant ? button2.variant : "contained"}
              disabled={disableButton2}
            >
              {button2?.title ? button2.title : "OK"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ModalController;
