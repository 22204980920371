import React from "react";
import FullScreenDialog from "components/atoms/FullScreenDialog";
import ListApproveHistory from "./ListApproveHistory";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

interface IProps {
  open: boolean;
  setOpen: Function;
  work_id: string;
}

const ModalApproveHistory = ({ open, setOpen, work_id = "" }: IProps) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <FullScreenDialog open={open} onClose={handleClose} title={"承認履歴"}>
      <ListApproveHistory work_id={work_id} />
      <Box sx={{ height: 40 }} />
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button onClick={handleClose}>戻る</Button>
      </Box>
    </FullScreenDialog>
  );
};

export default ModalApproveHistory;
