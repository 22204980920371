import { useState } from "react";
import { Box, IconButton, InputBase } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    marginRight: theme.spacing(0),
    width: "50%",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "21ch",
      },
    },
  },
}));

interface SearchProps {
  placeholder?: string;
  onSearch?: (v: string) => void;
  onClear?: () => void;
}

const SearchCustom = ({
  placeholder = "",
  onSearch = (v: string) => {},
  onClear = () => {},
}: SearchProps) => {
  const [value, setValue] = useState("");

  return (
    <Search>
      <Box
        sx={{
          p: 1,
          position: "absolute",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SearchIcon />
      </Box>
      <StyledInputBase
        value={value}
        placeholder={placeholder}
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // エンターキー押下時の処理
            onSearch(value);
          }
        }}
        endAdornment={
          <IconButton
            onClick={() => {
              setValue("");
              onClear();
            }}
            sx={{ mr: 0 }}
            disabled={value === ""}
          >
            <HighlightOffIcon
              sx={{ color: value !== "" ? "white" : "rgb(255,255,255,0.3)" }}
            />
          </IconButton>
        }
      />
    </Search>
  );
};

export default SearchCustom;
