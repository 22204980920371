import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  TextFieldProps,
} from "@mui/material";

export type IStepperInput = Omit<
  TextFieldProps,
  "minValue" | "maxValue" | "onChange" | "textFieldWidth"
> & {
  minValue?: number;
  maxValue?: number;
  onChange?: (value: string) => void;
  textFieldWidth?: number;
};

const StepperInput: React.FC<IStepperInput> = ({
  minValue = 0,
  maxValue = 99,
  textFieldWidth = 100,
  value,
  onChange,
  sx,
  inputProps,
  helperText,
  ...props
}) => {
  const [text, setText] = useState("0");
  const isNumber = useMemo(() => !isNaN(Number(text)), [text]);

  useEffect(() => {
    setText((value ?? 0).toString());
  }, [value]);

  const handleSubtract = () => {
    const new_value = (Number(text) - 1).toString();
    setText(new_value);
    if (onChange) onChange(new_value);
  };

  const handleAdd = () => {
    const new_value = (Number(text) + 1).toString();
    setText(new_value);
    if (onChange) onChange(new_value);
  };

  const sx_width = sx ? JSON.parse(JSON.stringify(sx)).width : undefined;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        sx={{
          gap: "3px",
          minWidth: (sx_width ?? textFieldWidth) + 30 * 2 + 3 * 2,
        }}
      >
        <Button
          sx={styles.button}
          onClick={() => handleSubtract()}
          disabled={Number(text) <= minValue || props.error || !isNumber}
        >
          -
        </Button>
        <TextField
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            if (onChange) onChange(e.target.value);
          }}
          sx={{ width: textFieldWidth, ...sx }}
          inputProps={{
            style: { textAlign: "center" },
            maxLength: maxValue.toString().length,
            ...inputProps,
          }}
          {...props}
        />
        <Button
          sx={styles.button}
          onClick={() => handleAdd()}
          disabled={Number(text) >= maxValue || props.error || !isNumber}
        >
          +
        </Button>
      </Box>
      {helperText && (
        <FormHelperText error={props.error} sx={{ mx: 0 }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default StepperInput;

const styles = {
  button: {
    height: 30,
    minWidth: 30,
    maxWidth: 30,
    borderRadius: 15,
    padding: 0,
  },
} as const;
