import { takeEvery } from "redux-saga/effects";
import { TYPES } from "store/types";
import { login, logout } from "./auth";
import { setColorSage } from "./color";
import { setFilterConditionSaga } from "./filter";
import { setSortConditionSaga } from "./sort";
import {
  getListLocationProjectSaga,
  getListStatusProjectSaga,
} from "./project";
import { setFavoriteConditionSaga } from "./favorite";
import { setBarcodeSage } from "./barcode";
import { setDevanSearchConditionSaga } from "./devanSearch";
import { setContractInfoSaga, clearContractInfoSaga } from "./contract";
import { setSignaturePenSizeConditionSaga } from "./template";
import { setMasterYuasaSage } from "./master";

const watcher = [
  takeEvery(TYPES.LOGIN, login),
  takeEvery(TYPES.LOGOUT, logout),
  takeEvery(TYPES.CONTRACT_DECISION, setContractInfoSaga),
  takeEvery(TYPES.CONTRACT_LOGOUT, clearContractInfoSaga),
  takeEvery(TYPES.SET_MASTER_COLOR_DATA, setColorSage),
  takeEvery(TYPES.SET_FILTER_CONDITION, setFilterConditionSaga),
  takeEvery(TYPES.SET_SORT_CONDITION, setSortConditionSaga),
  takeEvery(TYPES.GET_LIST_STATUS_PROJECT, getListStatusProjectSaga),
  takeEvery(TYPES.GET_LIST_LOCATION, getListLocationProjectSaga),
  takeEvery(TYPES.SET_FAVORITE_CONDITION, setFavoriteConditionSaga),
  takeEvery(TYPES.SET_2D_BARCODE_LAYOUT, setBarcodeSage),
  takeEvery(TYPES.SET_DEVAN_SEARCH_CONDITION, setDevanSearchConditionSaga),
  takeEvery(TYPES.SET_SIGNATURE_PEN_SIZE, setSignaturePenSizeConditionSaga),
  takeEvery(TYPES.SET_MASTER_YUASA_DATA, setMasterYuasaSage),
];

export default watcher;
