import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { signerAttributeOptions } from "../fakeData";
import SelectLabelYuasa from "../components/SelectLabelYuasa";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import SignatureInput from "components/atoms/SignatureInput";
import AccordionSection from "components/molecules/AccordionSection";
import ImagePickerList from "components/molecules/ImagePickerList";
import DrawPhotoDialog from "screens/DrawPhotoDialog";
import { StateFormType } from "@utils/template/yuasa";
import ModalController from "@shared-components/modal/ModalController";
import { DrawItem } from "services/models";
import {
  convertSvgContentToData,
  getDrawSourceFromDataSVG,
  getImageSourceFromDataSvg,
} from "@utils/draw";
import { getSignedUrlFile } from "@api/template";
import { vehicleImagePath } from "./../index";

interface IProps {
  stateForm: StateFormType;
  onChangeText: (field: keyof StateFormType) => (newText: any) => void;
}

const imageDefault: Type.ImageInfoType = {
  type: "",
  uri: "",
  name: "",
  path_file: "",
};

const maxImage = 4;

const StatusCar: React.FC<IProps> = ({ stateForm, onChangeText }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [uriImageAppearance, setUriImageAppearance] = useState<string>("");
  const [drawData, setDrawData] = useState<DrawItem[]>();
  const signatureRef = useRef<any>(null);

  const beforeOpenSignatureCanvas = () => {
    ModalController.show({
      message: `※夜間‧悪天候‧⾼速道路上‧緊急時につきましては、⼗分な⾞両確認が出来ない場合が御座いますので、ご了承下さい。
        ※作業実施にあたっては最善を尽くしますが、作業途中もしくは終了後、また保管中にやむを得ず⾞両の損傷‧不具合等のトラブルが⽣じる場合があります。
        作業実施事業者の故意または過失以外によるトラブルについては、その責任を負いません。
        ※保険会社へ作業報告のために、⾞両状態や作業など写真撮影をさせて頂く場合が御座いますが、ご了承ください。
        ※バッテリーの復旧作業を⾏った際に、稀にコンピューターやカーナビ‧チェックランプ点灯等の⽀障をきたす場合が御座いますが、ご了承下さい。`,
      visibleButton2: true,
      button2: { title: "同意する" },
      handlePressButton2: () => {
        if (signatureRef.current) {
          signatureRef.current.openModal();
        }
      },
      disableFeedback: true,
    });
    return false;
  };

  const getUrlImage = () => {
    const { type, uri, uri_jpg, uriThumb, non_Local } =
      stateForm.vehicle_appearance;
    let url = "";
    if (type === "image/svg+xml") {
      if (non_Local) {
        url = uri_jpg ?? "";
      } else {
        url = uriThumb ?? "";
      }
    } else {
      url = uri;
    }
    return url;
  };

  const getDrawData = async () => {
    let draw_data: DrawItem[] = [];
    let uri: string = "";
    const value = stateForm.vehicle_appearance;
    if (value?.type === "image/svg+xml") {
      let xml;
      if (value?.non_Local) {
        xml = await fetch(value.uri).then((response) => response.text());
      } else {
        xml = value.xmlSvg ?? "";
      }
      const svgData = await convertSvgContentToData(xml);
      if (svgData) {
        draw_data = getDrawSourceFromDataSVG(svgData, {
          width: window.innerWidth * 0.5,
          height: window.innerHeight * 0.8,
        });
        const image_data = getImageSourceFromDataSvg(svgData);
        uri = image_data?.uri ?? "";
      }
    } else {
      draw_data = [];
      uri = getUrlImage();
    }

    if (uri) {
      const currentTime = new Date().getTime() / 1000;
      const splitURI = uri.split("=");
      const exp = splitURI[splitURI.length - 1];
      if (Number(exp) < currentTime) {
        const res = await getSignedUrlFile(vehicleImagePath);
        if (res?.link_url) {
          uri = res.link_url;
        }
      }
    }

    return { draw_data, uri };
  };

  useEffect(() => {
    getDrawData().then((res) => {
      setDrawData(res.draw_data);
      setUriImageAppearance(res.uri);
    });
  }, [stateForm.vehicle_appearance]);

  return (
    <>
      <DrawPhotoDialog
        uri={uriImageAppearance}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(v) => {
          onChangeText("vehicle_appearance")(v);
        }}
        drawData={drawData}
      />
      <AccordionSection title="車両状況">
        <Stack sx={styles.formStack}>
          <FormControl>
            <InputLabel>車両外観内容</InputLabel>
          </FormControl>
          <Box
            component="img"
            src={getUrlImage()}
            sx={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            編集
          </Button>
          <FormControl>
            <InputLabel>
              画像
              <Typography component="span" variant="body2" pl={1}>
                {maxImage}枚まで
              </Typography>
            </InputLabel>
            <ImagePickerList
              addButtonText="画像を追加"
              imagesList={stateForm.images}
              maxImage={maxImage}
              onChange={onChangeText("images")}
              deleteConfirmMessage="画像を削除しますか？"
            />
          </FormControl>
          <Stack spacing={0.5}>
            <SignatureInput
              ref={signatureRef}
              label={"お客様署名"}
              signPath={stateForm.customer_signature.uri}
              onChange={(value) =>
                onChangeText("customer_signature")(value || imageDefault)
              }
              disabledNext={true}
              disabledPrev={true}
              beforeOpenSignatureCanvas={beforeOpenSignatureCanvas}
            />
            <SelectLabelYuasa
              label=""
              value={stateForm.signer_attribute?.SK}
              data={signerAttributeOptions}
              onChange={onChangeText("signer_attribute")}
              valueFieldName="value"
            />
          </Stack>
          <FormControl>
            <InputLabel>チェックリスト</InputLabel>
            <FormGroup>
              <CheckboxLabel
                label="車両の外観確認"
                labelPlacement="start"
                sx={{ mr: 1, p: 0.5 }}
                formSx={{ ".MuiFormControlLabel-label": { flex: 1 }, ml: 1 }}
                onChange={(_, checked) =>
                  onChangeText("is_check_vehicle_appearance")(checked)
                }
                checked={stateForm.is_check_vehicle_appearance}
              />
              <CheckboxLabel
                label="四輪回る"
                labelPlacement="start"
                sx={{ mr: 1, p: 0.5 }}
                formSx={{ ".MuiFormControlLabel-label": { flex: 1 }, ml: 1 }}
                onChange={(_, checked) =>
                  onChangeText("is_all_four_wheels")(checked)
                }
                checked={stateForm.is_all_four_wheels}
              />
              <CheckboxLabel
                label="鍵有無"
                labelPlacement="start"
                sx={{ mr: 1, p: 0.5 }}
                formSx={{ ".MuiFormControlLabel-label": { flex: 1 }, ml: 1 }}
                onChange={(_, checked) =>
                  onChangeText("is_presence_of_key")(checked)
                }
                checked={stateForm.is_presence_of_key}
              />
              <CheckboxLabel
                label="忘れ物"
                labelPlacement="start"
                sx={{ mr: 1, p: 0.5 }}
                formSx={{ ".MuiFormControlLabel-label": { flex: 1 }, ml: 1 }}
                onChange={(_, checked) => onChangeText("is_lost_item")(checked)}
                checked={stateForm.is_lost_item}
              />
              <CheckboxLabel
                label="ＢＴ外し有無"
                labelPlacement="start"
                sx={{ mr: 1, p: 0.5 }}
                formSx={{ ".MuiFormControlLabel-label": { flex: 1 }, ml: 1 }}
                onChange={(_, checked) =>
                  onChangeText("is_BT_removed")(checked)
                }
                checked={stateForm.is_BT_removed}
              />
              <CheckboxLabel
                label="名刺"
                labelPlacement="start"
                sx={{ mr: 1, p: 0.5 }}
                formSx={{ ".MuiFormControlLabel-label": { flex: 1 }, ml: 1 }}
                onChange={(_, checked) => onChangeText("is_name_card")(checked)}
                checked={stateForm.is_name_card}
              />
            </FormGroup>
          </FormControl>
          <div style={{ position: "relative" }}>
            <Typography
              variant="body2"
              sx={{ position: "absolute", top: 0, right: 0.5 }}
            >
              {`${stateForm.work_detail_scene.length}/100`}
            </Typography>
            <TextField
              label="作業詳細"
              value={stateForm.work_detail_scene}
              onChange={(e) =>
                onChangeText("work_detail_scene")(e.target.value)
              }
              fullWidth
              multiline={true}
              inputProps={{ maxLength: 100 }}
            />
          </div>
        </Stack>
      </AccordionSection>
    </>
  );
};

export default StatusCar;

const styles = {
  formStack: { pb: 0, width: "100%" },
};
