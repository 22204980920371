import React, { VFC, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import DrawCore from "components/common/DrawCore";
import { DrawItem } from "services/models";
import { Close } from "@mui/icons-material";

interface IProps {
  open: boolean;
  uri: string;
  onClose: () => void;
  onChange?: (data: any) => void;
  drawData?: DrawItem[];
}

const DrawPhotoDialog: VFC<IProps> = ({
  open,
  uri,
  onClose,
  onChange = () => {},
  drawData = [],
}: IProps) => {
  const DrawRef = useRef<any>();
  const handleSave = async () => {
    if (DrawRef.current) {
      const image = await DrawRef.current.getImage();
      onChange(image);
    }
    onClose();
  };

  return (
    <Dialog
      maxWidth="lg"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
      onClose={onClose}
    >
      <DialogContent sx={{ position: "relative", py: 1 }}>
        <Box sx={{ textAlign: "right" }}>
          <Tooltip title="閉じる">
            <IconButton edge="end" onClick={onClose} sx={{ p: 0.5 }}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
        <DrawCore ref={DrawRef} uri={uri} drawData={drawData} />
        <Box sx={styles.buttonBox}>
          <Button color="secondary" onClick={handleSave}>
            保存
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DrawPhotoDialog;

const styles = {
  buttonBox: {
    display: "flex",
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 20,
    right: 24,
  },
} as const;
