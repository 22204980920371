import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import messages from "config/messages";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { Project, User } from "services/models";
import { copyCustomMasterByTemplateId } from "@api/project";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { isConfirmCopyMaster } from "@utils/index";

const CREATE_PROJECT_PATH = "/project/create";
const getCopyProjectParam = (data: Project) => {
  return {
    typeAction: "custom",
    project_id: data.SK,
  };
};

interface IModalCopyCustomTemplateConfirm {
  open: boolean;
  user_info: User;
  data: Project;
}

const ModalCopyCustomTemplateConfirm: React.FC<
  IModalCopyCustomTemplateConfirm
> = ({ open, user_info, data }) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [copyData, setCopyData] = useState<boolean>(false);
  const history = useHistoryCustom();

  useEffect(() => {
    if (open) {
      if (isConfirmCopyMaster(user_info, data)) {
        setOpenDialog(true);
      } else {
        // 複製しない場合、帳票作成へ遷移
        history.pushWithRef(CREATE_PROJECT_PATH, getCopyProjectParam(data));
      }
    }
  }, [open]);

  const handleClickDeny = () => {
    history.pushWithRef(CREATE_PROJECT_PATH, getCopyProjectParam(data));
  };

  const handleClickConfirm = async () => {
    LoadingOverlayController.show();
    await copyCustomMasterByTemplateId(
      data.template_project_id,
      data.project_location_id,
      user_info.location_id ?? "",
      copyData,
    ).finally(() => {
      LoadingOverlayController.hide();
    });
    setTimeout(() => {
      history.pushWithRef(CREATE_PROJECT_PATH, getCopyProjectParam(data));
    }, 100);
  };

  return (
    <Dialog
      maxWidth="xs"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={openDialog}
    >
      <DialogContent>
        {messages.TEMPLATE.MSG_CONFIRM_COPY_MASTER.split(/<br>|\n/g).map(
          (item, i) => {
            return (
              <Typography key={i} sx={{ wordBreak: "break-all" }}>
                {item}
              </Typography>
            );
          },
        )}
        <CheckboxLabel
          label={messages.TEMPLATE.MSG_COPY_MASTER_DATA}
          onClick={() => {
            setCopyData(!copyData);
          }}
          checked={copyData}
          formSx={{ mr: 0 }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleClickDeny} variant={"outlined"}>
          {messages.COMMON.BUTTON.CANCEL}
        </Button>
        <Button
          onClick={async () => {
            await handleClickConfirm();
          }}
        >
          {messages.COMMON.BUTTON.CONFIRM}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalCopyCustomTemplateConfirm;
