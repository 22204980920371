import React from "react";
import { Box, Button, Card, CardContent, Divider } from "@mui/material";
import { Stack } from "@mui/system";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import FullScreenDialog from "components/atoms/FullScreenDialog";

interface IProps {
  originData: Array<any>;
  isVisible: boolean;
  closeModal: Function;
  onPressButton: Function;
  listItemSelected: Array<any>;
  setListItemSelected: Function;
  title?: string;
}

const SelectMultipleOption = ({
  isVisible,
  closeModal,
  originData,
  onPressButton,
  listItemSelected,
  setListItemSelected,
  title = "荷姿状態選択",
}: IProps) => {
  const ItemCard = ({ item }: any) => {
    return (
      <>
        <Box display={"flex"} alignItems={"center"}>
          <span className="IgnoreExtractRuleTarget">
            <CheckboxLabel
              onClick={() => handleSelected(item)}
              checked={isCheckSelectBox(item)}
              label={item?.product_status_name}
            />
          </span>
        </Box>
        <Divider />
      </>
    );
  };

  const isCheckSelectBox = (item: any) => {
    return listItemSelected?.some((elm: any) => elm.SK == item.SK);
  };

  const handleSelected = (item: any) => {
    const cloneSelected = [...listItemSelected];
    if (isCheckSelectBox(item)) {
      setListItemSelected(
        listItemSelected?.filter((elm: any) => elm.SK !== item.SK),
      );
    } else {
      cloneSelected.push(item);
      setListItemSelected(cloneSelected);
    }
  };

  const handleButton = () => {
    onPressButton([...listItemSelected]);
    closeModal();
  };

  return (
    <FullScreenDialog
      open={isVisible}
      onClose={() => closeModal()}
      title={title}
    >
      <Card sx={{ mb: 5 }}>
        <CardContent>
          {originData &&
            originData.map((item, index) => {
              if (!item) {
                return null;
              } else {
                return <ItemCard item={item} key={index} />;
              }
            })}
        </CardContent>
      </Card>
      <Box
        sx={{
          position: "fixed",
          flexDirection: "row",
          bottom: 20,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ bgcolor: "white", borderRadius: 1, mr: 2 }}>
          <Button variant="outlined" onClick={() => closeModal()}>
            キャンセル
          </Button>
        </Box>
        <Button color="secondary" onClick={() => handleButton()}>
          決定
        </Button>
      </Box>
    </FullScreenDialog>
  );
};

export default SelectMultipleOption;
