import { call, select } from "redux-saga/effects";
import { getUserInfo, storeCondition } from "shared/utils";
import { RootState } from "store/reducer";

export function* setFavoriteConditionSaga(action: any): any {
  try {
    const updatedConditionFavorite = yield select(
      (state: RootState) => state.favoriteProjectInfo,
    );
    const userId = yield getUserInfo()?.user_id;
    yield call(
      storeCondition,
      "favorite-project",
      updatedConditionFavorite,
      userId,
    );
  } catch (error) {
    console.log("function*setFavoriteConditionSaga ~ error", error);
  }
}
