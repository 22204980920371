import {
  METHOD_API,
  createConnector,
  getParamsHelp,
  prefixApi,
} from "./Connector";

interface IParams {
  keyword: string;
  last_key: string;
  sort_type: string;
  sort_by?: string;
  filter_condition?: string;
}

interface IParamsUserCompany {
  keyword: string;
  user_id?: string;
  ignore_me: boolean;
}

interface IParamsUserMade {
  keyword: string;
  last_key: string;
  sort_type: string;
  sort_by?: string;
  filter_condition?: string;
  is_deleted?: boolean;
}

export const getListProject = (params: IParams) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project?${getParamsHelp(params)}`,
    null,
  );
};

export const getDetailProject = (project_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/${encodeURIComponent(project_id)}`,

    null,
  );
};

export const updateProjectApi = (project_id: string, dataBody: FormData) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/project_v2/${encodeURIComponent(project_id)}`,
    dataBody,
  );
};

export const createProjectApi = (project_data: FormData) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/project_v2`,
    project_data,
  );
};

export const getListStatusProjectApi = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/status`, null);
};

export const updateFavoriteProjectApi = (
  project_id: string,
  dataBody: { is_favorite: boolean },
) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/project/favorite/${encodeURIComponent(project_id)}`,
    dataBody,
  );
};

export const getListUserCompanyApi = (params: IParamsUserCompany) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/contract/users?${getParamsHelp(params)}`,
    null,
  );
};

export const getUserByProjectApi = (params: any) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/${encodeURIComponent(
      params.project_id,
    )}/users?${getParamsHelp(params.params)}`,
    null,
  );
};

export const getListLocationApi = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/location`, null);
};

// TLOG --->>
// export const getProjectDefaultApi = () => {
//   return createConnector(METHOD_API.GET, `${prefixApi}/project_default`, null);
// };

export const getListReport = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/report/all`, null);
};

export const getDetailProjectTlog = (project_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/detail/${encodeURIComponent(project_id)}`,
    null,
  );
};

export const getDetailProjectByWorkId = (work_id: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/detail/workid/${encodeURIComponent(work_id)}`,
    null,
  );
};

export const getProjectByRreportType = () => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/by_report_type`,
    null,
  );
};

export const getExistsProject = (data: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/project/exists_project`,
    data,
  );
};

export const getListProjectUserMade = (params: IParamsUserMade) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/project/user-made?${getParamsHelp(params)}`,
    null,
  );
};

export const updateRestoreProject = (data: any) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/project/restore-project`,
    data,
  );
};

export const deleteProject = (data: any) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/project/delete`,
    data,
  );
};

export const updateIsDeletedProject = (project_id: string, data: any) => {
  return createConnector(
    METHOD_API.PUT,
    `${prefixApi}/project/is_deleted/${encodeURIComponent(project_id)}`,
    data,
  );
};

export const getProjectLocation = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/project/location`, null);
};

export const copyCustomMasterByTemplateId = (
  template_id: string,
  project_location_id: string,
  location_id: string,
  copy_data: boolean,
) => {
  const data = {
    template_id: template_id,
    original_location_id: project_location_id,
    create_location_id: location_id,
    copy_data: copy_data,
  };
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/project/copy-master`,
    data,
  );
};
// TLOG <<---
