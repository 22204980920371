import { RootState } from "store/reducer";

export const listStatusProject = (state: RootState) => {
  return state?.managerMaster?.status_project;
};

export const listLocationProject = (state: RootState) => {
  return state?.managerMaster?.location_project;
};

export const getIsLoadingProject = (state: RootState) => {
  return state?.managerMaster?.is_loading_project;
};
