import { useCallback, useEffect, useMemo, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
  Stack,
  Dialog,
  DialogContent,
} from "@mui/material";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import UsageInfo from "components/molecules/UsageInfo";
import { getContractInfoApi } from "@api/contract";
import { formatBytes } from "@utils/FormatBytes";
import TabPanel from "components/atoms/TabPanel";
import MemberList from "./Member/MemberList";
import InviteList from "./Inivte/InviteList";
import AddInviteDialog, { IInviteType } from "./Inivte/AddInviteDialog";
import { InviteUser, User } from "services/models";
import { getMemberListApi } from "@api/member";
import messages from "config/messages";
import { checkUserJoinContractApi } from "@api/User";
import ModalController from "@shared-components/modal/ModalController";
import CreateAccountDialog from "./CreateAccountDialog";
import { getParamsFilter, getUserInfo } from "@utils/index";
import { useDispatch, useSelector } from "react-redux";
import { getConditionFilter } from "selector/filterSelector";
import { getSortOfScreen } from "selector/sortSelector";
import { TYPES } from "store/types";
import PopupSort from "components/atoms/PopupSort";
import FilterInput from "components/molecules/FilterInput";
import { LIST_TYPE_SORT_MEMBER_LIST } from "@shared-constants";

let paramsFilter: any;

const MemberListScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [tab, setTab] = useState(0);
  const [invite, setInvite] = useState(false);
  const [add_invite, setAddInvite] = useState(false);
  const [invite_type, setInviteType] = useState<IInviteType>("email");
  const [fetchData, setFetchData] = useState(false);
  const [searchResult, setSearchResult] = useState<string>("");

  const [data_list_user, setDataListUser] = useState<User[]>([]);
  const [disp_list_user, setDispListUser] = useState<User[]>([]);
  // const [data_list_invite, setDataListInvite] = useState<InviteUser[]>([]);
  const [disp_list_invite, setDispListInvite] = useState<InviteUser[]>([]);

  const [max_storage_usage, setMaxStorageUsage] = useState<number>(0);
  const [memory_used, setMemoryUsed] = useState<number>(0);
  const [max_number_employees, setMaxNumberEmployees] = useState<number>(0);
  const [user_count, setUserCount] = useState<number>(0);
  const [
    number_of_create_user_report_accounts,
    setNumberOfCreateUserReportAccounts,
  ] = useState<number>(0);
  const [can_create_user_report_user_count, setCanCreateUserReportUserCount] =
    useState<number>(0);

  const membersManagementFilter = useSelector(
    getConditionFilter("members_management"),
  );
  const typeSort = useSelector(getSortOfScreen("members_management"));
  const [sortBy, setSortBy] = useState<string | null>(null);

  const [add_account, setAddAccount] = useState(false); // TLOG
  const user_info: User = getUserInfo(); // TLOG
  const dispatch = useDispatch();

  // データ取得 ------------------------------------------------
  // get contract info
  const fetchUsageInfo = async () => {
    setMaxStorageUsage(0);
    setMemoryUsed(0);
    setMaxNumberEmployees(0);
    setUserCount(0);
    setNumberOfCreateUserReportAccounts(0);
    setCanCreateUserReportUserCount(0);

    await getContractInfoApi().then((res) => {
      setMaxStorageUsage(
        formatBytes({
          val: res.max_storage_usage,
          from: "KB",
          to: "GB",
        }),
      );
      setMemoryUsed(
        formatBytes({ val: res.memory_used, from: "KB", to: "GB" }),
      );
      setMaxNumberEmployees(res.max_number_employees);
      setUserCount(res.user_count);
      setNumberOfCreateUserReportAccounts(
        res.number_of_create_user_report_accounts,
      );
      setCanCreateUserReportUserCount(res.can_create_user_report_user_count);
    });
  };

  // get member list
  // --->> TLOG
  // const fetchMenberList = async () => {
  //   LoadingOverlayController.show();
  //   await getMemberListApi()
  //     .then((res) => {
  //       setDataListUser(res?.users);
  //       setDispListUser(res?.users);
  //       setDataListInvite(res?.invite_users);
  //       setDispListInvite(res?.invite_users);

  //       // メンバー編集後の表示のため、メンバー数を更新
  //       setUserCount(res?.users.length);
  //     })
  //     .catch((error) => {
  //       console.log(error, "get member list error???");
  //     })
  //     .finally(() => {
  //       LoadingOverlayController.hide();
  //       setFetchData(false);
  //     });
  // };
  const fetchMenberList = async (sort_by?: string) => {
    LoadingOverlayController.show();
    await getMemberListApi("", "", sort_by ?? sortBy ?? "", paramsFilter)
      .then((res) => {
        setDataListUser(res?.users.data);
        setDispListUser(res?.users.data);
      })
      .catch((error) => {
        console.log(error, "get member list error???");
      })
      .finally(() => {
        LoadingOverlayController.hide();
        setFetchData(false);
      });
  };
  // <<--- TLOG

  useEffect(() => {
    fetchUsageInfo();
    paramsFilter = getParamsFilter(membersManagementFilter); // TLOG
    fetchMenberList();
  }, []);

  useEffect(() => {
    if (fetchData) {
      fetchUsageInfo(); // TLOG
      fetchMenberList();
    }
  }, [fetchData]);

  // 検索 ------------------------------------------------
  const _handleSearch = useCallback(
    (str: string) => {
      LoadingOverlayController.show();
      str = convertZenkakuToHankaku(str);
      setDispListUser([]);
      setSearchResult("");

      const res_user_data = data_list_user.filter((item) => {
        const full_name = convertZenkakuToHankaku(item.full_name);
        return (
          str === "" || full_name.toUpperCase().includes(str.toUpperCase())
        );
      });

      setDispListUser(res_user_data);
      if (res_user_data.length === 0 && str !== "") {
        setSearchResult(messages.COMMON.MSG_NON_SEARCH_RESULT(str));
      }
      LoadingOverlayController.hide();
    },
    [data_list_user],
  );

  const convertZenkakuToHankaku = (str: string) => {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  };

  // --->> TLOG
  // // 招待 ------------------------------------------------
  // const handlePressInvite = useCallback(async () => {
  //   const checkAddUser = async () => {
  //     LoadingOverlayController.show();
  //     return await checkUserJoinContractApi()
  //       .then((res) => {
  //         return res;
  //       })
  //       .catch((error) => {
  //         console.log(error, "error checkAddUser");
  //         return null;
  //       })
  //       .finally(() => {
  //         LoadingOverlayController.hide();
  //       });
  //   };
  //   const check = await checkAddUser();
  //   if (check === null) return;
  //   if (!check) {
  //     ModalController.show({
  //       message: messages.OTHER_SCREEN.MSG_MEMBERS_MANAGEMENT_ERROR_ADD_BUTTON,
  //       visibleButton2: true,
  //     });
  //     return;
  //   }
  //   setInvite(true);
  // }, []);

  // 追加 ------------------------------------------------
  const handlePressAdd = useCallback(async () => {
    const checkAddUser = async () => {
      LoadingOverlayController.show();
      return await checkUserJoinContractApi()
        .then((res) => {
          return res;
        })
        .catch((error) => {
          console.log(error, "error checkAddUser");
          return null;
        })
        .finally(() => {
          LoadingOverlayController.hide();
        });
    };
    const check = await checkAddUser();
    if (check === null) return;
    if (!check) {
      ModalController.show({
        message: messages.OTHER_SCREEN.MSG_MEMBERS_MANAGEMENT_ERROR_ADD_BUTTON,
        visibleButton2: true,
      });
      return;
    }
    setAddAccount(true);
  }, []);
  // <<--- TLOG

  // --->> TLOG
  const handleSort = async (type_sort: string) => {
    setSortBy(type_sort);
    const payload = {
      type_screen: "members_management",
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    await fetchMenberList(type_sort);
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_MEMBER_LIST).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_MEMBER_LIST).indexOf(
          defaultSort[0],
        );
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  useEffect(() => {
    paramsFilter = getParamsFilter(membersManagementFilter);
  }, [membersManagementFilter]);
  // <<--- TLOG

  return (
    <GenericTemplate
      title="アカウント一覧"
      isSearch={true}
      // --->> TLOG
      // searchPlaceholder={"参加メンバーの名前検索"}
      searchPlaceholder={"アカウントの名前検索"}
      // <<--- TLOG
      onSearch={(v) => {
        _handleSearch(v);
      }}
      onSearchClear={() => {
        _handleSearch("");
      }}
    >
      {/* 利用状況 */}
      <UsageInfo
        max_storage_usage={max_storage_usage}
        memory_used={memory_used}
        max_number_employees={max_number_employees}
        user_count={user_count}
        number_of_create_user_report_accounts={
          number_of_create_user_report_accounts
        }
        can_create_user_report_user_count={can_create_user_report_user_count}
      />

      {/* 追加ボタン */}
      {/* --->> TLOG
      <Box width={"100%"} textAlign={"right"}>
        <Button sx={{ mt: 2 }} onClick={handlePressInvite}>
          招待
        </Button>
      </Box>
      */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        sx={{ mt: 2 }}
      >
        <Box minWidth={150}>
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_MEMBER_LIST).map((item) => {
              return {
                title: item.title,
                onClick: () => handleSort(item.value),
              };
            })}
            defaultIndex={sortDefaultIndex}
            disabled={disp_list_user.length === 0}
          />
        </Box>
        <Box flexGrow={1} />
        <Box width={{ xs: "100%", md: "50%" }}>
          <FilterInput
            typeScreen="members_management"
            onFetchData={() => {
              fetchMenberList();
            }}
          />
        </Box>
        <Button
          onClick={handlePressAdd}
          sx={{
            mt: { xs: 2, md: 0 },
            ml: 1,
            alignSelf: { xs: "flex-end", md: "center" },
          }}
          color="secondary"
        >
          追加
        </Button>
      </Box>
      {/* <<--- TLOG*/}

      {/* 一覧 */}
      <Card sx={{ mt: 2 }}>
        <CardContent sx={{ overflowY: "auto" }}>
          {/* --->> TLOG
          <Tabs
            value={tab}
            onChange={(event: React.SyntheticEvent, newValue: number) => {
              setTab(newValue);
            }}
            sx={{ pb: 1 }}
            variant="fullWidth"
          >
            <Tab label={<Typography variant="h6">参加中</Typography>} />
            <Tab label={<Typography variant="h6">招待中</Typography>} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            // 参加中 
            <MemberList
              data={disp_list_user}
              setFetchData={setFetchData}
              searchResult={searchResult}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            // 招待中 
            <InviteList data={disp_list_invite} setFetchData={setFetchData} />
          </TabPanel>
          <<--- TLOG */}
          <MemberList
            data={disp_list_user}
            setFetchData={setFetchData}
            searchResult={searchResult}
          />
        </CardContent>
      </Card>

      {/* 招待ダイアログ */}
      <Dialog
        maxWidth="xs"
        sx={{ "& .MuiDialog-paper": { width: "50%", py: 2 } }}
        open={invite}
        onClick={() => setInvite(false)}
      >
        <DialogContent>
          <Stack>
            <Button
              onClick={() => {
                setInviteType("email");
                setAddInvite(true);
              }}
              fullWidth
            >
              メールアドレスで招待
            </Button>
            <Button
              onClick={() => {
                setInviteType("phone");
                setAddInvite(true);
              }}
              fullWidth
            >
              電話番号で招待
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <AddInviteDialog
        open={add_invite}
        setOpen={setAddInvite}
        inviteType={invite_type}
        setFetchData={setFetchData}
      />
      <CreateAccountDialog
        open={add_account}
        setOpen={setAddAccount}
        data={user_info}
        handleAfterCreate={() => setFetchData(true)}
      />
    </GenericTemplate>
  );
};

export default MemberListScreen;
