import { TYPES } from "store/types";

interface State {
  PK: string;
  SK: string;
  report_id: string;
  report_type_id: string;
}
const initialState: State[] = [];

const set_favorite_project = (payload: State[]) => ({
  type: TYPES.SET_FAVORITE_PROJECT_INFO,
  payload: payload,
});
const clear_favorite_project = () => ({
  type: TYPES.CLEAR_FAVORITE_PROJECT_INFO,
});

type Actions = ReturnType<
  typeof set_favorite_project | typeof clear_favorite_project
>;

export const favoriteProjectInfoReducer = (
  state = initialState,
  action: Actions,
) => {
  switch (action.type) {
    case TYPES.SET_FAVORITE_PROJECT_INFO:
      return action.payload;

    case TYPES.CLEAR_FAVORITE_PROJECT_INFO:
      return initialState;

    default:
      return state;
  }
};
