import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import "./styles.css";
import {
  LocalizationProvider,
  TimePickerProps,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jaLocale from "date-fns/locale/ja";

type TimePickerCustomProps<TInputDate, TDate> = Omit<
  TimePickerProps<TInputDate, TDate>,
  "renderInput" | "required" | "helperText" | "error" | "onBlur"
> & {
  renderInput?: (props: TextFieldProps) => React.ReactElement;
  required?: boolean;
  helperText?: React.ReactNode;
  error?: boolean;
  onBlur?: Function;
};
const TimePickerCustom = <TInputDate, TDate = TInputDate>(
  timePickerCustomProps: TimePickerCustomProps<TInputDate, TDate>,
) => {
  const {
    required,
    helperText,
    error,
    onBlur = () => {},
    ...props
  } = timePickerCustomProps;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale}>
      <TimePicker
        {...props}
        inputFormat="HH:mm"
        mask="__:__"
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            helperText={helperText}
            onBlur={() => onBlur()}
            error={error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TimePickerCustom;
