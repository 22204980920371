import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Colors } from "@template/style";
import { DevanSchedule } from "services/models";

interface IDevanItemProps {
  data: DevanSchedule;
  cardSx?: SxProps<Theme>;
  onClick?: Function;
  disabled?: boolean;
}

const DevanItem: React.FC<IDevanItemProps> = ({
  data,
  cardSx,
  onClick,
  disabled,
}) => {
  return (
    <Card
      onClick={() => {
        if (disabled) return;
        if (onClick && typeof onClick == "function") {
          onClick(data);
        }
      }}
      sx={{
        cursor: disabled ? undefined : "pointer",
        opacity: disabled ? 0.6 : undefined,
        filter: disabled ? "grayscale(40%)" : undefined,
        ...cardSx,
      }}
    >
      <CardHeader
        avatar={
          <Typography
            sx={{
              fontSize: 12,
              color: Colors.DISABLE_BTN_TEXT,
              width: 50,
              maxWidth: 50,
            }}
          >
            コンテナ番号
          </Typography>
        }
        sx={{
          bgcolor: "#BFE4FF",
          py: 1.5,
        }}
        titleTypographyProps={{ fontSize: 20, fontWeight: "bold" }}
        title={
          <span
            className="IgnoreExtractRuleTarget"
            style={{ wordBreak: "break-all" }}
          >
            {data?.container_no}
          </span>
        }
        action={
          <Box sx={{ display: "flex", height: 42, alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: 12,
                color: Colors.REQUIRE,
                width: "50px",
                maxWidth: "50px",
              }}
            >
              {data?.is_accepted ? "受付済" : ""}
            </Typography>
          </Box>
        }
      />
      <CardContent sx={{ p: 1.5, minHeight: 80, ":last-child": { pb: 1 } }}>
        <Stack spacing={1}>
          <Box sx={styles.contentRow}>
            <Typography sx={styles.contentTitle}>納入日時</Typography>
            <Typography sx={styles.contentValue}>
              {data?.date_of_delivery + " " + data?.delivery_time ?? ""}
            </Typography>
          </Box>
          <Box sx={styles.contentRow}>
            <Typography sx={styles.contentTitle}>搬入場所</Typography>
            <Typography sx={styles.contentValue}>
              {data?.delivery_location ?? "1"}
            </Typography>
          </Box>
          <Box sx={styles.contentRow}>
            <Typography sx={styles.contentTitle}>バース番号</Typography>
            <Typography sx={styles.contentValue}>
              {data?.berth_number ?? "1"}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DevanItem;

const styles = {
  contentRow: { display: "flex", flexDirection: "row", alignItems: "center" },
  contentTitle: {
    color: Colors.DISABLE_BTN_TEXT,
    minWidth: 80,
    wordBreak: "break-all",
  },
  contentValue: { pl: 2, flex: 1, wordBreak: "break-all" },
} as const;
