import {
  METHOD_API,
  createConnector,
  prefixApi,
  getParamsHelp,
} from "./Connector";

export type paramAddUserToListGroup = {
  data_user: {
    sk_user: string;
    location_id: string;
    contract_login_id: string;
  };
  list_group: {
    SK_group: string;
    group_parentid: string;
    // seq: number;
  }[];
  user_out_group: {
    PK: string;
    SK: string;
  }[];
};

export const postAddUserToListGroup = (data: paramAddUserToListGroup) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/add-user-to-list-group-v2`,
    data,
  );
};

export const putSortGroupApi = (data: { PK: string; SK: string }[]) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/groups/sort`,
    data,
  );
};

export const getListGroupMaster = () => {
  return createConnector(METHOD_API.GET, `${prefixApi}/list-group-master`);
};

export const createGroupMaster = (data: { group_master_name: string }) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/create-group-master`,
    data,
  );
};

export const deleteGroupMaster = (
  data: { PK: string; SK: string },
  isDeleteGroupMaster: boolean = true,
) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/${
      isDeleteGroupMaster ? `delete-group-master` : `delete-group`
    }`,
    data,
  );
};

export const updateGroupMaster = (data: {
  PK: string;
  SK: string;
  group_master_name: string;
}) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/update-group-master`,
    data,
  );
};

export const putUpdateGroupInfo = (data: {
  PK: string;
  SK: string;
  group_name: string;
}) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/update-group`,
    data,
  );
};

export const GetListGroupByUserID = (params: {
  sk_user: string;
  location_id: string;
}) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-group-by-user-id-v2?${getParamsHelp(params)}`,
  );
};

export const getListGroup = (params: { group_parentid: string }) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-group?${getParamsHelp(params)}`,
  );
};

export const postCreateGroup = (data: {
  group_parentid: string;
  group_master_id: string;
  group_name: string;
}) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/create-group`,
    data,
  );
};

export const getListTopGroupAll = (contract_id?: string) => {
  let url_params = "";
  if (contract_id !== undefined) {
    const params = { contract_id: contract_id };
    url_params = `?${getParamsHelp(params)}`;
  }
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/get-list-top-group-all${url_params}`,
  );
};

export const getListTopGroupAllByContractInfo = (data: any, id: string) => {
  return createConnector(
    METHOD_API.POST,
    `${prefixApi}/get-list-top-group-all/${encodeURIComponent(id)}`,
    data,
  );
};

export const getListActivityBase = (keyword?: string, flg?: boolean) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-activity-base?${getParamsHelp({
      keyword: keyword,
      group_list_flg: flg,
    })}`,
  );
};

export const getActivityBaseGroupName = (SK: string) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-activity-base-group-name?${getParamsHelp({ SK: SK })}`,
  );
};

export const checkActivityBaseExists = (params: {
  activity_base_name: string;
  activity_base_code: string;
  group_sk: string;
  SK: string;
  check_user: boolean;
}) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/check-activity-base-exists?${getParamsHelp(params)}`,
  );
};

export const createActivityBase = (data: {
  activity_base_name: string;
  activity_base_code: string;
  group_sk: string;
}) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_POST,
    `${prefixApi}/create-activity-base`,
    data,
  );
};

export const updateActivityBase = (data: {
  PK: string;
  SK: string;
  activity_base_name: string;
  activity_base_code: string;
  group_sk: string;
}) => {
  return createConnector(
    METHOD_API.UPLOAD_FORM_PUT,
    `${prefixApi}/update-activity-base`,
    data,
  );
};

export const deleteActivityBase = (data: {
  PK: string;
  SK: string;
  group_sk: string;
}) => {
  return createConnector(
    METHOD_API.DELETE,
    `${prefixApi}/delete-activity-base`,
    data,
  );
};

export const GetListGroupByProjectID = (params: {
  project_id: string;
  location_id: string;
}) => {
  return createConnector(
    METHOD_API.GET,
    `${prefixApi}/get-list-group-by-project-id?${getParamsHelp(params)}`,
  );
};
