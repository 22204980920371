import { Cancel } from "@mui/icons-material";
import { Badge, BadgeTypeMap, IconButton } from "@mui/material";
import { OverrideProps } from "@mui/material/OverridableComponent";

type IRemoveBtnBadge<
  D extends React.ElementType = BadgeTypeMap["defaultComponent"],
  P = {},
> = Omit<OverrideProps<BadgeTypeMap<D, P>, D>, "onClick" | "disabled"> & {
  onClick: Function;
  disabled?: boolean;
};

const CancelBtnBadge = ({ onClick, disabled, ...props }: IRemoveBtnBadge) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={
        <IconButton
          onClick={() => onClick()}
          color="inherit"
          disabled={disabled}
        >
          <Cancel
            sx={{
              bgcolor: "white",
              borderRadius: "50%",
            }}
          />
        </IconButton>
      }
      {...props}
    />
  );
};
export default CancelBtnBadge;
