import { useEffect, useMemo, useRef, useState, VFC } from "react";
import { useDispatch } from "react-redux";
import GenericTemplate from "@template/index";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  FormGroup,
  TextField,
  FormLabel,
  Divider,
  Stack,
  Box,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import _ from "lodash";
import { createFormUpload, createObjectURL, getUserInfo } from "@utils/index";
import PasswordChangeDialog from "./PasswordChangeDialog";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import DropzoneDialog from "components/molecules/DropzoneDialog";
import AvatarRes from "components/atoms/AvatarRes";
import LabelRequired from "components/atoms/LabelRequired";
import { getContractApi } from "@api/contract";
import { getListPositionApi, apiGetUserInfo, updateUserApi } from "@api/User";
import {
  COUNTRY_CODE_JP,
  SEAL_MAX_ROWS,
  USER_ROLES,
  LIST_USER_ROLE_FOR_ADMIN,
  LIST_USER_ROLE_FOR_MEMBER_AND_EDITOR,
} from "@shared-constants";
import messages from "config/messages";
import { TYPES } from "store/types";
import { Validation } from "@validation";
import { useRouterPrompt } from "shared/hook/useRouterPrompt";
import LabelValueText from "components/atoms/LabelValueText";
import GroupInfoOption, {
  IGroupEditData,
} from "components/organisms/AccountGroupInfoOption";
import { TypePosition } from "services/models/models";
import Dropzone from "components/atoms/Dropzone";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import SelectLabel from "components/atoms/SelectLabel";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { ActivityBase } from "services/models";
import { getDataStorage, STORAGE, storeData } from "@utils/Storage";

interface IStateForm {
  PK: string;
  SK: string;
  avatar: string | null;
  preferred_username_cognito: string;
  user_role: string;
  full_name: string;
  phone_number: string;
  email: string;
  contract_name: string;
  belong_company_name: string;
  avatar_image: any;
  email_verified: boolean;
  phone_number_verified: boolean;
  // --->> TLOG
  position_id: string;
  position_name: string;
  location_id: string;
  seal_display_name: string;
  seal_file_path: string | null;
  seal_image: any;
  location_code: string | null;
  group_info: IGroupEditData[];
  is_vertical_writing_sign: boolean;
  can_create_user_report: boolean;
  // <<--- TLOG
}

const initialStateForm: IStateForm = {
  PK: "",
  SK: "",
  avatar: "",
  preferred_username_cognito: "",
  user_role: "",
  full_name: "",
  phone_number: "",
  email: "",
  contract_name: "",
  belong_company_name: "",
  avatar_image: undefined,
  email_verified: false,
  phone_number_verified: false,
  // --->> TLOG
  position_id: "",
  position_name: "",
  location_id: "",
  seal_display_name: "",
  seal_file_path: "",
  seal_image: undefined,
  location_code: null,
  group_info: [],
  is_vertical_writing_sign: false,
  can_create_user_report: false,
  // <<--- TLOG
};

const initalFormError = {
  preferred_username_cognito: "",
  full_name: "",
  phone_number: "",
  email: "",
  belong_company_name: "",
  // --->> TLOG
  group_info: "",
  seal_display_name: "",
  position_name: "",
  // <<--- TLOG
};

const POSITION_SEP = "@" as const; // TLOG

const AccountScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [stateForm, setStateForm] = useState(initialStateForm);
  const [originData, setOriginData] = useState(initialStateForm);
  const [formError, setFormError] = useState(initalFormError);
  const [delete_avatar, setDeleteAvatar] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [fileOpen, setFileOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const ref = useRef(null);
  // --->> TLOG
  const [isDeleteSeal, setIsDeleteSeal] = useState<boolean>(false);
  const [listPosition, setListPosition] = useState<TypePosition[]>([]);
  const groupInfoRef = useRef<any>();
  const contract_info = getDataStorage(STORAGE.CONTRACT_INFO);
  const contractInfoObject = JSON.parse(contract_info);
  // <<--- TLOG

  // データ取得 ------------------------------------------------
  useEffect(() => {
    fetchdata();
    window.scrollTo(0, 0);
  }, []);

  const fetchdata = () => {
    let updateStateForm: IStateForm = initialStateForm;

    const fetchUserInfo = async () => {
      LoadingOverlayController.show();

      const user_info = await getUserInfo();

      const res = await apiGetUserInfo(user_info?.SK)
        .then((res) => {
          updateStateForm = {
            ...updateStateForm,
            PK: res?.PK,
            SK: res?.SK,
            avatar: res?.avatar,
            preferred_username_cognito: res?.preferred_username_cognito,
            user_role: res?.user_role,
            full_name: res?.full_name,
            phone_number: res?.phone_number ? res.phone_number : "",
            email: res?.email ? res.email : "",
            belong_company_name: res?.belong_company_name ?? "",
            avatar_image: undefined,
            // --->> TLOG
            // email_verified: res?.email_verified,
            email_verified: false,
            // <<--- TLOG
            phone_number_verified: res?.phone_number_verified,
            // --->> TLOG
            position_id: res?.position_id,
            position_name: res?.position_name,
            location_id: res?.location_id,
            seal_display_name: res?.seal_display_name
              ? res?.seal_display_name.replace(/\r\n/g, "\n")
              : "",
            seal_file_path: res?.seal_file_path,
            seal_image: undefined,
            is_vertical_writing_sign:
              res?.is_vertical_writing_sign !== undefined
                ? res?.is_vertical_writing_sign
                : false,
            can_create_user_report:
              res?.can_create_user_report !== undefined
                ? res?.can_create_user_report
                : false,
            // <<--- TLOG
          };

          setOriginData(updateStateForm);
          setStateForm(updateStateForm);
          // fetchListPosition(); // TLOG

          storeData(STORAGE.USER_INFO, JSON.stringify(res));
          return true;
        })
        .catch((error) => {
          console.log(error, "error getUserInfoByIDApi");
          LoadingOverlayController.hide(); // TLOG
        })
        .finally(() => {
          // LoadingOverlayController.hide(); // TLOG
        });

      // --->> TLOG
      if (res && updateStateForm.user_role != USER_ROLES.OWNER.value) {
        if (groupInfoRef && groupInfoRef.current) {
          await groupInfoRef.current.handlefetchList().finally(() => {
            LoadingOverlayController.hide();
          });
        }
      } else {
        LoadingOverlayController.hide();
      }
      // <<--- TLOG
    };
    const fetchContractInfo = async () => {
      await getContractApi()
        .then((res) => {
          updateStateForm = {
            ...updateStateForm,
            contract_name: res?.contract_name,
          };

          setOriginData(updateStateForm);
          setStateForm(updateStateForm);
        })
        .catch((error) => {
          console.log(error, "error getContractApi");
        });
    };
    // --->> TLOG
    // const fetchListPosition = async () => {
    //   try {
    //     const res: TypePosition[] = await getListPositionApi();
    //     if (res.length > 0) {
    //       setListPosition(res);
    //     }
    //   } catch (error: any) {
    //     if (!ModalController.isShowing())
    //       ModalController.show({
    //         message: error?.response?.detail.message,
    //         visibleButton2: true,
    //       });
    //     console.log(error, "error getContractApi");
    //   }
    // };
    // <<--- TLOG

    LoadingOverlayController.hide();
    fetchUserInfo();
    fetchContractInfo();
  };

  // 入力制御 ------------------------------------------------
  const hasUpdateData = useMemo(() => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  }, [stateForm, originData]);

  const chooseImage = (response: any) => {
    setStateForm({ ...stateForm, avatar_image: response[0] });
    setIsEdited(
      !_.isEqual({ ...stateForm, avatar_image: response[0] }, originData),
    );
    setFileOpen(false);
  };

  const getUrlAvatar = () => {
    if (typeof stateForm.avatar_image === "object") {
      return createObjectURL(stateForm.avatar_image);
    }
    return stateForm.avatar ? stateForm.avatar : undefined;
  };

  // --->> TLOG
  const chooseImageSeal = (response: any) => {
    setStateForm({ ...stateForm, seal_image: response[0] });
    setIsEdited(
      !_.isEqual({ ...stateForm, seal_image: response[0] }, originData),
    );
    setFormError({ ...formError, seal_display_name: "" });
  };

  const getUrlSeal = () => {
    if (typeof stateForm.seal_image === "object") {
      return createObjectURL(stateForm.seal_image);
    }
    return stateForm.seal_file_path ? stateForm.seal_file_path : undefined;
  };
  // <<--- TLOG

  const onChangeText =
    (field: keyof IStateForm) => (newText: string | undefined) => {
      let newStateForm = { ...stateForm, [field]: newText };

      if (field === "avatar_image")
        newStateForm = { ...newStateForm, avatar: null };
      // --->> TLOG
      if (field === "seal_image")
        newStateForm = { ...newStateForm, seal_file_path: null };
      // <<--- TLOG

      setIsEdited(!_.isEqual(newStateForm, originData));
      setStateForm(newStateForm);
    };

  // --->> TLOG
  const onChangePosition = (value: string) => {
    const value_sep = value.split(POSITION_SEP);
    setStateForm((pre) => {
      return {
        ...pre,
        position_id: value_sep[0],
        position_name: value_sep.length > 1 ? value_sep[1] : "",
      };
    });
  };

  const onChangeLevel1 = (item: ActivityBase, group_info: IGroupEditData[]) => {
    setStateForm((pre) => {
      return {
        ...pre,
        location_code: item?.activity_base_code ?? "",
      };
    });
    if (originData.location_code === null) {
      setOriginData((pre) => {
        return {
          ...pre,
          location_code: item?.activity_base_code ?? "",
          group_info: group_info ?? [],
        };
      });
      setIsEdited(!_.isEqual(stateForm, originData));
    }

    if (stateForm.preferred_username_cognito != "")
      onValidateText("preferred_username_cognito", item?.activity_base_code);
  };
  // <<--- TLOG

  const onValidateText = async (
    field: keyof IStateForm,
    location_code?: string, //  TLOG
  ) => {
    const mess = await validator(field, stateForm[field], location_code);
    setFormError({ ...formError, [field]: mess });
  };

  const validator = async (
    field: keyof IStateForm,
    value: string,
    location_code?: string, //  TLOG
  ) => {
    let mess: string = "";

    switch (field) {
      case "preferred_username_cognito":
        let locationCode = location_code ?? stateForm.location_code ?? "";
        mess = await Validation.validateLoginIDTlog(
          value,
          originData.preferred_username_cognito,
          locationCode,
          originData.location_code ?? locationCode,
          "ログインID",
          true,
          contractInfoObject.contract_login_id,
        );
        // <<--- TLOG
        break;
      case "email":
        mess = Validation.validateEmail(
          value,
          "メールアドレス",
          originData.email_verified,
        );
        break;
      case "phone_number":
        mess = Validation.validatePhone(
          value,
          "電話番号",
          originData.phone_number_verified,
        );
        break;
      case "full_name":
        mess = Validation.validate({
          type: "text",
          name: "名前",
          value: value,
          required: true,
          max_length: 30,
        });
        break;
      case "belong_company_name":
        mess = Validation.validate({
          type: "text",
          // --->> TLOG
          // name: "所属企業",
          name: "グループ名",
          // <<--- TLOG
          value: value,
          required: false,
          max_length: 30,
        });
        break;
      // --->> TLOG
      case "position_id":
        mess = Validation.validate({
          type: "text",
          name: "職位",
          value: value,
          required: true,
        });
        break;
      case "position_name":
        mess = Validation.validate({
          type: "text",
          name: "役職名",
          value: value,
          required: false,
          max_length: 30,
        });
        break;
      case "seal_display_name":
      case "seal_image": // 電子印画像削除時
        mess = Validation.validate({
          type: "text",
          name: "電子印情報",
          value: value.replace(/\r?\n/g, ""), // 改行は桁数に含まない
          required: field === "seal_image" ? true : !getUrlSeal(),
          max_length: 30,
        });
        // <<--- TLOG
        break;
    }
    return mess;
  };

  // --->> TLOG
  const validateGroup = () => {
    let mess: string = "";
    if (groupInfoRef && groupInfoRef.current) {
      mess = groupInfoRef.current.validate();
    }
    return mess;
  };

  useEffect(() => {
    setIsEdited(!_.isEqual(stateForm, originData));
    const mess = validateGroup();
    setFormError({ ...formError, group_info: mess });
  }, [stateForm.group_info]);
  // <<--- TLOG

  const validationAllCheck = async () => {
    let response = true;
    const mess_id = await validator(
      "preferred_username_cognito",
      stateForm.preferred_username_cognito,
    );
    const mess_name = await validator("full_name", stateForm.full_name);
    const mess_phone = await validator("phone_number", stateForm.phone_number);
    const mess_email = await validator("email", stateForm.email);
    const mess_company = await validator(
      "belong_company_name",
      stateForm.belong_company_name,
    );
    // --->> TLOG
    let mess_group = validateGroup();
    let mess_seal = await validator(
      "seal_display_name",
      stateForm.seal_display_name,
    );
    if (originData.user_role === USER_ROLES.OWNER.value) {
      mess_seal = "";
    }
    // <<--- TLOG

    if (
      mess_id.length > 0 ||
      mess_name.length > 0 ||
      mess_phone.length > 0 ||
      mess_email.length > 0 ||
      mess_company.length > 0 ||
      mess_group.length > 0 || // TLOG
      mess_seal.length > 0 // TLOG
    )
      response = false;

    setFormError({
      ...formError,
      preferred_username_cognito: mess_id,
      full_name: mess_name,
      phone_number: mess_phone,
      email: mess_email,
      belong_company_name: mess_company,
      group_info: mess_group, // TLOG
      seal_display_name: mess_seal, // TLOG
    });
    return response;
  };

  const disabledSaveBtn = useMemo(() => {
    const error_messages = Object.values(formError).filter(
      (item) => item.length > 0,
    );
    if (!hasUpdateData || error_messages.length > 0) return true;
    return false;
  }, [formError, hasUpdateData]);

  // 保存 ------------------------------------------------
  const handleUpdateAccount = async (isButton?: boolean) => {
    try {
      LoadingOverlayController.show();

      // 入力チェック
      if (!(await validationAllCheck())) {
        return false;
      }

      const data = {
        contract_id: stateForm.PK,
        user_id: stateForm.SK,
        preferred_username_cognito: stateForm.preferred_username_cognito,
        user_role: stateForm.user_role,
        full_name: stateForm.full_name,
        phone_number: stateForm.phone_number,
        email: stateForm.email,
        belong_company_name: stateForm.belong_company_name,
        avatar_status:
          stateForm.avatar_image === undefined && delete_avatar
            ? "false"
            : "true",
        country_code: stateForm.phone_number ? COUNTRY_CODE_JP : undefined,
        // --->> TLOG
        position_id: stateForm.position_id,
        position_name: stateForm.position_name,
        user_stamp:
          stateForm.seal_image === undefined && isDeleteSeal ? "false" : "true",
        seal_display_name: stateForm.seal_display_name,
        location_code: stateForm.location_code,
        location_id: stateForm.location_id,
        is_vertical_writing_sign: stateForm.is_vertical_writing_sign
          ? "true"
          : "false",
        can_create_user_report: stateForm.can_create_user_report
          ? "true"
          : "false",
        contract_login_id: contractInfoObject.contract_login_id,
        // <<--- TLOG
      };

      let fileUpload = null;
      if (stateForm.avatar_image !== originData.avatar_image) {
        fileUpload = stateForm.avatar_image;
      }
      let formData = createFormUpload(fileUpload, data);
      // --->> TLOG
      if (
        stateForm.seal_image !== originData.seal_image &&
        stateForm.seal_image
      ) {
        formData.append("seal_display_file", stateForm.seal_image);
      }
      // <<--- TLOG
      // --->> TLOG
      if (groupInfoRef && groupInfoRef.current) {
        const resUpdateGroup = await groupInfoRef.current.handleUpdateGroup();
      } // <<--- TLOG

      const res = await updateUserApi(formData);

      if (res) {
        setOriginData({ ...stateForm });
        ModalController.show({
          message: messages.COMMON.MSG_COMMON_SUCCESS_001("アカウントの更新"),
          visibleButton2: true,
          handlePressButton2: () => checkLogout(isButton),
        });
        if (isProfileUpdate) {
          // 保存後に画面遷移しない
          window.history.pushState(null, "", null);
          setIsEdited(false);
          return false;
        } else {
          if (isButton) setIsEdited(false);
          return true;
        }
      }
      return false;
    } catch (error: any) {
      if (!ModalController.isShowing())
        ModalController.show({
          message: error?.detail,
          visibleButton2: true,
        });
      console.log("error handleUpdateAccount", error);
      return false;
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const isProfileUpdate = useMemo(
    () =>
      originData.preferred_username_cognito !==
        stateForm.preferred_username_cognito ||
      originData.user_role !== stateForm.user_role,
    [originData, stateForm],
  );

  const checkLogout = (isButton?: boolean) => {
    let message = "";
    if (isProfileUpdate) {
      message = messages.COMMON.MSG_LOGOUT_MY_PROFILE_UPDATE;
    }

    if (message !== "") {
      // ログアウト
      setTimeout(() => {
        ModalController.show({
          message: message,
          visibleButton2: true,
        });
      }, 500);
      dispatch({ type: TYPES.LOGOUT });
      return;
    }

    // 再表示
    if (isButton) fetchdata();
  };

  // 保存確認ポップアップ
  const { setIsEdited } = useRouterPrompt({ onOK: handleUpdateAccount });

  return (
    <GenericTemplate title="マイアカウント設定">
      <DropzoneDialog
        open={fileOpen}
        onClose={() => setFileOpen(false)}
        onChoose={chooseImage}
        maxFiles={1}
      />
      <PasswordChangeDialog open={open} setOpen={setOpen} />
      <Grid
        container
        spacing={2}
        sx={{ flexWrap: { xs: undefined, md: "nowrap" } }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            minWidth: { xs: undefined, md: "300px" },
            maxWidth: { xs: undefined, md: "300px" },
          }}
        >
          <Stack>
            <Card>
              <CardContent sx={{ textAlign: "center" }}>
                <CancelBtnBadge
                  invisible={getUrlAvatar() === undefined}
                  onClick={() => {
                    onChangeText("avatar_image")(undefined);
                    setDeleteAvatar(true);
                  }}
                >
                  <AvatarRes size={"lg"} src={getUrlAvatar()} />
                </CancelBtnBadge>
                <span className="IgnoreExtractRuleTarget">
                  <Typography sx={{ mt: 1 }}>{originData.full_name}</Typography>
                </span>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  variant="text"
                  fullWidth
                  onClick={() => {
                    setFileOpen(true);
                  }}
                >
                  写真を変更
                </Button>
              </CardActions>
            </Card>
            <Card>
              <CardContent>
                <FormGroup>
                  <Stack>
                    <TextField
                      label={
                        <>
                          <LabelRequired title={"ログインID"} />
                        </>
                      }
                      name="preferred_username_cognito"
                      value={stateForm.preferred_username_cognito}
                      onChange={(e) =>
                        onChangeText("preferred_username_cognito")(
                          e.target.value,
                        )
                      }
                      onBlur={() =>
                        onValidateText("preferred_username_cognito")
                      }
                      error={formError.preferred_username_cognito.length > 0}
                      helperText={formError.preferred_username_cognito}
                      inputProps={{
                        maxLength: 30,
                      }}
                      placeholder="半角英数記号 6~30 スペース利用不可"
                      sx={{
                        "& ::placeholder": {
                          fontSize: { xs: undefined, md: 11 },
                        },
                      }}
                    />
                    <FormLabel>パスワード</FormLabel>
                    <Button
                      sx={{ width: 100 }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      変更
                    </Button>
                    {originData.user_role === USER_ROLES.OWNER.value ? null : (
                      <SelectLabel
                        label={
                          <>
                            <LabelRequired title={"権限"} />
                          </>
                        }
                        value={stateForm.user_role}
                        onChange={(e) => {
                          onChangeText("user_role")(e.target.value);
                        }}
                        isIgnoreExtractRuleLabel={false}
                        isIgnoreExtractRuleSelect={false}
                      >
                        {originData.user_role === USER_ROLES.ADMIN.value
                          ? LIST_USER_ROLE_FOR_ADMIN.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item.name}
                              </MenuItem>
                            ))
                          : originData.user_role === USER_ROLES.EDITOR.value ||
                            originData.user_role === USER_ROLES.MEMBER.value
                          ? LIST_USER_ROLE_FOR_MEMBER_AND_EDITOR.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item.name}
                              </MenuItem>
                            ))
                          : null}
                      </SelectLabel>
                    )}
                    <LabelValueText
                      label="契約企業"
                      value={stateForm.contract_name}
                      isIgnoreExtractRule={true}
                    />
                  </Stack>
                </FormGroup>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} md={9}>
          <Card>
            <CardContent>
              <FormGroup>
                <Stack>
                  <TextField
                    label={
                      <>
                        <LabelRequired title={"名前"} />
                      </>
                    }
                    name="full_name"
                    value={stateForm.full_name}
                    onChange={(e) => onChangeText("full_name")(e.target.value)}
                    onBlur={() => onValidateText("full_name")}
                    error={formError.full_name.length > 0}
                    helperText={formError.full_name}
                    inputProps={{
                      maxLength: 30,
                    }}
                    placeholder="現場　太郎"
                  />
                  {/* --->> TLOG
                  <TextField
                    label="電話番号"
                    name="phone_number"
                    value={stateForm.phone_number}
                    onChange={(e) =>
                      onChangeText("phone_number")(e.target.value)
                    }
                    required={stateForm.phone_number_verified}
                    onBlur={() => onValidateText("phone_number")}
                    error={formError.phone_number.length > 0}
                    helperText={formError.phone_number}
                    inputProps={{
                      maxLength: 11,
                    }}
                    placeholder="電話番号 ※ハイフン（-）不要"
                  />
                  <<--- TLOG */}
                  <TextField
                    label="メールアドレス"
                    name="email"
                    value={stateForm.email}
                    onChange={(e) => onChangeText("email")(e.target.value)}
                    required={stateForm.email_verified}
                    onBlur={() => onValidateText("email")}
                    error={formError.email.length > 0}
                    helperText={formError.email}
                    inputProps={{
                      maxLength: 256,
                    }}
                    placeholder="xxx@example.com"
                  />
                  {/* --->> TLOG */}
                  {/* {stateForm.user_role === USER_ROLES.OWNER.value ? null : (
                    <SelectLabel
                      label="職位"
                      value={
                        listPosition.length > 0 && stateForm.position_id
                          ? stateForm.position_id +
                            POSITION_SEP +
                            stateForm.position_name
                          : ""
                      }
                      defaultValue=""
                      onChange={(e) => onChangePosition(e.target.value)}
                      onBlur={() => onValidateText("position_id")}
                    >
                      {listPosition.map((item) => (
                        <MenuItem
                          value={item.SK + POSITION_SEP + item.position_name}
                          key={item.SK}
                        >
                          {item.position_name}
                        </MenuItem>
                      ))}
                    </SelectLabel>
                  )} */}
                  {/* <<--- TLOG */}
                  <TextField
                    // --->> TLOG
                    // label="所属企業"
                    label="グループ名"
                    // <<--- TLOG
                    name="belong_company_name"
                    onChange={(e) =>
                      onChangeText("belong_company_name")(e.target.value)
                    }
                    value={stateForm.belong_company_name}
                    onBlur={() => onValidateText("belong_company_name")}
                    error={formError.belong_company_name.length > 0}
                    helperText={formError.belong_company_name}
                    inputProps={{
                      maxLength: 30,
                    }}
                    // --->> TLOG
                    // placeholder="企業名"
                    placeholder="グループ名"
                    // <<--- TLOG
                  />
                  {/* --->> TLOG */}
                  <TextField
                    label="役職名"
                    name="position_name"
                    onChange={(e) =>
                      onChangeText("position_name")(e.target.value)
                    }
                    value={stateForm.position_name}
                    onBlur={() => onValidateText("position_name")}
                    error={formError.position_name.length > 0}
                    helperText={formError.position_name}
                    inputProps={{
                      maxLength: 30,
                    }}
                    placeholder="役職名"
                  />
                  <CheckboxLabel
                    checked={stateForm.can_create_user_report}
                    onClick={() => {
                      setStateForm((pre) => ({
                        ...pre,
                        can_create_user_report: !pre.can_create_user_report,
                      }));
                    }}
                    label="ユーザー作成帳票を作成可能"
                    sx={{ ml: -1 }}
                    formSx={{ alignSelf: "flex-start" }}
                  />
                  <GroupInfoOption
                    ref={groupInfoRef}
                    stateForm={stateForm}
                    setStateForm={setStateForm}
                    allowEditTopLocation={false}
                    allowEditGroup={
                      stateForm.user_role === USER_ROLES.ADMIN.value
                    }
                    disabled={stateForm.user_role === USER_ROLES.OWNER.value}
                    handleChangeLevel1={onChangeLevel1}
                  />

                  {stateForm.user_role !== USER_ROLES.OWNER.value && (
                    <>
                      <TextField
                        label={
                          <>
                            <LabelRequired
                              title={"電子印の表示名(3行、30桁まで)"}
                            />
                          </>
                        }
                        multiline
                        rows={SEAL_MAX_ROWS}
                        value={stateForm.seal_display_name}
                        onChange={(e) => {
                          if (
                            e.target.value.split("\n").length > SEAL_MAX_ROWS
                          ) {
                            return;
                          }
                          onChangeText("seal_display_name")(e.target.value);
                        }}
                        onBlur={() => onValidateText("seal_display_name")}
                        inputProps={{
                          maxLength: 32,
                          style: { textAlign: "center" },
                        }}
                        helperText={formError.seal_display_name}
                        error={formError.seal_display_name.length > 0}
                      />
                      <CheckboxLabel
                        checked={stateForm.is_vertical_writing_sign}
                        onClick={() => {
                          setStateForm((pre) => ({
                            ...pre,
                            is_vertical_writing_sign:
                              !pre.is_vertical_writing_sign,
                          }));
                        }}
                        label="電子印で表示名を縦書きで表示する"
                        sx={{ ml: -1 }}
                        formSx={{ alignSelf: "flex-start" }}
                      />
                      <FormControl>
                        <InputLabel>電子印画像</InputLabel>
                        {getUrlSeal() ? (
                          <Box sx={{ textAlign: "center" }}>
                            <CancelBtnBadge
                              onClick={async () => {
                                onChangeText("seal_image")(undefined);
                                setIsDeleteSeal(true);
                                const mess = await validator(
                                  "seal_image",
                                  stateForm.seal_display_name,
                                );
                                setFormError({
                                  ...formError,
                                  seal_display_name: mess,
                                });
                              }}
                            >
                              <Box
                                component="img"
                                src={getUrlSeal()}
                                sx={{
                                  height: 100,
                                  objectFit: "contain",
                                  borderRadius: 1,
                                }}
                              />
                            </CancelBtnBadge>
                          </Box>
                        ) : (
                          <Dropzone onChoose={chooseImageSeal} maxFiles={1} />
                        )}
                      </FormControl>
                    </>
                  )}
                  {/* <<--- TLOG */}
                </Stack>
              </FormGroup>
            </CardContent>
            <Divider />
            <CardActions sx={{ p: 2, justifyContent: "flex-end" }}>
              <Button
                onClick={() => handleUpdateAccount(true)}
                disabled={disabledSaveBtn}
                ref={ref}
              >
                保存
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default AccountScreen;
