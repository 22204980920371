import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { Colors } from "@template/style";

interface IProps {
  index: number;
  isFinish: boolean;
  item: Type.FlowDataItemType;
  onChooseUser: Function;
  listUserStep: Array<Type.DataStepUserType[]>;
  is_inactive: boolean;
}

export const renderUserFlowCreateTlog = (
  flowApproveUser: Type.FlowDataType,
  onChooseUser: Function,
  listUserStep: Array<Type.DataStepUserType[]>,
  is_inactive: boolean,
) => {
  return (
    <>
      {flowApproveUser.map((item, index) => (
        <StatusUserFlow
          isFinish={index == flowApproveUser.length - 1}
          item={item}
          onChooseUser={onChooseUser}
          listUserStep={listUserStep}
          is_inactive={is_inactive}
          key={`flow_approve_item_${index}`}
          index={index}
        />
      ))}
    </>
  );
};

export default function StatusUserFlow({
  isFinish = false,
  item,
  onChooseUser,
  listUserStep,
  is_inactive,
  index,
}: IProps) {
  const listU = listUserStep[index] ?? item?.data_step;

  return (
    <>
      {index !== 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
          <KeyboardArrowDown sx={{ color: Colors.MAIN_GREEN }} />
        </Box>
      )}
      <Card
        variant="outlined"
        sx={{
          display: { xs: "inline", md: "flex" },
          borderWidth: 2,
          borderColor: Colors.MAIN_GREEN,
          width: "100%",
        }}
      >
        <CardMedia
          sx={{ backgroundColor: Colors.MAIN_GREEN, p: 1, minWidth: 100 }}
        >
          <Box
            sx={{ display: "flex", flexDirection: { xs: "row", md: "column" } }}
          >
            <Typography sx={{ color: "white" }}>ステップ{index + 1}</Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
            <Typography sx={{ color: "white" }}>
              ({item?.data_max_user?.max_user}名承認)
            </Typography>
          </Box>
        </CardMedia>
        <CardContent
          sx={{ width: "100%", alignSelf: "center", ":last-child": { pb: 2 } }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {listU?.map((itemStep, indexStep) => (
                <Typography
                  variant="body1"
                  sx={{
                    color:
                      !itemStep?.isDefault && listUserStep[index]
                        ? Colors.REQUIRE
                        : Colors.DISABLE_BTN_TEXT,
                    pr: 1,
                  }}
                  key={indexStep}
                  className="IgnoreExtractRuleTarget"
                >
                  {itemStep?.full_name}
                  {indexStep + 1 == listU?.length ? null : `,`}
                </Typography>
              ))}
            </Box>
            <Box sx={{ display: "flex", flex: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
              <Button
                onClick={() => {
                  onChooseUser(item, index);
                }}
                disabled={is_inactive}
              >
                変更
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
