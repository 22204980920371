import {
  Typography,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Divider,
} from "@mui/material";
import { LocalOffer } from "@mui/icons-material";
import { SxProps, Theme } from "@mui/system";
import dayjs from "dayjs";
import {
  COLOR_BACKGROUND_NAME_STATUS_WORK,
  COLOR_STATUS_WORK,
  TITLE_STATUS_WORK,
} from "@shared-constants";
import { Colors } from "@template/style";
import { Work } from "services/models";
import formatDateToString from "@utils/DateFormat";

interface WorkItemProps {
  data: Work;
  onClick?: (data: Work) => void;
  checked?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const WorkItem = ({
  data,
  onClick = () => {},
  checked = false,
  sx = {},
  disabled,
}: WorkItemProps) => {
  const endTime = data?.work_end_date ? new Date(data?.work_end_date) : "";
  const expire: boolean = endTime ? dayjs().isAfter(endTime, "d") : false;
  const updateTime = data?.updated_at ? new Date(data?.updated_at) : "";
  return (
    <Card
      onClick={() => {
        if (!disabled) onClick(data);
      }}
      sx={{
        cursor: disabled ? undefined : "pointer",
        borderColor: checked ? Colors.MAIN_GREEN : undefined,
        borderWidth: checked ? 2 : undefined,
        opacity: disabled ? 0.6 : undefined,
        filter: disabled ? "grayscale(40%)" : undefined,
        ...sx,
      }}
      variant={checked ? "outlined" : "elevation"}
    >
      <CardHeader
        avatar={
          <Typography
            sx={{
              bgcolor: COLOR_STATUS_WORK[data.work_status],
              p: 1,
              px: 2,
              borderRadius: 1,
              fontSize: 12,
              color:
                data.work_status === "ASSIGNMENT" ||
                data.work_status === "REPORT" ||
                data.work_status === "WAITING"
                  ? Colors.TEXT
                  : "white",
            }}
            noWrap
          >
            {TITLE_STATUS_WORK[data.work_status]}
          </Typography>
        }
        sx={{
          bgcolor: COLOR_BACKGROUND_NAME_STATUS_WORK[data.work_status],
          py: 1.5,
        }}
        titleTypographyProps={{ fontSize: 20, fontWeight: "bold" }}
        title={data.work_name}
      />
      <CardContent sx={{ p: 1, minHeight: 80 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              ml: 1,
              maxWidth: "75%",
            }}
          >
            <LocalOffer sx={{ alignSelf: "center" }} />
            {data.category_id?.category_name ? (
              <Typography
                sx={{
                  bgcolor: data.category_id?.category_color,
                  color: "white",
                  ...styles.category,
                }}
                noWrap
              >
                {data.category_id?.category_name}
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: Colors.BORDER,
                  ...styles.category,
                }}
                noWrap
              >
                未設定
              </Typography>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Typography
            sx={{
              bgcolor: Colors.BTN_GREEN,
              color: "white",
              maxWidth: "25%",
              ...styles.assigner,
            }}
            noWrap
          >
            {data.full_name_assigner}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={styles.contentTitle}>期日</Typography>
          <Typography
            sx={{
              color:
                expire && data?.work_status != "FINISH"
                  ? Colors.ERROR_TEXT
                  : undefined,
              ...styles.contentValue,
            }}
          >
            {formatDateToString(endTime, "YMD_jp")}
          </Typography>
        </Box>
        {/* --->> TLOG */}
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={styles.contentTitle}>承認</Typography>
          <Typography sx={styles.contentValue}>
            {data?.approval_status}
          </Typography>
        </Box>
        {/* <<--- TLOG */}
      </CardContent>
      <CardActions sx={{ bgcolor: Colors.IMAGE_SELECT_BLOCK }}>
        <Box sx={{ display: "flex", flexDirection: "row", px: 1 }}>
          <Typography sx={{ wordBreak: "keep-all" }}>更新</Typography>
          <Divider orientation="vertical" flexItem sx={styles.verticalBar} />
          <Typography>{formatDateToString(updateTime, "YMDHm_jp")}</Typography>
        </Box>
      </CardActions>
    </Card>
  );
};

export default WorkItem;

const styles = {
  category: { p: 1, ml: 1, fontSize: 12, borderRadius: 2 },
  assigner: { p: 1, ml: 0.5, fontSize: 12, borderRadius: 2 },
  contentTitle: { color: Colors.DISABLE_BTN_TEXT },
  contentValue: { pl: 2, flex: 1 },
  verticalBar: { mx: 1.5, my: 0.5, bgcolor: Colors.BORDER, width: "2px" },
} as const;
