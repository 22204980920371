import React, { useEffect, useMemo, useState } from "react";

import GenericTemplate from "@template/index";
import { Work } from "services/models";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import { getSortOfScreen } from "selector/sortSelector";
import { Box, Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import PopupSort from "components/atoms/PopupSort";
import FilterInput from "components/molecules/FilterInput";
import ScrollLoader from "components/atoms/ScrollLoader";
import WorkItem from "components/molecules/WorkItem";
import { LIST_TYPE_SORT_WORK } from "@shared-constants";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { getParamsFilter } from "@utils/index";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import { getListWorkApi } from "@api/work";
import { TYPES } from "store/types";
import { RootState } from "store/reducer";
import messages from "config/messages";
import { Colors } from "@template/style";

let paramsFilter: any;

const WorkCopyScreen: React.VFC = () => {
  const location = useLocation<any>();
  const typeAction = location.state?.typeAction;
  const typeScreen = typeAction == "2" ? "assignment_copy" : "report_copy";
  const dispatch = useDispatch();
  const { selected_project_id } = useSelector(
    (state: RootState) => state.projectInfo,
  );
  const [selectedWorkItem, setSelectedWorkItem] = useState<Work[]>([]);
  const hasCondition = useSelector(getHasFilterConditionProject("work_copy"));
  const workCopyFilter = useSelector(getConditionFilter("work_copy"));
  const typeSort = useSelector(getSortOfScreen("work_copy"));
  const [listWorkItem, setListWorkItem] = useState<Work[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [PK, setPK] = useState<string | null>(null);
  const [searchedKeyword, setSearchKeyword] = useState<string>("");
  const [isDisableFilter, setIsDisableFilter] = useState<boolean>(false);
  const history = useHistoryCustom();

  // ------------------------------------------------------------------
  // チェック
  // ------------------------------------------------------------------
  const handleSelectItem = (item: Work) => {
    if (selectedWorkItem.length === 0) {
      setSelectedWorkItem([...selectedWorkItem, item]);
      return;
    }
    const indexItem = selectedWorkItem.findIndex(
      (wItem: Work) => wItem.SK === item.SK,
    );
    if (indexItem <= -1) {
      setSelectedWorkItem([item]);
    }
    if (indexItem > -1) {
      setSelectedWorkItem([]);
    }
  };

  const getIsCheck = (id: string) => {
    return selectedWorkItem.map((workItem: any) => workItem.SK).includes(id);
  };

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (str: string) => {
    setSearchKeyword(str);
    setLoadMore(true);
    setLastKey(null);
    setSortBy(null);
    setPK(null);
    LoadingOverlayController.show();
    fetchListWorkItem(str, undefined, "", "");
  };

  const fetchListWorkItem = async (
    keyWord?: string,
    sortType?: string,
    lastKeyword?: string,
    sort_by?: string,
    isMerge: boolean = false,
    pk?: string,
  ) => {
    setLoading(true);
    if (listWorkItem.length === 0) {
      LoadingOverlayController.show();
    }
    let params = {
      keyword: keyWord ?? searchedKeyword,
      sort_type: sortType ?? typeSort,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy,
      pk: pk ?? PK,
      filter_condition: paramsFilter,
      project_id: selected_project_id,
    };

    await getListWorkApi(params)
      .then((res) => {
        if (res?.data.length === 0 && !res?.last_key) {
          setLoadMore(false);
        }
        if (isMerge) {
          setListWorkItem((prevValue) => [...prevValue, ...res?.data]);
        } else {
          setListWorkItem(res?.data);
        }
        if (res?.last_key) {
          setLastKey(res?.last_key?.SK ?? null);
          setSortBy(res?.last_key?.sort_by ?? null);
          setPK(res?.last_key?.PK ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setPK(null);
          setLoadMore(false);
        }
        if (res?.data.length < 10 && res?.last_key) {
          fetchListWorkItem(
            params.keyword,
            params.sort_type,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
            res?.last_key?.PK,
          );
        }
        if (!res?.last_key || (res.data.length >= 10 && res.last_key)) {
          LoadingOverlayController.hide();
        }
      })
      .catch((error) => {
        ModalController.show({
          message: error?.response?.detail?.message,
          visibleButton2: true,
        });
      })
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  // ------------------------------------------------------------------
  //　確定
  // ------------------------------------------------------------------
  const handleCopyWorkItem = () => {
    history.pushWithRef("/work/create", {
      typeAction: typeAction,
      workData: selectedWorkItem[0],
      fromDirective: false,
    });
  };

  // ------------------------------------------------------------------
  // 描画
  // ------------------------------------------------------------------
  useEffect(() => {
    paramsFilter = getParamsFilter(workCopyFilter, typeScreen);
    fetchListWorkItem();
  }, []);

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && listWorkItem.length > 0;
  }, [listWorkItem, loadMore, lastKey]);

  const hasActiveButton = useMemo(() => {
    return selectedWorkItem.length > 0 && !loading;
  }, [loading, selectedWorkItem]);

  const _renderItem = (item: Work) => {
    const checked = getIsCheck(item.SK);
    const disabled = item.work_status !== "FINISH";
    return (
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={0}>
        <Checkbox
          id={"check" + item.SK}
          checked={checked}
          onChange={() => handleSelectItem(item)}
          disabled={disabled}
        />
        <WorkItem
          data={item}
          onClick={() => handleSelectItem(item)}
          checked={checked}
          sx={{ flexGrow: 1 }}
          disabled={disabled}
        />
      </Stack>
    );
  };

  // ------------------------------------------------------------------
  // 並べ替え
  // ------------------------------------------------------------------
  const handleSort = (type_sort: string) => {
    LoadingOverlayController.show();
    const payload = {
      type_screen: "work_copy",
      value: type_sort,
    };
    setSortBy(null);
    setLastKey(null);
    setPK(null);
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchListWorkItem(undefined, type_sort, "", "");
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_WORK).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_WORK).indexOf(defaultSort[0]);
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!listWorkItem.length && !hasCondition) {
      setIsDisableFilter(true);
    } else {
      setIsDisableFilter(false);
    }
  }, [listWorkItem]);

  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy(null);
    }
    if (lastKey) {
      setLastKey(null);
    }
    if (PK) {
      setPK(null);
    }
    paramsFilter = getParamsFilter(workCopyFilter, typeScreen);
  }, [workCopyFilter]);

  return (
    <GenericTemplate
      title="コピー元の作業を選択"
      isSearch={true}
      showMenu={false}
      searchPlaceholder="作業名で検索"
      onSearch={(v) => _handleSearch(v)}
      onSearchClear={() => _handleSearch("")}
    >
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} md="auto">
          {/* =================== 並べ替え =================== */}
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_WORK).map((item) => {
              return {
                title: item.title,
                onClick: () => handleSort(item.value),
              };
            })}
            defaultIndex={sortDefaultIndex}
            disabled={listWorkItem.length === 0}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ width: { md: "40%" }, marginLeft: "auto" }}
        >
          {/* =================== 絞り込み =================== */}
          <FilterInput
            typeScreen="work_copy"
            disabled={isDisableFilter}
            onFetchData={() => {
              LoadingOverlayController.show();
              fetchListWorkItem(undefined, undefined, "", "");
            }}
          />
        </Grid>
      </Grid>

      {/* =================== 作業 =================== */}
      <InfiniteScroll
        loadMore={() => {
          if (loadMore && lastKey && listWorkItem.length > 0 && !loading) {
            fetchListWorkItem(undefined, undefined, lastKey, undefined, true);
          }
        }}
        hasMore={showLoadMore}
        loader={<ScrollLoader key={0} />}
        style={{ paddingBottom: 50 }}
      >
        <Grid container spacing={3} sx={{ pt: 2 }}>
          {listWorkItem.map((d: Work, i) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={i}>
                {_renderItem(d)}
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {listWorkItem.length === 0 && !loading && (
        <Typography>
          {searchedKeyword === ""
            ? messages.COMMON.MSG_NOT_EXIST("作業")
            : messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)}
        </Typography>
      )}

      {/* =================== ボタン =================== */}
      <Box
        sx={{
          position: "fixed",
          margin: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          width: "100%",
        }}
      >
        <Button
          onClick={handleCopyWorkItem}
          size="large"
          disabled={!hasActiveButton}
          sx={{
            "&.Mui-disabled": {
              bgcolor: Colors.DISABLE_INPUT_BG,
            },
          }}
        >
          決定
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default WorkCopyScreen;
