import { TYPES } from "store/types";

const initialState = {
  project: "update_time_latest_sort",
  work: "update_time_latest_sort",
  work_group: "update_time_latest_sort",
  work_delete: "update_time_latest_sort",
  work_copy: "update_time_latest_sort",
  work_status: "update_time_latest_sort",
  folder: "default",
  folder_save_free: "file_created_at_folder_updated_at_new",
  folder_not_save_free: "created_at_new",
  ledger: "update_time_latest_sort",
  template: "last_use_sort",
  editLedger: "default",
  members_management: "update_time_latest_sort",
  handover_report: "update_time_latest_sort",
  custom_template: "update_time_latest_sort",
  custom_template_trash: "deleted_time_latest_sort",
};

export const sortReducer = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case TYPES.SET_SORT_SCREEN: {
      let { type_screen, value } = payload;
      return { ...state, [type_screen]: value };
    }
    case TYPES.CLEAR_SORT_CONDITION: {
      return { ...initialState };
    }
    case TYPES.SET_SORT_ALL_SCREEN: {
      return { ...payload };
    }
    default:
      return state;
  }
};
