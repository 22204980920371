import React from "react";
import {
  Typography,
  Box,
  CardActions,
  CardContent,
  CardHeader,
  Card,
  Divider,
  Button,
} from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import dayjs from "dayjs";
import {
  COLOR_BACKGROUND_NAME_STATUS_WORK_TLOG,
  COLOR_STATUS_WORK_TLOG,
  STATUS_PRINTED,
  TITLE_STATUS_WORK_TLOG,
} from "@shared-constants";
import { Colors } from "@template/style";
import { Work } from "services/models";
import formatDateToString from "@utils/DateFormat";
import { getBarcodeData, getBarcodeLayoutDataByProject } from "@utils/index";

interface WorkItemProps {
  data: Work;
  onClick?: (data: Work) => void;
  checked?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  showPrinted?: boolean;
  showDetailButton?: boolean;
  onClickDetail?: (data: Work) => void;
}

const WorkItemTlog = ({
  data,
  onClick = () => {},
  checked = false,
  sx = {},
  disabled,
  showPrinted = true,
  showDetailButton = false,
  onClickDetail = () => {},
}: WorkItemProps) => {
  // const endTime = data?.work_end_date ? new Date(data?.work_end_date) : "";
  // const expire: boolean = endTime ? dayjs().isAfter(endTime, "d") : false;
  const updateTime = data?.updated_at ? new Date(data?.updated_at) : "";

  const work_template_data = JSON.parse(
    data?.work_template_data ? data?.work_template_data : "{}",
  );

  const [barcodeData, setBarcodeData] = React.useState<{
    [key: string]: any;
  }>({});

  React.useEffect(() => {
    async function getTemplateDispData() {
      const layout = await getBarcodeLayoutDataByProject(data.project_id ?? "");
      setBarcodeData(getBarcodeData(layout, work_template_data));
    }
    getTemplateDispData();
  }, [data]);

  return (
    <Card
      onClick={() => {
        if (!disabled) onClick(data);
      }}
      sx={{
        cursor: disabled ? undefined : "pointer",
        borderColor: checked ? Colors.MAIN_GREEN : undefined,
        borderWidth: checked ? 2 : undefined,
        opacity: disabled ? 0.6 : undefined,
        filter: disabled ? "grayscale(40%)" : undefined,
        ...sx,
      }}
      variant={checked ? "outlined" : "elevation"}
    >
      <CardHeader
        avatar={
          <Typography
            sx={{
              bgcolor: COLOR_STATUS_WORK_TLOG[data.work_status],
              p: 1,
              px: 2,
              borderRadius: 1,
              fontSize: 12,
              color: data.work_status === "REJECT" ? Colors.TEXT : "white",
            }}
            noWrap
          >
            {TITLE_STATUS_WORK_TLOG[data?.work_status.replace(" ", "_")]}
          </Typography>
        }
        sx={{
          bgcolor: COLOR_BACKGROUND_NAME_STATUS_WORK_TLOG[data.work_status],
          py: 1.5,
        }}
        titleTypographyProps={{ fontSize: 20, fontWeight: "bold" }}
        title={
          <span
            className="IgnoreExtractRuleTarget"
            style={{ wordBreak: "break-all" }}
          >
            {data?.risky_id}
          </span>
        }
      />
      <CardContent sx={{ p: 1, minHeight: 80 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            pb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              ml: 1,
              maxWidth: "75%",
            }}
          >
            {/* <LocalOffer sx={{ alignSelf: "center" }} />
            {data.category_id?.category_name ? (
              <Typography
                sx={{
                  bgcolor: data.category_id?.category_color,
                  color: "white",
                  ...styles.category,
                }}
                noWrap
              >
                {data.category_id?.category_name}
              </Typography>
            ) : (
              <Typography
                sx={{
                  color: Colors.BORDER,
                  ...styles.category,
                }}
                noWrap
              >
                未設定
              </Typography>
            )} */}
          </Box>

          <Box sx={{ 
            textAlign: "left",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
             }} />
          <Typography
            sx={{
              bgcolor: Colors.BTN_GREEN,
              color: "white",
              maxWidth: "100%",
              ...styles.assigner,
            }}
            noWrap
            className="IgnoreExtractRuleTarget"
          >
            {data?.created_by_name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", minHeight: 24 }}>
          <Typography
            sx={styles.contentTitle}
            className="IgnoreExtractRuleTarget"
          >
            {barcodeData?.title1?.label ?? ""}
          </Typography>
          <Typography
            sx={styles.contentValue}
            noWrap
            className="IgnoreExtractRuleTarget"
          >
            {barcodeData?.title1?.value ?? ""}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", minHeight: 24 }}>
          <Typography
            sx={styles.contentTitle}
            className="IgnoreExtractRuleTarget"
          >
            {barcodeData?.detail1?.label ?? ""}
          </Typography>
          <Typography
            sx={styles.contentValue}
            noWrap
            className="IgnoreExtractRuleTarget"
          >
            {barcodeData?.detail1?.value ?? ""}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", minHeight: 24 }}>
          <Typography
            sx={styles.contentTitle}
            className="IgnoreExtractRuleTarget"
          >
            {barcodeData?.detail2?.label ?? ""}
          </Typography>
          <Typography
            sx={styles.contentValue}
            noWrap
            className="IgnoreExtractRuleTarget"
          >
            {barcodeData?.detail2?.value ?? ""}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
          <Box sx={{ display: "flex", width: "50%" }}>
            {showPrinted && (
              <Typography
                color={"white"}
                sx={[
                  styles.printContainer,
                  { backgroundColor: data?.is_printed ? "#005AFF" : "#FF4B00" },
                ]}
              >
                {data?.is_printed
                  ? STATUS_PRINTED.PRINTED
                  : STATUS_PRINTED.NOT_PRINTED}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "50%",
              alignItems: "center",
            }}
          >
            <Typography sx={styles.contentTitle}>承認</Typography>
            <Typography
              sx={styles.contentValue}
              noWrap
              className="IgnoreExtractRuleTarget"
            >
              {data?.approval_status}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ bgcolor: Colors.IMAGE_SELECT_BLOCK }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            px: 1,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography sx={{ wordBreak: "keep-all" }}>更新</Typography>
          <Divider orientation="vertical" flexItem sx={styles.verticalBar} />
          <Typography className="IgnoreExtractRuleTarget">
            {formatDateToString(updateTime, "YMDHm")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {showDetailButton && (
            <Button
              sx={{
                minWidth: 50,
                borderRadius: 99,
                padding: "0px 9px",
                "&:hover": {
                  bgcolor: "rgba(2, 159, 152, 0.1)",
                },
              }}
              size="small"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                if (typeof onClickDetail === "function") onClickDetail(data);
              }}
            >
              詳細
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default WorkItemTlog;

const styles = {
  category: { p: 1, ml: 1, fontSize: 12, borderRadius: 2 },
  assigner: { p: 1, ml: 0.5, fontSize: 12, borderRadius: 2 },
  contentTitle: { color: Colors.DISABLE_BTN_TEXT, minWidth: 35 },
  contentValue: { pl: 2, flex: 1 },
  verticalBar: { mx: 1.5, my: 0.5, bgcolor: Colors.BORDER, width: "2px" },
  printContainer: { px: 1.5, py: 0.5, borderRadius: 1 },
} as const;
