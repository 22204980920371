import { VFC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormLabel,
  TextField,
  Stack,
} from "@mui/material";
import logoImg from "assets/logo.png";
import PasswordInput from "components/atoms/PasswordInput";
import { TYPES } from "store/types";
import { useSize } from "shared/hook/useSize";
import { theme } from "@template/style"; // TLOG
import LabelRequired from "components/atoms/LabelRequired";

const ContractDecision: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [contractLoginId, setContractLoginId] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { isSmUp } = useSize();
  const [disableBtn, setDisableBtn] = useState<boolean>(true);

  const theme_label: any = theme.components?.MuiInputLabel?.styleOverrides;
  const styles = {
    label: {
      minWidth: 130,
      width: isSmUp ? 130 : "100%",
      alignSelf: isSmUp ? "center" : "flex-start",
      paddingRight: 1,
      mb: isSmUp ? undefined : theme_label.formControl.marginBottom,
    },
  } as const;

  // ------------------------------------------------------------------
  // ログイン
  // ------------------------------------------------------------------
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: TYPES.CONTRACT_DECISION,
      payload: {
        contract_login_id: contractLoginId,
        contract_password: password,
      },
    });
  };

  // ------------------------------------------------------------------
  // ボタン制御
  // ------------------------------------------------------------------
  useEffect(() => {
    if (contractLoginId && password) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [contractLoginId, password]);

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            component="form"
            action="post"
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              mt: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
            onSubmit={handleSubmit}
          >
            <img src={logoImg} alt="logo" height="252px" width="441px" />
            <FormControl fullWidth margin="normal">
              <Stack direction={isSmUp ? "row" : "column"} spacing={0}>
                <FormLabel sx={styles.label}>
                  <LabelRequired title={"契約ID"} />
                </FormLabel>
                <TextField
                  id="contractLoginId"
                  name="contractLoginId"
                  type="text"
                  fullWidth
                  onChange={(e) => setContractLoginId(e.target.value)}
                  inputProps={{ maxLength: 20 }}
                />
              </Stack>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Stack direction={isSmUp ? "row" : "column"} spacing={0}>
                <FormLabel sx={styles.label}>
                  <LabelRequired title={"パスワード"} />
                </FormLabel>
                <PasswordInput
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  label=""
                  fullWidth={true}
                />
              </Stack>
            </FormControl>
            <Button
              disabled={disableBtn}
              type="submit"
              sx={{ mt: 3, mb: 2, py: 2, px: 5 }}
            >
              決定
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ContractDecision;
