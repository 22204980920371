import { EXCEL_TEMPLATE_INPUT } from "@shared-constants";

interface User {
  PK?: string;
  SK: string;
  avatar: string | null;
  create_at?: string;
  phone_number?: string | null;
  username?: string;
  address?: string | null;
  full_name: string;
  email?: string;
  user_role?: string;
  company_id?: string;
  user_id?: string;
  location_id?: string;
  can_create_user_report?: boolean;
}

interface InviteUser {
  contract_id: string;
  invite_id: string;
  created_at?: string;
  invited_at?: string;
  updated_at?: string;
  phone_number?: string;
  email?: string;
  user_role?: string;
}

interface Project {
  PK: string;
  SK: string;
  created_by: string;
  sort_by_created_at?: string;
  sort_by_last_update?: string;
  project_id: string;
  project_name: string;
  project_status_id: any;
  project_start_date: string;
  project_end_date: string;
  user?: User[];
  is_favorite: boolean;
  image?: string;
  memo: string;
  path_file: string;
  path_file_thumb: string;
  project_location_id: string;
  template_project_id: string;
  report_type_code: string;
  report_number: string;
  report_name: string;
  is_daily: boolean;
}

interface Template {
  PK: string;
  SK: string;
  create_at: string;
  order: number;
  screen_id: string;
  template_id: string;
  template_image: string;
  template_image_thumb: string;
  template_name: string;
  template_path: string;
  template_preview_path: string;
  template_size: string;
  template_type: string;
  flow_id: string;
  flow_name: string;
  flow_approve_user: Array<Type.UserFlowItem>;
  path_file_thumb?: string;
  is_landscape?: boolean;
}

interface Work {
  PK: string;
  SK: string;
  category_id: Category;
  created_at: string;
  memo: string;
  order_category_last_update: string;
  work_id: string;
  work_name: string;
  work_start_date: string;
  work_end_date: string;
  work_status: string;
  avatar: any;
  created_by: string;
  full_name_assigner: string;
  updated_at: string;
  user_assigned: string;
  work_template_id: string | any;
  of_assignment: string;
  approval_status: string;
  checking_approve_flag: boolean;
  risky_id: string;
  rejected_by_user: string;
  status_not_approve_flag: boolean;
  work_template_data: string;
  is_redo_work: boolean;
  is_confirm?: boolean;
  is_recreate_from_reject?: boolean;
  created_by_name?: string;
  is_printed?: boolean;
  is_user_in_flow_approve?: boolean;
  rejected_in_step?: string;
  flow_approve_data?: any;
  flow_approve_id?: any;
  project_id?: string;
  is_hand_over?: boolean;
  print_2d_barcode?: boolean;
}

interface Category {
  PK?: string;
  SK?: string;
  category_color: string;
  category_name: string;
  created_at: string;
  industry_name: string;
}

type DataFile = {
  PK: string;
  SK: string;
  blackboard_info: any | null;
  category_id: any | null;
  contract_id: string;
  created_at: string;
  creator_id: string;
  exif_info: any | null;
  file_name: string;
  file_name_filter: string;
  file_size: number;
  file_type: string;
  latitude: string | null;
  longitude: string | null;
  order_category_last_update: string | null;
  path_file: string;
  path_file_thumb: string | null;
  work_status: string | null;
};

type GroupMaster = {
  PK: string;
  SK: string;
  created_at: string;
  group_master_name: string;
  updated_at: string;
};

type GroupInfo = {
  PK: string;
  SK: string;
  created_at: string;
  created_by: string;
  group_id: string;
  group_master_id: string;
  group_name: string;
  group_parentid: string;
  location_id: string;
  seq: number;
  updated_at: string;
  location_code?: string;
};

type ActivityBase = {
  PK: string;
  SK: string;
  activity_base_name: string;
  activity_base_code: string;
  group_sk: string;
  updated_at: string;
  created_at: string;
};

type ResInfoGroup = {
  PK: string;
  SK: string;
  created_at: string;
  created_by: string;
  group_id: string;
  group_master_id: string;
  group_name: string;
  group_parentid: string;
  seq: number;
  updated_at: string;
};

type ResponseOriginGroup = {
  data: ResInfoGroup[];
  timestamp: number;
}[];

type Publisher = {
  PK: string;
  SK: string;
  publisher_name: string;
  is_manual_input: boolean;
};
type OccurrenceLocation = {
  PK: string;
  SK: string;
  occurrence_location_name: string;
  is_manual_input: boolean;
};
type SpanMaster = {
  PK: string;
  SK: string;
  span_name: string;
  is_manual_input: boolean;
};

type ProductStatus = {
  PK: string;
  SK: string;
  product_status_name: string;
  is_manual_input: boolean;
};
type AccidentReason = {
  PK: string;
  SK: string;
  accident_reason_name: string;
  is_manual_input: boolean;
};

type ImportDivision = {
  PK: string;
  SK: string;
  import_division_name: string;
  is_manual_input: boolean;
};

type AccidentHandling = {
  PK: string;
  SK: string;
  accident_handling_name: string;
  is_manual_input: boolean;
};

type Product = {
  PK: string;
  SK: string;
  product_name: string;
  is_manual_input: boolean;
  jan_code?: string;
};

type ShipperMaster = {
  PK: string;
  SK: string;
  shipper_name: string;
  is_manual_input: boolean;
};

interface ManualInput {
  full_name: string;
  is_manual_input: boolean;
}

type AllReportItem = {
  publisher: Array<any>;
  occurrenceLocation: Array<any>;
  spanMaster: Array<any>;
  shipper: Array<any>;
  productStatus: Array<any>;
  accidentReason: Array<any>;
  importDivision: Array<any>;
  accidentHandling: Array<any>;
};

type BarcodeLayout = {
  [key: string]: {
    label: string;
    value_key: Array<string>;
    sub_key?: Array<string>;
  };
};

type CreateExcelTemplate = {
  [key: string]: Array<{
    coordinate: string;
    colSpan: number;
    rowSpan: number;
    value: string;
    width: string;
    height: string;
    textAlign:
      | "start"
      | "end"
      | "left"
      | "right"
      | "center"
      | "justify"
      | "match-parent";
    verticalAlign: string;
    border: {
      top?: IBorder;
      left?: IBorder;
      right?: IBorder;
      bottom?: IBorder;
    };
    font: {
      size: string;
      fontFamily: string;
      color: string;
      weight: string;
      style: string;
      underline?: string;
    };
    bg_color: string;
  }>;
};

interface IBorder {
  color: string;
  width: string;
  style: string;
}

type TemplateExcel = {
  PK: string;
  SK: string;
  excel_path: string;
  excel_name: string;
  start_col: string;
  start_row: string;
  end_col: string;
  end_row: string;
  sheet_name: string;
  font: string;
  paper_size: string;
  horizontal_pixel_size: string;
  vertical_pixel_size: string;
  is_landscape: boolean;
  data_info: Array<TemplateExcelDataInfo>;
  excel: any;
  is_multiple?: boolean;
  extra_template?: Array<TemplateExcelExtra>;
};

type TemplateExcelExtra = {
  excel_path: TemplateExcel["excel_path"];
  excel_name: TemplateExcel["excel_name"];
  start_col: TemplateExcel["start_col"];
  start_row: TemplateExcel["start_row"];
  end_col: TemplateExcel["end_col"];
  end_row: TemplateExcel["end_row"];
  sheet_name: TemplateExcel["sheet_name"];
  excel: any;
  data_info: Array<TemplateExcelDataInfo>;
};

type TemplateExcelDataInfo = {
  coordinate: string;
  name: string;
  input: (typeof EXCEL_TEMPLATE_INPUT)[keyof typeof EXCEL_TEMPLATE_INPUT];
  maxlen: string;
  master: string;
  default: string;
  required: boolean;
  fontSize: string;
  fontColor: string;
  multiline: boolean;
  lineRows: number;
  titleImage?: string;
  headImage1?: string;
  headImage2?: string;
  headImage3?: string;
  headImage4?: string;
  headImage5?: string;
  headImage6?: string;
  approval_step?: number;
  master_coordinate?: string;
  master_attr?: string;
  image?: Type.ImageInfoType;
  isDraw?: boolean;
  dateFormat: string;
  customizeFormat?: string;
  categoryTitle?: string;
  calc?: string;
  calcRound?: string;
  calcRoundPlace?: number;
  radio?: Array<TemplateExcelRadio>;
  timeFormat?: string;
  required_step?: number;
  display_step?: number;
  checkbox?: TemplateExcelCheckbox;
  group_info?: Array<TemplateExcelDataInfo>;
  category_name?: string;
  button_name?: string;
};

type TemplateExcelRadio = {
  label: string;
  text: string;
};

type TemplateExcelCheckbox = {
  checked_text?: string;
  unchecked_text?: string;
};

interface DevanSchedule {
  PK: string;
  SK: string;
  berth_number: string;
  carrier_name: string;
  container_no: string;
  container_size: string;
  created_at: string;
  created_by: string;
  date_of_delivery: string;
  delivery_location: string;
  delivery_time: string;
  invoice_no: string;
  is_accepted: boolean;
  is_delivered: boolean;
  model_name: string;
  number_of_moves: string;
  product_class: string;
  seal_no: string;
  unit_class: string;
  updated_at: string;
  updated_by: string;
}

type ForkliftItem = {
  PK: string;
  SK: string;
  created_at: string;
  check_section_name: string;
  check_section_id: string;
  check_item_name: string;
  updated_at: string;
  seq: number;
};

export const LIST_COLOR_MODE = ["red", "yellow", "black", "white"] as const;
export type ColorMode = (typeof LIST_COLOR_MODE)[number];

export const LIST_STROKE_WIDTH_MODE = ["light", "bold"] as const;
export type StrokeWidthMode = (typeof LIST_STROKE_WIDTH_MODE)[number];

export const LIST_DRAW_TOOL_MODE = [
  "pen",
  "line",
  "text",
  "rectangle",
  "ellipse",
  "eraser",
] as const;
export type DrawToolMode = (typeof LIST_DRAW_TOOL_MODE)[number];

export type DrawItem = (
  | { type: "eraser"; data: null }
  // | { type: "singleHead"; data:  LineProps }
  // | { type: "doubleHead"; data: LineProps }
  | { type: "rectangle"; data: any }
  | { type: "ellipse"; data: any }
  | { type: "text"; data: any; text?: string }
  | { type: "pen"; data: any }
  | { type: "line"; data: any }
) & { strokeWidthMode: StrokeWidthMode; colorMode: ColorMode };

export type {
  User,
  InviteUser,
  Project,
  Template,
  Work,
  Category,
  DataFile,
  GroupMaster,
  GroupInfo,
  ActivityBase,
  ResInfoGroup,
  ResponseOriginGroup,
  Publisher,
  OccurrenceLocation,
  SpanMaster,
  ProductStatus,
  AccidentReason,
  ImportDivision,
  AccidentHandling,
  Product,
  ShipperMaster,
  ManualInput,
  AllReportItem,
  BarcodeLayout,
  CreateExcelTemplate,
  TemplateExcel,
  TemplateExcelExtra,
  TemplateExcelDataInfo,
  TemplateExcelRadio,
  DevanSchedule,
  ForkliftItem,
};
