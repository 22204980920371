import { getIdProject, getIdTemplateProject } from "shared/utils";
import { RootState } from "store/reducer";

export const getProjectIdStore = (state: RootState) =>
  getIdProject(state.projectInfo);

export const getProjectTemplateIdStore = (state: RootState) =>
  getIdTemplateProject(state.projectInfo);

export const getProjectLocationIdStore = (state: RootState) => {
  return state?.projectInfo?.project_location_id;
};

export const getProjectNameStore = (state: RootState) => {
  return state?.projectInfo?.project_name;
};
