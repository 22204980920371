import { call, select } from "redux-saga/effects";
import { getUserInfo, storeCondition } from "shared/utils";

export function* setSortConditionSaga(action: any): any {
  try {
    const updatedConditionSort = yield select((state: any) => state.sort);
    const userId = yield getUserInfo()?.user_id;
    yield call(storeCondition, "sort", updatedConditionSort, userId);
  } catch (error) {
    console.log("function*setFilterConditionSaga ~ error", error);
  }
}
